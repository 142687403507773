import { MouseEventHandler, ReactNode } from 'react'

import * as Styled from './styled'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import { theme } from 'theme'

interface ButtonProps {
  tootltip?: string | undefined
  disabled?: boolean
  children: ReactNode
  className?: string
  size?: 'sm' | 'xs' | 'icon' | 'md'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'submit' | 'reset' | 'button' | undefined
  bgColor?: string
  width?: string
  fontWeight?: string
  id?: string
}

export default function Button(props: ButtonProps) {
  return (
    <GroupcalTooltip title={props.tootltip ?? ''}>
      <Styled.Button
        sx={{
          backgroundColor:
            props.bgColor ?? theme.palette.button.background.primary,
          width: props.width ?? 'auto',
          cursor: 'pointer',
          fontWeight: props.fontWeight ?? '400'
        }}
        id={props.id}
        type={props.type}
        disabled={props.disabled ?? false}
        data-button="button"
        data-size={props.size}
        className={props.className}
        onClick={props.onClick}
      >
        {props.children}
      </Styled.Button>
    </GroupcalTooltip>
  )
}
