import { I } from '@fullcalendar/core/internal-common'
import { AttachFile, CameraAltSharp } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { PlusIcon, Approve } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import InputWithApproveButton from 'components/inputs/InputWithApproveButton'
import { useConnection } from 'components/providers/ConnectionProvider'
import { on } from 'events'
import { IEventDetails } from 'hooks/useEventDetails'
import { t } from 'i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import Logger from 'services/Logger'
import styled from 'styled-components'
import { theme } from 'theme'
import { DocType } from 'types/api/changes'
import { useFilePicker } from 'use-file-picker'
import { compressBase64 } from 'utils/file'
import { capitalizeFirstChar } from 'utils/strings'

export interface NoteTitleInputProps {
  docType?: DocType
  isCurrentGroupIsGoogleCalendar: boolean
  onApproveNoteText: (s: string) => void
  onAttachFile?: (title: string, bytes: string) => void
  eventHook?: IEventDetails
}

export default function NoteTitleInput(props: NoteTitleInputProps) {
  const [text, setText] = useState<string>('')
  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
    props.eventHook?.setNotePendingText(event.target.value)
  }

  const {
    isCurrentGroupIsGoogleCalendar,
    onApproveNoteText,
    onAttachFile,
    docType
  } = props
  const connection = useConnection()
  const [compressedImage, setCompressedImage] = useState<string | undefined>()
  const [openFileSelector, { filesContent }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false
  })

  function selectPic() {
    openFileSelector()
  }

  useEffect(() => {
    if (filesContent.length > 0) {
      if (connection.isConnectionAvailable()) {
        if (onAttachFile) {
          compressBase64(filesContent[0].content)
            .then((data) => {
              // returns an array of compressed images
              // onAttachFile(data)

              setText(capitalizeFirstChar(t('photo')))
              setCompressedImage(data)
            })
            .catch((e) =>
              Logger.red('Error while compressing image', e, 'NoteTitleInput')
            )
        }
      }
    }
  }, [filesContent])

  return (
    <Grid
      width={'100%'}
      display={'flex'}
      flexDirection={'row'}
      container
      alignItems={'center'}
      wrap={'nowrap'}
    >
      <StyledInput
        compressedImage={compressedImage}
        text={text}
        bringFocus={compressedImage ? true : false}
        onTextChange={onTextChange}
        leftIcon={<PlusIcon color={theme.palette.input.placeholder} />}
        hintText={`${t(
          isCurrentGroupIsGoogleCalendar ? 'addNote' : 'addNewNote'
        )}`}
        onApprove={(text: string) => {
          if (compressedImage) {
            onAttachFile?.(
              text ?? capitalizeFirstChar(t('photo')),
              compressedImage
            )
            setCompressedImage(undefined)
          } else {
            onApproveNoteText(text)
          }
          setText('')
        }}
        approveIcon={<Approve width={16} height={13} />}
      />
      {docType !== DocType.REGULAR_EVENT && !compressedImage && (
        <IconButton onClick={selectPic} size="xs">
          <CameraAltSharp style={{ color: theme.palette.input.placeholder }} />
        </IconButton>
      )}
    </Grid>
  )
}

const StyledInput = styled(InputWithApproveButton)`
  color: ${(props) => (props.compressedImage ? 'blue' : 'black')};
  text-decoration: ${(props) => (props.compressedImage ? 'underline' : 'none')};

  & .Input__Input {
    color: ${(props) => (props.compressedImage ? 'blue' : 'black')};
  }
`
