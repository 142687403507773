import isArray from 'lodash.isarray'
import isEqual from 'lodash.isequal'
import isObject from 'lodash.isobject'
import transform from 'lodash.transform'
import mapKeys from 'lodash.transform'
import { ChangeDoc, ChangeDocsByTypes } from 'types/api/changes'
import { formatDateIfNeeded } from './api/changes'

export { isEqual, isArray, isObject }

export function isEmpty(obj: Record<string, any> | any[]): boolean {
  if (Array.isArray(obj)) {
    return !obj.length
  }
  return !Object.keys(obj).length
}

type ObjectType = Record<string, any>

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(origObj: ObjectType, newObj: ObjectType) {
  function changes(newObj: ObjectType, origObj: ObjectType) {
    let arrayIndexCounter = 0
    return transform(
      newObj,
      function (result: ObjectType, value: any, key: string) {
        if (!isEqual(value, origObj[key])) {
          const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
          result[resultKey] =
            isObject(value) && isObject(origObj[key])
              ? changes(value, origObj[key])
              : value
        }
      }
    )
  }
  return changes(newObj, origObj)
}

export function omit(object: Record<string, any>, keys: string[] | string) {
  const copy = { ...object }

  if (typeof keys === 'string') {
    delete copy[keys]
  } else {
    keys.forEach((key) => {
      delete copy[key]
    })
  }

  return copy
}

export function arraysEqual(a?: any[], b?: any[]) {
  if (a == null && b == null) return true
  if (a == null || b == null) return false

  return !!a && !!b && !(a < b || b < a)
}

export function extract(object: Record<string, any>, keys: string[] | string) {
  if (!object) {
    return {}
  }

  const obj: Record<string, any> = {}

  if (typeof keys === 'string') {
    obj[keys] = object[keys]
  } else {
    keys.forEach((key) => {
      obj[key] = object[key]
    })
  }

  return obj
}

const problematicFields = ['id', 'rev']

export function mapKeysIfNeeded(object: any): any {
  /**
   * sometimes we might receive a response where 'id' and 'rev' without underscore
   * in order to fix that, we change those keys to the proper ones
   */

  let result = Object.keys(object).reduce((reducer: Record<string, any>, k) => {
    if (problematicFields.includes(k)) {
      reducer[`_${k}`] = object[k]
    } else {
      reducer[k] = object[k]
    }
    return reducer
  }, {})

  return result
}

export function prepareDocForSaving<T extends ChangeDoc>(
  docFromServer: any
): Record<string, T> {
  const docToSave = { ...docFromServer }
  const docChange: Record<string, T> = {}
  docChange[docToSave._id] = {
    ...docToSave,

    someday: isTrulyEmpty(docToSave.StartDate) ? '1' : '0',
    StartDate: formatDateIfNeeded(docToSave.StartDate),
    EndDate: formatDateIfNeeded(docToSave.EndDate),
    LastUpdate: formatDateIfNeeded(docToSave.LastUpdate)
  }

  return docChange
}

export function isTrulyEmpty(str?: string | null | undefined) {
  if (!str) return true
  if (str === 'null') return true
  if (str.length === 0) return true

  return false
}
