import { useEffect } from 'react'

const useEnterKey = (onEnter: () => void) => {
  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onEnter()
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [onEnter])
}

export default useEnterKey
