import { WeatherIcon } from 'assets/icons'
import Switch from 'components/form/Switch'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import i18n from 'i18n'
import { ChangeEvent } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { EventDoc } from 'types/api/changes'

export interface AllDayRowProps {
  value: string
  field: ControllerRenderProps<EventDoc, any> | undefined
  setAllDay?: (allDay: string) => void
}

export default function AllDayRow(props: AllDayRowProps) {
  const onAllDayChange =
    (field?: ControllerRenderProps<EventDoc, any>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (props.setAllDay) props.setAllDay(event.target.checked ? '1' : '0')
    }
  return (
    <PopupSectionRow
      icon={<WeatherIcon />}
      title={`${i18n.t('allDay')}`}
      cursor="default"
      value={
        <Switch
          id="all-day-event"
          onChange={onAllDayChange(props.field)}
          checked={props.value === '1'}
        />
      }
    />
  )
}
