import { Model } from '@nozbe/watermelondb'
import { relation, text } from '@nozbe/watermelondb/decorators'

export default class ThirdPartyCalendarAccount extends Model {
  static table = 'GoogleAccount'

  @text('token') token!: string
  @text('tokenExpire') tokenExpire!: number
  @text('email') email!: string
  @text('groupId') groupId!: string
  @text('provider') provider!: string
  @text('password') password!: string
}
