import { List, ListItemButton } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { AccountDoc, EventDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import Item from './Item'
import { ComponentType, ReactNode, useRef } from 'react'
import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { makeStyles } from '@mui/styles'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { useAppUIType } from 'components/providers/AppUITypeProvider'
import { CalendarRange } from 'types/components/calendar'
import { theme } from 'theme'

interface TFMEventsListProps {
  events?: EventDoc[]
  showEvent?: string
  setShowEvent: (s: string) => void
  droppableId: string
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTouchRipple-root span': {
      backgroundColor: 'transparent !important',
      opacity: 0.3
    },
    '&:selected': {
      backgroundColor: 'transparent !important'
    }
  }
})

export default function TFMEventsList(props: TFMEventsListProps) {
  const focusOnEvent = props.events?.find((event) => {
    const uniqueId = `${event._id}${event.StartDate}`
    return (
      event._rev?.length === 0 ||
      uniqueId === LocalStorage.get(LocalStorageKey.FOCUS_EVENT)
    )
  })

  const focusId = `${focusOnEvent?.id}${focusOnEvent?.StartDate}`

  const listRef = useRef<HTMLDivElement | null>(null)

  //to scroll to recently updated item
  setTimeout(() => {
    if (focusOnEvent) {
      const listElement = listRef.current

      if (
        listElement &&
        LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) ===
          CalendarRange.LISTWEEK
      ) {
        listElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
        LocalStorage.remove(LocalStorageKey.FOCUS_EVENT)
      }
    }
  }, 250)

  return (
    <List>
      {(props.events ?? []).map((item, index) => {
        const uniqueId = `${item.id ?? item._id}${item.StartDate}`
        return (
          <Draggable
            isDragDisabled={
              item.Recurrence !== undefined &&
              item.Recurrence !== null &&
              typeof item.Recurrence !== 'string'
            }
            key={uniqueId}
            draggableId={uniqueId}
            index={index}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <ListItemButton
                  ref={
                    item._rev?.length === 0 || uniqueId === focusId
                      ? listRef
                      : undefined
                  }
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'transparent'
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: 'transparent'
                    },
                    ':hover': {
                      backgroundColor: 'transparent'
                    },
                    '& .MuiTouchRipple-root span': {
                      backgroundColor: 'transparent !important',
                      opacity: 0.3
                    },
                    padding: '0px 16px 0px 16px',
                    textAlign: 'unset !important'
                  }}
                >
                  <div style={{ width: '100%' }}>
                    {buildItem({
                      index: index,
                      item: item,
                      account: props.account,
                      userSettings: props.userSettings,
                      showEvent: props.showEvent,
                      setShowEvent: props.setShowEvent,
                      droppableId: props.droppableId
                    })}
                  </div>
                </ListItemButton>
              </div>
            )}
          </Draggable>
        )
      })}
    </List>
  )
}

interface ListItemProps {
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
  index: number
  item: EventDoc
  showEvent?: string
  setShowEvent: (s: string) => void
  droppableId: string
}

function buildItem(props: ListItemProps) {
  return (
    <div
      style={{ width: '100%', overflow: 'hidden', margin: theme.spacing(2, 0) }}
    >
      <Item
        droppableId={props.droppableId}
        item={props.item}
        setShowEvent={props.setShowEvent}
        withToggle={props.item.someday != '1'}
        account={props.account}
        userSettings={props.userSettings}
        event={props.item}
        open={
          props.showEvent === `${props.item._id}${props.item.StartDate}` ||
          props.item._rev?.length === 0
        }
        setOpen={function (b: boolean): void {}}
        onClose={() => {
          props.setShowEvent('')
        }}
      />
    </div>
  )
}
