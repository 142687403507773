import { Typography } from '@mui/material'
import { AddIcon } from 'assets/icons'
import { BusinessPlanButton } from 'components/sidebar/styled'
import dayjs from 'dayjs'
import { theme } from 'theme'
import { AccountProduct } from 'types/api/changes'
import { Tier } from 'utils/api/accounts'
import {
  BUSINESS_COLOR,
  BUSINESS_PLUS_COLOR,
  FREE_COLOR,
  PRO_COLOR
} from 'utils/colors'
import useFitText from 'use-fit-text'
import { t } from 'i18next'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'

interface TierButtonProps {
  tier: Tier
  product?: AccountProduct | null
  onClick?: () => void
}

export default function TierButton(props: TierButtonProps) {
  const handleViewPaymentPlan = () => {
    //TODO: show payments screen
  }
  function trialText(product?: AccountProduct | null) {
    if (!product || !product.Status) return ''

    const planDate = dayjs(Number(product?.ExpiryDate) * 1000).fromNow()

    switch (product.Status) {
      case 'trialing':
        return `│${t('trial')}: ${planDate}`
      case 'cancelled':
        return `│${t('expires')}: ${planDate}`
      default:
        return ''
    }
  }

  const { fontSize, ref } = useFitText({ maxFontSize: 75 })

  return (
    <BusinessPlanButton
      theme={theme}
      color={colorFromTier(props.tier)}
      onClick={props.onClick ?? handleViewPaymentPlan}
    >
      <GroupcalTooltip title={t('existingPlan')}>
        <div
          ref={ref}
          style={{
            fontSize: fontSize,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: '24px',
            whiteSpace: 'nowrap',
            width: 'fit-content',
            transform: 'translateY(1px)',
            maxWidth: '180px'
          }}
        >
          {`${titleFromTier(props.tier)}${trialText(props.product)}`}
        </div>
      </GroupcalTooltip>
    </BusinessPlanButton>
  )
}

function colorFromTier(tier: Tier): string {
  switch (tier) {
    case Tier.PRO:
      return PRO_COLOR
    case Tier.BUSINESS:
      return BUSINESS_COLOR
    case Tier.BUSINESS_PLUS:
      return BUSINESS_PLUS_COLOR
    default:
      return FREE_COLOR
  }
}

function titleFromTier(tier: Tier): string {
  switch (tier) {
    case Tier.PRO:
      return t('tierPro')
    case Tier.BUSINESS:
      return t('tierBusiness')
    case Tier.BUSINESS_PLUS:
      return t('tierBusinessPlus')
    default:
      return t('tierBasic')
  }
}
