import { EventSourceInput } from '@fullcalendar/core'
import dayjs from 'dayjs'
import {
  AccountDoc,
  AggregatedDeliveryStatus,
  DocType,
  EventDoc,
  EventRecurrence,
  EventStatus,
  Exclusion,
  GroupDoc,
  MasterLabelLabel,
  ParticipantDataDoc,
  TaskType
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { format, isBefore, unix } from 'utils/date'
import tick1 from '../../assets/icons/event-status/1tick.svg'
import tick2 from '../../assets/icons/event-status/2tick.svg'
import tick2Completed from '../../assets/icons/event-status/2tick_comp.svg'
import notsent from '../../assets/icons/event-status/not-sent.svg'

import { Frequency, Options, RRule, RRuleSet } from 'rrule'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import i18n from '../../i18n'
import Logger from 'services/Logger'
import { updateEventOnServer } from 'services/api/event'
import database from 'model'
import {
  RemoveTempEvents,
  excludeInstance,
  localEventById,
  localEventByLocalId
} from 'services/db/Queries/Event'
import Database from 'services/db/Database'
import { t } from 'i18next'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { isGroupOutOfTier, isUserCanOperateEvents } from './groups'
import { highestActiveTier } from './accounts'
import { add, isToday } from 'date-fns'
import { generateGroupcalArrayFromRecurrence } from 'components/MinuteTimer'
import {
  groupIdToProvider,
  groupToProvider,
  isConvertedGroup,
  isConvertedGroupId
} from 'utils/groups'
import { CurrentConvertedCalendars } from 'utils/google_events'
import {
  ProviderType,
  removePrefix
} from 'components/providers/CalendarSyncProdivder'
import { eventLengthInDays } from 'utils/event'
import { en } from '@fullcalendar/core/internal-common'
import { getDefaultTimezone } from 'index'
import { argv0 } from 'process'

function formatEventLastUpdate(event: EventDoc): string {
  if (!event.StartDate || event.StartDate === 'null') return ''

  return dayjs(Number(event.StartDate) * 1000).format(
    event.AllDay === '1' ? 'L' : 'L LT'
  )
}

export function eventStatusIcon(event: EventDoc): string {
  const {
    AggregatedParticipantsDeliveryStatus,
    AggregatedParticipantsConfirmationStatus
  } = event

  if (event._id?.length === 0) return notsent as unknown as string
  if (
    AggregatedParticipantsConfirmationStatus != null &&
    AggregatedParticipantsConfirmationStatus.length > 0
  )
    return tick2Completed as unknown as string

  if (AggregatedParticipantsDeliveryStatus != null) {
    if (
      Number(AggregatedParticipantsDeliveryStatus) >=
      Number(AggregatedDeliveryStatus.DELIVERED)
    ) {
      return tick2 as unknown as string
    } else {
      return tick1 as unknown as string
    }
  }

  return notsent as unknown as string
}

function isEventNotAdded(event: EventDoc): boolean {
  return `${event?.Status}` === EventStatus.HARD_DELETE_FROM_DB
}

function isEventActive(event: EventDoc): boolean {
  return `${event?.Status}` === EventStatus.ACTIVE
}

function addEventMessage(event: EventDoc, message: string): EventDoc {
  return {
    ...event,
    StartDate: String(event.StartDate),
    EndDate: String(event.EndDate),
    _message: message
  }
}

export function updateEventDocMessage(
  userID: string,
  newEvent: EventDoc,
  prevEvent: EventDoc,
  participants: Model<ParticipantDataDoc>[]
): EventDoc {
  const isCurrentUserChangedEvent = newEvent.UserID === userID
  const lastUpdatedDate = formatEventLastUpdate(newEvent)
  const eventDetails = `${lastUpdatedDate} ${newEvent.Text}`
  const userMadeAction = participants.find(
    (participant) => participant.AccountID === newEvent.UserID
  )

  let userTitleMadeChange =
    userMadeAction?.FullName ?? userMadeAction?.PhoneNumber ?? ''

  if (userTitleMadeChange === 'null') {
    userTitleMadeChange = ''
  }

  if (!prevEvent) {
    const lastUpdatedDate = formatEventLastUpdate(newEvent)
    const eventDetails = `${lastUpdatedDate} ${newEvent.Text}`

    if (newEvent.OwnerID === userID) {
      return addEventMessage(
        newEvent,
        `${i18n.t('youAddedEvent', { eventDetails })}`
      )
    } else {
      return addEventMessage(
        newEvent,
        userTitleMadeChange
          ? `${i18n.t('userAddedEvent', {
              userTitleMadeChange,
              eventDetails
            })}`
          : `${i18n.t('eventAddedGeneral', { eventDetails })}`
      )
    }
  }

  if (prevEvent.LastUpdate !== newEvent.LastUpdate) {
    if (isEventNotAdded(prevEvent) && isEventNotAdded(newEvent)) {
      return addEventMessage(
        newEvent,
        `${i18n.t('eventNotAdded', { eventDetails })}`
      )
    } else if (isEventActive(prevEvent) && !isEventActive(newEvent)) {
      if (isCurrentUserChangedEvent) {
        return addEventMessage(
          newEvent,
          `${i18n.t('youRemovedEvent', { eventDetails })}`
        )
      } else {
        return addEventMessage(
          newEvent,
          userTitleMadeChange
            ? `${i18n.t('userRemovedEvent', {
                userTitleMadeChange,
                eventDetails
              })}`
            : `${i18n.t('eventRemovedGeneral', { eventDetails })}`
        )
      }
    } else {
      const isPrevEventUpdated = isBefore(
        unix(Number(newEvent.LastUpdate)),
        unix(Number(prevEvent.LastUpdate))
      )

      if (prevEvent._rev === newEvent._rev && isPrevEventUpdated) {
        return addEventMessage(
          newEvent,
          `${i18n.t('changesNotSaved', { eventDetails })}`
        )
      } else if (isCurrentUserChangedEvent) {
        return addEventMessage(
          newEvent,
          `${i18n.t('youUpdatedEvent', { eventDetails })}`
        )
      } else {
        return addEventMessage(
          newEvent,
          userTitleMadeChange
            ? `${i18n.t('userUpdatedEvent', {
                userTitleMadeChange,
                eventDetails
              })}`
            : `${i18n.t('eventUpdatedGeneral', { eventDetails })}`
        )
      }
    }
  }

  return addEventMessage(newEvent, prevEvent._message ?? '')
}

export function buildAddEventMessage(userID: string, newEvent: EventDoc) {
  const lastUpdatedDate = formatEventLastUpdate(newEvent)
  const eventDetails = `${lastUpdatedDate} ${newEvent.Text}`

  if (newEvent.OwnerID === userID) {
    return addEventMessage(
      { ...newEvent, Status: EventStatus.ACTIVE },
      `${i18n.t('youAddedEvent', { eventDetails })}`
    )
  } else {
    return addEventMessage(
      { ...newEvent, Status: EventStatus.ACTIVE },
      `${i18n.t('eventAddedGeneral', { eventDetails })}`
    )
  }
}

export function mapEventsToCalendar(
  events: Model<EventDoc>[],
  groups: Model<GroupDoc>[],
  account: Model<AccountDoc>
): EventSourceInput {
  let itemsForUI = buildEventsArrayForUIFromDBEvents(events)

  return itemsForUI
    .filter((event) => {
      if (isConvertedGroupId(event.GroupID)) {
        return (
          event.attendees
            ?.find((attendee) => attendee.self)
            ?.responseStatus?.toLowerCase() !== 'declined'
        )
      }

      //we ignore participant status in 24me app
      if (is24meApp()) return true

      const participantStatus =
        event.ParticipantsStatus?.[
          LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        ] ?? undefined

      return participantStatus?.ParticipantConfirmationStatus != '3'
    })
    .filter((event) => event.Status !== EventStatus.REMOVED)
    .map((event) => {
      let startDate = dayjs(Number(event.StartDate) * 1000).tz(
        getDefaultTimezone()
      )
      const isAllDay = event.AllDay === '1' || event.late

      let endDate = dayjs(Number(event.EndDate) * 1000).tz(getDefaultTimezone())

      if (isAllDay) {
        startDate = dayjs(Number(event.StartDate) * 1000).tz('UTC')
        endDate = dayjs(Number(event.EndDate) * 1000)
          // in order full calendar correctly show items on a screen, we need to add extra days for all day events
          // in case of group events (and icloud events which follow group events logic, we add 2 extra days)
          // do not forget to remove this additional days in Event instance processing
          .add(
            (event._id?.length ?? 0) > 0 &&
              (event.Type === DocType.EVENT ||
                groupIdToProvider(event.GroupID ?? '') === ProviderType.APPLE)
              ? 2
              : 1,
            'day'
          )
          .startOf('day')
          .tz('UTC')
      }

      let rrule: string | undefined = undefined

      try {
        rrule = mapRecurrency(event, true)
      } catch (e) {
        console.error(e)
      }

      if (rrule?.length == 0) {
        rrule = undefined
      }

      const group = groups?.find((group) => group._id === event.GroupID)

      const outOfTierGroup = isGroupOutOfTier(
        highestActiveTier(account),
        group,
        account
      )

      let canEdit = isUserCanOperateEvents(
        group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      )

      if (group && isConvertedGroup(group)) {
        canEdit = removePrefix(group._id).includes(event.OwnerID ?? '')

        if (event.Organizer) {
          canEdit = event.Organizer.email === removePrefix(group._id)
        }
      }

      const minutes = endDate.diff(startDate, 'minutes')

      const eventLen = eventLengthInDays(event)

      const data = {
        end: endDate.format(isAllDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'),
        id: `${event.late}${event._id}`,
        title: event.Text,
        start: startDate.format(
          isAllDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
        ),
        allDay: isAllDay || event.late,
        durationEditable:
          (event._id?.length ?? 0) > 0 &&
          event.someday != '1' &&
          canEdit &&
          !outOfTierGroup &&
          (event.GroupID === LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ||
            !LocalStorage.get(LocalStorageKey.SELECTED_GROUP)),
        editable:
          canEdit &&
          !outOfTierGroup &&
          (event.GroupID === LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ||
            !LocalStorage.get(LocalStorageKey.SELECTED_GROUP)),
        eventModel: event,
        rrule: event.late ? undefined : rrule,
        duration: {
          days: rrule && isAllDay ? (eventLen === 0 ? 1 : eventLen) : undefined,
          minutes: rrule && isAllDay ? undefined : minutes > 0 ? minutes : 20
        }
      }

      return data
    })
}
/**
 * converts groupcal recurrence to the rfc5545 rrule, see {@link https://www.rfc-editor.org/rfc/rfc5545#section-3.3.10}
 * {@link https://www.rfc-editor.org/rfc/rfc5545#section-3.8.5}
 * @param event 'groupcal' model of the event
 * @param keepDTStart flag which determines DTSTART field of RRULE
 * @returns string representation of a 'groupcal' recurrence
 */
export function mapRecurrency(
  event: EventDoc,
  keepDTStart: boolean,
  tz?: string,
  ignoreExclusions = false
): string {
  let options: Partial<Options> | undefined = undefined
  const recurrence: EventRecurrence | undefined = event.Recurrence
  const rruleSet: RRuleSet = new RRuleSet()

  let timeZone =
    event.TimeZoneNameID?.length != 0
      ? event.TimeZoneNameID
      : getDefaultTimezone()

  if (recurrence && recurrence.Interval) {
    options = {
      interval: recurrence.Interval
    }

    if (recurrence.Unit === 5) {
      options.byweekday = RRule.FR.nth(recurrence.Interval)
    }

    if (recurrence.Unit) {
      options.freq = groupCalFrequencyToGoogle(Number(recurrence.Unit))
    }

    rruleSet.rrule(new RRule(options))

    let rruleForEvent = rruleSet.toString()

    const eventStart = dayjs.unix(Number(event.StartDate)).tz(timeZone)

    if (keepDTStart) {
      /**
       * In order to preserve original start time of recurring event between daylight savings, we need to set
       * DTSTART and UNTIL manually, so event will not jump in hours on view
       */
      const validStart = `DTSTART:${eventStart.format('YYYYMMDDTHHmmss')}\n\r`

      rruleForEvent = rruleForEvent.padStart(
        rruleForEvent.length + validStart.length,
        validStart
      )
    }

    if (recurrence?.EndDate && !isNaN(+recurrence?.EndDate)) {
      const validEnd = `;UNTIL=${dayjs
        .unix(Number(recurrence?.EndDate))
        .format('YYYYMMDDTHHmmss')}${keepDTStart ? '' : 'Z'}`

      rruleForEvent = rruleForEvent.padEnd(
        rruleForEvent.length + validEnd.length,
        validEnd
      )
    }
    if (
      event?.Recurrence?.Exclusion &&
      Array.isArray(event?.Recurrence?.Exclusion) &&
      !ignoreExclusions
    ) {
      event.Recurrence?.Exclusion?.forEach((excl: Exclusion) => {
        try {
          if (
            !isNaN(Number(excl.Date)) &&
            excl.Status !== EventStatus.COMPLETED
          ) {
            rruleForEvent = `${rruleForEvent}\n\rEXDATE:${dayjs
              .unix(Number(excl.Date))
              .set('hour', eventStart.hour())
              .set('minute', eventStart.minute())
              .format('YYYYMMDDTHHmmss')}`
          }
        } catch (e) {
          console.error(e)
        }
      })
    }
    return rruleForEvent
  }

  return rruleSet.toString()
}

export function groupCalFrequencyToGoogle(freq: Number): Frequency {
  switch (freq) {
    case 1:
      return Frequency.DAILY
    case 2:
      return Frequency.WEEKLY
    case 3:
      return Frequency.MONTHLY
    case 4:
      return Frequency.YEARLY
  }

  return Frequency.DAILY
}

export async function handleEventUpdate(
  event: Model<EventDoc>,
  data: EventDoc,
  shouldSaveLocally: boolean,
  isDirty: boolean
): Promise<void> {
  /**
   * This field determines, if an event should be updated locally only, without touching the server
   * For example, we save reminders only locally
   */

  Logger.blue('EVENT_UPDATE', event, data, shouldSaveLocally, isDirty)
  const assignFields =
    (ignoreRecurrence: boolean, data: EventDoc) =>
    (localEvent: Model<EventDoc>) => {
      const now = Date.now().valueOf() / 1000
      localEvent.LastUpdate = String(now)
      localEvent.RequestConfirmation = data.RSVP

      //in case of ignore recurrence, we remove it from data object
      if (ignoreRecurrence) {
        delete data['Recurrence']
      }

      Object.keys(data).forEach((key) => {
        if (key !== 'id') localEvent[key] = data[key]
      })
    }

  localEventByLocalId(event.id).then(async (localEvent) => {
    if (shouldSaveLocally) {
      await database.write(async () => {
        await localEvent.update(assignFields(false, data))
      })
    } else {
      if (
        isDirty ||
        data.Status === EventStatus.DELETED ||
        data.attendees
          ?.find((attendee) => attendee.self)
          ?.responseStatus?.toLowerCase() === 'declined'
      ) {
        await database
          .write(async () => {
            if (groupIdToProvider(data.GroupID ?? '') !== ProviderType.GOOGLE) {
              await localEvent.update(assignFields(false, data))
            } else {
              await Promise.resolve()
            }
          })
          .then(() => {
            return updateEventOnServer(event, data)
          })
          .then(() => {
            if (
              data.attendees
                ?.find((attendee) => attendee.self)
                ?.responseStatus?.toLowerCase() === 'declined'
            ) {
              return Database.write(async () => {
                return event.destroyPermanently()
              })
            }
          })
      }
    }
  })
}

export async function excludeInstanceFromRecurrence(
  data: EventDoc,
  event: Model<EventDoc> | EventDoc,
  instanceStart: number,
  instanceEnd: number
) {
  LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
  const excluded = await excludeInstance(
    instanceStart,
    await localEventById(event._id ?? ''),
    isGroupcalApp()
      ? EventStatus.REMOVED
      : data.Status === EventStatus.ACTIVE
      ? EventStatus.DELETED
      : data.Status ?? EventStatus.DELETED
  )

  Logger.debug('excluded', excluded)

  await updateEventOnServer(excluded, {
    ...data,
    Recurrence: excluded.Recurrence,
    Status: EventStatus.ACTIVE, // to keep original series state
    StartDate: excluded.StartDate, // to keep original start series
    EndDate: excluded.EndDate, // to keep original start series
    Text: excluded.Text // to keep original start series
  })
}

export function isLate(event: EventDoc, ignoreRecurrence?: boolean) {
  if (!event || !event.StartDate || event.someday === '1') return false

  if (
    ignoreRecurrence &&
    event.Recurrence &&
    typeof event.Recurrence !== 'string'
  ) {
    return false
  }

  const now = dayjs()

  return (
    event.EndDate !== undefined &&
    dayjs(Number(event.EndDate) * 1000).isBefore(now.startOf('day'))
  )
}

export function isSomeDay(event: EventDoc) {
  if (!event) return false

  return (
    event.StartDate === 'null' ||
    event.StartDate === '' ||
    event.someday === '1'
  )
}

export function prepareTasksForUI(
  events: EventDoc[] | undefined,
  masterLabelLabel?: MasterLabelLabel,
  showingDocType?: DocType
): EventDoc[] {
  if (!events) return []
  const filtered = events
    .filter((event) => {
      return event.TaskType != TaskType.birthday
    })
    .filter((event) => {
      if (!masterLabelLabel) return true // return all items

      if (event.Label && typeof event.Label !== 'string') {
        return event.Label.some(
          (ele) =>
            ele.LabelText === masterLabelLabel?.ID &&
            event.Type === showingDocType
        )
      }
    })

  const compositeSomedayTask = makeCompositeSomedayTask(filtered)

  return compositeSomedayTask
    ? [
        ...filtered.filter((event) => {
          return event.someday != '1'
        }),
        compositeSomedayTask
      ]
    : filtered
}

export function makeCompositeSomedayTask(events: EventDoc[]) {
  const somedayTask = events.filter((event) => {
    return (
      event.someday === '1' ||
      (event.StartDate === 'null' && event.EndDate === 'null')
    )
  })

  let compositeSomedayTask: Model<EventDoc> | EventDoc | undefined = undefined

  if (somedayTask && somedayTask.length > 0 && is24meApp()) {
    let start = dayjs().tz('UTC').startOf('day')
    let end = start.add(1, 'second')

    if (LocalStorage.get(LocalStorageKey.SOMEDAY_DATE)) {
      start = dayjs.unix(Number(LocalStorage.get(LocalStorageKey.SOMEDAY_DATE)))
      end = start.add(1, 'second')
    }

    compositeSomedayTask = {
      ...somedayTask[0],
      ...Database.toServerObject(somedayTask[0]),
      Text: t('somedayTask', { amount: somedayTask.length }) ?? '',
      Label: undefined,
      someday: '1',
      AllDay: LocalStorage.get(LocalStorageKey.SOMEDAY_ALL_DAY)
        ? LocalStorage.get(LocalStorageKey.SOMEDAY_ALL_DAY) ?? '0'
        : '1',
      Status: EventStatus.ACTIVE,
      Priority: '0',
      StartDate: start.unix().toString(),
      EndDate: end.unix().toString(),
      TaskType: TaskType.meet,
      Type: DocType.TASK,
      _id: `s${somedayTask[0]._id}`
    }
  }
  return compositeSomedayTask
}

export function generateLateTasksForEvents(events: EventDoc[]): EventDoc[] {
  const late = events
    .filter((event) => isLate(event) && event.Type === DocType.TASK)
    .map((event) => {
      return createLateFromEvent(event)
    })

  return late
}

export function createLateFromEvent(event: EventDoc): EventDoc {
  const now = dayjs().tz('UTC')

  const eventStart = dayjs(Number(event.StartDate) * 1000)
  const eventEnd = dayjs(Number(event.EndDate) * 1000)
  const duration = eventEnd.diff(eventStart)
  const lateStart = now.startOf('day')

  return {
    ...event,
    ...Database.toServerObject(event),
    StartDate: lateStart.unix().toString(),
    EndDate: lateStart.add(1, 'second').unix().toString(),
    TimeZoneNameID: 'UTC',
    late: true,
    lateOriginalStartTime: event.StartDate,
    lateOriginalEndTime: event.EndDate
  }
}

function generateLateRecurrentInstances(arg0: Model<EventDoc>[] | EventDoc[]) {
  const relevantItems = arg0.filter(
    (event) =>
      event.Type === DocType.TASK &&
      event.Recurrence &&
      typeof event.Recurrence !== 'string' &&
      isLate(event) &&
      (event._id?.length ?? 0 > 0)
  )

  let lateInstances: any = []

  // convert this map to a for loop

  for (let i = 0; i < relevantItems.length; i++) {
    const event = relevantItems[i]
    const parsedItems = generateGroupcalArrayFromRecurrence(event, true)

    if (parsedItems.some((event) => isToday(Number(event.StartDate) * 1000)))
      continue

    const filteredItems = parsedItems.filter((item) => isLate(item))
    const reversedItems = filteredItems.reverse()
    const uniqueItems = reversedItems.filter(
      (item: EventDoc, index: number, self: EventDoc[]) =>
        index === self.findIndex((event: EventDoc) => event._id === item._id)
    )
    const instance = uniqueItems[0]
    if (
      instance &&
      (instance.Status === EventStatus.ACTIVE ||
        instance.Status === EventStatus.COMPLETED)
    )
      lateInstances.push(createLateFromEvent(instance))
    else continue
  }
  return lateInstances
}

export function buildEventsArrayForUIFromDBEvents(events: Model<EventDoc>[]) {
  let itemsForUI = is24meApp() ? prepareTasksForUI(events) : events

  if (is24meApp()) {
    itemsForUI = [
      ...itemsForUI,
      ...generateLateTasksForEvents(
        itemsForUI.filter(
          (event) => isLate(event, true) && (event._id?.length ?? 0 > 0)
        )
      ),
      ...generateLateRecurrentInstances(itemsForUI)
    ]
  }

  return itemsForUI
}

export function buildEventsArrayForUIFromDBEventsWithRecurrentEvents(
  events: EventDoc[]
) {
  let itemsForUI: EventDoc[] = []

  prepareTasksForUI(events).forEach((event) => {
    const parsedItems = generateGroupcalArrayFromRecurrence(event)

    itemsForUI.push(...parsedItems)
  })

  if (is24meApp()) {
    itemsForUI = [
      ...itemsForUI,
      ...generateLateTasksForEvents(
        itemsForUI.filter(
          (event) => isLate(event, true) && (event._id?.length ?? 0 > 0)
        )
      ),
      ...generateLateRecurrentInstances(events)
    ]
  }

  return itemsForUI
}

export const eventComparator = (o1: EventDoc, o2: EventDoc): number => {
  const event1AllDay = o1.AllDay === '1' || o1.late ? '1' : '0'
  const event2AllDay = o2.AllDay === '1' || o2.late ? '1' : '0'

  return (
    // Compare by status
    Number(o1.Status) - Number(o2.Status) ||
    // Compare by allDay
    Number(event2AllDay) - Number(event1AllDay) ||
    // Compare by start time
    (o1.lateOriginalStartTime ?? o1.StartDate ?? '').localeCompare(
      o2.lateOriginalEndTime ?? o2.StartDate ?? ''
    ) ||
    // Compare by rank
    (o1.Rank ?? '').localeCompare(o2.Rank ?? '')
  )
}

export function saveEventToLocalDB(event: EventDoc) {
  return RemoveTempEvents().then(() => {
    return Database.write(async () => {
      return Database.getCollection<EventDoc>(CollectionType.EVENT).create(
        (localEvent) => {
          Object.keys(event).forEach((key) => {
            if (key !== 'id') localEvent[key] = event[key]
          })
        }
      )
    })
  })
}
