import {
  AccountDoc,
  EventDoc,
  MasterLabelDoc,
  MasterLabelLabel
} from 'types/api/changes'
import { Tier, highestActiveTier } from './api/accounts'
import { Model } from 'types/model'

export const defaultTaskColor = '#000'

export function provideTaskColor(
  masterLabel: MasterLabelDoc | undefined,
  item: EventDoc,
  accountDoc?: Model<AccountDoc> | AccountDoc
): string {
  const activeTier = highestActiveTier(accountDoc)
  if (activeTier === Tier.FREE) return defaultTaskColor
  if (
    !masterLabel ||
    !item.Label ||
    item.Label?.length === 0 ||
    typeof item.Label === 'string'
  )
    return defaultTaskColor

  // search for a first existing label in a master label

  let found: MasterLabelLabel | undefined = undefined

  if (item.Label?.length > 0) {
    for (let i = 0; i < item.Label.length; i++) {
      const itemLabel = item.Label[i]
      found = masterLabel.Labels?.find(
        (label) => label.ID === itemLabel.LabelText
      )
      if (found) break
    }
  }

  if (found?.Color) {
    const converted = convertLabelColorToStringColor(found.Color)
    return converted ?? defaultTaskColor
  }

  return defaultTaskColor
}

export function provideLabelName(
  masterLabel: MasterLabelDoc | undefined,
  item: EventDoc
): string {
  if (
    !masterLabel ||
    !item.Label ||
    item.Label?.length === 0 ||
    typeof item.Label === 'string'
  )
    return ''
  const found = masterLabel.Labels?.find(
    (label) => label.ID === item.Label?.[0].LabelText
  )

  if (found?.Text) {
    return found.Text
  }

  return ''
}

export function convertLabelColorToStringColor(
  colorString: string
): string | undefined {
  if (
    !colorString ||
    colorString === 'null' ||
    colorString.length === 0 ||
    !colorString.includes('{')
  )
    return defaultTaskColor

  if (colorString == '{0.000, 0.000, 0.000, 0.000}') {
    return defaultTaskColor
  }

  // Remove curly braces and any whitespace from the string
  const cleanedString = colorString.replace(/[{} ]/g, '')

  // Split the string into individual values
  const colorValues = cleanedString.split(',')

  // Parse the values and convert them to the React TypeScript color format
  const red = parseFloat(colorValues[0].trim())
  const green = parseFloat(colorValues[1].trim())
  const blue = parseFloat(colorValues[2].trim())

  // Check if the parsed values are valid
  if (isNaN(red) || isNaN(green) || isNaN(blue)) {
    return defaultTaskColor
  }

  // Convert the RGB values to the React TypeScript color string
  const color = `rgb(${Number(red * 255)}, ${Number(green * 255)}, ${Number(
    blue * 255
  )})`

  return color
}

export function isLabelInsideMasterLabel(
  masterLabel?: MasterLabelDoc,
  labelId?: string
) {
  if (!labelId || !masterLabel) return false

  return masterLabel?.Labels?.some((label) => label.ID === labelId)
}
