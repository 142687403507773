import { ClickAwayListener } from '@mui/material'
import Calendar from 'components/calendar/Calendar'
import AppContainer from 'components/containers/AppContainer'
import Sidebar from 'components/sidebar/Sidebar'
import { useEffect } from 'react'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { isTestEnv } from 'utils/env'
import AppUITypeProvider from './providers/AppUITypeProvider'
import { useAppSettings } from './providers/AppSettingsProvider'
import Logger from 'services/Logger'

export default function Main() {
  const copyToClipVer = async () => {
    await navigator.clipboard.writeText(
      `${process.env.APP_VERSION}-${process.env.REACT_APP_APP_TYPE}`
    )
  }

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (
        event.key === LocalStorageKey.USER_ID &&
        (event.newValue || !event.newValue) &&
        document.visibilityState !== 'visible'
      ) {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const appSettings = useAppSettings()

  Logger.debug('App settings', appSettings)

  return (
    <>
      {isTestEnv() &&
      LocalStorage.get(LocalStorageKey.SHOW_DEV_INFO) === '1' ? (
        <div
          onClick={copyToClipVer}
          style={{
            position: 'absolute',
            fontSize: '12px',
            marginLeft: 24,
            marginTop: 2
          }}
        >
          {process.env.APP_VERSION}-{process.env.REACT_APP_APP_TYPE}
        </div>
      ) : (
        <></>
      )}
      <AppUITypeProvider>
        <AppContainer>
          <Sidebar />
          <div style={{ width: '100%' }}>
            <Calendar />
          </div>
        </AppContainer>
      </AppUITypeProvider>
    </>
  )
}
