import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

export interface CountdownButtonProps {
  onClick: () => void
  buildTitle: (seconds: number) => string
}

export const CountdownButton = (props: CountdownButtonProps) => {
  const [seconds, setSeconds] = useState(
    LocalStorage.get(LocalStorageKey.CODE_TIME_SECONDS)
      ? Number(LocalStorage.get(LocalStorageKey.CODE_TIME_SECONDS))
      : 30
  )
  const [resendCount, setResendCount] = useState(0)

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
        LocalStorage.set(LocalStorageKey.CODE_TIME_SECONDS, seconds)
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [seconds])

  const handleResendClick = () => {
    setResendCount((prevCount) => prevCount + 1)
    setSeconds(resendCount === 0 ? 60 : 120)
    if (props.onClick) props.onClick()
  }

  return (
    <Typography
      sx={{
        pointerEvents: seconds > 0 ? 'none' : 'all',
        fontSize: '13px',
        padding: 2,
        fontFamily: 'NotoSans',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5'
        }
      }}
      onClick={handleResendClick}
    >
      {props.buildTitle(seconds)}
    </Typography>
  )
}
