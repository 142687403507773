import { Grid } from '@mui/material'
import { CardHeader } from './CardHeader'

import Logger from 'services/Logger'
// import { CompareToBuilder } from 'apache-commons-lang' // Assuming you have the CompareToBuilder available

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import TFMEventsList from './TFMEventsList'
import {
  AccountDoc,
  DocType,
  EventDoc,
  EventStatus,
  MasterLabelLabel,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import { t } from 'i18next'
import dayjs, { Dayjs } from 'dayjs'
import { isDateBetween } from 'utils/date'
import { eventComparator, isLate } from 'utils/api/events'
import { updateEventOnServer } from 'services/api/event'
import Database from 'services/db/Database'

import './styles.scss'
import { localEventById, RemoveTempEvents } from 'services/db/Queries/Event'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { addTempTaskToDate } from './TFMEventsView'
import { useAppUIType } from 'components/providers/AppUITypeProvider'
import { useEffect, useState } from 'react'
import { isToday } from 'date-fns'
import OffsetFromBottomDivider from 'components/dividers/OffsetFromBottomDivider'

export enum DroppableColumns {
  today = 'today',
  tomorrow = 'tomorrow',
  later = 'later'
}

interface TFMEventsColumnsProps {
  type: DocType
  events?: Model<EventDoc>[] | EventDoc[]
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
}

export default function TFMEventsColumns(props: TFMEventsColumnsProps) {
  const today = dayjs().startOf('day')
  const tomorrow = today.add(1, 'day')
  const later = today.add(2, 'day')
  const formatForDates = 'dddd, MMM DD'
  const appUiTypeContext = useAppUIType()

  let todayBucket = props.events?.filter(
    (event) =>
      isDateBetween(dayjs(Number(event.StartDate) * 1000), today, tomorrow) ||
      isLate(event, true)
  )

  for (const event of props.events
    ?.filter(
      (event) => event.Recurrence && typeof event.Recurrence !== 'string'
    )
    .slice()
    .reverse() ?? []) {
    if (
      (isToday(Number(event.StartDate) * 1000) || isLate(event)) &&
      !todayBucket?.find((todayEvent) => event._id === todayEvent._id)
    ) {
      todayBucket?.push(event)
    }
  }

  todayBucket = todayBucket
    ?.sort(eventComparator)
    .map((event) => {
      // Create a new object with the same properties as the original event
      const updatedEvent: EventDoc = {
        ...Database.toServerObject(event),
        lateOriginalStartTime: event.StartDate,
        lateOriginalEndTime: event.EndDate,
        id: event.id,
        SupplementaryGroupsIDs: event.SupplementaryGroupsIDs
      }
      // Update the 'late' field to a new value
      updatedEvent.late = isLate(updatedEvent)
      // Return the updated event object
      return updatedEvent
    })
    .filter((event) => event.Status !== EventStatus.REMOVED)
  const tomorrowBucket = props.events
    ?.filter((event) =>
      isDateBetween(dayjs(Number(event.StartDate) * 1000), tomorrow, later)
    )
    .sort(eventComparator)

  const laterBucket = props.events
    ?.filter((event) => dayjs(Number(event.StartDate) * 1000).isAfter(later))
    .filter(
      (item, index, self) => index === self.findIndex((i) => i._id === item._id)
    )
    .sort(eventComparator)

  const onDragEnd = (result: DropResult) => {
    // Logic to handle the item reorder
    // You can update your data structure here based on the result of the drag and drop
    LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)

    Logger.blue('dnd', `result of drop `)
    Logger.blue('dnd', result)

    let event = props.events?.find(
      (event) => `${event.id}${event.StartDate}` === result.draggableId
    )

    //for pending events we are using inner db 'id' field
    if (!event) {
      event = props.events?.find((event) => event.id === result.draggableId)
    }

    Logger.blue('dnd', event)

    const newPosition = result.destination?.index
    Logger.blue('dnd:position', newPosition)
    if (newPosition != undefined && newPosition >= 0) {
      //define events which might be above and below our dropped event
      let prevEventPosition = newPosition - 1
      let nextEventPosition = newPosition

      let prevEvent: EventDoc | undefined
      let nextEvent: EventDoc | undefined

      Logger.blue('dnd:prevposition', prevEventPosition)
      Logger.blue('dnd:nextposition', nextEventPosition)

      let bucket: EventDoc[] | undefined = todayBucket

      switch (result.destination?.droppableId as DroppableColumns) {
        case DroppableColumns.today:
          bucket = todayBucket
          break

        case DroppableColumns.later:
          bucket = laterBucket
          break

        case DroppableColumns.tomorrow:
          bucket = tomorrowBucket

          break

        default:
          break
      }

      prevEvent = bucket?.at(prevEventPosition)
      nextEvent = bucket?.at(nextEventPosition)

      Logger.blue('dnd:bucket', bucket)
      Logger.blue('dnd:prev', prevEvent)
      Logger.blue('dnd:next', nextEvent)

      let newStartDate: Dayjs | undefined = undefined

      if (result.destination) {
        let rank = event?.Rank
        const duration = event
          ? Number(event.EndDate) - Number(event.StartDate)
          : 1000 * 60
        const eventStart = event
          ? dayjs(Number(event.StartDate) * 1000)
          : dayjs()
        let allDay = event ? event.AllDay : '0'

        if (prevEvent || nextEvent) {
          if (newPosition === 0 && nextEvent) {
            rank = (Number(nextEvent?.Rank) - 100).toString()
            allDay = nextEvent.AllDay
            newStartDate = dayjs.unix(Number(nextEvent.StartDate))
          } else if (newPosition === (bucket?.length ?? 0) - 1) {
            //we dropped to the end of the list
            rank = (Number(nextEvent?.Rank) + 100).toString()
            allDay = nextEvent?.AllDay
            newStartDate = dayjs.unix(Number(nextEvent?.StartDate))
          } else if (prevEvent && nextEvent) {
            //we dropped inbetween
            if (prevEvent.StartDate === nextEvent.StartDate) {
              //we dropped inbetween
              rank = (
                (Number(nextEvent.Rank) + Number(prevEvent.Rank)) /
                2
              ).toString()
            } else {
              rank = (Number(prevEvent.Rank) + 100).toString()
            }

            newStartDate = dayjs.unix(Number(prevEvent?.StartDate))
            allDay = prevEvent.AllDay
          } else if (prevEvent && !nextEvent) {
            rank = (Number(prevEvent.Rank) + 100).toString()
            allDay = prevEvent.AllDay
            newStartDate = dayjs.unix(Number(prevEvent?.StartDate))
          }
        }

        if (!newStartDate) {
          switch (result.destination?.droppableId as DroppableColumns) {
            case DroppableColumns.today:
              newStartDate = dayjs(today)
                .hour(eventStart.hour())
                .minute(eventStart.minute())
              break
            case DroppableColumns.tomorrow:
              {
                newStartDate = dayjs(tomorrow)
                  .hour(eventStart.hour())
                  .minute(eventStart.minute())
              }
              break
            case DroppableColumns.later:
              newStartDate = dayjs(later)
                .hour(eventStart.hour())
                .minute(eventStart.minute())
              break
          }
        }

        if (!event || event.someday === '1') {
          LocalStorage.set(
            LocalStorageKey.SOMEDAY_DATE,
            newStartDate.unix().toString()
          )
          LocalStorage.set(LocalStorageKey.SOMEDAY_ALL_DAY, allDay)
          return
        }

        if (event !== undefined && event._id && event._rev) {
          Database.write(async () => {
            if (event !== undefined && event._id && event._rev)
              (await localEventById(event._id ?? '')).update((local) => {
                local.StartDate = newStartDate?.unix().toString()
                local.EndDate = newStartDate
                  ?.add(duration, 'second')
                  .unix()
                  .toString()
                local.Rank = rank
              })
          }).then(() => {
            if (event && event._id && event._rev) {
              updateEventOnServer(event, {
                StartDate: newStartDate?.unix().toString(),
                EndDate: newStartDate
                  ?.add(duration, 'second')
                  .unix()
                  .toString(),
                Rank: rank,
                SupplementaryGroupsIDs: []
              })
            }
          })
        }
      }
    }
  }

  const [showEvent, setShowEvent] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (showEvent) {
      LocalStorage.set(LocalStorageKey.VISIBLE_EVENT, showEvent)
    } else {
      LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
    }
  }, [showEvent])

  return (
    <DragDropContext
      onDragStart={() => {
        LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')
      }}
      onDragEnd={onDragEnd}
    >
      <Grid
        container
        flexWrap={'nowrap'}
        style={{
          height: '100%'
        }}
      >
        {buildColumn({
          title: t('today'),
          subTitle: today.format(formatForDates),
          events: todayBucket,
          droppableId: DroppableColumns.today,
          type: props.type,
          userSettings: props.userSettings,
          account: props.account,
          label: appUiTypeContext.selectedLabel,
          showEvent: showEvent,
          setShowEvent: setShowEvent
        })}
        {buildColumn({
          title: t('tomorrow'),
          events: tomorrowBucket?.filter(
            (event) =>
              event.Status === EventStatus.ACTIVE ||
              event.Status === EventStatus.COMPLETED
          ),
          subTitle: tomorrow.format(formatForDates),
          droppableId: DroppableColumns.tomorrow,
          showDividers: true,
          type: props.type,
          userSettings: props.userSettings,
          account: props.account,
          label: appUiTypeContext.selectedLabel,
          showEvent: showEvent,
          setShowEvent: setShowEvent
        })}
        {buildColumn({
          title: t('later'),
          events: laterBucket?.filter(
            (event) =>
              event.Status === EventStatus.ACTIVE ||
              event.Status === EventStatus.COMPLETED
          ),
          subTitle: later.format(formatForDates),
          droppableId: DroppableColumns.later,
          type: props.type,
          userSettings: props.userSettings,
          account: props.account,
          label: appUiTypeContext.selectedLabel,
          showEvent: showEvent,
          setShowEvent: setShowEvent
        })}
      </Grid>
    </DragDropContext>
  )
}

interface ColumnProps {
  title: string
  subTitle: string
  droppableId: string
  type: DocType
  events?: EventDoc[]
  showDividers?: boolean
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
  label?: MasterLabelLabel
  showEvent?: string
  setShowEvent: (s?: string) => void
}

function buildColumn(props: ColumnProps) {
  const columnClick = () => {
    if (!LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)) {
      let date = dayjs()
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)

      switch (props.droppableId) {
        case DroppableColumns.today:
          date = dayjs()
            .add(1, 'hour')
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
          break
        case DroppableColumns.tomorrow:
          date = date.add(1, 'day')
          break
        case DroppableColumns.later:
          date = date.add(2, 'day')
          break
      }
      LocalStorage.set(LocalStorageKey.VISIBLE_EVENT, date.unix())

      addTempTaskToDate(
        date.unix(),
        props.userSettings,
        props.type,
        props.label
      )
    } else {
      RemoveTempEvents()
      LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
      props.setShowEvent(undefined)
    }
  }

  return (
    <Grid item xs={4}>
      <Droppable droppableId={props.droppableId}>
        {(provided) => (
          <Grid
            style={{
              position: 'relative',
              height: '100%',
              flex: 1
            }}
          >
            <CardHeader title={props.title} subtitle={props.subTitle} />
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                height: '100%',
                width: '100%'
              }}
              onClick={columnClick}
            >
              <div
                className="scrollable-content"
                style={{
                  position: 'absolute',
                  top: 90,
                  bottom: 90,
                  left: 4,
                  overflow: 'auto',
                  right: 4
                }}
              >
                <TFMEventsList
                  userSettings={props.userSettings}
                  account={props.account}
                  droppableId={props.droppableId}
                  events={props.events}
                  showEvent={props.showEvent}
                  setShowEvent={props.setShowEvent}
                />
              </div>

              {provided.placeholder}
            </div>
            <OffsetFromBottomDivider />
          </Grid>
        )}
      </Droppable>
    </Grid>
  )
}
