export enum AppType {
  twentyfourme = 'twentyfourme',
  groupcal = 'groupcal'
}

export function is24meApp(): boolean {
  return process.env.REACT_APP_APP_TYPE === AppType.twentyfourme
}

export function isGroupcalApp(): boolean {
  return process.env.REACT_APP_APP_TYPE === AppType.groupcal
}
