import { Tooltip, TooltipProps } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactElement, ReactNode, useState } from 'react'

interface GroupcalTooltipProps {
  children: ReactElement | ReactNode
  title: ReactNode
  delay?: number
  color?: string
  enterDelay?: number
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  backgroundColor?: string
  textColor?: string
  textSize?: string
  enterNextDelay?: number
  hidden?: boolean
}

export default function GroupcalTooltip(props: GroupcalTooltipProps) {
  // State to control tooltip visibility
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <Tooltip
        leaveDelay={200}
        enterDelay={props.enterDelay ?? 700}
        enterNextDelay={props.enterNextDelay ?? 1000}
        placement={props.placement ?? 'bottom'}
        title={props.title}
        PopperProps={{
          style: {
            zIndex: 9999,
            visibility: isTooltipVisible && !props.hidden ? 'visible' : 'hidden'
          }
        }}
        componentsProps={{
          tooltip: {
            style: {
              lineHeight: '22px',
              letterSpacing: '0.5px',
              backgroundColor:
                props.backgroundColor || 'default-background-color',
              color: props.textColor || 'default-text-color',
              fontSize: props.textSize || 'default-text-size',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px'
            }
          }
        }}
      >
        {/* This div now controls the visibility of the tooltip */}
        <div>{props.children}</div>
      </Tooltip>
    </div>
  )
}
