import { Box, Grid } from '@mui/material'
import { ReactNode, useState } from 'react'
import * as Styled from '../styled'
import { PopupInstance } from 'components/popup/Popup'
import { EventReminder } from 'types/api/changes'
import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import CustomEventReminderPopup, {
  CustomReminderContent
} from './CustomEventRecurrencePopup'
import { t } from 'i18next'
import i18n from 'i18n'

interface ContentProps {
  instance: PopupInstance | null | undefined
  reminders: EventReminder[]
  setReminders: (reminders: EventReminder[]) => void
}

const humanizeDuration = require('humanize-duration')

export const defaultReminders: EventReminder[] = [
  {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: 0
  },
  {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: convertMinutesToSeconds(5)
  },
  {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: convertMinutesToSeconds(10)
  },
  {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: convertMinutesToSeconds(15)
  },
  {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: convertMinutesToSeconds(30)
  }
]

export function convertMinutesToSeconds(minutes: number) {
  return minutes * 60
}

function Content(props: ContentProps) {
  const addReminderToEvent = (reminder: EventReminder) => {
    if (
      props.reminders == undefined ||
      !props.reminders.find((rem) => rem.offset === reminder.offset)
    ) {
      props.setReminders([...(props.reminders ?? []), reminder])
    }

    props.instance?.hide()
  }

  const [showCustom, setShowCustom] = useState(false)

  return showCustom ? (
    <CustomReminderContent {...props} />
  ) : (
    <Grid container alignItems={'start'}>
      <PopupSection title={`${t('remindMe')}`}>
        <Box height={8}></Box>
        {defaultReminders
          .filter((n) => {
            if (!props.reminders) {
              return true
            }
            return props.reminders?.includes(n) == false
          })
          .map((rem) => {
            return (
              <PopupSectionRow
                action={() => {
                  addReminderToEvent(rem)
                }}
                key={rem.offset}
                title={
                  rem.offset == 0
                    ? t('onTime')
                    : `${t('timeBefore', {
                        time: minutesToHumanReadable(rem.offset / 60)
                      })}`
                }
              />
            )
          })}
        <div>
          <PopupSectionRow
            action={() => {
              setShowCustom(true)
            }}
            title={t('custom')}
          />
        </div>
      </PopupSection>
    </Grid>
  )
}

interface AddReminderPopupProps {
  children: ReactNode
  open: boolean
  setOpen: (b: boolean) => void
  reminders: EventReminder[]
  setReminders: (reminders: EventReminder[]) => void
}

export default function AddReminderPopup(props: AddReminderPopupProps) {
  const [instance, onInstance] = useState<PopupInstance>()
  return (
    <Styled.DatePickPopup
      // open={props.open}
      // setOpen={props.setOpen}
      onMount={onInstance}
      content={<Content {...props} instance={instance} />}
      placement={'bottom'}
    >
      {props.children}
    </Styled.DatePickPopup>
  )
}

export function minutesToHumanReadable(seconds: number) {
  return humanizeDuration(seconds * 60 * 1000, {
    language:
      i18n.language.length > 2 ? i18n.language.slice(0, 2) : i18n.language
  })
}
