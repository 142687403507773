import LocalStorage from 'services/LocalStorage'
import { GroupDoc } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'

export function isOwnerForGroup(group: GroupDoc | undefined): boolean {
  if (!group) return false
  return LocalStorage.get(LocalStorageKey.USER_ID) === group.OwnerID
}

export function isUserLeft(group: GroupDoc, userId: string | null): boolean {
  if (group == null) return false
  for (
    let index = 0;
    index < Object.keys(group.Participants ?? {}).length;
    index++
  ) {
    const participantId = Object.keys(group.Participants)[index]
    const participant = group.Participants[participantId]

    if (
      participantId === userId &&
      participant.Status === '2' &&
      participant.RemovedBy == userId
    )
      return true
  }
  return false
}

export function isUserRemoved(userId: string, group: GroupDoc): boolean {
  if (group == null) return false
  for (let index = 0; index < Object.keys(group.Participants).length; index++) {
    const participantId = Object.keys(group.Participants)[index]
    const participant = group.Participants[participantId]

    if (
      participantId === userId &&
      participant.Status === '2' &&
      participant.RemovedBy
    )
      return true
  }
  return false
}
