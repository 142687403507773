import { SwitchUnstyled } from '@mui/base'
import { useAddGroup } from 'components/popups/addGroup/AddGroupProvider'
import { ChangeEvent, ChangeEventHandler } from 'react'
import * as Styled from './styled'

import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'

import createCache from '@emotion/cache'
import i18n from 'i18n'
import { CacheProvider } from '@emotion/react'

interface SwitchProps {
  onChange?: ChangeEventHandler
  checked?: boolean
  id?: string
}

export default function Switch(props: SwitchProps) {
  const cacheRtl = createCache({
    key: i18n.dir() === 'rtl' ? 'cssrtl' : 'cssltr',
    stylisPlugins: [prefixer, rtlPlugin]
  })
  return i18n.dir() === 'rtl' ? (
    <CacheProvider value={cacheRtl}>
      <Styled.Switch
        id={props.id}
        checked={props.checked ?? false}
        onChange={props.onChange}
      />
    </CacheProvider>
  ) : (
    <Styled.Switch
      id={props.id}
      checked={props.checked ?? false}
      onChange={props.onChange}
    />
  )
}
