import { CloseIcon, RefreshIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import RecurrencePopup from 'components/popups/eventInfo/RecurrencePopup'
import dayjs from 'dayjs'
import isEqual from 'lodash.isequal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from 'theme'
import { EventRecurrence, UserSettingsDoc } from 'types/api/changes'
import i18n from '../../i18n'
import { IEventDetails } from 'hooks/useEventDetails'

export interface EventRecurrenceProps {
  recurrence: EventRecurrence | undefined
  setEventRecurrence: (recurrence: EventRecurrence) => void
  eventHook: IEventDetails
  userSettings?: UserSettingsDoc
}

export default function EventRecurrenceComponent(props: EventRecurrenceProps) {
  const { t } = useTranslation()
  const humanReadable = parseEventRecurrenceToHumanReadable(props.recurrence)
  const hasActiveRecurrence = humanReadable && humanReadable.length > 0
  const recurrenceString = hasActiveRecurrence
    ? humanReadable
    : t('addRecurrence')
  const [recurrencePopupOpen, setRecurrencePopupOpen] = useState(false)

  const removeRecurrence = () => {
    props.setEventRecurrence({})
  }
  return (
    <RecurrencePopup
      {...props}
      open={recurrencePopupOpen}
      setOpen={setRecurrencePopupOpen}
    >
      <span>
        <PopupSectionRow
          actionId="add-recurrence"
          action={() => {
            setRecurrencePopupOpen(true)
          }}
          icon={<RefreshIcon />}
          title={recurrenceString}
          titleColor={
            hasActiveRecurrence ? undefined : theme.palette.input.placeholder
          }
          value={
            humanReadable ? (
              <IconButton size="xs" onClick={removeRecurrence}>
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )
          }
        />
      </span>
    </RecurrencePopup>
  )
}
function parseEventRecurrenceToHumanReadable(
  recurrence?: EventRecurrence
): string | null {
  if (!recurrence || isEqual(recurrence, {}) || typeof recurrence === 'string')
    return null

  let unit = ''
  let interval = ''
  let until = ''

  if (recurrence?.Interval) {
    if (recurrence.Interval > 1) interval = String(recurrence.Interval)
  }

  const intervalForUnit = Number(interval.length > 0 ? interval : '1')

  if (recurrence?.Unit) {
    switch (Number(recurrence?.Unit)) {
      case 1:
        unit = i18n.t('day', { count: intervalForUnit }).toLowerCase()
        break
      case 2:
        unit = i18n.t('week', { count: intervalForUnit }).toLowerCase()
        break
      case 3:
        unit = i18n.t('month', { count: intervalForUnit }).toLowerCase()
        break
      case 4:
        unit = i18n.t('year', { count: intervalForUnit }).toLowerCase()
        break
    }
  }

  if (recurrence?.EndDate) {
    const untilDate = dayjs(Number(recurrence?.EndDate) * 1000).format('L')
    until = `; ${i18n.t('untilDate', { untilDate })}`
  }

  const everyValue = `${interval} ${unit}${until}`
  return i18n.t('repeatEvery', { everyValue })
}
