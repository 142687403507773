import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/translation.json'
import ru from './locales/ru/translation.json'
import uk from './locales/uk/translation.json'
import fr from './locales/fr/translation.json'
import de from './locales/de/translation.json'
import he from './locales/he/translation.json'
import pt from './locales/pt/translation.json'
import es from './locales/es/translation.json'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import dayjs from 'dayjs'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const supportedLangs = { en, ru, uk, fr, es, de, pt, he }

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      ...supportedLangs,
      'en-GB': en
    }
  })

//in order to dayjs to work we need require all langs as well
require('dayjs/locale/en')
require('dayjs/locale/en-gb')
require('dayjs/locale/ru')
require('dayjs/locale/uk')
require('dayjs/locale/nl')
require('dayjs/locale/fr')
require('dayjs/locale/de')
require('dayjs/locale/he')
require('dayjs/locale/it')
require('dayjs/locale/ja')
require('dayjs/locale/ko')
require('dayjs/locale/pt')
require('dayjs/locale/es')
require('dayjs/locale/tr')
require('dayjs/locale/zh')
require('dayjs/locale/zh-tw')

dayjs.locale(i18n.language)

export default i18n
