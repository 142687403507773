import './index.scss'

import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './i18n'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import config from 'components/providers/outlook/config'

import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult
} from '@azure/msal-browser'

import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat) // use the plugin
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(dayOfYear)

// Set the global timezone
let globalTimezone = dayjs.tz.guess()
dayjs.tz.setDefault(globalTimezone)

// Function to set the default timezone
export const setDefaultTimezone = (timezone: string) => {
  globalTimezone = timezone
  dayjs.tz.setDefault(globalTimezone)
  LocalStorage.set(LocalStorageKey.TIMEZONE, timezone)
}

// Function to get the current default timezone
export const getDefaultTimezone = () => {
  return LocalStorage.get(LocalStorageKey.TIMEZONE) || globalTimezone
}

// <MsalInstanceSnippet>
export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
})

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts()
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = event.payload as AuthenticationResult
    msalInstance.setActiveAccount(authResult.account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App pca={msalInstance} />)

//init pwa
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
