import { LandlineCheckProvider } from 'components/providers/LandlineCheckProvider'
import { PaymentProvider } from 'components/providers/PaymentProvider'
import { ReactNode } from 'react'
import { use100vh } from 'react-div-100vh'

import * as Styled from './styled'

interface AppContainerProps {
  children: ReactNode
}

export default function AppContainer(props: AppContainerProps) {
  const height = use100vh()
  return (
    <Styled.AppContainer container style={{ height: height as number }}>
      <PaymentProvider>
        <LandlineCheckProvider>{props.children}</LandlineCheckProvider>
      </PaymentProvider>
    </Styled.AppContainer>
  )
}
