import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from '../styled'
import { t } from 'i18next'
import { AccountDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { Grid } from '@mui/material'
import { MenuAddCalendarIcon } from 'assets/icons/menu'
import SettingsSection from './SettingsSection'

import HelpCenterSection from './HelpCenterSection'
import AccountSection from './AccountSection'
import { useCalendarSync } from 'components/providers/CalendarSyncProdivder'

export const days = [
  { id: '1', title: t('sunday'), idPrefix: 'first-day-' },
  { id: '2', title: t('monday'), idPrefix: 'first-day-' }
]

interface UserMenuPopupProps {
  open: boolean
  setOpen: (b: boolean) => void
  connectGoogleCalendar: () => void
  children: ReactNode
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
  connectOutlookCalendar: () => void
}

function Content(props: UserMenuPopupProps) {
  const { t, i18n } = useTranslation()
  const calendarSync = useCalendarSync()
  return (
    <Grid flexDirection={'column'} display={'flex'}>
      <PopupSection>
        <PopupSectionRow
          actionId="add-calendar-accounts-button"
          title={t('addYourCalendarAccounts')}
          iconClass="icon-regular"
          maxWidthIcon={20}
          icon={<MenuAddCalendarIcon />}
          action={() => {
            props.setOpen(false)
            if (calendarSync.showProviderPicker)
              calendarSync.showProviderPicker()
          }}
        />
      </PopupSection>
      <SettingsSection
        userSettings={props.userSettings}
        setMenuOpen={props.setOpen}
      />
      <HelpCenterSection setPopupOpen={props.setOpen} />
      <AccountSection
        account={props.account}
        userSettings={props.userSettings}
      />
    </Grid>
  )
}

export default function UserMenuPopup(props: UserMenuPopupProps) {
  return (
    <Styled.UserMenuPopup
      open={props.open}
      setOpen={props.setOpen}
      placement="right"
      content={<Content {...props} />}
    >
      {props.children}
    </Styled.UserMenuPopup>
  )
}
