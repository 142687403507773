import 'react-phone-input-2/lib/style.css'
import './styles.scss'

import { Box, Grid, Typography } from '@mui/material'
import Analytics, {
  EventCategory,
  EventProperties,
  Events
} from 'analytics/Analytics'
import {
  OnboardingIcon,
  OnboardingIcon24me,
  onboardingPic,
  onboardingPic24me
} from 'assets/img'

import Card from 'components/cards/Card'
import { LoadingSpinner } from 'components/containers/styled'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { useCalendar } from 'components/providers/CalendarProvider'
import { useConnection } from 'components/providers/ConnectionProvider'
import { FirebaseError } from 'firebase/app'
import useTabActive from 'hooks/useTabActive'
import i18n from 'i18n'
import { startsWith } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import PinInput from 'react-pin-input'
import { cacheAndLogin, login, signUp } from 'services/api/auth'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { theme } from 'theme'
import { CalendarRange } from 'types/components/calendar'
import { LocalStorageKey } from 'types/services/localStorage'
import { APP_COLOR } from 'utils/colors'
import {
  generateRecaptcha,
  verifyPhoneNumber,
  verifyPinCode
} from 'utils/firebase'
import { userLang } from 'utils/lang'

import { BlockContainer, MiscText } from './styled'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import TwentyfourLoginInputs from './TwentyfourLoginInputs'
import Button from 'components/buttons/Button'
import { isValidEmail } from 'components/lists/ParticipantList'
import { GENERIC_ERROR } from 'utils/strings'
import { AxiosError } from 'axios'
import { forgotPassword } from 'services/api/account'
import PhoneTypePicker from './PhoneTypePicker'
import { CountdownButton } from './CountdownButton'
import { requestSMSFromCheckmobi, validatePin } from 'services/api/checkmobi'
import { CheckmobiValidationRequest } from 'types/api/CheckmobiValidationRequest'
import { CodeProvider, useAuth } from 'components/providers/AuthProvider'
import { Code } from '@mui/icons-material'
import { CheckmobiCheckPinRequest } from 'types/api/CheckmobiCheckPinRequest'
import { DocPhoneNumberType } from 'utils/auth'
import dayjs from 'dayjs'

export enum PhoneType {
  Landline = 'Landline',
  Mobile = 'Mobile'
}

export enum UISTate {
  REGULAR,
  FORGOT_PASSWORD,
  DONE_FORGOT_PASSWORD
}

export interface CountryData {
  name: string
  dialCode: string
  countryCode: string
  format: string
}

interface LoginPageProps {
  onAuth: (b: boolean) => void
  isAuth: boolean
}

export default function LoginPage(props: LoginPageProps) {
  const [generateCaptcha, onGenerateCaptcha] = useState(false)
  const [showCode, onShowCode] = useState(false)
  const [phone, setPhone] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('')

  useEffect(() => {
    Analytics.getInstance().sendEvent(Events.FIRST_SCREEN)
    Analytics.getInstance().sendEvent(Events.PAGE_LOAD_ENTER_PHONE)
  }, [props.isAuth])

  return (
    <Box
      className="login-container"
      onClick={() => {
        if (showCode) pinInput?.focus()
      }}
    >
      <Card className="content-card">
        <Grid
          height={'100%'}
          container
          direction={'row'}
          display={'flex'}
          wrap={'nowrap'}
          justifyContent={'space-evenly'}
          bgcolor={'white'}
          className="blocks-container"
        >
          <Box
            height={'100%'}
            width={'100%'}
            justifyContent={'end'}
            className="login-panel"
          >
            <BlockContainer
              display={'flex'}
              wrap={'nowrap'}
              theme={theme}
              loading={0}
              className="auth-block"
            >
              {showCode ? (
                <CodeInputBlock
                  countryCode={countryCode}
                  onGenerateCaptcha={onGenerateCaptcha}
                  phone={phone}
                  showCode={onShowCode}
                  {...props}
                />
              ) : (
                <PhoneInputBlock
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  onGenerateCaptcha={onGenerateCaptcha}
                  onShowCode={onShowCode}
                  phone={phone}
                  setPhone={setPhone}
                  {...props}
                />
              )}
              {generateCaptcha && <div id="recaptcha" />}
            </BlockContainer>
          </Box>
          <Box height={'100%'} width={'100%'} className="hero">
            <img
              src={is24meApp() ? onboardingPic24me : onboardingPic}
              alt="Today"
              className="hero-bg"
            />
            <div className="hero-text">
              <Typography className="title">
                {is24meApp() ? i18n.t('tfmTitle') : i18n.t('sharedCalendarFor')}
              </Typography>
              <Typography className="subtitle">
                {is24meApp()
                  ? i18n.t('tfmDesc')
                  : i18n.t('sharingCalendarsWith')}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Card>
    </Box>
  )
}

interface CodeInputBlockProps {
  phone: string
  onAuth: (b: boolean) => void
  showCode: (b: boolean) => void
  onGenerateCaptcha: (b: boolean) => void
  countryCode: string
}

let pinInput: PinInput | null = null

function CodeInputBlock(props: CodeInputBlockProps) {
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  const [loading, setLoading] = useState<number>(0)
  const [errorText, setErrorText] = useState<string | undefined>(' ')
  const connection = useConnection()
  const tabActive = useTabActive()
  const calendar = useCalendar()

  const checkSMSCode = async (pin: string) => {
    setLoading(1)
    setErrorText(' ')
    if (!navigator.onLine) {
      connection.setNoInternetVisible(true)
    } else {
      try {
        let token
        let userPhone

        if (auth.codeProvider === CodeProvider.Firebase) {
          const [firebaseToken, phone] = await verifyPinCode(pin)
          userPhone = phone
          token = firebaseToken
        } else if (auth.codeProvider === CodeProvider.Checkmobi) {
          const data: CheckmobiCheckPinRequest = {
            id: LocalStorage.get(LocalStorageKey.CHECKMOBI_TOKEN) ?? '',
            pin: pin
          }
          await validatePin(data).then((response) => {
            userPhone = `${response.number}`
            token = LocalStorage.get(LocalStorageKey.CHECKMOBI_TOKEN)
          })
        }

        logPhoneAnalytics(
          EventCategory.CODE_VERIF,
          Events.PHONE_VERIF_CORRECT,
          auth.phoneType,
          props.countryCode,
          props.phone
        )

        //after successful code validation, we reset lastupdate
        LocalStorage.remove(LocalStorageKey.LAST_UPDATE)

        if (userPhone && token) {
          try {
            await signUp(
              userPhone,
              token,
              undefined,
              undefined,
              undefined,
              auth.phoneType === PhoneType.Landline
                ? DocPhoneNumberType.LANDLINE
                : DocPhoneNumberType.MOBILE
            ).then(() => {
              props.onAuth(true)
              Analytics.getInstance().sendEvent(Events.LOGIN_SUCCESS)
              LocalStorage.set(
                LocalStorageKey.PHONE_NUMBER_TYPE,
                auth.phoneType === PhoneType.Landline
                  ? DocPhoneNumberType.LANDLINE
                  : DocPhoneNumberType.MOBILE
              )
              calendar.onView(CalendarRange.WEEK)
            })
          } catch (e) {
            Analytics.getInstance().sendEvent(Events.LOGIN_ERROR)
            Logger.red('error during signup-login', e)
            setErrorText(`${t('oopsError')}`)
          }
        }
      } catch (e) {
        if (e instanceof FirebaseError) {
          console.log(`code ${e.code}; msg ${e.message}`)
          let errorText = ''
          if (e.code === 'auth/invalid-verification-code') {
            errorText = t('smsCodeWrong')
            logPhoneAnalytics(
              EventCategory.CODE_VERIF,
              Events.PHONE_VERIF_INCORRECT,
              auth.phoneType,
              props.countryCode,
              props.phone
            )
          }

          setErrorText(errorText)
        }

        pinInput?.clear()
        pinInput?.focus()
      }
    }

    setLoading(0)
  }

  const onCodeEntered = async (value: string) => {
    await checkSMSCode(value)
  }

  const onCodeChange = () => {
    setErrorText(' ')
  }

  useEffect(() => {
    if (tabActive) focusPin()
  }, [tabActive])

  const focusPin = () => {
    pinInput?.focus()
  }

  const onGoBack = () => {
    props.showCode(false)
    props.onGenerateCaptcha(false)
  }

  const parsedNum = parsePhoneNumber(`+${props.phone}`)
  const resendTitle =
    auth.phoneType === PhoneType.Landline ? t('recall') : t('resend')

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <BlockContainer
        theme={theme}
        container
        className="code-input-block"
        loading={loading}
        onClick={focusPin}
      >
        {is24meApp() ? <OnboardingIcon24me /> : <OnboardingIcon />}
        <Grid
          container
          flexDirection={'row'}
          justifyContent={'start'}
          columnSpacing={2}
        >
          <Grid item>
            <MiscText theme={theme} className="entered-phone">
              &#x202D;
              {`${parsedNum?.formatInternational()}`}
            </MiscText>
          </Grid>
          <Grid item>
            <MiscText
              theme={theme}
              sx={{ cursor: 'pointer' }}
              color={theme.palette.button.text.groupcal}
              onClick={onGoBack}
              className="edit-phone"
            >
              {`(${t('edit')})`}
            </MiscText>
          </Grid>
        </Grid>
        <MiscText theme={theme} className="entered-phone">
          {t('enterCode')}
        </MiscText>
        <PinInput
          length={6}
          ref={(n) => (pinInput = n)}
          initialValue=""
          onComplete={onCodeEntered}
          onChange={onCodeChange}
          type="numeric"
          inputMode="number"
          style={{
            display: 'flex',
            direction: 'ltr'
          }}
          inputStyle={{
            fontSize: 18,
            borderColor: 'white',
            borderWidth: '2px',
            borderStyle: 'solid',
            padding: '1px',
            borderRadius: 12,
            backgroundColor: theme.palette.background.default
          }}
          inputFocusStyle={{
            borderColor: APP_COLOR,
            borderWidth: '2px',
            borderStyle: 'solid'
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </BlockContainer>
      <Grid
        maxWidth={324}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        {errorText != ' ' ? (
          <MiscText theme={theme} color={'red'}>
            {errorText}
          </MiscText>
        ) : (
          <span>&nbsp;</span>
        )}
        <LoadingSpinner loading={loading} sx={{ position: 'inherit' }} />
        <CountdownButton
          buildTitle={(seconds) => {
            return seconds > 0
              ? t('resendInType', {
                  redo: resendTitle,
                  inMinutes: dayjs(seconds * 1000).format('mm:ss')
                })
              : resendTitle
          }}
          onClick={function (): void {
            auth.onCodeProvider(CodeProvider.Checkmobi)
            const data: CheckmobiValidationRequest = {
              number: `+${props.phone}`,
              type: auth.phoneType === PhoneType.Landline ? 'ivr' : 'sms',
              platform: 'web'
            }
            requestSMSFromCheckmobi(data)
          }}
        />
      </Grid>
    </Box>
  )
}

interface PhoneInputBlockProps {
  phone: string
  setPhone: (phone: string) => void
  onShowCode: (b: boolean) => void
  onGenerateCaptcha: (b: boolean) => void
  countryCode: string
  setCountryCode: (s: string) => void
  onAuth: (b: boolean) => void
}

function PhoneInputBlock(props: PhoneInputBlockProps) {
  const { t, i18n } = useTranslation()
  const tabActive = useTabActive()
  const [submitEnabled, setIsValidPhoneNum] = useState(
    isValidPhoneNumber(`+${props.phone}`)
  )
  const [errorText, setErrorText] = useState<string | undefined>(' ')
  const [loading, setLoading] = useState<number>(0)
  const [sureDialog, setSureDialog] = useState(false)
  const connection = useConnection()
  const requestSMSCode = () => {
    if (!navigator.onLine) connection.setNoInternetVisible(true)
    else if (submitEnabled) setSureDialog(true)
  }

  const checkPhoneValidity = (
    inputNumber: string,
    country: object,
    countries: object[]
  ) => {
    return countries.some((country) => {
      return (
        startsWith(inputNumber, (country as CountryData).dialCode) ||
        startsWith((country as CountryData).dialCode, inputNumber)
      )
    })
  }

  const [showLogin, setShowLogin] = useState<boolean>(false)
  const auth = useAuth()
  const [name, onName] = useState<string>('')
  const [email, onEmail] = useState<string>('')
  const [password, onPassword] = useState<string>('')
  const [uiState, onUIState] = useState<UISTate>(UISTate.REGULAR)

  const tfmFieldsFilled =
    uiState === UISTate.DONE_FORGOT_PASSWORD ||
    ((name || showLogin) &&
      email &&
      isValidEmail(email) &&
      (password || uiState === UISTate.FORGOT_PASSWORD))

  const onPhoneNumberChange = (value: string, data: CountryData) => {
    const withPlus = `+${value}`
    props.setCountryCode(data.dialCode)
    props.setPhone(value)
    setErrorText(' ')
    setIsValidPhoneNum(isValidPhoneNumber(withPlus))
    phoneInputRef.current?.focus()
  }

  async function processPhoneNumber() {
    generateRecaptcha()
    try {
      logPhoneAnalytics(
        EventCategory.PHONE_NUMBER,
        Events.PHONE_VERIF_REQUEST_SENT,
        auth.phoneType,
        props.countryCode,
        props.phone
      )

      if (auth.phoneType === PhoneType.Landline) {
        auth.onCodeProvider(CodeProvider.Checkmobi)
        const checkmobiRequest: CheckmobiValidationRequest = {
          number: `+${props.phone}`,
          type: 'ivr',
          platform: 'web'
        }
        await requestSMSFromCheckmobi(checkmobiRequest).then((response) => {
          LocalStorage.set(LocalStorageKey.PHONE_NUMBER, `+${props.phone}`)
          setLoading(0)
        })
      } else {
        await verifyPhoneNumber(`+${props.phone}`)
          .then(() => {
            setLoading(0)
          })
          .catch((e) => {
            console.error(e)
            LocalStorage.remove(LocalStorageKey.PHONE_NUMBER)
            const errorText = t('oopsError')
            setErrorText(errorText)
            auth.onCodeProvider(CodeProvider.Checkmobi)
            const checkmobiRequest: CheckmobiValidationRequest = {
              number: `+${props.phone}`,
              type: 'sms',
              platform: 'web'
            }
            requestSMSFromCheckmobi(checkmobiRequest).then(() => {
              setLoading(0)
            })
          })
      }
    } catch (e) {
      console.error(e)
      LocalStorage.remove(LocalStorageKey.PHONE_NUMBER)
      const errorText = t('oopsError')
      setLoading(0)
      setErrorText(errorText)
      Analytics.getInstance().sendEvent(Events.PHONE_VERIF_REQ_ERROR_FIREBASE, {
        [EventProperties.CATEGORY]: EventCategory.PHONE_NUMBER,
        [EventProperties.PHONE_NUMBER]: props.phone,
        [EventProperties.ERROR]: e
      })
    }
    if (LocalStorage.get(LocalStorageKey.PHONE_NUMBER)) {
      props.onGenerateCaptcha(false)
      props.onShowCode(true)
      logPhoneAnalytics(
        EventCategory.PHONE_NUMBER,
        Events.PHONE_VERIF_REQ_DONE,
        auth.phoneType,
        props.countryCode,
        props.phone
      )
      logPhoneAnalytics(
        EventCategory.CODE_VERIF,
        Events.PAGE_LOAD_VERIF,
        auth.phoneType,
        props.countryCode,
        props.phone
      )
    }
  }

  const goToPinVerify = () => {
    setSureDialog(false)
    props.onGenerateCaptcha(true)
    setLoading(1)
    setErrorText(' ')
    //in order to sync captcha, because we do not use unmounting in login process, we need a delay
    setTimeout(() => {
      processPhoneNumber()
    }, 500)
  }

  const closeSureDialog = () => {
    setSureDialog(false)
  }

  const phoneInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (tabActive) phoneInputRef?.current?.focus()
  }, [tabActive])

  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' && (submitEnabled || tfmFieldsFilled)) {
        if (isGroupcalApp()) {
          if (!sureDialog) {
            event.preventDefault()
            requestSMSCode()
          } else {
            closeSureDialog()
            goToPinVerify()
          }
        } else {
          onTFMLogin()
        }
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [submitEnabled, sureDialog, tfmFieldsFilled, uiState])

  const parsedNum = parsePhoneNumber(`+${props.phone}`)

  const calendar = useCalendar()

  const onTFMLogin = async () => {
    setLoading(1)
    if (uiState === UISTate.REGULAR) {
      try {
        if (showLogin) {
          try {
            await cacheAndLogin(email, password, true).then((response) => {
              console.log(response)

              if (response instanceof AxiosError) {
                setErrorText(`${t('loginFailed')}`)
              } else {
                if (response) {
                  props.onAuth(true)
                  Analytics.getInstance().sendEvent(Events.LOGIN_SUCCESS)
                  calendar.onView(CalendarRange.WEEK)
                } else {
                  setErrorText(GENERIC_ERROR)
                }
              }

              setLoading(0)
            })
          } catch (e) {
            setErrorText(`${t('loginFailed')}`)
          }
        } else {
          try {
            await signUp(undefined, undefined, email, password, {
              FirstName: name,
              FullName: name,
              LastName: 'null'
            }).then((response) => {
              Logger.blue('TFM login', response)
              if (response instanceof AxiosError) {
                if (
                  response.response &&
                  response.response?.data['errorCode'] === -300
                ) {
                  setErrorText(`${t('signupFailed')}`)
                } else {
                  setErrorText(GENERIC_ERROR)
                }
              } else if (response) {
                props.onAuth(true)
                Analytics.getInstance().sendEvent(Events.LOGIN_SUCCESS)
                calendar.onView(CalendarRange.WEEK)
              } else {
                setErrorText(GENERIC_ERROR)
              }

              setLoading(0)
            })
          } catch (e) {
            setErrorText(GENERIC_ERROR)
          }
        }
      } catch (e) {
        setLoading(0)
        setErrorText(GENERIC_ERROR)
      }
    } else if (uiState === UISTate.FORGOT_PASSWORD) {
      forgotPassword(email).then(() => {
        setTimeout(() => {
          onUIState(UISTate.DONE_FORGOT_PASSWORD)
          setLoading(0)
        }, 1500)
      })
    } else if (uiState === UISTate.DONE_FORGOT_PASSWORD) {
      onUIState(UISTate.REGULAR)
      setLoading(0)
    }
  }

  let miscText = is24meApp()
    ? showLogin
      ? t('loginToYourAccount')
      : t('enterYourName')
    : auth.phoneType === PhoneType.Landline
    ? t('landlineDesc')
    : t('mobileDesc')

  if (uiState === UISTate.FORGOT_PASSWORD) {
    miscText = t('forgotPasswordDesc')
  } else if (uiState === UISTate.DONE_FORGOT_PASSWORD) {
    miscText = t('doneForgotPasswordDesc')
  }

  return (
    <BlockContainer
      theme={theme}
      container
      className="phone-input-block"
      loading={loading}
    >
      {is24meApp() ? <OnboardingIcon24me /> : <OnboardingIcon />}
      <Typography
        fontWeight={700}
        fontSize={'52px'}
        lineHeight={'34px'}
        fontStyle={'normal'}
      >
        {is24meApp() ? t('appName24me') : t('appName')}
      </Typography>
      <Box width={'100%'} className="description">
        <MiscText
          theme={theme}
          width={'100%'}
          fontWeight={'400 !important'}
          textAlign={'start'}
          fontSize={'16px !important'}
          color={'rgba(0,0,0,0.7)!important'}
        >
          {miscText}
        </MiscText>
      </Box>
      {is24meApp() ? (
        <TwentyfourLoginInputs
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          name={name}
          password={password}
          email={email}
          onName={onName}
          onEmail={onEmail}
          onPassword={onPassword}
          onTFMLogin={onTFMLogin}
          setErrorText={setErrorText}
          onUIState={onUIState}
          uiState={uiState}
        />
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            direction: 'ltr',
            position: 'relative',
            margin: 'auto',
            maxWidth: '300px',
            height: '48px'
          }}
        >
          <PhoneTypePicker />
          <PhoneInput
            enableSearch
            placeholder={String(t('phoneNumberPlaceholder'))}
            inputProps={{ ref: phoneInputRef }}
            inputStyle={{
              direction: 'ltr',
              border: '1px solid #B9B9B9',
              width: '250px',

              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: '25px',
              borderBottomRightRadius: '25px',
              height: '48px'
            }}
            buttonClass="flag-dropwdown"
            country={userLang()}
            masks={{
              ua: '.. ... .. ..'
            }}
            value={props.phone}
            onChange={onPhoneNumberChange}
            isValid={checkPhoneValidity}
          />
        </Box>
      )}
      <Grid
        item
        container
        justifyContent={'center'}
        display={'flex'}
        marginTop={errorText === ' ' ? -8 : 0}
        alignItems={'center'}
        flexDirection={'column'}
        gap={'0.2rem'}
      >
        {errorText != ' ' ? (
          <MiscText theme={theme} color={'red'}>
            {errorText}
          </MiscText>
        ) : (
          <MiscText theme={theme} color={'red'}>
            &nbsp;
          </MiscText>
        )}
        {loading === 1 ? (
          <LoadingSpinner
            loading={1}
            sx={{
              position: 'inherit',
              height: 48,
              top: '0px !important',
              right: '0px !important'
            }}
          />
        ) : (
          <Button
            id="loginButton"
            size="md"
            fontWeight="700 !important"
            onClick={is24meApp() ? onTFMLogin : requestSMSCode}
            disabled={is24meApp() ? !tfmFieldsFilled : !submitEnabled}
          >
            {uiState === UISTate.FORGOT_PASSWORD && t('resetPassword')}
            {uiState === UISTate.REGULAR &&
              (is24meApp()
                ? showLogin
                  ? t('login')
                  : t('createAccount')
                : auth.phoneType === PhoneType.Landline
                ? t('receiveACall')
                : t('sendSmsButton'))}
            {uiState === UISTate.DONE_FORGOT_PASSWORD && t('backToLogin')}
          </Button>
        )}
      </Grid>
      {sureDialog && (
        <ConfirmationDialog
          title={String(t('numberConfirmation'))}
          leftButtonColor={'black'}
          leftButton={String(t('edit'))}
          rightButton={String(t('yes'))}
          content={
            <div style={{ textAlign: 'center' }}>
              &#x202D;
              {`${parsedNum?.formatInternational()}`}
              <br />
              {`${t('phoneNumberCorrect')}`}
            </div>
          }
          handleLeftButton={closeSureDialog}
          open={sureDialog}
          handleRightButton={goToPinVerify}
          handleClose={closeSureDialog}
        />
      )}
    </BlockContainer>
  )
}

/**
 * In order to show correct num we need to remove zero, because we show it on country code
 * @param num phone number to remove zero from
 * @returns num without phone number
 */
export function removeZeroIfNeeded(num: string | null | undefined) {
  if (!num) return ''
  if (num.startsWith('0')) return num.substring(1, num.length)
  return num
}

function logPhoneAnalytics(
  categoty: string,
  eventName: Events,
  type: PhoneType,
  countryCode: string,
  phoneNumber: string
) {
  Analytics.getInstance().sendEvent(eventName, {
    [EventProperties.CATEGORY]: categoty,
    [EventProperties.SELECTED_PHONE_TYPE]: type,
    [EventProperties.COUNTRY_CODE]: countryCode,
    [EventProperties.PHONE_NUMBER]: phoneNumber
  })
}
