import { lighten, Grid } from '@mui/material'
import { darken, styled, Theme } from '@mui/material/styles'
import Button from 'components/buttons/Button'
import Card from 'components/cards/Card'
import Input from 'components/form/Input'

import Avatar from '../badges/Avatar'
import MultiAccountBadge from '../badges/MultiAccountBadge'

export const Sidebar = styled(Grid)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}))

export const Header = styled(Card)(({ theme }) => ({
  padding: '0.75rem 1.25rem',

  marginBottom: theme.spacing(5)
}))

export const HeaderContent = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

export const HeaderAvatar = styled(Avatar)(() => ({
  cursor: 'pointer'
}))

export const Groups = styled(Card)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  paddingBottom: 0
}))

export const AddGroupButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  right: 16,
  zIndex: 10,

  '& svg': {
    width: 32,
    height: 32,
    marginRight: 0
  }
}))

interface BusinessPlanProps {
  theme: Theme
  color: string
}

export const BusinessPlanButton = styled(Button)(
  ({ theme, color }: BusinessPlanProps) => ({
    height: 'fit-content',
    width: 'fit-content',
    background: color,
    marginTop: '4px',
    color: '#fff',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    zIndex: 10,
    '& svg': {
      width: 24,
      height: 24,
      marginRight: theme.spacing(2)
    },
    '&:hover': {
      backgroundColor: lighten(color, 0.2)
    }
  })
)

export const AllCalendarButton = styled(Button)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  borderColor: theme.palette.border.primary,
  justifyContent: 'flex-start',
  padding: theme.spacing(6, 1)
}))

export const AllCalendarContainer = styled(Grid)(({ theme }) => ({
  height: 52,
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  padding: theme.spacing(0, 2, 0, 4),
  color: theme.palette.button.text.primary,
  backgroundColor: theme.palette.button.background.primary,
  border: '1px solid #e4e4e4',
  borderRadius: '2.8125rem',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  fontFamily: 'NotoSans',
  '&:hover': {
    backgroundColor: darken(
      theme.palette.button.background.primary as string,
      0.02
    )
  }
}))

export const AllCalendarBadge = styled(MultiAccountBadge)(({ theme }) => ({}))

export const SearchInput = styled(Input)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}))

export const GroupList = styled(Grid)(() => ({
  flex: 1,
  position: 'relative'
}))

export const GroupListScrollContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: 'calc(100% + 2.5rem)',
  height: '100%',
  borderRadius: '0 0 20px 20px'
}))

export const GroupListScroll = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  borderRadius: '0 0 20px 20px',
  paddingBottom: theme.spacing(5)
}))
