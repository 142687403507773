import { capitalize } from '@mui/material'
import withObservables from '@nozbe/with-observables'
import { CalendarBackIcon, CaretDownIcon, GroupChannelIcon } from 'assets/icons'
import ContainerWithBottomBadgeIcon from 'components/containers/ContainerWithBottomBadgeIcon'
import GroupPopup from 'components/popups/groupInfo/GroupPopup'
import GroupTitle from 'components/popups/groupInfo/GroupTitle'
import { useCalendar } from 'components/providers/CalendarProvider'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import ObserveService from 'services/db/Observe'
import { isUserLeft } from 'services/db/utils/GroupUtils'
import LocalStorage from 'services/LocalStorage'
import { AccountDoc, GroupDoc } from 'types/api/changes'
import { CalendarRange } from 'types/components/calendar'
import { Option } from 'types/components/form'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { highestActiveTier } from 'utils/api/accounts'
import {
  defaultGroupImage,
  getActiveParticipants,
  groupsToImages,
  isGroupChannel,
  isGroupOutOfTier,
  isUserActiveInGroup
} from 'utils/api/groups'
import calendarBack from '../../assets/icons/calendar-back.svg'
import NamesStrip from './NamesStrip'
import * as Styled from './styled'
import { t } from 'i18next'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import Logger from 'services/Logger'
import i18n from 'i18n'
import { SEARCH_MODE } from 'components/popups/searchItems/SearchPopup'
import SearchPopupButton from 'components/SearchPopupButton'
import { is24meApp } from 'utils/appType'

export const RANGE: Option[] = [
  { label: capitalize(t('day_one')), value: CalendarRange.DAY },
  { label: capitalize(t('week_one')), value: CalendarRange.WEEK },
  { label: capitalize(t('month_one')), value: CalendarRange.MONTH },
  { label: capitalize(t('list')), value: CalendarRange.LISTWEEK }
]

export const dateFormat = 'YYYY-MM-DD'

interface CalendarHeaderProps {
  account?: Model<AccountDoc>
  groups?: Model<GroupDoc>[]
  currentGroup?: Model<GroupDoc>
  groupID: string
  currentView?: CalendarRange
}

function CalendarHeader(props: CalendarHeaderProps) {
  const calendar = useCalendar()
  const [groupPoupOpen, setGroupPopupOpen] = useState(false)

  Logger.blue('calendarinfo', calendar.groupInfo)
  useEffect(() => {
    if (calendar.groupInfo) showGroup()
  }, [calendar.groupInfo])

  const isUserNotActive =
    props.currentGroup &&
    !isUserActiveInGroup(
      props.currentGroup,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )

  const showGroup = () => {
    setGroupPopupOpen(true)
  }

  const hideGroup = () => {
    setGroupPopupOpen(false)
  }

  const readOnly = !LocalStorage.get(LocalStorageKey.USER_ID)

  return (
    <Styled.CalendarHeader style={{ cursor: 'pointer' }}>
      {props.currentGroup?._id ? (
        <GroupPopup
          setOpen={setGroupPopupOpen}
          isOpen={groupPoupOpen}
          groupID={props.currentGroup._id}
          group={props.currentGroup}
        >
          <Styled.CalendarHeaderInfo onClick={showGroup}>
            <ContainerWithBottomBadgeIcon
              showLock={isGroupOutOfTier(
                highestActiveTier(props.account),
                props.currentGroup,
                props.account
              )}
              topRightIcon={
                isGroupChannel(props.currentGroup) ? (
                  <GroupChannelIcon width={18} height={18} />
                ) : undefined
              }
              marginRight={6}
            >
              <Styled.AvatarBadge
                name=""
                size="sm"
                asGroup
                img={props.currentGroup.Photo}
                emptyPic={defaultGroupImage(props.currentGroup)}
              />
            </ContainerWithBottomBadgeIcon>

            <Styled.CalendarHeaderTitleContainer className="calendar-info-wrapper">
              <GroupcalTooltip title={t('clickToSeeCalendarInfo')}>
                <Styled.CalendarHeaderTitle>
                  <GroupTitle group={props.currentGroup} iconHeight={'20px'} />
                </Styled.CalendarHeaderTitle>
              </GroupcalTooltip>
              <GroupcalTooltip title={t('clickToSeeCalendarInfo')}>
                <Styled.CalendarHeaderSubtitle
                  color={isUserNotActive ? 'red' : undefined}
                >
                  {isUserNotActive && !readOnly ? (
                    isUserLeft(
                      props.currentGroup,
                      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
                    ) ? (
                      'You left the shared calendar'
                    ) : (
                      'You have been removed from the shared calendar'
                    )
                  ) : readOnly ? (
                    <></>
                  ) : (
                    <NamesStrip
                      group={props.currentGroup}
                      participantIds={Object.keys(
                        getActiveParticipants(props.currentGroup.Participants)
                      )}
                    />
                  )}
                </Styled.CalendarHeaderSubtitle>
              </GroupcalTooltip>
            </Styled.CalendarHeaderTitleContainer>
          </Styled.CalendarHeaderInfo>
        </GroupPopup>
      ) : (
        <Styled.CalendarHeaderInfo>
          <Styled.AllAccountBadge
            groups={props.groups}
            images={groupsToImages(props.groups)}
          />
          <Styled.CalendarHeaderTitleContainer>
            <Styled.CalendarHeaderTitle id="calednar-group-title">
              {t('allCalendarsButton')}
            </Styled.CalendarHeaderTitle>
          </Styled.CalendarHeaderTitleContainer>
        </Styled.CalendarHeaderInfo>
      )}

      <Styled.CalendarHeaderActions>
        <Styled.CalendarHeaderActionsCell dir="ltr">
          <Styled.IconButtonSlide
            data-prev={1}
            size="sm"
            onClick={i18n.dir() === 'ltr' ? calendar.prev : calendar.next}
          >
            <CaretDownIcon />
          </Styled.IconButtonSlide>
          <Styled.IconButtonSlide
            data-next={1}
            size="sm"
            onClick={i18n.dir() === 'ltr' ? calendar.next : calendar.prev}
          >
            <CaretDownIcon />
          </Styled.IconButtonSlide>
          <Styled.RangeSelect
            variant="no-border"
            placeholder="Month"
            size="lg"
            value={calendar.currentMonth}
            options={calendar.monthArray}
            onChange={(e) => {
              calendar.onCurrentMonth(`${e?.value}`)
              calendar.onGoToDate(dayjs(Number(e?.value)).toDate())
            }}
          />
        </Styled.CalendarHeaderActionsCell>
        <Styled.CalendarHeaderActionsCell>
          <Styled.RangeSelect
            sx={{ direction: 'ltr' }}
            variant="no-border"
            placeholder="Range"
            className={`select-date-wrapper ${calendar.view}`}
            size="lg"
            options={RANGE}
            value={calendar.view}
            onChange={(e) => {
              console.log(calendar.ref)
              calendar.onView(e?.value as CalendarRange)
            }}
          />
        </Styled.CalendarHeaderActionsCell>

        <Styled.CalendarHeaderActionsCell>
          <GroupcalTooltip title={t('backToToday')}>
            <div className="today-button" onClick={calendar.onGoToToday}>
              <span className="today-text">{t('today')}</span>
              <CalendarBackIcon className="today-image" />
            </div>
          </GroupcalTooltip>
        </Styled.CalendarHeaderActionsCell>
        <Styled.CalendarHeaderActionsCell>
          <SearchPopupButton
            groupId={props.currentGroup?._id}
            mode={SEARCH_MODE.OnlyEvents}
            textfieldPlaceholder={
              (calendar.groupServerID.length > 0
                ? t('searchItemsIn', {
                    what: t('events').toLowerCase(),
                    where: props.currentGroup?.Name ?? ''
                  })
                : is24meApp()
                ? t('searchItems', {
                    what: t('eventsTasksNotes').toLowerCase()
                  })
                : t('searchItems', { what: t('events').toLowerCase() })) ?? ''
            }
          />
        </Styled.CalendarHeaderActionsCell>
      </Styled.CalendarHeaderActions>
    </Styled.CalendarHeader>
  )
}

export default withObservables(['groupID'], (props: CalendarHeaderProps) => ({
  account: ObserveService.Account(),
  groups: ObserveService.Groups(),
  currentGroup: ObserveService.GroupByServerID(props.groupID)
}))(CalendarHeader)
