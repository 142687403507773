import ProBadge from 'components/badges/ProBadge'
import { ReactNode } from 'react'
import { highestActiveTier, paddlePlanToTier, Tier } from 'utils/api/accounts'
import withObservables from '@nozbe/with-observables'
import Database from 'services/db/Database'
import ObserveService from 'services/db/Observe'

import * as Styled from './styled'
import { Model } from 'types/model'
import { AccountDoc } from 'types/api/changes'
import Logger from 'services/Logger'
import { usePayment } from 'components/providers/PaymentProvider'
import { tierToPlan } from 'components/tier/TierHover'
import { Box, Stack } from '@mui/system'
import { PlanName } from 'components/dialogs/payment/PlansInfoDialog'
import { TierFeatures } from 'utils/api/groups'
import { tierFeaturesToAnalyticsFeature } from 'components/tier/OutOfTierDialog'
import { theme } from 'theme'
import { cursorTo } from 'readline'
import { Spacing } from '@material-ui/core/styles/createSpacing'

interface PopupSectionRowProps {
  title: ReactNode
  value?: ReactNode
  icon?: ReactNode
  onlyTitle?: boolean
  action?: () => void
  color?: string
  valueColor?: string
  iconClass?: string
  neededTier?: Tier
  account?: Model<AccountDoc> | AccountDoc
  tierFeature?: TierFeatures
  titleColor?: string
  overflow?: string
  width?: string
  maxWidthIcon?: number
  cursor?: 'pointer' | 'default'
  spacing?: Spacing | string
  actionId?: string
}

export function PopupSectionRow(props: PopupSectionRowProps) {
  const activeTier = highestActiveTier(props.account)

  const needBlockUser =
    props.neededTier != undefined && activeTier < props.neededTier
  const { showPaymentDialog } = usePayment()

  const handleClose = (
    status: 'PURCHASED' | 'PURCHASE_ABORTED' | 'DIALOG_CLOSED',
    plan: PlanName
  ) => {
    Logger.debug('plan status; plan; =>', status, plan)

    const shouldHandleAction =
      paddlePlanToTier(plan) >= (props.neededTier ?? Tier.FREE)

    if (status === 'DIALOG_CLOSED') {
      if (props.action && shouldHandleAction) props.action()
    }
  }

  const openPayments = () => {
    if (props.neededTier != undefined)
      showPaymentDialog(tierToPlan(props.neededTier), handleClose, [
        tierFeaturesToAnalyticsFeature(props.tierFeature)
      ])
  }

  return (
    <Styled.PopupSectionRow
      data-onlytitle={props.onlyTitle}
      sx={{
        '& svg': {
          width: 'auto',
          maxWidth: props.maxWidthIcon ?? 16,
          height: '100%',
          color: props.color ?? theme.palette.input.placeholder
        },
        cursor: props.cursor ?? 'default',
        padding: props.spacing ?? theme.spacing(1, 0)
      }}
    >
      <div style={{ flexGrow: 1 }}>
        {props.icon && (
          <span
            onClick={(e) => {
              e.preventDefault()
            }}
            data-content={props.iconClass ?? 'icon'}
          >
            {props.icon}
          </span>
        )}
        <span
          id={props.actionId}
          style={{
            color: props.titleColor,

            textOverflow: 'ellipsis',
            justifyContent: 'space-around',
            overflow: props.overflow ? props.overflow : undefined,
            flexGrow: 1
          }}
          onClick={needBlockUser ? openPayments : props.action}
          data-content={props.action ? 'action' : 'title'}
        >
          {needBlockUser ? (
            <ProBadge title={props.title} neededTier={props.neededTier} />
          ) : (
            props.title
          )}
        </span>
      </div>
      {props.value && (
        <div style={{ width: 'fit-content' }}>
          <span style={{ color: props.valueColor }}>{props.value}</span>
          {needBlockUser && (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={openPayments}
              position={'absolute'}
              width={'fit-content'}
              height={'fit-content'}
            >
              <span style={{ pointerEvents: 'none', opacity: 0 }}>
                {props.value}
              </span>
            </Box>
          )}
        </div>
      )}
    </Styled.PopupSectionRow>
  )
}

export default withObservables([], () => ({
  account: ObserveService.Account()
}))(PopupSectionRow)
