import Box from '@mui/material/Box'

export interface OffsetFromBottomDividerProps {
  bottomOffset?: number
}

export default function OffsetFromBottomDivider(
  props: OffsetFromBottomDividerProps
) {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: props.bottomOffset ?? 120,
        width: '1px',
        backgroundColor: '#E4E4E4'
      }}
    />
  )
}
