import Logger from 'services/Logger'

export async function shareLink(link: string) {
  const shareData = {
    title: 'Share title',
    text: 'Share text',
    url: link
  }
  if (navigator.share) {
    try {
      Logger.blue('trying to share', link)
      await navigator.share(shareData)
    } catch (e) {
      console.error(e)
    }
  } else {
    console.error('Not supported')
  }
}
