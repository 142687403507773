import {
  Box,
  ClickAwayListener,
  createTheme,
  TextField,
  ThemeProvider
} from '@mui/material'
import Switch from 'components/form/Switch'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { ReactNode, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Styled from '../styled'
import dayjs, { Dayjs } from 'dayjs'
import { PopupInstance } from 'components/popup/Popup'
import { APP_COLOR } from 'utils/colors'
import i18n from 'i18n'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import { t } from 'i18next'
import { IEventDetails } from 'hooks/useEventDetails'
import { Controller } from 'react-hook-form'
import { DocType } from 'types/api/changes'
import Logger from 'services/Logger'

interface ContentProps {
  open: boolean
  setOpen: (b: boolean) => void
  instance: PopupInstance | null | undefined
  value: Dayjs | null
  onAccept: (date: Dayjs | null) => void
  eventHook: IEventDetails
  instanceStart: number
}
const theme = createTheme({
  palette: {
    input: {
      border: {
        primary: undefined
      },
      placeholder: undefined
    },
    border: {
      primary: undefined
    },
    primary: {
      main: String(APP_COLOR)
    },
    card: {
      primary: undefined
    },
    button: {
      background: {
        primary: undefined,
        selected: undefined
      },
      text: {
        primary: undefined,
        approve: undefined,
        tenative: undefined,
        negative: undefined,
        groupcal: undefined,
        link: undefined,
        darkRed: undefined
      }
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: String(APP_COLOR)
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: String(APP_COLOR)
        }
      }
    }
  }
})

function Content(props: ContentProps) {
  const [currentValue, setCurrentValue] = useState(props.value)

  const handleClickAway = () => {
    props.setOpen(false)
  }

  const toggleState = () => {
    const newState = props.eventHook.getValues('someday') === '1' ? '0' : '1'

    props.eventHook.setSomeday(newState)

    if (newState === '1') {
      props.setOpen(false)
    }
  }

  Logger.blue('currentValue', currentValue)

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <div style={{ color: 'black' }}>
        <Controller
          name="someday"
          control={props.eventHook.control}
          defaultValue={props.eventHook.someday}
          render={({ field }) => (
            <Box sx={{ direction: 'ltr' }}>
              {props.eventHook.getValues('someday') != '1' && (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <ThemeProvider theme={theme}>
                    <StaticDatePicker
                      openTo="day"
                      disablePast={false}
                      orientation="landscape"
                      timezone={props.eventHook.timeZone}
                      value={dayjs(props.instanceStart)}
                      onChange={(newValue) => {
                        setCurrentValue(dayjs(newValue))
                        props?.instance?.hide()
                        props.onAccept(newValue)
                        props.setOpen(false)
                      }}
                      displayStaticWrapperAs="desktop"
                      onAccept={(date) => {
                        console.log(dayjs(date))
                        props?.instance?.hide()
                        props.onAccept(dayjs(date))
                      }}
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              )}
              {props.eventHook.getValues('Type') === DocType.TASK && (
                <Box
                  margin={theme.spacing(0, 3)}
                  minWidth={'200px'}
                  sx={{ direction: i18n.dir() }}
                >
                  <PopupSectionRow
                    title={t('somedayTaskTitle')}
                    action={toggleState}
                    value={
                      <Switch
                        onChange={() => {
                          toggleState()
                        }}
                        checked={props.eventHook.getValues('someday') === '1'}
                      />
                    }
                  />
                </Box>
              )}
            </Box>
          )}
        />
      </div>
    </ClickAwayListener>
  )
}

interface DatePickPopupProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  value: Dayjs | null
  onAccept: (date: Dayjs | null) => void
  eventHook: IEventDetails
  instanceStart: number
}

export default function DatePickPopup(props: DatePickPopupProps) {
  const [instance, onInstance] = useState<PopupInstance>()
  return (
    <Styled.DatePickPopup
      width="300px"
      open={props.open}
      onMount={onInstance}
      content={<Content {...props} instance={instance} />}
      placement={'right'}
    >
      {props.children}
    </Styled.DatePickPopup>
  )
}
