import { Box, ClickAwayListener, Grid, List, TextField } from '@mui/material'
import { ChangeEvent, ReactNode, useState } from 'react'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { t } from 'i18next'
import { CheckmarkIcon } from 'assets/icons'
import Popup from 'components/popup/Popup'
import { theme } from 'theme'
import { ArrowRightGrey } from 'assets/img'
import i18n from 'i18n'
import { Model } from 'types/model'
import { GroupDoc } from 'types/api/changes'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

interface SelectItemWithSearchPopupProps {
  showSearch?: boolean
  searchHint?: string | undefined | null
  data: SelectableItem[]
  open: boolean
  setOpen?: (b: boolean) => void
  children: ReactNode
  selectedItemId: string | undefined // id field from the SelectableItem
  selectItem?: (item: SelectableItem) => void
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'right-start' | undefined
  rowBuilder?: (item: SelectableItem) => ReactNode
  title?: string
  width?: string
  onHide?: () => void
}

export interface SelectableItem {
  id?: string
  title: string
  subTitle?: string
  subTitleColor?: string
  subItems?: SelectableItem[]
  rightIcon?: ReactNode
  leftIcon?: ReactNode
  leftIconColor?: string
  notClickable?: boolean
  value?: any
  svgIconMaxWidth?: number
  selected?: boolean
  notClickableReason?: string
  photo?: string
  group?: Model<GroupDoc> | GroupDoc
  idPrefix?: string
}

function Content(props: SelectItemWithSearchPopupProps) {
  const [selectedItem, onSelectedLang] = useState<SelectableItem | undefined>(
    props.data.find((e) => e.id === props.selectedItemId)
  )
  const [searchText, setSearchText] = useState<string>('')

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const onItemClick = (lang: SelectableItem) => {
    onSelectedLang(lang)
    if (props.selectItem) props.selectItem(lang)
  }

  const searchEnabled = props.showSearch

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (props.setOpen) props.setOpen(false)
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '300px'
        }}
      >
        <Grid
          container
          direction={'column'}
          sx={{ padding: theme.spacing(0, 4) }}
        >
          {props.title && (
            <div style={{ color: 'black', fontSize: '20px', marginBottom: 8 }}>
              {props.title}
            </div>
          )}
          {searchEnabled ? (
            <TextField
              autoFocus
              placeholder={props.searchHint ?? ''}
              value={searchText}
              onChange={onSearchChange}
              variant="standard"
            />
          ) : (
            <></>
          )}
          <List sx={{ width: '100%' }}>
            {props.data
              .filter((item) => {
                if (searchEnabled) {
                  return item.title
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                }

                return true
              })
              .map((item, index) => {
                if (props.rowBuilder) {
                  return props.rowBuilder(item)
                }

                return (
                  <ItemRow
                    key={index}
                    {...props}
                    onItemClick={onItemClick}
                    selectedItem={selectedItem}
                    item={item}
                  />
                )
              })}
          </List>
        </Grid>
      </Box>
    </ClickAwayListener>
  )
}

interface ItemRowProps extends SelectItemWithSearchPopupProps {
  onItemClick: (item: SelectableItem) => void
  selectedItem: SelectableItem | undefined
  item: SelectableItem
}

export function ItemRow(props: ItemRowProps) {
  const { item } = props
  const [showSubItems, onShowSubItems] = useState(false)

  return (
    <SelectItemWithSearchPopup
      data={item.subItems ?? []}
      searchHint={t('calendar')}
      selectedItemId={props.selectedItemId}
      open={showSubItems}
      placement={i18n.dir() === 'rtl' ? 'left' : undefined}
      setOpen={onShowSubItems}
      selectItem={props.selectItem}
    >
      <div>
        <PopupSectionRow
          actionId={`${item.idPrefix ?? ''}${item.id ?? ''}`}
          overflow={'hidden'}
          color={item.leftIconColor}
          icon={props.item.leftIcon}
          maxWidthIcon={props.item.svgIconMaxWidth}
          title={
            <div
              style={{
                width: 'auto'
              }}
            >
              {item.title}
            </div>
          }
          action={
            item.notClickable
              ? () => {}
              : () => {
                  if ((item.subItems?.length ?? 0) > 0) {
                    onShowSubItems(true)
                  } else {
                    props.onItemClick(item)
                    if (props.setOpen) props.setOpen(false)
                  }
                }
          }
          value={
            props.item.rightIcon != undefined ? (
              <Grid
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if ((item.subItems?.length ?? 0) > 0) onShowSubItems(true)
                }}
              >
                {props.item.rightIcon}
              </Grid>
            ) : props.selectedItem?.id?.includes(item.id ?? '') ? (
              <CheckmarkIcon />
            ) : (
              <></>
            )
          }
        />
      </div>
    </SelectItemWithSearchPopup>
  )
}

export default function SelectItemWithSearchPopup(
  props: SelectItemWithSearchPopupProps
) {
  return (
    <Popup
      onHide={props.onHide}
      setOpen={props.setOpen}
      open={props.open}
      content={<Content {...props} />}
      width={props.width}
      placement={props.placement ?? 'right'}
    >
      {props.children}
    </Popup>
  )
}
