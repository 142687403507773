import GroupCard from 'components/cards/GroupCard'
import AddGroupPopup from 'components/popups/addGroup/AddGroupPopup'
import { useCalendar } from 'components/providers/CalendarProvider'
import dayjs from 'dayjs'
import { Reorder } from 'framer-motion'

import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { clearUnread } from 'services/api/userSettings'
import { ThirdPartyAccountByEmail } from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import { GroupDoc, UserSettingsDoc } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import {
  groupUnread,
  isGroupMuted,
  isGroupHidden,
  isGroupDefault
} from 'utils/api/userSettings'
import {
  prefixGoogleEventsGroup,
  fetchCalendarsFromGoogle,
  connectGoogleCalendar
} from 'utils/google_events'
import { GroupsProps } from './Groups'
import { Model } from 'types/model'
import { tfmGoogleTempLabel } from 'services/db/Queries/GroupQueries'
import GroupLoader from 'components/loaders/GroupLoader'
import { Box, ThemeProvider, createMuiTheme } from '@mui/material'
import i18n from 'i18n'
import {
  groupToProvider,
  isConvertedGroup,
  isConvertedGroupId
} from 'utils/groups'
import {
  ProviderType,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'
import { useEffect, useRef } from 'react'

interface GroupsListProps {
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined
  groupsForUI: Model<GroupDoc>[] | GroupDoc[]
  saving: boolean
  setSaving: (b: boolean) => void
  pendingGroup?: Model<GroupDoc>
  addGroupPopupState: boolean
  onAddGroupPopupState: (b: boolean) => void
  onCancelAdd: () => void
  handleCloseContextMenu?: () => void
  handleContextMenu: (
    event: React.MouseEvent<HTMLElement>,
    group: GroupDoc
  ) => void
  topRef?: any
}

export function GroupsList(props: GroupsListProps) {
  const {
    saving,
    setSaving,
    pendingGroup,
    addGroupPopupState,
    onAddGroupPopupState,
    onCancelAdd,
    topRef
  } = props

  return (
    <div style={{ width: '100%' }}>
      {props.groupsForUI.length > 0 ? (
        props.groupsForUI.map((row, index) => (
          <Reorder.Item
            as="div"
            key={row.id ?? row._id}
            value={row}
            dragListener={false}
          >
            <AddGroupPopup
              saving={saving}
              setSaving={setSaving}
              group={pendingGroup}
              open={
                addGroupPopupState &&
                (row._rev === 'temp' ||
                  row._id === LocalStorage.get(LocalStorageKey.ADDING_GROUP))
              }
              setOpen={onAddGroupPopupState}
              onClose={onCancelAdd}
              onClickAwayAction={onCancelAdd}
            >
              <div>
                <GroupRow row={row} {...props} />
              </div>
            </AddGroupPopup>
          </Reorder.Item>
        ))
      ) : (
        <Box marginTop={3}>
          {[...Array(5)].map((_, i) => (
            <GroupLoader key={i} />
          ))}
        </Box>
      )}
    </div>
  )
}

interface GroupRowProps extends GroupsProps {
  row: Model<GroupDoc> | GroupDoc
  onCancelAdd: () => void
  onAddGroupPopupState: (b: boolean) => void
  handleCloseContextMenu?: () => void
  handleContextMenu: (
    event: React.MouseEvent<HTMLElement>,
    group: GroupDoc
  ) => void
  handleGroupClick?: (group: GroupDoc) => void
  disableSelection?: boolean
  hideMetaData?: boolean
}

export function GroupRow(props: GroupRowProps) {
  const calendar = useCalendar()
  const reloadGoogleEvents = async (group: GroupDoc) => {
    if (group.SyncProblem != null) return
    const from = dayjs(
      new Date(Number(LocalStorage.get(LocalStorageKey.VISIBLE_DATE)))
    )
      .add(-1, 'month')
      .toISOString()
    const to = dayjs(
      new Date(Number(LocalStorage.get(LocalStorageKey.VISIBLE_DATE)))
    )
      .add(1, 'month')
      .toISOString()
    const googleAcc = await ThirdPartyAccountByEmail(
      group._id?.replace(prefixGoogleEventsGroup, '') ?? ''
    )
    fetchCalendarsFromGoogle(
      googleAcc.email,
      {
        access_token: googleAcc.token,
        expiry_date: googleAcc.tokenExpire,
        email: '',
        username: '',
        type: '',
        connected: false
      },
      from,
      to
    )
  }

  const calendarSync = useCalendarSync()

  return (
    <GroupCard
      hideMetaData={props.hideMetaData}
      disableSelection={props.disableSelection}
      handleContextMenu={props.handleContextMenu}
      unread={groupUnread(props.userSettings, props.row._id ?? '')}
      userSettings={props.userSettings}
      group={props.row}
      muted={isGroupMuted(props.userSettings, props.row._id ?? '')}
      hidden={isGroupHidden(props.userSettings, props.row._id ?? '')}
      default={isGroupDefault(props.userSettings, props.row._id ?? '')}
      pinned={
        isConvertedGroup(props.row) &&
        !props.row._id?.includes(tfmGoogleTempLabel)
      }
      onClick={() => {
        Logger.orange('group', props.row)
        LocalStorage.remove(LocalStorageKey.PENDING_EVENT_DATA)

        if (props.row._id === 'tempid') {
          //add google cal here
          if (calendarSync.showProviderPicker) calendarSync.showProviderPicker()
          return
        } else if (!isConvertedGroup(props.row)) {
          clearUnread(props.row._id ?? '')
        } else {
          reloadGoogleEvents(props.row)
        }
        props.onCancelAdd()
        calendar.onGroupServer(props.row._id ?? '')
        props.onAddGroupPopupState(false)

        if (props.handleGroupClick) {
          props.handleGroupClick(props.row)
        }
      }}
    />
  )
}
