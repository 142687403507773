import { ChevronUp, LandlineTypeIcon, MobileTypeIcon } from 'assets/icons'

import * as Styled from './styled'
import { useState } from 'react'
import SelectItemWithSearchPopup, {
  SelectableItem
} from 'components/popups/SelectItemWithSearchPopup'
import { t } from 'i18next'
import { useAuth } from 'components/providers/AuthProvider'
import Box from '@mui/material/Box'
import { Phone } from '@mui/icons-material'
import { PhoneType } from './LoginPage'
import { ClickAwayListener } from '@mui/material'
import PhoneTypePopup from './PhoneTypePopup'

export interface PhoneTypePickerProps {}

export default function PhoneTypePicker(props: PhoneTypePickerProps) {
  const auth = useAuth()
  const [showPicker, onShowPicker] = useState(false)
  const togglePhonePicker = () => {
    onShowPicker(!showPicker)
  }
  return (
    <PhoneTypePopup setOpen={onShowPicker} open={showPicker}>
      <div style={{ height: '48px' }}>
        <Styled.PhoneTypePicker
          selected={showPicker}
          onClick={togglePhonePicker}
        >
          {auth.phoneType === PhoneType.Mobile ? (
            <MobileTypeIcon style={{ marginLeft: 8 }} width={16} />
          ) : (
            <LandlineTypeIcon width={16} />
          )}
        </Styled.PhoneTypePicker>
      </div>
    </PhoneTypePopup>
  )
}
