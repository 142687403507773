import { Backdrop, CircularProgress, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Input from 'components/form/Input'
import { ChangeEvent, useState } from 'react'
import { AccountDoc } from 'types/api/changes'
import { Model } from 'types/model'
import ConfirmationDialog from './ConfirmationDialog'
import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { updateAccountOnServer } from 'services/api/account'
import Database from 'services/db/Database'

import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { Account } from 'services/db/Queries/AccountQueries'
import { APP_COLOR } from 'utils/colors'
import { useConnection } from 'components/providers/ConnectionProvider'
import { GENERIC_ERROR } from 'utils/strings'
import { useTranslation } from 'react-i18next'
import TwoInputFields from 'components/form/TwoInputFields'

const MAX_LEN = 50

interface EnterNameDialogProps {
  account?: Model<AccountDoc>
  setOpen: (b: boolean) => void
  open: boolean
}

function EnterNameDialog(props: EnterNameDialogProps) {
  const { t } = useTranslation()
  const connection = useConnection()
  const [progress, onProgress] = useState(false)
  const [errorText, onErrorText] = useState('')
  const [firstName, onSetFirstName] = useState(
    props.account?.Name?.FirstName === 'null'
      ? ''
      : props.account?.Name?.FirstName ?? ''
  )
  const [secondName, onSetSecondName] = useState(
    props.account?.Name?.LastName === 'null'
      ? ''
      : props.account?.Name?.LastName ?? ''
  )

  const hideDialog = () => {
    if (!progress) props.setOpen(false)
  }

  const updateServerWithName = async (name: string, lastName: string) => {
    const account = await Account()

    if (account) {
      onErrorText('')
      onProgress(true)
      try {
        await Database.write(async () => {
          account.update((acc) => {
            acc.Name = {
              FirstName: name,
              LastName: !lastName ? 'null' : lastName,
              FullName: `${name} ${lastName}`
            }
          })
        })

        await updateAccountOnServer(account)
        props.setOpen(false)
      } catch (e) {
        console.error(e)
        connection.setGenericErrorVisible(true)
        onErrorText(GENERIC_ERROR)
      }

      onProgress(false)
    }
  }

  const sendNameToServer = () => {
    if (
      progress ||
      (firstName === props.account?.Name?.FirstName &&
        (secondName === props.account?.Name?.LastName ||
          props.account?.Name?.LastName === 'null'))
    ) {
      //nothing changed, or progress still going
      hideDialog()
      return
    }

    if (!firstName) {
      onErrorText(`${t('firstNameRequired')}`)
    } else {
      if (connection.isConnectionAvailable())
        updateServerWithName(firstName, secondName)
    }
  }

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onErrorText('')
    if (event.target.value.length <= MAX_LEN) onSetFirstName(event.target.value)
  }

  const onSecondNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MAX_LEN)
      onSetSecondName(event.target.value)
  }

  const onCancelDialog = () => {
    if (!progress) {
      hideDialog()
    }
  }

  return (
    <>
      <ConfirmationDialog
        followForm
        title={t('addYourName')}
        leftButtonColor={'black'}
        leftButton={`${t('cancel')}`}
        rightButton={`${t('ok')}`}
        content={
          <TwoInputFields
            inProgress={progress}
            value={firstName}
            value2={secondName}
            placeholder1={`${t('enterYourFirstname')}`}
            placeholder2={`${t('enterYourLastname')}`}
            onChange={onFirstNameChange}
            onChange2={onSecondNameChange}
            errorText={errorText}
          />
        }
        handleClose={onCancelDialog}
        handleLeftButton={hideDialog}
        open={props.open}
        handleRightButton={sendNameToServer}
      />
      <Backdrop sx={{ color: APP_COLOR, zIndex: 99999 }} open={progress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default withObservables([], () => ({
  account: ObserveService.Account()
}))(EnterNameDialog)
