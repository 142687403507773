import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { useState } from 'react'
import { GroupDoc } from 'types/api/changes'
import { Tier } from 'utils/api/accounts'
import {
  featureToMinimumTierNeeded,
  isUserOwnerOfGroup,
  TierFeatures
} from 'utils/api/groups'
import { t } from 'i18next'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

interface MakeGroupVerifiedProps {
  group: GroupDoc
  currentTier: Tier
}

export default function MakeGroupVerified(props: MakeGroupVerifiedProps) {
  const mail = 'support@groupcal.app'
  const subject = t('calendarVerification')
  const groupId = `#ID0Q${props.group._id}^A76hn!#`
  const body = t('iWouldLikeToRequest', {
    groupName: props.group.Name,
    groupId: groupId
  })

  const content =
    props.currentTier === Tier.BUSINESS_PLUS
      ? t('calendarVerificationRequest')
      : t('calendarVerificationRequest')

  const makeGroupVerified = () => {
    hideShowSureDialog()
    window.open(`mailto:${mail}?subject=${subject}&body=${body}`)
  }

  const [showSure, onShowSure] = useState(false)

  const showSureDialog = () => {
    onShowSure(true)
  }

  const hideShowSureDialog = () => {
    onShowSure(false)
  }

  let neededTier = featureToMinimumTierNeeded(TierFeatures.VERIFIED)

  if (
    !isUserOwnerOfGroup(
      props.group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )
  ) {
    neededTier = Tier.FREE
  }

  return (
    <>
      <PopupSectionRow
        tierFeature={TierFeatures.VERIFIED}
        neededTier={neededTier}
        title={t('calendarVerification')}
        onlyTitle
        action={showSureDialog}
      />
      {showSure && (
        <ConfirmationDialog
          title={t('calendarVerification')}
          content={content}
          open={showSure}
          handleLeftButton={hideShowSureDialog}
          handleClose={hideShowSureDialog}
          leftButtonColor={'black'}
          followForm
          handleRightButton={makeGroupVerified}
        />
      )}
    </>
  )
}
