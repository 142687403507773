import { useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'

export interface NoInternetDialogProps {
  showing: boolean
  setShowing: (b: boolean) => void
  title: string
  message: string
  leftButton: string
}

export default function GenericDialog(props: NoInternetDialogProps) {
  const { showing, setShowing, leftButton } = props
  return (
    <ConfirmationDialog
      title={props.title}
      rightButtonHidden
      leftButton={leftButton}
      content={props.message}
      handleLeftButton={function () {
        setShowing(false)
      }}
      open={showing}
      handleClose={function () {
        setShowing(false)
      }}
    />
  )
}
