import React, { createContext, useContext, useState } from 'react'
import ICloudCredentialsDialog from './IcloudCredentialsDialog'
import {
  ConnectThirdPartyToServerData,
  connectThirdPartyToServer
} from 'services/api/group'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import Database from 'services/db/Database'
import ThirdPartyCalendarAccount from 'model/models/ThirdPartyCalendarAccount'
import { CollectionType } from 'types/model'
import {
  RemoveThirdPartyAccount,
  ThirdPartyAccountByEmail as ThirdPartyAccountByEmail,
  ThirdPartyCalendarAccounts
} from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import {
  ProviderType,
  cacheOtherCalendarsEvents,
  createGeneralGroupIfNeeded
} from '../CalendarSyncProdivder'
import IcloudApi from 'services/IcloudApi'
import Logger from 'services/Logger'
import {
  localGroupById,
  RemoveThirdPartyCalendar
} from 'services/db/Queries/GroupQueries'
import {
  RemoveThirdPartyEvents,
  otherCalendarEvents
} from 'services/db/Queries/Event'
import { EventDoc } from 'types/api/changes'
import dayjs from 'dayjs'
import { ICalendar } from 'types/google_events'
import { CurrentConvertedCalendars } from 'utils/google_events'

interface IcloudService {
  connect: (onCancel?: () => void) => void
  sync: () => void
  disconnect: (id: string) => void
}

interface IcloudProviderProps {
  children: React.ReactNode
}
export const icloudPrefix = 'icloud'
const IcloudContext = createContext<IcloudService | undefined>(undefined)

export function IcloudProvider(props: IcloudProviderProps) {
  const [showCredentialsDialog, setShowCredentialsDialog] = useState(false)
  const [onCancel, setOnCancel] = useState<(() => void) | undefined>(undefined)

  const openCredentialsDialog = (onCancel?: () => void) => {
    setShowCredentialsDialog(true)
    setOnCancel(() => onCancel) // Store the function reference correctly
  }

  const connect = (
    email: string,
    password: string
  ): Promise<ConnectThirdPartyToServerData> => {
    return connectThirdPartyToServer({
      email: email,
      DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID) ?? '',
      action: 'CONNECT',
      type: 'iCloud',
      appId: process.env.REACT_APP_APP_TYPE?.toLowerCase().includes('groupcal')
        ? 'groupcal'
        : 'twentyfourme',
      password: password
    }).then((response) => {
      if (response.connected) {
        ThirdPartyAccountByEmail(email, ProviderType.APPLE)
          .then((account) => {
            return Database.write(async () => {
              return account
                ? account.update((local) => {})
                : Database.getCollection<ThirdPartyCalendarAccount>(
                    CollectionType.GoogleAccount
                  ).create((local) => {
                    local.provider = ProviderType.APPLE
                    local.email = email
                  })
            })
          })
          .then((thirdPartyAccount) => {
            console.log('thirdPartyAccount', thirdPartyAccount)
            sync()
            if (onCancel) onCancel()
          })
      }

      return response
    })
  }

  const sync = () => {
    ThirdPartyCalendarAccounts(ProviderType.APPLE).then((accounts) => {
      console.log('accounts', accounts)
      accounts.forEach((account) => {
        IcloudApi.getCalendars({ email: account.email, type: 'iCloud' })
          .then((calendars) => {
            if (calendars.length !== 0) {
              createGeneralGroupIfNeeded(
                icloudPrefix,
                account.email,
                calendars[0].calendarColor,
                account.email,
                'iCloud calendar'
              )
            }

            calendars
              .filter((calendar) => calendar.components.includes('VEVENT'))
              .sort((a, b) => a.url.localeCompare(b.url))
              .forEach(async (calendar, index) => {
                let iCalendar: ICalendar = {
                  title: calendar.displayName
                }

                if (
                  CurrentConvertedCalendars.find(
                    (calendarFromServer) =>
                      calendarFromServer.id === calendar.url
                  ) === undefined
                ) {
                  iCalendar.id = calendar.url
                  iCalendar.url = calendar.url
                  iCalendar.title = calendar.displayName
                  iCalendar.name = calendar.displayName
                  iCalendar.backgroundColor = calendar.calendarColor
                    ? calendar.calendarColor
                    : '#3293eb'
                  iCalendar.accessRole = 'owner'
                  iCalendar.prefix = icloudPrefix
                  iCalendar.primary = index === 0
                  iCalendar.calDavString = calendar
                  iCalendar.primaryCalendarEmail = account.email
                  CurrentConvertedCalendars.push(iCalendar)
                }

                const existing = await otherCalendarEvents(
                  `${icloudPrefix}${account.email}`,
                  LocalStorage.get(LocalStorageKey.VISIBLE_FROM) ??
                    dayjs().startOf('day').unix().toString(),
                  LocalStorage.get(LocalStorageKey.VISIBLE_TO) ??
                    dayjs().startOf('day').add(7, 'day').unix().toString()
                )
                const filtered = existing.filter(
                  (event) => event.ThirdPartyID === calendar.url
                )
                const eventsFromServer = await IcloudApi.getCalendarItems(
                  account.email,
                  calendar
                )

                cacheOtherCalendarsEvents(
                  eventsFromServer,
                  filtered,
                  eventsFromServer,
                  undefined
                )
              })
          })
          .catch((error) => {
            createGeneralGroupIfNeeded(
              icloudPrefix,
              account.email,
              '#000000',
              account.email,
              'iCloud calendar'
            ).then(() => {
              return localGroupById(`${icloudPrefix}${account.email}`).then(
                (group) => {
                  if (group) {
                    Database.write(async () => {
                      return group.update((local) => {
                        local.SyncProblem = '1'
                      })
                    })
                  }
                }
              )
            })
          })
      })
    })
  }

  const disconnect = (id: string) => {
    ThirdPartyCalendarAccounts(ProviderType.APPLE).then((accounts) => {
      accounts.forEach((account) => {
        if (id === `${icloudPrefix}${account.email}`) {
          RemoveThirdPartyAccount(account.email, ProviderType.APPLE)
          RemoveThirdPartyEvents(`${icloudPrefix}${account.email}`)
          RemoveThirdPartyCalendar(`${icloudPrefix}${account.email}`)
        }
      })
    })
  }

  return (
    <IcloudContext.Provider
      value={{ connect: openCredentialsDialog, sync, disconnect }}
    >
      {props.children}
      {showCredentialsDialog && (
        <ICloudCredentialsDialog
          onCancel={onCancel}
          open={showCredentialsDialog}
          setOpen={setShowCredentialsDialog}
          connect={connect}
        />
      )}
    </IcloudContext.Provider>
  )
}

export const useIcloud = (): IcloudService => {
  const context = useContext(IcloudContext)
  if (context === undefined) {
    throw new Error('useIcloud must be used within an IcloudProvider')
  }
  return context
}
