import { Box, Grid } from '@mui/material'
import { AddIcon, ArrowAllCalendars, LayersIcon } from 'assets/icons'
import { useCalendar } from 'components/providers/CalendarProvider'
import { AnimatePresence, Reorder } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import withObservables from '@nozbe/with-observables'
import Database from 'services/db/Database'
import ObserveService from 'services/db/Observe'
import {
  AddTempGroup,
  RemoveTempGroups,
  allGroupsForUI
} from 'services/db/Queries/GroupQueries'
import {
  AccountDoc,
  GroupDoc,
  GroupStatus,
  MasterLabelDoc,
  MasterLabelLabel,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import {
  isGroupArchived,
  isGroupHidden,
  isGroupUnread
} from 'utils/api/userSettings'

import * as Styled from './styled'
import { theme } from 'theme'
import { useConnection } from 'components/providers/ConnectionProvider'
import Analytics, { Events } from 'analytics/Analytics'
import { useTranslation } from 'react-i18next'
import useFitText from 'use-fit-text'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import GroupItemContextMenu from 'components/contextMenu/GroupItemContextMenu'
import { performUpdateGroupSettings } from 'components/popups/groupInfo/SettingsForCalendarSection'
import { SegmentItem } from 'components/buttons/SegmentButton'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { GroupsList } from './GroupsList'
import LabelsList from './LabelsList'
import { addTempLabel } from 'services/db/Queries/MasterLabel'
import LabelItemContextMenu from 'components/contextMenu/LabelContextMenu'
import SearchPopupButton from 'components/SearchPopupButton'
import { SEARCH_MODE } from 'components/popups/searchItems/SearchPopup'

export interface GroupsProps {
  masterLabel?: Model<MasterLabelDoc>
  groups?: Model<GroupDoc>[] | GroupDoc[]
  topGroups?: Model<GroupDoc>[]
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc
  account?: Model<AccountDoc>
}

export interface GroupContextMenuProps {
  x: number
  y: number
  group: GroupDoc
}

interface LabelContextMenuProps {
  x: number
  y: number
  label: MasterLabelLabel
}

function Groups(props: GroupsProps) {
  const uiTypeContext = useAppUIType()
  const calendar = useCalendar()
  const connection = useConnection()
  const [saving, setSaving] = useState(false)
  const [pendingGroup, setPendingGroup] = useState<
    Model<GroupDoc> | undefined
  >()
  const [addGroupPopupState, onAddGroupPopupState] = useState(false)

  const topRef = useRef(null)
  const listRef = useRef(null)

  const onCancelAdd = () => {
    RemoveTempGroups()
    onAddGroupPopupState(false)
    LocalStorage.remove(LocalStorageKey.ADDING_GROUP)
  }

  function handleAddGroup() {
    if (connection.isConnectionAvailable()) {
      if (is24meApp()) {
        //add label here
        addTempLabel()
      } else {
        if (addGroupPopupState) return
        ;(listRef.current as HTMLDivElement | null)?.scrollTo(0, 0)
        Analytics.getInstance().sendEvent(Events.ADD_GROUP)
        addTempGroupToDB((group: Model<GroupDoc>) => {
          onAddGroupPopupState(true)
          setPendingGroup(group)
        })
      }
    }
  }

  const showAllCalendars = () => {
    uiTypeContext.setSelectedLabel(undefined)
    calendar.onGroupServer('')
    LocalStorage.remove(LocalStorageKey.PENDING_EVENT_DATA)
    onCancelAdd()
  }

  const { t, i18n } = useTranslation()
  const [maxFontSize, onMaxFontSize] = useState(100)
  const { fontSize, ref } = useFitText({
    minFontSize: 10,
    maxFontSize: 120
  })

  useEffect(() => {
    onMaxFontSize(uiTypeContext.getCurrentIndex() === 0 ? 120 : 100)
  }, [uiTypeContext.getCurrentIndex()])

  const groupsForUI = allGroupsForUI(props.groups ?? [], props.userSettings)

  const [contextMenuPosition, setContextMenuPosition] =
    useState<GroupContextMenuProps | null>(null)
  const [contextMenuPositionLabel, setContextMenuPositionLabel] =
    useState<LabelContextMenuProps | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)
  const handleCloseContextMenu = () => {
    setContextMenuPosition(null)
    setContextMenuPositionLabel(null)
  }

  const handleContextMenu = (
    event: React.MouseEvent<HTMLElement>,
    group: GroupDoc
  ) => {
    event.preventDefault() // Prevent the default context menu from showing
    handleCloseContextMenu() // Close the existing context menu

    const containerRect = containerRef?.current?.getBoundingClientRect()
    if (!containerRect) return

    const offsetX =
      event.clientX -
      (i18n.dir() === 'rtl' ? containerRect.right : containerRect.left)
    const offsetY = event.clientY - containerRect.top

    setContextMenuPosition({ x: offsetX, y: offsetY, group })
  }

  const handleLabelContextMenu = (
    event: React.MouseEvent<HTMLLIElement>,
    label: MasterLabelLabel
  ) => {
    event.preventDefault() // Prevent the default context menu from showing
    handleCloseContextMenu() // Close the existing context menu

    const containerRect = containerRef.current?.getBoundingClientRect()
    if (!containerRect) return

    const offsetX =
      event.clientX -
      (i18n.dir() === 'rtl' ? containerRect.right : containerRect.left)
    const offsetY = event.clientY - containerRect.top

    setContextMenuPositionLabel({ x: offsetX, y: offsetY, label })
  }

  function allItemsButtonTitle(uiType: SegmentItem): string {
    switch (uiType.value) {
      case AppUIType.calendars:
        return t('allCalendarsButton')
      case AppUIType.notes:
        return t('allNotes')
      case AppUIType.tasks:
        return t('allTasks')
    }

    return ''
  }

  return (
    <Styled.Groups>
      <Grid
        gap={'0.5rem'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'100%'}
      >
        <SearchPopupButton
          textfieldPlaceholder={`${
            is24meApp()
              ? t('searchItems', { what: t('eventsTasksNotes').toLowerCase() })
              : t('searchEvents')
          }`}
          mode={is24meApp() ? SEARCH_MODE.OnlyAllEvents : SEARCH_MODE.All}
        />
        <Styled.AllCalendarContainer
          container
          display={'flex'}
          flexDirection={'row'}
          alignItems="center"
          overflow={'hidden'}
          onClick={showAllCalendars}
          gap={0.5}
          sx={{
            background:
              !calendar.groupServerID && !uiTypeContext.selectedLabel
                ? theme.palette.button.background.selected
                : 'transparent'
          }}
        >
          <Grid item alignItems={'center'} display={'flex'}>
            <LayersIcon />
          </Grid>
          <div
            ref={ref}
            style={{ fontSize: fontSize }}
            className="allCalendars"
          >
            {allItemsButtonTitle(uiTypeContext.uiType)}
          </div>
          <ArrowAllCalendars
            style={{
              transform: i18n.dir() === 'ltr' ? '' : 'rotate(180deg)'
            }}
          />
        </Styled.AllCalendarContainer>
      </Grid>

      <Styled.GroupList
        ref={containerRef}
        sx={{
          marginTop: 2,
          '&& .Mui-selected': {
            bgcolor: theme.palette.button.background.selected
          }
        }}
      >
        <Box>
          {groupsForUI.length == 0 ? (
            <Box />
          ) : (
            <Styled.GroupListScrollContainer
              sx={{
                marginRight: i18n.dir() === 'ltr' ? 0 : theme.spacing(-5),
                marginLeft: i18n.dir() === 'ltr' ? theme.spacing(-5) : 0
              }}
            >
              <Styled.GroupListScroll ref={listRef}>
                <AnimatePresence>
                  <Reorder.Group
                    axis="y"
                    as="div"
                    values={groupsForUI.map((row) => row.id)}
                    onReorder={() => {}}
                  >
                    <div style={{ height: '8px', width: '100%' }} />
                    {(isGroupcalApp() ||
                      uiTypeContext.uiType.value === AppUIType.calendars) && (
                      <GroupsList
                        userSettings={props.userSettings}
                        saving={saving}
                        groupsForUI={groupsForUI ?? []}
                        setSaving={setSaving}
                        pendingGroup={pendingGroup}
                        addGroupPopupState={addGroupPopupState}
                        onAddGroupPopupState={onAddGroupPopupState}
                        onCancelAdd={onCancelAdd}
                        handleCloseContextMenu={handleCloseContextMenu}
                        handleContextMenu={handleContextMenu}
                        topRef={topRef}
                      />
                    )}
                    {is24meApp() &&
                      uiTypeContext.uiType.value != AppUIType.calendars && (
                        <LabelsList
                          masterLabel={props.masterLabel}
                          handleCloseContextMenu={handleCloseContextMenu}
                          handleContextMenu={handleLabelContextMenu}
                        />
                      )}
                  </Reorder.Group>
                </AnimatePresence>
              </Styled.GroupListScroll>
            </Styled.GroupListScrollContainer>
          )}
          {(isGroupcalApp() ||
            uiTypeContext.uiType.value != AppUIType.calendars) && (
            <Styled.AddGroupButton
              id="add-group-button"
              onClick={handleAddGroup}
            >
              <AddIcon />
              <span>
                {t(
                  uiTypeContext.uiType.value === AppUIType.calendars
                    ? 'calendarButton'
                    : 'label'
                )}
              </span>
            </Styled.AddGroupButton>
          )}
        </Box>
        {contextMenuPosition && (
          <GroupItemContextMenu
            account={props.account}
            userSettings={props.userSettings}
            x={contextMenuPosition?.x ?? 0}
            y={contextMenuPosition?.y ?? 0}
            group={contextMenuPosition.group}
            onClose={handleCloseContextMenu}
            contextMenuPosition={contextMenuPosition}
          />
        )}
        {contextMenuPositionLabel && (
          <LabelItemContextMenu
            userSettings={props.userSettings}
            x={contextMenuPositionLabel?.x ?? 0}
            y={contextMenuPositionLabel?.y ?? 0}
            label={contextMenuPositionLabel.label}
            onClose={handleCloseContextMenu}
            masterLabel={props.masterLabel}
          />
        )}
      </Styled.GroupList>
    </Styled.Groups>
  )
}

export default withObservables([], () => ({
  masterLabel: ObserveService.MasterLabel(),
  groups: ObserveService.Groups(),
  account: ObserveService.Account(),
  topGroups: ObserveService.TopGroups(),
  userSettings: ObserveService.UserSettings()
}))(Groups)

function addTempGroupToDB(cb: (group: Model<GroupDoc>) => void) {
  Database.write(async () => {
    const added = await AddTempGroup()
    cb(added)
  })
}
