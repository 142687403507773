import { Box } from '@mui/material'
import { Verified } from 'assets/icons'
import { GroupVerified } from 'model/models/Group'
import { GroupDoc } from 'types/api/changes'
import { Model } from 'types/model'

interface GroupTitleProps {
  iconHeight?: string
  group?: Model<GroupDoc> | GroupDoc
  align?: string
}

export default function GroupTitle(props: GroupTitleProps) {
  const shouldShowBadge =
    props.group?.VerifiedGroupStatus &&
    props.group?.VerifiedGroupStatus === GroupVerified.VERIFIED

  return (
    <Box
      fontSize={`${props.iconHeight ?? '16px'} !important`}
      height={'inherit'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={props.align ? props.align : 'center'}
    >
      <div className="group-title">{props.group?.Name}</div>
      {shouldShowBadge ? (
        <Verified
          height={props.iconHeight ?? '16px'}
          width={props.iconHeight ?? '16px'}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
