class LocalStorage {
  get(key: string) {
    if (typeof window !== 'undefined' && window.localStorage) {
      return window.localStorage.getItem(key)
    } else {
      console.error('window or localStorage is not defined')
      return null
    }
  }

  set(key: string, value: any) {
    let oldValue = this.get(key)
    window.localStorage.setItem(key, value)
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: key,
        oldValue: oldValue,
        newValue: value
      })
    )
  }

  remove(key: string) {
    window.localStorage.removeItem(key)
  }

  clear() {
    window.localStorage.clear()
  }
}

export default new LocalStorage()
