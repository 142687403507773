import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import zIndex from '@mui/material/styles/zIndex'
import i18n from 'i18n'
import { LazyTippy } from 'utils/tippy'

export const Popup = styled(LazyTippy)(({ theme }) => ({
  backgroundColor: theme.palette.card.primary,
  width: 390,
  maxWidth: '500px !important',
  borderRadius: 20,
  padding: 0,
  interactive: true,
  animation: undefined,
  border: '1px solid rgba(0,0,0,0.26)',
  boxShadow:
    '0px 8px 8px rgba(50, 50, 71, 0.2), 0px 4px 16px rgba(50, 50, 71, 0.2)',

  '& .tippy-arrow': {
    color: '#767676'
  },

  '& .tippy-content': {
    padding: theme.spacing(4, 0)
  },

  '&.tippy-box[data-placement^=top]>.tippy-arrow ': {
    bottom: '-1px'
  },
  '&.tippy-box[data-placement^=bottom]>.tippy-arrow ': {
    top: '-1px'
  },
  '&.tippy-box[data-placement^=left]>.tippy-arrow ': {
    right: '-1px'
  },
  '&.tippy-box[data-placement^=right]>.tippy-arrow ': {
    left: '-1px'
  }
}))

export const PopupSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  fontWeight: 400,
  textTransform: 'uppercase',
  padding: theme.spacing(1.5, 0, 1, 0)
}))

export const PopupSectionContent = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid',
  borderColor: theme.palette.border.primary
}))

export const PopupSectionRow = styled(Grid)(({ color, theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'space-between',
  fontSize: 14,
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 0),

  '& > div': {
    display: 'flex',
    alignItems: 'center'
  },

  '&[data-onlytitle="true"]': {
    '& > div': {
      width: '100%'
    },
    '& span[data-content="title"]': {
      width: '100%',
      fontWeight: 400
    }
  },

  '& span[data-content="icon"]': {
    height: 16,
    fontSize: '0.625rem',
    minWidth: '42px',
    fontWeight: 400,
    color: theme.palette.input.placeholder,
    margin: theme.spacing(0, 3, 0, 0)
  },
  '& span[data-content="icon-small"]': {
    height: 12,
    fontSize: '0.625rem',
    minWidth: '42px',
    fontWeight: 400,
    color: theme.palette.input.placeholder,
    margin: theme.spacing(0, 3, 0, 0)
  },
  '& span[data-content="icon-regular"]': {
    height: 20,
    fontSize: '0.625rem',
    width: 20,
    maxWidth: 20,
    fontWeight: 400,
    color: theme.palette.input.placeholder,
    margin: theme.spacing(
      0,
      i18n.dir() === 'ltr' ? 3 : 0,
      0,
      i18n.dir() === 'rtl' ? 3 : 0
    )
  },
  '& span[data-content="icon-big"]': {
    height: 24,
    fontSize: '0.625rem',
    width: 24,
    maxWidth: 24,
    fontWeight: 400,
    color: theme.palette.input.placeholder,
    margin: theme.spacing(
      0,
      i18n.dir() === 'ltr' ? 3 : 0,
      0,
      i18n.dir() === 'rtl' ? 3 : 0
    )
  },
  '& span[data-content="action"]': {
    cursor: 'pointer',
    color: color
  }
}))

export const PopupSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 5),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,

  '&:first-of-type': {
    [`${PopupSectionContent}`]: {
      paddingTop: 0
    }
  },

  '&:last-child': {
    [`${PopupSectionContent}`]: {
      borderBottom: 0,
      paddingBottom: 0
    }
  }
}))
