import { EventDoc, EventReminder } from 'types/api/changes'

export function generateDefaultReminder(): EventReminder {
  return {
    AlertTime: '',
    isOn: '1',
    isRelativeReminder: '1',
    offset: 60 * 60
  }
}
