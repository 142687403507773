import { Q } from '@nozbe/watermelondb'
import { UserSettingsDoc } from 'types/api/changes'
import { CollectionType } from 'types/model'
import Database from '../Database'

export const UserSettings = async () => {
  const userSettings = await Database.getCollection<UserSettingsDoc>(
    CollectionType.USER_SETTINGS
  )
    .query(Q.take(1))
    .fetch()
  return userSettings[0]
}
