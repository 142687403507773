import dayjs from 'dayjs'
import {
  ChangeDoc,
  ChangeDocResponse,
  ChangeDocsByTypes,
  DocType,
  EventDoc
} from 'types/api/changes'
import { CollectionType } from 'types/model'
import { isGroupcalApp } from 'utils/appType'
import { isTrulyEmpty } from 'utils/object'

export const GROUP_MOST_RECENT_EVENT: Record<string, number> = {}

export function docTypeToCollectionType(docType: DocType): CollectionType {
  switch (docType) {
    case DocType.ACCOUNT:
      return CollectionType.ACCOUNT
    case DocType.EVENT:
      return CollectionType.EVENT
    case DocType.MASTER_LABEL:
      return CollectionType.MASTER_LABEL
    case DocType.GROUP:
      return CollectionType.GROUP
    case DocType.PROFILE:
      return CollectionType.PROFILE
    case DocType.USER_SETTINGS:
      return CollectionType.USER_SETTINGS
    default:
      return CollectionType.EVENT
  }
}

export function parseDocsBeforeSaving(
  data: ChangeDocResponse[]
): ChangeDocsByTypes {
  const eventsById: { [id: string]: ChangeDoc } = {}
  const groupsById: { [id: string]: ChangeDoc } = {}
  const accountById: { [id: string]: ChangeDoc } = {}
  const masterLabelById: { [id: string]: ChangeDoc } = {}
  const profileById: { [id: string]: ChangeDoc } = {}
  const userSettingsById: { [id: string]: ChangeDoc } = {}

  for (const doc of data
    .filter(
      (changeDoc) =>
        changeDoc.doc.Type === DocType.EVENT ||
        changeDoc.doc.Type === DocType.NOTE ||
        changeDoc.doc.Type === DocType.TASK
    )
    .map((changeDoc) => changeDoc.doc)) {
    if (isGroupcalApp()) {
      GROUP_MOST_RECENT_EVENT[(doc as EventDoc).GroupID ?? ''] =
        Number(doc.LastUpdate) >
        (GROUP_MOST_RECENT_EVENT[(doc as EventDoc).GroupID ?? ''] || 0)
          ? Number(doc.LastUpdate)
          : GROUP_MOST_RECENT_EVENT[(doc as EventDoc).GroupID ?? '']
    }

    eventsById[doc._id ?? ''] = {
      ...doc,
      someday:
        isTrulyEmpty(doc.StartDate) && isTrulyEmpty(doc.EndDate) ? '1' : '0',
      StartDate: formatDateIfNeeded(
        isTrulyEmpty(doc.StartDate) ? doc.EndDate : doc.StartDate
      ),
      EndDate: formatDateIfNeeded(doc.EndDate ?? doc.StartDate),
      LastUpdate: formatDateIfNeeded(doc.LastUpdate)
    }
  }

  for (const doc of data
    .filter((changeDoc) => changeDoc.doc.Type === DocType.GROUP)
    .map((changeDoc) => changeDoc.doc)) {
    groupsById[doc._id ?? ''] = doc
  }
  for (const doc of data
    .filter((changeDoc) => changeDoc.doc.Type === DocType.ACCOUNT)
    .map((changeDoc) => changeDoc.doc)) {
    accountById[doc._id ?? ''] = doc
  }
  for (const doc of data
    .filter((changeDoc) => changeDoc.doc.Type === DocType.MASTER_LABEL)
    .map((changeDoc) => changeDoc.doc)) {
    masterLabelById[doc._id ?? ''] = doc
  }
  for (const doc of data
    .filter((changeDoc) => changeDoc.doc.Type === DocType.PROFILE)
    .map((changeDoc) => changeDoc.doc)) {
    profileById[doc._id ?? ''] = doc
  }
  for (const doc of data
    .filter((changeDoc) => changeDoc.doc.Type === DocType.USER_SETTINGS)
    .map((changeDoc) => changeDoc.doc)) {
    userSettingsById[doc._id ?? ''] = doc
  }

  const docs = {
    [DocType.GROUP]: groupsById,
    [DocType.EVENT]: eventsById,
    [DocType.ACCOUNT]: accountById,
    [DocType.MASTER_LABEL]: masterLabelById,
    [DocType.PROFILE]: profileById,
    [DocType.USER_SETTINGS]: userSettingsById
  }

  return docs as unknown as ChangeDocsByTypes
}

export function formatDateIfNeeded(date?: string) {
  if (!date || date === 'null') return dayjs().unix().toString()

  if (String(date).includes('.')) return String(date).split('.')[0]

  return date
}
