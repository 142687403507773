import { Model } from '@nozbe/watermelondb'
import { text } from '@nozbe/watermelondb/decorators'

export default class Participant extends Model {
  static table = 'Participant'

  @text('AccountID') AccountID!: string
  @text('FullName') FullName!: string
  @text('LastUpdate') LastUpdate!: string
  @text('PhoneNumber') PhoneNumber!: string
  @text('PhotoURL') PhotoURL!: string
}
