import {
  addColumns,
  createTable,
  schemaMigrations
} from '@nozbe/watermelondb/Schema/migrations'
import ThirdPartyCalendarAccount from './models/ThirdPartyCalendarAccount'
import ParticipantSuggestion from './models/ParticipantSuggestion'

export default schemaMigrations({
  migrations: [
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 2,
      steps: [
        // See "Migrations API" for more details
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'AggregatedParticipantsConfirmationStatus',
              type: 'string',
              isOptional: true
            },
            {
              name: 'AggregatedParticipantsDeliveryStatus',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 3,
      steps: [
        // See "Migrations API" for more details
        createTable({
          name: 'Participant',
          columns: [
            { name: 'AccountID', type: 'string' },
            { name: 'FullName', type: 'string' },
            { name: 'LastUpdate', type: 'string' },
            { name: 'PhoneNumber', type: 'string' },
            { name: 'PhotoURL', type: 'string' }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 4,
      steps: [
        addColumns({
          table: 'Groups',
          columns: [
            {
              name: 'Version',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 5,
      steps: [
        addColumns({
          table: 'Groups',
          columns: [
            {
              name: 'VerifiedGroupStatus',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 6,
      steps: [
        addColumns({
          table: 'Accounts',
          columns: [
            {
              name: 'Products',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 7,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'Color',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 8,
      steps: [
        createTable({
          name: ThirdPartyCalendarAccount.table,
          columns: [
            { name: 'token', type: 'string' },
            { name: 'tokenExpire', type: 'number' },
            { name: 'email', type: 'string' },
            { name: 'groupId', type: 'string' }
          ]
        }),
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'ConferenceData',
              type: 'string',
              isOptional: true
            },
            {
              name: 'attendees',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 9,
      steps: [
        addColumns({
          table: 'Accounts',
          columns: [
            {
              name: 'ConnectedCalAccounts',
              type: 'string',
              isOptional: true
            }
          ]
        }),
        addColumns({
          table: 'Groups',
          columns: [
            {
              name: 'SyncProblem',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 10,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'canAddAttendees',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 11,
      steps: [
        addColumns({
          table: 'Accounts',
          columns: [
            {
              name: 'PaymentEmail',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 12,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'someday',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 13,
      steps: [
        createTable({
          name: ParticipantSuggestion.table,
          columns: [
            { name: 'name', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'img', type: 'string' }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 14,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'SupplementaryGroupsIDs',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 15,
      steps: [
        addColumns({
          table: ThirdPartyCalendarAccount.table,
          columns: [
            { name: 'provider', type: 'string', isOptional: true },
            { name: 'password', type: 'string', isOptional: true }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 16,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'etag',
              type: 'string',
              isOptional: true
            },
            {
              name: 'url',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 17,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'Url',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 18,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'CustomUrl',
              type: 'string',
              isOptional: true
            },
            {
              name: 'Attachments',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    },
    {
      // ⚠️ Set this to a number one larger than the current schema version
      toVersion: 19,
      steps: [
        addColumns({
          table: 'Events',
          columns: [
            {
              name: 'Organizer',
              type: 'string',
              isOptional: true
            },
            {
              name: 'LocationReminders',
              type: 'string',
              isOptional: true
            }
          ]
        })
      ]
    }
  ]
})
