import axios, { AxiosError } from 'axios'
import { CurrentConvertedCalendars } from 'utils/google_events'
import { ThirdPartyAccountByEmail } from './db/Queries/ThirdPartyCalendarsQuieries'
import { connectThirdPartyToServer } from './api/group'
import Database from './db/Database'
import Logger from './Logger'

class GoogleApi {
  instance = axios.create({
    baseURL: `https://www.googleapis.com/calendar/v3/calendars/`
  })

  constructor() {
    this.instance.interceptors.request.use(async (req) => {
      const calendarId = req.url?.split('/')[0] ?? ''
      const email = CurrentConvertedCalendars.find(
        (cal) => cal.id === calendarId
      )?.primaryCalendarEmail

      if (email) {
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${
            (await ThirdPartyAccountByEmail(email)).token
          }`
        }
      }

      return req
    })

    this.instance.interceptors.response.use(
      undefined,
      async (error: AxiosError) => {
        if (error.response?.status != 401) {
          return Promise.reject(error)
        }

        const originalRequestConfig = error.config
        const calendarId = originalRequestConfig.url?.split('/')[0] ?? ''
        const email = CurrentConvertedCalendars.find(
          (cal) => cal.id === calendarId
        )?.primaryCalendarEmail

        if (email) {
          try {
            return connectThirdPartyToServer({
              email: email,
              action: 'REFRESH_TOKEN',
              type: 'Google'
            }).then((token) => {
              Logger.blue('refresh token from google response', token)
              return Database.write(async () => {
                return (await ThirdPartyAccountByEmail(email)).update(
                  (local) => {
                    local.token = token.access_token
                    local._raw['tokenExpire'] = token.expiry_date
                  }
                )
              }).then((data) => {
                originalRequestConfig.headers = {
                  ...originalRequestConfig.headers,
                  Authorization: `Bearer ${data.token}`
                }
                return axios.request(originalRequestConfig)
              })
            })
          } catch (e) {
            return Promise.reject(e)
          }
        }

        // delay original requests until authorization has been completed
        return Promise.reject(error)
      }
    )
  }
}

export default new GoogleApi()
