import { isToday } from 'date-fns'

import { getDay } from './date'
import { DayHeaderContentArg } from '@fullcalendar/core'
import dayjs from 'dayjs'

export function dayHeaderClassNames(e: DayHeaderContentArg) {
  return isToday(dayjs(e.date).add(12, 'hour').toDate())
    ? 'FullCalendarHeaderCurrentDay'
    : ''
}
