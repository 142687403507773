import ThirdPartyCalendarAccount from 'model/models/ThirdPartyCalendarAccount'
import { Q } from '@nozbe/watermelondb'
import { CollectionType } from 'types/model'
import Database from '../Database'

export async function ThirdPartyCalendarAccounts(provider?: string) {
  if (provider) {
    return await Database.getCollection<ThirdPartyCalendarAccount>(
      CollectionType.GoogleAccount
    )
      .query(Q.where('provider', provider))
      .fetch()
  }
  return await Database.getCollection<ThirdPartyCalendarAccount>(
    CollectionType.GoogleAccount
  )
    .query()
    .fetch()
}

export async function ThirdPartyAccountByEmail(
  email: string,
  provider?: string
) {
  const conditions = [Q.where('email', email)]

  if (provider) {
    conditions.push(Q.where('provider', provider))
  }

  return (
    await Database.getCollection<ThirdPartyCalendarAccount>(
      CollectionType.GoogleAccount
    )
      .query(Q.and(...conditions))
      .fetch()
  )[0]
}

export async function RemoveThirdPartyAccount(
  email: string,
  provider?: string
) {
  return Database.write(async () => {
    return (await ThirdPartyCalendarAccounts())
      .find(
        (group) => group.email.includes(email) && group.provider === provider
      )
      ?.destroyPermanently()
  })
}
