import { Box, Grid, List, TextField } from '@mui/material'
import { ChangeEvent, ReactNode, useState } from 'react'

import PopupSectionRow from 'components/popup/PopupSectionRow'
import { t } from 'i18next'
import i18n from 'i18n'
import { CheckmarkIcon } from 'assets/icons'
import Popup from 'components/popup/Popup'

export const langs = [
  { lang: 'en-GB', name: 'English' },
  { lang: 'en-US', name: 'English (US, Canada)' },
  { lang: 'uk', name: 'Українська' },
  { lang: 'ru', name: 'Pyccĸий' },
  { lang: 'fr', name: 'Français' },
  { lang: 'es', name: 'Español' },
  { lang: 'pt', name: 'Português' },
  { lang: 'de', name: 'Deutsch' },
  { lang: 'he', name: 'עברית' }
]

interface ContentProps {
  setOpened: (b: boolean) => void
  selectItem: (item: string) => void
}

function Content(props: ContentProps) {
  const [selectedLang, onSelectedLang] = useState<string>(i18n.language)
  const [searchText, setSearchText] = useState<string>('')

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const onItemClick = (lang: string) => {
    onSelectedLang(lang)
    props.selectItem(lang)
  }

  return (
    <Box sx={{ overflow: 'auto', maxHeight: '200px' }}>
      <Grid container direction={'column'} sx={{ padding: 4 }}>
        <TextField
          autoFocus
          placeholder={`${t('typeToSearchLanguage')}`}
          value={searchText}
          onChange={onSearchChange}
          variant="standard"
        />
        <List>
          {langs
            .filter((item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((lang, index) => {
              console.log(lang)
              return (
                <PopupSectionRow
                  key={index}
                  title={lang.name}
                  action={() => {
                    onItemClick(lang.lang)
                    props.setOpened(false)
                  }}
                  value={selectedLang === lang.lang ? <CheckmarkIcon /> : <></>}
                />
              )
            })}
        </List>
      </Grid>
    </Box>
  )
}

interface PickLanguagePopupProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  selectItem: (item: string) => void
}

export default function PickLanguagePopup(props: PickLanguagePopupProps) {
  return (
    <Popup
      setOpen={props.setOpen}
      open={props.open}
      content={<Content {...props} setOpened={props.setOpen} />}
      placement={'right'}
    >
      {props.children}
    </Popup>
  )
}
