import Api from 'services/Api'
import Database from 'services/db/Database'
import { AccountDoc, PaddleCheckout } from 'types/api/changes'
import { getOrUpdateDeviceId } from 'utils/device'
import { Model } from 'types/model'

export async function updateAccountAfterPlanSubscription(
  account: Model<AccountDoc>,
  paddlePayload: PaddleCheckout
) {
  if (paddlePayload.checkout.completed) {
    return updatePaymentOnServer({
      UserID: account._id,
      CheckoutEmail: paddlePayload.user.email,
      CheckoutId: paddlePayload.checkout.id,
      SubscriptionId: paddlePayload.checkout.subscriptionId,
      DeviceChangeID: getOrUpdateDeviceId()
    }).then((serverResponse) => {
      const serverAccount = serverResponse.data
      return Database.write(async () => {
        return account.update((local) => {
          // Update products based on server response.
          local.Products = serverAccount.Products
          local.Email = serverAccount.Email
        })
      })
    })
  }
}

export async function cancelPlanAndUpdateAccount(
  account: Model<AccountDoc>,
  subscriptionId: number
) {
  return cancelPlan({
    userId: account._id,
    subscriptionId: subscriptionId,
    DeviceChangeID: getOrUpdateDeviceId()
  }).then((serverResponse) => {
    const serverAccount = serverResponse.data
    return Database.write(async () => {
      return account.update((local) => {
        // Update products based on server response.
        local.Products = serverAccount.Products
      })
    })
  })
}

export async function changePlanAndUpdateAccount(
  account: Model<AccountDoc>,
  subscriptionId: number,
  planId: number
) {
  return changePlan({
    userId: account._id,
    subscriptionId: subscriptionId,
    planId: planId,
    DeviceChangeID: getOrUpdateDeviceId()
  }).then((serverResponse) => {
    const serverAccount = serverResponse.data
    return Database.write(async () => {
      return account.update((local) => {
        // Update products based on server response.
        local.Products = serverAccount.Products
      })
    })
  })
}

export async function tryPlanAndUpdateAccount(
  account: Model<AccountDoc>,
  planId: string
) {
  return tryPlan({
    userId: account._id,
    planId: planId,
    DeviceChangeID: getOrUpdateDeviceId()
  }).then((serverResponse) => {
    const serverAccount = serverResponse.data
    return Database.write(async () => {
      return account.update((local) => {
        // Update products based on server response.
        local.Products = serverAccount.Products
      })
    })
  })
}

export async function pauseSubscriptionAndUpdateAccount(
  account: Model<AccountDoc>,
  subscriptionId: number
) {
  return pausePlan({
    userId: account._id,
    subscriptionId,
    DeviceChangeID: getOrUpdateDeviceId()
  }).then((serverResponse) => {
    const serverAccount = serverResponse.data
    return Database.write(async () => {
      return account.update((local) => {
        // Update products based on server response.
        local.Products = serverAccount.Products
      })
    })
  })
}

export async function reconcilePaymentsAndUpdateAccount(
  account: Model<AccountDoc>
) {
  if (!account?._id) return
  return reconcilePayments(account._id).then((serverResponse) => {
    const serverAccount = serverResponse.data
    return Database.write(async () => {
      return account.update((local) => {
        // Update products based on server response.
        local.Products = serverAccount.Products
      })
    })
  })
}

export async function updatePaymentOnServer(
  payload: Record<string, string | number | object | undefined | null>
) {
  return Api.post(`/v1/payments/${payload.UserID}/checkout`, payload)
}

export async function reconcilePayments(userId: string) {
  return Api.get(
    `/v1/payments/${userId}/reconcile?DeviceChangeID=${getOrUpdateDeviceId()}`
  )
}

export async function cancelPlan(
  payload: Record<string, string | number | object | undefined | null>
) {
  return Api.post(`/v1/payments/${payload.userId}/cancel`, payload)
}

export async function changePlan(
  payload: Record<string, string | number | object | undefined | null>
) {
  return Api.post(`/v1/payments/${payload.userId}/changeplan`, payload)
}

export async function pausePlan(
  payload: Record<string, string | number | object | undefined | null>
) {
  return Api.post(`/v1/payments/${payload.userId}/pause`, payload)
}

export async function tryPlan(
  payload: Record<string, string | number | object | undefined | null>
) {
  return Api.post(`/v1/payments/${payload.userId}/try`, payload)
}
