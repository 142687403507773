import { processDownloadedData } from 'components/providers/GetChangesProvider'
import Api from 'services/Api'
import { ChangeDocsByTypes, ProfileDoc } from 'types/api/changes'

export async function updateProfileOnServer(profile: ProfileDoc) {
  const update = await Api.post(`/v1/editProfile/${profile._id}`, profile)

  const profileFromServer = { ...update.data }
  const profileChangeDoc: Record<string, ProfileDoc> = {}
  profileChangeDoc[profileFromServer._id] = {
    ...profileFromServer
  }
  const docs: ChangeDocsByTypes = {
    Group: {},
    GroupEvent: {},
    Account: {},
    MasterLabel: {},
    Profile: profileChangeDoc,
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  await processDownloadedData(docs)

  return update
}
