import { Grid, Switch as MuiSwitch } from '@mui/material'
import { styled } from '@mui/material/styles'

export const InputRoot = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}))

export const Input = styled('input')(({ theme }) => ({
  width: '100%',
  height: 32,
  padding: theme.spacing(0, 2),
  outline: 'none',
  border: '1px solid',
  borderColor: '#F4F4F6',
  boxShadow: '0px 1px 6px 0px #32324714 inset',
  borderRadius: 23,
  fontSize: '0.625rem',
  color: '#3D3B3B',

  '&::placeholder': {
    color: '#A6A6A6'
  },

  '&[data-prefix="true"]': {
    padding: theme.spacing(0, 4, 0, 10)
  },
  '&[data-variant="popup"]': {
    border: 0,
    fontWeight: 400,
    boxShadow: 'none'
  },
  '&[data-variant="regular"]': {
    fontSize: '0.9rem',
    fontWeight: 400,
    boxShadow: 'none',
    borderColor: '#F4F4F6'
  }
}))

export const MultilineInput = styled('textarea')(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  padding: theme.spacing(0, 2),
  outline: 'none',
  borderColor: theme.palette.input.border.primary,
  borderRadius: 23,
  color: theme.palette.text.primary,
  border: 0,
  fontSize: '1.25rem',
  fontWeight: 400,
  boxShadow: 'none'
}))

export const InputPrefixContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
}))

export const InputPrefix = styled('span')(({ theme }) => ({
  position: 'absolute',
  width: 18,
  height: 18,
  left: 16,
  color: theme.palette.input.placeholder,

  '& svg': {
    width: 18,
    height: 18
  }
}))

export const Switch = styled(MuiSwitch)(() => ({
  width: 26,
  height: 14,
  padding: 0,
  overflow: 'visible',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    width: 14,
    height: 14,
    minWidth: 14,
    minHeight: 14,
    '&.Mui-checked': {
      transform: 'translateX(12px)'
    }
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
    minWidth: 14,
    minHeight: 14
  }
}))
