import { useAuth } from 'components/providers/AuthProvider'
import { useCalendar } from 'components/providers/CalendarProvider'
import { joinGroupIfNeeded } from 'components/providers/GetChangesProvider'
import { ReactNode, useEffect, useState } from 'react'
import { localGroupById } from 'services/db/Queries/GroupQueries'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { LocalStorageKey } from 'types/services/localStorage'
import { isUserActiveInGroup } from 'utils/api/groups'

import CalendarHeader from './CalendarHeader'
import CalendarView from './CalendarView'
import * as Styled from './styled'
import { CalendarRange } from 'types/components/calendar'
import { ClickAwayListener } from '@mui/material'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import TFMEventsView from 'components/tfmDateSortedItems/TFMEventsView'
import { DocType } from 'types/api/changes'
import dayjs from 'dayjs'
import { ThirdPartyAccountByEmail } from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import { fetchCalendarsFromGoogle } from 'utils/google_events'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'
import { ConnectedGoogleCalendar } from 'types/google_events'
import Database from 'services/db/Database'
import ThirdPartyCalendarAccount from 'model/models/ThirdPartyCalendarAccount'
import { CollectionType } from 'types/model'
import { Account } from 'services/db/Queries/AccountQueries'
import { useIcloud } from 'components/providers/icloud/IcloudProvider'

export interface CalendarProps {
  readOnly?: boolean
  defaultView?: CalendarRange
  showOnlySelectedGroup?: boolean
  groupId?: string
  dayNameFormat?: string
}

export default function Calendar(props: CalendarProps) {
  const calendar = useCalendar()
  const auth = useAuth()
  const appUItype = useAppUIType()
  const icloud = useIcloud()

  function joinGroupFromCache() {
    /**
     * join pending group here, to update screen after join
     */
    if (auth.firstGetChangesFinished) {
      try {
        joinGroupIfNeeded().then((group) => {
          if (group) {
            Logger.debug('joined group', group)
            calendar.onGroupServer(group._id ?? '')
          }
        })
      } catch (e) {
        Logger.red('join', e)
      }
    }
  }

  useEffect(() => {
    localGroupById(LocalStorage.get(LocalStorageKey.CACHED_GROUP_ID_JOIN) ?? '')
      .then((group) => {
        //group found, need to check if user active there, if so, perform group selection

        if (group) {
          if (
            isUserActiveInGroup(
              group,
              LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
            )
          ) {
            calendar.onGroupServer(group._id)

            LocalStorage.remove(LocalStorageKey.CACHED_GROUP_ID_JOIN)
            LocalStorage.remove(LocalStorageKey.CACHED_GROUP_PASSWORD)
          } else {
            joinGroupFromCache()
          }
        }
      })
      .catch((e) => {
        console.error(e)
        //group not found, or error during check if user are part of a group
        try {
          joinGroupFromCache()
        } catch (e) {
          console.error(e)
        }
      })

    Account().then((account) => {
      /**
       * We collect all the connected accounts by email keys
       */

      const connectedEmails = Object.keys(
        account?.ConnectedCalAccounts ?? {}
      ).filter((item) => item.includes('@'))

      connectedEmails.forEach(async (key) => {
        await ThirdPartyAccountByEmail(key, ProviderType.GOOGLE).then(
          (thirdPartyAcc) => {
            Logger.blue('Account:', thirdPartyAcc)
            if (!thirdPartyAcc) {
              // in case it's not exist already
              if ((account.ConnectedCalAccounts[key] as any).Google) {
                Logger.blue('Account:Creating Google Calendar', key)
                fetchCalendarsFromGoogle(
                  key,
                  (account.ConnectedCalAccounts[key] as any).Google
                ).catch((error) => {
                  const connectedGoogleCalendar: ConnectedGoogleCalendar = (
                    account.ConnectedCalAccounts[key] as any
                  ).Google
                  Logger.pink(
                    'creating calendar with issue',
                    connectedGoogleCalendar
                  )
                })
              }
            }
          }
        )

        await ThirdPartyAccountByEmail(key, ProviderType.APPLE).then((acc) => {
          if (!acc) {
            if ((account.ConnectedCalAccounts[key] as any).iCloud) {
              ThirdPartyAccountByEmail(key, ProviderType.APPLE)
                .then((account) => {
                  Logger.blue('Account:iCloud', account)
                  return Database.write(async () => {
                    return account
                      ? account.update((local) => {})
                      : Database.getCollection<ThirdPartyCalendarAccount>(
                          CollectionType.GoogleAccount
                        ).create((local) => {
                          local.provider = ProviderType.APPLE
                          local.email = key
                        })
                  })
                })
                .then((thirdPartyAccount) => {
                  console.log('thirdPartyAccount', thirdPartyAccount)
                  icloud.sync()
                })
            }
          }
        })
      })
    })

    //clear popup state on very first load

    LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
  }, [auth.firstGetChangesFinished])

  const isEmbed = window.self !== window.top

  let content: ReactNode = <></>

  // we need this hook in order to refresh event data on a screen based on a visible area
  const [localStorageValue, setLocalStorageValue] = useState(
    LocalStorage.get(LocalStorageKey.VISIBLE_FROM)
  )
  useEffect(() => {
    function handleStorageChange() {
      setTimeout(() => {
        setLocalStorageValue(LocalStorage.get(LocalStorageKey.VISIBLE_FROM))
      }, 1)
    }

    window.addEventListener('storage', handleStorageChange)

    // Cleanup function
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  switch (appUItype.uiType.value) {
    case AppUIType.calendars:
      content = (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            calendar.onGroupInfo(undefined)
            if (LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) != '1') {
              RemoveTempEvents()
              LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
            }
          }}
        >
          <>
            <CalendarHeader groupID={calendar.groupServerID} />

            <div
              style={{
                position: 'relative',
                height: '100%'
              }}
            >
              <CalendarView
                dayNameFormat={props.dayNameFormat}
                defaultView={props.defaultView}
                readOnly={props.readOnly}
                groupID={props.groupId ?? calendar.groupServerID}
                showOnlySelectedGroup={props.showOnlySelectedGroup}
                visibleFrom={dayjs(
                  LocalStorage.get(LocalStorageKey.VISIBLE_FROM)
                )
                  .subtract(1, 'day')
                  .unix()}
                visibleTo={dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_TO))
                  .add(1, 'day')
                  .unix()}
              />
            </div>
          </>
        </ClickAwayListener>
      )
      break
    case AppUIType.notes:
      return <TFMEventsView type={DocType.NOTE} />

    case AppUIType.tasks:
      return <TFMEventsView type={DocType.TASK} />
  }

  return (
    <Styled.Calendar
      sx={{
        boxShadow: `0px 4px ${
          isEmbed || props.readOnly ? '15px' : '30px'
        } rgba(0, 0, 0, 0.3) !important`
      }}
      padding={false}
    >
      {content}
    </Styled.Calendar>
  )
}
