import { SelectableItem } from 'components/popups/SelectItemWithSearchPopup'
import * as Styled from './styled'
import { theme } from 'theme'
import { ReactNode } from 'react'
import { t } from 'i18next'
import {
  LandlineTypeIcon,
  MobileTypeIcon,
  ProIcon,
  ProRedIcon
} from 'assets/icons'
import { Box, ClickAwayListener } from '@mui/material'
import { useAuth } from 'components/providers/AuthProvider'

export interface PhoneTypePopupProps {
  children: ReactNode
  open: boolean
  setOpen: (b: boolean) => void
}

const types: SelectableItem[] = [
  {
    id: 'Mobile',
    title: t('mobile'),
    subTitle: `${t('mobileSubtitle')}`,
    leftIcon: <MobileTypeIcon width={20} height={20} />
  },
  {
    id: 'Landline',
    title: t('landline'),
    subTitle: `${t('landlineSubtitle')}`,
    leftIcon: (
      <LandlineTypeIcon style={{ marginTop: 0 }} width={20} height={20} />
    ),
    rightIcon: <ProRedIcon width={20} height={20} />
  }
]

export default function PhoneTypePopup(props: PhoneTypePopupProps) {
  const auth = useAuth()
  return (
    // @ts-ignore
    <Styled.PhoneTypePopup
      visible={props.open}
      placement={'bottom'}
      offset={[155, 8]}
      content={
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          style={{ pointerEvents: 'all' }}
        >
          {types.map((item) => {
            return (
              <ClickAwayListener
                key={item.id}
                onClickAway={function (event: MouseEvent | TouchEvent): void {
                  props.setOpen(false)
                }}
              >
                <Styled.PhoneTypeContainer
                  onClick={() => {
                    console.log(item)
                    auth.onPhoneType(item.id as any)
                    props.setOpen(false)
                  }}
                >
                  {item.leftIcon}
                  <Box display={'flex'} flexDirection={'column'}>
                    <Box
                      width={'100%'}
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignContent={'center'}
                      alignItems={'center'}
                    >
                      <Styled.PhoneTypeText>{item.title}</Styled.PhoneTypeText>
                      {item.rightIcon}
                    </Box>
                    <Styled.PhoneTypeMisc>{item.subTitle}</Styled.PhoneTypeMisc>
                  </Box>
                </Styled.PhoneTypeContainer>
              </ClickAwayListener>
            )
          })}
        </Box>
      }
      theme={theme}
    >
      <div style={{ width: '100%', height: '100%' }}>{props.children}</div>
    </Styled.PhoneTypePopup>
  )
}
