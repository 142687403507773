import { darken } from '@mui/material'
import dayjs from 'dayjs'
import ThirdPartyCalendarAccount from 'model/models/ThirdPartyCalendarAccount'
import { Frequency, RRule } from 'rrule'
import {
  connectThirdPartyToServer,
  ConnectThirdPartyToServerData
} from 'services/api/group'
import Database from 'services/db/Database'

import {
  ThirdPartyAccountByEmail,
  ThirdPartyCalendarAccounts
} from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import {
  googleEventsGroup,
  localGroupById
} from 'services/db/Queries/GroupQueries'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import {
  AccountDoc,
  DocType,
  EventDoc,
  EventRecurrence,
  EventStatus,
  Exclusion
} from 'types/api/changes'
import {
  ConnectedGoogleCalendar,
  DefaultReminder,
  GoogleCalendar,
  GoogleEvent,
  ICalendar
} from 'types/google_events'
import { CollectionType } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'

import { mapRecurrency } from './api/events'
import { AxiosError } from 'axios'
import { EventReminder } from 'types/api/changes'
import {
  cacheOtherCalendarsEvents,
  createGeneralGroupIfNeeded,
  ProviderType,
  removePrefix
} from 'components/providers/CalendarSyncProdivder'
import { otherCalendarEvents } from 'services/db/Queries/Event'
import { getDefaultTimezone } from 'index'

//by this prefix I check is current shared calendar is google event or not, because we have also UI differences on shared calendar info screen and on event info screen
export const prefixGoogleEventsGroup = 'GOOGLE_EVENTS'

export const CurrentConvertedCalendars: ICalendar[] = []

const emailScope = 'https://www.googleapis.com/auth/userinfo.email'
const eventScope = 'https://www.googleapis.com/auth/calendar.events'
const calendarScope = 'https://www.googleapis.com/auth/calendar'
const tasksScope = 'https://www.googleapis.com/auth/tasks'
const SCOPES = `${eventScope} ${calendarScope} ${emailScope}`

interface GoogleSignInData {
  access_token: string
  expires_at: number
}

/**
 * triggers google dialog to authorize web site with google apis to receive a token
 */
export function connectGoogleCalendar(
  onComplete?: (calendar: ICalendar) => void,
  onCancel?: () => void,
  startedProcess?: () => void
) {
  //client with a callback which will receive a google apis token
  if (google) {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      access_type: 'offline',
      ux_mode: 'popup',
      scope: SCOPES,
      callback: (res: { scope: string; error: any; code: any }) => {
        if (startedProcess) startedProcess()
        const grantedScopes: string[] | undefined = res.scope
          ? res.scope.split(' ')
          : undefined

        if (res.error) {
          if (onCancel) onCancel()
        } else if (
          SCOPES.split(' ')?.every((item) => grantedScopes?.includes(item))
        ) {
          Logger.blue('GoogleEvents:Init', res)
          connectThirdPartyToServer({
            authorizationCode: res.code,
            appId: process.env.REACT_APP_APP_TYPE?.toLowerCase().includes(
              'groupcal'
            )
              ? 'groupcal'
              : 'twentyfourme',
            action: 'CONNECT'
          })
            .then((serverResponse) => {
              const token = serverResponse

              return fetchCalendarsFromGoogle(token.email, token)
            })
            .then((primaryCalendar) => {
              if (onComplete && primaryCalendar) onComplete(primaryCalendar)
            })
            .catch(() => {
              if (onCancel) onCancel()
            })
        } else {
          if (onCancel) onCancel()
        }
      }
    })
    client.requestCode()
  }
}

function refreshAccessToken(
  email: string,
  calendarFuncParams: {
    fromDateISO?: string
    toDateISO?: string
    retryAttempts: number
  }
) {
  connectThirdPartyToServer({
    email: email,
    action: 'REFRESH_TOKEN',
    type: 'Google'
  })
    .then((tokenResponse) => {
      const newToken = tokenResponse
      return fetchCalendarsFromGoogle(
        newToken.email,
        {
          access_token: newToken.access_token,
          expiry_date: newToken.expiry_date,
          email: '',
          username: '',
          type: '',
          connected: false
        },
        calendarFuncParams?.fromDateISO,
        calendarFuncParams?.toDateISO,
        undefined,
        calendarFuncParams?.retryAttempts
      )
    })
    .catch((error) => {
      Logger.red('error refresh token', error)

      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          //we cant refresh token so marking group as problematic to notify user to re-connect the google calendar

          Database.write(async () => {
            return await googleEventsGroup(
              `${prefixGoogleEventsGroup}${email}`
            ).then((group) => {
              group?.update((local) => {
                local.SyncProblem = '1'
              })
            })
          })
        }
      }
    })
}

/**
 * Triggers entire google sync for all accounts
 * @param start ISO date format with start of the window which events to sync
 * @param end ISO date format with end of the window which events to sync
 */
export async function syncAllGoogleAccounts(
  start: string,
  end: string,
  query?: string
) {
  const googleAccounts = await ThirdPartyCalendarAccounts()
  googleAccounts.forEach(async (googleAcc) => {
    const group = await localGroupById(googleAcc.groupId)
    Logger.pink('group', group)
    if (group && group.SyncProblem != '1') {
      try {
        syncGoogleAcc(
          googleAcc,
          start,
          end.length > 0
            ? dayjs(end).toISOString()
            : dayjs(start).add(7, 'day').toISOString(),
          query
        )
      } catch (e) {
        console.error(e)
      }
    }
  })
}

/**
 * Synchronizes google account data
 * @param googleAcc google account to sync event from
 * @param start ISO date format with start of the window which events to sync
 * @param end ISO date format with end of the window which events to sync
 */
export async function syncGoogleAcc(
  googleAcc: ThirdPartyCalendarAccount,
  start: string,
  end: string,
  query?: string
) {
  try {
    if (googleAcc) {
      return await fetchCalendarsFromGoogle(
        googleAcc.email,
        {
          access_token: googleAcc.token,
          expiry_date: googleAcc.tokenExpire,
          email: '',
          username: '',
          type: '',
          connected: false
        },
        start,
        end,
        query
      )
    }
  } catch (e) {
    console.error('error sync google cal', e)
  }
}

/**
 * Call this function from whatever place, with valid token and expiry date in order to trigger sync
 *
 * @param signInData data to execute api calls to google servers
 * @param fromDateISO "from" date string in ISO format to fetch events
 * @param toDateISO "to" date string in ISO format to fetch events
 * @returns void
 */
export async function fetchCalendarsFromGoogle(
  email: string,
  signInData: ConnectThirdPartyToServerData,
  from?: string,
  to?: string,
  query?: string,
  retryAttempts = 0
) {
  try {
    const startDateTime = LocalStorage.get(
      LocalStorageKey.VISIBLE_FROM
    ) as string
    const endDateTime = LocalStorage.get(LocalStorageKey.VISIBLE_TO) as string

    const fromDateISO = from || startDateTime

    const toDateISO = to || endDateTime

    const url = `https://www.googleapis.com/calendar/v3/users/me/calendarList`

    return await fetch(url, {
      headers: {
        Authorization: `Bearer ${signInData.access_token}`
      }
    })
      .then((res) => {
        if (res.status !== 401) {
          return res.json()
        }

        if (res.status === 401 && retryAttempts < 2) {
          return refreshAccessToken(email, {
            fromDateISO,
            toDateISO,
            retryAttempts: retryAttempts + 1
          })
        }
      })
      .then(async (response) => {
        if (!response || !response.items) return Promise.reject()
        const calendars: GoogleCalendar[] = response.items.filter(
          (calendar: { accessRole: string }) =>
            calendar.accessRole != 'freeBusyReader' // per our logic we ignore all calendars where role is 'freeBusyReader'
        )

        Logger.pink('google', calendars)
        //cache primary calendar based on a list of calendars received from google APIs
        const primaryCalendar = calendars.find((calendar) => calendar.primary)

        if (primaryCalendar) {
          /**
           * In order to preserve all GroupCal logic re events and groups 'out of the box'
           * We decided to create a Shared Calendar per each google primary calendar (if it's not exist already)
           *
           * This local only shared calendar is used to group ALL events (from primary calendar and all sub calendars) under one shared calendar
           * So we can preserve 'GroupCal-like' look on a UI
           */
          const group = await createGeneralGroupIfNeeded(
            prefixGoogleEventsGroup,
            primaryCalendar.id ?? '',
            primaryCalendar.backgroundColor,
            primaryCalendar.summary
          )

          const existingGoogleAcc = await ThirdPartyAccountByEmail(
            primaryCalendar?.id ?? '',
            ProviderType.GOOGLE ?? ''
          )
          /**
           * I also locally cache google account data to easier retreive token in future
           * (update in case it's already exist)
           */
          const googleAcc = await Database.write(async () => {
            return existingGoogleAcc
              ? existingGoogleAcc.update((local) => {
                  local.token = signInData.access_token
                  local.provider = ProviderType.GOOGLE
                  local._raw['tokenExpire'] = signInData.expiry_date
                })
              : Database.getCollection<ThirdPartyCalendarAccount>(
                  CollectionType.GoogleAccount
                ).create((local) => {
                  local.token = signInData.access_token
                  local.provider = ProviderType.GOOGLE
                  local._raw['tokenExpire'] = signInData.expiry_date
                  local.email = primaryCalendar?.id ?? ''
                  local.groupId = group._id ?? ''
                })
          })

          Logger.debug('created primary google account data', googleAcc)
        }

        for (let index = 0; index < calendars.length; index++) {
          const calendar: GoogleCalendar = calendars[index]
          /**
           * For the UI usage only.
           * I cache all available calendars (primary and sub-calendars) in memory in order to show calendar picker on event details
           */
          if (
            CurrentConvertedCalendars.find(
              (calendarFromServer) => calendarFromServer.id === calendar.id
            ) === undefined
          ) {
            calendar.title = calendar.summary
            calendar.primaryCalendarEmail = primaryCalendar?.id ?? ''
            calendar.prefix = prefixGoogleEventsGroup
            CurrentConvertedCalendars.push(calendar)
          }

          fetchEventsFromGoogle(
            calendar,
            signInData,
            fromDateISO,
            toDateISO,
            query
          )
            .then(async (data) => {
              const eventsFromGoogle: GoogleEvent[] = data.items
              const reminders: DefaultReminder[] = data.defaultReminders
              const converted = mapGoogleEventsToGroupCalEvents(
                calendar,
                primaryCalendar,
                eventsFromGoogle,
                reminders
              )

              const existingGoogleEvents = (
                await otherCalendarEvents(
                  `${prefixGoogleEventsGroup}${primaryCalendar?.id}`,
                  fromDateISO,
                  toDateISO
                )
              ).filter(
                (event) =>
                  removePrefix(event.ThirdPartyID ?? '') === calendar.id &&
                  event._id.length > 0
              )

              return cacheOtherCalendarsEvents(
                data,
                existingGoogleEvents,
                converted,
                query
              )
            })
            .catch((error) => {
              console.error(error)
            })
        }

        return primaryCalendar
      })
  } catch (e) {
    console.error('error get calendars', e)
    Database.write(async () => {
      return await googleEventsGroup(`${prefixGoogleEventsGroup}${email}`).then(
        (group) => {
          group?.update((local) => {
            local.SyncProblem = '1'
          })
        }
      )
    })
    return undefined
  }
}
/**
 *
 * @param calendar current calendar to fetch events from
 * @param signInData data to execute api calls to google servers
 * @param fromDateISO "from" date string in ISO format to fetch events
 * @param toDateISO "to" date string in ISO format to fetch events
 * @returns array of promises with pending changes to watermelondb (create/update/delete)
 */
function fetchEventsFromGoogle(
  calendar: GoogleCalendar,
  signInData: ConnectThirdPartyToServerData,
  fromDateISO: string,
  toDateISO: string,
  queryText?: string
) {
  const key = process.env.REACT_APP_FIREBASE_API_KEY
  let url = `https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events?key=${key}&timeMin=${fromDateISO}&timeMax=${toDateISO}`

  if (queryText) {
    url = `https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events?key=${key}&q=${queryText}`
  }

  //I'm replacing that sign to prevent api crashes
  return fetch(url.replace('#', '%23'), {
    headers: {
      Authorization: `Bearer ${signInData.access_token}`
    }
  }).then((res) => {
    // Check if unauthorized status code is return open sign in popup

    if (res.status !== 401) {
      return res.json()
    }
  })
}

/**
 *
 * @param googleCalendar current google calendar from where list of events came from
 * @param primaryCalendar primary calendar for connected google account
 * @param list list of 'google' events
 * @param reminders default reminders for this calendar
 * @returns list of 'groupcal' events
 */
function mapGoogleEventsToGroupCalEvents(
  googleCalendar: GoogleCalendar,
  primaryCalendar?: GoogleCalendar,
  list?: GoogleEvent[] | null,
  reminders?: DefaultReminder[]
): EventDoc[] {
  if (!list) return []

  return list
    .filter((googleEvent) => {
      /**
       * filtering out all 'canceled' events, because we need only active events. Also usually 'canceled' events are exclusions for recurrent events
       * also we need to filter out events which we are declined
       */
      return googleEvent.status != 'cancelled'
    })
    .filter((googleEvent) => {
      /**
       * also we need to filter out events which we are declined
       */
      const me = googleEvent.attendees?.find((attendee) => attendee.self)
      return (me?.responseStatus ?? '') != 'declined'
    })
    .map((parentGoogleEvent) => {
      let groupCalReminders: EventReminder[] | undefined = []
      if (parentGoogleEvent.reminders) {
        groupCalReminders = convertGoogleRemindersToGroupcalReminders(
          parentGoogleEvent?.reminders.useDefault
            ? reminders
            : parentGoogleEvent.reminders.overrides
        )
      }
      const groupCalEvent = {
        ...googleEventToGroupcal(
          parentGoogleEvent,
          list.filter(
            (googleEvent) =>
              googleEvent.recurringEventId === parentGoogleEvent.id
          )
        ),
        ThirdPartyID: `${googleCalendar.id}`,
        OwnerID:
          googleCalendar.accessRole === 'reader'
            ? 'readonly'
            : `${parentGoogleEvent.organizer?.email}` ?? '',
        Color: darken(googleCalendar.backgroundColor, 0.15),
        GroupID: `${prefixGoogleEventsGroup}${primaryCalendar?.id}`,
        Reminder: groupCalReminders
      }

      return groupCalEvent
    })
}

/**
 * Converts 'google' event to a 'groupcal' event
 * @param parentGoogleEvent can be regular event and recurrent event
 * @param exclusions array of exclusions for recurrent events
 * @returns created 'GroupCal' model document based on 'google' data
 */
export function googleEventToGroupcal(
  parentGoogleEvent: GoogleEvent,
  exclusions?: GoogleEvent[]
): EventDoc {
  const isAllDay = parentGoogleEvent.start?.date != undefined
  const timeZone = isAllDay
    ? 'UTC'
    : parentGoogleEvent?.start?.timeZone ?? getDefaultTimezone()

  let startTime = dayjs(
    parentGoogleEvent?.start?.dateTime ?? parentGoogleEvent.start?.date
  ).unix()

  if (parentGoogleEvent.start?.date) {
    startTime = dayjs.utc(parentGoogleEvent.start.date).unix()
  }

  const end = isAllDay
    ? dayjs.utc(parentGoogleEvent.end?.date).unix().toString()
    : `${dayjs(
        parentGoogleEvent.end?.dateTime ?? parentGoogleEvent.end?.date
      ).unix()}`

  return {
    _id: `${parentGoogleEvent.id}_${
      parentGoogleEvent.organizer?.self ? '1' : '0'
    }`,
    TimeZoneNameID: timeZone,
    StartDate: `${startTime}`,
    EndDate: end,
    Text: parentGoogleEvent.summary,
    AllDay: isAllDay ? '1' : '0',

    OwnerID:
      parentGoogleEvent.organizer?.email ??
      LocalStorage.get(LocalStorageKey.USER_ID) ??
      '',
    Status: EventStatus.ACTIVE,
    local_id: parentGoogleEvent.id,
    LastUpdate: `${dayjs(parentGoogleEvent?.updated).unix()}`,
    Rank: `${dayjs(parentGoogleEvent?.created).unix()}`,
    Notes: parentGoogleEvent.description
      ? [
          {
            Note: parentGoogleEvent.description,
            NoteID: dayjs().unix(),
            Status: 1,
            FilePath: ''
          }
        ]
      : [],
    Recurrence: convertRRuleToGroupcalRecurrence(
      parentGoogleEvent.recurrence,
      dayjs(
        parentGoogleEvent?.start?.dateTime ?? parentGoogleEvent.start?.date
      ).unix(),
      exclusions
    ),
    Location: parentGoogleEvent.location
      ? {
          Address: parentGoogleEvent.location
        }
      : undefined,
    ConferenceData: parentGoogleEvent.conferenceData,
    attendees: parentGoogleEvent.attendees,
    canAddAttendees:
      parentGoogleEvent.guestsCanInviteOthers === false ? '0' : undefined,
    Reminder: convertGoogleRemindersToGroupcalReminders(
      parentGoogleEvent?.reminders?.overrides
    ),
    Type: DocType.REGULAR_EVENT,
    SupplementaryGroupsIDs: []
  }
}

/**
 * Converts 'groupcal' events to the 'google' events
 * @param events 'GroupCal' event models list
 * @returns 'google' events array
 */
export function mapGroupcalEventsToGoogleEvents(
  events: EventDoc[]
): GoogleEvent[] {
  if (!events) return []

  return events.map((groupcalEvent) =>
    groupcalEventToGoogleEvent(groupcalEvent)
  )
}

export function groupcalEventToGoogleEvent(
  groupcalEvent: EventDoc,
  simplified = false
): GoogleEvent {
  const isAllDay = groupcalEvent.AllDay === '1'
  const googleEvent: GoogleEvent = {
    end: {
      date: isAllDay
        ? dayjs(Number(groupcalEvent.EndDate) * 1000)
            .add(groupcalEvent.Recurrence ? 0 : 1, 'day')
            .format('YYYY-MM-DD')
        : undefined,
      dateTime: isAllDay
        ? undefined
        : dayjs(Number(groupcalEvent.EndDate) * 1000).toISOString(),
      timeZone: isAllDay ? 'UTC' : groupcalEvent.TimeZoneNameID
    },
    start: {
      date: isAllDay
        ? dayjs(Number(groupcalEvent.StartDate) * 1000).format('YYYY-MM-DD')
        : undefined,
      dateTime: isAllDay
        ? undefined
        : dayjs(Number(groupcalEvent.StartDate) * 1000).toISOString(),
      timeZone: isAllDay ? 'UTC' : groupcalEvent.TimeZoneNameID
    },
    summary: groupcalEvent.Text ?? '',
    description:
      groupcalEvent.Notes?.length && groupcalEvent.Notes?.length > 0
        ? groupcalEvent.Notes?.[0].Note
        : ''
  }

  if (groupcalEvent.local_id) {
    googleEvent.id = groupcalEvent.local_id
  }

  if (groupcalEvent.Recurrence) {
    googleEvent.recurrence = mapRecurrency(groupcalEvent, false).split('\n\r')
  }

  if (groupcalEvent.attendees) {
    googleEvent.attendees = groupcalEvent.attendees
  }

  if (groupcalEvent.Reminder) {
    googleEvent.reminders = {
      useDefault: false,
      overrides: groupcalEvent.Reminder.map((groupCalReminder) => ({
        method: 'popup',
        minutes: groupCalReminder.offset
      }))
    }
  }

  if (groupcalEvent.Location) {
    googleEvent.location = groupcalEvent.Location.Address
  }

  if (simplified) {
    return {
      start: googleEvent.start,
      end: googleEvent.end,
      attendees: googleEvent.attendees,
      id: googleEvent.id
    }
  }

  return googleEvent
}

/**
 *
 * @param recurrence google event recurrence
 * @param startSeconds start of an event
 * @param exclusions array of 'canceled' events from google api
 * @returns 'GroupCal' recurrence object
 */
export function convertRRuleToGroupcalRecurrence(
  recurrence: string[] | undefined,
  startSeconds: number,
  exclusions?: GoogleEvent[] | undefined,
  rrule?: RRule
): EventRecurrence | undefined {
  if ((!recurrence || recurrence.length === 0) && !rrule) return undefined

  const parsedRrule =
    rrule ??
    RRule.fromString(
      recurrence?.find((rrule) => {
        return rrule.startsWith('RRULE')
      }) ?? ''
    )

  /**
   * there are might be exclusions from the rule within
   * we need to convert them to a dates in order to save as groupcal object
   */
  const exclusionsFromRule = recurrence
    ?.filter((rrule) => {
      return rrule.startsWith('EXDATE')
    })
    .map((exclusion) => {
      const eventExclusion: Exclusion = {
        Date: `${dayjs(exclusion, 'YYYYMMDDTHHmmss').unix()}`,
        Status: EventStatus.DELETED
      }
      return eventExclusion
    })

  let unit = '1'

  switch (parsedRrule.options.freq) {
    case Frequency.DAILY:
      unit = '1'
      break
    case Frequency.WEEKLY:
      unit = '2'
      break
    case Frequency.MONTHLY:
      unit = '3'
      break
    case Frequency.YEARLY:
      unit = '4'
      break
  }

  const DAY_IN_SECONDS = 86400

  let eventRecurrence: EventRecurrence = {
    Unit: Number(unit),
    Interval: parsedRrule.options.interval ?? 1
  }

  if (parsedRrule.options.count != null && parsedRrule.options.count != 0) {
    eventRecurrence = {
      ...eventRecurrence,
      EndDate: `${
        startSeconds + (parsedRrule.options.count - 1) * DAY_IN_SECONDS
      }`
    }
  }

  if (parsedRrule.options.until && parsedRrule.options.until != null) {
    eventRecurrence = {
      ...eventRecurrence,
      EndDate: `${parsedRrule.options.until.valueOf() / 1000}`
    }
  }

  if (exclusions && exclusions.length > 0) {
    exclusions.forEach((googleEvent) => {
      exclusionsFromRule?.push({
        Date: `${dayjs(googleEvent.originalStartTime?.dateTime).unix()}`,
        Status: EventStatus.DELETED
      })
    })
  }

  if (exclusionsFromRule && exclusionsFromRule.length > 0) {
    eventRecurrence = {
      ...eventRecurrence,
      Exclusion: exclusionsFromRule
    }
  }

  return eventRecurrence
}

export function convertGoogleRemindersToGroupcalReminders(
  reminders: DefaultReminder[] | undefined
): EventReminder[] | undefined {
  if (!reminders) return undefined

  return reminders
    .filter((reminder) => reminder.method === 'popup')
    .map((reminder) => ({
      isOn: '1',
      isRelativeReminder: '1',
      offset: reminder.minutes,
      AlertTime: ''
    }))
}

export function syncGoogleForVisibleArea() {
  const start = LocalStorage.get(LocalStorageKey.VISIBLE_FROM) as string
  const end = LocalStorage.get(LocalStorageKey.VISIBLE_TO) as string
  syncAllGoogleAccounts(start, end)
}
