import { Box, ClickAwayListener, Grid, lighten } from '@mui/material'
import withObservables from '@nozbe/with-observables'
import Avatar from '../../../components/badges/Avatar'
import EventPopup from '../../popups/eventInfo/EventPopup'
import ObserveService from '../../../services/db/Observe'
import { compose } from 'recompose'
import { from } from 'rxjs'
import {
  AccountDoc,
  AggregatedDeliveryStatus,
  DocType,
  EventDoc,
  EventStatus,
  GroupDoc,
  MasterLabelDoc,
  ParticipantDataDoc,
  ProfileDoc,
  UserSettingsDoc
} from '../../../types/api/changes'
import { Model } from '../../../types/model'
import * as Styled from './styled'
import { eventStatusIcon, isSomeDay } from '../../../utils/api/events'
import { useEffect, useState } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { CalendarRange } from 'types/components/calendar'
import { defaultGroupImage } from 'utils/api/groups'
import { APP_COLOR, isColorLight } from 'utils/colors'
import { isTrulyEmpty } from 'utils/object'
import { isTextRTL } from 'utils/lang'

import './styles.scss'
import { t } from 'i18next'
import dayjs from 'dayjs'
import dateToTimeString from '.'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { defaultTaskColor, provideTaskColor } from 'utils/masterLabel'
import {
  Notes,
  RecurrentItemCalendar,
  TypeEvent,
  TypeNote,
  TypeTask
} from 'assets/icons'
import SomedayItem from 'components/tfmDateSortedItems/SomedayItem'
import { CSSProperties } from 'styled-components'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import Item from 'components/tfmDateSortedItems/Item'
import { LocationLabel } from '../styled'
import Logger from 'services/Logger'
import {
  eventIdFormat,
  isGroupInSupplementaryGroups as isGroupInSupplementaryGroups,
  provideReferenceGroup as provideReferenceGroupId
} from 'utils/event'
import { localGroupById } from 'services/db/Queries/GroupQueries'
import { useCalendar } from 'components/providers/CalendarProvider'
import { uuid } from 'utils/uuid'
import { getDefaultTimezone } from 'index'
import { isConvertedGroupId } from 'utils/groups'

export interface EventProps {
  topGroup?: Model<GroupDoc>
  title: string
  event?: Model<EventDoc> | EventDoc | undefined
  group?: Model<GroupDoc> | GroupDoc | undefined
  account?: Model<AccountDoc> | AccountDoc | undefined
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc | undefined
  masterLabel?: Model<MasterLabelDoc>
  instanceStart: number
  instanceEnd: number
  localParticipants?: Model<ParticipantDataDoc>[]
  profile?: Model<ProfileDoc>
  isStart?: boolean // for the long events, we need to know that we showing popup only for the start of the event
  closeAll?: boolean // for the drag/resize callbacks when we want to hide all opened popups
  alwaysFullSize?: boolean // to display event row fullsize always without checks which view is selected
  onEventDetailsClose?: () => void
  ignoreDim?: boolean
  calendarView?: CalendarRange
  doNotScrollToEvent?: boolean
  fc_id?: string
}

function Event(props: EventProps) {
  const cal = useCalendar()
  const eventView =
    props.calendarView ??
    (LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) as CalendarRange)
  const [eventOpened, setEventOpened] = useState(false)
  const [eventSubinfoGroup, setEventSubinfoGroup] = useState(props.group)

  const [awayClickTriggered, setAwayClickTriggered] = useState(false)

  const awayClick = () => {
    setAwayClickTriggered(true) // Toggle the state
  }

  useEffect(() => {
    if (!eventOpened) setAwayClickTriggered(false)
  }, [eventOpened])

  const taskDesign = isTaskDesign(props.event)

  useEffect(() => {
    if ((props.event?.SupplementaryGroupsIDs?.length ?? 0) > 0) {
      provideReferenceGroupId(props.event, props.userSettings).then((group) => {
        localGroupById(group ?? '').then((group) => {
          setEventSubinfoGroup(group)
        })
      })
    }
  }, [LocalStorage.get(LocalStorageKey.SELECTED_GROUP)])

  const isPendingEvent = props.event?._id?.length === 0 && props.isStart

  const initialOpenState = isPendingEvent || eventOpened

  let color = APP_COLOR
  if ((isGroupcalApp() && props.event) || !taskDesign) {
    const groupsSettings = props.userSettings?.GroupsSettings

    try {
      color =
        (eventSubinfoGroup?.Participants?.[props.event?.OwnerID ?? '']
          ?.MemberColor ||
          (props.event?.Color !== 'null' ? props.event?.Color : undefined) ||
          groupsSettings?.[props.event?.GroupID ?? '']?.GroupColor ||
          groupsSettings?.[eventSubinfoGroup?._id ?? '']?.GroupColor ||
          eventSubinfoGroup?.GroupColor) ??
        APP_COLOR
    } catch (e) {
      // ignore
      console.log(e)
    }

    if (!color) color = APP_COLOR
  } else {
    color = 'white'
  }
  const eventStart = dayjs.unix(props.instanceStart / 1000)
  const eventEnd = dayjs.unix(props.instanceEnd / 1000)
  let newEventSubText = `${dateToTimeString(
    eventStart.toDate(),
    true
  )} - ${dateToTimeString(eventEnd.toDate(), true)}`
  let fontSize = eventView === CalendarRange.MONTH ? '11.5px' : '12px'
  const dir = isTextRTL(props.event?.Text) ? 'rtl' : 'ltr' // Determine the text direction

  if (!props.event) {
    return (
      <Styled.EventContent
        sx={{
          border:
            eventView != CalendarRange.MONTH || taskDesign
              ? `1px solid ${taskDesign ? 'black' : 'white'}`
              : undefined
        }}
        padding={0}
        color={color}
        fontcolor={taskDesign ? 'black' : 'white'}
        fontSize={fontSize}
        style={{ direction: dir }}
        pending={false}
      >
        {eventView != CalendarRange.MONTH ? (
          <Box
            sx={{
              width: '100%',
              flexDirection: 'column',
              display: 'flex',
              color: taskDesign ? 'black' : 'white'
            }}
          >
            {t('newEvent')}
            <SubText text1={newEventSubText} />
          </Box>
        ) : (
          <></>
        )}
      </Styled.EventContent>
    )
  }

  if (
    (props.event?._id?.length ?? 0) > 0 ||
    props.event?.AllDay === '1' ||
    props.event.late
  )
    newEventSubText = ''

  let direction =
    eventView === CalendarRange.MONTH ||
    props.event.AllDay === '1' ||
    props.event.late
      ? 'row-reverse'
      : 'column'

  let location =
    eventView !== CalendarRange.MONTH &&
    props.event.Location &&
    props.event.Location.Address != undefined

  const statusSize = provideStatusIconSize(props.event, eventView)
  const currentUserId = LocalStorage.get(LocalStorageKey.USER_ID)
  const duration = Number(props.event.EndDate) - Number(props.event.StartDate)
  const allCalendarMode = !LocalStorage.get(LocalStorageKey.SELECTED_GROUP)
  const ownerParticipant = props.localParticipants?.find(
    (p) => p.AccountID === props.event?.OwnerID
  )
  const isCurrentUserOwner = props.event.OwnerID === currentUserId

  const isGuest = !currentUserId

  const fullyVisibleEvent =
    props.event.GroupID === LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ||
    !LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ||
    LocalStorage.get(LocalStorageKey.SELECTED_GROUP)?.length === 0 ||
    isGroupInSupplementaryGroups(
      LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ?? '',
      props.event
    )
  const confirmStatus =
    props.event.ParticipantsStatus?.[
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    ]?.ParticipantConfirmationStatus
  const pending =
    ((props.event._id?.length ?? 0) > 0 &&
      (props.event?.RequestConfirmation ?? '') === '1' &&
      props.event.Type !== DocType.REGULAR_EVENT &&
      (!confirmStatus || confirmStatus === '1' || confirmStatus === '4')) ||
    props.event?.attendees?.find(
      (attendee) =>
        attendee.self &&
        attendee.responseStatus?.toLowerCase() !== 'accepted' &&
        attendee.responseStatus?.toLowerCase() !== 'declined'
    ) != null

  const eventDuration = (props.instanceEnd - props.instanceStart) / 60000 //check if this less than 60, that means we have small event rectangle
  let smallEventGap = 59

  if (props.event.Location && typeof props.event.Location !== 'string')
    smallEventGap = 74
  const taskColor = provideTaskColor(
    props.masterLabel,
    props.event,
    props.account
  )

  const eventGroupColor =
    isGroupcalApp() &&
    eventSubinfoGroup?.Participants?.[props.event?.OwnerID ?? '']?.MemberColor
      ? props.userSettings?.GroupsSettings?.[eventSubinfoGroup?._id ?? '']
          ?.GroupColor ?? eventSubinfoGroup?.GroupColor
      : undefined

  const hasLeftIndicator = isTaskDesign(props.event) || eventGroupColor

  let smallEvent =
    !props.alwaysFullSize &&
    (eventDuration < smallEventGap ||
      props.event.AllDay === '1' ||
      props.event.late ||
      eventView === CalendarRange.MONTH ||
      props.event._id?.length === 0)

  const containerStyle = {
    width: eventView === CalendarRange.MONTH ? '100%' : '100%',
    height:
      ((props.event.AllDay === '1' || props.event.late) &&
        (props.event.Type === DocType.TASK ||
          props.event.Type === DocType.NOTE)) ||
      eventView === CalendarRange.MONTH
        ? '18px !important'
        : '100%',
    minHeight: eventView === CalendarRange.MONTH ? undefined : '16px !important'
  }

  let isInAlldaySection =
    (props.event.AllDay === '1' || props.event.late) && !props.alwaysFullSize

  if (eventView === CalendarRange.LISTWEEK) {
    smallEvent = false
    isInAlldaySection = false
    direction = 'column'
    fontSize = '16px'
  }

  const pictureAndStatus = (
    <Grid
      display={{ xs: 'none', lg: 'flex' }}
      flexDirection={direction === 'column' ? 'column' : 'row'}
      alignItems={'center'}
      height={'100%'}
    >
      <div>
        {eventView === CalendarRange.MONTH ? (
          <></>
        ) : allCalendarMode ? (
          <Avatar
            name=""
            size="xxs"
            asGroup
            img={eventSubinfoGroup?.Photo}
            emptyPic={defaultGroupImage(eventSubinfoGroup)}
          />
        ) : (
          <Avatar
            asGroup={isGuest}
            emptyPic={defaultGroupImage(eventSubinfoGroup)}
            img={
              isCurrentUserOwner
                ? props.profile?.ProfilePicture
                : ownerParticipant?.PhotoURL ??
                  defaultGroupImage(eventSubinfoGroup)
            }
            size="xxs"
            name={
              isCurrentUserOwner
                ? 'Y'
                : (isTrulyEmpty(ownerParticipant?.FullName)
                    ? ownerParticipant?.PhoneNumber
                    : ownerParticipant?.FullName) ?? ''
            }
          />
        )}
      </div>
      {isConvertedGroupId(props.event.GroupID ?? '') ? (
        <></>
      ) : (
        <img
          style={{
            overflow: 'hidden'
          }}
          width={statusSize}
          height={statusSize}
          src={eventStatusIcon(props.event)}
        />
      )}
    </Grid>
  )

  const itemLeadingIcons = leadingIcons(props.event)

  const beforeNow = dayjs(props.instanceEnd).isBefore(dayjs())
  const open = props.closeAll === true ? false : initialOpenState ?? false

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={!open ? () => {} : awayClick}
    >
      <div style={{ height: '100%', width: '100%' }}>
        {isTaskDesign(props.event) && eventView === CalendarRange.LISTWEEK ? (
          <Item
            item={props.event}
            title={props.title}
            hideTime
            setShowEvent={() => {}}
            withToggle
            account={props.account}
            userSettings={props.userSettings}
            event={props.event}
            open={props.closeAll === true ? false : initialOpenState ?? false}
            setOpen={setEventOpened}
            instanceStart={props.instanceStart}
            instanceEnd={props.instanceEnd}
            onClose={props.onEventDetailsClose}
          />
        ) : (
          <EventPopup
            awayClickTriggered={awayClickTriggered}
            setAwayClickTriggered={setAwayClickTriggered}
            onClose={props.onEventDetailsClose}
            account={props.account}
            group={props.group}
            event={props.event}
            fc_id={props.fc_id}
            userSettings={props.userSettings}
            open={open}
            setOpen={setEventOpened}
            instanceStart={
              props.event.late
                ? Number(props.event.lateOriginalStartTime) * 1000
                : props.instanceStart
            }
            instanceEnd={
              props.event.late
                ? Number(props.event.lateOriginalEndTime) * 1000
                : props.instanceEnd
            }
          >
            <Styled.EventContent
              id={
                props.event.AllDay === '1'
                  ? `${props.event?.Text}-${dayjs(props.instanceStart).format(
                      eventIdFormat
                    )}`
                  : `${props.event?.Text}-${dayjs(props.instanceStart)
                      .tz(
                        (props.event.TimeZoneNameID?.length ?? 0) > 0
                          ? props.event.TimeZoneNameID
                          : getDefaultTimezone()
                      )
                      .format(eventIdFormat)}`
              }
              sx={{
                ...containerStyle,
                position: 'relative',
                border:
                  eventView != CalendarRange.MONTH || taskDesign
                    ? `1px solid ${taskDesign ? taskColor : 'white'}`
                    : undefined,
                height:
                  eventView === CalendarRange.LISTWEEK
                    ? '47px'
                    : props.alwaysFullSize &&
                      (props.event.AllDay === '1' || props.event.late) &&
                      (props.event.Type === DocType.TASK ||
                        props.event.Type === DocType.NOTE)
                    ? '19px !important'
                    : '100%',
                minHeight:
                  eventView === CalendarRange.LISTWEEK || props.alwaysFullSize
                    ? '47px'
                    : eventView === CalendarRange.MONTH
                    ? undefined
                    : '19px',
                backgroundColor:
                  taskColor != defaultTaskColor
                    ? lighten(taskColor, 0.95)
                    : 'undefined'
              }}
              color={color}
              fontSize={fontSize}
              style={{ direction: dir }}
              fontcolor={
                isTaskDesign(props.event)
                  ? '#000'
                  : isColorLight(color, 0.8)
                  ? '#000'
                  : '#fff'
              }
              className={
                props.ignoreDim
                  ? undefined
                  : fullyVisibleEvent
                  ? beforeNow
                    ? 'before-now'
                    : 'regular'
                  : 'animate-opacity'
              }
              pending={pending}
              onClick={() => {
                const clickedOnSelf =
                  (props.event?._id ?? '') + props.instanceStart ===
                  LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)

                if (
                  clickedOnSelf ||
                  LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) === '1' ||
                  props.event?.someday === '1'
                ) {
                  return
                }

                if (fullyVisibleEvent || props.ignoreDim) {
                  setEventOpened(true)
                  if ((props.event?._id?.length ?? 0) > 0) RemoveTempEvents()
                  LocalStorage.set(
                    LocalStorageKey.VISIBLE_EVENT,
                    props.event?._id ?? '' + props.instanceStart
                  )
                } else {
                  const divsToAnimate =
                    document.querySelectorAll('.animate-opacity')
                  divsToAnimate.forEach(
                    (div) => ((div as any).style.opacity = beforeNow ? 0.6 : 1)
                  )
                  const animationTimeout = setTimeout(() => {
                    divsToAnimate.forEach(
                      (div) => ((div as any).style.opacity = '0.1')
                    )
                  }, 2000)
                }
              }}
            >
              {hasLeftIndicator && (
                <Grid
                  xs={'auto'}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  style={{
                    width: '4px',
                    borderTopLeftRadius: dir === 'rtl' ? 0 : 8,
                    borderBottomLeftRadius: dir === 'rtl' ? 0 : 8,
                    borderTopRightRadius: dir === 'rtl' ? 8 : 0,
                    borderBottomRightRadius: dir === 'rtl' ? 8 : 0,
                    overflow: 'hidden',
                    backgroundColor: isGroupcalApp()
                      ? eventGroupColor
                      : taskColor
                  }}
                />
              )}
              <Grid
                xs
                item
                height={'100%'}
                overflow={'hidden'}
                paddingLeft={
                  eventView === CalendarRange.MONTH ? '0.15rem' : '0.3rem'
                }
                paddingRight={
                  eventView === CalendarRange.MONTH ? '0.15rem' : '0.3rem'
                }
                paddingTop={
                  eventView === CalendarRange.MONTH || isInAlldaySection
                    ? 0
                    : '0.15rem'
                }
              >
                <Grid
                  overflow={'hidden'}
                  display={'flex'}
                  width={'100%'}
                  flexDirection={'column'}
                >
                  {props.event.someday === '1' ? (
                    <Grid
                      container
                      display={'flex'}
                      wrap="nowrap"
                      alignItems={
                        isInAlldaySection || eventView === CalendarRange.MONTH
                          ? 'center'
                          : undefined
                      }
                      flexDirection={smallEvent ? 'row' : 'column'}
                    >
                      <SomedayItem title={props.event.Text} />
                    </Grid>
                  ) : (
                    <GroupcalTooltip
                      placement="top"
                      hidden={props.event.Text?.length === 0}
                      enterDelay={200}
                      title={
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={'0.25rem'}
                          alignItems={dir === 'rtl' ? 'end' : 'start'}
                        >
                          {itemLeadingIcons}
                          {props.event.Text ?? ''}
                        </Box>
                      }
                    >
                      <Grid
                        container
                        display={'flex'}
                        wrap="nowrap"
                        alignItems={
                          isInAlldaySection || eventView === CalendarRange.MONTH
                            ? 'center'
                            : undefined
                        }
                        flexDirection={smallEvent ? 'row' : 'column'}
                      >
                        <Grid
                          item
                          xs
                          gap={1}
                          flexDirection={'row'}
                          display={'flex'}
                          color={
                            props.event.Priority == '2' ? 'red' : undefined
                          }
                          style={{
                            transform:
                              eventView === CalendarRange.LISTWEEK
                                ? 'translateY(-1px)'
                                : undefined,
                            lineHeight:
                              eventView === CalendarRange.LISTWEEK
                                ? undefined
                                : '16px',
                            textDecoration: isInstanceCompleted(
                              props.event,
                              props.instanceStart
                            )
                              ? 'line-through'
                              : 'none'
                          }}
                        >
                          {`${
                            props.event._id?.length === 0
                              ? `${
                                  props.event.Type === DocType.TASK
                                    ? t('newTask')
                                    : t('newEvent')
                                }`
                              : props.title ?? props.event.Text
                          }${
                            location && eventView == CalendarRange.LISTWEEK
                              ? ', '
                              : ''
                          }`}
                          {eventView === CalendarRange.LISTWEEK && (
                            <LocationLabel>
                              {location
                                ? `${props.event.Location?.Address}`
                                : ''}
                            </LocationLabel>
                          )}
                        </Grid>
                      </Grid>
                    </GroupcalTooltip>
                  )}
                  {eventView !== CalendarRange.LISTWEEK &&
                    (location || newEventSubText) &&
                    props.event.AllDay !== '1' &&
                    !props.event.late && (
                      <div
                        style={{
                          color:
                            props.event.Type === DocType.REGULAR_EVENT ||
                            props.event.Type === DocType.EVENT
                              ? isColorLight(color, 0.8)
                                ? '#000'
                                : '#fff'
                              : '#000',
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: 300
                        }}
                      >
                        {props.event.Location?.Address}
                        {eventView != CalendarRange.MONTH && newEventSubText}
                      </div>
                    )}
                </Grid>
              </Grid>
              <Grid
                xs={'auto'}
                paddingRight={'0.3rem'}
                paddingLeft={'0.3rem'}
                paddingTop={isInAlldaySection ? 0 : '0.15rem'}
              >
                {isGroupcalApp() ? pictureAndStatus : <></>}
              </Grid>
            </Styled.EventContent>
          </EventPopup>
        )}
      </div>
    </ClickAwayListener>
  )
}

function provideStatusIconSize(
  event: Model<EventDoc> | EventDoc | undefined,
  eventView: CalendarRange
) {
  if (!event) return 0
  const {
    AggregatedParticipantsDeliveryStatus,
    AggregatedParticipantsConfirmationStatus
  } = event

  const regularSize = eventView === CalendarRange.MONTH ? 9 : 10

  const seenByAllSize = eventView === CalendarRange.MONTH ? 12 : 14

  if (
    AggregatedParticipantsConfirmationStatus != null &&
    AggregatedParticipantsConfirmationStatus.length > 0
  )
    return seenByAllSize

  if (AggregatedParticipantsDeliveryStatus != null) {
    if (
      Number(AggregatedParticipantsDeliveryStatus) >=
      Number(AggregatedDeliveryStatus.DELIVERED)
    ) {
      return seenByAllSize
    } else {
      return regularSize
    }
  }

  return regularSize
}

interface SubTextProps {
  text1?: string | undefined | null
  text2?: string | undefined | null
}

export function SubText(props: SubTextProps) {
  return (
    <div
      style={{
        marginTop: '3px',
        fontSize: '12px',
        fontWeight: 300,
        overflow: 'hidden',
        lineHeight: '13px',
        whiteSpace: 'pre-line',
        textOverflow: 'ellipsis'
      }}
    >
      {props.text1}
      {props.text2}
    </div>
  )
}

export default withObservables(['event'], (props: EventProps) => ({
  account: ObserveService.Account(),
  group: ObserveService.GroupByServerIDRestricted(
    props.event?.GroupID ?? LocalStorage.get(LocalStorageKey.SELECTED_GROUP)
  ),
  userSettings: ObserveService.UserSettings(),
  topGroup: ObserveService.FirstGroup(props.userSettings),
  profile: ObserveService.Profile(),
  localParticipants: ObserveService.Participants(),
  masterLabel: ObserveService.MasterLabel()
}))(Event as any)

export function leadingIcons(event: EventDoc) {
  if (!event.Type) return <></>

  const size = 10
  const color = 'white'
  let icon
  switch (event.Type as DocType) {
    case DocType.EVENT:
      icon = undefined
      break
    case DocType.REGULAR_EVENT:
      icon = isGroupcalApp() ? undefined : (
        <TypeEvent color={'fff'} width={size} height={size} />
      )
      break
    case DocType.TASK:
      icon = <TypeTask color="white" width={size} height={size} />
      break
    case DocType.NOTE:
      icon = <TypeNote color="white" width={size} height={size} />
      break
  }

  const divStyle: CSSProperties = {}

  const hasIcons =
    icon ||
    (event.Recurrence &&
      typeof event.Recurrence !== 'string' &&
      Object.keys(event.Recurrence).length != 0) ||
    (event.Type != DocType.NOTE &&
      event.Notes &&
      typeof event.Notes !== 'string' &&
      event.Notes.length > 0)

  if (!hasIcons) return <></>

  return (
    <Grid item display={'flex'} flexDirection={'row'} gap={'0.25rem'}>
      {icon && <div style={divStyle}>{icon}</div>}
      {event.Recurrence &&
        typeof event.Recurrence !== 'string' &&
        Object.keys(event.Recurrence).length != 0 && (
          <div style={divStyle}>
            <RecurrentItemCalendar color={color} width={size} height={size} />
          </div>
        )}
      {event.Type != DocType.NOTE &&
        event.Notes &&
        typeof event.Notes !== 'string' &&
        event.Notes.length > 0 && (
          <div style={divStyle}>
            <Notes width={size} height={size} />
          </div>
        )}
    </Grid>
  )
}

function isTaskDesign(event?: EventDoc): boolean {
  if (!event) {
    return is24meApp()
  }

  return event.Type === DocType.TASK || event.Type === DocType.NOTE
}

export function isInstanceCompleted(
  event: EventDoc,
  instanceStart: number
): boolean {
  if (!event.Recurrence) return event.Status === EventStatus.COMPLETED

  if (event.Status !== EventStatus.ACTIVE) return true

  if (
    event.Recurrence &&
    event.Recurrence.Exclusion &&
    typeof event.Recurrence.Exclusion !== 'string'
  ) {
    const instance = event.Recurrence.Exclusion?.find(
      (exclusion) =>
        dayjs.unix(Number(exclusion.Date)).format('YYYY-MM-DD') ===
        dayjs(instanceStart).format('YYYY-MM-DD')
    )

    return instance?.Status === EventStatus.COMPLETED
  }

  return false
}

export function instanceStatus(
  event: EventDoc,
  instanceStart: number
): EventStatus {
  if (!event.Recurrence) return event.Status ?? EventStatus.ACTIVE

  if (event.Status !== EventStatus.ACTIVE)
    return event.Status ?? EventStatus.ACTIVE

  if (
    event.Recurrence &&
    event.Recurrence.Exclusion &&
    typeof event.Recurrence.Exclusion !== 'string'
  ) {
    const instance = event.Recurrence.Exclusion?.find(
      (exclusion) =>
        dayjs.unix(Number(exclusion.Date)).format('YYYY-MM-DD') ===
        dayjs(instanceStart).format('YYYY-MM-DD')
    )
    return instance?.Status ?? EventStatus.ACTIVE
  }

  return event.Status ?? EventStatus.ACTIVE
}
