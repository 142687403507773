import { darken, Grid } from '@mui/material'
import {
  appleIcon,
  appleLogo,
  exchangeIcon,
  exchangeLogo,
  googleIcon,
  googleLogo,
  outlookIcon,
  outlookLogo
} from 'assets/icons/menu'
import { AxiosError } from 'axios'
import Button from 'components/buttons/Button'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import {
  parseErrorToMessage,
  RECUR_SAVE_TYPE
} from 'components/popups/eventInfo/EventPopup'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { Event } from 'microsoft-graph'
import React, { createContext, useCallback, useContext, useState } from 'react'
import Api from 'services/Api'
import { API_ADD_EVENT, updateEventOnServer } from 'services/api/event'
import { genericUpdateFields, unlinkExternalCalendar } from 'services/api/group'
import Database from 'services/db/Database'
import {
  createEventFromAnotherEvent,
  localEventById,
  RemoveThirdPartyEvents,
  stopRecurrenceAt
} from 'services/db/Queries/Event'
import {
  googleEventsGroup,
  RemoveThirdPartyCalendar
} from 'services/db/Queries/GroupQueries'
import { RemoveThirdPartyAccount } from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import IcloudApi from 'services/IcloudApi'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import {
  DocType,
  EventDoc,
  EventStatus,
  GroupDoc,
  TaskType
} from 'types/api/changes'
import { GoogleCalendar, GoogleEvent, ICalendar } from 'types/google_events'
import { CollectionType, Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import {
  buildAddEventMessage,
  excludeInstanceFromRecurrence,
  handleEventUpdate,
  saveEventToLocalDB
} from 'utils/api/events'
import { addGoogleEvent } from 'utils/api/google_events'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { APP_COLOR } from 'utils/colors'
import { outlookPrefix } from 'utils/constants'
import {
  connectGoogleCalendar,
  convertGoogleRemindersToGroupcalReminders,
  CurrentConvertedCalendars as CurrentConvertedCalendars,
  googleEventToGroupcal,
  groupcalEventToGoogleEvent,
  prefixGoogleEventsGroup,
  syncGoogleForVisibleArea
} from 'utils/google_events'
import {
  groupIdToProvider,
  groupToProvider,
  isConvertedGroupId
} from 'utils/groups'
import { thirdPartyCalendarIdToPrimaryEmail } from 'utils/third_party_calendars'
import { uuid } from 'utils/uuid'

import { icloudPrefix, useIcloud } from './icloud/IcloudProvider'
import {
  outlookEventToTfmEvent,
  tfmEventToOutlook,
  useOutlookContext
} from './outlook/OutlookProvider'
import { m } from 'framer-motion'

// Define the types of third-party providers you support
export enum ProviderType {
  GOOGLE = 'Google',
  OUTLOOK = 'Outlook',
  APPLE = 'iCloud'
  // Add more providers here
}

export interface CalendarSyncContextProps {
  connect: (
    providerType: ProviderType,
    onComplete?: (calendar: ICalendar) => void,
    onCancel?: () => void,
    startedProcess?: () => void
  ) => void
  sync: () => void
  logout: (group: Model<GroupDoc>) => Promise<void>
  calendarTitle(thirdPartyId: string, group: GroupDoc | undefined): string
  loadEventDetails(eventId: string, provider: ProviderType): Promise<EventDoc>
  showProviderPicker?: () => void
  syncEventWithThirdParty: (
    instanceStart: number,
    instanceEnd: number,
    event: EventDoc,
    originalEvent: Model<EventDoc>,
    localSave: boolean,
    isDirty: boolean,
    onError?: (error: string) => void,
    type?: RECUR_SAVE_TYPE,
    shouldOverrideTime?: boolean
  ) => Promise<void>
  sendEventToServer: (
    originalEvent: Model<EventDoc> | EventDoc,
    data: EventDoc,
    localSave: boolean,
    isDirty: boolean
  ) => Promise<void>
  showProviderPickerDialog?: boolean
  setShowProviderPickerDialog?: (show: boolean) => void
}

// Define the context for the provider
const CalendarSyncContext = createContext<CalendarSyncContextProps>({
  connect: () => {},
  sync: () => {},
  logout: (group: Model<GroupDoc>) => Promise.resolve(),
  calendarTitle: (thirdPartyId: string, group: GroupDoc) => 'test1',
  loadEventDetails: (eventId: string, provider: ProviderType) =>
    Promise.resolve({} as EventDoc),
  showProviderPicker: () => {},
  syncEventWithThirdParty: (
    instanceStart: number,
    instanceEnd: number,
    event: EventDoc,
    originalEvent: Model<EventDoc>,
    localSave: boolean,
    isDirty: boolean,
    onError?: (error: string) => void,
    type?: RECUR_SAVE_TYPE,
    shouldOverrideTime?: boolean
  ) => Promise.resolve(),
  sendEventToServer: (
    originalEvent: Model<EventDoc> | EventDoc,
    data: EventDoc,
    localSave: boolean,
    isDirty: boolean
  ) => Promise.resolve(),
  showProviderPickerDialog: false,
  setShowProviderPickerDialog: () => {}
})

export const useCalendarSync = () => {
  return useContext(CalendarSyncContext)
}

export interface CalendarSyncProviderProps {
  children: React.ReactNode
}

export const CalendarSyncProvider = (props: CalendarSyncProviderProps) => {
  const outlook = useOutlookContext()
  const icloud = useIcloud()
  const [showProviderPickerDialog, setShowProviderPickerDialog] =
    useState(false)

  const connect = useCallback(
    (
      providerType: ProviderType,
      onComplete?: (calendar: ICalendar) => void,
      onCancel?: () => void,
      startedProcess?: () => void
    ) => {
      // Add your logic here to connect to the provider
      switch (providerType) {
        case ProviderType.GOOGLE:
          // Connect to Google
          connectGoogleCalendar(onComplete, onCancel, startedProcess)
          break
        case ProviderType.OUTLOOK:
          // Connect to Outlook
          if (outlook.signIn) outlook.signIn()
          break
        // Add more cases here for other providers
        case ProviderType.APPLE:
          icloud.connect(onCancel)
          break
        default:
          throw new Error(`Unsupported provider type: ${providerType}`)
      }
    },
    []
  )

  const showProviderPicker = useCallback(() => {
    setShowProviderPickerDialog(true)
  }, [])

  const sync = useCallback(() => {
    console.log('Outlook', outlook)
    syncGoogleForVisibleArea()
    if (outlook.syncCalendars) outlook.syncCalendars()
    icloud.sync()
  }, [])

  const logout = useCallback(async (group: Model<GroupDoc>): Promise<void> => {
    // Add your logic here to log out from all providers
    const provider = groupToProvider(group)

    if (provider === ProviderType.OUTLOOK) {
      Promise.all([
        RemoveThirdPartyEvents(group._id),
        RemoveThirdPartyCalendar(group._id),
        RemoveThirdPartyAccount(group.Name, ProviderType.OUTLOOK)
      ]).then(() => {
        outlook.signOut && outlook.signOut()
      })
    } else {
      unlinkExternalCalendar(group).then(() => {
        if (provider === ProviderType.GOOGLE) {
          return Promise.all([
            RemoveThirdPartyEvents(group._id),
            RemoveThirdPartyCalendar(group._id),
            RemoveThirdPartyAccount(group.Name, ProviderType.GOOGLE)
          ])
        } else if (provider === ProviderType.APPLE) {
          icloud.disconnect(group._id)
        }
      })
    }
  }, [])

  const calendarTitle = useCallback(
    (thirdPartyId: string, group: GroupDoc | undefined) => {
      const provider = groupToProvider(group)

      if (provider === ProviderType.OUTLOOK) {
        return (
          CurrentConvertedCalendars.find((calendar) => {
            return calendar.id === thirdPartyId
          })?.name ??
          group?._id?.replace(outlookPrefix, '') ??
          ''
        )
      } else if (provider === ProviderType.APPLE) {
        return (
          (
            CurrentConvertedCalendars.find((calendar) => {
              return calendar.id === thirdPartyId
            }) as ICalendar
          )?.name ??
          group?._id?.replace(prefixGoogleEventsGroup, '') ??
          ''
        )
      }

      return (
        (
          CurrentConvertedCalendars.find((calendar) => {
            return calendar.id === thirdPartyId
          }) as GoogleCalendar
        )?.summary ??
        group?._id?.replace(prefixGoogleEventsGroup, '') ??
        ''
      )
    },
    []
  )

  const loadEventDetails = async (
    eventId: string,
    provider?: ProviderType
  ): Promise<EventDoc> => {
    // for the outlook we need to download event details because of recurrence
    if (provider === ProviderType.OUTLOOK && outlook.getEventDetails) {
      return outlook.getEventDetails(eventId).then((event: Event) => {
        return outlookEventToTfmEvent(event)
      })
    }
    // for the rest of the events we can return local copy
    return localEventById(eventId)
  }

  async function handleEventCreate(
    event: Model<EventDoc> | EventDoc,
    data: EventDoc
  ) {
    if (
      is24meApp() &&
      (data.Type === DocType.TASK || data.Type === DocType.NOTE)
    ) {
      delete data['GroupID']
      delete data['ThirdPartyID']
      Database.write(async () => {
        event?.update((local: { GroupID: undefined }) => {
          local.GroupID = undefined
        })
      })
    }

    data = {
      ...data,
      _id: undefined,
      id: undefined,
      _rev: undefined,
      TaskType: TaskType.meet,
      Status: EventStatus.ACTIVE,
      Priority: (data?.Priority?.length ?? 0) > 0 ? data.Priority : '1',
      Shared: 'null',
      OwnerID: LocalStorage.get(LocalStorageKey.USER_ID)!,
      Type: data.Type ?? (is24meApp() ? DocType.TASK : DocType.EVENT),
      GroupID:
        is24meApp() && data.Type !== DocType.REGULAR_EVENT
          ? undefined
          : data.GroupID,
      Rank: String(Date.now().valueOf() / 1000),
      ObjectType: '1',
      isDeleted: '0',
      UserID: LocalStorage.get(LocalStorageKey.USER_ID)!,
      TimeZoneNameID: data.AllDay === '1' ? 'UTC' : data.TimeZoneNameID,
      StartDate:
        data.someday === '1'
          ? 'null'
          : data.AllDay === '1'
          ? dayjs
              .utc(dayjs(Number(data.StartDate) * 1000).format('YYYY-MM-DD'))
              .startOf('day')
              .unix()
              .toString()
          : data.StartDate,
      EndDate:
        data.someday === '1'
          ? 'null'
          : data.AllDay === '1'
          ? dayjs(Number(data.EndDate) * 1000)
              .tz('UTC')
              .startOf('day')
              .add(1, 'day')
              .add(-1, 'second')
              .startOf('day')
              .unix()
              .toString()
          : data.EndDate
    }

    data = {
      ...data,
      Reminder: setAlertTimeToReminders(Number(data.StartDate), data.Reminder)
    }

    if (isGroupcalApp()) delete data['Label']

    Logger.blue('Data for event', data)

    if (isConvertedGroupId(data.GroupID)) {
      const calendarId = data.ThirdPartyID
      const provider = groupIdToProvider(data.GroupID ?? '')

      Logger.debug('Adding event', data, ' to provider', provider)
      if (provider === ProviderType.GOOGLE) {
        await addGoogleEvent(
          calendarId?.replace(prefixGoogleEventsGroup, '') ?? '',
          groupcalEventToGoogleEvent(data)
        ).then((response) => {
          if (response.status === 200) {
            const googleCalendar = CurrentConvertedCalendars.find(
              (cal) =>
                cal.id === calendarId?.replace(prefixGoogleEventsGroup, '')
            )
            const googleEvent: GoogleEvent = response.data
            return saveEventToLocalDB({
              ...googleEventToGroupcal(response.data),
              Color: darken(googleCalendar?.backgroundColor ?? APP_COLOR, 0.15),
              ThirdPartyID: calendarId,
              GroupID: data.GroupID,
              Reminder: convertGoogleRemindersToGroupcalReminders(
                googleEvent.reminders?.overrides
              )
            })
          }
        })
      } else if (provider === ProviderType.OUTLOOK) {
        if (outlook.addEvent) {
          Logger.blue('Adding outlook event', data)
          await outlook
            .addEvent(
              await tfmEventToOutlook(data),
              calendarId?.replace(outlookPrefix, '') ?? ''
            )
            .then((response: Event) => {
              Logger.red('Added outlook event', response)
              if (outlook.syncCalendars) outlook.syncCalendars()
              return saveEventToLocalDB({
                ...outlookEventToTfmEvent(response),
                OwnerID: thirdPartyCalendarIdToPrimaryEmail(
                  calendarId?.replace(outlookPrefix, '') ?? ''
                ),
                GroupID: data.GroupID,
                ThirdPartyID: calendarId?.replace(outlookPrefix, '') ?? ''
              })
            })
        }
      } else if (provider === ProviderType.APPLE) {
        const appleCalendar = CurrentConvertedCalendars.find(
          (iCalendar) => iCalendar.id === data.ThirdPartyID
        )
        const email = removePrefix(data.GroupID ?? '')
        Logger.blue('Adding apple event', data, appleCalendar, email)
        if (typeof data.Location === 'string') delete data['Location']
        delete data['RequestConfirmation']
        if (appleCalendar && email) {
          const uniqueId = uuid()

          const calendarAttendees = data.attendees?.map((attendee) => {
            return {
              Email: attendee.email,
              ResponseStatus:
                attendee?.responseStatus?.toUpperCase() &&
                attendee?.responseStatus?.toLowerCase()?.includes('needs')
                  ? 'NEEDS-ACTION'
                  : attendee?.responseStatus?.toUpperCase()
            }
          })

          await IcloudApi.addCalendarItemToIcloud({
            email: email,
            calendar: appleCalendar.calDavString,
            event: { ...data, Attendees: calendarAttendees, _id: uniqueId }
          }).then((added) => {
            Logger.red('Added apple event', added)

            return saveEventToLocalDB({
              ...data,
              _id: added[0]._id,
              rev: `1-${added[0]._id}`,
              Url: added[0].Url,
              OpenDate: added[0].OpenDate,
              Type: DocType.REGULAR_EVENT,
              OwnerID: email,
              GroupID: data.GroupID,
              ThirdPartyID: data.ThirdPartyID,
              Organizer: added[0].Organizer
            })
          })
        }
      }
    } else {
      const response = await Api.post(`${API_ADD_EVENT}`, {
        ...data,
        late: 0,
        attendeesChanged: 0,
        ...genericUpdateFields()
      })

      const serverData = response.data['doc']

      const eventWithMessage = buildAddEventMessage(
        LocalStorage.get(LocalStorageKey.USER_ID) ?? '',
        { ...serverData, Priority: String(serverData['Priority']) }
      )
      await saveEventToLocalDB(eventWithMessage)
    }
  }

  const sendEventToServer = async (
    originalEvent: Model<EventDoc> | EventDoc,
    data: EventDoc,
    localSave: boolean,
    isDirty: boolean
  ) => {
    const local = await localEventById(originalEvent._id ?? '')

    if (local?._id?.length ?? 0 > 0) {
      return await handleEventUpdate(
        local,
        data,
        localSave,
        isDirty || data.Status != local.Status
      ).catch((e) => {
        console.error(e)
      })
    } else {
      return await handleEventCreate(local, data)
    }
  }

  const syncEventWithThirdParty = async (
    instanceStart: number,
    instanceEnd: number,
    data: EventDoc,
    originalEvent: Model<EventDoc>,
    localSave: boolean,
    isDirty: boolean,
    onError?: (error: string) => void,
    type?: RECUR_SAVE_TYPE,
    shouldOverrideTime?: boolean
  ) => {
    const provider = groupIdToProvider(data.GroupID ?? '')
    switch (type) {
      case RECUR_SAVE_TYPE.ALL:
        {
          const local = await loadEventDetails(
            originalEvent._id ?? '',
            provider
          )

          await sendEventToServer(
            originalEvent,
            {
              ...data,
              StartDate: shouldOverrideTime ? data.StartDate : local.StartDate,
              EndDate: shouldOverrideTime ? data.EndDate : local.EndDate,
              ParentTaskID: local.ParentTaskID,
              local_id:
                (local.ParentTaskID?.length ?? 0) > 0
                  ? local.ParentTaskID
                  : local.local_id
            },
            localSave,
            isDirty
          ).then(() => {
            if (outlook.syncCalendars) outlook.syncCalendars()
          })
        }
        break
      case RECUR_SAVE_TYPE.THIS:
        {
          try {
            Logger.blue('Recurrence saving data', data)
            delete data['Recurrence']

            if (provider === ProviderType.OUTLOOK) {
              await sendEventToServer(originalEvent, data, localSave, isDirty)
              return
            }

            if (data.Status === EventStatus.ACTIVE) {
              await handleEventCreate(originalEvent, data)
            }
            await excludeInstanceFromRecurrence(
              data,
              originalEvent,
              instanceStart,
              instanceEnd
            )
          } catch (e) {
            if (e instanceof AxiosError)
              if (onError) onError(parseErrorToMessage(e))
            Logger.red('during event update', e)
          }
        }
        break
      case RECUR_SAVE_TYPE.THIS_AND_FOLLOWING:
        if (data.Status === EventStatus.ACTIVE) {
          try {
            const copyOf = await createEventFromAnotherEvent(
              instanceStart,
              instanceEnd,
              originalEvent
            )
            await handleEventCreate(copyOf, {
              ...data,
              Recurrence: copyOf.Recurrence,
              Status: EventStatus.ACTIVE // to keep original series state
            })
          } catch (e) {
            if (e instanceof AxiosError)
              if (onError) onError(parseErrorToMessage(e))
            Logger.red('during event create: ', e)
          }
        }

        try {
          const excluded = await stopRecurrenceAt(instanceStart, originalEvent)
          if (excluded)
            await updateEventOnServer(excluded, {
              ...data,
              Recurrence: excluded.Recurrence,
              Status: EventStatus.ACTIVE, // to keep original series state
              StartDate: excluded.StartDate, // to keep original start series
              EndDate: excluded.EndDate // to keep original start series
            })
        } catch (e) {
          if (e instanceof AxiosError)
            if (onError) onError(parseErrorToMessage(e))
          Logger.red('during event update', e)
        }
        break
    }
  }

  return (
    <CalendarSyncContext.Provider
      value={{
        connect,
        sync,
        logout,
        calendarTitle,
        loadEventDetails,
        showProviderPicker,
        syncEventWithThirdParty,
        sendEventToServer,
        showProviderPickerDialog,
        setShowProviderPickerDialog
      }}
    >
      {props.children}
      <ConfirmationDialog
        title={t('selectYourCalendarAccount')}
        showCloseIcon={true}
        titlePadding="45px"
        content={
          <Grid
            display={'flex'}
            flexDirection={'column'}
            gap={'1.5rem'}
            alignItems={'center'}
            width={'100%'}
            paddingLeft={40}
            paddingRight={40}
          >
            <ProviderButton
              id="connect-apple-calendar-button"
              click={() => {
                connect(
                  ProviderType.APPLE,
                  undefined,
                  () => {
                    setShowProviderPickerDialog(false)
                  },
                  undefined
                )
              }}
              icon={appleIcon}
              logo={appleLogo}
            />
            <ProviderButton
              click={() => {
                connect(ProviderType.GOOGLE)
                setShowProviderPickerDialog(false)
              }}
              icon={googleIcon}
              logo={googleLogo}
            />
            <ProviderButton
              click={() => {
                connect(ProviderType.OUTLOOK)
                setShowProviderPickerDialog(false)
              }}
              icon={outlookIcon}
              logo={outlookLogo}
            />
            <ProviderButton
              click={() => {
                connect(ProviderType.OUTLOOK)
                setShowProviderPickerDialog(false)
              }}
              icon={exchangeIcon}
              logo={exchangeLogo}
            />
          </Grid>
        }
        handleClose={() => setShowProviderPickerDialog(false)}
        noButtons
        open={showProviderPickerDialog}
      />
    </CalendarSyncContext.Provider>
  )
}

interface ProviderButtonProps {
  icon: string
  logo: string
  click: () => void
  id?: string
}

export function ProviderButton(props: ProviderButtonProps) {
  return (
    <Button id={props.id} onClick={props.click} width="250px">
      <Grid
        style={{ cursor: 'pointer' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        gap={4}
      >
        <img src={props.icon} alt="icon" />
        <img style={{ maxHeight: '38px' }} src={props.logo} alt="logo" />
      </Grid>
    </Button>
  )
}

export async function createGeneralGroupIfNeeded(
  prefix: string = prefixGoogleEventsGroup,
  id: string,
  color: string,
  message = 'Google Calendar',
  name = 'Google Calendar'
): Promise<GroupDoc> {
  const group = await googleEventsGroup(`${prefix}${id}`)

  function assignFields(local: GroupDoc, brandNew: boolean) {
    local._id = `${prefix}${id}`
    local.Name = name
    local.GroupColor = darken(color.length > 0 ? color : APP_COLOR, 0.15)
    local._message = message
    local.SyncProblem = null
    local.OwnerID = LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    if (brandNew) local._raw['_sortOrder'] = dayjs().add(7, 'year').valueOf()
    return local
  }

  if (!group) {
    return Database.write(async () => {
      return Database.getCollection<GroupDoc>(CollectionType.GROUP).create(
        (local) => assignFields(local, true)
      )
    })
  } else {
    return Database.write(async () => {
      return group.update((local) => {
        assignFields(local, false)
      })
    })
  }
}

/**
 *
 * @param eventsFromGoogle array of events from the google api
 * @param existingEvents existing locally events
 * @param converted received data from third party provider
 * @returns array of promises with pending changes to watermelondb
 */
export async function cacheOtherCalendarsEvents(
  data: any,
  existingEvents: EventDoc[],
  converted: EventDoc[],
  searchQuery?: string
) {
  if (!data) return []

  const promisesForDB: any[] = []

  Logger.blue('ICLOUD:Existing', existingEvents)
  Logger.blue('ICLOUD:Converted', converted)

  /**
   * Gather events which are not in response - delete them, meaning they was deleted outside of groupcal web app
   */
  existingEvents.forEach((existingEvent) => {
    if (
      converted.find(
        (converted) => converted.local_id === existingEvent.local_id
      ) === undefined
    ) {
      if (!searchQuery && (existingEvent._id?.length ?? 0) > 0)
        promisesForDB.push(existingEvent.prepareMarkAsDeleted())
    }
  })

  await Promise.all(promisesForDB).then((events) => {
    Database.write(async () => {
      Database.batch(events)
    })
  })

  const collection = Database.getCollection<EventDoc>(CollectionType.EVENT)
  /**
   * per each converted item we either create or delete new items in local db
   */
  const promises: any[] = []

  for (const convertedItem of converted) {
    const existing = await localEventById(convertedItem._id ?? '')

    if (existing?.Status === EventStatus.DELETED) {
      continue
    }

    promises.push(
      existing
        ? existing.prepareUpdate((local) => {
            Object.keys(convertedItem).forEach((key) => {
              local[key] = convertedItem[key]
              local.Status = EventStatus.ACTIVE
            })
          })
        : collection.prepareCreate((local) => {
            Object.keys(convertedItem).forEach((key) => {
              local[key] = convertedItem[key]
              local.Status = EventStatus.ACTIVE
            })
          })
    )
  }

  await Promise.all(promises).then((events) => {
    Database.write(async () => {
      Database.batch(events)
    })
  })
}

export function removePrefix(id: string) {
  return id
    .replace(prefixGoogleEventsGroup, '')
    .replace(outlookPrefix, '')
    .replace(icloudPrefix, '')
}

export function setAlertTimeToReminders(
  startDate: number,
  Reminder: import('types/api/changes').EventReminder[] | undefined
) {
  if (!Reminder || Reminder.length === 0 || !Array.isArray(Reminder))
    return undefined

  return Reminder.map((reminder) => {
    return {
      ...reminder,
      AlertTime: (startDate - reminder.offset * 60).toString()
    }
  })
}
