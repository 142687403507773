import Tippy from '@tippyjs/react'
import { useState, forwardRef } from 'react'
export const LazyTippy = forwardRef((props, ref) => {
  const [mounted, setMounted] = useState(false)

  const lazyPlugin = {
    fn: () => ({
      onMount: () => {
        setMounted(true)
        document.body.style.overflow = 'hidden'
      },
      onHidden: () => {
        setMounted(false)
        document.body.style.overflow = 'auto'
      }
    })
  }

  const computedProps = { ...props }

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])]

  if (props.render) {
    computedProps.render = (...args) => (mounted ? props.render(...args) : '')
  } else {
    computedProps.content = mounted ? props.content : ''
  }

  return <Tippy allowHTML zIndex={9997} {...computedProps} ref={ref} />
})
