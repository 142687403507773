import { Model } from '@nozbe/watermelondb'
import { json, text } from '@nozbe/watermelondb/decorators'
import { sanitizeJson } from 'utils/watermalon'

export default class Account extends Model {
  static table = 'Accounts'

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('AccessToken') AccessToken!: string
  @text('AppleID') AppleID!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('Email') Email!: string
  @text('FacebookID') FacebookID!: string
  @text('LastUpdate') LastUpdate!: string
  @text('Password') Password!: string
  @text('PhoneNumber') PhoneNumber!: string
  @text('PhoneNumberType') PhoneNumberType!: string
  @text('TokenExpires') TokenExpires!: string
  @text('Type') Type!: string
  @text('groupcalActivatedDate') groupcalActivatedDate!: string
  @text('isDeleted') isDeleted!: string
  @text('DevicesID') DevicesID!: string
  @json('Devices', sanitizeJson) Devices!: string
  @json('Name', sanitizeJson) Name!: string
  @json('UserType', sanitizeJson) UserType!: string
  @json('ConnectedCalAccounts', sanitizeJson) ConnectedCalAccounts!: string
  @json('Products', sanitizeJson) Products!: string
  @text('PaymentEmail') PaymentEmail!: string
}
