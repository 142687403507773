import Analytics, { UserProperties } from 'analytics/Analytics'
import dayjs from 'dayjs'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { getChanges } from 'services/api/changes'
import {
  updateParticipantStatus,
  UpdateParticipantStatusBody
} from 'services/api/event'
import { getProfilesForParticipants, joinGroup } from 'services/api/group'
import ChangesService, { populateUnreadIfNeeded } from 'services/db/Changes'
import {
  Account,
  updatePushTokenIfNeeded
} from 'services/db/Queries/AccountQueries'
import { allGroups, localGroupById } from 'services/db/Queries/GroupQueries'
import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import {
  AccountDoc,
  ChangeDoc,
  ChangeDocsByTypes,
  DocType,
  EventDoc,
  EventParticipantStatus,
  GroupDoc,
  ParticipantDeliveryStatusType
} from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import { parseDocsBeforeSaving } from 'utils/api/changes'
import {
  isGroupBlockedToAddEvents,
  isUserActiveInGroup
} from 'utils/api/groups'
import { isEmpty } from 'utils/object'

import { performUpdateGroupSettings } from 'components/popups/groupInfo/SettingsForCalendarSection'
import { isGroupArchived, isGroupHidden } from 'utils/api/userSettings'
import { isGroupcalApp } from 'utils/appType'
import { updateAccountOnServer } from 'services/api/account'
import Database from 'services/db/Database'
import { isConvertedGroupId } from 'utils/groups'

interface GetChangesContextProps {}

interface GetChangesProviderProps {
  children: ReactNode
}

const GetChangesContext = createContext<GetChangesContextProps>({})

export default function GetChangesProvider(props: GetChangesProviderProps) {
  useEffect(() => {
    doGetChanges(
      LocalStorage.get(LocalStorageKey.DEVICE_ID),
      undefined,
      LocalStorage.get(LocalStorageKey.LAST_UPDATE)
    )
  }, [])

  return (
    <GetChangesContext.Provider value={{}}>
      {props.children}
    </GetChangesContext.Provider>
  )
}

export async function doGetChanges(
  deviceID?: string | null,
  groupIds?: string[] | undefined,
  lastUpdate?: string | null,
  ignoreEventsForMessage?: boolean
) {
  Logger.blue(
    'getting changes for groups and last update',
    groupIds,
    lastUpdate
  )

  try {
    const data = await getChanges({
      LastUpdate: lastUpdate,
      DeviceChangeID: deviceID,
      ForGroupsID: groupIds
    })
    const docs = parseDocsBeforeSaving(data.results)

    let groupsToFetch: string[] | undefined = undefined

    //to be sure that it's done *not* on 1st getchanges, we check it first
    if (lastUpdate && lastUpdate != null) {
      groupsToFetch = await gatherGroupsToUpdate(docs.Group)
    }

    if (groupsToFetch === undefined) {
      /**
       * Very first get changes
       */
      try {
        if (docs.Group)
          markUserAsInvitedIfNeeded(Object.values(docs.Group) as GroupDoc[])
      } catch (e) {
        Logger.debug('docs', docs)
        Logger.red('error get changes', e)
      }
    }

    if (data.now) {
      LocalStorage.set(LocalStorageKey.LAST_UPDATE, data.now)
    }

    if (docs[DocType.ACCOUNT]) {
      const account = Object.values(docs[DocType.ACCOUNT])[0] as AccountDoc
      if (account) {
        const UserID = account._id
        LocalStorage.set(LocalStorageKey.USER_ID, UserID)
        Analytics.getInstance().setAnalyticsUserId(UserID ?? 'none')
        Analytics.getInstance().setUserProperties({
          key: UserProperties.LANG_CODE,
          value: dayjs.locale().toLowerCase()
        })
        Analytics.getInstance().appendSubscriptionInfo(account)
      }
    }

    Logger.apiResponse('/general/changes', data, docs)
    if (isEmpty(docs)) {
      performActionsAfterGetChanges()
      return
    }

    await processDownloadedData(docs, ignoreEventsForMessage)

    if (
      (ignoreEventsForMessage === false ||
        ignoreEventsForMessage === undefined) &&
      lastUpdate != null
    ) {
      populateUnreadIfNeeded(docs, await UserSettings())
    }

    if (lastUpdate != null) updatePushTokenIfNeeded()

    //performing it after all sync done, to prevent extra calls and endless loops
    if (groupsToFetch != undefined && groupsToFetch.length > 0)
      await doGetChanges(null, groupsToFetch, null, true)
  } catch (e) {
    Logger.red('GetChanges', e)
  }
}

export function processDownloadedData(
  docs: ChangeDocsByTypes,
  ignoreEventsForMessage?: boolean
) {
  return ChangesService.updateDocsMessage(docs, ignoreEventsForMessage ?? false)
    .then((docs) => {
      return ChangesService.saveChanges(docs)
    })
    .then(() => {
      loadParticipantsData(docs)
      if (isGroupcalApp()) sendDeliveredStatuses(docs)
      performActionsAfterGetChanges()
    })
}

async function sendDeliveredStatuses(docs: ChangeDocsByTypes) {
  if (!docs.GroupEvent) return
  const events = Object.values(docs.GroupEvent)
  if (events.length === 0) return
  const participantStatuses: UpdateParticipantStatusBody[] = []
  const today = dayjs()

  events
    .filter((doc) => {
      const event = doc as EventDoc
      const dayjsTime = dayjs(Number(event.StartDate) * 1000)
      const legit =
        dayjsTime.isSame(today, 'day') ||
        (dayjsTime.isAfter(today) && !isConvertedGroupId(event.GroupID))

      return legit
    })
    .forEach((doc) => {
      const event = doc as EventDoc
      const status: EventParticipantStatus = event.ParticipantsStatus?.[
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ] ?? {
        ParticipantDeliveryStatus: ParticipantDeliveryStatusType.DELIVERED,
        ParticipantConfirmationStatus: '1',
        LastUpdate: dayjs().unix().toString()
      }
      let numberedDeliveryStatus = 0

      if (status?.ParticipantDeliveryStatus)
        numberedDeliveryStatus = Number(status.ParticipantDeliveryStatus)

      if (
        status === undefined ||
        status.ParticipantDeliveryStatus === undefined ||
        numberedDeliveryStatus < Number(ParticipantDeliveryStatusType.DELIVERED)
      ) {
        const newStatus: UpdateParticipantStatusBody = {
          _id: event._id ?? '',
          ParticipantsStatus: {
            ...status,
            ParticipantDeliveryStatus: ParticipantDeliveryStatusType.DELIVERED
          },
          DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID) ?? ''
        }
        participantStatuses.push(newStatus)
      }
    })

  if (participantStatuses.length == 0) return

  await updateParticipantStatus(participantStatuses)
}

function loadParticipantsData(docs: ChangeDocsByTypes) {
  /**
   * for all received groups, we update participants table
   */
  let participantIds: string[] = []
  if (docs.Group) {
    Object.keys(docs.Group).forEach((groupId) => {
      const group = docs.Group[groupId] as GroupDoc

      if (group.Participants) {
        const participantsOfGroup = Object.keys(group.Participants)
        participantIds = [...participantIds, ...participantsOfGroup]
      }
    })

    getProfilesForParticipants(
      participantIds.filter(function (elem, index, self) {
        return index === self.indexOf(elem)
      })
    )
  }
}

export function useGetChanges() {
  return useContext(GetChangesContext)
}
async function gatherGroupsToUpdate(
  groupsFromServer: Record<string, ChangeDoc>
) {
  if (!groupsFromServer) return []
  const groups = Object.values(groupsFromServer)
  const localGroups = await allGroups()
  let groupsToFetch: string[] | undefined = []
  if (groups) {
    groups.forEach((groupFromServer) => {
      const existingGroup = localGroups.find(
        (group) => group._id === groupFromServer._id
      ) as GroupDoc
      const serverGroup = groupFromServer as GroupDoc
      const userId = LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      if (existingGroup) {
        if (
          isUserActiveInGroup(serverGroup, userId) &&
          !isUserActiveInGroup(existingGroup, userId)
        ) {
          groupsToFetch?.push(groupFromServer._id ?? '')
        }
      } else {
        groupsToFetch?.push(groupFromServer._id ?? '')
      }
    })
  }

  if (groupsToFetch?.length === 0) groupsToFetch = []

  return groupsToFetch
}

function markUserAsInvitedIfNeeded(groups: GroupDoc[]) {
  const hasNonGenericGroups = groups.some((group) => {
    return !group.DeviceChangeID?.includes('Prepopulated_Group')
  })

  if (
    (hasNonGenericGroups ||
      LocalStorage.get(LocalStorageKey.CACHED_GROUP_ID_JOIN)) &&
    LocalStorage.get(LocalStorageKey.NEW_USER)
  ) {
    Logger.debug('sending invited status')
    Analytics.getInstance().setUserProperties({
      key: UserProperties.INVITED,
      value: 'Yes'
    })
  }
}

export async function joinGroupIfNeeded() {
  const groupId = LocalStorage.get(LocalStorageKey.CACHED_GROUP_ID_JOIN)
  const groupPassword = LocalStorage.get(LocalStorageKey.CACHED_GROUP_PASSWORD)
  const group = await localGroupById(groupId ?? '')
  const isActive = isUserActiveInGroup(
    group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  )

  /**
   * Remove to prevent endless loop
   */
  LocalStorage.remove(LocalStorageKey.CACHED_GROUP_ID_JOIN)
  LocalStorage.remove(LocalStorageKey.CACHED_GROUP_PASSWORD)

  if (groupId && groupPassword && (!group || !isActive)) {
    return await joinGroup(groupId, groupPassword)
  }
}

function performActionsAfterGetChanges() {
  setDefaultCalendarIfNeeded()
  updatePhoneNumberTypeIfNeeded()
}

export async function setDefaultCalendarIfNeeded(
  excludeGroup?: string | undefined
) {
  const userSettings = await UserSettings()
  const account = await Account()

  const id =
    userSettings &&
    userSettings.GroupsSettings &&
    userSettings.GroupsSettings != null
      ? Object.entries(userSettings.GroupsSettings).find(
          ([key, value]) =>
            value.Default === '1' && !isGroupArchived(userSettings, key)
        )?.[0]
      : undefined

  Logger.blue('default calendar', `current id: ${id}`)

  if (
    !id ||
    (id &&
      isGroupBlockedToAddEvents(
        account,
        await localGroupById(id),
        userSettings,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ))
  ) {
    allGroups().then((groups) => {
      if (!groups || groups.length === 0) return undefined
      const newDefaultGroupId =
        groups
          .filter((group) => {
            return !isGroupArchived(userSettings, group._id)
          })
          .find((group) => {
            return (
              !isGroupBlockedToAddEvents(
                account,
                group,
                userSettings,
                LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
              ) &&
              excludeGroup != group._id &&
              !isGroupHidden(userSettings, group._id)
            )
          })?._id ?? ''

      Logger.blue('default calendar', `new cal id: ${newDefaultGroupId}`)
      if (newDefaultGroupId && newDefaultGroupId.length > 0) {
        return performUpdateGroupSettings(userSettings, newDefaultGroupId, {
          Default: '1'
        })
      }
    })
  }
}

async function updatePhoneNumberTypeIfNeeded() {
  const account = await Account()
  if (!account) return
  const current = LocalStorage.get(LocalStorageKey.PHONE_NUMBER_TYPE)
  const fromAccount = account.PhoneNumberType

  if (current && current !== fromAccount) {
    // LocalStorage.set(LocalStorageKey.PHONE_NUMBER_TYPE, fromAccount)

    await Database.write(async () => {
      account.update((acc) => {
        acc.PhoneNumberType = current
      })
    })

    await updateAccountOnServer({
      ...(Database.toServerObject(account) as AccountDoc),
      PhoneNumberType: current
    })
  }
}
