import BlockOverlay, { BlockOverlayProps } from 'components/BlockOverlay'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import SegmentedControl from 'components/segment-control/SegmentControl'
import { IEventDetails } from 'hooks/useEventDetails'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { DocType } from 'types/api/changes'
import { isUserHaveGroupToAddEvents } from 'utils/api/groups'
import { is24meApp } from 'utils/appType'
import { APP_COLOR } from 'utils/colors'

export interface TypeSegmentProps extends BlockOverlayProps {
  eventHook: IEventDetails
}

export default function TypeSegment(props: TypeSegmentProps) {
  const segments = [
    {
      label: t('event'),
      value: DocType.REGULAR_EVENT,
      disabled: false,
      ref: useRef()
    },
    {
      label: t('task', { count: 1 }),
      value: DocType.TASK,
      disabled: false,
      ref: useRef()
    },
    {
      label: t('note', { count: 1 }),
      value: DocType.NOTE,
      disabled: false,
      ref: useRef()
    }
  ]
  function indexFromType(type: DocType): number | undefined {
    switch (type) {
      case DocType.REGULAR_EVENT:
        return 0
      case DocType.TASK:
        return 1
      case DocType.NOTE:
        return 2
    }
  }

  const segmentRef = useRef()
  const margin = 8

  const [showEventAddingNotPossible, onShowEventAddingNotPossible] =
    React.useState(false)
  const [hasActiveCalendar, setHaveActiveCalendar] = React.useState(false)

  useEffect(() => {
    isUserHaveGroupToAddEvents().then((groupId) => {
      if (groupId) {
        setHaveActiveCalendar(true)
      } else {
        setHaveActiveCalendar(false)
      }
    })
  }, [props.eventHook.getValues('id'), props.eventHook.type])

  return (
    <Controller
      name="Type"
      control={props.eventHook.control}
      defaultValue={is24meApp() ? DocType.REGULAR_EVENT : DocType.EVENT}
      render={({ field }) => (
        <div style={{ width: '100%', marginTop: margin, marginBottom: margin }}>
          <SegmentedControl
            name="itemType"
            callback={(item: any) => {
              console.log(item)
              if (item === DocType.REGULAR_EVENT && !hasActiveCalendar) {
                onShowEventAddingNotPossible(true)
                return
              }
              field.onChange(item)
              props.eventHook.setType(item)

              if (
                item !== DocType.TASK &&
                props.eventHook.getValues('someday') === '1'
              ) {
                props.eventHook.setSomeday('0')
              }
            }}
            currentIndex={indexFromType(
              field.value ?? (is24meApp() ? DocType.TASK : DocType.EVENT)
            )}
            controlRef={segmentRef}
            segments={segments}
          />
          {showEventAddingNotPossible && (
            <ConfirmationDialog
              title={''}
              content={t('toAddEvents')}
              leftButtonHidden
              rightButton={`${t('ok')}`}
              rightButtonColor={APP_COLOR}
              handleRightButton={() => {
                onShowEventAddingNotPossible(false)
              }}
              open
              handleClose={() => {}}
            />
          )}
          <BlockOverlay {...props} />
        </div>
      )}
    />
  )
}
