import GenericDialog from 'components/dialogs/NoInternetDialog'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

import { t } from 'i18next'

interface ConnectionProviderProps {
  children: ReactNode
}
export default function ConnectionProvider(props: ConnectionProviderProps) {
  const [noInternetVisible, setNoInternetVisible] = useState(false)
  const [genericErrorVisible, setGenericErrorVisible] = useState(false)
  const isConnectionAvailable = () => {
    const isOnLine = navigator.onLine

    if (!isOnLine) {
      setNoInternetVisible(true)
    }

    return isOnLine
  }
  const [context, setContext] = useState<ConnectionContextProps>({
    noInternetVisible,
    setNoInternetVisible,
    isConnectionAvailable,
    setGenericErrorVisible
  })

  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setNoInternetVisible(false)
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [noInternetVisible])

  return (
    <ConnectionContext.Provider value={context}>
      {props.children}
      {noInternetVisible && (
        <GenericDialog
          title={t('noInternet')}
          message={t('toUseThisFunctionality')}
          showing={noInternetVisible}
          setShowing={setNoInternetVisible}
          leftButton={t('ok')}
        />
      )}
      {genericErrorVisible && (
        <GenericDialog
          title={t('oopsErrorTitle')}
          message={t('genericErrorBody')}
          showing={genericErrorVisible}
          setShowing={setGenericErrorVisible}
          leftButton={t('ok')}
        />
      )}
    </ConnectionContext.Provider>
  )
}

interface ConnectionContextProps {
  noInternetVisible: boolean
  setNoInternetVisible: (b: boolean) => void
  isConnectionAvailable: () => boolean
  setGenericErrorVisible: (b: boolean) => void
}

const ConnectionContext = createContext<ConnectionContextProps>({
  noInternetVisible: false,
  setNoInternetVisible: (b: boolean) => {},
  isConnectionAvailable: () => true,
  setGenericErrorVisible: (b: boolean) => {}
})

export function useConnection(): ConnectionContextProps {
  return useContext(ConnectionContext)
}
