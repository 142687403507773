import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider'
import GenericErrorPage from 'components/errorPages/GenericErrorPage'
import Main from 'components/Main'
import AuthProvider from 'components/providers/AuthProvider'
import CalendarProvider from 'components/providers/CalendarProvider'
import ConnectionProvider from 'components/providers/ConnectionProvider'
import { useTranslation } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { handleAppBadge } from 'services/api/userSettings'
import database from './model'
import QA from './services/QA'
import { theme } from './theme'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import dayjs from 'dayjs'
import { doGetChanges } from 'components/providers/GetChangesProvider'
import Logger from 'services/Logger'
import { MinuteTimer } from 'components/MinuteTimer'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import { RemoveTempGroups } from 'services/db/Queries/GroupQueries'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import {
  appStoreLink24me,
  appStoreLinkGroupcal,
  googlePlayLink24me,
  googlePlayLinkGroupcal
} from 'utils/device'
import { APP_COLOR } from 'utils/colors'
import AppSettingsProvider from 'components/providers/AppSettingsProvider'
import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import ProvideOutlookContext from 'components/providers/outlook/OutlookProvider'
import {
  CalendarSyncProvider,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'
import { IcloudProvider } from 'components/providers/icloud/IcloudProvider'
import { NotificationProvider } from 'components/providers/NotificationProvider'
import { isCypress } from 'utils/cypress'

const MS_BETWEEN_SYNCS = 5000

QA.init()
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <Main />
      </AuthProvider>
    ),
    errorElement: <GenericErrorPage />
  },
  {
    path: '/calendar/:groupIdAndPass',
    element: (
      <AuthProvider>
        <Main />
      </AuthProvider>
    ),
    errorElement: <GenericErrorPage />
  }
])

const MINUTES_FOR_GOOGLE_SYNC = 10

Sentry.init({
  dsn: 'https://728bb22830fe4d02890296b3b952bd8e@o4505345996357632.ingest.sentry.io/4505352289845248',
  enabled: process.env.REACT_APP_ENV != 'TESTING',
  environment: `${process.env.REACT_APP_ENV_NAME}_${process.env.REACT_APP_APP_TYPE}`,
  release: process.env.APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.2
})

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

function App(props: { pca: IPublicClientApplication | undefined }) {
  handleAppBadge()
  //once app reloads
  LocalStorage.remove(LocalStorageKey.PENDING_EVENT_DATA)
  LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
  LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)

  RemoveTempEvents()
  RemoveTempGroups()

  if (!LocalStorage.get(LocalStorageKey.SHOW_DEV_INFO)) {
    LocalStorage.set(LocalStorageKey.SHOW_DEV_INFO, '1')
  }

  const { t, i18n } = useTranslation()

  const calendarSync = useCalendarSync()

  document.body.dir = i18n.dir()

  function performAppSync() {
    LocalStorage.set(LocalStorageKey.LAST_APP_SYNC, dayjs().valueOf())

    const deviceId = LocalStorage.get(LocalStorageKey.DEVICE_ID)

    if (deviceId)
      doGetChanges(
        deviceId,
        undefined,
        LocalStorage.get(LocalStorageKey.LAST_UPDATE)
      )
    calendarSync.sync()
  }

  function onAppReceivedFocus() {
    const now = dayjs()
    const lastSync = dayjs.unix(
      Number(LocalStorage.get(LocalStorageKey.LAST_APP_SYNC)) / 1000
    )
    const lastGetChangesOnFocus = dayjs(
      Number(LocalStorage.get(LocalStorageKey.LAST_FOCUS_SYNC) ?? 0)
    )

    const millisDiff = now.diff(lastGetChangesOnFocus, 'millisecond')
    const minutesDiff = now.diff(lastSync, 'minute')

    if (minutesDiff > MINUTES_FOR_GOOGLE_SYNC) {
      performAppSync()
    }

    if (millisDiff > MS_BETWEEN_SYNCS) {
      LocalStorage.set(LocalStorageKey.LAST_FOCUS_SYNC, now.valueOf())

      const deviceId = LocalStorage.get(LocalStorageKey.DEVICE_ID)

      if (deviceId)
        doGetChanges(
          deviceId,
          undefined,
          LocalStorage.get(LocalStorageKey.LAST_UPDATE)
        )
    }

    if (!LocalStorage.get(LocalStorageKey.SHOW_DEV_INFO)) {
      LocalStorage.set(LocalStorageKey.SHOW_DEV_INFO, 1)
    }
  }

  function onAppLostFocus() {
    let lastReload

    try {
      lastReload = dayjs.unix(
        Number(LocalStorage.get(LocalStorageKey.APP_RELOAD) ?? 0)
      )
    } catch (e) {
      //fix for https://24me.sentry.io/issues/4286962595/?project=4505352289845248&query=is%3Aunresolved&referrer=issue-stream&stream_index=0
      lastReload = dayjs()
    }

    const now = dayjs()
    const diff = now.diff(lastReload, 'hour')

    if (LocalStorage.get(LocalStorageKey.APP_RELOAD) === null || diff >= 12) {
      LocalStorage.set(LocalStorageKey.APP_RELOAD, dayjs().unix())
      window.location.reload()
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      Logger.debug('tab in focus?', !document.hidden)

      if (!document.hidden) {
        onAppReceivedFocus()
      } else {
        onAppLostFocus()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  //to update google events
  useEffect(() => {
    const interval = setInterval(() => {
      Logger.debug('10 minutes sync')
      Logger.debug('is foreground?', !document.hidden)

      if (!document.hidden) {
        performAppSync()
      }
    }, MINUTES_FOR_GOOGLE_SYNC * 60000)

    return () => clearInterval(interval)
  }, [])

  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const [showDialog, setShowDialog] = useState(true)

  useEffect(() => {
    const userAgent = navigator.userAgent
    setIsMobileDevice(/Android|iPhone|iPad/i.test(userAgent))
    if (isMobileDevice) setShowDialog(/Android|iPhone|iPad/i.test(userAgent))
  }, [])

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleOpenAppStore = () => {
    window.open(is24meApp() ? appStoreLink24me : appStoreLinkGroupcal, '_blank')
  }

  const handleOpenGooglePlay = () => {
    window.open(
      is24meApp() ? googlePlayLink24me : googlePlayLinkGroupcal,
      '_blank'
    )
  }

  const userAgent = navigator.userAgent

  const isAndroid = /Android/i.test(userAgent)
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent)

  return isMobileDevice ? (
    <div>
      {isMobileDevice && (
        <ConfirmationDialog
          title={t('toUseApp', {
            appName: isGroupcalApp() ? t('appName') : t('appName24me')
          })}
          content={undefined}
          rightButton={`${t('downloadApp')}`}
          leftButton={`${t('close')}`}
          rightButtonColor={is24meApp() ? 'black' : APP_COLOR}
          leftButtonColor={theme.palette.input.placeholder}
          handleRightButton={
            isAndroid ? handleOpenGooglePlay : handleOpenAppStore
          }
          handleLeftButton={handleDialogClose}
          open={showDialog}
        />
      )}
    </div>
  ) : (
    <MsalProvider instance={props.pca!}>
      <IcloudProvider>
        <ProvideOutlookContext>
          <DatabaseProvider database={database}>
            <AppModule />
          </DatabaseProvider>
        </ProvideOutlookContext>
      </IcloudProvider>
    </MsalProvider>
  )
}

function AppModule() {
  return (
    <NotificationProvider>
      <AppSettingsProvider>
        <ThemeProvider theme={theme}>
          <CalendarSyncProvider>
            <ConnectionProvider>
              <CalendarProvider>
                <MinuteTimer>
                  <div></div>
                </MinuteTimer>
                <RouterProvider router={router} />
              </CalendarProvider>
            </ConnectionProvider>
          </CalendarSyncProvider>
        </ThemeProvider>
      </AppSettingsProvider>
    </NotificationProvider>
  )
}

export default App
