import { Card, Grid } from '@mui/material'
import {
  AccountDoc,
  DocType,
  EventDoc,
  MasterLabelDoc,
  MasterLabelLabel,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import TFMItemsTitle from './TFMTitle'
import TFMEventsColumns from './TFMEventsColumns'
import { ComponentType } from 'react'
import withObservables from '@nozbe/with-observables'
import ObserveService from 'services/db/Observe'
import { compose } from 'recompose'
import { AddIcon } from 'assets/icons'
import { t } from 'i18next'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import { prepareTasksForUI } from 'utils/api/events'
import { RemoveTempEvents, addTempEventToDate } from 'services/db/Queries/Event'
import dayjs from 'dayjs'
import { generateGroupcalArrayFromRecurrence } from 'components/MinuteTimer'
import { generateStartEndBasedOnTimeWithShift } from 'utils/date'
import { defaultGroupId } from 'utils/groups'
import i18n from 'i18n'
import { Button } from 'components/buttons/styled'
import useLocalStorage from 'components/LocalStorageHook'
import { LocalStorageKey } from 'types/services/localStorage'
import LocalStorage from 'services/LocalStorage'

interface TFMEventsViewProps {
  items?: Model<EventDoc>[]
  type: DocType
  events?: Model<EventDoc>[]
  userSettings?: Model<UserSettingsDoc>
  masterLabel?: Model<MasterLabelDoc>
  account?: Model<AccountDoc>
}

export function TFMEventsView(props: TFMEventsViewProps) {
  //triggers re-render once someday date is changed
  const [someDayDate, setSomedayDate] = useLocalStorage(
    LocalStorageKey.SOMEDAY_DATE,
    dayjs().tz('UTC').startOf('day').unix().toString()
  )
  const appUiTypeContext = useAppUIType()
  const handleAddItem = () => {
    const startEnd = generateStartEndBasedOnTimeWithShift(dayjs().unix())

    setTimeout(() => {
      addTempTaskToDate(
        startEnd.start,
        props.userSettings,
        props.type,
        appUiTypeContext.selectedLabel
      )
    }, 250)
  }

  const showingDocType =
    appUiTypeContext.uiType.value === AppUIType.tasks
      ? DocType.TASK
      : DocType.NOTE

  let events = prepareTasksForUI(
    props.events?.filter(
      (event) =>
        !event.Recurrence ||
        (event.Recurrence && typeof event.Recurrence === 'string')
    ),
    appUiTypeContext.selectedLabel,
    showingDocType
  )

  props.events
    ?.filter(
      (event) =>
        event.Recurrence &&
        typeof event.Recurrence !== 'string' &&
        (!appUiTypeContext.selectedLabel ||
          (event.Label &&
            typeof event.Label !== 'string' &&
            event.Label.some(
              (ele) =>
                ele.LabelText === appUiTypeContext.selectedLabel?.ID &&
                event.Type === showingDocType
            )))
    )
    .forEach((event) => {
      events.push(...generateGroupcalArrayFromRecurrence(event, true))
    })

  return (
    <Grid height={'100%'} display={'flex'} flexDirection={'column'}>
      <Card
        sx={{
          position: 'relative',
          height: '100%',
          borderRadius: '20px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)'
        }}
      >
        <div
          style={{
            padding: '0rem 2rem',
            display: 'flex',
            height: '92.5px',
            alignItems: 'center'
          }}
        >
          <TFMItemsTitle type={props.type} />
        </div>
        <TFMEventsColumns
          userSettings={props.userSettings}
          account={props.account}
          type={props.type}
          events={events}
        />
      </Card>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          right: i18n.dir() === 'ltr' ? 0 : undefined,
          left: i18n.dir() === 'ltr' ? undefined : 0,
          bottom: 40,
          marginRight: i18n.dir() === 'ltr' ? 40 : undefined,
          marginLeft: i18n.dir() === 'ltr' ? undefined : 40
        }}
        onClick={handleAddItem}
      >
        <Button>
          <AddIcon />
          <span>
            {props.type === DocType.TASK
              ? t('task', { count: 1 })
              : t('note', { count: 1 })}
          </span>
        </Button>
      </div>
    </Grid>
  )
}

export default compose(
  withObservables([], () => ({
    userSettings: ObserveService.UserSettings()
  })),
  withObservables([], () => ({
    account: ObserveService.Account()
  })),
  withObservables(['type'], (props: TFMEventsViewProps) => ({
    events: ObserveService.Events(props.userSettings, props.type)
  }))
)(TFMEventsView as ComponentType<unknown>) as any

export function addTempTaskToDate(
  startTimeSeconds: number,
  userSettings: UserSettingsDoc | undefined,
  type: DocType,
  label?: MasterLabelLabel
) {
  RemoveTempEvents().then(() =>
    addTempEventToDate(
      startTimeSeconds,
      dayjs.unix(startTimeSeconds).add(30, 'minute').unix(),
      '',
      false,
      type,
      label?.ID
    )
  )
}
