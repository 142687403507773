import Grid from '@mui/material/Grid'
import { AlarmIcon, CloseIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { formatDuration } from 'date-fns'
import { EventReminder } from 'types/api/changes'

import { t } from 'i18next'
import i18n from 'i18n'

interface RemindersListProps {
  reminders: EventReminder[]
  setReminders: (reminders: EventReminder[]) => void
}
const humanizeDuration = require('humanize-duration')

export default function RemindersList(props: RemindersListProps) {
  return (
    <Grid container>
      {(props.reminders ?? [])
        ?.sort((a, b) => Number(a.offset) - Number(b.offset))
        ?.map((reminder, index) => {
          const removeReminder = () => {
            props.setReminders(
              props.reminders.filter((rem) => rem.offset != reminder.offset)
            )
          }

          return (
            <PopupSectionRow
              key={reminder.offset}
              icon={<AlarmIcon />}
              cursor="default"
              title={
                reminder.offset === 0
                  ? t('onTime')
                  : `${t('timeBefore', {
                      time: humanizeDuration(reminder.offset * 1000, {
                        language:
                          i18n.language.length > 2
                            ? i18n.language.slice(0, 2)
                            : i18n.language
                      })
                    })}`
              }
              value={
                <IconButton size="xs" onClick={removeReminder}>
                  <CloseIcon />
                </IconButton>
              }
            />
          )
        })}
    </Grid>
  )
}
