import { isOwnerForGroup } from '../../../services/db/utils/GroupUtils'
import { GroupDoc } from '../../../types/api/changes'
import { Model } from '../../../types/model'
import { ILoading } from './GroupPopup'

import PopupSectionRow from '../../../components/popup/PopupSectionRow'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'
import { useState } from 'react'
import LocalStorage from '../../../services/LocalStorage'
import { LocalStorageKey } from '../../../types/services/localStorage'
import {
  buildRemoveParticipantData,
  updateGroupOnServer
} from '../../../services/api/group'
import Logger from '../../../services/Logger'
import { useConnection } from 'components/providers/ConnectionProvider'
import { t } from 'i18next'

interface ExitGroupProps extends ILoading {
  group: Model<GroupDoc>
  rowTitle?: string
  dialogTitle?: string
  dialogBodyTitle?: string
  action?: () => void
  positiveTitle?: string
}

export function ExitGroupButton(props: ExitGroupProps) {
  const connection = useConnection()
  const [openExitGroup, setOpenExitGroup] = useState(false)
  const handleOpenExitGroup = () => setOpenExitGroup(true)
  const handleCloseExitGroup = () => setOpenExitGroup(false)

  const onOkClick = async () => {
    handleCloseExitGroup()

    if (props.action) {
      props.action()
    } else {
      if (connection.isConnectionAvailable()) {
        const userId = LocalStorage.get(LocalStorageKey.USER_ID)!

        props.setLoading(true)
        try {
          await updateGroupOnServer(
            buildRemoveParticipantData(
              props.group.Participants[userId],
              userId,
              props.group._id
            ),
            props.group
          )
        } catch (e) {
          Logger.red(String(e))
        }
        props.setLoading(false)
      }
    }
  }

  return (
    <div>
      <PopupSectionRow
        action={() => {
          handleOpenExitGroup()
        }}
        titleColor="red"
        title={props.rowTitle ?? t('exitCalendar')}
      />
      {openExitGroup && (
        <ConfirmationDialog
          open={openExitGroup}
          title={
            props.dialogTitle ??
            t('exitCalendarName', { groupName: props.group.Name })
          }
          content={props.dialogBodyTitle ?? ''}
          leftButtonColor={'black'}
          leftButton={`${t('cancel')}`}
          rightButton={props.positiveTitle ?? `${t('ok')}`}
          handleLeftButton={() => {
            handleCloseExitGroup()
          }}
          handleRightButton={onOkClick}
          handleClose={() => {
            handleCloseExitGroup()
          }}
        />
      )}
    </div>
  )
}
