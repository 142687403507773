import { Menu, MenuItem, Tooltip } from '@mui/material'
import { MoreOptions } from 'assets/icons'
import Button from 'components/buttons/Button'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { t } from 'i18next'

import React from 'react'
import { Tier } from 'utils/api/accounts'

export interface Option {
  title: string
  neededTier?: Tier
  color?: string
}

interface Props {
  options: Option[]
  tooltipTitle?: string
  onSelectOption: (option: string) => void
  disabled?: boolean
}

const TooltipMenu: React.FC<Props> = ({
  options,
  tooltipTitle,
  onSelectOption,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectOption = (option: string) => {
    onSelectOption(option)
    handleClose()
  }

  return (
    <>
      <Tooltip title={tooltipTitle ?? <></>}>
        <Button
          tootltip={`${t('moreOptions')}`}
          disabled={disabled}
          onClick={handleClick}
          type="button"
          size="icon"
        >
          <MoreOptions />
        </Button>
      </Tooltip>
      <Menu
        PaperProps={{
          style: { borderRadius: 16 }
        }}
        sx={{ zIndex: 9999 }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.title}>
            <PopupSectionRow
              onlyTitle
              titleColor={option.color}
              title={option.title}
              neededTier={option.neededTier}
              action={() => handleSelectOption(option.title)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TooltipMenu
