import './styles.scss'
import { Grid } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import withObservables from '@nozbe/with-observables'
import Analytics, {
  EventProperties,
  Events,
  UserProperties
} from 'analytics/Analytics'
import { CheckmarkIcon, CloseIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import GenericDialog from 'components/dialogs/NoInternetDialog'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import {
  changePlanAndUpdateAccount,
  pauseSubscriptionAndUpdateAccount,
  tryPlanAndUpdateAccount,
  updateAccountAfterPlanSubscription
} from 'services/api/payments'
import ObserveService from 'services/db/Observe'
import LocalStorage from 'services/LocalStorage'
import { AccountDoc, AccountProduct, PaddleCheckout } from 'types/api/changes'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import useFitText from 'use-fit-text'
import {
  GROUP_CAL_PROD_PLANIDS,
  hasExhaustedTrialOptions,
  highestActiveProduct,
  isActivePlanFromAnotherStore,
  P_SKU_BUSINESS_MONTHLY,
  P_SKU_BUSINESS_PLUS_MONTHLY,
  P_SKU_BUSINESS_PLUS_YEARLY,
  P_SKU_BUSINESS_YEARLY,
  P_SKU_PRO_MONTHLY,
  P_SKU_PRO_YEARLY,
  PADDLE_SKU_BUSINESS_MONTHLY,
  PADDLE_SKU_BUSINESS_PLUS_MONTHLY,
  PADDLE_SKU_BUSINESS_PLUS_YEARLY,
  PADDLE_SKU_BUSINESS_YEARLY,
  PADDLE_SKU_PRO_MONTHLY,
  PADDLE_SKU_PRO_YEARLY,
  trialProduct
} from 'utils/api/accounts'
import { isTestEnv } from 'utils/env'

import ConfirmationDialog from '../ConfirmationDialog'
import { InfoOutlined } from '@mui/icons-material'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
})

export type PlanName = '_free_tier' | 'tier1' | 'tier2' | 'tier3'

interface PlanInfoProps {
  setOpen: (b: boolean) => void
  account?: Model<AccountDoc>
  recommendedPlan: PlanName | null | undefined
  onPurchaseDialogClose?: (
    userAction: 'PURCHASED' | 'DIALOG_CLOSED' | 'PURCHASE_ABORTED',
    plan: PlanName
  ) => void
  open: boolean
  features?: String[] | null
}

type Plan = {
  name: string
  key: PlanName
  description: string
  annualPlanId?: number
  monthlyPlanId?: number
  monthlyPrice: string
  annualPrice: string
}

export const supportedFeatures: Array<{
  title: string
  key: string
  desc: string
  _free_tier: string | boolean
  tier1: string | boolean
  tier2: string | boolean
  tier3: string | boolean
  [key: string]: string | boolean // Add this line
}> = [
  {
    title: t('numberOfCalendars'),
    key: 'numCalendars',
    _free_tier: `${t('unlimited')}`,
    tier1: `${t('unlimited')}`,
    tier2: `${t('unlimited')}`,
    tier3: `${t('unlimited')}`,
    desc: t('num_calendars_desc')
  },
  {
    title: t('numberOfEvents'),
    key: 'numEvents',
    _free_tier: `${t('unlimited')}`,
    tier1: `${t('unlimited')}`,
    tier2: `${t('unlimited')}`,
    tier3: `${t('unlimited')}`,
    desc: t('num_events_desc')
  },
  {
    title: t('numberOfAdmins'),
    key: 'numAdmins',
    _free_tier: '1',
    tier1: '1',
    tier2: '3',
    tier3: `${t('unlimited')}`,
    desc: t('num_admins_desc')
  },
  {
    title: t('chooseAddEvents'),
    key: 'permManagementAddEvents',
    _free_tier: false,
    tier1: true,
    tier2: true,
    tier3: true,
    desc: t('add_events_desc')
  },
  {
    title: t('chooseCalendarNamePhoto'),
    key: 'permManagementCalendarName',
    _free_tier: false,
    tier1: true,
    tier2: true,
    tier3: true,
    desc: t('change_calendar_desc')
  },
  {
    title: t('chooseCanAddMembers'),
    key: 'permManagementAddMembers',
    _free_tier: false,
    tier1: true,
    tier2: true,
    tier3: true,
    desc: t('add_members_desc')
  },
  {
    title: t('rsvpForEvents'),
    key: 'rsvp',
    _free_tier: false,
    tier1: true,
    tier2: true,
    tier3: true,
    desc: t('rsvp_desc')
  },
  {
    title: t('differentColorsPerMembers'),
    key: 'memberColors',
    _free_tier: false,
    tier1: true,
    tier2: true,
    tier3: true,
    desc: t('color_desc')
  },
  {
    title: t('prioritySupport'),
    key: 'prioritySupport',
    _free_tier: false,
    tier1: false,
    tier2: true,
    tier3: true,
    desc: t('support_desc')
  },
  {
    title: t('landlineNumber'),
    key: 'landline',
    _free_tier: false,
    tier1: false,
    tier2: true,
    tier3: true,
    desc: t('landline_feature_desc')
  },
  {
    title: t('businessPage'),
    key: 'business',
    _free_tier: false,
    tier1: false,
    tier2: true,
    tier3: true,
    desc: t('business_page_desc')
  },
  {
    title: t('calendarChannels'),
    key: 'calendarChannels',
    _free_tier: false,
    tier1: false,
    tier2: false,
    tier3: true,
    desc: t('channels_desc')
  },
  {
    title: t('verifiedCalendars'),
    key: 'verifiedChannels',
    _free_tier: false,
    tier1: false,
    tier2: false,
    tier3: true,
    desc: t('verified_desc')
  },
  {
    title: t('syncEventsAcrossCalendars'),
    key: 'syncEvents',
    _free_tier: false,
    tier1: false,
    tier2: false,
    tier3: true,
    desc: t('sync_desc')
  }
]

export function PlanInfo(props: PlanInfoProps) {
  const [subscriptionType, setSubscriptionType] = useState<
    'monthly' | 'annually'
  >('monthly')

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [infoDialogShowing, setInfoDialogShowing] = useState<boolean>(false)
  const [activeMessage, setActiveMessage] = useState<string>('')

  const {
    setOpen,
    account,
    open,
    recommendedPlan,
    onPurchaseDialogClose,
    features
  } = props

  function logAnalytics(
    event: Events,
    account?: AccountDoc,
    additionalData?: {}
  ) {
    if (account) {
      const product = highestActiveProduct(account)
      let data = {
        [UserProperties.IS_ACTIVE_SUBS]: product != null ? 'Yes' : 'No',
        [UserProperties.ACTIVE_SUBS]: product?.ID,
        [EventProperties.NEEDED_TIER]:
          recommendedPlanToAnalyticsTier(recommendedPlan),
        [EventProperties.FEATURES_OPENED_WITH]: features?.join(', '),
        [UserProperties.IS_TRIAL_EXPIRED]: hasExhaustedTrialOptions(account)
          ? 'Yes'
          : 'No',
        [UserProperties.IS_IN_ACTIVE_TRIAL]:
          trialProduct(account) != undefined && product?.Status === 'trialing'
            ? 'Yes'
            : 'No',
        [EventProperties.TRIAL_OR_PLAN_EXPIRES_IN]:
          product?.Status === 'trialing' || product?.Status === 'cancelled'
            ? dayjs(Number(product?.ExpiryDate ?? '0') * 1000).diff(
                dayjs(),
                'seconds'
              )
            : undefined,
        ...additionalData
      }
      if (product?.Status !== 'trialing' && product?.Status !== 'cancelled') {
        delete data[EventProperties.TRIAL_OR_PLAN_EXPIRES_IN]
      }
      Analytics.getInstance().sendEvent(event, data)
    }
  }

  useEffect(() => {
    if (open) {
      //log analytics only on opening
      logAnalytics(Events.OPEN_INAPP_PURCHASE, props.account)
    }
  }, [open])

  const plans: Array<Plan> = [
    {
      name: t('tierBasic'),
      key: '_free_tier',
      description: t('freeDesc'),
      monthlyPrice: '$0',
      annualPrice: '$0'
    },
    {
      name: t('tierPro'),
      key: 'tier1',
      annualPlanId: PADDLE_SKU_PRO_YEARLY,
      monthlyPlanId: PADDLE_SKU_PRO_MONTHLY,
      description: t('proDesc'),
      monthlyPrice: '$7.99',
      annualPrice: '$4.99'
    },
    {
      name: t('tierBusiness'),
      key: 'tier2',
      annualPlanId: PADDLE_SKU_BUSINESS_YEARLY,
      monthlyPlanId: PADDLE_SKU_BUSINESS_MONTHLY,
      description: t('businessDesc'),
      monthlyPrice: '$24.99',
      annualPrice: '$16.99'
    },
    {
      name: t('tierBusinessPlus'),
      key: 'tier3',
      annualPlanId: PADDLE_SKU_BUSINESS_PLUS_YEARLY,
      monthlyPlanId: PADDLE_SKU_BUSINESS_PLUS_MONTHLY,
      description: t('businessPlusDesc'),
      monthlyPrice: '$74.99',
      annualPrice: '$49.99'
    }
  ]

  const annualSavings = () => {
    const proPlan = plans.find((item) => item.key === 'tier3')
    if (!proPlan) {
      return 'O'
    }
    const priceMonthly = proPlan?.monthlyPrice.replace(/^\D+/g, '')
    const priceAnnually = proPlan?.annualPrice.replace(/^\D+/g, '')
    const ratio = parseFloat(priceAnnually) / parseFloat(priceMonthly)
    const savings = 1 - ratio
    return Math.round(savings * 100)
  }

  const activePlan: AccountProduct | null | undefined =
    account && highestActiveProduct(account)

  const toggleSubscriptionType = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (subscriptionType === 'monthly') {
      setSubscriptionType('annually')
    } else {
      setSubscriptionType('monthly')
    }
  }

  const getPlanFeatures = (planKey: string) => {
    return supportedFeatures.map((feat) => ({
      key: feat.key,
      title: feat.title,
      value: feat[planKey]
    }))
  }

  const handleTryForFree = (plan: Plan) => {
    logAnalytics(Events.CLICK_INAPP_PURCHASE, props.account, {
      [EventProperties.IS_PURCHASING_TRIAL]: 'Yes'
    })
    const planIdkey = [plan.key, subscriptionType].join('_')

    const planId = GROUP_CAL_PROD_PLANIDS.find((i) => i.includes(planIdkey))
    if (!planId) {
      throw `${planIdkey} Plan not supported. List ${GROUP_CAL_PROD_PLANIDS}`
    }

    setIsProcessing(true)
    account &&
      tryPlanAndUpdateAccount(account, planId)
        .then((updatedAccount) => {
          logAnalytics(Events.INAPP_PURCHASED, updatedAccount, {
            [EventProperties.IS_PURCHASING_TRIAL]: 'Yes'
          })

          logGTag('Start Trial', {
            send_to: 'AW-10788534611/I-CICPvNnY0YENPyr5go',
            value: '0',
            currency: 'ILS',
            transaction_id: ''
          })

          typeof onPurchaseDialogClose === 'function' &&
            updatedAccount &&
            onPurchaseDialogClose('PURCHASED', plan.key)
        })
        .finally(() => setIsProcessing(false))
  }

  const handlePurchase = (plan: Plan) => {
    logAnalytics(Events.CLICK_INAPP_PURCHASE, props.account, {
      [EventProperties.IS_PURCHASING_TRIAL]: 'No'
    })
    const planId =
      subscriptionType === 'monthly' ? plan.monthlyPlanId : plan.annualPlanId

    const price =
      subscriptionType === 'monthly' ? plan.monthlyPrice : plan.annualPrice
    if (planId) {
      window?.Paddle?.Checkout.open({
        product: planId,
        title: t('groupcalPlan', { planName: plan.name }),
        email: account?.PaymentEmail || account?.Email,
        method: 'overlay',
        displayModeTheme: 'light',
        passthrough: JSON.stringify({
          userId: account?._id,
          amplitude_deviceid: Analytics.getInstance().amplitudeDeviceId,
          singularid: Analytics.getInstance().singularDeviceId,
          custom_id: account?._id
        }),
        closeCallback: (e: any) => {
          typeof onPurchaseDialogClose === 'function' &&
            account &&
            onPurchaseDialogClose(
              'PURCHASE_ABORTED',
              activePlan?.ID
                ? subscriptionIdToPlan(activePlan?.ID?.toLowerCase())
                : '_free_tier'
            )
        },
        successCallback: (e: PaddleCheckout) => {
          setIsProcessing(true)
          window?.Paddle?.Order?.details(
            e.checkout.id,
            (data: { state: string; order: { subscription_id: any } }) => {
              if (account) {
                if (data.state === 'processed') {
                  updateAccountAfterPlanSubscription(account, {
                    ...e,
                    checkout: {
                      ...e.checkout,
                      subscriptionId: data.order.subscription_id
                    }
                  })
                    .then((updatedAccount) => {
                      logAnalytics(Events.INAPP_PURCHASED, updatedAccount, {
                        [EventProperties.IS_PURCHASING_TRIAL]: 'No'
                      })
                      logGTag('Purchase', {
                        send_to: 'AW-10788534611/9hGiCP7NnY0YENPyr5go',
                        value: price.slice(1, price.length),
                        currency: price.slice(0, 1),
                        transaction_id: e.checkout.id
                      })
                      typeof onPurchaseDialogClose === 'function' &&
                        updatedAccount &&
                        onPurchaseDialogClose(
                          'PURCHASED',
                          subscriptionIdToPlan(e.product.id)
                        )
                    })
                    .finally(() => setIsProcessing(false))
                }
              }
            },
            false
          )
        }
      })
    }
  }

  const handleCancelPlan = (plan: Plan) => {
    logAnalytics(Events.INAPP_CANCELLED, account)

    if (account && isActivePlanFromAnotherStore(account)) {
      setInfoDialogShowing(true)
      setActiveMessage(`${t('useTheAppToCancel')}`)
    } else if (account && activePlan && activePlan.SubscriptionId) {
      setIsProcessing(true)
      pauseSubscriptionAndUpdateAccount(
        account,
        activePlan?.SubscriptionId
      ).finally(() => setIsProcessing(false))
    }
  }

  const handleActiveMessageClose = () => {
    setInfoDialogShowing(false)
    setActiveMessage('')
  }

  const handleChangePlan = (plan: Plan) => {
    logAnalytics(Events.CLICK_INAPP_PURCHASE, props.account, {
      [EventProperties.IS_PURCHASING_TRIAL]: 'No'
    })
    if (account && isActivePlanFromAnotherStore(account)) {
      setInfoDialogShowing(true)
      setActiveMessage(
        `${t('yourActivePlanPurchased', { store: activePlan?.Store })}`
      )
    } else if (activePlan?.Status === 'trialing') {
      setInfoDialogShowing(true)
    } else if (
      activePlan &&
      activePlan.SubscriptionId &&
      plan.annualPlanId &&
      plan.monthlyPlanId
    ) {
      setIsProcessing(true)
      account &&
        changePlanAndUpdateAccount(
          account,
          activePlan.SubscriptionId,
          subscriptionType === 'monthly'
            ? plan?.monthlyPlanId
            : plan?.annualPlanId
        )
          .then((updatedAccount) => {
            logAnalytics(Events.INAPP_PURCHASED, updatedAccount, {
              [EventProperties.IS_PURCHASING_TRIAL]: 'No'
            })
            console.log(plan)
            account &&
              typeof onPurchaseDialogClose === 'function' &&
              onPurchaseDialogClose(
                'DIALOG_CLOSED',
                plan.annualPlanId
                  ? subscriptionIdToPlan(plan.annualPlanId)
                  : '_free_tier'
              )
          })
          .finally(() => {
            setIsProcessing(false)
          })
    }
  }

  const handleDialogClose = () => {
    setOpen(false)
    setTimeout(() => {
      LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
    }, 150) // timeout to wait close animation
  }

  const PlanNotice = (props: { plan: Plan }) => {
    const { plan } = props
    const isActivePlan = activePlan?.ID?.includes(plan.key)
    const isRecommendedPlan =
      recommendedPlan?.includes(plan.key) &&
      !activePlan?.ID?.includes(recommendedPlan)
    if (isActivePlan) {
      const date = dayjs(Number(activePlan?.ExpiryDate) * 1000).fromNow()
      let notice = t('existingPlan')
      if (activePlan?.Status === 'trialing') notice = t('freeTrialEndsIn')
      if (activePlan?.Status === 'cancelled') notice = t('existingPlanEndsIn')

      return (
        <span
          className={`plan-notice ${plan.key} ${
            activePlan?.Status == 'active' ? 'active' : ''
          }`}
        >
          {notice}
          {(activePlan?.Status === 'trialing' ||
            activePlan?.Status === 'cancelled') && (
            <span className="plan-relative-date">{date}</span>
          )}
        </span>
      )
    }

    if (!activePlan && plan.key === '_free_tier') {
      return (
        <span className={`plan-notice ${plan.key} active`}>{`${t(
          'existingPlan'
        )}`}</span>
      )
    }

    if (isRecommendedPlan) {
      return (
        <span className={`plan-notice ${plan.key} recommended`}>
          {t('requiredPlan')}
        </span>
      )
    }
    return null
  }

  const PlanActionBtn = (props: { plan: Plan }) => {
    const { plan } = props
    let action = null
    let label = ''
    let btnClass = ''

    const canTry = (plan.monthlyPlanId || plan.annualPlanId) && !activePlan

    const isNotActivePlanAndOnTrial =
      (plan.monthlyPlanId || plan.annualPlanId) &&
      activePlan &&
      activePlan.Status === 'trialing' &&
      !activePlan?.ID?.includes(plan.key)

    const canPurchase =
      activePlan &&
      activePlan.Status === 'trialing' &&
      activePlan?.ID?.includes(plan.key)

    const canChangePlan =
      (plan.monthlyPlanId || plan.annualPlanId) &&
      activePlan &&
      activePlan.Status !== 'trialing' &&
      !activePlan?.ID?.includes(plan.key)

    const canCancel =
      activePlan &&
      activePlan.Status !== 'trialing' &&
      activePlan.Status !== 'cancelled' &&
      activePlan?.ID?.includes(plan.key)

    const canActivate =
      activePlan &&
      activePlan.Status !== 'trialing' &&
      activePlan.Status === 'cancelled' &&
      activePlan?.ID?.includes(plan.key)

    const cantTry = hasExhaustedTrialOptions(account)

    if (canTry || isNotActivePlanAndOnTrial) {
      action = handleTryForFree.bind(null, plan)
      label = t('tryForFree')
      btnClass = isNotActivePlanAndOnTrial ? 'changetrialplan-btn' : 'trial-btn'
    }

    if (canPurchase) {
      action = handlePurchase.bind(null, plan)
      label = t('purchase')
      btnClass = 'purchase-btn'
    }

    if (cantTry) {
      action = handlePurchase.bind(null, plan)
      label = t('continue')
      btnClass = 'purchase-btn'
    }

    if (canCancel) {
      action = handleCancelPlan.bind(null, plan)
      label = t('cancelPlan')
      btnClass = 'cancel-plan-btn'
    }

    if (canChangePlan || canActivate) {
      action = handleChangePlan.bind(null, plan)
      const activePlanMeta = plans.find((p) => activePlan?.ID.includes(p?.key))
      const isHigherPlan =
        activePlanMeta &&
        parseInt(plan.monthlyPrice.replace(/^\D+/g, '')) >
          parseInt(activePlanMeta.monthlyPrice.replace(/^\D+/g, ''))
      if (activePlan.Status === 'cancelled') {
        label = t('activate')
      } else if (isHigherPlan) {
        label = t('upgrade')
      } else {
        label = t('downgrade')
      }
      btnClass = 'changeplan-btn'
    }

    if (plan.key === '_free_tier') {
      return null
    }

    if (!action) {
      return null
    }

    return (
      <Button className={`trial-btn ${btnClass}`} onClick={action?.bind(null)}>
        {label}
      </Button>
    )
  }

  const DialogTitle = () => {
    const hasUsedPlanBefore =
      activePlan?.Status === 'active' ||
      activePlan?.Status === 'cancelled' ||
      hasExhaustedTrialOptions(account)

    if (hasUsedPlanBefore) {
      return (
        <>
          <h2 className="membership-title">{t('chooseTheRightPlan')}</h2>
          <h3 className="membership-subtitle">{t('cancelAnytime')}</h3>
        </>
      )
    }

    return (
      <>
        <h2 className="membership-title">{t('chooseTheRightPlan')}</h2>
        <h3 className="membership-subtitle">{t('try7days')}</h3>
      </>
    )
  }

  const { fontSize, ref } = useFitText({
    minFontSize: 50,
    maxFontSize: 100
  })

  return (
    <ConfirmationDialog
      title={''}
      fullWidth={true}
      classes={{
        paper: 'plan-container'
      }}
      scroll="body"
      disableScrollLock
      showCloseIcon
      content={
        <div className="membership-block" id="membership-block">
          <div className="subscription-header">
            <GenericDialog
              title=""
              message={activeMessage}
              leftButton={`${t('ok')}`}
              showing={infoDialogShowing}
              setShowing={handleActiveMessageClose}
            />

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isProcessing}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <DialogTitle />

            <div className="subscription-type">
              <div className="subscription-links-wrapper">
                <a
                  className={`monthly ${
                    subscriptionType === 'monthly' ? 'active' : ''
                  }`}
                  href="#"
                  onClick={toggleSubscriptionType}
                >
                  {t('monthly')}
                </a>
                <a
                  className={`yearly ${
                    subscriptionType === 'annually' ? 'active' : ''
                  }`}
                  href="#"
                  onClick={toggleSubscriptionType}
                >
                  {t('yearlySave', { percent: annualSavings() })}
                </a>
              </div>
            </div>
          </div>

          <div className="membership-table">
            <div className="membership-table-inner">
              <div className="membership-column first">
                <div className="membership-row first"></div>
                {supportedFeatures.map((feat) => (
                  <FeatureTitle
                    key={feat.key}
                    title={feat.title}
                    desc={feat.desc}
                  />
                ))}
              </div>
              {plans.map((plan) => (
                <div className={`membership-column ${plan.key}`} key={plan.key}>
                  <PlanNotice plan={plan} />

                  <div className="membership-row first">
                    <div className="row-content">
                      <h3 className="row-title">{plan.name}</h3>
                      <div ref={ref} className="row-text">
                        {plan.description}
                      </div>
                      <div className="row-price">
                        {subscriptionType === 'monthly'
                          ? plan.monthlyPrice
                          : plan.annualPrice}
                        <br />
                        <span>
                          {' '}
                          /{' '}
                          {subscriptionType === 'monthly'
                            ? t('month_one')
                            : t('month_one')}
                        </span>
                      </div>
                      <PlanActionBtn plan={plan} />
                    </div>
                  </div>
                  {getPlanFeatures(plan.key).map((feat) => {
                    if (typeof feat.value == 'boolean') {
                      return (
                        <div
                          className={`membership-row ${feat.key}`}
                          key={feat.key}
                        >
                          {feat.value ? (
                            <CheckmarkIcon color="#51B87B" />
                          ) : (
                            <CloseIcon color="#FF0A0A" />
                          )}
                        </div>
                      )
                    }

                    return (
                      <div
                        className={`membership-row ${feat.key}`}
                        key={feat.key}
                      >
                        {feat.value}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      open={open}
      rightButtonHidden
      leftButtonHidden
      handleClose={handleDialogClose}
      handleLeftButton={handleDialogClose}
    />
  )
}

interface FeatureTitleProps {
  title: string
  key: string
  desc: string
}

function FeatureTitle(props: FeatureTitleProps) {
  const { fontSize, ref } = useFitText({
    minFontSize: 50,
    maxFontSize: 100
  })
  return (
    <Grid
      justifyContent={'space-between'}
      className={`membership-row ${props.key}`}
      key={props.key}
      ref={ref}
      style={{ fontSize: fontSize }}
    >
      {props.title}
      <GroupcalTooltip
        backgroundColor="white"
        textColor="black"
        textSize="16px"
        delay={0}
        enterNextDelay={0}
        enterDelay={0}
        title={props.desc}
      >
        <InfoOutlined sx={{ marginTop: '8px', fontSize: '1.3rem' }} />
      </GroupcalTooltip>
    </Grid>
  )
}
export default withObservables([], () => ({
  account: ObserveService.Account()
}))(PlanInfo)

export function subscriptionIdToPlan(id: number | string): PlanName {
  switch (id) {
    case PADDLE_SKU_PRO_MONTHLY:
    case PADDLE_SKU_PRO_YEARLY:
    case P_SKU_PRO_MONTHLY:
    case P_SKU_PRO_YEARLY:
      return 'tier1'
    case PADDLE_SKU_BUSINESS_MONTHLY:
    case PADDLE_SKU_BUSINESS_YEARLY:
    case P_SKU_BUSINESS_MONTHLY:
    case P_SKU_BUSINESS_YEARLY:
      return 'tier2'
    case P_SKU_BUSINESS_PLUS_MONTHLY:
    case P_SKU_BUSINESS_PLUS_YEARLY:
    case PADDLE_SKU_BUSINESS_PLUS_MONTHLY:
    case PADDLE_SKU_BUSINESS_PLUS_YEARLY:
      return 'tier3'
    default:
      return '_free_tier'
  }
}

export function recommendedPlanToAnalyticsTier(
  recommendedPlan: PlanName | undefined | null
): string {
  if (recommendedPlan) {
    recommendedPlan
    switch (recommendedPlan) {
      case 'tier1':
        return 'Tier10'
      case 'tier2':
        return 'Tier20'
      case 'tier3':
        return 'Tier30'
      default:
        return 'Free'
    }
  }

  return ''
}

export interface GTagProps {
  send_to: string
  value: string
  currency: string
  transaction_id: string
  event_callback?: () => void
}

export function logGTag(type: string, props: GTagProps) {
  if (!isTestEnv()) {
    gtag('event', type, props)
  }
}
