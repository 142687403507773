import { Button, IconButton } from '@mui/material'
import { lighten, styled } from '@mui/material/styles'

export const RoundButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '4.25rem',
  background: '#0f7feb',
  color: 'white',
  boxShadow: '1px 1px 6px 0px rgba(50, 50, 71, 0.05);',
  fontSize: '1rem',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 3,
  paddingBottom: 3,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: lighten('#0f7feb', 0.5)
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: lighten('#0f7feb', 0.5)
  }
}))
