import React, { ReactNode, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { theme } from 'theme'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

export interface InputFieldWithIconProps {
  icon: ReactNode
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  isPasswordField?: boolean
  autocomplete?: string
  id?: string
}

const InputFieldWithIcon = (props: InputFieldWithIconProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      id={props.id}
      variant="outlined"
      placeholder={props.placeholder}
      type={props.isPasswordField && !showPassword ? 'password' : 'text'}
      autoComplete={'off'}
      autoCorrect="off"
      sx={{
        width: '100%',
        borderColor: '#B9B9B9',
        height: '48px',
        '& fieldset': {
          borderWidth: '1px',
          borderRadius: '24px'
        },
        '& input': {
          height: '30px',
          padding: theme.spacing(0, 2),
          // Targeting the input field for autofill styles
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: '#000'
          },
          '&:-webkit-autofill:hover': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: '#000'
          },
          '&:-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: '#000'
          },
          '&:-webkit-autofill:active': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            WebkitTextFillColor: '#000'
          }
        }
      }}
      onChange={props.onChange}
      InputProps={{
        sx: {
          height: '48px'
        },

        startAdornment: (
          <InputAdornment position="start">
            <IconButton tabIndex={-1}>{props.icon}</IconButton>
          </InputAdornment>
        ),
        endAdornment: props.isPasswordField ? (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
    />
  )
}

export default InputFieldWithIcon
