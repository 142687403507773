import { Grid, Typography } from '@mui/material'
import Input from 'components/form/Input'
import { t } from 'i18next'
import { ChangeEvent } from 'react'
import { theme } from 'theme'

export interface TwoInputFieldsProps {
  value: string
  value2: string
  placeholder1: string
  placeholder2: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onChange2: (event: ChangeEvent<HTMLInputElement>) => void
  inProgress: boolean
  errorText: string
}

export default function TwoInputFields(props: TwoInputFieldsProps) {
  return (
    <>
      <Grid
        style={{
          pointerEvents: props.inProgress ? 'none' : 'all',
          width: '300px'
        }}
        container
        rowSpacing={4}
        flexDirection={'column'}
      >
        <Grid item>
          <Input
            variant="regular"
            placeholder={props.placeholder1}
            value={props.value}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item>
          <Input
            variant="regular"
            shouldNotFocus
            placeholder={props.placeholder2}
            value={props.value2}
            onChange={props.onChange2}
          />
        </Grid>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography marginTop={4} color={'red'} fontSize={12}>
            {props.errorText ? props.errorText : <>&nbsp;</>}
          </Typography>
        </div>
      </Grid>
    </>
  )
}
