import {
  Backdrop,
  CircularProgress,
  Grid,
  lighten,
  styled
} from '@mui/material'
import { theme } from 'theme'
import { AccountDoc, GroupDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { Scrollable } from './styled'
import { localGroupById } from 'services/db/Queries/GroupQueries'
import { GroupRow } from 'components/sidebar/GroupsList'
import { useRef, useState } from 'react'
import { GroupPrivacy } from 'model/models/Group'
import {
  isUserActiveInGroup,
  isUserParticipantOfAGroup
} from 'utils/api/groups'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { joinGroup } from 'services/api/group'
import { useCalendar } from 'components/providers/CalendarProvider'
import { APP_COLOR } from 'utils/colors'
import { RoundButton } from 'components/inputs/styled'
import { t } from 'i18next'
import { MiscText } from 'components/login/styled'
import { CheckmarkIcon } from 'assets/icons'
import i18n from 'i18n'
import { GroupContextMenuProps } from 'components/sidebar/Groups'
import GroupItemContextMenu from 'components/contextMenu/GroupItemContextMenu'

export enum GROUP_TYPE {
  PUBLIC,
  ALL
}

export interface GroupsColumnProps {
  localGroups?: GroupDoc[]
  groups?: GroupDoc[]
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc
  account?: Model<AccountDoc>
  setOpen: (b: boolean) => void
  type: GROUP_TYPE
}

export default function GroupsColumn(props: GroupsColumnProps) {
  const calendar = useCalendar()
  const [inProgress, setInProgress] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const [contextMenuPosition, setContextMenuPosition] =
    useState<GroupContextMenuProps | null>(null)

  const handleCloseContextMenu = () => {
    setContextMenuPosition(null)
  }

  const handleContextMenu = (
    event: React.MouseEvent<HTMLElement>,
    group: GroupDoc
  ) => {
    event.preventDefault() // Prevent the default context menu from showing
    handleCloseContextMenu() // Close the existing context menu

    const containerRect = containerRef?.current?.getBoundingClientRect()
    if (!containerRect) return

    const offsetX =
      event.clientX -
      (i18n.dir() === 'rtl' ? containerRect.right : containerRect.left)
    const offsetY = event.clientY - containerRect.top

    setContextMenuPosition({ x: offsetX, y: offsetY, group })
  }

  const joinGroupIfNeeded = (group: GroupDoc) => {
    localGroupById(group._id ?? '').then((localGroup) => {
      if (
        localGroup &&
        isUserActiveInGroup(
          localGroup,
          LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        )
      ) {
        //we already part of this group, skipping
        props.setOpen(false)
        calendar.onGroupServer(localGroup._id)
      } else {
        setInProgress(true)

        if (group._id) {
          joinGroup(group._id)
            .then(() => {
              calendar.onGroupServer(localGroup._id)
            })
            .finally(() => {
              setInProgress(false)
              props.setOpen(false)
            })
        } else {
          props.setOpen(false)
        }
      }
    })
  }

  return (
    <Scrollable ref={containerRef}>
      <div style={{ height: '8px', width: '100%' }}>
        <br />
      </div>
      <>
        {props.groups?.length === 0 && <NoResults />}
        {props.groups?.map((group) => {
          const alreadyInPublicGroup =
            props.type === GROUP_TYPE.PUBLIC &&
            isUserParticipantOfAGroup(
              props.localGroups?.find((g) => g._id === group._id),
              LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
            )

          return (
            <Grid
              key={group._id}
              display={'flex'}
              flexDirection={'row'}
              position={'relative'}
              alignItems={'center'}
            >
              <GroupRow
                key={group._id}
                row={group}
                disableSelection
                onCancelAdd={() => {}}
                onAddGroupPopupState={() => {}}
                handleCloseContextMenu={handleCloseContextMenu}
                handleContextMenu={handleContextMenu}
                masterLabel={undefined}
                groups={props.groups}
                topGroups={[]}
                userSettings={props.userSettings}
                handleGroupClick={(group) => {
                  if (group.PrivacyMode !== GroupPrivacy.PRIVATE && group._id) {
                    // do nothing
                    joinGroupIfNeeded(group)
                  } else {
                    props.setOpen(false)
                  }
                }}
              />
              {props.type === GROUP_TYPE.PUBLIC && (
                <Grid
                  alignItems={'center'}
                  display={'flex'}
                  style={{
                    position: 'absolute',
                    right: i18n.dir() === 'ltr' ? 20 : undefined,
                    left: i18n.dir() === 'rtl' ? 20 : undefined,
                    top: 0,
                    bottom: 0
                  }}
                >
                  <ButtonContainer>
                    <RoundButton
                      onClick={() => {
                        joinGroupIfNeeded(group)
                      }}
                      sx={{
                        pointerEvents: alreadyInPublicGroup ? 'none' : 'auto',
                        opacity: alreadyInPublicGroup ? 0 : 1,
                        height: '24px',
                        background: 'white',
                        border: '1px solid #e4e4e4',
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        textAlign: 'center',
                        minWidth: 'unset',
                        '&:hover': {
                          backgroundColor: lighten('#e4e4e4', 0.5)
                        }
                      }}
                    >
                      {t('join')}
                    </RoundButton>
                    {alreadyInPublicGroup && (
                      <CheckmarkIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#51B87B'
                        }}
                      />
                    )}
                  </ButtonContainer>
                </Grid>
              )}
            </Grid>
          )
        })}
      </>

      <div style={{ height: '8px', width: '100%' }}>
        <br />
      </div>
      <Backdrop
        sx={{ color: APP_COLOR, zIndex: 99999, borderRadius: '20px' }}
        open={inProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {contextMenuPosition && (
        <GroupItemContextMenu
          account={props.account}
          userSettings={props.userSettings}
          x={contextMenuPosition?.x ?? 0}
          y={contextMenuPosition?.y ?? 0}
          group={contextMenuPosition.group}
          onClose={handleCloseContextMenu}
          contextMenuPosition={contextMenuPosition}
        />
      )}
    </Scrollable>
  )
}

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block'
}))

export function NoResults() {
  return (
    <MiscText
      sx={{
        width: '100%',
        marginTop: '32px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 400
      }}
      theme={theme}
    >
      {t('noResults')}
    </MiscText>
  )
}
