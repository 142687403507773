import { Model } from '@nozbe/watermelondb'
import { ChangeDoc } from 'types/api/changes'

export function sanitizeJson(json: string) {
  return json
}

export function modelsToIdsObject(models: ChangeDoc[]): Record<string, Model> {
  return models.reduce(
    (acc, cur) => ({
      ...acc,
      [cur._id ?? '']: cur
    }),
    {}
  )
}
