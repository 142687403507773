import { Model, Query } from '@nozbe/watermelondb'
import { json, relation, text, writer } from '@nozbe/watermelondb/decorators'
import { EventDoc, GroupDoc } from 'types/api/changes'
import { Model as AppModel } from 'types/model'
import { sanitizeJson } from 'utils/watermalon'

export default class Event extends Model {
  static table = 'Events'

  static associations = {
    Groups: { type: 'belongs_to', key: 'GroupID' }
  } as const

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('_message') _message!: string
  @text('GroupID') GroupID!: string
  @text('AllDay') AllDay!: string
  @text('BirthdayDetails') BirthdayDetails!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('EndDate') EndDate!: string
  @text('Job') Job!: string
  @text('LastUpdate') LastUpdate!: string
  @text('ObjectType') ObjectType!: string
  @text('OpenDate') OpenDate!: string
  @text('OwnerID') OwnerID!: string
  @text('ParentTaskID') ParentTaskID!: string
  @text('PostStatus') PostStatus!: string
  @text('Priority') Priority!: string
  @text('PrivacyStatus') PrivacyStatus!: string
  @text('Rank') Rank!: string
  @text('Color') Color!: string
  @text('RequestConfirmation') RequestConfirmation!: string
  @text('Shared') Shared!: string
  @text('StartDate') StartDate!: string
  @text('Status') Status!: string
  @text('TaskType') TaskType!: string
  @text('Text') Text!: string
  @text('ThirdPartyID') ThirdPartyID!: string
  @text('TimeZoneNameID') TimeZoneNameID!: string
  @text('Type') Type!: string
  @text('someday') someday!: string
  @text('UserID') UserID!: string
  @text('isDeleted') isDeleted!: string
  @text('canAddAttendees') canAddAttendees!: string
  @text('local_id') local_id!: string
  @text('AggregatedParticipantsConfirmationStatus')
  AggregatedParticipantsConfirmationStatus!: string
  @text('AggregatedParticipantsDeliveryStatus')
  AggregatedParticipantsDeliveryStatus!: string
  @json('Notes', sanitizeJson) Notes!: string
  @json('SupplementaryGroupsIDs', sanitizeJson) SupplementaryGroupsIDs!: string
  @json('ParticipantsStatus', sanitizeJson) ParticipantsStatus!: string
  @json('LocationReminders', sanitizeJson) LocationReminders!: string
  @json('Reminder', sanitizeJson) Reminder!: string
  @json('Location', sanitizeJson) Location!: string
  @json('Recurrence', sanitizeJson) Recurrence!: string
  @json('ConferenceData', sanitizeJson) ConferenceData!: string
  @json('ContactDetails', sanitizeJson) ContactDetails!: string
  @json('attendees', sanitizeJson) attendees!: string
  @json('Label', sanitizeJson) Label!: string
  @relation('Groups', 'GroupID') Group!: Query<AppModel<GroupDoc>>
  @text('CustomUrl') CustomUrl!: string
  @json('Attachments', sanitizeJson) Attachments!: string
  @json('Organizer', sanitizeJson) Organizer!: string

  @writer async updateTitle(title: string) {
    await this.update((event) => {
      event.Text = title
    })
  }

  @writer async addNew(eventDoc: EventDoc) {
    this.collection.prepareCreate((event) => {
      Object.keys(eventDoc).forEach((key) => {
        ;(event as any)[key] = eventDoc[key]
      })
    })
  }
}
