import { Model } from '@nozbe/watermelondb'
import { json, text } from '@nozbe/watermelondb/decorators'
import { sanitizeJson } from 'utils/watermalon'

export default class MasterLabel extends Model {
  static table = 'MasterLabels'

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('LastUpdate') LastUpdate!: string
  @text('Type') Type!: string
  @text('UserID') UserID!: string
  @json('Labels', sanitizeJson) Labels!: string
}
