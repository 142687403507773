import { Q } from '@nozbe/watermelondb'
import Database from 'services/db/Database'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import QA from 'services/QA'
import {
  ChangeDoc,
  ChangeDocsByTypes,
  DocType,
  EventDoc,
  GroupDoc,
  ParticipantDataDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import {
  docTypeToCollectionType,
  GROUP_MOST_RECENT_EVENT
} from 'utils/api/changes'
import { updateEventDocMessage } from 'utils/api/events'
import { updateGroupDocMessage } from 'utils/api/groups'
import { modelsToIdsObject } from 'utils/watermalon'
import { ParticipantsAll } from './Queries/GroupQueries'
import { generateDefaultReminder } from './utils/Event'
import { clearUnread, handleAppBadge } from 'services/api/userSettings'
import { isGroupcalApp } from 'utils/appType'

const ignoreFieldsFromServer = isGroupcalApp() ? ['Reminder'] : []

class ChangesService {
  public async updateDocsMessage(
    docsByTypes: ChangeDocsByTypes,
    ignoreEventsForMessage: boolean
  ): Promise<ChangeDocsByTypes> {
    const newDocs: ChangeDocsByTypes = {} as ChangeDocsByTypes

    const participants = await ParticipantsAll().fetch()

    async function updateDocsMessageByType<T>(
      docType: DocType,
      docs: Record<string, ChangeDoc>,
      transformer: (
        userID: string,
        newDoc: T,
        prevDoc: T,
        participants: Model<ParticipantDataDoc>[]
      ) => T
    ): Promise<Record<string, ChangeDoc>> {
      const docsIds = Object.keys(docs)

      const prevDocs = modelsToIdsObject(
        await Database.getCollection(docTypeToCollectionType(docType))
          .query(Q.where('_id', Q.oneOf(docsIds)))
          .fetch()
      )

      const docsMap: Record<string, any> = {}
      docsIds.forEach((docId) => {
        docsMap[docId] = transformer(
          LocalStorage.get(LocalStorageKey.USER_ID) as string,
          docs[docId] as unknown as T,
          prevDocs[docId] as unknown as T,
          participants
        )
      })
      return docsMap
    }

    await Promise.all(
      (Object.keys(docsByTypes) as DocType[]).map(async (key) => {
        if (key === DocType.GROUP) {
          newDocs[key] = await updateDocsMessageByType<GroupDoc>(
            DocType.GROUP,
            docsByTypes[key],
            updateGroupDocMessage
          )
        } else if (key === DocType.EVENT && ignoreEventsForMessage === false) {
          const record = await updateDocsMessageByType<EventDoc>(
            DocType.EVENT,
            docsByTypes[key],
            updateEventDocMessage
          )
          newDocs[key] = record
        } else {
          newDocs[key] = docsByTypes[key]
        }
      })
    )

    return newDocs
  }

  public saveChanges(docs: ChangeDocsByTypes): Promise<void> {
    return Database.write(async () => {
      const updateCollection = async (
        docType: DocType,
        collectionKey: CollectionType
      ) => {
        const docsFromServer = docs[docType]
        const allIdsFromServer = Object.keys(docsFromServer)
        const existingDocs = await Database.getCollection(collectionKey)
          .query(Q.where('_id', Q.oneOf(allIdsFromServer)))
          .fetch()

        const docsToUpdateIds = existingDocs.map((doc) => doc._id)
        const docsToSaveIds = allIdsFromServer.filter(
          (id) => !docsToUpdateIds.includes(id)
        )
        const collection = Database.getCollection(collectionKey)
        const batchToUpdate = [
          ...existingDocs.map((existingDoc) =>
            existingDoc.prepareUpdate((doc) => {
              Object.keys(docsFromServer[doc._id]).forEach((key) => {
                if (ignoreFieldsFromServer.includes(key)) {
                  ;(doc as any)[key] = (existingDoc as any)[key]
                } else {
                  if (['_sortOrder', 'FirstDayOfTheWeek'].includes(key)) {
                    ;(doc as any)._raw[key] = docsFromServer[doc._id][key]
                  } else if (key !== 'id') {
                    ;(doc as any)[key] = docsFromServer[doc._id][key]
                  }
                }
              })
            })
          ),
          ...docsToSaveIds.map((id) =>
            collection.prepareCreate((doc) => {
              for (
                let index = 0;
                index < Object.keys(docsFromServer[id]).length;
                index++
              ) {
                const key = Object.keys(docsFromServer[id])[index]

                if (ignoreFieldsFromServer.includes(key)) continue

                if (['_sortOrder'].includes(key)) {
                  ;(doc as any)._raw[key] = docsFromServer[id][key]
                } else if (key !== 'id') {
                  ;(doc as any)[key] = docsFromServer[id][key]
                }
              }

              //for all incoming events we set default reminder
              ;(doc as any)['Reminder'] = [generateDefaultReminder()]
            })
          )
        ]
        await Database.batch(batchToUpdate)

        if (QA.isDebugLogger()) {
          const updatedCollection = await Database.getCollection(collectionKey)
            .query()
            .fetch()

          Logger.debug('Updated collection', collectionKey, updatedCollection)
        }
      }

      await Promise.all(
        (Object.keys(docs) as DocType[]).map((key) =>
          updateCollection(key, docTypeToCollectionType(key))
        )
      )

      // Update group _sortOrder with most recent event.
      let groupsToUpdate = await Database.getCollection(CollectionType.GROUP)
        .query(Q.where('_id', Q.oneOf(Object.keys(GROUP_MOST_RECENT_EVENT))))
        .fetch()

      if (docs && docs.Group && Object.keys(docs.Group)) {
        // groupsToUpdate.push(
        //   ...(await Database.getCollection(CollectionType.GROUP)
        //     .query(Q.where('_id', Q.oneOf(Object.keys(docs.Group))))
        //     .fetch())
        // )
      }

      groupsToUpdate.forEach((_doc) =>
        _doc.update((doc) => {
          const currentSortOrderOfGroup = Number((_doc as any)['_sortOrder'])
          const recentEventLastUpdate = GROUP_MOST_RECENT_EVENT[_doc._id]
          if (recentEventLastUpdate > currentSortOrderOfGroup) {
            ;(doc as any)._raw['_sortOrder'] = recentEventLastUpdate
          }
        })
      )
    })
  }
}

export default new ChangesService()

export function populateUnreadIfNeeded(
  docs: ChangeDocsByTypes,
  userSettings: Model<UserSettingsDoc>
) {
  if (!userSettings) return

  const groupsSettings = userSettings.GroupsSettings ?? {}
  let shouldUpdate = false

  if (docs && docs.GroupEvent) {
    let hasCurrentGroupEvents = (
      Object.values(docs.GroupEvent) as EventDoc[]
    ).some((event) => {
      return event.GroupID === LocalStorage.get(LocalStorageKey.SELECTED_GROUP)
    })

    const eventsForUnread = (
      Object.values(docs.GroupEvent) as EventDoc[]
    ).filter((event) => {
      return (
        event.GroupID != LocalStorage.get(LocalStorageKey.SELECTED_GROUP) &&
        Number(event.LastUpdate) > Number(userSettings.LastUpdate) &&
        event.UserID != LocalStorage.get(LocalStorageKey.USER_ID)
      )
    })

    if (eventsForUnread) {
      eventsForUnread.forEach((event) => {
        const groupId = event.GroupID ?? ''
        const eventId = event._id ?? ''
        const groupSettingsForEvent = groupsSettings?.[groupId] ?? {}

        if (!groupSettingsForEvent.UnreadItems) {
          groupSettingsForEvent.UnreadItems = {}
        }

        if (!groupSettingsForEvent.UnreadItems[eventId]) {
          shouldUpdate = true
        }

        groupSettingsForEvent.UnreadItems[eventId] = '1'
        groupsSettings[groupId] = {
          ...groupsSettings[groupId],
          ...groupSettingsForEvent
        }
      })
    }

    if (shouldUpdate) {
      Database.write(async () => {
        userSettings.update(() => {
          userSettings.GroupsSettings = { ...groupsSettings }

          handleAppBadge()
        })
      })
    }

    if (hasCurrentGroupEvents) {
      setTimeout(() => {
        const currentGroup = LocalStorage.get(LocalStorageKey.SELECTED_GROUP)
        if (currentGroup) clearUnread(currentGroup)
      }, 2000)
    }
  }
}
