import { styled } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'

type MyInputProps = TextFieldProps & {
  max?: number
}

const StyledTextField = styled(TextField)({
  // Add your styles here
})

const SizeTextFieldInput = (props: MyInputProps) => {
  const { max, ...rest } = props

  return (
    <StyledTextField
      {...rest}
      inputProps={{ max }}
      style={{ fontSize: '14px' }}
      type="number"
    />
  )
}

export default SizeTextFieldInput
