import { PhotoIcon } from 'assets/icons'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { useEffect, useState } from 'react'
import { useFilePicker } from 'use-file-picker'
import { base64RemoveMetadata, compressBase64 } from 'utils/file'

import * as GroupCardStyled from '../../components/cards/styled'

interface CircleImagePickerProps {
  picName?: string
  currentPicture: string
  onPicSelected: (pic: string) => void
  fallback?: string
}

export function CircleImagePicker(props: CircleImagePickerProps) {
  const [error, setError] = useState<string>('')
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false
  })

  useEffect(() => {
    if (filesContent.length > 0) {
      compressBase64(filesContent[0].content)
        .then((data) => {
          // returns an array of compressed images
          props.onPicSelected(data)
        })
        .catch((e) =>
          setError('GroupCal allows to use photos up to 8MB in size.')
        )
    }
  }, [filesContent])

  return (
    <span onClick={openFileSelector}>
      <div className="GroupInfoPopup__pic-edit">
        <div className="GroupInfoPopup__pic-edit-icon">
          <PhotoIcon />
        </div>
      </div>
      <GroupCardStyled.GroupCardAvatar
        asGroup
        emptyPic={props.fallback}
        img={props.currentPicture}
        name={props.picName ?? ''}
      />
      {error && (
        <ConfirmationDialog
          rightButtonHidden
          title={'Oops. Error.'}
          content={error}
          handleLeftButton={function () {
            setError('')
          }}
          open={error.length > 0}
        />
      )}
    </span>
  )
}
