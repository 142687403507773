import { Autocomplete, Box, TextField } from '@mui/material'
import {
  Approve,
  CaretDownIcon,
  CloseIcon,
  PlusIcon,
  TickIcon
} from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import InputWithApproveButton from 'components/inputs/InputWithApproveButton'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { IEventDetails } from 'hooks/useEventDetails'
import { t } from 'i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import { theme } from 'theme'
import { Attendee, EventDoc } from 'types/api/changes'
import { MEMBERS_THRESHOLD } from './MembersList'
import * as Styled from './styled'
import Database from 'services/db/Database'
import ParticipantSuggestion from 'model/models/ParticipantSuggestion'
import { CollectionType } from 'types/model'
import { groupIdToProvider } from 'utils/groups'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'

export interface ParticipantsListProps {
  eventDoc: EventDoc
  participants?: Attendee[]
  eventHook?: IEventDetails
}

export default function ParticipantsList(props: ParticipantsListProps) {
  const [showExpander, setShowExpander] = useState(true)

  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    Database.getCollection<ParticipantSuggestion>(
      CollectionType.PARTICIPANT_SUGGESTION
    )
      .query()
      .fetch()
      .then((res) => {
        // filter out participant suggestions that are already in props.participants by email
        const filtered = res.filter((item) => {
          return !props.participants?.find(
            (participant) => participant.email === item.email
          )
        })

        setOptions(filtered.map((item) => item.email))
      })
  }, [props.participants])

  if (!props.participants) return <></>
  const participantsAmount = props.participants.length
  let pendingSlice = undefined

  if (participantsAmount < MEMBERS_THRESHOLD && showExpander) {
    pendingSlice = MEMBERS_THRESHOLD - participantsAmount
  }

  const showAllMembers = () => {
    setShowExpander(false)
  }

  const owner = props.participants.find(
    (participant) => participant.email === props.eventDoc.OwnerID
  )

  // put owner on top
  if (owner) {
    props.participants.splice(props.participants.indexOf(owner), 1)
    props.participants.unshift(owner)
  }

  const participantsToDraw = props.participants.slice(
    0,
    showExpander
      ? participantsAmount > MEMBERS_THRESHOLD // if we have participants more than threshold, we show threshold
        ? MEMBERS_THRESHOLD
        : undefined // otherwise we show all participants
      : undefined // in case no expander we also show all participants
  )

  const provider = groupIdToProvider(props.eventDoc.GroupID ?? '')

  if (provider === ProviderType.OUTLOOK) {
    if (participantsToDraw.length > 0) {
      participantsToDraw.unshift({
        email: props.eventDoc.OwnerID,
        responseStatus: 'accepted'
      })
    }
  }

  function validateEmail(text: string) {
    return isValidEmail(text)
  }

  function onApproveText(text: string) {
    console.log('adding ', text)
    props.eventHook?.addAttendee({
      email: text,
      responseStatus: 'needsAction'
    })
  }

  function removeAttendee(toRemove: Attendee) {
    if (props.participants)
      props.eventHook?.setAttendees(
        props.participants.filter(
          (attendee) => attendee.email != toRemove.email
        )
      )
  }

  return (
    <Box>
      <Styled.MembersList>
        {participantsToDraw.map((participant) => {
          let statusText = t('pending')
          let statusColor = '#808080'

          switch (participant.responseStatus) {
            case 'ACCEPTED':
            case 'accepted':
              statusText = t('accepeted')
              statusColor = '#008000'
              break
            case 'TENTATIVE':
            case 'tentative':
              statusText = t('maybe')
              statusColor = '#D4C91D'
              break
            case 'DECLINED':
            case 'declined':
              statusText = t('declined')
              statusColor = '#FF0000'
              break
            case 'NEEDS-ACTION':
            case 'needsAction':
              statusColor = '#808080'
              statusText = t('pending')
              break
          }

          return (
            <div key={participant.id}>
              <Styled.MemberListItem key={participant.id}>
                <PopupSectionRow
                  onlyTitle
                  cursor="default"
                  title={
                    <Box display={'flex'} flexDirection={'column'}>
                      {participant.displayName ?? participant.email ?? ''}
                      <Box display={'flex'} flexDirection={'row'}>
                        <span
                          style={{
                            fontSize: 12,
                            marginRight: 4,
                            color: statusColor
                          }}
                        >
                          {`${statusText}`}
                        </span>
                        {participant.organizer && (
                          <span
                            style={{
                              fontSize: 12,
                              color: theme.palette.text.disabled
                            }}
                          >
                            {` | ${t('owner')}`}
                          </span>
                        )}
                      </Box>
                    </Box>
                  }
                  value={
                    <IconButton
                      size="xs"
                      onClick={() => removeAttendee(participant)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                />
              </Styled.MemberListItem>
            </div>
          )
        })}
        {participantsAmount > MEMBERS_THRESHOLD && showExpander ? (
          <div onClick={showAllMembers} style={{ cursor: 'pointer' }}>
            <Styled.MemberListItem style={{ marginBottom: 16 }}>
              <Styled.MembersListNameContainer columnGap={1} marginLeft={12}>
                <span style={{ marginLeft: 4 }}>
                  {t('membersAmount', {
                    members: participantsAmount - MEMBERS_THRESHOLD
                  })}
                </span>
                <CaretDownIcon />
              </Styled.MembersListNameContainer>
            </Styled.MemberListItem>
          </div>
        ) : (
          <></>
        )}
      </Styled.MembersList>
      {props.eventHook && props.eventDoc.canAddAttendees != '0' && (
        <InputWithApproveButton
          options={options}
          leftIcon={<PlusIcon color={theme.palette.input.placeholder} />}
          hintText={`${t('addAParticipant')}`}
          onApprove={onApproveText}
          approveIcon={<Approve />}
          validate={validateEmail}
        />
      )}
    </Box>
  )
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
