import { useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import { LocalStorageKey } from 'types/services/localStorage'
import LocalStorage from 'services/LocalStorage'
import { t } from 'i18next'
import { APP_COLOR } from 'utils/colors'
import Logger from 'services/Logger'
import { updatePushTokenIfNeeded } from 'services/db/Queries/AccountQueries'
import { isGroupcalApp } from 'utils/appType'
import { isCypress } from 'utils/cypress'

interface NotificationPermissionDialogProps {
  openGeneral?: boolean
  setOpenGeneral?: (b: boolean) => void
  openDesc?: boolean
  onGranted?: () => void
  onCanceled?: () => void
}

export default function NotificationPermissionDialog(
  props: NotificationPermissionDialogProps
) {
  const [openExplanationDialog, onOpenExplanationDialog] = useState(
    props.openDesc ?? false
  )

  const handleCloseNotifDialog = () => {
    LocalStorage.set(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN, '1')
    if (props.setOpenGeneral) props.setOpenGeneral(false)
    onOpenExplanationDialog(false)
  }

  const handleLater = () => {
    LocalStorage.set(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN, '1')

    Logger.debug('dialog state', props.setOpenGeneral)
    if (props.setOpenGeneral) props.setOpenGeneral(false)
    onOpenExplanationDialog(false)
  }

  const handleTurnOn = () => {
    LocalStorage.set(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN, '1')
    if (typeof Notification === 'undefined') return
    if (Notification.permission === 'default') {
      Promise.resolve(Notification.requestPermission()).then(function (
        permission
      ) {
        Logger.orange('permissions: ', permission)
        if (permission === 'granted') {
          updatePushTokenIfNeeded()
          if (props.onGranted) {
            props.onGranted()
          }
        } else {
          if (props.onCanceled) {
            props.onCanceled()
          }
        }
      })
    } else {
      onOpenExplanationDialog(true)
    }
  }

  const close = () => {
    onOpenExplanationDialog(false)
    if (props.setOpenGeneral) props.setOpenGeneral(false)
  }

  if (isCypress()) return <></>

  if (openExplanationDialog) {
    return (
      <ConfirmationDialog
        title={`${t('notificationsNotEnabled', {
          app: isGroupcalApp() ? t('appName') : t('appName24me')
        })}`}
        content={provideDescBasedOnBrowser()}
        rightButton={`${t('ok')}`}
        rightButtonColor={'black'}
        leftButtonHidden
        handleRightButton={close}
        handleClose={close}
        open={openExplanationDialog}
      />
    )
  }

  return (
    <>
      {props.openGeneral && (
        <ConfirmationDialog
          title={`${t('notifsForGroupcal', {
            app: isGroupcalApp() ? t('appName') : t('appName24me')
          })}`}
          content={`${t('notifsForGroupcalDesc', {
            app: isGroupcalApp() ? t('appName') : t('appName24me')
          })}`}
          rightButtonColor={APP_COLOR}
          rightButton={`${t('turnOn')}`}
          leftButton={`${t('maybeLater')}`}
          leftButtonColor="black"
          handleLeftButton={handleLater}
          handleRightButton={handleTurnOn}
          handleClose={handleCloseNotifDialog}
          doNotShowButtonEnabled
          doNotShowButtonKey={LocalStorageKey.DONT_SHOW_NOTIF}
          doNotShowButtonValue={'1'}
          open={props.openGeneral}
        />
      )}
    </>
  )
}

function provideDescBasedOnBrowser() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const isChrome = /chrome/i.test(navigator.userAgent)

  if (isSafari) {
    return t('notificationsNotEnabledDescSafari', {
      app: isGroupcalApp() ? t('appName') : t('appName24me')
    })
  } else if (isChrome) {
    return t('notificationsNotEnabledDescChrome')
  } else {
    return t('notificationsNotEnabledDesc', {
      app: isGroupcalApp() ? t('appName') : t('appName24me')
    })
  }
}
