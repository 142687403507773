import {
  Button as MuiButton,
  darken,
  IconButton as MuiIconButton
} from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import { CSSProperties } from 'react'

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: 52,
  height: 52,
  color: theme.palette.text.primary,
  '&[data-variant="border"]': {
    border: '1px solid',
    borderColor: theme.palette.divider,
    boxShadow: '1px 1px 6px 0px #3232470D'
  },
  '&[data-size="sm"]': {
    width: 32,
    height: 32
  },
  '&[data-size="xs"]': {
    width: 20,
    height: 20,
    '& svg': {
      width: 16,
      height: 16,
      minWidth: 16,
      minHeight: 16
    }
  },
  '&[data-size="md"]': {
    width: 40,
    height: 40
  }
}))

export const Button = styled(MuiButton)(({ theme }) => ({
  height: 52,
  width: '100%',
  padding: theme.spacing(0, 5),
  color: theme.palette.button.text.primary,
  backgroundColor: theme.palette.button.background.primary,
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: 68,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  fontFamily: 'NotoSans',
  textTransform: 'capitalize',
  fontSize: '1.25rem',
  fontWeight: 500,
  boxShadow:
    '0px 1px 8px rgba(50, 50, 71, 0.08), 0px 2px 16px rgba(50, 50, 71, 0.06)',

  '&:hover': {
    backgroundColor: darken(
      theme.palette.button.background.primary as string,
      0.02
    )
  },

  '&[data-size="md"]': {
    height: 48,
    fontSize: '16px',
    fontWeight: 400,
    boxShadow:
      '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 2px 4px rgba(50, 50, 71, 0.06)'
  },

  '&[data-size="xs"]': {
    height: 26,
    fontSize: '0.875rem',
    fontWeight: 400,
    boxShadow:
      '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 2px 4px rgba(50, 50, 71, 0.06)'
  },

  '&[data-size="icon"]': {
    minHeight: 0,
    minWidth: 0,
    height: 26,
    padding: theme.spacing(0, 2.5),
    boxShadow:
      '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 2px 4px rgba(50, 50, 71, 0.06)'
  }
}))

interface StateButtonProps {
  statecolor: string | undefined
  bgcolor: string | undefined
}

export const StateButton = styled(MuiButton)<StateButtonProps>(
  ({ theme, statecolor: stateColor, bgcolor: bgColor }) => {
    return {
      height: 'auto',
      width: 'auto',
      padding: 5,
      color: stateColor,
      backgroundColor: bgColor,
      border: '1px solid',
      borderColor: 'black',
      borderRadius: 68,
      fontFamily: 'NotoSans',
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 700,

      '&:hover': {
        backgroundColor: darken(
          theme.palette.button.background.primary as string,
          0.1
        )
      }
    }
  }
)
