import { ReactElement, ReactNode } from 'react'

export interface CircleContainerProps {
  children?: ReactNode
  color?: string
  size?: number
}

export default function CircleContainer(props: CircleContainerProps) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        width: 'auto',
        minWidth: '39px',
        aspectRatio: '1/1',
        borderRadius: '50%',
        fontSize: '26px',
        lineHeight: 1.5,
        backgroundColor: props.color
      }}
    >
      {props.children}
    </div>
  )
}
