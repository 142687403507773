import { styled } from '@mui/material/styles'
import Popup from 'components/popup/Popup'

export const EventDetailsPopup = styled(Popup)(({ theme }) => ({
  width: 405,

  '& .tippy-content': {
    padding: theme.spacing(0, 0, 0, 0),
    marginTop: '16px',
    height: '650px'
  }
}))

export const CustomEventRecurrencePopup = styled(Popup)(() => ({
  width: 225
}))

export const NoteDetailsPopup = styled(Popup)(({ theme }) => ({
  width: 600
}))
