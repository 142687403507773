import i18n from 'i18n'
import ContentLoader from 'react-content-loader'
import { IContentLoaderProps } from 'react-content-loader'

const GroupLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={70}
      width={'100%'}
      viewBox="-10 0 320 70"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ transform: i18n.dir() === 'rtl' ? 'scaleX(-1)' : undefined }}
      {...props}
    >
      <circle cx="27" cy="27" r="25" />
      <rect x="60" y="0" rx="3" ry="3" width="150" height="13" />
      <rect x="255" y="0" width="40" height="13" />

      <rect x="60" y="25" rx="3" ry="3" width="74" height="10" />

      <circle cx="285" cy="38" r="8" />
      <rect x="60" y="53" rx="0" ry="0" width="235" height="1" />
    </ContentLoader>
  )
}
export default GroupLoader
