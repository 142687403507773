import React, { ComponentType, ReactNode, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Radio, Box, lighten, ClickAwayListener } from '@mui/material'
import { theme } from 'theme'
import './styles.scss'
import {
  AccountDoc,
  DocType,
  EventDoc,
  EventStatus,
  MasterLabelDoc,
  TaskType,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import dayjs from 'dayjs'
import dateToTimeString from 'components/calendar/components'
import { t } from 'i18next'
import { ArchiveIcon, TaskEmail, TaskGift } from 'assets/icons'
import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import {
  convertLabelColorToStringColor,
  defaultTaskColor,
  isLabelInsideMasterLabel,
  provideTaskColor
} from 'utils/masterLabel'
import { updateEventOnServer } from 'services/api/event'
import IconButton from 'components/buttons/IconButton'
import { useAppUIType } from 'components/providers/AppUITypeProvider'
import EventPopup from 'components/popups/eventInfo/EventPopup'
import { excludeInstance, localEventById } from 'services/db/Queries/Event'
import SomedayItem from './SomedayItem'
import IconsForEvent from './IconsForEvent'
import { Tier, highestActiveTier } from 'utils/api/accounts'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { ArrowRightGrey } from 'assets/img'
import i18n from 'i18n'
import { CALENDAR_ITEM_CORNER_RARIUS } from 'utils/constants'
import { LocationLabel } from 'components/calendar/styled'
import { isInstanceCompleted } from 'components/calendar/components/Event'
import { L } from '@fullcalendar/list/internal-common'

interface ItemProps {
  item: EventDoc
  withToggle?: boolean
  masterLabel?: Model<MasterLabelDoc>
  account?: Model<AccountDoc>
  userSettings?: Model<UserSettingsDoc>
  setShowEvent: (id: string) => void
  open: boolean
  droppableId: string
  setOpen?: (b: boolean) => void
  hideTime?: boolean
  instanceStart?: number
  instanceEnd?: number
  onClose?: () => void
  title?: string
}

const useStyles = makeStyles(() => ({
  itemContainer: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    maxHeight: '46px',
    width: '100%',
    minHeight: '46px',
    alignItems: 'center',
    borderRadius: CALENDAR_ITEM_CORNER_RARIUS,
    overflow: 'hidden'
  },
  typeIndicator: {
    width: 6,
    position: 'absolute',
    height: '100%'
  },
  titleContainer: {
    flex: 1,

    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden'
  },
  iconContainer: {
    marginLeft: theme.spacing(0, 2),
    display: 'flex',
    justifyContent: 'center'
  }
}))

const Item: React.FC<ItemProps> = ({
  item,
  withToggle,
  masterLabel,
  setShowEvent,
  account,
  userSettings,
  open,
  setOpen,
  droppableId,
  hideTime,
  instanceStart,
  instanceEnd,
  onClose,
  title
}) => {
  const classes = useStyles()
  const appUiTypeContext = useAppUIType()
  const completed =
    item.Status === EventStatus.COMPLETED ||
    isInstanceCompleted(item, Number(item.StartDate) * 1000)

  function updateItemWithStatus(newState: EventStatus) {
    if (item.Recurrence && typeof item.Recurrence !== 'string') {
      const dayjsTime = dayjs(Number(item.StartDate) * 1000)
        .add(item.AllDay === '1' ? 12 : 0, 'hour')
        .tz('UTC')
        .startOf('day')
        .valueOf()
      const exDate = String(dayjsTime / 1000)

      localEventById(item._id ?? '').then((event) => {
        updateEventOnServer(event, {
          Recurrence: {
            ...item.Recurrence,
            Exclusion: [
              {
                Date: `${exDate}` ?? '',
                Status: newState
              },
              ...(item.Recurrence?.Exclusion &&
              typeof item.Recurrence?.Exclusion !== 'string'
                ? item.Recurrence.Exclusion
                : [])
            ].filter(
              (item, index, self) =>
                index === self.findIndex((i) => i.Date === item.Date)
            )
          },
          SupplementaryGroupsIDs: item.SupplementaryGroupsIDs
        })
      })
    } else {
      updateEventOnServer(item, {
        Status: newState,
        SupplementaryGroupsIDs: item.SupplementaryGroupsIDs
      })
    }
  }

  const handleToggle = () => {
    const newState = completed ? EventStatus.ACTIVE : EventStatus.COMPLETED

    updateItemWithStatus(newState)
  }

  const taskItemAction = () => {
    const contact = item.ContactDetails
    const hostName = account?.Name?.FullName ?? account?.Name?.FirstName ?? ''
    const guestName = contact?.Name
    const email = contact?.data?.[0].Email

    if (item.Status === EventStatus.COMPLETED) {
      updateItemWithStatus(EventStatus.REMOVED)
    }

    if (item.TaskType === TaskType.gift) {
      appUiTypeContext.setGiftLink(
        `https://giftit.jifiti.com/gifting.html?host_name=${guestName}&guest_email=${LocalStorage.get(
          LocalStorageKey.USER_EMAIL
        )}&guest_name=${hostName}`
      )
    }

    if (item.TaskType === TaskType.email) {
      window.open(
        `mailto:${email}?body=${
          (item.Notes?.length ?? 0) > 0 && typeof item.Notes !== 'string'
            ? item.Notes?.[0].Note
            : ''
        }`
      )
    }
  }

  const handleItemClick = () => {
    if (item.someday === '1') {
      // handle late task click here
    } else {
      if (setShowEvent) setShowEvent(`${item._id}${item.StartDate}`)
      if (setOpen) setOpen(true)
    }
  }

  const labelText = masterLabel?.Labels.find(
    (mLabel) =>
      mLabel.ID ===
      (appUiTypeContext.selectedLabel
        ? appUiTypeContext.selectedLabel.ID
        : typeof item.Label !== 'string' && (item.Label?.length ?? 0) > 0
        ? item.Label?.filter((label) =>
            isLabelInsideMasterLabel(masterLabel, label.LabelText)
          )?.[0]?.LabelText
        : undefined)
  )?.Text

  let lineColor = appUiTypeContext.selectedLabel
    ? convertLabelColorToStringColor(
        masterLabel?.Labels.find(
          (mLabel) => mLabel.ID === appUiTypeContext.selectedLabel?.ID
        )?.Color ?? defaultTaskColor
      )
    : provideTaskColor(masterLabel, item, account)

  if (highestActiveTier(account) === Tier.FREE) lineColor = defaultTaskColor

  let startTime = dayjs(Number(item.StartDate) * 1000)
  let endTime = dayjs(Number(item.EndDate) * 1000)

  const taskTypeIcon = makeTaskTypeIcon(item)

  const backgroundColor =
    lineColor && lineColor != defaultTaskColor
      ? lighten(lineColor, 0.95)
      : '#fff'
  let dateText =
    startTime.isBefore(dayjs().startOf('day')) ||
    startTime.isAfter(dayjs().add(1, 'day').endOf('day'))
      ? `${dayjs(Number(item.StartDate) * 1000).format('MMM D YYYY')}`
      : ''

  if (item.AllDay === '1' && dateText === '') {
    dateText = t('allDay')
  }

  if (item.late) {
    startTime = dayjs(Number(item.lateOriginalStartTime) * 1000)
    endTime = dayjs(Number(item.lateOriginalEndTime) * 1000)
  }

  const startText = dateToTimeString(startTime.toDate(), true)
  const endText = dateToTimeString(endTime.toDate(), true)

  const icons = <IconsForEvent instanceEnd={instanceEnd} item={item} />
  let location: string | undefined = undefined

  if (item.Location && item.Location?.Address) {
    location = item.Location.Address
  }

  const [awayClickTriggered, setAwayClickTriggered] = useState(false)

  useEffect(() => {
    if (!open) {
      setAwayClickTriggered(false)
    }
  }, [open])

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (open) {
          setAwayClickTriggered(true)
        }
      }}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={classes.itemContainer}
        sx={{
          maxHeight: '40px',
          border: `1px solid ${lineColor}`,
          backgroundColor: backgroundColor
        }}
      >
        <Box
          className={classes.typeIndicator}
          style={{ backgroundColor: lineColor }}
        />

        {withToggle && item.someday !== '1' && (
          <Box className={classes.iconContainer}>
            <Radio color="primary" checked={completed} onClick={handleToggle} />
          </Box>
        )}

        <EventPopup
          awayClickTriggered={awayClickTriggered}
          setAwayClickTriggered={setAwayClickTriggered}
          account={account}
          userSettings={userSettings}
          event={item}
          open={open}
          group={undefined}
          instanceStart={
            item.late
              ? startTime.valueOf()
              : instanceStart ?? startTime.valueOf()
          }
          instanceEnd={
            item.late ? endTime.valueOf() : instanceEnd ?? endTime.valueOf()
          }
          setOpen={setOpen ?? function (b: boolean): void {}}
          onClose={() => {
            if (setShowEvent) setShowEvent('')
            if (onClose) onClose()
          }}
        >
          <div onClick={handleItemClick} style={{ width: '100%' }}>
            <Box
              className={classes.titleContainer}
              sx={{
                padding: theme.spacing(0, withToggle ? 0 : 4)
              }}
            >
              {item.someday === '1' ? (
                <></>
              ) : (
                <Box className="time" flexDirection={'row'}>
                  {icons}
                  {!hideTime ? dateText : ''}
                  {item.AllDay === '1' || hideTime
                    ? ''
                    : `${dateText ? ', ' : ''}${startText}${
                        startText !== endText ? '-' + endText : ''
                      }`}
                  {labelText && (
                    <Box
                      className="time"
                      style={{
                        padding: theme.spacing(0.5, 1),

                        marginLeft: 3,
                        border: 'solid 1px #767676',
                        borderRadius: '0.25rem',
                        lineHeight: '13px',
                        fontSize: '11px'
                      }}
                    >
                      {labelText.toUpperCase()}
                    </Box>
                  )}
                </Box>
              )}
              <div
                className="subTitle"
                style={{
                  color:
                    item.Priority === '2' ? 'red' : theme.palette.text.primary,
                  textDecoration: completed ? 'line-through' : 'none',
                  position: 'relative'
                }}
              >
                {item.someday === '1' ? (
                  <>
                    <SomedayItem title={item.Text} />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: i18n.dir() === 'ltr' ? 0 : undefined,
                        left: i18n.dir() === 'ltr' ? undefined : 0,
                        backgroundColor: 'white'
                      }}
                    >
                      <ArrowRightGrey
                        height={16}
                        width={16}
                        style={{
                          marginLeft: 12,
                          marginRight: 12,
                          transform:
                            i18n.dir() === 'ltr' ? '' : 'rotate(180deg)'
                        }}
                      />
                    </div>
                  </>
                ) : item.Text?.length === 0 && item._id?.length === 0 ? (
                  item.Type === DocType.TASK ? (
                    t('newTask')
                  ) : (
                    t('newNote')
                  )
                ) : (
                  <Box display={'flex'} flexDirection={'row'} gap={1}>
                    {`${title ?? item.Text}${location ? ',' : ''}`}
                    <LocationLabel>
                      {location ? ` ${location}` : ''}
                    </LocationLabel>
                  </Box>
                )}
              </div>
            </Box>
          </div>
        </EventPopup>

        {taskTypeIcon && (
          <div
            style={{
              position: 'absolute',
              right: i18n.dir() === 'ltr' ? 0 : undefined,
              left: i18n.dir() === 'ltr' ? undefined : 0,
              marginRight: i18n.dir() === 'ltr' ? 0 : undefined,
              marginLeft: i18n.dir() === 'ltr' ? undefined : 0,
              background: backgroundColor,
              height: '100%',
              justifyItems: 'center',
              display: 'flex'
            }}
          >
            <IconButton onClick={taskItemAction} size="md">
              {taskTypeIcon}
            </IconButton>
          </div>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default compose(
  withObservables([], () => ({
    masterLabel: ObserveService.MasterLabel(),
    account: ObserveService.Account()
  }))
)(Item as ComponentType<unknown>) as any

function makeTaskTypeIcon(
  item: Model<EventDoc> | EventDoc
): ReactNode | undefined {
  const taskType = item.TaskType
  const iconHeight = 30

  if (item.Status === EventStatus.COMPLETED)
    return <ArchiveIcon height={iconHeight} />

  if (!taskType) return undefined

  if (taskType) {
    switch (taskType) {
      case TaskType.call:
        return undefined
      case TaskType.text:
        return undefined
      case TaskType.email:
        return <TaskEmail height={iconHeight} />
      case TaskType.gift:
        return <TaskGift height={iconHeight} />
      case TaskType.meet:
        return undefined
      case TaskType.hotel:
        return undefined
      case TaskType.googleTask:
        return undefined
    }
  }

  return undefined
}
