import {
  DayCellContentArg,
  EventContentArg,
  SlotLabelContentArg
} from '@fullcalendar/core'
import { Box, IconButton, Typography } from '@mui/material'
import {
  CALENDAR_VIEW,
  useCalendar
} from 'components/providers/CalendarProvider'
import { isToday } from 'date-fns/esm'
import dayjs from 'dayjs'
import i18n from 'i18n'
import LocalStorage from 'services/LocalStorage'
import { theme } from 'theme'
import { CalendarRange } from 'types/components/calendar'
import { LocalStorageKey } from 'types/services/localStorage'
import { APP_COLOR } from 'utils/colors'

import Event from './Event'
import * as Styled from './styled'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import CircleContainer from 'components/containers/CircleContainer'
import { isGroupcalApp } from 'utils/appType'
import { AddButton } from 'assets/icons'
import {
  StartEndEvent,
  generateStartEndBasedOnTimeWithShift,
  isAMPM
} from 'utils/date'
import { EventDoc, GroupDoc, UserSettingsDoc } from 'types/api/changes'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { isUserHaveGroupToAddEvents } from 'utils/api/groups'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { getDefaultTimezone } from 'index'
import moment from 'moment'
import { format } from 'path'

const weekHeaderFontSize = 14

export interface DayHeaderContentProps {
  dayNameFormat?: string
  userSettings?: UserSettingsDoc
  groupId?: string
  groups?: GroupDoc[]
  handleAddEvent?: (startEnd: StartEndEvent) => void
  date: Date
}

export function DayHeaderContent(props: DayHeaderContentProps) {
  const textColor = theme.palette.text.primary
  const calendar = useCalendar()
  const dayNameTextColor = theme.palette.text.disabled
  const dateForHeader = dayjs(props.date).add(12, 'hour').toDate()
  const text = dayjs(dateForHeader).format('D')
  const today = isToday(dateForHeader)

  const [haveActiveCalendar, setHaveActiveCalendar] = useState(false)
  const [showEventAddingNotPossible, onShowEventAddingNotPossible] =
    useState(false)

  useEffect(() => {
    isUserHaveGroupToAddEvents().then((groupId) => {
      if (groupId) {
        setHaveActiveCalendar(true)
      } else {
        setHaveActiveCalendar(false)
      }
    })
  }, [props.groups])

  switch (calendar.view) {
    case CalendarRange.LISTWEEK:
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          height={'20px'}
          width={'100%'}
          gap={1}
          alignItems={'center'}
          position={'relative'}
        >
          {today && (
            <Box display={'flex'} flexDirection={'row'}>
              <Typography
                fontWeight={550}
                color={isGroupcalApp() ? 'white' : 'black'}
              >
                {t('today')}
              </Typography>
              <Typography
                className="day-name"
                color={isGroupcalApp() ? 'white' : 'black'}
              >
                {`${today ? ', ' : ''}`}
              </Typography>
            </Box>
          )}
          <Typography
            className="day-name"
            color={isGroupcalApp() && today ? 'white' : undefined}
          >
            {`${dayjs(dateForHeader).format(props.dayNameFormat ?? 'dddd')}, `}
          </Typography>
          <Typography
            className="day-number"
            color={isGroupcalApp() && today ? 'white' : undefined}
          >
            {dayjs(dateForHeader).format('MMM DD YYYY')}
          </Typography>
          <Box
            position={'absolute'}
            right={i18n.dir() === 'ltr' ? -6 : undefined}
            left={i18n.dir() === 'rtl' ? -6 : undefined}
          >
            <IconButton
              onClick={() => {
                const now = dayjs()
                const startEnd = generateStartEndBasedOnTimeWithShift(
                  dayjs(dateForHeader).set('hour', now.hour()).unix()
                )
                if (props.handleAddEvent) props.handleAddEvent(startEnd)
              }}
            >
              <AddButton width={'24px'} height={'24px'} />
            </IconButton>
          </Box>
          {showEventAddingNotPossible && (
            <ConfirmationDialog
              title={''}
              content={t('toAddEvents')}
              leftButtonHidden
              rightButton={`${t('ok')}`}
              rightButtonColor={APP_COLOR}
              handleRightButton={() => {
                onShowEventAddingNotPossible(false)
              }}
              open
              handleClose={() => {}}
            />
          )}
        </Box>
      )

    case CalendarRange.WEEK:
      return (
        <Box
          onClick={() => {
            RemoveTempEvents()
          }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircleContainer
            color={isToday(dateForHeader) ? APP_COLOR : undefined}
          >
            <div
              style={{
                width: '100%',
                fontWeight: '500',
                padding: theme.spacing(0, 1),
                marginRight: text.length > 1 ? '1px' : undefined,
                color:
                  isGroupcalApp() && isToday(dateForHeader)
                    ? 'white'
                    : textColor
              }}
            >
              {text}
            </div>
          </CircleContainer>
          <Typography fontSize={weekHeaderFontSize} color={dayNameTextColor}>
            {dayjs(dateForHeader).format(props.dayNameFormat ?? 'dddd')}
          </Typography>
        </Box>
      )
    case CalendarRange.DAY:
      return (
        <Styled.DayHeaderContentDay gap={2}>
          <CircleContainer
            color={isToday(dateForHeader) ? APP_COLOR : undefined}
          >
            <Typography
              fontSize={26}
              color={
                isGroupcalApp() && isToday(dateForHeader) ? 'white' : textColor
              }
            >
              {dayjs(dateForHeader).format('D')}
            </Typography>
          </CircleContainer>

          <Typography fontSize={26} color={textColor}>
            {dayjs(dateForHeader).format(props.dayNameFormat ?? 'dddd')}
          </Typography>
        </Styled.DayHeaderContentDay>
      )
    default: {
      const now = dayjs()
      const header = dayjs(dateForHeader).utc()
      const visible = dayjs(
        Number(LocalStorage.get(LocalStorageKey.VISIBLE_DATE))
      )

      const isCurrentMonth =
        now.month() === visible.month() && now.year() === visible.year()
      const isSameDay = now.day() === header.day()
      return (
        <Typography
          width={'100%'}
          fontSize={18}
          color={
            isCurrentMonth && isSameDay ? APP_COLOR : theme.palette.text.primary
          }
        >
          {header.format(props.dayNameFormat ?? 'dddd')}
        </Typography>
      )
    }
  }
}

export function DayCellContent(props: DayCellContentArg) {
  const bgColor = isToday(props.date) ? APP_COLOR : undefined
  switch (props.view.type) {
    case CALENDAR_VIEW[CalendarRange.WEEK]:
      return null
    case CALENDAR_VIEW[CalendarRange.DAY]:
      return null
    default:
      return (
        <div style={{ margin: theme.spacing(1, 1) }}>
          <CircleContainer color={bgColor}>
            <Styled.DayCellContent>
              <Typography
                width={'100%'}
                textAlign={isToday(props.date) ? 'center' : undefined}
                style={{
                  transform: `translateX(${
                    props.dayNumberText.length > 1 ? '-1px' : undefined
                  })`
                }}
                color={
                  isToday(props.date) && isGroupcalApp() ? 'white' : 'black'
                }
              >
                {props.dayNumberText}
              </Typography>
            </Styled.DayCellContent>
          </CircleContainer>
        </div>
      )
  }
}

interface EventContentProps extends EventContentArg {
  closeAll?: boolean // for the drag/resize callbacks when we want to hide all opened popups
}

export function EventContent(props: EventContentProps) {
  const event: EventDoc = props.event?._def?.extendedProps?.eventModel
  const instanceStart = dayjs(props.event.startStr)
    .add(props.event.allDay ? 12 : 0, 'hour')
    .valueOf()
  let instanceEnd =
    props.event?.end === null ? instanceStart : props.event?.end?.valueOf()

  if (event?.AllDay === '1') {
    if (!props.event.endStr) {
      instanceEnd = dayjs(props.event.startStr).tz('UTC').valueOf()
    } else {
      instanceEnd = dayjs(props.event.endStr).tz('UTC').valueOf() - 1
    }
  }

  return (
    <Event
      isStart={props.isStart}
      instanceStart={instanceStart}
      instanceEnd={instanceEnd}
      event={props.event?._def?.extendedProps?.eventModel}
      title={props.event._def.title}
      closeAll={props.closeAll}
      fc_id={props.event._def.publicId}
    />
  )
}

export function AllDayContent() {
  return <Styled.SlotContent>{`${i18n.t('allDay')}`}</Styled.SlotContent>
}

export function SlotLabelContent(props: SlotLabelContentArg) {
  const time = dayjs(props.date).tz(getDefaultTimezone())
  return (
    <Styled.SlotContent data-color="secondary" data-time>
      {time.hour() == 0 && time.minute() == 0
        ? ''
        : dateToTimeString(
            moment.tz(props.date, getDefaultTimezone()).toDate()
          )}
    </Styled.SlotContent>
  )
}

export default function dateToTimeString(
  dateObj: Date,
  keepMinutes?: boolean
): string {
  const date = moment(dateObj).tz(getDefaultTimezone())

  let formatString = 'h A'

  if (
    keepMinutes ||
    (!date.format('A').toLowerCase().includes('am') &&
      !date.format('A').toLowerCase().includes('pm'))
  ) {
    formatString = 'h:mm A'
  }

  if (!isAMPM()) {
    formatString = 'HH:mm'
  }

  let timeString = date.format(formatString)

  return timeString.charAt(0) === '0' && timeString.length >= 5
    ? timeString.slice(1, timeString.length)
    : timeString
}
