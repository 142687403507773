import Analytics, { Events } from 'analytics/Analytics'
import axios, { AxiosError } from 'axios'
import { CodeProvider, processLogout } from 'components/providers/AuthProvider'
import Api, { API_URL } from 'services/Api'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { AccountName } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import {
  DocPhoneNumberType,
  createSignUpObject,
  extractToken
} from 'utils/auth'
import { getOrUpdateDeviceId } from 'utils/device'

export const LOGIN_URL = '/v1/account/login'
export const SIGNUP_URL = '/v2/account'

export async function signUp(
  phone?: string,
  token?: string,
  email?: string,
  password?: string,
  name?: AccountName,
  phoneType?: DocPhoneNumberType
) {
  try {
    const response = await Api.post(SIGNUP_URL, {
      ...createSignUpObject({
        deviceToken: getOrUpdateDeviceId(),
        token: token,
        phoneNumber: phone,
        email: email,
        password: password,
        fname: name?.FirstName,
        phoneNumberType: phoneType
      })
    })

    if (response?.data['userId'])
      LocalStorage.set(LocalStorageKey.USER_ID, response?.data['userId'])

    LocalStorage.set(LocalStorageKey.NEW_USER, true)
    LocalStorage.set(LocalStorageKey.SELECTED_GROUP, '')

    if (is24meApp()) cacheUserData(email ?? '', password ?? '')
    const loginResult = await login()

    Analytics.getInstance().sendEvent(Events.CREATE_ACC_SUCCESS)

    return loginResult
  } catch (e) {
    if (e instanceof AxiosError) {
      Logger.red('error on signup ', e)

      // in case of 500 error, return the error
      if (e.response?.status === 500) return e

      if (e.response && e.response?.data['errorCode'] === -300) {
        LocalStorage.set(LocalStorageKey.USER_ID, e.response?.data['userId'])
        if (isGroupcalApp()) await cacheAndLogin(email ?? '', password ?? '')
        return e
      } else {
        Analytics.getInstance().sendEvent(Events.CREATE_ACC_FAILURE)
      }
    } else {
      Analytics.getInstance().sendEvent(Events.CREATE_ACC_FAILURE)
    }
  }

  return undefined
}

export async function cacheAndLogin(
  email: string,
  password: string,
  byPassLoginErrors?: boolean
) {
  if (is24meApp()) cacheUserData(email, password)
  return await login(true, byPassLoginErrors)
}

export function login(isWakeUp?: boolean, byPassLoginErrors?: boolean) {
  const deviceToken = getOrUpdateDeviceId()
  const phone = LocalStorage.get(LocalStorageKey.PHONE_NUMBER) ?? ''
  const firebaseToken = LocalStorage.get(LocalStorageKey.FIREBASE_TOKEN) ?? ''
  const cmToken = LocalStorage.get(LocalStorageKey.CHECKMOBI_TOKEN) ?? ''
  const email = LocalStorage.get(LocalStorageKey.USER_EMAIL)
  const password = LocalStorage.get(LocalStorageKey.USER_PASS) ?? ''
  const decodedData: Uint8Array = new Uint8Array(
    atob(password)
      .split('')
      .map((char) => char.charCodeAt(0))
  )
  const decodedPass: string = new TextDecoder('utf-8').decode(decodedData)

  return Api.post(LOGIN_URL, {
    username: is24meApp()
      ? email
      : `${phone}*${deviceToken}*${
          LocalStorage.get(LocalStorageKey.CODE_PROVIDER) ===
          CodeProvider.Checkmobi
            ? 'cm'
            : 'fr'
        }*3`,
    password: is24meApp()
      ? decodedPass
      : LocalStorage.get(LocalStorageKey.CODE_PROVIDER) ===
        CodeProvider.Checkmobi
      ? cmToken
      : firebaseToken,
    secondpassword: LocalStorage.get(LocalStorageKey.PASSWORD),
    isWakeUp: isWakeUp ?? false
  })
    .then((response) => {
      LocalStorage.set(
        LocalStorageKey.ACCESS_TOKEN,
        extractToken(response.data.authToken)
      )
      return response.data
    })
    .catch((e) => {
      if (
        e instanceof AxiosError &&
        (byPassLoginErrors === false || byPassLoginErrors === undefined)
      ) {
        if (e.response?.status === 403 || e.response?.status === 404) {
          if (LocalStorage.get(LocalStorageKey.USER_ID))
            processLogout()
              .then(() => {
                return logout()
              })
              .then(() => {
                window.location.reload()
              })
        }
      }
      return e
    })
}

export function logout() {
  return axios.get(`${API_URL}/logout`)
}

function cacheUserData(email: string, password: string) {
  LocalStorage.set(LocalStorageKey.USER_EMAIL, email)
  const data: Uint8Array = new TextEncoder().encode(password)
  const base64: string = btoa(String.fromCharCode.apply(null, Array.from(data)))
  LocalStorage.set(LocalStorageKey.USER_PASS, base64)
}
