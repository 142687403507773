export const EDIT_PARTICIPANTS_USER_NOT_AUTHORIZED = -101
export const EDIT_GROUP_METADATA_USER_NOT_AUTHORIZED = -102
export const EDIT_GROUP_SETTINGS_USER_NOT_AUTHORIZED = -103
export const EDIT_GROUP_USER_NOT_PART_OF_GROUP = -104
export const EDIT_GROUP_ADD_PARTICIPANTS_USER_NOT_ADMIN = -105
export const OUT_OF_TIER = -110
export const ADD_EVENT_NOT_PART_OF_GROUP = -111
export const ADD_EVENT_CANNOT_POST_ITEMS = -112
export const EDIT_EVENT_NOT_PART_OF_GROUP = -121
export const EDIT_EVENT_CANNOT_CHANGE_EVENT = -122
