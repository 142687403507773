import { ReactNode } from 'react'

import * as Styled from './styled'
import { Tooltip } from '@mui/material'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'

interface IconButtonProps {
  children: ReactNode
  onClick?: () => void
  variant?: 'border'
  size?: 'sm' | 'xs' | 'md'
  type?: 'submit' | 'button'
  tooltip?: string | undefined
  id?: string
}

export default function IconButton(props: IconButtonProps) {
  const { variant, children, size, ...otherProps } = props
  return (
    <GroupcalTooltip title={props.tooltip}>
      <Styled.IconButton
        {...otherProps}
        id={props.id}
        data-button="icon-button"
        data-size={size}
        data-variant={variant}
      >
        {children}
      </Styled.IconButton>
    </GroupcalTooltip>
  )
}
