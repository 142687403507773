import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { APP_COLOR } from 'utils/colors'
import { RANGE } from 'components/calendar/CalendarHeader'
import { StyledDialog } from '../styled.dialog'
import { RangeSelect } from 'components/calendar/styled'
import { Option } from 'types/components/form'
import { InviteLinkRow } from 'components/badges/InviteLinkBadge'
import { GroupDoc } from 'types/api/changes'
import { CalendarRange } from 'types/components/calendar'
import { useCalendar } from 'components/providers/CalendarProvider'
import SizeTextFieldInput from './styled'
import GuestMode from 'components/guest/GuestMode'

interface EmbedCalendarDialogProps {
  group: GroupDoc
  open: boolean
  handleClose: () => void
}

const EmbedCalendarDialog: React.FC<EmbedCalendarDialogProps> = ({
  group,
  open,
  handleClose
}) => {
  const [width, setWidth] = useState('800')
  const [height, setHeight] = useState('800')
  const [selectedValue, setSelectedValue] = useState(RANGE[2].value)
  const [showHeader, setShowHeader] = useState(false)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    let modified = Number(value)
    if (modified > 2000) modified = 2000
    if (name === 'input1') {
      setWidth(`${modified}`)
    } else if (name === 'input2') {
      setHeight(`${modified}`)
    }
  }

  const handleSelectChange = (event: Option | null) => {
    setSelectedValue(event?.value as string)
    calendar.onView(event?.value as CalendarRange)
  }

  const calendar = useCalendar()
  const groupLink = `${process.env.REACT_APP_LINK_DOMAIN}/calendar/${
    group._id
  }⁠${group.PrivateLinkPassword}?view=${selectedValue}&showHeader=${
    showHeader ? '1' : '0'
  }`
  const iFrameString = `<iframe src="${groupLink}" width="${width}" frameborder="0" height="${height}"></iframe>`

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowHeader(event.target.checked)
  }

  useEffect(() => {
    calendar.onView(CalendarRange.MONTH)
  }, [])

  return (
    <StyledDialog
      disableEscapeKeyDown
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '90%',
          maxHeight: '90%',
          width: '90%',
          maxWidth: '90%',
          borderRadius: 8
        }
      }}
    >
      <DialogContent sx={{ display: 'flex', height: '100%', paddingTop: 16 }}>
        <Box
          sx={{ width: '20%', mr: 2, paddingTop: 32 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxWidth: '20%',
            marginRight: '16px'
          }}
        >
          <SizeTextFieldInput
            sx={{ marginBottom: 2 }}
            name="input1"
            value={width}
            label={`${t('width')}`}
            onChange={handleInputChange}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <SizeTextFieldInput
              label={`${t('height')}`}
              name="input2"
              value={height}
              onChange={handleInputChange}
            />
          </FormControl>
          <div style={{ marginLeft: -6 }}>
            <Typography
              fontSize={'0.75rem'}
              color={'#848484'}
              sx={{ paddingLeft: 2 }}
            >
              {t('defaultView')}
            </Typography>
            <RangeSelect
              sx={{ direction: 'ltr' }}
              variant="no-border"
              placeholder="Range"
              className={`select-date-wrapper ${selectedValue}`}
              size="md"
              options={RANGE}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox checked={showHeader} onChange={handleCheckboxChange} />
            }
            label={t('showHeader')}
          />
        </Box>
        <div style={{ flex: 1, width: '50%', maxWidth: '800px' }}>
          <Typography>{t('toDisplayCalendar')}</Typography>
          <Box sx={{ width: '100%' }}>
            <InviteLinkRow allowScroll textSize="16px" link={iFrameString} />
          </Box>
          <Box
            sx={{
              pointerEvents: 'none'
            }}
            minWidth={'100px'}
            minHeight={'100px'}
            height={`${height}px`}
            width={`${width}px`}
            padding={4}
          >
            <GuestMode
              preview
              showHeader={showHeader ? '1' : '0'}
              defaultView={selectedValue as CalendarRange}
              groupId={group._id ?? ''}
              height={`${height ?? 800}px`}
              width={`${width ?? 800}px`}
            />
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: APP_COLOR }} onClick={handleClose}>{`${t(
          'cancel'
        )}`}</Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default EmbedCalendarDialog
