import { t } from 'i18next'
import { DocType } from 'types/api/changes'

export function docTypeToString(docType?: DocType) {
  if (docType === DocType.EVENT) {
    return t('event_one')
  } else if (docType === DocType.TASK) {
    return t('task_one')
  } else if (docType === DocType.NOTE) {
    return t('note_one')
  }
  return ''
}
