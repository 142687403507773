import dayjs, { Dayjs } from 'dayjs'
import {
  EventDoc,
  EventStatus,
  GroupDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import Event from './components/Event'
import {
  DateHeader,
  EventRow,
  ListViewScrollable,
  NoItems,
  TimeColumn,
  TimeContainer,
  TimeLabel
} from './styled'
import { StartEndEvent } from 'utils/date'
import dateToTimeString, { DayHeaderContent } from './components'
import { t } from 'i18next'
import { isSameDay, isToday } from 'date-fns'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import {
  buildEventsArrayForUIFromDBEventsWithRecurrentEvents,
  eventComparator
} from 'utils/api/events'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import Database from 'services/db/Database'
import { updateEventOnServer } from 'services/api/event'
import { localEventById } from 'services/db/Queries/Event'
import { useEffect, useState } from 'react'
import { useCalendar } from 'components/providers/CalendarProvider'
import { isEventWithinTimePeriod } from './CalendarView'
import {
  groupIdToProvider,
  isConvertedGroup,
  isConvertedGroupId
} from 'utils/groups'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'
import { eventLengthInDays, isEventMoreThanADay } from 'utils/event'
import Logger from 'services/Logger'

interface ListViewProps {
  events: Model<EventDoc>[]
  dayNameFormat?: string
  userSettings?: UserSettingsDoc
  group?: Model<GroupDoc>
  groupId?: string
  groups?: GroupDoc[]
  handleAddEvent?: (startEnd: StartEndEvent) => void
}

const ListView: React.FC<ListViewProps> = (props: ListViewProps) => {
  const calendar = useCalendar()
  const visibleAreaStart = dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_FROM))
  const visibleAreaEnd = dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_TO))

  const eventsForListView =
    buildEventsArrayForUIFromDBEventsWithRecurrentEvents(props.events)
      .filter((event) => {
        return isEventWithinTimePeriod(event, visibleAreaStart, visibleAreaEnd)
      })
      .filter(
        (event) =>
          event.Status === EventStatus.ACTIVE ||
          event.Status === EventStatus.COMPLETED
      )
  // generate dates starting from visible area start to visible area end in format YYYY-MM-DD

  let currentDate = visibleAreaStart
  const dates = []

  while (
    currentDate.isBefore(visibleAreaEnd) ||
    currentDate.isSame(visibleAreaEnd)
  ) {
    dates.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }

  const uniqueDates = dates

  const eventsForDate = (date: string) => {
    const from = dayjs(date)
    const to = dayjs(date).add(1, 'day').subtract(1, 'second')

    return eventsForListView
      .filter((event) => {
        let eventStart = dayjs.unix(Number(event.StartDate))
        let eventEnd = dayjs.unix(Number(event.EndDate))

        if (event._id?.length === 0) {
          return isSameDay(dayjs(date).toDate(), eventStart.toDate())
        }

        if (event.late && !isToday(dayjs(date).toDate())) {
          return false
        }

        if (groupIdToProvider(event.GroupID ?? '') === ProviderType.GOOGLE) {
          if (event.AllDay === '1') {
            // for google all day events, we need to remove a day to the end date
            eventEnd = eventEnd.add(-1, 'day')
          }
        }

        return isEventWithinTimePeriod(event, from, to)
      })
      .sort(eventComparator)
  }

  const onDragEnd = (result: DropResult) => {
    console.log(result)

    if (!result.destination) return
    const newPositionIndex = result.destination?.index ?? 0

    const currentEvent = eventsForDate(result.source.droppableId)[
      result.source.index
    ]

    const oldStart = currentEvent.StartDate
    const oldEnd = currentEvent.EndDate
    const oldAllday = currentEvent.AllDay
    const oldRank = currentEvent.Rank

    const startHour = dayjs(Number(currentEvent.StartDate) * 1000).hour()
    const startMinute = dayjs(Number(currentEvent.StartDate) * 1000).minute()

    let prevEvent =
      newPositionIndex === 0
        ? undefined
        : eventsForDate(result.destination?.droppableId)[newPositionIndex]
    let nextEvent: Model<EventDoc> | EventDoc | undefined = eventsForDate(
      result.destination?.droppableId
    )[newPositionIndex + 1]
    const duration =
      Number(currentEvent.EndDate) - Number(currentEvent.StartDate)

    if (result.destination?.droppableId !== result.source.droppableId) {
      prevEvent =
        newPositionIndex === 0
          ? undefined
          : eventsForDate(result.destination?.droppableId)[newPositionIndex - 1]
      nextEvent = eventsForDate(result.destination?.droppableId)[
        newPositionIndex
      ]
    }

    let rank: string
    let newStartDate: Dayjs = dayjs(result.destination?.droppableId)
      .set('hour', startHour)
      .set('minute', startMinute)
      .set('second', 0)
    let allDay: string = currentEvent.AllDay ?? '0'

    const targetDay = dayjs(result.destination?.droppableId)

    if (prevEvent || nextEvent) {
      if (newPositionIndex === 0 && nextEvent) {
        rank = (Number(nextEvent?.Rank) - 100).toString()

        const nextDayStart = dayjs(
          Number(nextEvent.lateOriginalStartTime ?? nextEvent.StartDate) * 1000
        )
        newStartDate = nextDayStart
        allDay = nextEvent.AllDay ?? '0'
      } else if (!nextEvent && prevEvent) {
        //we dropped to the end of the list
        rank = (Number(prevEvent?.Rank) + 100).toString()

        const prevEventStart = dayjs(
          Number(prevEvent.lateOriginalStartTime ?? prevEvent.StartDate) * 1000
        )
        newStartDate = prevEventStart
        allDay = prevEvent.AllDay ?? '0'
      } else if (prevEvent && nextEvent) {
        if (prevEvent.StartDate === nextEvent.StartDate) {
          //we dropped inbetween
          rank = (
            (Number(nextEvent.Rank) + Number(prevEvent.Rank)) /
            2
          ).toString()
        } else {
          rank = (Number(prevEvent.Rank) + 100).toString()
        }

        const prevEventStart = dayjs(
          Number(prevEvent.lateOriginalStartTime ?? prevEvent.StartDate) * 1000
        )
        newStartDate = prevEventStart
        allDay = prevEvent.AllDay ?? '0'
      }
    }

    if (currentEvent.someday === '1') {
      LocalStorage.set(
        LocalStorageKey.SOMEDAY_DATE,
        newStartDate.unix().toString()
      )
      LocalStorage.set(LocalStorageKey.SOMEDAY_ALL_DAY, allDay)
      return
    }

    if (LocalStorage.get(LocalStorageKey.VISIBLE_EVENT) === '') {
      LocalStorage.set(
        LocalStorageKey.VISIBLE_EVENT,
        `${currentEvent._id}${newStartDate.valueOf()}` ?? ''
      )
    }

    if (currentEvent) {
      Database.write(async () => {
        ;(await localEventById(currentEvent._id ?? '')).update((local) => {
          local.StartDate = newStartDate.unix().toString()
          local.EndDate = newStartDate.add(duration, 'second').unix().toString()
          local.Rank = rank
          local.AllDay = allDay
        })
      }).then(() => {
        if (currentEvent._id) {
          updateEventOnServer(
            currentEvent,
            {
              StartDate: newStartDate.unix().toString(),
              EndDate: newStartDate.add(duration, 'second').unix().toString(),
              Rank: rank,
              SupplementaryGroupsIDs: currentEvent.SupplementaryGroupsIDs
            },
            true
          ).catch((error) => {
            Database.write(async () => {
              ;(await localEventById(currentEvent._id ?? '')).update(
                (local) => {
                  local.StartDate = oldStart
                  local.EndDate = oldEnd
                  local.Rank = oldRank
                  local.AllDay = oldAllday
                }
              )
            })
          })
        }
      })
    }
  }

  useEffect(() => {
    const time = calendar.scrollToTime.toDate()

    setTimeout(() => {
      const pendingItem = document.getElementById('pending')

      console.log('pendingItem', pendingItem)

      if (pendingItem) {
        pendingItem?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
        return
      }
      if (isToday(time)) {
        const element = document.getElementById('today-header')
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            })
          }, 50)
        }
      } else {
        const element = document.getElementById(
          calendar.scrollToTime.format('YYYY-MM-DD') ?? ''
        )
        if (element) {
          setTimeout(() => {
            element.scrollIntoView()
          }, 50)
        }
      }
    }, 50)
  }, [calendar.scrollToTime])

  // Create a Map to store the counts
  const eventCounts = new Map()

  return (
    <DragDropContext
      onDragStart={() => {
        LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')
      }}
      onDragEnd={onDragEnd}
    >
      <ListViewScrollable className="fc-scroller">
        {uniqueDates.length === 0 && <NoItems>No items</NoItems>}
        {uniqueDates.map((date, index) => {
          if (eventsForDate(date).length === 0) return <></>

          return (
            <Droppable key={date} droppableId={date}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    width: '100%'
                  }}
                  onClick={() => {}}
                >
                  <div>
                    <DateHeader
                      id={isToday(dayjs(date).toDate()) ? 'today-header' : date}
                      isFirst={index === 0}
                      isToday={isToday(dayjs(date).toDate())}
                    >
                      <DayHeaderContent
                        date={dayjs(date).toDate()}
                        groupId={props.group?._id}
                        userSettings={props.userSettings}
                        dayNameFormat={props.dayNameFormat}
                        handleAddEvent={props.handleAddEvent}
                      />
                    </DateHeader>
                    {eventsForDate(date).map((event, eventIndex) => {
                      const current = dayjs(date)
                      const startEvent = dayjs(Number(event.EndDate) * 1000)
                      let day =
                        eventLengthInDays(event) -
                        (startEvent.startOf('day').unix() -
                          current.startOf('day').unix()) /
                          86400

                      if (
                        isConvertedGroupId(event.GroupID) &&
                        event.AllDay === '1'
                      ) {
                        day += 1
                      }

                      let longLabel = isEventMoreThanADay(event)
                        ? `, (${t('day_of_long_event', {
                            num: day,
                            total: eventLengthInDays(event)
                          })})`
                        : undefined

                      let start = event.late
                        ? dayjs(
                            Number(event.lateOriginalStartTime) * 1000
                          ).format('MMM D YYYY')
                        : dateToTimeString(
                            dayjs(Number(event.StartDate) * 1000).toDate(),
                            true
                          )
                      let end = event.late
                        ? dayjs(
                            Number(event.lateOriginalEndTime) * 1000
                          ).format('MMM D YYYY')
                        : dateToTimeString(
                            dayjs(Number(event.EndDate) * 1000).toDate(),
                            true
                          )
                      const uniqueId = `${event.id ?? event._id}${
                        event.StartDate
                      }${date}`

                      if (isEventMoreThanADay(event)) {
                        if (
                          isSameDay(
                            dayjs(date).toDate(),
                            dayjs(Number(event.StartDate) * 1000).toDate()
                          )
                        ) {
                          start = dateToTimeString(
                            dayjs(Number(event.StartDate) * 1000).toDate(),
                            true
                          )
                          end = dayjs(Number(event.EndDate) * 1000).format(
                            'MMM D'
                          )
                        } else if (
                          isSameDay(
                            dayjs(date).toDate(),
                            dayjs(Number(event.EndDate) * 1000).toDate()
                          )
                        ) {
                          start = dayjs(Number(event.StartDate) * 1000).format(
                            'MMM D'
                          )
                          end = dateToTimeString(
                            dayjs(Number(event.EndDate) * 1000).toDate(),
                            true
                          )
                        } else {
                          start = dayjs(Number(event.StartDate) * 1000).format(
                            'MMM D'
                          )
                          end = dayjs(Number(event.EndDate) * 1000).format(
                            'MMM D'
                          )
                        }

                        if (event.AllDay === '1' && !event.late) {
                          start = dayjs(Number(event.StartDate) * 1000).format(
                            'MMM D'
                          )
                          end = dayjs(Number(event.EndDate) * 1000)
                            .add(
                              isConvertedGroupId(event.GroupID ?? '') ? -1 : 0,
                              'day'
                            )
                            .format('MMM D')
                        }
                      } else {
                        if (event.AllDay === '1' && !event.late) {
                          start = t('allDay')
                          end = ''
                        }
                      }

                      return (
                        <Draggable
                          isDragDisabled={
                            event.Recurrence !== undefined &&
                            event.Recurrence !== null &&
                            typeof event.Recurrence !== 'string'
                          }
                          key={uniqueId}
                          draggableId={uniqueId}
                          index={eventIndex}
                        >
                          {(provided) => {
                            return (
                              <div
                                data-unique-id={uniqueId}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <EventRow
                                  id={
                                    event._id?.length === 0
                                      ? 'pending'
                                      : undefined
                                  }
                                  key={`${event._id}${event.StartDate}`}
                                >
                                  <TimeColumn>
                                    <TimeContainer>
                                      <TimeLabel
                                        sx={{
                                          color: event.late ? 'red' : undefined
                                        }}
                                      >
                                        {start}
                                      </TimeLabel>
                                      {start !== end && !event.late ? (
                                        <TimeLabel>{end}</TimeLabel>
                                      ) : (
                                        <></>
                                      )}
                                    </TimeContainer>
                                  </TimeColumn>
                                  <Event
                                    alwaysFullSize
                                    isStart
                                    instanceStart={
                                      Number(event.StartDate) * 1000
                                    }
                                    instanceEnd={
                                      (Number(event.EndDate) -
                                        (event.AllDay === '1' &&
                                        (event._id?.length ?? 0) > 0 &&
                                        event.StartDate != event.EndDate &&
                                        isConvertedGroupId(event.GroupID ?? '')
                                          ? 12 * 60 * 60
                                          : 0)) *
                                      1000
                                    }
                                    event={event}
                                    title={`${event.Text}${
                                      longLabel ? longLabel : ''
                                    }`}
                                  />
                                </EventRow>
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )
        })}
      </ListViewScrollable>
    </DragDropContext>
  )
}

export default ListView
