import { Box } from '@mui/material'
import { ProRed, ProRedIcon, ProYellowIcon } from 'assets/icons'
import { PopupRowTitle } from 'components/popups/styled'
import { ReactNode } from 'react'
import { theme } from 'theme'
import { Tier } from 'utils/api/accounts'
import { ProBadgeBox } from './styled'
import { is24meApp } from 'utils/appType'
import i18n from 'i18n'

interface ProBadgeProps {
  title: string | ReactNode
  neededTier?: Tier
}

const proIconSize = 20
const proBadgeBox = 20

export default function ProBadge(props: ProBadgeProps) {
  return (
    <ProBadgeBox
      theme={theme}
      badgesize={proBadgeBox}
      flexDirection={'row'}
      paddingRight={i18n.dir() === 'ltr' ? '16px' : undefined}
      paddingLeft={i18n.dir() === 'ltr' ? undefined : '16px'}
    >
      <PopupRowTitle style={{ position: 'relative' }}>
        {props.title}
        <div
          style={{
            right: i18n.dir() === 'ltr' ? -(proBadgeBox - 2) : undefined,
            left: i18n.dir() === 'ltr' ? undefined : -(proBadgeBox - 2),
            top: -8,
            position: 'absolute'
          }}
        >
          {is24meApp() ? <ProYellowIcon /> : <ProRedIcon />}
        </div>
      </PopupRowTitle>
    </ProBadgeBox>
  )
}

interface ProBadgeContainerProps {
  children: ReactNode
}

export function ProBadgeContainer(props: ProBadgeContainerProps) {
  return (
    <Box display={'flex'}>
      {props.children}
      <div style={{ left: -4, bottom: 4, position: 'relative' }}>
        <ProRedIcon width={proIconSize} height={proIconSize} />
      </div>
    </Box>
  )
}
