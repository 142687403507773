import Box from '@mui/material/Box'
import { ReactElement, ReactNode, useState } from 'react'
import Popup, { PopupInstance } from '../popup/Popup'
import { ClickAwayListener, Grid } from '@mui/material'
import { Root } from 'react-dom/client'
import { t } from 'i18next'
import { EventSegment } from '@fullcalendar/core'
import { theme } from 'theme'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

export interface EventsListPopupProps {
  children: ReactElement
  events: EventSegment[]
  root: Root
  date?: Date
  eventDrawer?: (eventSegment: EventSegment) => ReactNode
  instance?: PopupInstance
  onInstance?: (instance: PopupInstance) => void
  onAway?: () => void
}

export default function EventsListPopup(props: EventsListPopupProps) {
  return (
    <ClickAwayListener
      key={'list-popup-away'}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) === '1') return
        if (props.onAway) props.onAway()
        props.root.render(
          <Box>{t('moreAmount', { more: props.events.length })}</Box>
        )
        props.instance?.hide()
        LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
      }}
    >
      <div>
        <Popup
          onMount={props.onInstance}
          open
          content={
            <Grid
              style={{
                padding: theme.spacing(0, 4),
                overflowY: 'auto',
                backgroundColor: 'white',

                height: 'fit-content',
                maxHeight: '300px',
                pointerEvents: 'all'
              }}
            >
              {props.events.map((event) =>
                props.eventDrawer ? (
                  <div key={event.event.id} style={{ marginTop: 4 }}>
                    {props.eventDrawer(event)}
                  </div>
                ) : (
                  <Box
                    sx={{
                      color: 'black'
                    }}
                    key={event.event.extendedProps.eventModel._id}
                  >
                    {event.event.extendedProps.eventModel.Text}
                  </Box>
                )
              )}
            </Grid>
          }
        >
          {props.children}
        </Popup>
      </div>
    </ClickAwayListener>
  )
}
