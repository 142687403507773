import { Theme } from '@mui/material'
import i18n from 'i18n'
import { StylesConfig } from 'react-select'

function applyLg(size: string, styles: Record<string, any>) {
  return {
    ...(size === 'lg' && styles)
  }
}

function applyMd(size: string, styles: Record<string, any>) {
  return {
    ...(size === 'md' && styles)
  }
}

export function customStyles(
  theme: Theme,
  size: string,
  variant: string | undefined
): StylesConfig {
  return {
    control: (base) => ({
      ...base,
      width: 'fit-content',
      height: 32,
      flexWrap: 'nowrap',
      minHeight: 32,
      direction: i18n.dir(),
      marginRight: i18n.dir() === 'ltr' ? 0 : '8px',
      paddingRight: i18n.dir() === 'ltr' ? '8px' : 0,
      border: `1px solid ${theme.palette.input.border.primary}`,
      boxShadow: '0px 1px 6px 0px #32324714 inset',
      borderRadius: 23,
      cursor: 'pointer',
      ...applyLg(size, {
        height: 52,
        minHeight: 52
      }),
      ...(variant === 'no-border' && {
        border: 0,
        boxShadow: 'none'
      }),
      '&:hover': {
        border: `1px solid ${theme.palette.input.border.primary}`,
        ...(variant === 'no-border' && {
          border: 0,
          boxShadow: '0px 1px 6px 0px #32324714 inset'
        })
      }
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flex: 'none',
      overflow: 'none',
      height: 30,
      padding: theme.spacing(0, 2)
    }),
    placeholder: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.palette.input.placeholder,
      fontSize: '0.625rem',
      fontWeight: 400,

      ...applyLg(size, {
        fontSize: '1.25rem'
      }),

      ...applyMd(size, {
        fontSize: 14
      })
    }),
    singleValue: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      display: 'flex',
      color: theme.palette.text.primary,
      fontSize: '0.75rem',
      fontWeight: 400,
      ...applyLg(size, {
        fontSize: '1.25rem'
      }),
      ...applyMd(size, {
        fontSize: 14
      })
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.palette.text.primary,
      fontSize: '0.625rem',
      fontWeight: 400,
      ...applyLg(size, {
        fontSize: '1.25rem'
      }),
      ...applyMd(size, {
        fontSize: 14
      })
    }),
    indicatorsContainer: (base) => ({
      ...base,
      width: 'fit-content',
      height: 30,
      marginLeft: i18n.dir() === 'ltr' ? 0 : '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.primary,

      '& svg': {
        width: 8
      },

      ...applyLg(size, {
        width: 'fit-content',
        height: 50,

        '& svg': {
          width: 12
        }
      })
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 23,
      border: `1px solid ${theme.palette.input.border.primary}`,
      zIndex: 99,
      width: 'fit-content',
      minWidth: 125,
      boxShadow:
        '0px 1px 8px rgba(50, 50, 71, 0.08), 0px 4px 16px rgba(50, 50, 71, 0.06)'
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      width: 'fit-content',
      minWidth: 125,
      direction: i18n.dir(),
      borderRadius: 22
    }),
    noOptionsMessage: (base) => ({
      ...base,
      fontWeight: 400,
      color: theme.palette.input.placeholder,
      fontSize: '1rem'
    }),
    option: (base, props) => ({
      ...base,
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      padding: theme.spacing(3, 4),
      fontSize: '1rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
      ...(props.isSelected && {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      })
    })
  }
}
