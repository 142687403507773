import { AccountDoc } from 'types/api/changes'
import { highestActiveTier } from './api/accounts'
import { isFeatureAllowedToUser, TierFeatures } from './api/groups'

export function isUserOutOfTierBasedOnLandline(accountDoc?: AccountDoc) {
  return !isFeatureAllowedToUser(
    TierFeatures.LANDLINE,
    highestActiveTier(accountDoc),
    undefined,
    accountDoc
  )
}

export function outOfTiersFeaturesForAccount(accountDoc?: AccountDoc) {
  if (!accountDoc) return []

  const outOfTierFeatures: TierFeatures[] = []
  const currentTier = highestActiveTier(accountDoc)
  if (
    !isFeatureAllowedToUser(
      TierFeatures.LANDLINE,
      currentTier,
      undefined,
      accountDoc
    )
  )
    outOfTierFeatures.push(TierFeatures.LANDLINE)

  return outOfTierFeatures
}
