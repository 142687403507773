import { Model, Q, Query } from '@nozbe/watermelondb'
import { children, json, lazy, text } from '@nozbe/watermelondb/decorators'
import { EventDoc } from 'types/api/changes'
import { Model as AppModel } from 'types/model'
import { sanitizeJson } from 'utils/watermalon'

export enum GroupPrivacy {
  CHANNEL = '3',
  PRIVATE = '1'
}

export enum GroupVerified {
  VERIFIED = '2'
}

export default class Group extends Model {
  static table = 'Groups'

  static associations = {
    Events: { type: 'has_many', foreignKey: 'GroupID' }
  } as const

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('_message') _message!: string
  @text('_sortOrder') _sortOrder!: number
  @text('CreationDate') CreationDate!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('GroupColor') GroupColor!: string
  @text('LastUpdate') LastUpdate!: string
  @text('Name') Name!: string
  @text('OwnerID') OwnerID!: string
  @text('Photo') Photo!: string
  @text('PrivacyMode') PrivacyMode!: string
  @text('Version') Version!: string
  @text('VerifiedGroupStatus') VerifiedGroupStatus!: string
  @text('PrivateLinkPassword') PrivateLinkPassword!: string
  @text('PrivateLinkUrl') PrivateLinkUrl!: string
  @text('Status') Status!: string
  @text('SyncProblem') SyncProblem?: string
  @text('Type') Type!: string
  @text('UserID') UserID!: string
  @json('GroupSettings', sanitizeJson) GroupSettings!: string
  @json('Participants', sanitizeJson) Participants!: string
  @json('PendingParticipants', sanitizeJson) PendingParticipants!: string
  @json('BusinessInfo', sanitizeJson) BusinessInfo!: string
  @children('Events') Events!: Query<AppModel<EventDoc>>

  @lazy LastUpdateEvent = this.Events.extend(
    Q.sortBy('LastUpdated', Q.desc),
    Q.take(1)
  )
}
