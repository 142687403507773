import { Model } from '@nozbe/watermelondb'
import { json, text } from '@nozbe/watermelondb/decorators'
import { UserSettingsGroupSettings } from 'types/api/changes'
import { sanitizeJson } from 'utils/watermalon'

export default class UserSettings extends Model {
  static table = 'UserSettings'

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('FirstDayOfTheWeek') FirstDayOfTheWeek!: number
  @text('Gender') Gender!: string
  @text('Language') Language!: string
  @text('LastUpdate') LastUpdate!: string
  @text('Locale') Locale!: string
  @text('ShowBirthdays') ShowBirthdays!: string
  @text('TimeZone') TimeZone!: string
  @text('TimeZoneOffsetInSecFromGMT') TimeZoneOffsetInSecFromGMT!: string
  @text('Type') Type!: string
  @text('UserID') UserID!: string

  @json('GroupsSettings', sanitizeJson)
  GroupsSettings!: Record<string, UserSettingsGroupSettings>
}
