import { is24meApp } from './appType'
import { random } from './array'

export const FREE_COLOR = '#3D3B3B'
export const PRO_COLOR = '#15CF74'
export const BUSINESS_COLOR = '#0F7FEB'
export const BUSINESS_PLUS_COLOR = '#725CFF'

export const APP_COLOR = is24meApp() ? '#F2C700' : '#d43554'

export const GREY_BG = ''

export const COLORS: string[] = [
  '#8E24AA',
  '#3F51B5',
  '#7986CB',
  '#039BE5',
  '#33B679',
  '#0B8043',
  '#F6BF26',
  '#e67c73',
  '#F4511E',
  '#d50000',
  '#616161'
]

export function getRandomColor(position?: number): string {
  if (position) return COLORS[position % COLORS.length]

  return random(COLORS)
}

export function isColorLight(color: string, threshold: number): boolean {
  // Parse the color to get its RGB values

  let colorToProcess = color

  if (color.includes('rgb') && color.match(/\d+/g)) {
    const hasrgb = color.match(/\d+/g)
    if (hasrgb != null) {
      const [r, g, b] = hasrgb.map(Number)
      colorToProcess = rgbToHex(r, g, b)
    }
  }

  const hex = colorToProcess.replace(/^#/, '')
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  // Calculate the perceived brightness
  const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Compare the brightness to the threshold
  return brightness >= threshold
}

function rgbToHex(r: number, g: number, b: number): string {
  const redHex = r.toString(16).padStart(2, '0')
  const greenHex = g.toString(16).padStart(2, '0')
  const blueHex = b.toString(16).padStart(2, '0')
  return `#${redHex}${greenHex}${blueHex}`
}
