import { Box, Grid, IconButton, Typography } from '@mui/material'
import Analytics, { Events } from 'analytics/Analytics'
import { ArrowRight, logo } from 'assets/icons'
import Calendar from 'components/calendar/Calendar'
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import Database from 'services/db/Database'

import './styles.scss'
import { CalendarRange } from 'types/components/calendar'

export interface GuestModeProps {
  showHeader?: string
  defaultView?: CalendarRange
  groupId: string
  width?: string
  height?: string
  preview?: boolean
}

export default function GuestMode(props: GuestModeProps) {
  const navigate = useNavigate()
  const goToLogin = () => {
    Analytics.getInstance().sendEvent(Events.GEUST_MODE_OPEN_FULL_VERSION)
    Database.clear()
    navigate('/')
  }

  const isEmbed = window.self !== window.top

  useEffect(() => {
    Analytics.getInstance().sendEvent(Events.GEUST_MODE_OPEN)
  }, [])

  const openMainPage = () => {
    window.open('https://app.groupcal.app/')
  }

  const queryParams = new URLSearchParams(location.search)
  const showHeader = queryParams.get('showHeader')

  return (
    <Box
      bgcolor="green"
      display="flex"
      height={props.height ? props.height : '100vh'}
      width={props.width ? props.width : '100vw'}
    >
      <Box
        bgcolor={isEmbed || props.preview ? '#ffffff' : '#e5E5E5'}
        display="flex"
        flex={1}
        minWidth={'100px'}
        minHeight={'100px'}
        flexDirection={{ xs: 'column', sm: 'column' }}
      >
        {showHeader ?? props.showHeader === '0' ? (
          <></>
        ) : (
          <Box paddingLeft={4} paddingRight={4} paddingTop={4}>
            <Box>
              <Grid
                direction={'row'}
                container
                gap={'64px'}
                wrap={'nowrap'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Grid
                  item
                  xs={2}
                  container
                  wrap={'nowrap'}
                  direction={'row'}
                  gap={'11px'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <IconButton onClick={openMainPage}>
                    <img
                      src={logo}
                      style={{ width: 36, height: 36, cursor: 'pointer' }}
                    />
                  </IconButton>
                  <span onClick={openMainPage} style={{ cursor: 'pointer' }}>
                    <Typography fontFamily={'Montserrat'} fontSize={'24px'}>
                      GroupCal
                    </Typography>
                  </span>
                </Grid>
                {props.preview ? (
                  <></>
                ) : (
                  <div>
                    <Typography
                      textAlign={'center'}
                      lineHeight={'22px'}
                      fontSize={'24px'}
                      fontFamily={'NotoSans'}
                      style={{ pointerEvents: 'none' }}
                    >
                      Shared Calendar for Individuals and Businesses
                    </Typography>
                  </div>
                )}
                <div className="goToSignUp" onClick={goToLogin}>
                  Open in full view
                  <ArrowRight className="arrow" style={{ marginLeft: 8 }} />
                </div>
              </Grid>
            </Box>
          </Box>
        )}
        <Box height={'100%'} padding={4}>
          <Calendar
            showOnlySelectedGroup
            groupId={props.groupId}
            readOnly
            defaultView={props.defaultView}
            dayNameFormat="ddd"
          />
        </Box>
      </Box>
    </Box>
  )
}
