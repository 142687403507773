import { Box, ClickAwayListener, Grid, TextField } from '@mui/material'
import { ChangeEvent, ReactNode, useState } from 'react'
import * as Styled from '../styled'
import { PopupInstance } from 'components/popup/Popup'
import { EventReminder } from 'types/api/changes'
import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import Button from 'components/buttons/Button'
import { TickIcon } from 'assets/icons'
import { t, use } from 'i18next'
import Logger from 'services/Logger'

interface ContentProps {
  reminders: EventReminder[]
  setReminders: (reminders: EventReminder[]) => void
  instance: PopupInstance | null | undefined
}

export enum ReminderType {
  MIN,
  HOUR,
  DAY,
  WEEK
}

interface CustomReminder {
  type: ReminderType
  title: string
}

export function CustomReminderContent(props: ContentProps) {
  const [searchText, setSearchText] = useState<string>('15')

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const count = Number(searchText)

  const reminderTypes: CustomReminder[] = [
    {
      type: ReminderType.MIN,
      title: t('timeBefore', { time: t('minute', { count }) })
    },
    {
      type: ReminderType.HOUR,
      title: t('timeBefore', { time: t('hour', { count }) })
    },
    {
      type: ReminderType.DAY,
      title: t('timeBefore', { time: t('day', { count }) })
    },
    {
      type: ReminderType.WEEK,
      title: t('timeBefore', { time: t('week', { count }) })
    }
  ]
  const [currentType, setCurrentType] = useState<CustomReminder>(
    reminderTypes[0]
  )

  const showError =
    convertToMinutes(searchText, currentType.type) >
    convertToMinutes('4', ReminderType.WEEK)

  function addReminderToEvent(type: ReminderType) {
    if (showError) return

    Logger.pink('Reminder:', searchText, type)

    let minutes = convertToMinutes(searchText, type)

    Logger.pink('Reminder:', minutes)

    const reminder: EventReminder = {
      AlertTime: '',
      isOn: '1',
      isRelativeReminder: '1',
      offset: minutes * 60 // store offset in seconds
    }
    props.setReminders([...(props.reminders ?? []), reminder])
    Logger.pink('Reminder:', props.reminders)
    props.instance?.hide()
  }

  return (
    <Grid container alignItems={'start'} direction={'column'}>
      <PopupSection title={`${t('customReminder')}`}>
        <Box height={8}></Box>
        <TextField
          autoFocus
          helperText={showError ? `${t('reminderNotValid')}` : ''}
          placeholder="0"
          error={showError}
          type={'number'}
          value={searchText}
          onChange={onSearchChange}
          variant="standard"
        />
        {reminderTypes.map((customRem) => {
          return (
            <PopupSectionRow
              action={() => {
                setCurrentType(customRem)
              }}
              value={customRem.type === currentType.type ? <TickIcon /> : <></>}
              key={customRem.type}
              title={customRem.title}
            />
          )
        })}
        <Box height={8}></Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Button
            size="xs"
            onClick={addReminderToEvent.bind(null, currentType.type)}
          >
            {t('done')}
          </Button>
        </div>
      </PopupSection>
    </Grid>
  )
}

interface CustomEventReminderPopupProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  reminders: EventReminder[]
  setReminders: (reminders: EventReminder[]) => void
  instance: PopupInstance | null | undefined
}

export default function CustomEventReminderPopup(
  props: CustomEventReminderPopupProps
) {
  const [instance, onInstance] = useState<PopupInstance>()
  return (
    <ClickAwayListener
      onClickAway={() => {
        props.setOpen(false)
      }}
    >
      <Styled.DatePickPopup
        open={props.open}
        onMount={onInstance}
        content={<CustomReminderContent {...props} />}
        placement={'bottom'}
      >
        {props.children}
      </Styled.DatePickPopup>
    </ClickAwayListener>
  )
}

function convertToMinutes(text: string, type: ReminderType) {
  let minutes = 0
  let typed = Number(text)

  switch (type) {
    case ReminderType.MIN:
      minutes = typed * 1
      break
    case ReminderType.HOUR:
      minutes = typed * 60
      break
    case ReminderType.DAY:
      minutes = typed * 60 * 24
      break
    case ReminderType.WEEK:
      minutes = typed * 60 * 24 * 7
      break
  }

  return minutes
}
