import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Logger from 'services/Logger'
import { isTestEnv } from 'utils/env'

export const API_URL = 'https://api.checkmobi.com/v1'

class CheckmobiApi {
  instance = axios.create({
    baseURL: API_URL
  })

  constructor() {
    this.instance.interceptors.request.use((req) => {
      req.headers = {
        ...req.headers,
        Authorization: isTestEnv()
          ? 'C48ABFC5-C960-4FCF-9542-2C274DD16BA2'
          : '1F7E9F14-B9EF-4CD7-AEB8-9A218B6B6937'
      }
      return req
    })
  }

  public get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    Logger.blue(`request to ${url}`)

    const response = this.instance.get(url, config)

    return response
  }

  public post(
    url: string,
    body: Record<string, string | number | object | undefined | null>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    let postBody = {
      ...body
    }

    Logger.blue(`request to ${url}; with body`, postBody)

    return this.instance.post(url, postBody, config).then((e) => {
      return e
    })
  }
}

export default new CheckmobiApi()
