import { useState } from 'react'

import * as Styled from './styled'

import { t } from 'i18next'
import { shareLink } from 'utils/share'

export interface InviteLinkBadgeProps {
  link: string
}

export default function InviteLinkBadge(props: InviteLinkBadgeProps) {
  return (
    <Styled.InviteLinkBadge>
      {props.link ? <InviteLinkRow link={props.link} /> : `${t('clickSave')}`}
    </Styled.InviteLinkBadge>
  )
}

interface InviteLinkRowProps {
  link: string
  textSize?: string
  allowScroll?: boolean
}

export function InviteLinkRow(props: InviteLinkRowProps) {
  const [linkCopyTitle, setLinkCopyTitle] = useState<string>(`${t('copy')}`)

  async function handleCopyAction() {
    try {
      setLinkCopyTitle(`${t('copied')}`)
      await navigator.clipboard.writeText(props.link)
      shareLink(props.link)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Styled.InviteLinkContainer dir="ltr">
      <Styled.LinkContent
        style={{ fontSize: props.textSize ? props.textSize : '14px' }}
        {...props}
      >
        {props.link}
      </Styled.LinkContent>
      <Styled.CopyLinkButton onClick={handleCopyAction}>
        {linkCopyTitle}
      </Styled.CopyLinkButton>
    </Styled.InviteLinkContainer>
  )
}
