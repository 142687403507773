import withObservables from '@nozbe/with-observables'
import { PlanName } from 'components/dialogs/payment/PlansInfoDialog'
import PlansInfoDialog from 'components/dialogs/payment/PlansInfoDialog'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { reconcilePaymentsAndUpdateAccount } from 'services/api/payments'
import ObserveService from 'services/db/Observe'
import { AccountDoc } from 'types/api/changes'
import { Model } from 'types/model'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { highestActiveProduct } from 'utils/api/accounts'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { t } from 'i18next'
import { is24meApp } from 'utils/appType'

interface PaymentProviderProps {
  children: ReactNode
  account: Model<AccountDoc>
}

interface PaymentContextType {
  showPaymentDialog: (
    recommendedPlan: PlanName | null | undefined,
    onClose?: (
      status: 'PURCHASED' | 'PURCHASE_ABORTED' | 'DIALOG_CLOSED',
      plan: PlanName
    ) => void,
    features?: string[] | null | undefined
  ) => void
  isOpen: boolean
}

const PaymentContext = createContext<PaymentContextType>({
  showPaymentDialog: () => null,
  isOpen: false
})

function PaymentProvider(props: PaymentProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [recommendedPlan, setRecommendedPlan] = useState<
    PlanName | null | undefined
  >(null)
  const [onDialogClose, setOnDialogClose] = useState<any>(() => () => null)
  const [features, setFeatures] = useState<string[] | null | undefined>(null)

  const [show24mePaymentDialog, setShow24mePaymentDialog] = useState(false)

  const show24meStub = () => {
    setShow24mePaymentDialog(true)
  }

  const hide24meStub = () => {
    setShow24mePaymentDialog(false)
    setTimeout(() => {
      LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
    }, 150) // timeout to wait close animation
  }

  useEffect(() => {
    reconcilePaymentsAndUpdateAccount(props.account)
  }, [])

  const showPaymentDialog = (
    recommendedPlan: PlanName | null | undefined,
    onClose?: unknown,
    features?: string[] | null | undefined
  ) => {
    LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')
    if (is24meApp()) {
      show24meStub()
    } else {
      setRecommendedPlan(recommendedPlan)
      setFeatures(features)
      setIsOpen(true)

      if (onClose) {
        setOnDialogClose(() => onClose)
      }
    }
  }

  return (
    <PaymentContext.Provider value={{ showPaymentDialog, isOpen }}>
      <PlansInfoDialog
        features={features}
        open={isOpen}
        recommendedPlan={recommendedPlan}
        setOpen={setIsOpen}
        onPurchaseDialogClose={onDialogClose}
      />
      {show24mePaymentDialog && (
        <ConfirmationDialog
          handleClose={() => {
            hide24meStub()
          }}
          leftButtonHidden
          title={t('24mepro')}
          content={t('24meprodesc')}
          open={show24mePaymentDialog}
        />
      )}
      {props.children}
    </PaymentContext.Provider>
  )
}

const PaymentProviderWithAccount = withObservables([], () => ({
  account: ObserveService.Account()
}))(PaymentProvider)

const usePayment = () => useContext(PaymentContext)

export { PaymentProviderWithAccount as PaymentProvider, usePayment }
