import { UserSettingsDoc } from 'types/api/changes'
import { isGroupHidden } from './api/userSettings'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { isUserParticipantOfAGroup } from './api/groups'
import { localGroupById } from 'services/db/Queries/GroupQueries'
import dayjs from 'dayjs'
import { EventDoc } from 'types/api/changes'
import { groupIdToProvider, isConvertedGroupId } from './groups'

export const eventIdFormat = 'YYYY-MM-DD-HH-mm'

export function isGroupInSupplementaryGroups(
  group: string,
  event?: EventDoc
): boolean {
  return (event?.SupplementaryGroupsIDs ?? []).includes(group)
}

export async function provideReferenceGroup(
  event: EventDoc | undefined,
  userSettings: UserSettingsDoc | undefined
): Promise<string | undefined> {
  const currentGroup = LocalStorage.get(LocalStorageKey.SELECTED_GROUP)

  if (
    currentGroup &&
    currentGroup.length > 0 &&
    (currentGroup === event?.GroupID ||
      isGroupInSupplementaryGroups(currentGroup, event))
  ) {
    return currentGroup
  }

  const eventGroup = await localGroupById(event?.GroupID ?? '')

  if (
    !isGroupHidden(userSettings, event?.GroupID ?? '') &&
    isUserParticipantOfAGroup(
      eventGroup ?? '',
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )
  ) {
    return event?.GroupID
  }

  return event?.SupplementaryGroupsIDs?.find(
    (group) => !isGroupHidden(userSettings, group)
  )
}

export function eventLengthInDays(event: EventDoc) {
  if (!event.StartDate || !event.EndDate) return 0

  const provider = groupIdToProvider(event.GroupID ?? '')
  const startDate = dayjs.unix(Number(event.StartDate))
  const endDate = dayjs.unix(Number(event.EndDate))

  const startDateDay = startDate.startOf('day')
  const endDateDay = endDate.startOf('day')

  let days = endDateDay.diff(startDateDay, 'day')

  return days
}

export function isEventMoreThanADay(event: EventDoc) {
  return eventLengthInDays(event) > 1
}
