import { Box } from '@mui/material'
import Input from 'components/form/Input'
import { isValidEmail } from 'components/lists/ParticipantList'
import { t } from 'i18next'
import { ChangeEvent, useState } from 'react'
import { MiscText } from './styled'
import IconButton from 'components/buttons/IconButton'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import i18n from 'i18n'
import { UISTate } from './LoginPage'
import { theme } from 'theme'

interface TwentyfourLoginInputsProps {
  onTFMLogin: () => void
  name: string
  email: string
  password: string
  onName: (s: string) => void
  onEmail: (s: string) => void
  onPassword: (s: string) => void
  showLogin: boolean
  setShowLogin: (b: boolean) => void
  setErrorText: (text: string) => void
  onUIState: (uiState: UISTate) => void
  uiState: UISTate
}

export default function TwentyfourLoginInputs(
  props: TwentyfourLoginInputsProps
) {
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onName(e.target.value)
    props.setErrorText(' ')
  }
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onEmail(e.target.value)
    props.setErrorText(' ')
  }
  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onPassword(e.target.value)
    props.setErrorText(' ')
  }

  const [isEmailValid, onEmailValid] = useState(true)

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const actionStyle = {
    marginLeft: 8,
    marginRight: 8,
    cursor: 'pointer',
    textDecoration: 'underline'
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{ width: '100%', '& > :not(style)': { marginBottom: '1rem' } }}
    >
      {props.uiState === UISTate.REGULAR && (
        <>
          {!props.showLogin && (
            <Input
              variant="regular"
              placeholder={`${t('yourName')}`}
              value={props.name}
              onChange={onNameChange}
            />
          )}
          <div style={{ width: 'inherit' }}>
            {!isEmailValid && (
              <MiscText sx={{ marginLeft: 2 }} color={'red'} theme={theme}>
                {t('emailNotValid')}
              </MiscText>
            )}
            <Input
              variant="regular"
              placeholder={`${t('yourEmail')}`}
              value={props.email}
              onChange={onEmailChange}
              onFocusLost={() => {
                onEmailValid(isValidEmail(props.email))

                if (props.email.length === 0) onEmailValid(true)
              }}
            />
          </div>
          <div style={{ width: 'inherit', position: 'relative' }}>
            <Input
              variant="regular"
              type={showPassword ? 'normal' : 'password'}
              placeholder={`${t('choosePassword')}`}
              value={props.password}
              onChange={onPasswordChange}
            />
            <div
              style={{
                position: 'absolute',
                top: -10,
                right: i18n.dir() === 'ltr' ? 0 : undefined,
                left: i18n.dir() === 'ltr' ? undefined : 0
              }}
            >
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? (
                  <VisibilityIcon style={{ fontSize: '1.3rem' }} />
                ) : (
                  <VisibilityOffIcon style={{ fontSize: '1.3rem' }} />
                )}
              </IconButton>
            </div>
          </div>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <div>{t(props.showLogin ? 'newUser' : 'haveAnAccount')}</div>
            <div
              onClick={() => {
                props.setShowLogin(!props.showLogin)
              }}
              style={actionStyle}
            >
              {props.showLogin ? t('createAccount') : t('login')}
            </div>
          </Box>
          {props.showLogin && (
            <div
              onClick={() => {
                props.onUIState(UISTate.FORGOT_PASSWORD)
              }}
              style={actionStyle}
            >
              {t('forgotPassword')}
            </div>
          )}
        </>
      )}
      {props.uiState === UISTate.FORGOT_PASSWORD && (
        <>
          <Input
            variant="regular"
            placeholder={`${t('yourEmail')}`}
            value={props.email}
            onChange={onEmailChange}
          />
        </>
      )}
    </Box>
  )
}
