import { darken } from '@mui/material'
import Grid from '@mui/material/Grid'
import { StateButton } from 'components/buttons/styled'
import { useState } from 'react'
import { updateParticipantStatus } from 'services/api/event'
import LocalStorage from 'services/LocalStorage'
import { theme } from 'theme'
import { Attendee, EventDoc, EventParticipantStatus } from 'types/api/changes'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { t } from 'i18next'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import { groupIdToProvider, isConvertedGroupId } from 'utils/groups'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'

const darkenBgColor = darken(
  theme.palette.button.background.primary as string,
  0.1
)

interface GoingBarProps {
  setLoading: (b: boolean) => void
  event: Model<EventDoc> | EventDoc
  status: Record<string, EventParticipantStatus> | undefined
  setStatus: (s: Record<string, EventParticipantStatus>) => void
  attendees: Attendee[] | undefined
  setAttendees: (attendees: Attendee[]) => void
}

export function GoingBar(props: GoingBarProps) {
  const isThirdParty = isConvertedGroupId(props.event.GroupID)
  let userStatus =
    props.status?.[LocalStorage.get(LocalStorageKey.USER_ID) ?? '']
      ?.ParticipantConfirmationStatus ?? '1'

  if (props.attendees && isThirdParty) {
    userStatus = googleAttendeeStatusToGroupcal(
      props.attendees?.find((a) => a.self)?.responseStatus
    )
  }
  console.log(userStatus, 'userStatus')
  const [selectedState, setSelectedState] = useState<string>(userStatus)

  const changeUserStatus = async (status: string) => {
    setSelectedState(status)
    if (isThirdParty) {
      props.setAttendees(
        props.attendees?.map((attendee) => {
          if (attendee.self)
            attendee.responseStatus =
              groupIdToProvider(props.event.GroupID ?? '') ===
              ProviderType.APPLE
                ? groupcalStatusToGoogle(status).toUpperCase()
                : groupcalStatusToGoogle(status)
          return attendee
        }) ?? []
      )
    } else {
      const updatedStatus =
        props.event.ParticipantsStatus?.[
          LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        ]
      if (updatedStatus) {
        updatedStatus.ParticipantConfirmationStatus = status

        props.setLoading(true)
        await updateParticipantStatus([
          {
            _id: props.event._id,
            DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID) ?? '',
            ParticipantsStatus: { ...updatedStatus }
          }
        ])
        props.setLoading(false)
      }
    }
  }
  const regularColor = theme.palette.button.text.primary
  const yesTextColor =
    selectedState === '2' ? theme.palette.button.text.approve : regularColor
  const noTextColor =
    selectedState === '3' ? theme.palette.button.text.negative : regularColor
  const maybeTextColor =
    selectedState === '4' ? theme.palette.button.text.tenative : regularColor

  const yesBgColor =
    selectedState === '2'
      ? darkenBgColor
      : theme.palette.button.background.primary
  const noBgColor =
    selectedState === '3'
      ? darkenBgColor
      : theme.palette.button.background.primary
  const maybeBgColor =
    selectedState === '4'
      ? darkenBgColor
      : theme.palette.button.background.primary
  return (
    <Grid container direction={'row'} gap={1}>
      <StateButton
        bgcolor={yesBgColor}
        statecolor={yesTextColor}
        onClick={changeUserStatus.bind(null, '2')}
      >
        {t('yes')}
      </StateButton>
      <StateButton
        bgcolor={noBgColor}
        statecolor={noTextColor}
        onClick={changeUserStatus.bind(null, '3')}
      >
        {t('no')}
      </StateButton>
      <StateButton
        bgcolor={maybeBgColor}
        statecolor={maybeTextColor}
        onClick={changeUserStatus.bind(null, '4')}
      >
        {t('maybe')}
      </StateButton>
    </Grid>
  )
}
function googleAttendeeStatusToGroupcal(
  responseStatus: string | undefined
): string {
  switch (responseStatus) {
    case 'declined':
    case 'DECLINED':
      return '3'
    case 'TENTATIVE':
    case 'tentative':
      return '4'
    case 'ACCEPTED':
    case 'accepted':
      return '2'
    default:
      return '1'
  }
}

export function groupcalStatusToGoogle(responseStatus: string): string {
  switch (responseStatus) {
    case '3':
      return 'declined'
    case '4':
      return 'tentative'
    case '2':
      return 'accepted'
    default:
      return 'needsAction'
  }
}
