import { appSchema, tableSchema } from '@nozbe/watermelondb'
import ThirdPartyCalendarAccount from './models/ThirdPartyCalendarAccount'
import ParticipantSuggestion from './models/ParticipantSuggestion'

export default appSchema({
  version: 19,
  tables: [
    tableSchema({
      name: 'Accounts',
      columns: [
        { name: 'ConnectedCalAccounts', type: 'string' },
        { name: 'AccessToken', type: 'string' },
        { name: 'AppleID', type: 'string' },
        { name: 'DeviceChangeID', type: 'string' },
        { name: 'Devices', type: 'string' },
        { name: 'Products', type: 'string' },
        { name: 'Email', type: 'string' },
        { name: 'FacebookID', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Name', type: 'string' },
        { name: 'Password', type: 'string' },
        { name: 'PhoneNumber', type: 'string' },
        { name: 'PhoneNumberType', type: 'string' },
        { name: 'TokenExpires', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserType', type: 'string' },
        { name: 'PaymentEmail', type: 'string' },
        { name: 'groupcalActivatedDate', type: 'string' },
        { name: 'isDeleted', type: 'boolean' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'Groups',
      columns: [
        { name: 'CreationDate', type: 'string' },
        { name: 'BusinessInfo', type: 'string' },
        { name: 'DeviceChangeID', type: 'string' },
        { name: 'GroupColor', type: 'string' },
        { name: 'GroupSettings', type: 'string' },
        { name: 'BusinessInfo', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Name', type: 'string' },
        { name: 'OwnerID', type: 'string' },
        { name: 'Participants', type: 'string' },
        { name: 'PendingParticipants', type: 'string' },
        { name: 'Photo', type: 'string' },
        { name: 'PrivacyMode', type: 'string' },
        { name: 'Version', type: 'string' },
        { name: 'VerifiedGroupStatus', type: 'string' },
        { name: 'PrivateLinkPassword', type: 'string' },
        { name: 'PrivateLinkUrl', type: 'string' },
        { name: 'Status', type: 'string' },
        { name: 'SyncProblem', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserID', type: 'string' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' },
        { name: '_message', type: 'string' },
        { name: '_sortOrder', type: 'number' }
      ]
    }),
    tableSchema({
      name: 'Events',
      columns: [
        { name: 'GroupID', type: 'string', isIndexed: true },
        { name: 'AllDay', type: 'string' },
        { name: 'BirthdayDetails', type: 'string' },
        { name: 'ContactDetails', type: 'string' },
        { name: 'DeviceChangeID', type: 'string' },
        { name: 'EndDate', type: 'string' },
        { name: 'Job', type: 'string' },
        { name: 'Label', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Location', type: 'string' },
        { name: 'Notes', type: 'string' },
        { name: 'ObjectType', type: 'string' },
        { name: 'OpenDate', type: 'string' },
        { name: 'OwnerID', type: 'string' },
        { name: 'ParentTaskID', type: 'string' },
        { name: 'ParticipantsStatus', type: 'string' },
        { name: 'PostStatus', type: 'string' },
        { name: 'Priority', type: 'string' },
        { name: 'PrivacyStatus', type: 'string' },
        { name: 'Rank', type: 'string' },
        { name: 'Recurrence', type: 'string' },
        { name: 'Reminder', type: 'string' },
        { name: 'RequestConfirmation', type: 'string' },
        { name: 'Shared', type: 'string' },
        { name: 'StartDate', type: 'string' },
        { name: 'SupplementaryGroupsIDs', type: 'string' },
        { name: 'Status', type: 'string' },
        { name: 'Color', type: 'string' },
        { name: 'TaskType', type: 'string' },
        { name: 'Text', type: 'string' },
        { name: 'ThirdPartyID', type: 'string' },
        { name: 'TimeZoneNameID', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserID', type: 'string' },
        { name: 'isDeleted', type: 'string' },
        { name: 'local_id', type: 'string' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' },
        { name: '_message', type: 'string' },
        { name: 'AggregatedParticipantsConfirmationStatus', type: 'string' },
        { name: 'AggregatedParticipantsDeliveryStatus', type: 'string' },
        { name: 'ConferenceData', type: 'string' },
        { name: 'attendees', type: 'string' },
        { name: 'canAddAttendees', type: 'string' },
        { name: 'someday', type: 'string' },
        { name: 'LocationReminders', type: 'string' },
        { name: 'etag', type: 'string' },
        { name: 'Url', type: 'string' },
        { name: 'CustomUrl', type: 'string' },
        { name: 'Attachments', type: 'string' },
        { name: 'Organizer', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'MasterLabels',
      columns: [
        { name: 'DeviceChangeID', type: 'string' },
        { name: 'Labels', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserID', type: 'string' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'Profiles',
      columns: [
        { name: 'ActiveProviders', type: 'string' },
        { name: 'DataProviders', type: 'string' },
        { name: 'DeviceChangeID', type: 'string' },
        { name: 'Facebook', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Newsletters', type: 'string' },
        { name: 'Notifications', type: 'string' },
        { name: 'ProfilePicture', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserID', type: 'string' },
        { name: 'isDeleted', type: 'string' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'UserSettings',
      columns: [
        { name: 'DeviceChangeID', type: 'string' },

        { name: 'FirstDayOfTheWeek', type: 'number' },
        { name: 'Gender', type: 'string' },
        { name: 'GroupsSettings', type: 'string' },
        { name: 'Language', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'Locale', type: 'string' },
        { name: 'ShowBirthdays', type: 'string' },
        { name: 'TimeZone', type: 'string' },
        { name: 'TimeZoneOffsetInSecFromGMT', type: 'string' },
        { name: 'Type', type: 'string' },
        { name: 'UserID', type: 'string' },
        { name: '_id', type: 'string' },
        { name: '_rev', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'AppConstants',
      columns: [
        { name: 'deviceId', type: 'string' },
        { name: 'userId', type: 'string' },
        { name: 'tfMepassword', type: 'string' },
        { name: 'groupServerId', type: 'string' },
        { name: 'lastUpdate', type: 'string' },
        { name: 'accessToken', type: 'string' },
        { name: 'calendarView', type: 'string' },
        { name: 'phoneNumber', type: 'string' },
        { name: 'firebaseToken', type: 'string' },
        { name: 'debugLogger', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'Participant',
      columns: [
        { name: 'AccountID', type: 'string' },
        { name: 'FullName', type: 'string' },
        { name: 'LastUpdate', type: 'string' },
        { name: 'PhoneNumber', type: 'string' },
        { name: 'PhotoURL', type: 'string' }
      ]
    }),
    tableSchema({
      name: ThirdPartyCalendarAccount.table,
      columns: [
        { name: 'token', type: 'string' },
        { name: 'tokenExpire', type: 'number' },
        { name: 'email', type: 'string' },
        { name: 'groupId', type: 'string' },
        { name: 'provider', type: 'string' }
      ]
    }),
    tableSchema({
      name: ParticipantSuggestion.table,
      columns: [
        { name: 'name', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'img', type: 'string' }
      ]
    })
  ]
})
