import Analytics, { Events } from 'analytics/Analytics'
import {
  ProviderType,
  removePrefix,
  setAlertTimeToReminders
} from 'components/providers/CalendarSyncProdivder'
import { processDownloadedData } from 'components/providers/GetChangesProvider'
import {
  generalStatusToOutlook,
  outlookEventToTfmEvent,
  tfmEventToOutlook
} from 'components/providers/outlook/OutlookProvider'
import dayjs from 'dayjs'
import Api from 'services/Api'
import ChangesService from 'services/db/Changes'
import Database from 'services/db/Database'
import { localEventById } from 'services/db/Queries/Event'
import { setGroupSortOrder } from 'services/db/Queries/GroupQueries'
import eventBus, { AppEvents } from 'services/EventBus'
import IcloudApi, { updateCalendarItem } from 'services/IcloudApi'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import {
  ChangeDocsByTypes,
  DocType,
  EventDoc,
  EventParticipantStatus,
  EventStatus,
  GroupDoc,
  TaskType
} from 'types/api/changes'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { isSomeDay } from 'utils/api/events'
import { deleteGoogleEvent, updateGoogleEvent } from 'utils/api/google_events'
import {
  deleteEvent,
  updateAttendeeStatus,
  updateEvent
} from 'utils/api/GraphService'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import {
  CurrentConvertedCalendars,
  googleEventToGroupcal,
  groupcalEventToGoogleEvent
} from 'utils/google_events'
import { groupIdToProvider, isConvertedGroupId } from 'utils/groups'
import { prepareDocForSaving } from 'utils/object'

import { genericUpdateFields } from './group'

export const API_EVENT_URL = '/tasks/edit/'
export const API_ADD_EVENT = '/tasks/add'
export const PARTICIPANT_STATUS = '/v1/eventsParticipantsStatus/batch'

export async function updateEventOnServer(
  event: Model<EventDoc> | EventDoc,
  data: EventDoc,
  ignoreLate?: boolean
) {
  Logger.pink('UpdateEvent:Data', data)
  Logger.pink('UpdateEvent:Event', event)

  LocalStorage.set(
    LocalStorageKey.FOCUS_EVENT,
    `${event._id}${event.StartDate}`
  )

  const startOfItemForServer =
    data.AllDay === '1'
      ? dayjs
          .utc(
            dayjs(Number(data.StartDate ?? event.StartDate) * 1000).format(
              'YYYY-MM-DD'
            )
          )
          .startOf('day')
          .unix()
          .toString()
      : event.lateOriginalStartTime && !ignoreLate
      ? event.lateOriginalStartTime
      : data.StartDate ?? event.StartDate

  const endOfItemForServer =
    data.AllDay === '1'
      ? dayjs
          .utc(
            dayjs(Number(data.EndDate ?? event.EndDate) * 1000).format(
              'YYYY-MM-DD'
            )
          )
          .add(1, 'day')
          .add(-1, 'second')
          .startOf('day')
          .unix()
          .toString()
      : event.lateOriginalEndTime && !ignoreLate
      ? event.lateOriginalEndTime
      : data.EndDate ?? event.EndDate

  const localDBData = Database.toServerObject(
    await localEventById(event._id ?? '')
  )
  let eventData: EventDoc = {
    ...localDBData,
    ...data,
    GroupID:
      is24meApp() && event.Type != DocType.REGULAR_EVENT
        ? undefined
        : event.GroupID,
    Status:
      data.Status && data.Status.length > 0 ? data.Status : EventStatus.ACTIVE,
    TaskType:
      data.TaskType && data.TaskType.length > 0 ? data.TaskType : TaskType.meet,
    Priority:
      (data.Priority && data.Priority?.length != 0) ||
      (event.Priority && event.Priority?.length != 0)
        ? data.Priority ?? event.Priority ?? '1'
        : '1',
    isDeleted: '0',
    Shared: 'null',
    Reminder:
      isGroupcalApp() && event.ThirdPartyID?.length === 0
        ? undefined
        : data.Reminder,
    Location:
      data.Location && Object.keys(data.Location).length > 0
        ? data.Location
        : undefined,
    TimeZoneNameID: data.AllDay === '1' ? 'UTC' : data.TimeZoneNameID,
    local_id:
      localDBData.local_id?.length === 0 ? undefined : localDBData.local_id,
    StartDate:
      isSomeDay(data) || localDBData.someday === '1'
        ? 'null'
        : startOfItemForServer,
    EndDate:
      isSomeDay(data) || localDBData.someday === '1'
        ? 'null'
        : endOfItemForServer
  }

  if (isGroupcalApp()) delete data['Label']

  eventData = {
    ...eventData,
    Reminder: setAlertTimeToReminders(
      Number(eventData.StartDate),
      eventData.Reminder
    )
  }

  Logger.pink('sending to server', eventData)

  if (eventData.Recurrence && Object.keys(eventData.Recurrence).length === 0)
    delete eventData['Recurrence']

  if (isConvertedGroupId(eventData.GroupID)) {
    // process google event
    const provider = groupIdToProvider(eventData.GroupID ?? '')
    if (provider === ProviderType.GOOGLE) {
      if (
        data.Status === EventStatus.DELETED ||
        event.Status === EventStatus.REMOVED
      ) {
        if (event.ThirdPartyID) {
          return deleteGoogleEvent(
            event.ThirdPartyID,
            event.local_id ?? ''
          ).then((result) => {
            if (result.status === 204) {
              Database.write(async () => {
                event.update(
                  (local: { Status: EventStatus }) =>
                    (local.Status = EventStatus.REMOVED)
                )
              })
            }
          })
        }
      } else {
        return updateGoogleEvent(
          event.ThirdPartyID && event.ThirdPartyID?.length > 0
            ? event.ThirdPartyID ?? ''
            : 'primary',
          groupcalEventToGoogleEvent(eventData)
        ).then((result) => {
          if (result.status === 200) {
            updateLocally({
              ...googleEventToGroupcal(result.data),
              OwnerID: eventData.OwnerID,
              GroupID: data.GroupID,
              ThirdPartyID: eventData.ThirdPartyID
            })
          }
        })
      }
    } else if (provider === ProviderType.OUTLOOK) {
      if (
        data.Status === EventStatus.DELETED ||
        event.Status === EventStatus.REMOVED
      ) {
        if (event.local_id) {
          return deleteEvent(event.local_id).then((result) => {
            Database.write(async () => {
              event.update(
                (local: { Status: EventStatus }) =>
                  (local.Status = EventStatus.REMOVED)
              )
            })
          })
        }
      } else {
        Logger.blue('Attendees', data.attendeesChanged)
        Logger.blue(
          'Attendees',
          data.attendees?.some(
            (attendee) => attendee.responseStatus === 'needsAction'
          )
        )

        if (data.attendeesChanged) {
          // we need to update status on remote server
          const newStatus =
            generalStatusToOutlook(
              data.attendees?.find((attendee) => attendee.self)?.responseStatus
            ) ?? ''
          Logger.pink('newStatus', newStatus)
          if (newStatus) {
            updateAttendeeStatus(newStatus, event._id ?? '')
          }
          return event
        }
        return tfmEventToOutlook(eventData).then((outlookEvent) => {
          updateEvent(event.local_id ?? '', outlookEvent).then(
            (outlookEvent) => {
              updateLocally({
                ...outlookEventToTfmEvent(outlookEvent),
                OwnerID: eventData.OwnerID,
                GroupID: data.GroupID,
                ThirdPartyID: eventData.ThirdPartyID
              })
            }
          )
        })
      }
    } else if (provider === ProviderType.APPLE) {
      const calendarAttendees = eventData.attendees?.map((attendee) => {
        return {
          Email: attendee.email,
          ResponseStatus:
            attendee?.responseStatus?.toUpperCase() &&
            attendee?.responseStatus?.toLowerCase()?.includes('needs')
              ? 'NEEDS-ACTION'
              : attendee?.responseStatus?.toUpperCase()
        }
      })
      eventData = {
        ...eventData,
        Attendees: calendarAttendees
      }

      const email = removePrefix(data.GroupID ?? '')

      if (
        data.Status === EventStatus.DELETED ||
        event.Status === EventStatus.REMOVED
      ) {
        // delete event
        IcloudApi.deleteCalendarItemFromIcloud({
          email: email,
          url: eventData.Url
        }).then((result) => {
          if (result.status === 200) {
            Database.write(async () => {
              event.update(
                (local: { Status: EventStatus }) =>
                  (local.Status = EventStatus.REMOVED)
              )
            })
          }
        })
      } else {
        // update event

        if (data.Location && Object.values(data.Location).length === 0) {
          delete data.Location
        }

        await IcloudApi.updateCalendarItemToIcloud({
          email: email,
          url: eventData.url || eventData.Url,
          etag: event.etag,
          event: eventData
        }).then((event) => {
          Logger.blue('ICLOUD:UpdatedEvent ', event)
        })
      }
    }
  } else {
    const response = await Api.post(`${API_EVENT_URL}${event._id}`, {
      ...genericUpdateFields(),
      ...eventData,
      late: 0,
      attendeesChanged: 0
    }).catch((error) => {
      Logger.pink('error', error)
      eventBus.publish(AppEvents.EventItemError, {
        name: AppEvents.EventItemError,
        data: {
          error: error
        }
      })
      return error
    })

    if (!response.data) return

    let eventFromServer: EventDoc = response.data['doc']

    if (!eventFromServer) return

    if (isGroupcalApp()) {
      eventFromServer = {
        ...eventFromServer,
        Reminder: data.Reminder
      }
    }

    return updateLocally(eventFromServer)
  }
}

async function updateLocally(eventFromServer: EventDoc) {
  const statusOfEvent = eventFromServer['Status']
  const priority = eventFromServer['Priority']
  const eventChangeDoc: Record<string, EventDoc> = prepareDocForSaving({
    ...eventFromServer,
    Status: String(statusOfEvent),
    Priority: String(priority),
    Location: eventFromServer['Location']
  })
  const docs: ChangeDocsByTypes = {
    Group: {},
    GroupEvent: eventChangeDoc,
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  if (isGroupcalApp() && !isConvertedGroupId(eventFromServer.GroupID)) {
    await setGroupSortOrder(eventFromServer.GroupID ?? '', dayjs().unix())
  }

  if (
    String(statusOfEvent) === EventStatus.DELETED ||
    String(statusOfEvent) === EventStatus.REMOVED
  ) {
    Analytics.getInstance().sendEvent(Events.ITEM_DELETED, {})
  }

  return await processDownloadedData(docs)
}

export interface UpdateParticipantStatusBody {
  //event id
  _id?: string
  ParticipantsStatus?: EventParticipantStatus
  DeviceChangeID?: string
}

export async function updateParticipantStatus(
  statuses: UpdateParticipantStatusBody[]
) {
  const response = await Api.post(`${PARTICIPANT_STATUS}`, {
    Events: statuses
  })

  const updatedStatuses: Record<string, EventDoc> = response.data

  const eventsWithStatuses: ChangeDocsByTypes = {
    Group: {},
    GroupEvent: updatedStatuses,
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }
  ChangesService.saveChanges(eventsWithStatuses)

  return updatedStatuses
}

export interface GuestGroupData {
  group: GroupDoc
  events: EventDoc[]
  recurrings: EventDoc[]
}

export async function getEventsForGroup(
  groupId: string,
  fromSeconds: number,
  toSeconds: number
) {
  const events = await Api.get(
    `/v1/groups/${groupId}/events?fromDate=${fromSeconds}&toDate=${toSeconds}`
  )

  const guestData = events.data as GuestGroupData

  const groupEvents: Record<string, EventDoc> = {}
  const groups: Record<string, GroupDoc> = {}
  groups[guestData.group._id ?? ''] = guestData.group

  guestData.events.forEach((event) => {
    groupEvents[event._id ?? ''] = event
  })

  guestData.recurrings.forEach((event) => {
    groupEvents[event._id ?? ''] = event
  })

  const eventsWithStatuses: ChangeDocsByTypes = {
    Group: groups,
    GroupEvent: groupEvents,
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }
  ChangesService.saveChanges(eventsWithStatuses)

  return events.data as GuestGroupData
}
