import { Model as BaseModel } from '@nozbe/watermelondb'
import ParticipantSuggestion from 'model/models/ParticipantSuggestion'

export enum CollectionType {
  PARTICIPANT = 'Participant',
  GROUP = 'Groups',
  EVENT = 'Events',
  ACCOUNT = 'Accounts',
  MASTER_LABEL = 'MasterLabels',
  PROFILE = 'Profiles',
  USER_SETTINGS = 'UserSettings',
  APP_CONTSTANTS = 'AppConstants',
  GoogleAccount = 'GoogleAccount',
  PARTICIPANT_SUGGESTION = 'ParticipantSuggestion'
}

export type Model<T> = T &
  BaseModel & {
    _id: string
    id: string
    _raw: T
  }
