import { styled, Theme } from '@mui/material/styles'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Popup } from 'components/popup/styled'
import { LazyTippy } from 'utils/tippy'

interface MiscTextProps {
  theme: Theme
  color?: any
  fontSize?: string
}

export const MiscText = styled(Typography)(
  ({ theme, color, fontSize }: MiscTextProps) => ({
    fontSize: fontSize ?? '14px',
    color: color ?? theme.palette.text.disabled,
    fontWeight: 200
  })
)

interface BlockContainerProps {
  loading: number
  theme: Theme
}

export const BlockContainer = styled(Grid)(
  ({ loading, theme }: BlockContainerProps) => ({
    padding: theme.spacing(0, 16),
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    pointerEvents: loading === 1 ? 'none' : 'all'
  })
)

export const PhoneTypePicker = styled(Box)<{
  selected?: boolean
}>(({ theme, selected }) => ({
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px',
  minWidth: '40px',
  width: '40px',
  height: '100%',
  cursor: 'pointer',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderLeft: '1px solid #B9B9B9',
  borderBottom: '1px solid #B9B9B9',
  borderTop: '1px solid #B9B9B9',
  background: selected ? '#fff' : '#F5F5F5',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',

  padding: theme.spacing(0, 2),

  '&:hover': {
    backgroundColor: '#fff'
  }
}))

export const PhoneTypeContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  cursor: 'pointer',
  gap: 16,
  marginTop: 8,
  padding: theme.spacing(0, 2),
  alignItems: 'flex-start',
  flexShrink: 0,
  '&:hover': {
    backgroundColor: '#f5f5f5'
  }
}))

export const PhoneTypeText = styled(Typography)(({ theme }) => ({
  color: '#000000',
  letterSpacing: '0.00938em',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'NotoSans',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.15625rem' /* 115.625% */
}))

export const PhoneTypeMisc = styled(PhoneTypeText)(({ theme }) => ({
  fontSize: '0.85rem',
  fontWeight: 300,
  lineHeight: '1.0625rem' /* 106.25% */
}))

export const PhoneTypePopup = styled(LazyTippy)(({ theme }) => ({
  backgroundColor: theme.palette.card.primary,
  width: 390,
  borderRadius: 0,
  padding: theme.spacing(0, 4),
  interactive: true,
  animation: undefined,

  boxShadow:
    '0px 8px 8px rgba(50, 50, 71, 0.2), 0px 4px 16px rgba(50, 50, 71, 0.2)',

  '& .tippy-arrow': {
    color: 'transparent'
  },

  '& .tippy-content': {
    padding: theme.spacing(4, 0)
  }
}))
