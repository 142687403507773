import { processLogout } from 'components/providers/AuthProvider'
import { doGetChanges } from 'components/providers/GetChangesProvider'
import Database from 'services/db/Database'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { CollectionType } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { getOrUpdateDeviceId } from 'utils/device'
import { isTestEnv } from 'utils/env'

class QA {
  public init() {
    if (!isTestEnv()) return
    window.QA = {
      auth: this.auth,
      clearLastUpdate: this.clearLastUpdate,
      debugLogger: this.debugLogger,
      logout: this.logout,
      clearDb: this.clearDb,
      log: this.log,
      logById: this.logById,
      cleanGetChanges: this.cleanGetChanges,
      forceReload: this.forceReload
    }
  }

  auth(password: string) {
    if (!isTestEnv()) return
    if (password === '123456789') {
      LocalStorage.set(
        LocalStorageKey.PASSWORD,
        '90180948e4ec4e439b03e9c067d6bff7'
      )
    }
  }

  logDeviceId() {
    if (!isTestEnv()) return
    Logger.blue('Device ID:', getOrUpdateDeviceId())
  }

  log(collectionType: CollectionType) {
    if (!isTestEnv()) return
    Database.getCollection(collectionType)
      .query()
      .fetch()
      .then((data) => {
        Logger.blue(`[COLLECTION ${collectionType}]`, data)
      })
  }

  logById(collectionType: CollectionType, id: string) {
    if (!isTestEnv()) return
    Database.getById(collectionType, id).then((doc) =>
      Logger.blue(`[ID ${id}]`, doc)
    )
  }

  clearLastUpdate() {
    if (!isTestEnv()) return
    LocalStorage.remove(LocalStorageKey.LAST_UPDATE)
  }

  cleanGetChanges() {
    if (!isTestEnv()) return
    LocalStorage.remove(LocalStorageKey.LAST_UPDATE)
    doGetChanges(null, undefined, null)
  }

  clearDb() {
    if (!isTestEnv()) return
    Database.clear().then(() => {
      Logger.red('[DB Cleared]')
    })
  }

  logout() {
    if (!isTestEnv()) return
    LocalStorage.clear()
    LocalStorage.set(
      LocalStorageKey.PASSWORD,
      '90180948e4ec4e439b03e9c067d6bff7'
    )
    window.location.reload()
    processLogout()
  }

  debugLogger(value: number) {
    if (!isTestEnv()) return
    LocalStorage.set(LocalStorageKey.DEBUG_LOGGER, value)
  }

  forceReload() {
    if (!isTestEnv()) return

    this.clearDb()
    this.clearLastUpdate()
    this.cleanGetChanges()
  }

  public isDebugLogger(): boolean {
    if (!isTestEnv()) return false
    return LocalStorage.get(LocalStorageKey.DEBUG_LOGGER) === '1'
  }
}

export default new QA()
