import { Box, ClickAwayListener, Grid, List, Radio } from '@mui/material'
import Switch from 'components/form/Switch'
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import Popup from 'components/popup/Popup'
import { theme } from 'theme'
import { SelectableItem } from './SelectItemWithSearchPopup'
import PopupSection from 'components/popup/PopupSection'
import { t, use } from 'i18next'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import { MiscText } from 'components/login/styled'
import { IEventDetails } from 'hooks/useEventDetails'
import { Tier, highestActiveTier } from 'utils/api/accounts'
import { TierFeatures, isUserCanOperateEvents } from 'utils/api/groups'
import i18n from 'i18n'
import { isConvertedGroup, isConvertedGroupId } from 'utils/groups'
import { AccountDoc, GroupDoc } from 'types/api/changes'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { set } from 'date-fns'
import { Model } from 'types/model'
import { CheckmarkIcon } from 'assets/icons'
import { removePrefix } from 'components/providers/CalendarSyncProdivder'
import { localGroupById } from 'services/db/Queries/GroupQueries'

export interface CalendarSectionItem {
  title: string
  items: SelectableItem[]
  sectionIcon?: ReactNode
  availableMultiMode?: boolean
}

interface CalendarSelectPopupInterface {
  calendars: CalendarSectionItem[]
  open?: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  eventHook: IEventDetails
  group: GroupDoc | undefined
  onCalendarPick: (calendar: SelectableItem) => void
  SupplementaryGroupsIDs: string[]
  account: Model<AccountDoc> | AccountDoc
}

function CalendarSelectPopupContent(props: CalendarSelectPopupInterface) {
  const [supplementaryMode, setSupplementaryMode] = useState(
    props.SupplementaryGroupsIDs.length > 0
  )

  const onSuppModeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSupplementaryMode(event.target.checked)
    if (!event.target.checked) {
      props.eventHook.setSupplementaryGroupIds([])
    }
  }
  const isUserAllowedToAddEvents = isUserCanOperateEvents(
    props.group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  )
  const convertedGroup = isConvertedGroupId(props.eventHook.groupId)
  const isSyncNotPossible = convertedGroup || !isUserAllowedToAddEvents
  const [itemGroup, setItemGroup] = useState<GroupDoc | undefined>(undefined)

  // Create a ref for the item you want to scroll to
  const itemRef = useRef(null)

  useEffect(() => {
    // Scroll to the item when the component mounts
    ;(itemRef.current as any)?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    })
  }, [])

  useEffect(() => {
    localGroupById(props.eventHook.groupId ?? '').then((group) => {
      if (!group) return
      setItemGroup(group)
    })
  }, [props.eventHook.groupId])
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (props.setOpen) props.setOpen(false)
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '450px'
        }}
      >
        <Grid container direction={'column'}>
          <Grid
            container
            direction={'column'}
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 2,
              position: 'sticky',
              background: 'white',
              zIndex: 10,
              top: 0
            }}
          >
            {isSyncNotPossible ? (
              <></>
            ) : (
              <div
                style={{
                  backgroundColor: 'rgb(241, 240, 245)',
                  borderRadius: 8,
                  padding: theme.spacing(1, 4)
                }}
              >
                <PopupSectionRow
                  title={t('syncEventsAcrossCalendars')}
                  neededTier={
                    itemGroup?.OwnerID ===
                    LocalStorage.get(LocalStorageKey.USER_ID)
                      ? Tier.BUSINESS_PLUS
                      : Tier.FREE
                  }
                  tierFeature={TierFeatures.SYNC_WITH_CALENDARS}
                  account={props.account}
                  value={
                    <Switch
                      onChange={onSuppModeChanged}
                      checked={supplementaryMode}
                    />
                  }
                />
                <MiscText
                  theme={theme}
                  fontSize={'13px'}
                  fontWeight={'400 !important'}
                  lineHeight={1.5}
                  letterSpacing={'0.02em'}
                >
                  {t('updatesToThisEvent')}
                </MiscText>
              </div>
            )}
          </Grid>
          <List
            sx={{
              width: '100%'
            }}
          >
            {props.calendars
              .filter((section) => {
                if (!supplementaryMode) return true

                return section.availableMultiMode
              })
              .map((item, index) => {
                return SectionWithCalendars(
                  item,
                  index,
                  props.SupplementaryGroupsIDs,
                  supplementaryMode ? undefined : props.onCalendarPick,
                  supplementaryMode,
                  props.eventHook,
                  isSyncNotPossible,
                  props.account,
                  itemGroup,
                  itemRef
                )
              })}
          </List>
        </Grid>
      </Box>
    </ClickAwayListener>
  )
}

function SectionWithCalendars(
  sectionItem: CalendarSectionItem,
  index: number,
  SupplementaryGroupsIDs: string[],
  onCalendarPick?: (item: SelectableItem) => void,
  multiMode?: boolean,
  eventHook?: IEventDetails,
  isSyncNotPossible?: boolean,
  account?: Model<AccountDoc> | AccountDoc,
  group?: GroupDoc,
  itemRef?: any
) {
  function addOrRemoveItem(item: SelectableItem) {
    if (
      group?.OwnerID === LocalStorage.get(LocalStorageKey.USER_ID) &&
      highestActiveTier(account) < Tier.BUSINESS_PLUS
    ) {
      return
    }

    if (item.notClickable === true) {
      return
    }

    if (!multiMode) return

    if (!SupplementaryGroupsIDs.includes(item.id ?? '')) {
      eventHook?.setSupplementaryGroupIds([
        ...SupplementaryGroupsIDs,
        item.id ?? ''
      ])
    } else {
      eventHook?.setSupplementaryGroupIds(
        SupplementaryGroupsIDs.filter((id) => id !== item.id)
      )
    }
  }

  return (
    <PopupSection
      key={sectionItem.title}
      title={sectionItem.title}
      titleIcon={sectionItem.sectionIcon}
    >
      <List
        sx={{
          width: '100%',
          paddingTop: 0
        }}
      >
        {sectionItem.items.map((item, index) => {
          return (
            <Grid
              ref={
                !multiMode &&
                (item.id === removePrefix(eventHook?.groupId ?? '') ||
                  item.id === eventHook?.thirdPartyId)
                  ? itemRef
                  : null
              }
              width={'100%'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  (item.notClickable && !multiMode && item.id !== group?._id) ||
                  (item.notClickable && multiMode)
                    ? 'rgba(0, 0, 0, 0.1)'
                    : '',
                padding: theme.spacing(0, 1),
                margin: theme.spacing(0.5, 0),
                borderRadius: '8px',
                minHeight: multiMode ? '52px' : '30px'
              }}
              key={item.id}
            >
              <Grid
                onClick={
                  item.notClickable
                    ? undefined
                    : multiMode
                    ? addOrRemoveItem.bind(null, item)
                    : onCalendarPick?.bind(null, item)
                }
                width={'100%'}
                display={'flex'}
                direction={'column'}
              >
                <Box display={'flex'} flexDirection={'row'} gap={1}>
                  {item.title}
                </Box>
                {multiMode ? (
                  <MiscText
                    fontSize={'13px'}
                    fontWeight={'400 !important'}
                    lineHeight={1.5}
                    letterSpacing={'0.02em'}
                    theme={theme}
                    color={item.subTitleColor ?? 'rgba(0, 0, 0, 0.4)'}
                  >
                    {item.notClickableReason
                      ? item.notClickableReason
                      : item.selected
                      ? t('tap_unselect')
                      : t('tap_select')}
                  </MiscText>
                ) : (
                  <></>
                )}
              </Grid>

              {isSyncNotPossible || !multiMode ? (
                item.id === eventHook?.thirdPartyId ? (
                  <CheckmarkIcon style={{ marginRight: 4 }} />
                ) : (
                  <></>
                )
              ) : multiMode && sectionItem.availableMultiMode ? (
                <Radio
                  sx={{ padding: 1 }}
                  checked={item.selected}
                  disabled={item.notClickable === true}
                  onClick={addOrRemoveItem.bind(null, item)}
                />
              ) : (
                <></>
              )}
            </Grid>
          )
        })}
      </List>
    </PopupSection>
  )
}

export default function CalendarSelectPopup(
  props: CalendarSelectPopupInterface
) {
  return (
    <Popup
      setOpen={props.setOpen}
      open={props.open}
      width="400px"
      content={<CalendarSelectPopupContent {...props} />}
      placement={'right'}
    >
      {props.children}
    </Popup>
  )
}
