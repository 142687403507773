import { t } from 'i18next'

export const GENERIC_ERROR = t('genericErrorBody')

export const SUPPORT_EMAIL = 'support@groupcal.app'
export const SUPPORT_EMAIL_TFM = 'support@twentyfour.me'

export function capitalizeFirstChar(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
