import 'tippy.js/dist/tippy.css'

import { ClickAwayListener } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

import * as Styled from './styled'

export interface PopupInstance {
  hide: () => void
}

interface PopupProps {
  children: ReactNode
  content: ReactNode
  className?: string
  placement?: 'bottom' | 'right-start' | 'right' | 'top' | 'left' | 'auto'
  appendTo?: 'parent'
  open?: boolean
  onMount?: (instance: PopupInstance) => void
  onKeyboardEnter?: () => void
  onKeyboardEsc?: () => void
  setOpen?: (b: boolean) => void
  onClickAwayAction?: () => void
  width?: string
  onHide?: () => void
}

export default function Popup(props: PopupProps) {
  const [tick, setTick] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTick((tick) => tick + 1)
    }, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (props.onKeyboardEnter) props.onKeyboardEnter()
      } else if (event.key === 'Escape') {
        if (props.onKeyboardEsc) props.onKeyboardEsc()
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [props.open])

  return (
    // @ts-ignore
    <Styled.Popup
      sx={{ width: props.width ?? '390px' }}
      interactive
      visible={props.open}
      onHide={() => {
        if (props.onHide) props.onHide()
      }}
      trigger={typeof props.open === 'boolean' ? undefined : 'click'}
      placement={props.placement || 'auto'}
      className={props.className}
      appendTo={props.appendTo || (() => document.body)}
      content={
        <ContentContainer
          onClickAwayAction={props.onClickAwayAction}
          content={props.content}
          open={props.open ?? false}
          setOpen={props.setOpen}
        />
      }
      onMount={props.onMount}
    >
      {props.children as any}
    </Styled.Popup>
  )
}

interface ContentContainerProps {
  setOpen?: (b: boolean) => void
  open: boolean
  content: ReactNode
  onClickAwayAction?: () => void
}

function ContentContainer(props: ContentContainerProps) {
  return (
    <ClickAwayListener
      onClickAway={() => {
        const shouldKeepPopup =
          LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) ?? '0'

        if (shouldKeepPopup === '0') {
          if (props.setOpen) props.setOpen(false)
          if (props.onClickAwayAction) props.onClickAwayAction()
        }
      }}
    >
      <div>{props.content}</div>
    </ClickAwayListener>
  )
}
