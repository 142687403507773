import { Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Avatar from 'components/badges/Avatar'
import IconButton from 'components/buttons/IconButton'
import Input from 'components/form/Input'
import Popup from 'components/popup/Popup'
import i18n from 'i18n'

export const EventPopupHead = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  top: '0px',
  zIndex: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(0, 4),
  paddingBottom: 8,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  background: 'white'
}))

export const SearchPopup = styled(Popup)(({ theme }) => ({
  '& .tippy-content': {
    padding: theme.spacing(0, 0, 0, 0),
    marginTop: '16px'
  }
}))

export const EventPopupInputContainer = styled(Grid)(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 4,
  marginTop: '8px',
  display: 'flex',
  alignItems: 'center'
}))

export const PopupCloseButton = styled(IconButton)(() => ({
  '& svg': {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24
  }
}))

export const PopupInput = styled(Input)(() => ({
  maxWidth: 'calc(100% - 20px)'
}))

export const ColorsPopup = styled(Popup)(() => ({
  width: 160
}))

export const ColorsPopupSelector = styled('div')(({ color }) => ({
  width: 20,
  height: 20,
  borderRadius: 9999,
  backgroundColor: color,
  cursor: 'pointer'
}))

export const ColorsPopupContent = styled(Grid)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '24px 24px 24px 24px',
  gap: '0.5rem'
}))

export const ColorsPopupColor = styled(Grid)(() => ({
  width: 24,
  height: 24,
  borderRadius: 9999,
  cursor: 'pointer'
}))

export const GroupPopupAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(0, 3, 0, 0)
}))

export const GroupPopupAvatarContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative'
}))

export const GroupPopupCloseButton = styled(PopupCloseButton)(() => ({
  position: 'absolute',

  top: -10
}))

export const GroupPopupInputContainer = styled(Grid)(() => ({
  marginLeft: 4,
  maxWidth: 'calc(100% - 80px)'
}))

export const GroupPopupInputCount = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 32,
  fontSize: '0.625rem',
  textAlign: 'end',
  fontWeight: 400,
  color: theme.palette.input.placeholder
}))

export const GroupPopupInput = styled(Input)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey['100'],
  width: '100%',
  '& .Input__Input': {
    fontSize: '1.125rem',
    paddingRight: '0px !important',
    fontWeight: 400,
    padding: theme.spacing(0, 8, 0, 1),
    backgroundColor: theme.palette.grey['100'],
    borderRadius: 6,
    boxShadow: 'none',
    border: 0
  }
}))

export const GroupPopupName = styled(Grid)(({ theme }) => ({
  position: 'relative',
  fontSize: '1.125rem',
  fontWeight: 400,
  padding: theme.spacing(0, 1)
}))

export const GroupNamePencilButton = styled(IconButton)(() => ({
  top: -15,
  '& svg': {
    width: 11,
    height: 11,
    minWidth: 11,
    minHeight: 11
  }
}))

export const GroupBusinessInfoPencilButton = styled(GroupNamePencilButton)(
  () => ({
    position: 'absolute',
    top: -36,
    right: i18n.dir() === 'ltr' ? '-12px !important' : undefined,
    left: i18n.dir() === 'ltr' ? undefined : '-12px !important',
    '& svg': {
      width: 11,
      height: 11,
      minWidth: 11,
      minHeight: 11
    }
  })
)

export const GroupPopupInputButton = styled(IconButton)(({ theme }) => ({
  width: 28,
  height: 28,
  right: 4,
  '& svg': {
    width: 14,
    minWidth: 14,
    color: theme.palette.success.main
  }
}))

export const AddGroupPopup = styled(Popup)(({ theme }) => ({
  width: '120%',
  maxWidth: '370px !important',
  '& .tippy-arrow': {
    transform: 'translate3d(0px, 25px, 0px) !important'
  }
}))

export const AddGroupInputContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative'
}))

export const PopupRowTitle = styled('div')(({ theme }) => ({
  height: 20,
  fontSize: 14,
  fontWeight: 400,
  cursor: 'pointer',
  color: theme.palette.text.primary
}))

export const AddGroupPhoto = styled(Grid)(({ theme }) => ({
  width: 42,
  height: 42,
  minWidth: 42,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 9999,
  border: '1px solid',
  borderColor: theme.palette.button.text.link,
  color: theme.palette.button.text.link
}))

export const AddGroupInput = styled(Input)(() => ({
  marginLeft: 8,
  '& .Input__Input': {
    fontSize: '1.125rem'
  }
}))

export const UserMenuPopup = styled(Popup)(() => ({
  width: 'calc(100% + 32px)'
}))

export const DatePickPopup = styled(Popup)(() => ({
  width: '100%'
}))

export const TimezonePickPopup = styled(Popup)(() => ({
  maxHeight: '200px'
}))

export const SmallTextField = styled(TextField)(() => ({
  width: '20px',
  fontSize: '0.75rem',
  fontWeight: 400,
  color: 'black',
  textAlign: 'center',
  '& .MuiInput-input': {
    padding: 0,
    boxSizing: 'none',
    borderColor: 'transparent'
  }
}))

export const TextButton = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.button.text.link,
  '&:hover': {
    opacity: 0.75
  }
}))

export const GroupPopup = styled(Popup)(({ theme }) => ({
  minWidth: '400px'
}))

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInput-input': {
    cursor: 'pointer',
    borderColor: 'transparent',
    padding: 0,

    marginTop: 4,
    marginLeft: 4,
    marginRight: 4,
    boxSizing: 'none'
  }
}))

export const StyledInput = styled(Input)(() => ({
  '& .Input__Input': {
    fontSize: 14,
    padding: 0,
    boxShadow: 'none',
    border: 0
  }
}))
