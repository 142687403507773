import { GroupStatus, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'

export function isGroupMuted(
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined,
  groupID: string
): boolean {
  return userSettings?.GroupsSettings?.[groupID]?.ActivateReminders === '0'
}

export function isGroupDefault(
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined,
  groupID: string
): boolean {
  return userSettings?.GroupsSettings?.[groupID]?.Default === '1'
}

export function groupUnread(
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined,
  groupID: string
): number {
  const unreadMap = userSettings?.GroupsSettings?.[groupID]?.UnreadItems

  return (unreadMap && Object.keys(unreadMap).length) ?? 0
}

export function isGroupHidden(
  userSettings: UserSettingsDoc | undefined | UserSettingsDoc,
  groupID: string
): boolean {
  return userSettings?.GroupsSettings?.[groupID]?.ShowOnAllCalendars === '0'
}

export function isGroupUnread(
  userSettings: Model<UserSettingsDoc> | undefined | UserSettingsDoc,
  groupID: string
): boolean {
  return userSettings?.GroupsSettings?.[groupID]?.Unread === '1'
}

export function isGroupArchived(
  userSettings: Model<UserSettingsDoc> | undefined | UserSettingsDoc,
  groupID: string
): boolean {
  return (
    userSettings?.GroupsSettings?.[groupID]?.GroupStatus ===
    GroupStatus.ARCHIVED
  )
}

export function getHiddenGroup(userSettings: Model<UserSettingsDoc>): string[] {
  if (userSettings == null) return []
  return Object.keys(userSettings.GroupsSettings ?? {}).filter((id) =>
    isGroupHidden(userSettings, id)
  )
}

export function getArchivedGroups(
  userSettings: Model<UserSettingsDoc>
): string[] {
  if (userSettings == null) return []
  return Object.keys(userSettings.GroupsSettings ?? {}).filter((id) =>
    isGroupArchived(userSettings, id)
  )
}
