import { Query } from '@nozbe/watermelondb'
import { ConferenceData } from 'types/google_events'
import { Model } from 'types/model'

export enum DocType {
  GROUP = 'Group',
  EVENT = 'GroupEvent',
  TASK = 'Task',
  NOTE = 'Note',
  ACCOUNT = 'Account',
  MASTER_LABEL = 'MasterLabel',
  PROFILE = 'Profile',
  USER_SETTINGS = 'UserSettings',
  REGULAR_EVENT = 'REGULAR_EVENT'
}

export interface GetChangesResponse {
  hasMore: boolean
  now: number
  results: ChangeDocResponse[]
}

export interface ChangeDocResponse {
  doc: ChangeDoc
}

export type ChangeDocsByTypes = Record<DocType, Record<string, ChangeDoc>>

export interface ChangeDoc extends Record<string, any> {
  _id?: string
  _rev?: string
  UserID?: string
  LastUpdate?: string
  DeviceChangeID?: string
  Type?: DocType
}

export enum GroupParticipantType {
  REGULAR = '1',
  ADMIN = '2'
}

export enum GroupParticipantStatus {
  ACTIVE = '1',
  INACTIVE = '2',
  EXITED = 'exited'
}

export interface GroupParticipant {
  JoinDate: string
  Status: GroupParticipantStatus
  Type: GroupParticipantType
  RemovedBy: string
  InvitedBy: string
  MemberColor: string
}

export interface GroupSettings {
  IsAllParticipantsCanAddItems: string
  IsAllParticipantsCanAddParticipants: string
  IsAllParticipantsCanEditGroupMetadata: string
}

export interface GroupBusinessInfo {
  Text: string
  Active: string
}

export interface GroupDoc extends ChangeDoc {
  CreationDate: string
  GroupColor: string
  GroupSettings: GroupSettings
  Name: string
  OwnerID: string
  Participants: Record<string, GroupParticipant>
  PendingParticipants: Record<string, GroupParticipant>
  Photo: string
  PrivacyMode: string
  PrivateLinkPassword: string
  PrivateLinkUrl: string
  BusinessInfo: GroupBusinessInfo | undefined
  Status: string
  LastUpdateEvent: Query<Model<EventDoc>> | undefined
  Events: Query<Model<EventDoc>> | undefined
  Version: string
  VerifiedGroupStatus: string
  SyncProblem: string | undefined | null
  _message: string
  _sortOrder: number
}

export interface EventNote {
  FilePath: string
  Note: string
  NoteID: number
  Status: number
  FileBytes?: string
}

export enum ParticipantDeliveryStatusType {
  DELIVERED = '9',
  READ = '13'
}

export interface EventParticipantStatus {
  LastUpdate?: string
  // 1 - pending
  // 2 - accepted
  // 3 - declined
  // 4 - maybe
  ParticipantConfirmationStatus?: string
  // DELIVERED = "9"
  // READ = "13"
  ParticipantDeliveryStatus?: string
}

export interface EventReminder {
  AlertTime: string
  isOn: string
  isRelativeReminder: string
  offset: number
}

export enum AggregatedDeliveryStatus {
  DELIVERED = '9',
  READ = '13'
}

export enum EventPriority {
  LOW = '1',
  HIGH = '2'
}

export enum EventStatus {
  ACTIVE = '1',
  COMPLETED = '2',
  REMOVED = '3',
  DELETED = '4',
  HARD_DELETE_FROM_DB = '5'
}

export enum TaskType {
  call = '6',
  text = '7',
  email = '8',
  gift = '10',
  meet = '1',
  hotel = '13',
  googleTask = '14',
  birthday = '3'
}

export enum RECURRENCE_UNIT {
  daily = 1,
  weekly = 2,
  monthly = 3,
  yearly = 4
}

export interface EventRecurrence {
  Unit?: number
  Interval?: number
  Exclusion?: Exclusion[]
  EndDate?: string | null
}

export interface Exclusion {
  Date: string
  Status: EventStatus
}

export interface ContactDetails {
  data: ContactData[]
  Name: string
}

export interface ContactData {
  Email: string
  PhoneNumber: string
  GroupName: string
}

export interface EventData extends Omit<Model<EventDoc>, keyof EventDoc> {}

export interface EventDoc extends ChangeDoc {
  id?: string
  GroupID?: string
  AllDay?: string
  BirthdayDetails?: string
  ContactDetails?: ContactDetails
  EndDate?: string
  Job?: string
  Label?: SimpleLabel[]
  Location?: EventLocation
  Notes?: EventNote[]
  ObjectType?: string
  OpenDate?: string
  OwnerID?: string
  ParentTaskID?: string // used also in converted recurrent events to store original event id
  ParticipantsStatus?: Record<string, EventParticipantStatus>
  PostStatus?: string
  Priority?: string
  PrivacyStatus?: string
  Rank?: string
  Recurrence?: EventRecurrence
  Reminder?: EventReminder[]
  RequestConfirmation?: string
  Shared?: string
  someday?: string
  StartDate?: string
  Status?: EventStatus
  TaskType?: TaskType
  Text?: string
  ThirdPartyID?: string
  TimeZoneNameID?: string
  LocationReminders?: LocationReminder[]
  Color?: string
  isDeleted?: string
  local_id?: string
  _message?: string
  SupplementaryGroupsIDs?: string[]
  AggregatedParticipantsDeliveryStatus?: string
  AggregatedParticipantsConfirmationStatus?: string
  Group?: Query<Model<GroupDoc>>
  updateTitle?: (title: string) => Promise<any>
  ConferenceData?: ConferenceData
  attendees?: Attendee[]
  canAddAttendees?: string
  late?: boolean
  lateOriginalStartTime?: string
  lateOriginalEndTime?: string
  attendeesChanged?: boolean
  etag?: string
  Url?: string
  CustomUrl?: string
  Attachments?: Attachment[]
  Organizer?: Organizer
}

export interface Organizer {
  id?: string
  name?: string
  email?: string
}

export interface Attachment {
  FileName?: string
  MimeType?: string
  FileSize?: number
  Url?: string
}

export interface LocationReminder {
  id: string
  radius: number
  lenType: '1' | '2' | '3' | '4' // Assuming these are the only valid values based on the provided comments
  departureEnabled?: boolean
  approachEnabled?: boolean
}

export interface SimpleLabel {
  LabelText: string
}

export interface Attendee {
  id?: string
  email?: string
  displayName?: string
  organizer?: boolean
  self?: boolean
  resource?: boolean
  optional?: boolean
  responseStatus?: string
  comment?: string
  additionalGuests?: number
}

export interface EventLocation {
  Address?: string
  Long?: string
  Lat?: string
  FilteredLocation?: string
}

export interface AccountProduct {
  Name: string
  ID: string
  ExpiryDate?: string
  CheckoutId?: string
  Status?: 'cancelled' | 'trialing' | 'active' | 'paused'
  SubscriptionId?: number
  Store: string
}
export interface PaddleCheckout {
  UserID: string
  checkout: {
    created_at: string
    completed: boolean
    subscriptionId: number
    id: string
  }
  product: {
    quantity: number
    id: number
    name: string
  }
  user: {
    id: string
    email: string
    country: string
  }
}

export interface CheckoutPayload {
  UserID: string
  SubscriptionId: number
  CheckoutId: number
}

export interface AccountDevice {
  AppID: string // 24me or groupcal
  DeviceType: string
  DeviceUUID: string
  Token?: string
  DevicesID: string // push token
}

export interface AccountName {
  FirstName: string
  FullName: string
  LastName: string
}

export interface AccountUserType {
  GivenCredits: number
  UsedCredits: number
  UserType: string
}

export interface AccountDoc extends ChangeDoc {
  AccessToken: string
  AppleID: string
  Devices: AccountDevice[]
  Email: string
  FacebookID: string
  Name: AccountName
  Products: AccountProduct[]
  Password: string
  PhoneNumber: string
  PhoneNumberType: string
  TokenExpires: string
  UserType: AccountUserType
  groupcalActivatedDate: string
  isDeleted: boolean
  ConnectedCalAccounts: Record<string, string>
}

export interface ConnectedCalAccount {}

export interface MasterLabelLabel {
  Color: string
  ID: string
  Text: string
}

export interface MasterLabelDoc extends ChangeDoc {
  Labels: MasterLabelLabel[]
}

export interface ProfileDoc extends ChangeDoc {
  ActiveProviders: string
  DataProviders: Record<string, any>
  DeviceChangeID: string
  Facebook: string
  Newsletters: string
  Notifications: string
  ProfilePicture: string
  isDeleted: string
}

export interface UserSettingsDevice {
  Badge: string
  DeviceID: string
  GroupByTime: number
  Passcode: string
  ShowBirthdays: string
  SoundEffects: string
  Themes: string
  WelcomeAnimation: string
}

export interface UserSettingsGroupSettings {
  GroupColor?: string
  ShowOnAllCalendars?: string
  ActivateReminders?: string
  UnreadItems?: Record<string, string>
  GroupStatus?: GroupStatus
  Unread?: string
  Default?: string
}

export interface UserSettingsDoc extends ChangeDoc {
  DeviceChangeID: string
  FirstDayOfTheWeek: number
  Gender: string
  GroupsSettings: Record<string, UserSettingsGroupSettings>
  Language: string
  Locale: string
  ShowBirthdays: string
  TimeZone: string
  TimeZoneOffsetInSecFromGMT: string
}

export interface ParticipantDataDoc {
  AccountID: string
  FullName: string
  LastUpdate: string
  PhoneNumber: string
  PhotoURL: string
}

export enum GroupStatus {
  ARCHIVED = '2',
  ACTIVE = '1'
}
