export enum AppEvents {
  EventItemError = 'event-item-error'
}

export interface EventData {
  name: string
  data: any
}

class EventBus {
  private listeners: { [key: string]: Function[] } = {}

  public subscribe(event: string, callback: (data: EventData) => void) {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push(callback)
  }

  public unsubscribe(event: string, callback: (data: EventData) => void) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (listener) => listener !== callback
      )
    }
  }

  public publish(event: string, data: EventData) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data))
    }
  }
}

const eventBus = new EventBus()

export default eventBus
