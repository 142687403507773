import { Grid, styled } from '@mui/material'
import Box from '@mui/material/Box'
import { theme } from 'theme'

export const AppTitleContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(8, 16),
  alignItems: 'center',
  columnGap: 4,
  fontWeight: 'lighter',
  fontSize: 32
}))

export const GenericErrorContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 16,
  paddingRight: 16,
  rowGap: 8
}))

export const RefreshPageButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  columnGap: 2
}))
