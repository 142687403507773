// NotificationProvider.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

interface NotificationContextType {
  showNotification: (
    message: string,
    severity?: 'error' | 'warning' | 'info' | 'success'
  ) => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    )
  }
  return context
}

interface NotificationProviderProps {
  children: ReactNode
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children
}) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<
    'error' | 'warning' | 'info' | 'success'
  >('info')

  const showNotification = (
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success' = 'info'
  ) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose as any}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position at bottom-center
        sx={{ marginBottom: '20px', zIndex: 9999 }} // Adjust margin from the bottom
      >
        <Alert severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  )
}
