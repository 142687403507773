import LoginPage, { PhoneType } from 'components/login/LoginPage'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Database from 'services/db/Database'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { doGetChanges, joinGroupIfNeeded } from './GetChangesProvider'
import GuestMode from 'components/guest/GuestMode'
import { isTestEnv } from 'utils/env'
import { getOrUpdateDeviceId } from 'utils/device'
import Analytics, { Events } from 'analytics/Analytics'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { t } from 'i18next'
import { APP_COLOR } from 'utils/colors'
import Logger from 'services/Logger'
import { on } from 'events'
import { onLog } from 'firebase/app'
import dayjs from 'dayjs'

export enum CodeProvider {
  Firebase = 'firebase',
  Checkmobi = 'checkmobi'
}

interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextProps {
  onLogout: () => Promise<void> | undefined
  isAuth: boolean
  firstGetChangesFinished: boolean
  phoneType: PhoneType
  onPhoneType: (phoneType: PhoneType) => void
  codeProvider: CodeProvider
  onCodeProvider: (codeProvider: CodeProvider) => void
}

const AuthContext = createContext<AuthContextProps>({
  isAuth: false,
  firstGetChangesFinished: false,
  onLogout: function (): Promise<void> | undefined {
    return undefined
  },
  phoneType: PhoneType.Mobile,
  onPhoneType: () => {},
  codeProvider: CodeProvider.Firebase,
  onCodeProvider: () => {}
})

export default function AuthProvider(props: AuthProviderProps): JSX.Element {
  const [phoneType, onPhoneType] = useState<PhoneType>(PhoneType.Mobile)
  const [firstGetChangesFinished, onFirstGetChangesFinished] = useState(false)
  const [isAuth, onAuth] = useState(
    !!LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
  )

  const params = useParams()
  const [searchParams, onSearchParams] = useSearchParams()
  const [codeProvider, onCodeProvider] = useState<CodeProvider>(
    CodeProvider.Firebase
  )

  const compositeGroupIdAndPass = params.groupIdAndPass

  Logger.pink('Link:', compositeGroupIdAndPass)

  const groupId = compositeGroupIdAndPass?.split('⁠')[0]
  const password = compositeGroupIdAndPass?.split('⁠')[1]

  Analytics.getInstance({
    amplitudeDeviceId: searchParams.get('aid'),
    singularDeviceId: searchParams.get('sid'),
    clevertapDeviceId: searchParams.get('cid')
  })
  if (groupId && password) {
    /**
     * This happens only when user pressed on a dynamic link to join/lookup a group
     * we need to cache this id in case of user not logged in
     * or launch join group in case of user logged in
     */

    LocalStorage.set(LocalStorageKey.CACHED_GROUP_ID_JOIN, groupId)
    LocalStorage.set(LocalStorageKey.CACHED_GROUP_PASSWORD, password)

    // if user already logged in, we need to try join group
    if (LocalStorage.get(LocalStorageKey.USER_ID)) {
      joinGroupIfNeeded()
    }
  }

  function setCodeProvider(provider: CodeProvider) {
    onCodeProvider(provider)
    LocalStorage.set(LocalStorageKey.CODE_PROVIDER, provider)
  }

  useEffect(() => {
    if (isAuth) {
      doGetChanges(
        LocalStorage.get(LocalStorageKey.CLEAN_GET_CHANGES_DONE)
          ? LocalStorage.get(LocalStorageKey.DEVICE_ID)
          : null,
        undefined,
        LocalStorage.get(LocalStorageKey.LAST_UPDATE)
      ).then(() => {
        LocalStorage.set(LocalStorageKey.CLEAN_GET_CHANGES_DONE, true)
        onFirstGetChangesFinished(true)
        LocalStorage.set(
          LocalStorageKey.VISIBLE_FROM,
          dayjs().subtract(14, 'day').toISOString()
        )
        LocalStorage.set(
          LocalStorageKey.VISIBLE_TO,
          dayjs().add(14, 'day').toISOString()
        )

        if (!LocalStorage.get(LocalStorageKey.SAW_MAIN_SCREEN) && isAuth) {
          LocalStorage.set(LocalStorageKey.SAW_MAIN_SCREEN, 1)
          Analytics.getInstance().sendEvent(Events.FIRST_MAIN_SCREEN)
        }
      })
      // TODO: Handle failures of getchanges here otherwise the app is useless if this request doesn't succeed.
      // We should make sure the user has a way to retry failures from this request as well.
    }
  }, [isAuth])

  const onLogout = async () => {
    const logout = await processLogout()
    onPhoneType(PhoneType.Mobile)
    onCodeProvider(CodeProvider.Firebase)
    onAuth(false)
    onFirstGetChangesFinished(false)

    return logout
  }

  window.processLogout = onLogout

  const [showLandlineDesc, setShowLandlineDesc] = useState(false)

  useEffect(() => {
    setShowLandlineDesc(phoneType === PhoneType.Landline)
  }, [phoneType])

  if (window.self !== window.top && groupId && groupId.length > 0) {
    return <GuestMode groupId={groupId} />
  }

  const children =
    !isAuth && window.self === window.top ? (
      groupId && password ? (
        <GuestMode groupId={groupId} />
      ) : (
        <LoginPage isAuth={isAuth} onAuth={onAuth} />
      )
    ) : (
      (props.children as JSX.Element)
    )
  return (
    <AuthContext.Provider
      value={{
        onLogout,
        isAuth,
        firstGetChangesFinished,
        phoneType,
        onPhoneType,
        codeProvider,
        onCodeProvider: setCodeProvider
      }}
    >
      <>
        {children}
        <ConfirmationDialog
          handleClose={() => setShowLandlineDesc(false)}
          handleLeftButton={() => {
            onPhoneType(PhoneType.Mobile)
            setShowLandlineDesc(false)
          }}
          handleRightButton={() => setShowLandlineDesc(false)}
          title={t('signingWithLandline')}
          content={t('signingWithLandlineDesc')}
          open={showLandlineDesc}
          rightButton={`${t('continue')}`}
          leftButtonColor="black"
          rightButtonColor={APP_COLOR}
        />
      </>
    </AuthContext.Provider>
  )
}

export async function processLogout() {
  const tfmPass = LocalStorage.get(LocalStorageKey.PASSWORD)
  const deviceuuid = getOrUpdateDeviceId()
  const expiryState = LocalStorage.get(LocalStorageKey.EXPIRY_DIALOG_SHOWN)
  const lang = LocalStorage.get('i18nextLng')
  LocalStorage.remove(LocalStorageKey.USER_ID)
  LocalStorage.clear()
  LocalStorage.set('i18nextLng', lang)
  LocalStorage.set(LocalStorageKey.DEVICE_ID, deviceuuid)
  LocalStorage.set(LocalStorageKey.EXPIRY_DIALOG_SHOWN, expiryState)
  if (tfmPass && isTestEnv()) {
    LocalStorage.set(LocalStorageKey.PASSWORD, tfmPass)
    if (!LocalStorage.get(LocalStorageKey.SHOW_DEV_INFO)) {
      LocalStorage.set(LocalStorageKey.SHOW_DEV_INFO, 1)
    }
  }

  //clear db with a delay
  setTimeout(() => {
    Database.clear().then(() => {
      {
        console.log('Database cleared')
      }
    })
  }, 1000)

  Analytics.getInstance().handleLogout()
}

export function useAuth(): AuthContextProps {
  return useContext(AuthContext)
}
