import { IEventDetails } from 'hooks/useEventDetails'
import { ReactNode, useEffect, useState, useRef, createRef } from 'react'
import { EventDoc, EventNote } from 'types/api/changes'
import { Model } from 'types/model'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Box, ClickAwayListener } from '@mui/material'

import * as Styled from '../styled'
import Button from 'components/buttons/Button'
import { t } from 'i18next'
import { CloseIcon } from 'assets/icons'
import { theme } from 'theme'

import './styles.scss'
import { PopupCloseButton } from 'components/popups/styled'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { useFilePickerContext } from 'components/providers/FilePickProvider'
import { compressBase64, getStringSizeInKB } from 'utils/file'
import { replace } from 'stylis'

export interface NoteDetailsPopupProps {
  children: ReactNode
  event: Model<EventDoc> | EventDoc
  eventHook: IEventDetails
  open: boolean
  setOpen: (b: boolean) => void
  note?: EventNote
}

export default function NoteDetailsPopup(props: NoteDetailsPopupProps) {
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],

        ['link', 'image'],
        ['clean']
      ]
    }
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]

  const [value, setValue] = useState(props.note?.Note)

  const closePopup = () => {
    if (
      initialNote?.replace(/<\/?[^>]+(>|$)/g, '') &&
      value?.replace(/<\/?[^>]+(>|$)/g, '') !=
        initialNote.replace(/<\/?[^>]+(>|$)/g, '')
    ) {
      props.eventHook.setOpenDiscard(true)
    } else {
      props.setOpen(false)
      LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '0')
    }
  }

  const quillRef = createRef<ReactQuill>()

  const editor = quillRef.current?.getEditor()
  const unprivilegedEditor = quillRef.current?.makeUnprivilegedEditor(
    editor as any
  )

  const [initialNote, setInitialNote] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!initialNote) {
      setInitialNote(unprivilegedEditor?.getHTML())
    }
  }, [unprivilegedEditor?.getHTML()])

  setTimeout(() => {
    quillRef.current?.focus()
  }, 200)

  async function compressImageIfNeeded(val: string) {
    let result = val

    const regex = /<img[^>]*src="(data:image\/[^;]*;base64,[^"]*)"[^>]*>/g

    const base64Data = []
    let match

    while ((match = regex.exec(val)) !== null) {
      base64Data.push(match[1])
    }

    for (let i = 0; i < base64Data.length; i++) {
      const imgData = base64Data[i]
      const compressedData = await compressBase64(imgData)

      result = result.replace(
        imgData,
        `${imgData.split(',')[0]},${compressedData}`
      )
    }

    return result
  }

  return (
    <Styled.NoteDetailsPopup
      open={props.open}
      setOpen={props.setOpen}
      content={
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            closePopup()
          }}
        >
          <div style={{ padding: theme.spacing(0, 4) }}>
            <Box
              paddingBottom={2}
              display={'flex'}
              flexDirection={'row'}
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <PopupCloseButton type={'button'} size="sm" onClick={closePopup}>
                <CloseIcon />
              </PopupCloseButton>
              <Button
                onClick={() => {
                  if (props.note) {
                    props.eventHook.updateNote({
                      ...props.note,
                      Note: value ?? ''
                    })
                    props.setOpen(false)
                  } else {
                    props.eventHook.addNote(value ?? '')
                    props.setOpen(false)
                  }
                }}
                size="xs"
              >
                {t('save')}
              </Button>
            </Box>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '16px',
                maxHeight: '500px',
                overflowY: 'scroll',
                position: 'relative'
              }}
            >
              <ReactQuill
                ref={quillRef}
                value={value}
                className="tfm-quill"
                theme="snow"
                style={{ color: 'black' }}
                formats={formats}
                modules={modules}
                onChange={(val) => {
                  compressImageIfNeeded(val).then((compressedImageHTML) => {
                    setValue(compressedImageHTML)
                  })

                  if ((removeHTMLFromText(val)?.length ?? 0) > 0)
                    LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')

                  props.eventHook.setNoteText(val)
                }}
              />
            </div>
          </div>
        </ClickAwayListener>
      }
    >
      {props.children}
    </Styled.NoteDetailsPopup>
  )
}

export function removeHTMLFromText(text?: string) {
  return text?.replace(/<\/?[^>]+(>|$)/g, '')
}

function extractBase64FromImgTag(html: string): string | null {
  const regex = /<img[^>]*src="(data:image\/[^;]*;base64,[^"]*)"[^>]*>/
  const match = html.match(regex)
  return match ? match[1] : null
}

function replaceImgSrc(html: string, newBase64: string): string {
  const regex = /(<img[^>]*src="data:image\/[^;]*;base64,)[^"]*("[^>]*>)/
  return html.replace(regex, `$1${newBase64}$2`)
}
