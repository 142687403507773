import { FIRST_DAYS } from 'components/calendar/CalendarView'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { t } from 'i18next'
import { getDefaultTimezone, setDefaultTimezone } from 'index'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import eventBus, { AppEvents, EventData } from 'services/EventBus'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import { LocalStorageKey } from 'types/services/localStorage'

import { GENERIC_ERROR } from 'utils/strings'

interface AppSettingsProviderProps {
  children: ReactNode
}

interface AppSettingsContextProps {
  firstDayOfTheWeek: number
  setFirstDayOfTheWeek: (n: number) => void
  timeZone: string
  setTimeZone: (tz: string) => void
}

export default function AppSettingsProvider(props: AppSettingsProviderProps) {
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState(1)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [timeZone, setTimeZone] = useState(getDefaultTimezone())

  useEffect(() => {
    setDefaultTimezone(timeZone)
    Logger.pink('APP SETTINGS:TZ', getDefaultTimezone())
  }, [timeZone])

  useEffect(() => {
    const callback = (event: EventData) => {
      console.log(event)
      if (event.name === AppEvents.EventItemError) {
        setIsErrorDialogOpen(true)
      }
    }

    eventBus.subscribe(AppEvents.EventItemError, callback)

    return () => {
      eventBus.unsubscribe(AppEvents.EventItemError, callback)
    }
  }, [])

  useEffect(() => {
    if (LocalStorage.get(LocalStorageKey.USER_ID)) {
      UserSettings().then((settings) => {
        if (!settings || settings === null) {
          setFirstDayOfTheWeek(FIRST_DAYS.SUNDAY)
        } else {
          setFirstDayOfTheWeek(
            settings &&
              [
                FIRST_DAYS.MONDAY,
                FIRST_DAYS.SUNDAY,
                FIRST_DAYS.SATURDAY
              ].includes(settings?.FirstDayOfTheWeek)
              ? settings?.FirstDayOfTheWeek
              : FIRST_DAYS.SUNDAY
          )
        }
      })
    } else {
      setFirstDayOfTheWeek(FIRST_DAYS.SUNDAY)
    }
  }, [])

  const context: AppSettingsContextProps = {
    firstDayOfTheWeek,
    setFirstDayOfTheWeek,
    timeZone,
    setTimeZone
  }

  window.setTimeZone = setTimeZone

  return (
    <AppSettingsContext.Provider value={context}>
      {props.children}
      {isErrorDialogOpen && (
        <ConfirmationDialog
          open
          title={t('oopsError')}
          content={GENERIC_ERROR}
          handleRightButton={() => setIsErrorDialogOpen(false)}
          leftButtonHidden
        />
      )}
    </AppSettingsContext.Provider>
  )
}

interface AppSettingsContextProps {}

const AppSettingsContext = createContext<AppSettingsContextProps>({
  firstDayOfTheWeek: 1,
  setFirstDayOfTheWeek: () => {},
  timeZone: 'UTC',
  setTimeZone: () => {}
})

export function useAppSettings(): AppSettingsContextProps {
  return useContext(AppSettingsContext)
}
