import { Grid } from '@mui/material'
import { darken, styled } from '@mui/material/styles'
import { CALENDAR_ITEM_CORNER_RARIUS } from 'utils/constants'

export const DayCellContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  fontSize: '1rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))

export const Weather = styled('span')(({ theme }) => ({
  fontSize: '0.5rem',
  fontWeight: 400,
  color: theme.palette.input.placeholder,
  display: 'flex',
  padding: theme.spacing(0, 1),
  '& span': {
    margin: theme.spacing(-1, 0, 0, 0)
  }
}))

export const EventContent = styled(Grid)<{
  color?: string
  fontSize?: string
  fontcolor?: string
  pending?: boolean
  opacity?: number
}>(({ theme, color, fontSize, pending, opacity, fontcolor }) => ({
  width: '100%',
  height: '100%',
  borderRadius: CALENDAR_ITEM_CORNER_RARIUS,
  backgroundColor: color,
  minHeight: '17px',
  opacity: opacity,
  color: fontcolor ?? '#FFFFFF',
  fontSize: fontSize,
  overflow: 'hidden',
  fontWeight: 400,
  overflowWrap: 'break-word',

  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'start',
  justifyContent: 'space-between',
  backgroundPosition: pending ? '0 0' : '',
  backgroundSize: pending ? '16px 16px' : '',
  backgroundImage: pending
    ? 'linear-gradient(135deg, transparent 45%, rgba(0, 0, 0, 0.15) 45%, rgba(0, 0, 0, 0.15) 55%, transparent 55%)'
    : ''
}))

export const DayHeaderContentWeek = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  fontWeight: 400,
  fontSize: '1rem'
}))

export const DayHeaderContentDay = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  fontWeight: 500
}))

export const SlotContent = styled(Grid)(({ theme }) => ({
  width: 60,
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
  textAlign: 'center',
  '&[data-color="secondary"]': {
    color: theme.palette.input.placeholder
  },
  '&[data-time]': {
    transform: 'translateY(-25px)'
  }
}))
