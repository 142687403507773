import GoogleApi from 'services/GoogleApi'
import Logger from 'services/Logger'
import { GoogleEvent } from 'types/google_events'

export function deleteGoogleEvent(calendarId: string, eventId: string) {
  const url = `${calendarId}/events/${eventId}`

  Logger.debug('from google removing', url)

  return GoogleApi.instance.delete(url)
}

export function addGoogleEvent(calendarId: string, googleEvent: GoogleEvent) {
  const url = `${calendarId}/events?sendUpdates=all`

  return GoogleApi.instance.post(url, { ...googleEvent })
}

export function updateGoogleEvent(
  calendarId: string,
  googleEvent: GoogleEvent
) {
  return GoogleApi.instance.patch(
    `${calendarId}/events/${googleEvent.id}?sendUpdates=all`,
    googleEvent
  )
}
