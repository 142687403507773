import Grid from '@mui/material/Grid'
import Input from 'components/form/Input'
import { ChangeEvent, useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'

import { APP_COLOR } from 'utils/colors'
import { useTranslation } from 'react-i18next'

const MAX_LEN = 50

interface DeleteAccountDialogProps {
  setOpen: (b: boolean) => void
  open: boolean
  delete: () => void
}

export default function DeleteAccountDialog(props: DeleteAccountDialogProps) {
  const { t } = useTranslation()

  const [progress, onProgress] = useState(false)
  const [deleteWord, onDeleteWord] = useState('')
  const [buttonDisabled, onButtonDisabled] = useState(true)

  const hideDialog = () => {
    if (!progress) props.setOpen(false)
  }

  const onDeleteWordChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDeleteWord(event.target.value)
    onButtonDisabled(
      event.target.value.toLowerCase() !== t('delete').toLowerCase()
    )
  }

  const onCancelDialog = () => {
    if (!progress) {
      hideDialog()
    }
  }

  return (
    <ConfirmationDialog
      title={t('toConfirmType', { check: t('delete') })}
      leftButtonColor={'black'}
      leftButton={`${t('cancel')}`}
      rightButton={`${t('delete')}`}
      rightButtonColor={APP_COLOR}
      cancelDisabled={buttonDisabled}
      content={
        <>
          <Grid
            style={{
              pointerEvents: progress ? 'none' : 'all',
              width: '300px'
            }}
            container
            rowSpacing={4}
            flexDirection={'column'}
          >
            <Grid item>
              <Input
                variant="regular"
                placeholder={`${t('delete')}`}
                value={deleteWord}
                onChange={onDeleteWordChange}
              />
            </Grid>
          </Grid>
        </>
      }
      handleClose={onCancelDialog}
      handleLeftButton={hideDialog}
      open={props.open}
      handleRightButton={props.delete}
    />
  )
}
