import Analytics, { Events } from 'analytics/Analytics'
import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { useAuth } from 'components/providers/AuthProvider'
import { ReactNode, useState } from 'react'
import { logout } from 'services/api/auth'

import * as Styled from '../styled'
import { t } from 'i18next'
import { AccountDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { deleteAccount } from 'services/api/account'
import DeleteAccountDialog from 'components/dialogs/DeleteAccountDialog'
import { MenuDeleteAccIcon, MenuLogoutIcon } from 'assets/icons/menu'

interface AccountActionProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
}

function Content(props: AccountActionProps) {
  const auth = useAuth()

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const performLogout = () => {
    logout()
      .then(() => {
        return auth.onLogout()
      })
      .then(() => {
        Analytics.getInstance().sendEvent(Events.LOGOUT)
      })
      .catch((error) => {
        console.error('logout error', error)
        auth.onLogout()
      })
  }

  const performDeleteAccount = () => {
    deleteAccount(props.account?._id ?? '').then(() => {
      return performLogout()
    })
  }

  return (
    <PopupSection title={`${t('account')}`}>
      <PopupSectionRow
        icon={<MenuLogoutIcon />}
        title={t('logout')}
        action={performLogout}
        onlyTitle
      />
      <PopupSectionRow
        titleColor="red"
        title={t('deleteAccount')}
        icon={<MenuDeleteAccIcon />}
        action={() => {
          setShowDeleteAccountDialog(true)
        }}
        onlyTitle
      />
      {showDeleteAccountDialog && (
        <ConfirmationDialog
          leftButtonColor="black"
          title={t('areYouSureDeleteAccount')}
          content={t('onceDeleted')}
          leftButton={`${t('no')}`}
          rightButton={`${t('yes')}`}
          handleLeftButton={() => {
            setShowDeleteAccountDialog(false)
          }}
          handleRightButton={() => {
            setShowDeleteAccountDialog(false)
            setShowDeleteConfirm(true)
          }}
          open={showDeleteAccountDialog}
        />
      )}
      {showDeleteConfirm && (
        <DeleteAccountDialog
          delete={performDeleteAccount}
          setOpen={setShowDeleteConfirm}
          open={showDeleteConfirm}
        />
      )}
    </PopupSection>
  )
}

export default function AccountActionsPopup(props: AccountActionProps) {
  return (
    <Styled.UserMenuPopup
      open={props.open}
      setOpen={props.setOpen}
      placement="right"
      content={<Content {...props} />}
    >
      {props.children}
    </Styled.UserMenuPopup>
  )
}
