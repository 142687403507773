import { Box, ClickAwayListener, Grid, List, TextField } from '@mui/material'
import { ChangeEvent, ReactNode, useState } from 'react'
import * as Styled from '../styled'
import { getTimeZones } from '@vvo/tzdb'
import { GlobeIcon } from 'assets/icons'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { t } from 'i18next'
import Popup from 'components/popup/Popup'

const timeZones = getTimeZones()

interface ContentProps {
  setOpened: (b: boolean) => void
  setTimeZone: (tz: string) => void
}

function Content(props: ContentProps) {
  const [searchText, setSearchText] = useState<string>('')

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const onTimeZoneClick = (tz: string) => {
    props.setTimeZone(tz)
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        props.setOpened(false)
      }}
    >
      <Box sx={{ overflow: 'auto', maxHeight: '200px' }}>
        <Grid container direction={'column'} sx={{ padding: 4 }}>
          <TextField
            autoFocus
            placeholder={`${t('typeToSearchTz')}`}
            value={searchText}
            onChange={onSearchChange}
            variant="standard"
          />
          <List>
            {timeZones
              .filter((tz) =>
                tz.name.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((timeZone, index) => {
                return (
                  <PopupSectionRow
                    key={index}
                    icon={<GlobeIcon />}
                    title={timeZone.name}
                    action={() => {
                      onTimeZoneClick(timeZone.name)
                      props.setOpened(false)
                    }}
                  />
                )
              })}
          </List>
        </Grid>
      </Box>
    </ClickAwayListener>
  )
}

interface TimezonePopupProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  setTimeZone: (tz: string) => void
}

export default function TimezonePopup(props: TimezonePopupProps) {
  return (
    <Popup
      setOpen={props.setOpen}
      open={props.open}
      content={<Content {...props} setOpened={props.setOpen} />}
      placement={'right'}
    >
      {props.children}
    </Popup>
  )
}
