import { Grid, Hidden, Typography } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import MultiAccountBadge from 'components/badges/MultiAccountBadge'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import Card from 'components/cards/Card'
import Select from 'components/form/Select'

import Avatar from '../badges/Avatar'
import { APP_COLOR } from 'utils/colors'
import i18n from 'i18n'

export const Calendar = styled(Card)(() => ({
  flex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column'
}))

export const CalendarHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100.5px',
  width: '100%',
  padding: theme.spacing(4, 5),
  borderBottom: '1px solid',
  borderColor: theme.palette.border.primary
}))

export const CalendarHeaderInfo = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content'
}))

export const CalendarHeaderTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

interface CalendarHeaderTitleProps {
  theme?: Theme
  fontSize?: string
}

export const CalendarHeaderTitle = styled('div')(
  ({ theme, fontSize }: CalendarHeaderTitleProps) => ({
    fontSize: fontSize ?? '1.625rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    color: theme?.palette.text.primary,
    minHeight: '24px'
  })
)

export const CalendarHeaderSubtitle = styled('span')(({ theme, color }) => ({
  fontSize: '0.9rem',
  fontWeight: 400,
  width: '100%',
  display: 'block',
  color: color ?? theme.palette.input.placeholder,
  cursor: 'pointer',
  overflow: 'visible',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline'
  }
}))

export const CalendarHeaderActions = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  zIndex: 20,
  background: 'white'
}))

export const CalendarHeaderActionsCell = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  position: 'relative',

  '&::after': {
    content: '""',
    width: 1,
    height: 30,
    margin: theme.spacing(0, 2.75),
    backgroundColor: theme.palette.border.primary
  },

  '&:first-of-type': {
    '&::before': {
      content: '""',
      width: 1,
      height: 30,
      backgroundColor: theme.palette.border.primary,
      position: 'absolute',
      left: 0
    }
  },
  '&:last-child': {
    '&::after': {
      display: 'none'
    }
  },

  '& button[data-button="icon-button"]': {
    '&:last-child': {
      margin: theme.spacing(0)
    }
  }
}))

export const SelectContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  position: 'relative',
  padding: theme.spacing(0, 1),

  '&:last-child': {
    padding: theme.spacing(0, 0, 0, 5),
    '&::after': {
      display: 'none'
    }
  },

  '& button[data-button="icon-button"]': {
    margin: theme.spacing(0, 2.5, 0, 0),
    '&:last-child': {
      margin: theme.spacing(0)
    }
  }
}))

export const RangeSelect = styled(Select)(() => ({}))

export const IconButtonSlide = styled(IconButton)(() => ({
  '&[data-prev]': {
    '& svg': {
      transform: 'rotate(90deg) translateY(1px)'
    }
  },
  '&[data-next]': {
    '& svg': {
      transform: 'rotate(-90deg) translateY(1px)'
    }
  }
}))

export const AllAccountBadge = styled(MultiAccountBadge)(({ theme }) => ({
  padding: theme.spacing(0, 3, 0, 0)
}))

export const LocationLabel = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 15
}))

export const AvatarBadge = styled(Avatar)(({ theme }) => ({}))

export const CalendarView = styled(Grid)(() => ({
  flex: 1,
  height: '100%',
  position: 'relative'
}))

export const CalendarViewScrollContainer = styled(Grid)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%'
}))

export const CalendarViewScroll = styled(Grid)(() => ({
  width: '100%',
  height: '100%'
}))

export const AddEventButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  right: i18n.dir() === 'ltr' ? 16 : undefined,
  left: i18n.dir() === 'ltr' ? undefined : 16,
  zIndex: 10,

  '& svg': {
    width: 32,
    height: 32,
    marginRight: 0
  }
}))

export const ListViewScrollable = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%'
}))

export const DateHeader = styled('div')<{ isFirst: boolean; isToday: boolean }>(
  ({ theme, isFirst, isToday }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: 40,
    width: '100%',
    padding: theme.spacing(0, 4),
    borderBottom: '1px solid #e4e4e4',
    borderTop: '1px solid #e4e4e4',
    background: isToday
      ? APP_COLOR
      : 'var(--gradient-22, linear-gradient(119deg, #F9F9F9 10.89%, #EFEEEE 97.51%))',
    borderColor: theme.palette.border.primary,
    marginTop: isFirst ? 0 : 16,
    marginBottom: 16
  })
)

export const EventRow = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexDirection: 'row',
  height: 50,
  width: '100%',
  margin: theme.spacing(1, 0),
  paddingRight: i18n.dir() === 'ltr' ? 16 : undefined,
  paddingLeft: i18n.dir() === 'ltr' ? undefined : 16
}))

export const TimeColumn = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  height: '100%',
  minWidth: 90,
  maxWidth: 90,
  fontSize: 14,
  padding: theme.spacing(0, 2)
}))

export const TimeContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
}))

export const TimeLabel = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: i18n.dir() === 'ltr' ? 'end' : 'start'
}))

export const NoItems = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.disabled,
  textAlign: 'center',
  width: '100%',
  padding: 16
}))
