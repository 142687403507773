import { ChangeEventHandler, ReactNode, useEffect, useRef } from 'react'

import * as Styled from './styled'
import i18n from 'i18n'

interface InputProps {
  maxLength?: number
  placeholder?: string
  prefix?: ReactNode
  className?: string
  variant?: 'popup' | string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  component?: ReactNode
  shouldNotFocus?: boolean
  onFocusLost?: () => void
  onFocusGain?: () => void
  type?: string
  bringFocus?: boolean
  id?: string
}

export default function Input(props: InputProps) {
  const { prefix, className, component, ...otherProps } = props

  const phoneInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!props.shouldNotFocus) phoneInputRef?.current?.focus()
  }, [])

  useEffect(() => {
    if (props.bringFocus) phoneInputRef?.current?.focus()
  }, [props.bringFocus])

  return (
    <Styled.InputRoot className={className}>
      <Styled.InputPrefixContainer>
        {prefix && <Styled.InputPrefix>{prefix}</Styled.InputPrefix>}
        <Styled.Input
          id={props.id}
          sx={{
            paddingRight: i18n.dir() === 'ltr' ? 12 : -12,
            backgroundColor: 'transparent'
          }}
          ref={phoneInputRef}
          autoComplete={'off'}
          data-prefix={!!prefix}
          data-variant={props.variant}
          className="Input__Input"
          value={props.value}
          type={props.type}
          onChange={props.onChange}
          onFocus={props.onFocusGain}
          onBlur={props.onFocusLost}
          {...otherProps}
        />
        {component}
      </Styled.InputPrefixContainer>
    </Styled.InputRoot>
  )
}
