import React from 'react'
import YouTube from 'react-youtube'

export interface VideoPreviewProps {
  videoId: string
}

const VideoPreview = (props: VideoPreviewProps) => {
  const { videoId } = props
  const opts = {
    height: '200',
    width: '320',
    playerVars: {
      autoplay: 0
    }
  }

  const onVideoClick = () => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank')
  }

  return (
    <div onClick={onVideoClick}>
      <YouTube videoId={videoId} opts={opts} />
    </div>
  )
}

export default VideoPreview
