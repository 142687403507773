import { Box, Button, TextField, lighten } from '@mui/material'
import { SearchIcon } from 'assets/icons'
import { t } from 'i18next'
import { theme } from 'theme'
import { KeyboardEvent } from 'react'
import { APP_COLOR } from 'utils/colors'
import { RoundButton } from './styled'

export interface SearchBarWithButtonProps {
  placeholder?: string
  onSearch: () => void
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchValue: string
}

export default function SearchBarWithButton(props: SearchBarWithButtonProps) {
  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      props.onSearch()
    }
  }

  return (
    <Box
      marginLeft={4}
      marginRight={4}
      height={'3rem'}
      borderRadius={'62.5rem'}
      border={'1px solid #e4e4e4'}
      position={'relative'}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      flexDirection={'row'}
      paddingLeft={2}
      paddingRight={2}
    >
      <SearchIcon />
      <TextField
        autoFocus
        sx={{
          flex: 1,
          width: '100%',
          '& .MuiInput-underline': {
            '&:before': {
              borderBottom: 'none'
            },
            '&:after': {
              borderBottom: 'none'
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none'
            }
          }
        }}
        onKeyDown={handleKeyPress}
        maxRows={1}
        value={props.searchValue}
        placeholder={props.placeholder}
        onChange={props.onTextChange}
        variant="standard"
        InputProps={{
          style: {
            paddingRight: '0.81rem',
            paddingLeft: '0.81rem',
            fontSize: '1rem',
            lineHeight: '1.25rem',
            borderBottom: 'none'
          }
        }}
      />
      <RoundButton
        sx={{
          opacity: props.searchValue.length === 0 ? 0.5 : 1
        }}
        disabled={props.searchValue.length === 0}
        onClick={props.onSearch}
      >
        {t('search')}
      </RoundButton>
    </Box>
  )
}
