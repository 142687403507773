import { createTheme } from '@mui/material'
import i18n from 'i18n'
import { CSSProperties } from 'react'
import { APP_COLOR } from 'utils/colors'

interface PaletteOverride {
  card: {
    primary: CSSProperties['color']
  }
  button: {
    background: {
      primary: CSSProperties['color']
      selected: CSSProperties['color']
    }
    text: {
      primary: CSSProperties['color']
      approve: CSSProperties['color']
      tenative: CSSProperties['color']
      negative: CSSProperties['color']
      groupcal: CSSProperties['color']
      link: CSSProperties['color']
      darkRed: CSSProperties['color']
    }
  }
  input: {
    border: {
      primary: CSSProperties['color']
    }
    placeholder: CSSProperties['color']
  }
  border: {
    primary: CSSProperties['color']
  }
}

declare module '@mui/material/styles' {
  interface Palette extends PaletteOverride {}
  interface PaletteOptions extends PaletteOverride {}
}

export const theme = createTheme({
  direction: i18n.dir(),
  palette: {
    border: {
      primary: '#E4E4E4'
    },
    background: {
      default: '#F9F9F9'
    },
    divider: '#F2F2F2',
    text: {
      primary: '#3D3B3B',
      secondary: '#848484'
    },
    card: {
      primary: '#FFFFFF'
    },
    button: {
      background: {
        primary: '#FFFFFF',
        selected: '#f6f6f6'
      },
      text: {
        groupcal: APP_COLOR,
        primary: '#3D3B3B',
        approve: '#006400',
        tenative: '#e2ab4c',
        negative: '#FF0000',
        link: '#0F7FEB',
        darkRed: '#EA5455'
      }
    },
    input: {
      border: {
        primary: '#F4F4F6'
      },
      placeholder: '#A6A6A6'
    },
    grey: {
      500: '#DEE4E6',
      100: '#EFEFEF'
    },
    error: {
      main: '#EB5545'
    },
    success: {
      main: '#00CA73'
    },
    primary: {
      main: APP_COLOR // Replace with your app's primary color
    }
  },
  spacing: (factor: number) => `${0.25 * factor}rem`
})
