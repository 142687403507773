import { Grid, ListItem, ListItemButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { borderRadius } from '@mui/system'
import Avatar from 'components/badges/Avatar'

export const Card = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 5),
  backgroundColor: theme.palette.card.primary,
  borderRadius: 20,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
  '&[data-padding="false"]': {
    padding: 0
  }
}))

export const PrivateLabel = styled('div')(({ theme }) => ({
  color: 'white',
  height: '16px',
  alignContent: 'center',
  padding: '0 0.25rem',
  backgroundColor: '#423636',
  borderRadius: '7px',
  lineHeight: '16px',
  fontSize: '12px'
}))

export const GroupCard = styled(ListItem)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0, 0, 0),
  cursor: 'pointer',
  backgroundColor: theme.palette.card.primary
}))

export const GroupCardButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0, 4, 0, 4)
}))

interface GroupCardAvatarProps {
  size?: number | undefined
}

export const GroupCardAvatar = styled(Avatar)<GroupCardAvatarProps>(
  (props) => ({
    width: props.size || 42,
    height: props.size || 42,
    minWidth: props.size || 42,
    minHeight: props.size || 42
  })
)

interface GroupCardAvatarContainerProps {
  color: string
  borderWidth?: string
}

export const GroupCardAvatarContainer = styled('div')(
  (props: GroupCardAvatarContainerProps) => ({
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `${props.borderWidth ?? '2px'} solid ${props.color}`,
    borderRadius: 9999,
    '&[data-size="sm"]': {
      width: 46,
      height: 46,
      minWidth: 46,
      minHeight: 46
    }
  })
)

export const GroupCardContent = styled(Grid)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: '71px',
  margin: theme.spacing(0, 2),
  paddingTop: 6,
  paddingBottom: 6,
  borderBottom: '1px solid',
  borderColor: theme.palette.border.primary
}))

export const GroupCardTitleContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const GroupCardTitle = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  maxWidth: 'calc(100% - 75px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const GroupCardTime = styled('p')(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap'
}))

export const GroupCardInfoContainer = styled(Grid)(() => ({
  width: 'calc(100% - 50px)',
  display: 'flex',
  minHeight: '34px',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const GroupInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const GroupCardInfo = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.text.secondary,
  minHeight: 34,
  display: 'box',
  overflow: 'hidden',
  lineClamp: 1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export const GroupIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '& svg': {
    color: theme.palette.text.secondary,
    width: 16,
    '&[data-icon="hide"]': {
      width: 20
    }
  }
}))

export const UnreadAmount = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  fontSize: 12,
  fontWeight: 300,
  textAlign: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: '50%',
  background: '#007FFF'
}))
