import { Box, ClickAwayListener } from '@mui/material'
import Grid from '@mui/material/Grid'
import { EventRecurrenceProps } from 'components/eventComponents/EventRecurrence'
import { PopupInstance } from 'components/popup/Popup'
import { ChangeEvent, ReactNode, useState } from 'react'
import { PopupRowTitle, SmallTextField } from '../styled'
import * as CalendarStyled from '../../../components/calendar/styled'
import { Option } from 'types/components/form'
import dayjs, { Dayjs } from 'dayjs'
import DatePickPopup from './DatePickPopup'
import Button from 'components/buttons/Button'
import { CustomEventRecurrencePopup } from './styled'
import { PopupSectionTitle } from 'components/popup/styled'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { IEventDetails } from 'hooks/useEventDetails'
import { isCypress } from 'utils/cypress'
import { UserSettingsDoc } from 'types/api/changes'
import { FIRST_DAYS } from 'components/calendar/CalendarView'
import { useAppSettings } from 'components/providers/AppSettingsProvider'
import { getDefaultTimezone } from 'index'

const UNTIL: Option[] = [
  {
    label: t('forever'),
    value: `${t('forever')}`
  },
  {
    label: `${t('until')}`,
    value: `${t('until')}`
  }
]

interface RecurrencePopupProps extends EventRecurrenceProps {
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  eventHook: IEventDetails
  userSettings?: UserSettingsDoc
}

function Content(props: RecurrencePopupProps) {
  const appSettings = useAppSettings()

  const [interval, setInterval] = useState<string>(
    String(props.recurrence?.Interval ?? '1')
  )
  const onIntervalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInterval(event.target.value)
  }

  const [until, setUntil] = useState<string>(
    props.recurrence?.EndDate || isCypress()
      ? `${t('until')}`
      : `${t('forever')}`
  )

  const [unit, setUnit] = useState<string>(
    String(props.recurrence?.Unit ?? isCypress() ? '1' : '2')
  )

  let endDate = props.recurrence?.EndDate || null

  if (isCypress()) {
    let pickDate = dayjs()

    const dayjsFirstDay =
      props.userSettings?.FirstDayOfTheWeek === FIRST_DAYS.SUNDAY ? 0 : 1

    while (pickDate.day() !== dayjsFirstDay) {
      pickDate = pickDate.subtract(1, 'day')
    }

    if (props.eventHook.getValues('AllDay') === '1') {
      pickDate = pickDate.tz('UTC', true).startOf('day')
    }

    endDate = pickDate.add(5, 'day').endOf('day').unix().toString()
  }

  const [untilDate, setUntilDate] = useState<string | null | undefined>(endDate)

  const untilDayJs = dayjs(Number(untilDate) * 1000)

  const onNewEndDatePick = (date: Dayjs | null) => {
    if (date) {
      const newEndDate = date.unix().toString()
      setUntilDate(newEndDate)
    }
  }

  const [dateState, setDateState] = useState(false)

  const openDatePicker = () => {
    setDateState(true)
  }

  const setRecurrenceToEvent = () => {
    props.setOpen(false)
    const recurrence = {
      ...props.recurrence,
      Unit: Number(unit),
      Interval: Number(interval),
      EndDate: untilDate
    }

    if (recurrence.EndDate === null) {
      delete recurrence['EndDate']
    }

    props.setEventRecurrence(recurrence)
  }

  const onClickAway = () => {
    props.setOpen(false)
  }

  const REPEAT_UNIT: Option[] = [
    { label: t('day', { count: Number(interval) }), value: '1' },
    { label: t('week', { count: Number(interval) }), value: '2' },
    { label: t('month', { count: Number(interval) }), value: '3' },
    { label: t('year', { count: Number(interval) }), value: '4' }
  ]

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={onClickAway}
    >
      <Grid
        container
        direction={'column'}
        sx={{ paddingLeft: 4, paddingRight: 4 }}
      >
        <Box marginLeft={2}>
          <PopupSectionTitle color={'black'}>
            {t('recurrence')}
          </PopupSectionTitle>
        </Box>
        <Box height={8} />
        <Grid container direction={'row'} alignItems={'center'} marginLeft={2}>
          <PopupRowTitle
            style={{ marginRight: 4, marginBottom: 4, cursor: 'default' }}
          >
            {t('every')}
          </PopupRowTitle>
          <SmallTextField
            id="recurrence-interval"
            autoFocus
            placeholder="1"
            value={interval}
            onChange={onIntervalChange}
            variant="standard"
            inputProps={{
              maxLength: 2,
              style: {
                textAlign: 'center',
                fontSize: 14,
                borderBottom: '0.5px solid'
              }
            }}
            InputProps={{ disableUnderline: true }}
          />
          <CalendarStyled.CalendarHeaderActionsCell style={{ marginBottom: 4 }}>
            <CalendarStyled.RangeSelect
              variant="no-border"
              placeholder="Range"
              size="md"
              options={REPEAT_UNIT}
              value={unit}
              onChange={(e) => {
                setUnit(String(e?.value))
              }}
            />
          </CalendarStyled.CalendarHeaderActionsCell>
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent={'start'}
          alignItems={'center'}
          wrap={'nowrap'}
        >
          <div style={{ width: 'fit-content' }}>
            <CalendarStyled.RangeSelect
              variant="no-border"
              placeholder="Range"
              size="md"
              options={UNTIL}
              value={until}
              onChange={(e) => {
                setUntil(String(e?.label) ?? t('forever'))
                setUntilDate(dayjs(Date.now()).add(1, 'M').unix().toString())
              }}
            />
          </div>
          {until != t('forever') ? (
            <DatePickPopup
              eventHook={props.eventHook}
              onAccept={onNewEndDatePick}
              value={untilDayJs}
              open={dateState}
              setOpen={setDateState}
              instanceStart={untilDayJs.valueOf()}
            >
              <span>
                <PopupRowTitle onClick={openDatePicker}>
                  {untilDayJs.format('L')}
                </PopupRowTitle>
              </span>
            </DatePickPopup>
          ) : (
            <></>
          )}
        </Grid>
        <Box height={16} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Button
            id="apply-recurrence"
            size="xs"
            onClick={setRecurrenceToEvent}
          >
            {t('apply')}
          </Button>
        </div>
      </Grid>
    </ClickAwayListener>
  )
}

export default function RecurrencePopup(props: RecurrencePopupProps) {
  const [instance, onInstance] = useState<PopupInstance>()
  return (
    <CustomEventRecurrencePopup
      open={props.open}
      onMount={onInstance}
      content={<Content {...props} />}
      placement={'right'}
    >
      {props.children}
    </CustomEventRecurrencePopup>
  )
}
