import { AxiosError } from 'axios'
import { processLogout } from 'components/providers/AuthProvider'
import Api from 'services/Api'
import { GetChangesResponse } from 'types/api/changes'
import { logout } from './auth'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

export const API_CHANGES = '/general/changes'

export interface GetChangesBody {
  LastUpdate: string | null | undefined
  DeviceChangeID: string | null | undefined
  ForGroupsID: string[] | null | undefined
}

export async function getChanges(
  body: GetChangesBody
): Promise<GetChangesResponse> {
  const response = await Api.post(API_CHANGES, { ...body }).catch((e) => {
    if (e instanceof AxiosError) {
      if (e.response?.status === 404) {
        logout().then(() => {
          processLogout()
          window.location.reload()
        })
      } else if (e.response?.status === 403) {
        if (!LocalStorage.get(LocalStorageKey.USER_ID)) {
          processLogout().then(() => {
            window.location.reload()
          })
        }
      }
    }
  })
  return JSON.parse(JSON.stringify(response?.data), (key, value) => {
    const propertiesToCast = ['Status'] // Which properties should be converted from number to string
    if (propertiesToCast.includes(key)) {
      return String(value)
    }
    return value
  })
}
