import { useTheme } from '@mui/material/styles'
import { CSSProperties, MouseEvent, ReactElement } from 'react'

export interface BlockOverlayProps {
  isUiBlocked?: boolean
  blockedOverlayClick?: (event: MouseEvent<HTMLDivElement>) => void
  blockedOverlayLeftOffset?: number
  blockedOverlayRightOffset?: number
  blockedOverlayTopOffset?: number
  blockedOverlayBottomOffset?: number
  color?: string
  opacity?: number
  zIndex?: number
}

export default function BlockOverlay({
  isUiBlocked,
  blockedOverlayClick,
  blockedOverlayLeftOffset: leftOffset,
  blockedOverlayRightOffset: rightOffset,
  blockedOverlayTopOffset: topOffset,
  blockedOverlayBottomOffset: bottomOffset,
  color,
  opacity,
  zIndex
}: BlockOverlayProps): ReactElement {
  const overlayStyle: CSSProperties = {
    position: 'absolute',
    opacity: opacity ?? (isUiBlocked ? 0.5 : 0.0),
    background: color ?? '#ededed',
    top: topOffset ?? 0,
    bottom: bottomOffset ?? 0,
    right: rightOffset ?? 0,
    left: leftOffset ?? 0,
    zIndex: zIndex ?? -1,
    overflow: 'hidden',
    cursor: 'not-allowed',
    borderRadius: '5px',
    pointerEvents: isUiBlocked && blockedOverlayClick ? 'all' : 'none'
  }

  return <div style={overlayStyle} onClick={blockedOverlayClick} />
}
