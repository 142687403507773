import { Q } from '@nozbe/watermelondb'
import dayjs from 'dayjs'
import LocalStorage from 'services/LocalStorage'
import {
  DocType,
  EventDoc,
  EventPriority,
  EventStatus,
  Exclusion,
  TaskType,
  UserSettingsDoc
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import Database from '../Database'
import { generateDefaultReminder } from '../utils/Event'
import { isGroupMuted } from 'utils/api/userSettings'
import { isConvertedGroupId } from 'utils/groups'
import { removePrefix } from 'components/providers/CalendarSyncProdivder'
import Logger from 'services/Logger'
import { th } from 'date-fns/locale'
import { outlookPrefix } from 'utils/constants'
import { generateGroupcalArrayFromRecurrence } from 'components/MinuteTimer'
import { isEventWithinTimePeriod } from 'components/calendar/CalendarView'
import { getDefaultTimezone } from 'index'
import { is24meApp } from 'utils/appType'

export async function addTempEventToDate(
  startTime: Number,
  endTime: Number,
  groupId?: string,
  allDay?: boolean,
  docType?: DocType,
  labelId?: string,
  thirdPartyId?: string | undefined
): Promise<Model<EventDoc>> {
  Logger.pink('DocType:', docType)
  Logger.pink('GroupID:', groupId)
  Logger.pink(
    'Start:',
    dayjs(Number(startTime) * 1000).format('YYYY-MM-DD HH:mm:ss')
  )
  Logger.pink(
    'End:',
    dayjs(Number(endTime) * 1000).format('YYYY-MM-DD HH:mm:ss')
  )

  let fallbackType = DocType.EVENT

  if (is24meApp()) {
    fallbackType = DocType.TASK
  }

  return await Database.write(async () => {
    const now = Date.now() / 1000
    return Database.getCollection<EventDoc>(CollectionType.EVENT).create(
      (localEvent) => {
        localEvent.ObjectType = '1'
        localEvent.TaskType = TaskType.meet
        localEvent.Status = EventStatus.ACTIVE
        localEvent.Type = isConvertedGroupId(groupId ?? '')
          ? DocType.REGULAR_EVENT
          : docType ?? fallbackType

        localEvent.GroupID =
          docType === DocType.TASK || docType === DocType.NOTE
            ? undefined
            : groupId
        localEvent.OwnerID = isConvertedGroupId(groupId)
          ? removePrefix(groupId ?? '')
          : LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        localEvent.Rank = String(now)
        localEvent.OpenDate = String(now)
        localEvent.isDeleted = '0'
        localEvent.Priority = EventPriority.LOW
        localEvent.Label = labelId ? [{ LabelText: labelId }] : undefined
        localEvent.AllDay = allDay ? '1' : '0'
        localEvent.RequestConfirmation = '0'
        localEvent.StartDate = startTime.toString()
        localEvent.EndDate = endTime.toString()
        localEvent.ThirdPartyID = thirdPartyId
        localEvent.TimeZoneNameID = allDay ? 'UTC' : getDefaultTimezone()
        localEvent.Reminder = [generateDefaultReminder()]
      }
    )
  })
}

export const RemoveTempEvents = () => {
  console.log('removing temp events')

  LocalStorage.remove(LocalStorageKey.PENDING_EVENT_DATA)
  LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '0')

  return Database.write(async () => {
    const eventsToRemove = await Database.getCollection<EventDoc>(
      CollectionType.EVENT
    )
      .query(Q.where('_id', null || ''))
      .fetch()
    eventsToRemove.map(async (event) => {
      await event.destroyPermanently()
    })
  })
}

export const RemoveThirdPartyEvents = (groupId: string) => {
  return Database.write(async () => {
    const eventsToRemove = await Database.getCollection<EventDoc>(
      CollectionType.EVENT
    )
      .query(Q.where('GroupID', Q.like(groupId)))
      .fetch()
    eventsToRemove.map(async (event) => {
      await event.markAsDeleted()
    })
  })
}

export function excludeInstance(
  instanceTime: number,
  event: Model<EventDoc>,
  status: EventStatus
) {
  return Database.write(async () => {
    return event.update((local) => {
      const dayjsTime = dayjs(instanceTime)
        .add(dayjs().tz(event.TimeZoneNameID).utcOffset(), 'minute')
        .tz('UTC', true)
        .startOf('day')
        .valueOf()
      const exDate = String(dayjsTime / 1000)
      let exclusions: Exclusion[] = local.Recurrence?.Exclusion ?? []

      if (exclusions.length === 0 || typeof exclusions === 'string') {
        exclusions = []
      }

      exclusions.push({
        Date: exDate, //`${is24meApp() ? instanceTime / 1000 : exDate}`,
        Status: status
      })

      local.Recurrence = {
        ...local.Recurrence,
        Exclusion: (exclusions ?? [])
          .reverse()
          .filter(
            (item: Exclusion, index: number, self: Exclusion[]) =>
              index === self.findIndex((i) => i.Date === item.Date)
          )
      }
    })
  })
}

export function stopRecurrenceAt(
  instanceTime: number,
  event?: Model<EventDoc>
) {
  return Database.write(async () => {
    return event?.update((local) => {
      const dayjsTime = dayjs(instanceTime).add(-1, 'day').valueOf()
      const endDate = String(dayjsTime / 1000)

      local.Recurrence = { ...local.Recurrence, EndDate: endDate }
    })
  })
}

/**
 *
 * @param startTime start time in millis
 * @param endTime in millis
 * @param event to copy from
 * @returns promise with event model created
 */
export async function createEventFromAnotherEvent(
  startTime: number,
  endTime: number,
  event: Model<EventDoc>
): Promise<Model<EventDoc>> {
  return await Database.write(async () => {
    return Database.getCollection<EventDoc>(CollectionType.EVENT).create(
      (localEvent) => {
        const now = Date.now() / 1000

        localEvent.Rank = now.toString()
        localEvent.LastUpdate = now.toString()
        localEvent.StartDate = String(startTime / 1000)
        localEvent.EndDate = String(endTime / 1000)
        localEvent.ObjectType = '1'
        localEvent.TaskType = TaskType.meet
        localEvent.Status = EventStatus.ACTIVE
        localEvent.ThirdPartyID = event.ThirdPartyID
        localEvent.Type = event.Type
        localEvent.GroupID = event.GroupID
        localEvent.OwnerID = event.OwnerID
        localEvent.Rank = String(now)
        localEvent.OpenDate = String(now)
        localEvent.isDeleted = '0'
        localEvent.Text = event.Text
        localEvent.Notes = event.Notes
        localEvent.AllDay = event.AllDay
        localEvent.Location = event.Location
        localEvent.Priority = event.Priority

        Logger.blue('Created copy', localEvent)
      }
    )
  })
}

export async function localEventByConvertedLocalId(id: string) {
  return (
    await Database.getCollection<EventDoc>(CollectionType.EVENT)
      .query(Q.where('local_id', id))
      .fetch()
  )[0]
}

export async function localEventById(id: string) {
  return (
    await Database.getCollection<EventDoc>(CollectionType.EVENT)
      .query(Q.where('_id', id))
      .fetch()
  )[0]
}

export async function localEventByLocalId(id: string) {
  return (
    await Database.getCollection<EventDoc>(CollectionType.EVENT)
      .query(Q.where('id', id))
      .fetch()
  )[0]
}

export async function allCalendarItems() {
  return await Database.getCollection<EventDoc>(CollectionType.EVENT)
    .query()
    .fetch()
}

export async function otherCalendarEvents(
  calendarId: string,
  fromISO: string,
  toISO: string
) {
  Logger.blue('ICLOUD:', calendarId, fromISO, toISO)
  return (
    await Database.getCollection<EventDoc>(CollectionType.EVENT)
      .query(Q.where('GroupID', Q.like(calendarId)))
      .fetch()
  ).filter((event) => {
    const from = dayjs(fromISO)
    const to = dayjs(toISO)

    // there are might be a case when event - recurrent event, and we need to check is there are instance for this period
    if (event.Recurrence && event.Recurrence.Unit) {
      const instances = generateGroupcalArrayFromRecurrence(event)

      for (const instance of instances) {
        const instanceStart = dayjs.unix(Number(instance.StartDate))
        const instanceEnd = dayjs.unix(Number(instance.EndDate))

        if (instanceStart.isBefore(to) && instanceEnd.isAfter(from)) {
          return true
        }
      }
    }

    return isEventWithinTimePeriod(event, from, to)
  })
}

export async function eventsForReminders(userSettings: Model<UserSettingsDoc>) {
  const events = await Database.getCollection<EventDoc>(CollectionType.EVENT)
    .query(Q.where('Status', Q.notIn(['3', '4'])))
    .fetch()
  return events.filter((event) => {
    if (
      event.Recurrence &&
      event.Recurrence.Unit &&
      event.Reminder?.length != 0
    ) {
      if (
        event.Recurrence.EndDate &&
        dayjs
          .unix(Number(event.Recurrence.EndDate))
          .isBefore(dayjs().startOf('day'))
      ) {
        return false
      }

      return true
    }

    return (
      event.Reminder?.length != 0 &&
      dayjs
        .unix(Number(event.StartDate))
        .isBefore(dayjs().startOf('day').add(33, 'day')) &&
      dayjs.unix(Number(event.StartDate)).isAfter(dayjs().startOf('day')) &&
      !isGroupMuted(userSettings, event.GroupID ?? '')
    )
  })
}
