import { Spacing } from '@mui/system'
import ProBadge, { ProBadgeContainer } from 'components/badges/ProBadge'
import { ReactNode } from 'react'
import { theme } from 'theme'

import * as Styled from './styled'
import BlockOverlay, { BlockOverlayProps } from 'components/BlockOverlay'
import { Grid } from '@mui/material'

interface PopupSectionProps extends BlockOverlayProps {
  title?: string
  titleIcon?: ReactNode
  children: ReactNode
  padding?: string
  withProBadge?: boolean
  pointerEvents?: 'none' | 'all'
  titlePadding?: string
  contentZIndex?: number
}

export default function PopupSection(props: PopupSectionProps) {
  return (
    <Styled.PopupSection
      sx={{
        pointerEvents: props.pointerEvents ?? 'all',
        padding: props.titlePadding ?? theme.spacing(0, 5)
      }}
    >
      <BlockOverlay
        {...props}
        blockedOverlayLeftOffset={16}
        blockedOverlayRightOffset={16}
        blockedOverlayTopOffset={4}
        blockedOverlayBottomOffset={4}
      />
      {props.title &&
        (props.withProBadge ? (
          <ProBadgeContainer>
            <Styled.PopupSectionTitle>{props.title}</Styled.PopupSectionTitle>
          </ProBadgeContainer>
        ) : (
          <Grid
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Styled.PopupSectionTitle>{props.title}</Styled.PopupSectionTitle>
            {props.titleIcon}
          </Grid>
        ))}
      <Styled.PopupSectionContent
        padding={props.padding ?? theme.spacing(2, 0, 2, 0)}
        sx={{
          zIndex: props.contentZIndex
        }}
      >
        {props.children}
      </Styled.PopupSectionContent>
      <BlockOverlay
        {...props}
        blockedOverlayLeftOffset={16}
        blockedOverlayRightOffset={16}
        blockedOverlayTopOffset={4}
        blockedOverlayBottomOffset={4}
        color="transparent"
        opacity={1}
        zIndex={2}
      />
    </Styled.PopupSection>
  )
}
