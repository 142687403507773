import Switch from '../../../components/form/Switch'
import PopupSection from '../../../components/popup/PopupSection'
import PopupSectionRow from '../../../components/popup/PopupSectionRow'
import { ChangeEvent, useState } from 'react'
import Logger from '../../../services/Logger'
import {
  AccountDoc,
  GroupDoc,
  UserSettingsDoc,
  UserSettingsGroupSettings
} from '../../../types/api/changes'
import { Model } from '../../../types/model'
import { ILoading } from './GroupPopup'
import { updateGroupsSetting as updateGroupsSettingInUserSettings } from '../../../services/api/userSettings'
import ColorsPopup from '../ColorsPopup'
import { useConnection } from 'components/providers/ConnectionProvider'
import { t } from 'i18next'
import { capitalize } from '@mui/material'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import { isGroupDefault } from 'utils/api/userSettings'
import { isGroupBlockedToAddEvents } from 'utils/api/groups'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { isConvertedGroup } from 'utils/groups'
import { U } from '@fullcalendar/core/internal-common'

interface SectionForCalendarProps extends ILoading {
  group: Model<GroupDoc>
  userSettings: Model<UserSettingsDoc>
  account: Model<AccountDoc>
}

export default function SettingsForCalendarSection(
  props: SectionForCalendarProps
) {
  const connection = useConnection()
  const groupsSettings = props.userSettings?.GroupsSettings?.[props.group._id]
  const colorFromUserSettings = groupsSettings?.GroupColor

  async function updateGroupSettingInUserSetting(
    data: UserSettingsGroupSettings,
    groupId?: string
  ) {
    if (connection.isConnectionAvailable()) {
      props.setLoading(true)
      await performUpdateGroupSettings(
        props.userSettings,
        groupId ?? props.group._id,
        data
      )
      props.setLoading(false)
    }
  }

  const changeShowOnAllCalendar = (event: ChangeEvent<HTMLInputElement>) => {
    updateGroupSettingInUserSetting({
      ShowOnAllCalendars: event.target.checked ? '1' : '0'
    })
  }

  const changeReceiveReminders = (event: ChangeEvent<HTMLInputElement>) => {
    updateGroupSettingInUserSetting({
      ActivateReminders: event.target.checked ? '1' : '0'
    })
  }

  const changeDefaultCalendar = (event: ChangeEvent<HTMLInputElement>) => {
    updateGroupSettingInUserSetting({
      Default: event.target.checked ? '1' : '0'
    })
  }

  const onSetColor = (color: string) => {
    updateGroupSettingInUserSetting({
      GroupColor: color
    })
  }

  const [showColorPopup, setShowColorPopup] = useState(false)

  const showColors = () => {
    setShowColorPopup(true)
  }

  return (
    <PopupSection title={`${t('settingsForThisCalendar')}`}>
      {isConvertedGroup(props.group) ? (
        <></>
      ) : (
        <PopupSectionRow
          title={`${capitalize(t('color'))}`}
          action={showColors}
          value={
            <ColorsPopup
              open={showColorPopup}
              setOpen={setShowColorPopup}
              color={colorFromUserSettings ?? props.group.GroupColor}
              setColor={onSetColor}
            />
          }
        />
      )}
      <PopupSectionRow
        title={`${t('showEventsInAll', {
          allCalendars: t('allCalendarsButton')
        })}`}
        value={
          <Switch
            checked={(groupsSettings?.ShowOnAllCalendars ?? '1') === '1'}
            onChange={changeShowOnAllCalendar}
          />
        }
      />
      <PopupSectionRow
        title={`${t('getRemindersForEvents')}`}
        value={
          <Switch
            checked={(groupsSettings?.ActivateReminders ?? '1') === '1'}
            onChange={changeReceiveReminders}
          />
        }
      />
      {!isGroupBlockedToAddEvents(
        props.account,
        props.group,
        props.userSettings,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) && (
        <PopupSectionRow
          title={`${t('defaultCalendar')}`}
          value={
            <Switch
              checked={isGroupDefault(props.userSettings, props.group._id)}
              onChange={changeDefaultCalendar}
            />
          }
        />
      )}
    </PopupSection>
  )
}
export async function performUpdateGroupSettings(
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined,
  groupId: string,
  data: UserSettingsGroupSettings
) {
  if (userSettings) {
    try {
      await updateGroupsSettingInUserSettings(userSettings, groupId, data)
    } catch (e) {
      Logger.red('error update color', String(e))
    }
  } else {
    Logger.red('error update color', 'user settings not found')
  }
}
