import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { GroupDoc, ParticipantDataDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { motion } from 'framer-motion'
import { GroupPrivacy } from 'model/models/Group'
import { t } from 'i18next'
import { isConvertedGroup } from 'utils/groups'

interface NamesStripProps {
  group: Model<GroupDoc>
  participantIds: string[]
  participants?: Model<ParticipantDataDoc>[]
}

function NamesStrip(props: NamesStripProps) {
  const [showNames, setShowNames] = useState(false)

  useEffect(() => {
    setShowNames(false)
    setTimeout(async () => {
      setShowNames(true)
    }, 2000)
  }, [props.group._id])

  let names = ''

  if (props.participants)
    for (let index = 0; index < props.participants.length; index++) {
      const participant = props.participants[index]
      let uiName = ''

      if (!participant.FullName || participant.FullName === 'null')
        uiName = participant.PhoneNumber
      else uiName = participant.FullName

      names +=
        participant.AccountID === LocalStorage.get(LocalStorageKey.USER_ID)
          ? t('you')
          : uiName
      if (index != props.participants.length - 1) names += ', '
    }

  if (props.group.PrivacyMode === GroupPrivacy.CHANNEL) {
    names = `Members: ${props.participants?.length}`
  }

  if (!LocalStorage.get(LocalStorageKey.USER_ID)) return <>{t('clickHere')}</>

  return (
    <>
      <motion.span animate={{ opacity: showNames ? 1 : 0 }}>
        {showNames ? (
          <>{isConvertedGroup(props.group) ? `${t('you')}` : names}</>
        ) : (
          <></>
        )}
      </motion.span>
      <motion.span animate={{ opacity: showNames ? 0 : 1 }}>
        {showNames ? <></> : <>{t('clickHere')}</>}
      </motion.span>
    </>
  )
}

export default withObservables(['group'], (props: NamesStripProps) => ({
  participants: ObserveService.ParticipantsForIds(props.participantIds)
}))(NamesStrip)
