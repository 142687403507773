import { Q } from '@nozbe/watermelondb'
import Participant from 'model/models/Participant'
import {
  DocType,
  GroupDoc,
  GroupStatus,
  UserSettingsDoc
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import Database from '../Database'
import UserSettings from 'model/models/UserSettings'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { isConvertedGroup } from 'utils/groups'
import { outlookPrefix } from 'utils/constants'

export const tfmGoogleTempLabel = 'tempgroup'

export const AddTempGroup = async () => {
  return await Database.getCollection<GroupDoc>(CollectionType.GROUP).create(
    (group) => {
      group.Name = 'New Shared Calendar'
      group.Type = DocType.GROUP
      group._raw['_sortOrder'] = Date.now() / 1000
      group._raw['_message'] = 'You created this shared calendar'
      group._raw['_rev'] = 'temp'
    }
  )
}

export const RemoveTempGroups = () => {
  Database.write(async () => {
    const groupsToRemove = await Database.getCollection<GroupDoc>(
      CollectionType.GROUP
    )
      .query(Q.where('OwnerID', null || ''))
      .fetch()

    groupsToRemove.map(async (group) => {
      await group.destroyPermanently()
    })
  })
}

export const ParticipantsAll = () => {
  return Database.getCollection<Participant>(CollectionType.PARTICIPANT).query()
}

export const ParticipantsForGroup = (participantIds: string[]) => {
  return Database.getCollection<Participant>(CollectionType.PARTICIPANT).query(
    Q.where('AccountID', Q.oneOf(participantIds))
  )
}

export async function provideFirstGroup() {
  let query: any[] = [Q.sortBy('_sortOrder', Q.desc)]
  if (isGroupcalApp())
    query.push(Q.where('_id', Q.notLike(`%${prefixGoogleEventsGroup}%`)))
  const groups = await Database.getCollection<GroupDoc>(CollectionType.GROUP)
    .query(...query)
    .fetch()

  if (!groups || groups.length === 0) return undefined

  return groups[0]
}

export async function allGroups() {
  const groups = await Database.getCollection<GroupDoc>(CollectionType.GROUP)
    .query()
    .fetch()
  return groups.sort((a, b) => b._sortOrder - a._sortOrder)
}

export function allGroupsForUI(
  groups: Model<GroupDoc>[] | GroupDoc[],
  userSettings: UserSettingsDoc | undefined,
  withArchived = false
) {
  const groupsForUI = groups
    .filter((group) => {
      if (withArchived) return true

      return (
        userSettings?.GroupsSettings?.[group._id ?? '']?.GroupStatus !==
        GroupStatus.ARCHIVED
      )
    })
    .sort((group) => (isConvertedGroup(group) ? -1 : 1))

  if (groupsForUI.length === 0 && is24meApp()) {
    const tempGroupForUI: GroupDoc = {
      CreationDate: '',
      GroupColor: '',
      GroupSettings: {
        IsAllParticipantsCanAddItems: '',
        IsAllParticipantsCanAddParticipants: '',
        IsAllParticipantsCanEditGroupMetadata: ''
      },
      Name: t('personalCalendar'),
      OwnerID: '',
      Participants: {},
      PendingParticipants: {},
      Photo: '',
      PrivacyMode: '',
      PrivateLinkPassword: '',
      PrivateLinkUrl: '',
      Status: '',
      Version: '',
      VerifiedGroupStatus: '',
      SyncProblem: undefined,
      _message: t('clickToAddYour'),
      _sortOrder: 0,
      _id: `tempid`,
      _rev: 'temprev',
      BusinessInfo: undefined,
      LastUpdateEvent: undefined,
      Events: undefined
    }

    return [tempGroupForUI]
  }

  return groupsForUI
}

export async function googleEventsGroup(id: string) {
  return (await allGroups()).find((group) => group._id.includes(id))
}

export async function allOutlookCalendars() {
  return (await allGroups()).filter((group) =>
    group._id.includes(outlookPrefix)
  )
}

export async function RemoveThirdPartyCalendar(id: string) {
  return Database.write(async () => {
    return (await allGroups())
      .find((group) => group._id.includes(id))
      ?.destroyPermanently()
  })
}

export async function localGroupById(id: string) {
  return (
    await Database.getCollection<GroupDoc>(CollectionType.GROUP)
      .query(Q.where('_id', id))
      .fetch()
  )[0]
}

export async function setGroupSortOrder(id: string, sortOrder: number) {
  return await Database.write(async () => {
    const group = await localGroupById(id)

    return await group.update(() => {
      group._raw['_sortOrder'] = sortOrder
    })
  })
}
