import {
  Button,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  FormControlLabel,
  Grid
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { CloseIcon } from 'assets/icons'
import { FormEvent, ReactNode, useState } from 'react'
import Logger from 'services/Logger'
import { theme } from 'theme'
import { t } from 'i18next'
import { StyledDialog } from './styled.dialog'
import i18n from 'i18n'
import { CheckBox } from '@mui/icons-material'
import LocalStorage from 'services/LocalStorage'

type ConfirmationDialogProps = {
  rightButtonColor?: string
  leftButtonColor?: string
  leftButton?: string
  rightButton?: string
  leftButtonDisabled?: boolean
  cancelDisabled?: boolean
  showCloseIcon?: boolean
  rightButtonHidden?: boolean
  leftButtonHidden?: boolean
  title: string
  content: ReactNode | string
  handleLeftButton?: () => any
  open: boolean
  handleClose?: () => any
  handleRightButton?: () => any
  pointerEvents?: 'all' | 'none' | undefined
  followForm?: boolean
  contentDir?: 'ltr' | 'rtl'
  noButtons?: boolean
  doNotShowButtonEnabled?: boolean
  doNotShowButtonKey?: string
  doNotShowButtonValue?: string
  rightButtonDisabled?: boolean
  titlePadding?: string
  fullWidth?: boolean
  classes?: any
  scroll?: any
  disableScrollLock?: boolean
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (handleRightButton) handleRightButton()
  }
  const {
    open,
    title,
    content,
    rightButtonHidden,
    leftButtonDisabled,
    rightButtonColor,
    rightButton,
    showCloseIcon,
    followForm,
    leftButtonColor,
    leftButtonHidden,
    handleLeftButton,
    leftButton,
    handleRightButton,
    handleClose: handleCancel,
    cancelDisabled,
    ...rest
  } = props

  const [dontShowAgain, onDontShowAgain] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDontShowAgain(event.target.checked)
  }

  const handleLeft = () => {
    if (handleLeftButton) handleLeftButton()

    if (
      dontShowAgain &&
      props.doNotShowButtonKey &&
      props.doNotShowButtonEnabled &&
      props.doNotShowButtonValue
    ) {
      LocalStorage.set(props.doNotShowButtonKey, props.doNotShowButtonValue)
    }
  }

  const handleRight = () => {
    if (handleRightButton) handleRightButton()

    if (
      dontShowAgain &&
      props.doNotShowButtonKey &&
      props.doNotShowButtonEnabled &&
      props.doNotShowButtonValue
    ) {
      LocalStorage.set(props.doNotShowButtonKey, props.doNotShowButtonValue)
    }
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 8 }
      }}
      {...rest}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            padding: props.titlePadding
          }}
        >
          {title}
          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        {props.content && (
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            sx={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              display: 'flex'
            }}
          >
            {typeof content === 'string' ? (
              <pre
                style={{
                  textAlign: 'center',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {content}
              </pre>
            ) : (
              content
            )}
            {props.doNotShowButtonEnabled && (
              <FormControlLabel
                sx={{
                  marginTop: '32px'
                }}
                control={
                  <Checkbox
                    checked={dontShowAgain}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('dontShowAgain')}
              />
            )}
          </Grid>
        )}
        {props.noButtons ? (
          <></>
        ) : (
          <DialogActions>
            {leftButtonHidden ? (
              <></>
            ) : (
              <Button
                id="declineButton"
                focusRipple={false}
                disabled={leftButtonDisabled ?? false}
                onClick={handleLeft}
                style={{
                  color: leftButtonColor
                    ? leftButtonColor
                    : theme.palette.button.text.groupcal
                }}
              >
                {leftButton ?? i18n.t('cancel')}
              </Button>
            )}

            {rightButtonHidden ? (
              <></>
            ) : (
              <Button
                id="confirmButton"
                autoFocus
                type={!followForm ? 'button' : 'submit'}
                focusRipple={false}
                style={{
                  color: rightButtonColor
                    ? rightButtonColor
                    : theme.palette.button.text.groupcal,
                  opacity: props.cancelDisabled ? 0.5 : 1
                }}
                disabled={cancelDisabled ?? false}
                onClick={handleRightButton ? handleRight : handleCancel}
              >
                {rightButton ?? i18n.t('ok')}
              </Button>
            )}
          </DialogActions>
        )}
      </form>
    </StyledDialog>
  )
}
