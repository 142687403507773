import * as Styled from '../containers/styled'
import { Spinner } from 'assets/icons'

export interface LoadingContainerProps {
  loading: number
  dontShowSpinner?: boolean
}

export default function LoadingContainer(props: LoadingContainerProps) {
  return (
    <Styled.LoadingOverlay
      style={{ opacity: props.loading ? 1 : 0 }}
      loading={props.loading ? 1 : 0}
    >
      {!props.dontShowSpinner && (
        <Styled.LoadingSpinner loading={props.loading ? 1 : 0} />
      )}
    </Styled.LoadingOverlay>
  )
}
