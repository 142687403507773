import { SegmentItem } from 'components/buttons/SegmentButton'
import BrowserDialog from 'components/dialogs/BrwoserDialog'
import { fromJSON, toJSON } from 'flatted'
import { t } from 'i18next'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { DocType, MasterLabelLabel } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import { isGroupcalApp } from 'utils/appType'

export enum AppUIType {
  calendars = 'calendars',
  tasks = 'tasks',
  notes = 'notes'
}

export function appUITypeToDocType(appUIType: AppUIType): string {
  switch (appUIType) {
    case AppUIType.calendars:
      return DocType.EVENT
    case AppUIType.tasks:
      return DocType.TASK
    case AppUIType.notes:
      return DocType.NOTE
  }
}

interface AppUITypeProviderProps {
  children: ReactNode
}

export default function AppUITypeProvider(props: AppUITypeProviderProps) {
  const tasks: SegmentItem = {
    value: AppUIType.tasks,
    title: t('tasks')
  }
  const calendars: SegmentItem = {
    value: AppUIType.calendars,
    title: t('calendar')
  }
  const notes: SegmentItem = { value: AppUIType.notes, title: t('notes') }

  const uiTypes = [calendars, tasks, notes]

  const [giftLink, setGiftLink] = useState<string | undefined>(undefined)

  const [uiType, onUIType] = useState<SegmentItem>(
    isGroupcalApp()
      ? calendars
      : uiTypes.find(
          (segment) =>
            segment.value ===
            (LocalStorage.get(LocalStorageKey.CURRENT_UI) ?? AppUIType.tasks)
        ) ?? tasks
  )

  useEffect(() => {
    LocalStorage.set(LocalStorageKey.CURRENT_UI, toJSON(uiType.value))
  }, [uiType])

  const [labelInfo, setLabelInfo] = useState<string | undefined>(undefined)

  const [selectedLabel, setSelectedLabel] = useState<
    MasterLabelLabel | undefined
  >(undefined)

  const goToTasks = () => {
    onUIType(tasks)
  }

  const goToNotes = () => {
    onUIType(notes)
  }

  const goToCalendar = () => {
    onUIType(calendars)
  }

  function getCurrentIndex() {
    if (uiType.value === AppUIType.calendars) return 0
    if (uiType.value === AppUIType.tasks) return 1
    if (uiType.value === AppUIType.notes) return 2

    return 1
  }

  const context: AppUITypeContextProps = {
    uiType,
    onUIType,
    uiTypes,
    selectedLabel,
    setSelectedLabel,
    goToTasks,
    goToNotes,
    goToCalendar,
    getCurrentIndex,
    labelInfo,
    setLabelInfo,
    giftLink,
    setGiftLink
  }

  return (
    <AppUITypeContext.Provider value={context}>
      {props.children}
      {giftLink && (
        <BrowserDialog
          open={giftLink != undefined}
          setOpen={function (b: boolean): void {
            if (!b) setGiftLink(undefined)
          }}
          url={giftLink}
        />
      )}
    </AppUITypeContext.Provider>
  )
}

interface AppUITypeContextProps {
  uiType: SegmentItem
  onUIType: (item: SegmentItem) => void
  uiTypes: SegmentItem[]
  selectedLabel?: MasterLabelLabel
  setSelectedLabel: (l: MasterLabelLabel | undefined) => void
  goToTasks: () => void
  goToNotes: () => void
  goToCalendar: () => void
  getCurrentIndex(): number
  labelInfo: string | undefined
  setLabelInfo: (id: string | undefined) => void
  giftLink?: string
  setGiftLink: (s?: string) => void
}

const AppUITypeContext = createContext<AppUITypeContextProps>({
  uiType: {
    value: AppUIType.calendars,
    title: t('calendars')
  },
  onUIType: () => {},
  uiTypes: [],
  selectedLabel: undefined,
  setSelectedLabel: () => {},
  goToTasks: () => {},
  goToNotes: () => {},
  goToCalendar: () => {},
  getCurrentIndex: () => 1,
  labelInfo: undefined,
  setLabelInfo: () => {},
  giftLink: undefined,
  setGiftLink: () => {}
})

export function useAppUIType(): AppUITypeContextProps {
  return useContext(AppUITypeContext)
}
