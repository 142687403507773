import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { LockGroup as LockGroupIcon } from 'assets/icons'
import i18n from 'i18n'

interface ContainerWithBottomBadgeProps {
  children: ReactNode
  showLock: boolean
  marginRight?: number
  lockIcon?: ReactNode
  topRightIcon?: ReactNode
  action?: () => void
}

export default function ContainerWithBottomBadgeIcon(
  props: ContainerWithBottomBadgeProps
) {
  return (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'end'}
      justifyItems={'end'}
      alignItems={'end'}
      onClick={props.action}
    >
      {props.children}
      {props.showLock ? (
        <div
          style={{
            width: '18px',
            height: '18px',
            position: 'absolute',
            right: i18n.dir() === 'ltr' ? '-4px' : undefined
          }}
        >
          {props.lockIcon ?? <LockGroupIcon width={18} height={18} />}
        </div>
      ) : (
        <></>
      )}
      {props.topRightIcon && (
        <div
          style={{
            position: 'absolute',
            top: '-4px',
            right: i18n.dir() === 'ltr' ? '-4px' : undefined
          }}
        >
          {props.topRightIcon}
        </div>
      )}
    </Box>
  )
}
