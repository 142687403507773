export function radiusInMeters(lenType: string, radius: number): number {
  switch (lenType) {
    case '1':
      return radius
    case '2':
      return radius * 0.9144
    case '3':
      return radius * 1609.34
    case '4':
      return radius * 1000
    default:
      return radius
  }
}

export function calculateZoomLevel(circleRadius: number): number {
  if (circleRadius === 0) return 16

  const scale = circleRadius / 125
  return Number((15.5 - Math.log(scale) / Math.log(2)).toFixed(2))
}
