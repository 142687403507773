import { ClickAwayListener } from '@mui/material'
import PopupSection from 'components/popup/PopupSection'
import { ReactNode } from 'react'
import { COLORS } from 'utils/colors'
import { useAddGroup } from './addGroup/AddGroupProvider'

import * as Styled from './styled'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import { t } from 'i18next'

interface ColorsPopupInterface {
  color?: string
  setColor: (color: string) => void
  selector?: ReactNode
  open?: boolean
  setOpen: (b: boolean) => void
}

function ColorsPopupContent(props: ColorsPopupInterface) {
  return (
    <PopupSection>
      <Styled.ColorsPopupContent>
        {COLORS.map((color) => (
          <Styled.ColorsPopupColor
            id={color.replace('#', '')}
            key={color}
            style={{ backgroundColor: color }}
            onClick={() => {
              props.setColor(color)
              props.setOpen(false)
            }}
          />
        ))}
      </Styled.ColorsPopupContent>
    </PopupSection>
  )
}

export default function ColorsPopup(props: ColorsPopupInterface) {
  return (
    <Styled.ColorsPopup
      open={props.open}
      setOpen={props.setOpen}
      placement="bottom"
      content={<ColorsPopupContent {...props} />}
    >
      {props.selector ?? (
        <div>
          <GroupcalTooltip title={t('setCalendarColor')}>
            <Styled.ColorsPopupSelector
              onClick={() => {
                props.setOpen(true)
              }}
              color={props.color}
            />
          </GroupcalTooltip>
        </div>
      )}
    </Styled.ColorsPopup>
  )
}
