export enum LocalStorageKey {
  PASSWORD = 'tfMepassword',
  ACCESS_TOKEN = 'accessToken',
  FIREBASE_TOKEN = 'firebaseToken',
  CALENDAR_VIEW = 'calendarView',
  DEVICE_ID = 'deviceId',
  LAST_UPDATE = 'lastUpdate',
  DEBUG_LOGGER = 'debugLogger',
  USER_ID = 'userId',
  SELECTED_GROUP = 'groupServerId',
  PHONE_NUMBER = 'phoneNumber',
  VISIBLE_DATE = 'visibleDate',
  NEW_USER = 'newUser',
  CACHED_GROUP_ID_JOIN = 'cachedGroupToJoin',
  CACHED_GROUP_PASSWORD = 'cachedGroupPassword',
  CLEAN_GET_CHANGES_DONE = 'cleanGetChanges',
  VISIBLE_EVENT = 'VISIBLE_EVENT',
  LANDLINE_LOGIN = 'LANDLINE_LOGIN',
  SHOULD_KEEP_POPUP = 'SHOULD_KEEP_POPUP',
  SAW_MAIN_SCREEN = 'SAW_MAIN_SCREEN',
  OAUTH2_EXPIRE = 'OauthExpire',
  VISIBLE_FROM = 'VisibleFrom',
  VISIBLE_TO = 'VisibleTo',
  EXPIRY_DIALOG_SHOWN = 'EXPIRY_DIALOG_SHOWN',
  ADDING_GROUP = 'ADDING_GROUP',
  LAST_APP_SYNC = 'LAST_APP_SYNC',
  PENDING_EVENT_DATA = 'PENDING_EVENT_NAME',
  NOTIFICATION_PERMISSION_SHOWN = 'NOTIFICATION_PERMISSION_SHOWN',
  DONT_SHOW_NOTIF = 'DONT_SHOW_NOTIF',
  APP_RELOAD = 'APP_RELOAD',
  DEFAULT_GROUP = 'DEFAULT_GROUP',
  LAST_FOCUS_SYNC = 'LAST_FOCUS_SYNC',
  USER_EMAIL = 'USER_EMAIL',
  USER_PASS = 'USER_PASS',
  SHOW_DEV_INFO = 'SHOW_DEV_INFO',
  CURRENT_UI = 'CURRENT_UI',
  FOCUS_EVENT = 'FOCUS_EVENT',
  NOTE_DETAILS_ID = 'NOTE_DETAILS_ID',
  CODE_TIME_SECONDS = 'CODE_TIME_SECONDS',
  SOMEDAY_DATE = 'SOMEDAY_DATE',
  SOMEDAY_ALL_DAY = 'SOMEDAY_ALL_DAY',
  CODE_PROVIDER = 'CODE_PROVIDER',
  CHECKMOBI_TOKEN = 'CM_TOKEN',
  PHONE_NUMBER_TYPE = 'PHONE_NUMBER_TYPE',
  OUTLOOK_DEFAULT_CALENDAR = 'OUTLOOK_DEFAULT_CALENDAR',
  ICLOUD_EMAIL = 'ICLOUD_EMAIL',
  MORE_EVENTS_OPENED = 'MORE_EVENTS_OPENED',
  TIMEZONE = 'TIMEZONE'
}
