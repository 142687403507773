import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { uuid } from 'utils/uuid'

export function getOrUpdateDeviceId() {
  const token = LocalStorage.get(LocalStorageKey.DEVICE_ID)
  if (token) {
    return token
  }
  const newToken = uuid()
  LocalStorage.set(LocalStorageKey.DEVICE_ID, newToken)
  return newToken
}

export const appStoreLink24me =
  'https://apps.apple.com/us/app/24me-smart-personal-assistant/id557745942'
export const googlePlayLink24me =
  'https://play.google.com/store/apps/details?id=me.twentyfour.www'
export const appStoreLinkGroupcal =
  'https://apps.apple.com/us/app/groupcal-shared-calendar/id1472335927'
export const googlePlayLinkGroupcal =
  'https://play.google.com/store/apps/details?id=app.groupcal.www'
