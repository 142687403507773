import { BlockedFlow } from 'analytics/Analytics'
import { theme } from 'theme'
import { AccountDoc, GroupDoc } from 'types/api/changes'
import { Tier } from 'utils/api/accounts'
import { PlanName } from '../dialogs/payment/PlansInfoDialog'
import { OutOfTierDialog } from './OutOfTierDialog'

interface TierHoverProps {
  group: GroupDoc
  account: AccountDoc
  isOutOfTier: boolean
  topOffset: number
  opened: boolean
  setOpen: (b: boolean) => void
}

export default function TierHover(props: TierHoverProps) {
  const showOutOfTier = () => {
    if (!props.opened) {
      props.setOpen(true)
    }
  }

  return props.isOutOfTier ? (
    <div
      onClick={showOutOfTier}
      style={{
        zIndex: 12,
        position: 'absolute',
        opacity: 0.5,
        background: theme.palette.background.default,
        top: props.topOffset,
        bottom: 0,
        right: 0,
        left: 0,
        overflow: 'hidden',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        pointerEvents: props.isOutOfTier && !props.opened ? 'all' : 'none'
      }}
    >
      {props.opened && (
        <OutOfTierDialog
          flow={BlockedFlow.groupInfo}
          group={props.group}
          setOpen={props.setOpen}
          opened={props.opened}
          account={props.account}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

export function humanReadableTier(tier: Tier) {
  switch (tier) {
    case Tier.PRO:
      return 'GroupCal Pro'
    case Tier.BUSINESS:
      return 'GroupCal Business'
    case Tier.BUSINESS_PLUS:
      return 'GroupCal Business Plus'
  }

  return ''
}

export function tierToPlan(tier: Tier): PlanName {
  switch (tier) {
    case Tier.PRO:
      return 'tier1'
    case Tier.BUSINESS:
      return 'tier2'
    case Tier.BUSINESS_PLUS:
      return 'tier3'

    default:
      return '_free_tier'
  }
}
