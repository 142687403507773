import { GroupBusinessInfo, GroupParticipant, GroupSettings } from './changes'

export enum GROUP_INFO_ACTION_TYPES {
  METADATA = '3',
  ADD_PARTICIPANTS = '1',
  EDIT_PARTICIPANTS = '2',
  GROUP_SETTINGS = '4'
}

export interface UpdateGroupData {
  DeviceChangeID: string | null
  ActionType: string
  UserID: string | null
  GroupID: string
}

export interface UpdateLinkData extends UpdateGroupData {
  PrivateLinkUrl: string
  PrivateLinkPassword: string
}

export interface UpdateTitleData extends UpdateGroupData {
  Name: string
}

export interface UpdateBusinessInfoData extends UpdateGroupData {
  BusinessInfo: GroupBusinessInfo
}

export interface UpdatePhotoData extends UpdateGroupData {
  Photo: string
}

export interface UpdateGroupSettingsData extends UpdateGroupData {
  GroupSettings: GroupSettings
}

export interface UpdateGroupPrivacyMode extends UpdateGroupSettingsData {
  PrivacyMode: string
}

export interface UpdateParticipantData extends UpdateGroupData {
  Participants: Record<string, GroupParticipant>
}
