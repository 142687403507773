import { Database } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import migrations from 'model/migrations'
import schema from 'model/schema'

import Account from './models/Account'
import AppConstants from './models/AppConstants'
import Event from './models/Event'
import ThirdPartyCalendarAccount from './models/ThirdPartyCalendarAccount'
import Group from './models/Group'
import MasterLabel from './models/MasterLabel'
import Participant from './models/Participant'
import Profile from './models/Profile'
import UserSettings from './models/UserSettings'
import ParticipantSuggestion from './models/ParticipantSuggestion'

const adapter = new LokiJSAdapter({
  schema,
  migrations,
  useWebWorker: false,
  useIncrementalIndexedDB: false,
  onQuotaExceededError: (error) => {
    console.log(
      'Browser ran out of disk space -- offer the user to reload the app or log out',
      error
    )
  },
  onSetUpError: (error) => {
    console.log(
      'Database failed to load -- offer the user to reload the app or log out',
      error
    )
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {
      console.log(`
        // Called when this adapter is forced to overwrite contents of IndexedDB.
        // This happens if there's another open tab of the same app that's making changes.
        // Try to synchronize the app now, and if user is offline, alert them that if they close this
        // tab, some data may be lost
      `)
    },
    onversionchange: () => {
      console.log(`
        // database was deleted in another browser tab (user logged out), so we must make sure we delete
        // it in this tab as well - usually best to just refresh the page  
      `)
    }
  }
})

const database = new Database({
  adapter,
  modelClasses: [
    Account,

    Event,

    Group,

    MasterLabel,

    Profile,

    UserSettings,
    AppConstants,
    Participant,
    ThirdPartyCalendarAccount,
    ParticipantSuggestion
  ]
})

export default database
