import { Box, Grid } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import { borderColor, borderRadius } from '@mui/system'

export const SearchColumn = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  position: 'relative',
  flex: 1,
  maxWidth: 'calc(33.33%)',
  height: '100%'
}))

export const Scrollable = styled(Grid)(({ theme }) => ({
  overflow: 'hidden',
  padding: theme.spacing(0, 4),
  overflowY: 'auto',
  backgroundColor: 'white',
  height: 'fit-content',
  maxHeight: '650px',
  pointerEvents: 'all',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,
  gap: 1
}))

export const Time = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px'
}))

export const Date = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px'
}))
