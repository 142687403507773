import { AxiosError } from 'axios'
import { processDownloadedData } from 'components/providers/GetChangesProvider'
import database from 'model'
import Api from 'services/Api'
import Database from 'services/db/Database'
import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import Logger from 'services/Logger'
import {
  ChangeDocsByTypes,
  UserSettingsDoc,
  UserSettingsGroupSettings
} from 'types/api/changes'
import { Model } from 'types/model'
import { mapKeysIfNeeded } from 'utils/object'
import ChangesService from 'services/db/Changes'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

export const API_UPDATE_USER_SETTINGS_URL = '/general/settings/user/edit/'
export const API_GET_USER_SETTINGS = '/general/settings/user'

export async function updateUserSettings(
  userSettingsToUpdate: UserSettingsDoc
) {
  try {
    const data: UserSettingsDoc = {
      ...userSettingsToUpdate,
      FirstDayOfTheWeek: userSettingsToUpdate.FirstDayOfTheWeek,
      Gender: '0',
      TimeZoneOffsetInSecFromGMT: '0'
    }

    /**
     * Remove Devices from the data object
     * It's a legacy field which
     */
    delete data['Devices']

    const updatedUserSettings = await (
      await Api.post(
        `${API_UPDATE_USER_SETTINGS_URL}${userSettingsToUpdate._id}`,
        data
      )
    ).data

    const dataToLocalDb: UserSettingsDoc = {
      ...data,
      _rev: mapKeysIfNeeded(updatedUserSettings)._rev
    }

    const settingsToDb: Record<string, UserSettingsDoc> = {}
    settingsToDb[dataToLocalDb._id ?? ''] = { ...dataToLocalDb }

    const userSettingsUpdate: ChangeDocsByTypes = {
      Group: {},
      GroupEvent: {},
      Account: {},
      MasterLabel: {},
      Profile: {},
      UserSettings: settingsToDb,
      Task: {},
      Note: {},
      REGULAR_EVENT: {}
    }

    await processDownloadedData(userSettingsUpdate)

    return updatedUserSettings
  } catch (e) {
    Logger.red('error update user settings', e)
    if (e instanceof AxiosError) {
      if (e.response?.status === 409) {
        const userSettingsFromServer = (await Api.get(API_GET_USER_SETTINGS))
          .data as UserSettingsDoc
        Logger.blue('Received from server', userSettingsFromServer)
        await cacheUserSettings({
          ...userSettingsFromServer,
          ...userSettingsToUpdate,
          _rev: userSettingsFromServer._rev
        })
        return userSettingsFromServer
      }
    }

    return userSettingsToUpdate
  }
}

export async function updateGroupsSetting(
  data: Model<UserSettingsDoc> | UserSettingsDoc,
  groupId: string,
  toUpdate: UserSettingsGroupSettings
) {
  const toServer = Database.toServerObject<UserSettingsDoc>(
    data as any
  ) as UserSettingsDoc

  if (!toServer.GroupsSettings) {
    toServer.GroupsSettings = {}
  }

  if (toUpdate.Default === '1') {
    Object.values(toServer.GroupsSettings).map(
      (groupSettings) => (groupSettings.Default = '0')
    )
  }
  toServer.GroupsSettings[groupId] = {
    ...toServer.GroupsSettings[groupId],
    ...toUpdate
  }

  const dataToLocalDb = cacheUserSettings(toServer)

  handleAppBadge()

  return dataToLocalDb
}

export async function cacheUserSettings(toServer: UserSettingsDoc) {
  const userSettings = await updateUserSettings(toServer)

  const dataToLocalDb: UserSettingsDoc = {
    ...toServer,
    _rev: mapKeysIfNeeded(userSettings)._rev
  }

  const settingsToDb: Record<string, UserSettingsDoc> = {}
  settingsToDb[dataToLocalDb._id ?? ''] = { ...dataToLocalDb }

  const userSettingsUpdate: ChangeDocsByTypes = {
    Group: {},
    GroupEvent: {},
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: settingsToDb,
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  await processDownloadedData(userSettingsUpdate)

  return dataToLocalDb
}

export async function clearUnread(groupId: string) {
  const userSettings = await UserSettings()
  const groupsSettings = userSettings?.GroupsSettings?.[groupId]

  if (
    groupsSettings &&
    groupsSettings.UnreadItems &&
    (Object.keys(groupsSettings.UnreadItems).length > 0 ||
      groupsSettings.Unread === '1')
  ) {
    groupsSettings.UnreadItems = {}
    groupsSettings.Unread = '0'
    await updateGroupsSetting(userSettings, groupId, groupsSettings)
  }
}

export async function handleAppBadge() {
  try {
    const userSettings = await UserSettings()
    let totalUnread = 0

    Object.values(userSettings?.GroupsSettings ?? {}).forEach((settings) => {
      totalUnread += Object.keys(settings.UnreadItems ?? {}).length
    })

    if (
      'setAppBadge' in window.navigator &&
      LocalStorage.get(LocalStorageKey.USER_ID) &&
      totalUnread > 0
    ) {
      ;(window.navigator as unknown as any).setAppBadge(totalUnread)
    }
  } catch (e) {
    //db might be reset already
  }
}
