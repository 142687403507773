import { Box, Grid } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import { borderColor, borderRadius } from '@mui/system'

export const PicContainer = styled(Grid)(({ theme }) => ({
  width: 52,
  height: 52,
  borderRadius: 0,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Avatar = styled(Grid)(({ theme }) => ({
  width: 52,
  height: 52,
  borderRadius: 9999,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fontSize: '1rem',

  '&[data-size="sm"]': {
    width: 40,
    height: 40,
    fontSize: '0.875rem'
  },

  '&[data-size="xs"]': {
    width: 20,
    height: 20,
    fontSize: '0.75rem'
  },

  '&[data-size="xxs"]': {
    width: 16,
    height: 16,
    fontSize: '0.75rem'
  },

  '&[data-asgroup="true"]': {
    alignItems: 'flex-end',
    '& svg': {
      color: '#FFF',
      width: '75%',
      height: '75%'
    }
  },

  '&[data-colored="true"]': {
    color: '#FFFFFF'
  },

  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: 9999,
    objectFit: 'cover'
  },

  '&[data-noradius="true"]': {
    '& img': {
      borderRadius: 0,
      objectFit: 'cover'
    }
  }
}))

interface MultiAccountBadgeProps {
  theme: Theme
  dir: 'ltr' | 'rtl'
}

export const MultiAccountBadge = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&[data-dir="ltr"]': {
    '& > div': {
      '&:nth-of-type(2), &:nth-of-type(3)': {
        margin: theme.spacing(0, 0, 0, -5)
      }
    }
  },

  '&[data-dir="rtl"]': {
    '& > div': {
      '&:nth-of-type(1), &:nth-of-type(2)': {
        margin: theme.spacing(0, 0, 0, -5)
      }
    }
  }
}))

export const InviteLinkBadge = styled(Grid)(({ theme }) => ({
  height: 40,
  width: 'inherit',
  background: '#0F7FEB1A',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: 10,
  marginTop: 6,
  color: theme.palette.text.primary
}))

export const InviteLinkContainer = styled(InviteLinkBadge)(({ theme }) => ({
  zIndex: 11,
  position: 'relative',
  top: -3,
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#e4e4e4',
  background: '#ffffff'
}))

export const CopyLinkButton = styled('div')(() => ({
  height: '100%',
  background: '#0F7FEB',
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.625rem',
  borderRadius: '0 0.55rem 0.55rem 0',
  cursor: 'pointer',
  fontWeight: 500
}))

interface LinkContentProps {
  allowScroll?: boolean
  theme: Theme
}

export const LinkContent = styled('div')((props: LinkContentProps) => ({
  flex: 1,
  padding: '0 0.625rem',
  whiteSpace: 'nowrap',
  overflow: props.allowScroll ? undefined : 'hidden',
  wordBreak: props.allowScroll ? undefined : 'break-all',
  textOverflow: props.allowScroll ? undefined : 'ellipsis',
  overflowX: props.allowScroll ? 'auto' : undefined,
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f2f2f2'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc'
  }
}))

interface ProBadgeProps {
  theme: Theme
  badgesize: number
}

export const ProBadgeBox = styled(Box)(
  ({ theme, badgesize }: ProBadgeProps) => ({
    display: 'flex',
    '& svg': {
      width: badgesize,
      height: 'auto',
      maxWidth: badgesize,
      color: theme.palette.input.placeholder
    }
  })
)
