import Popup from '../../../popup/Popup'
import PopupSection from '../../../popup/PopupSection'
import PopupSectionRow from '../../../popup/PopupSectionRow'
import { ReactNode, useState } from 'react'
import {
  buildRemoveParticipantData,
  changeParticipantAdminStatus,
  changeParticipantColor,
  updateGroupOnServer
} from '../../../../services/api/group'
import Logger from '../../../../services/Logger'
import {
  GroupDoc,
  GroupParticipant,
  GroupParticipantType
} from '../../../../types/api/changes'
import { Model } from '../../../../types/model'
import ColorsPopup from '../../ColorsPopup'
import { theme } from 'theme'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { GENERIC_ERROR } from 'utils/strings'
import { useConnection } from 'components/providers/ConnectionProvider'
import {
  amountOfAdminsInGroup,
  featureToMinimumTierNeeded,
  TierFeatures
} from 'utils/api/groups'

import { t } from 'i18next'
import { isOwnerForGroup } from 'services/db/utils/GroupUtils'
import { Tier } from 'utils/api/accounts'
import { parseErrorToMessage } from 'components/popups/eventInfo/EventPopup'

interface ParticipantsActionsPopupContentProps {
  participant: GroupParticipant
  participantId: string
  setLoading: (b: boolean) => void
  setIsOpen: (b: boolean) => void
  group: Model<GroupDoc> | GroupDoc
  setError: (error: string) => void
}

function ParticipantsActionsPopupContent(
  props: ParticipantsActionsPopupContentProps
) {
  return (
    <PopupSection>
      {LocalStorage.get(LocalStorageKey.USER_ID) === props.participantId ||
      props.participantId === props.group.OwnerID ? (
        <></>
      ) : (
        <>
          <AdminPermissions {...props} />
          <RemoveFromCalendar {...props} />
        </>
      )}
      <Color {...props} />
      {memberHasColor(props) ? <RemoveColor {...props} /> : <></>}
    </PopupSection>
  )
}

function memberHasColor(props: ParticipantsActionsPopupContentProps): boolean {
  return props.participant.MemberColor?.length > 0
}

function RemoveColor(props: ParticipantsActionsPopupContentProps) {
  const connection = useConnection()
  const removeColor = () => {
    if (connection.isConnectionAvailable())
      performAction(() => {
        return changeParticipantColor(
          props.participant,
          '',
          props.participantId,
          props.group
        )
      }, props)
  }
  return (
    <PopupSectionRow onlyTitle title={t('removeColor')} action={removeColor} />
  )
}

function Color(props: ParticipantsActionsPopupContentProps) {
  const connection = useConnection()
  const assignColor = function (color: string): void {
    if (connection.isConnectionAvailable())
      performAction(() => {
        return changeParticipantColor(
          props.participant,
          color,
          props.participantId,
          props.group
        )
      }, props)
  }
  const [showColorPicker, setShowColorPicker] = useState(false)

  const showColor = () => {
    setShowColorPicker(true)
  }

  let neededTier = featureToMinimumTierNeeded(
    TierFeatures.MEMBERCOLOR,
    amountOfAdminsInGroup(props.group)
  )

  if (!isOwnerForGroup(props.group)) neededTier = Tier.FREE

  return (
    <ColorsPopup
      open={showColorPicker}
      color={''}
      setOpen={setShowColorPicker}
      setColor={assignColor}
      selector={
        <div
          style={{
            color: theme.palette.text.primary,
            fontSize: 14,
            cursor: 'pointer'
          }}
        >
          <PopupSectionRow
            action={showColor}
            tierFeature={TierFeatures.MEMBERCOLOR}
            neededTier={neededTier}
            title={memberHasColor(props) ? t('updateColor') : t('assignColor')}
          />
        </div>
      }
    />
  )
}

function RemoveFromCalendar(props: ParticipantsActionsPopupContentProps) {
  const connection = useConnection()
  const removeFromCalendar = () => {
    if (connection.isConnectionAvailable())
      performAction(() => {
        const data = buildRemoveParticipantData(
          props.participant,
          props.participantId,
          props.group._id ?? ''
        )
        return updateGroupOnServer(data, props.group)
      }, props)
  }
  return (
    <PopupSectionRow
      title={`${t('removeFromSharedCalendar')}`}
      action={removeFromCalendar}
    />
  )
}

function AdminPermissions(props: ParticipantsActionsPopupContentProps) {
  const isAdmin = props.participant?.Type === GroupParticipantType.ADMIN
  const title = isAdmin ? t('removeAdminPermissions') : t('makeAdmin')
  const connection = useConnection()

  const toggleAdminPermissions = () => {
    if (connection.isConnectionAvailable())
      performAction(() => {
        const newType = isAdmin
          ? GroupParticipantType.REGULAR
          : GroupParticipantType.ADMIN

        props.setLoading(true)
        return changeParticipantAdminStatus(
          props.participant,
          newType,
          props.participantId,
          props.group
        )
      }, props)
  }

  let neededTier = featureToMinimumTierNeeded(
    TierFeatures.ADMINS,
    amountOfAdminsInGroup(props.group)
  )

  if (!isOwnerForGroup(props.group)) neededTier = Tier.FREE

  return (
    <PopupSectionRow
      tierFeature={TierFeatures.ADMINS}
      neededTier={neededTier}
      title={title}
      action={toggleAdminPermissions}
      onlyTitle
    />
  )
}

async function performAction(
  action: () => Promise<void>,
  props: ParticipantsActionsPopupContentProps
) {
  props.setLoading(true)
  try {
    await action()
  } catch (e) {
    Logger.red(String(e))
    props.setError(parseErrorToMessage(e))
  }
  props.setLoading(false)
  props.setIsOpen(false)
}

export interface ParticipantsActionsPopupArgs {
  children: ReactNode
  isOpen: boolean
  participant: GroupParticipant
  setLoading: (b: boolean) => void
  setIsOpen: (b: boolean) => void
  group: Model<GroupDoc> | GroupDoc
  participantId: string
  setError: (error: string) => void
}

export default function ParticipantsActionsPopup(
  props: ParticipantsActionsPopupArgs
) {
  return (
    <Popup
      open={props.isOpen}
      setOpen={props.setIsOpen}
      placement="right"
      content={<ParticipantsActionsPopupContent {...props} />}
    >
      {props.children}
    </Popup>
  )
}
