import { Axios } from 'axios'
import { PlanName } from 'components/dialogs/payment/PlansInfoDialog'
import dayjs from 'dayjs'
import { AccountDoc, AccountProduct } from 'types/api/changes'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { isTestEnv } from 'utils/env'

export const PADDLE_SKU_PRO_MONTHLY = isTestEnv() ? 43228 : 808144
export const PADDLE_SKU_PRO_YEARLY = isTestEnv() ? 42268 : 808145
export const PADDLE_SKU_BUSINESS_MONTHLY = isTestEnv() ? 43227 : 808147
export const PADDLE_SKU_BUSINESS_YEARLY = isTestEnv() ? 42269 : 808146
export const PADDLE_SKU_BUSINESS_PLUS_MONTHLY = isTestEnv() ? 43226 : 808149
export const PADDLE_SKU_BUSINESS_PLUS_YEARLY = isTestEnv() ? 42271 : 808148

export const P_SKU_PRO_MONTHLY =
  'app.groupcal.www.tier1_monthly_freetrial_1week'
export const P_SKU_PRO_YEARLY =
  'app.groupcal.www.tier1_annually_freetrial_1week'
export const P_SKU_BUSINESS_MONTHLY =
  'app.groupcal.www.tier2_monthly_freetrial_1week'
export const P_SKU_BUSINESS_YEARLY =
  'app.groupcal.www.tier2_annually_freetrial_1week'
export const P_SKU_BUSINESS_PLUS_MONTHLY =
  'app.groupcal.www.tier3_monthly_freetrial_1week'
export const P_SKU_BUSINESS_PLUS_YEARLY =
  'app.groupcal.www.tier3_annually_freetrial_1week'

export const GROUP_CAL_PROD_PLANIDS = [
  P_SKU_PRO_MONTHLY,
  P_SKU_PRO_YEARLY,
  P_SKU_BUSINESS_MONTHLY,
  P_SKU_BUSINESS_YEARLY,
  P_SKU_BUSINESS_PLUS_MONTHLY,
  P_SKU_BUSINESS_PLUS_YEARLY
]

export enum Tier {
  FREE = -1,
  PRO = 0,
  BUSINESS = 1,
  BUSINESS_PLUS = 2
}

export const WEB_APP_STORE_NAME = 'Paddle'
export const TRIAL_STORE_NAME = 'GroupCal'

export function getWebAppProduct(
  account: AccountDoc
): AccountProduct | undefined {
  if (!account || !account.Products) return undefined

  return account.Products.filter(
    (p: AccountProduct) => p.Store === WEB_APP_STORE_NAME
  ).pop()
}

export function isActivePlanFromAnotherStore(account: AccountDoc): boolean {
  const product = highestActiveProduct(account)

  if (!product) return false

  return (
    product.Store != WEB_APP_STORE_NAME && product.Store != TRIAL_STORE_NAME
  )
}

export function highestActiveProduct(
  account: AccountDoc
): AccountProduct | null | undefined {
  if (!account || !account.Products || account?.Products?.length === 0)
    return null

  const filterFunc = (p: AccountProduct) => {
    let expiryOfProduct = null
    if (p?.ExpiryDate?.includes('-')) {
      expiryOfProduct = dayjs(p?.ExpiryDate)
    } else if (
      (p?.ExpiryDate?.length ?? 0) > 13 &&
      !p?.ExpiryDate?.includes('.')
    ) {
      expiryOfProduct = dayjs(Number(p?.ExpiryDate))
    } else {
      expiryOfProduct = dayjs(Number(p.ExpiryDate) * 1000)
    }
    return (
      p.ExpiryDate?.length === 0 ||
      !p.ExpiryDate ||
      expiryOfProduct.isAfter(dayjs())
    )
  }

  const allPurchasedProducts = account?.Products?.filter(
    (p: AccountProduct) => {
      if (is24meApp()) return true
      return p.Store !== 'GroupCal'
    }
  ).filter(filterFunc)

  const allTrialProducts = account?.Products?.filter(
    (p: AccountProduct) => p.Store === 'GroupCal'
  ).filter(filterFunc)

  let products: AccountProduct[] = []

  if (allPurchasedProducts.length > 0) {
    products = allPurchasedProducts
  } else if (isGroupcalApp()) {
    products = allTrialProducts
  }

  const highestproduct = products
    .sort((a, b) => productIdToTier(a.ID) - productIdToTier(b.ID))
    .pop()

  return highestproduct
}

export function highestActiveTier(account: AccountDoc | undefined): Tier {
  if (!account || account?.Products?.length === 0) return Tier.FREE

  return productIdToTier(highestActiveProduct(account)?.ID.toLowerCase())
}

export function hasExhaustedTrialOptions(
  account: AccountDoc | undefined
): boolean {
  const trialProd = exhaustedProduct(account)
  if (trialProd) {
    return true
  }
  return false
}

export function exhaustedProduct(
  account: AccountDoc | undefined
): AccountProduct | undefined {
  return account?.Products?.filter((p: AccountProduct) => {
    const expiryOfProduct = dayjs(Number(p.ExpiryDate) * 1000)
    const expired =
      p.ExpiryDate?.length === 0 ||
      !p.ExpiryDate ||
      expiryOfProduct.isBefore(dayjs())

    return p.Store === 'GroupCal' && p.Status === 'trialing' && expired
  }).pop()
}

export function trialProduct(
  account: AccountDoc | undefined
): AccountProduct | undefined {
  return account?.Products?.filter((p: AccountProduct) => {
    const expiryOfProduct = dayjs(Number(p.ExpiryDate) * 1000)
    const notExpired =
      p.ExpiryDate?.length === 0 ||
      !p.ExpiryDate ||
      expiryOfProduct.isAfter(dayjs())

    return p.Store === 'GroupCal' && p.Status === 'trialing' && notExpired
  }).pop()
}

function productIdToTier(productId: string | null | undefined) {
  if (is24meApp()) {
    //for 24me it doesn't matter which product id, if we have some, then we return pro

    return productId ? Tier.PRO : Tier.FREE
  }

  if (!productId) return Tier.FREE
  switch (productId.toLocaleLowerCase()) {
    case P_SKU_PRO_MONTHLY.toLowerCase():
    case P_SKU_PRO_YEARLY.toLowerCase():
      return Tier.PRO
    case P_SKU_BUSINESS_MONTHLY.toLowerCase():
    case P_SKU_BUSINESS_YEARLY.toLowerCase():
      return Tier.BUSINESS
    case P_SKU_BUSINESS_PLUS_MONTHLY.toLowerCase():
    case P_SKU_BUSINESS_PLUS_YEARLY.toLowerCase():
      return Tier.BUSINESS_PLUS
    default:
      return Tier.FREE
  }
}

export function paddleProductToTier(productId: number) {
  switch (productId) {
    case PADDLE_SKU_PRO_MONTHLY:
    case PADDLE_SKU_PRO_YEARLY:
      return Tier.PRO
    case PADDLE_SKU_BUSINESS_MONTHLY:
    case PADDLE_SKU_BUSINESS_YEARLY:
      return Tier.BUSINESS
    case PADDLE_SKU_BUSINESS_PLUS_MONTHLY:
    case PADDLE_SKU_BUSINESS_PLUS_YEARLY:
      return Tier.BUSINESS_PLUS
    default:
      return Tier.FREE
  }
}

export function paddlePlanToTier(plan: PlanName) {
  switch (plan) {
    case '_free_tier':
      return Tier.FREE
    case 'tier1':
      return Tier.PRO
    case 'tier2':
      return Tier.BUSINESS
    case 'tier3':
      return Tier.BUSINESS_PLUS
  }
}
