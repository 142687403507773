import { Model } from '@nozbe/watermelondb'
import { text } from '@nozbe/watermelondb/decorators'
import { LocalStorageKey } from 'types/services/localStorage'

export default class AppConstants extends Model {
  static table = 'AppConstants'

  @text(LocalStorageKey.ACCESS_TOKEN) accessToken!: string
  @text(LocalStorageKey.CALENDAR_VIEW) calendarView!: string
  @text(LocalStorageKey.DEBUG_LOGGER) debugLogger!: string
  @text(LocalStorageKey.DEVICE_ID) deviceId!: string
  @text(LocalStorageKey.FIREBASE_TOKEN) firebaseToken!: string
  @text(LocalStorageKey.SELECTED_GROUP) groupServerId!: string
  @text(LocalStorageKey.LAST_UPDATE) lastUpdate!: string
  @text(LocalStorageKey.PASSWORD) password!: string
  @text(LocalStorageKey.PHONE_NUMBER) phoneNumber!: string
  @text(LocalStorageKey.USER_ID) userId!: string
}
