import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { ComponentType, useState } from 'react'
import { Model } from 'types/model'
import { EventDoc, MasterLabelDoc, SimpleLabel } from 'types/api/changes'
import PopupSection from 'components/popup/PopupSection'
import { t } from 'i18next'
import Logger from 'services/Logger'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import { CloseIcon, LabelIcon, PlusIcon } from 'assets/icons'
import { Box } from '@mui/material'
import { theme } from 'theme'
import { convertLabelColorToStringColor } from 'utils/masterLabel'
import { makeStyles } from '@material-ui/core/styles'

import SelectItemWithSearchPopup, {
  SelectableItem
} from '../SelectItemWithSearchPopup'
import { Circle } from '@mui/icons-material'
import IconButton from 'components/buttons/IconButton'
import { cl } from '@fullcalendar/core/internal-common'

export interface LabelsSectionProps {
  labels: SimpleLabel[]
  setLoading: (b: boolean) => void
  event: Model<EventDoc>
  masterLabel: Model<MasterLabelDoc>
  setLabels: (labels: SimpleLabel[]) => void
}

function LabelsSection(props: LabelsSectionProps) {
  const [addLabelState, setAddLabelState] = useState(false)

  const labelsForTask = props.masterLabel.Labels.filter((masterLabelLabel) => {
    return (
      props.labels && typeof props.labels !== 'string' ? props.labels : []
    ).some((taskLabel) => {
      return taskLabel.LabelText === masterLabelLabel.ID
    })
  })

  Logger.blue('Label:List', labelsForTask)

  const onLabelPick = (item: SelectableItem) => {
    const selectedLabel = props.masterLabel.Labels?.find(
      (label) => label.ID === item.id
    )

    const existing =
      props.labels && typeof props.labels !== 'string' ? props.labels : []

    if (selectedLabel && existing) {
      if (!existing?.find((label) => label.LabelText === selectedLabel.ID)) {
        props.setLabels([...(existing ?? []), { LabelText: selectedLabel.ID }])
      }
    }
  }

  return (
    <PopupSection title={t('labels') ?? ''}>
      {labelsForTask.map((taskMasterLabel) => {
        const removeLabel = () => {
          props.setLabels(
            props.labels.filter(
              (label) => label.LabelText != taskMasterLabel.ID
            )
          )
        }

        return (
          <PopupSectionRow
            color={convertLabelColorToStringColor(taskMasterLabel.Color)}
            key={taskMasterLabel.ID}
            maxWidthIcon={18}
            cursor="default"
            icon={
              <LabelIcon style={{ width: 18, height: 18, marginLeft: 2 }} />
            }
            title={taskMasterLabel.Text}
            value={
              <IconButton size="xs" onClick={removeLabel}>
                <CloseIcon />
              </IconButton>
            }
          />
        )
      })}
      <SelectItemWithSearchPopup
        data={props.masterLabel.Labels.map((masterlLabelLabel) => {
          return {
            leftIconColor: convertLabelColorToStringColor(
              masterlLabelLabel.Color
            ),
            svgIconMaxWidth: 18,
            leftIcon: (
              <LabelIcon
                style={{
                  width: 18,
                  height: 18,
                  marginLeft: 2
                }}
              />
            ),
            id: masterlLabelLabel.ID,
            title: masterlLabelLabel.Text
          }
        })}
        searchHint={t('label')}
        selectedItemId={labelsForTask.at(0)?.ID}
        open={addLabelState}
        setOpen={setAddLabelState}
        selectItem={onLabelPick}
      >
        <div>
          <PopupSectionRow
            action={() => {
              setAddLabelState(true)
            }}
            titleColor={theme.palette.input.placeholder}
            icon={
              <Box marginLeft={'2px'}>
                <PlusIcon />
              </Box>
            }
            iconClass={'icon-small'}
            title={t('addLabel')}
            value=""
          />
        </div>
      </SelectItemWithSearchPopup>
    </PopupSection>
  )
}

export default compose(
  withObservables([], () => ({
    masterLabel: ObserveService.MasterLabel()
  }))
)(LabelsSection as ComponentType<unknown>) as any
