import axios from 'axios'
import { AddGroupContextProps } from 'components/popups/addGroup/AddGroupProvider'
import {
  doGetChanges,
  processDownloadedData
} from 'components/providers/GetChangesProvider'
import dayjs from 'dayjs'
import { firebaseConfig } from 'firebase-config'
import { GroupPrivacy } from 'model/models/Group'
import Participant from 'model/models/Participant'
import Api from 'services/Api'
import Database from 'services/db/Database'
import { RemoveThirdPartyEvents } from 'services/db/Queries/Event'
import { RemoveThirdPartyAccount } from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import { ParticipantsForGroup } from 'services/db/Queries/GroupQueries'
import { RemoveThirdPartyCalendar } from 'services/db/Queries/GroupQueries'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import {
  ChangeDocsByTypes,
  GroupBusinessInfo,
  GroupDoc,
  GroupParticipant,
  GroupParticipantStatus,
  GroupParticipantType,
  GroupSettings,
  ParticipantDataDoc
} from 'types/api/changes'
import {
  GROUP_INFO_ACTION_TYPES,
  UpdateBusinessInfoData,
  UpdateGroupData,
  UpdateGroupSettingsData,
  UpdateLinkData,
  UpdateParticipantData,
  UpdatePhotoData,
  UpdateTitleData
} from 'types/api/group'
import { CollectionType, Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { getOrUpdateDeviceId } from 'utils/device'
import { isTestEnv } from 'utils/env'
import { base64RemoveMetadata } from 'utils/file'
import { prepareDocForSaving } from 'utils/object'
import { uuid } from 'utils/uuid'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'
import { groupIdToProvider, groupIdToProviderTypeName } from 'utils/groups'
const API_GROUP_URL = '/v1/groups/'
const API_PROFILES = '/v1/profiles'
const API_JOIN_GROUP = '/v1/groups/join'
const API_DOWNGRADE_GROUP = (groupId: string) =>
  `/v1/groups/${groupId}/downgrade`

const API_SEARCH_GROUP = '/v1/groups/search'

export const genericUpdateFields = () => ({
  DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID),
  UserID: LocalStorage.get(LocalStorageKey.USER_ID)
})

export async function addGroup(
  pendingGroup: Model<GroupDoc>,
  addGroupContextProps: AddGroupContextProps
): Promise<GroupDoc> {
  //adding group to server first
  const deviceId = LocalStorage.get(LocalStorageKey.DEVICE_ID)
  const response = await Api.post(API_GROUP_URL, {
    DeviceChangeID: deviceId,
    GroupSettings: {
      IsAllParticipantsCanAddParticipants:
        addGroupContextProps.isCalendarChannel ? '0' : '1',
      IsAllParticipantsCanEditGroupMetadata:
        addGroupContextProps.isCalendarChannel ? '0' : '1',
      IsAllParticipantsCanAddItems: addGroupContextProps.isCalendarChannel
        ? '0'
        : '1'
    },
    PrivacyMode: addGroupContextProps.isCalendarChannel
      ? GroupPrivacy.CHANNEL
      : GroupPrivacy.PRIVATE,
    GroupColor: addGroupContextProps.color,
    Version: '3',
    Status: '1',
    Name: addGroupContextProps.title,
    UserID: LocalStorage.get(LocalStorageKey.USER_ID),
    Photo: base64RemoveMetadata(addGroupContextProps.photo)
  })
  const data = response.data
  const groupId = data['_id']
  LocalStorage.set(LocalStorageKey.ADDING_GROUP, groupId)
  await Database.write(async () => {
    return pendingGroup.update(() => {
      pendingGroup.PrivacyMode = data['PrivacyMode']
      pendingGroup._raw['_id'] = groupId
      pendingGroup._raw['_rev'] = data['_rev']
      pendingGroup.Version = data['Version']
    })
  })

  await generateLinkForGroup(pendingGroup)

  addGroupContextProps.setLink(pendingGroup.PrivateLinkUrl)

  return pendingGroup
}

export async function generateLinkForGroup(group: Model<GroupDoc>) {
  const groupId = group._id
  const deviceId = getOrUpdateDeviceId()
  const password = uuid().toString().replace('-', '').replace('/', '')

  //creating link to update
  const link = await makeLinkForGroup(groupId, password)

  //updating group with link
  return await updateLinkOnServer(
    {
      PrivateLinkUrl: link,
      PrivateLinkPassword: password,
      ActionType: GROUP_INFO_ACTION_TYPES.METADATA,
      UserID: LocalStorage.get(LocalStorageKey.USER_ID) ?? '',
      GroupID: groupId,
      DeviceChangeID: deviceId
    },
    group
  )
}

export async function updateLinkOnServer(
  updateLinkData: UpdateLinkData,
  group: Model<GroupDoc>
): Promise<GroupDoc> {
  return await Api.post(`${API_GROUP_URL}${group._id}`, {
    ...updateLinkData
  }).then((response) => {
    const updatedGroupWithLink = response.data as unknown as GroupDoc

    // updating local storage after link synced with server
    return Database.write(async () => {
      return group.update(() => {
        group.Photo = updatedGroupWithLink.Photo
        group.Participants = updatedGroupWithLink.Participants
        group.Name = updatedGroupWithLink.Name
        group.GroupColor = updatedGroupWithLink.GroupColor
        group.PrivateLinkUrl = updatedGroupWithLink.PrivateLinkUrl
        group.PrivateLinkPassword = updatedGroupWithLink.PrivateLinkPassword
        group._raw['_id'] = group._id // to preserve server ids locally
        group._message = 'You created this shared calendar'
        group.OwnerID = updatedGroupWithLink.OwnerID
        group.GroupSettings = updatedGroupWithLink.GroupSettings
      })
    })
  })
}

async function makeLinkForGroup(
  groupId: string,
  password: string
): Promise<string> {
  const linksInstance = axios.create({
    baseURL: 'https://firebasedynamiclinks.googleapis.com/v1'
  })
  const ofl = `${process.env.REACT_APP_LINK_DOMAIN}/calendar/${groupId}⁠${password}`
  const ibi = isTestEnv() ? 'GroupCal24meDev' : 'GroupCal24me'
  const ius = isTestEnv() ? 'groupcaldev' : 'groupcal'
  const isi = '1472335927'
  const link = (
    await linksInstance.post(`/shortLinks?key=${firebaseConfig.apiKey}`, {
      longDynamicLink: `https://groupcal.page.link/?link=https://${groupId}?${password}&ibi=${ibi}&ius=${ius}&isi=${isi}&ofl=${ofl}`
    })
  ).data

  return link.shortLink
}

export async function updateGroupTitle(
  title: string,
  group: Model<GroupDoc>
): Promise<void> {
  const updateTitle: UpdateTitleData = {
    ...genericUpdateFields(),
    ActionType: GROUP_INFO_ACTION_TYPES.METADATA,
    Name: title,
    GroupID: group._id
  }

  return updateGroupOnServer(updateTitle, group)
}

export async function updateGroupBI(
  biText: string,
  group: Model<GroupDoc>
): Promise<void> {
  const newBusinessInfo: GroupBusinessInfo = {
    Text: group.BusinessInfo?.Text ?? '',
    Active: group.BusinessInfo?.Text ? '1' : '0'
  }
  newBusinessInfo.Text = biText
  newBusinessInfo.Active = '1'
  const updateTitle: UpdateBusinessInfoData = {
    ...genericUpdateFields(),
    ActionType: GROUP_INFO_ACTION_TYPES.METADATA,
    BusinessInfo: newBusinessInfo,
    GroupID: group._id
  }

  return updateGroupOnServer(updateTitle, group)
}

export async function updateGroupPhoto(
  photo: string,
  group: Model<GroupDoc>
): Promise<void> {
  const updatePhoto: UpdatePhotoData = {
    ...genericUpdateFields(),
    ActionType: GROUP_INFO_ACTION_TYPES.METADATA,
    Photo: photo,
    GroupID: group._id
  }

  return updateGroupOnServer(updatePhoto, group)
}

export async function updateGroupSettings(
  GroupSettings: GroupSettings,
  group: Model<GroupDoc>
): Promise<void> {
  const updateSettings: UpdateGroupSettingsData = {
    ...genericUpdateFields(),
    ActionType: GROUP_INFO_ACTION_TYPES.GROUP_SETTINGS,
    GroupSettings: GroupSettings,
    GroupID: group._id
  }

  return updateGroupOnServer(updateSettings, group)
}

export async function changeParticipantAdminStatus(
  participant: GroupParticipant,
  newType: GroupParticipantType,
  participantId: string,
  group: Model<GroupDoc> | GroupDoc
) {
  const Participants: Record<string, GroupParticipant> = {}
  const updatedParticipantStatus: GroupParticipant = {
    ...participant,
    Type: newType
  }

  Participants[participantId] = updatedParticipantStatus
  const data: UpdateParticipantData = {
    ...genericUpdateFields(),
    Participants: Participants,
    ActionType: GROUP_INFO_ACTION_TYPES.EDIT_PARTICIPANTS,
    GroupID: group._id ?? ''
  }

  return updateGroupOnServer(data, group)
}

export async function changeParticipantColor(
  participant: GroupParticipant,
  newColor: string,
  participantId: string,
  group: Model<GroupDoc> | GroupDoc
) {
  const Participants: Record<string, GroupParticipant> = {}
  const updatedParticipantStatus: GroupParticipant = {
    ...participant,
    MemberColor: newColor
  }

  Participants[participantId] = updatedParticipantStatus
  const data: UpdateParticipantData = {
    ...genericUpdateFields(),
    Participants: Participants,
    ActionType: GROUP_INFO_ACTION_TYPES.EDIT_PARTICIPANTS,
    GroupID: group._id ?? ''
  }

  return updateGroupOnServer(data, group)
}

export async function updateGroupOnServer(
  data: UpdateGroupData,
  group: Model<GroupDoc> | GroupDoc
) {
  const serverUpdate = await (
    await Api.post(`${API_GROUP_URL}${data.GroupID}`, { ...data })
  ).data

  const groupChangeDoc: Record<string, GroupDoc> = prepareDocForSaving({
    ...serverUpdate
  })

  const docs: ChangeDocsByTypes = {
    Group: groupChangeDoc,
    GroupEvent: {},
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  return await processDownloadedData(docs)
}

export async function getProfilesForParticipants(participantKeys: string[]) {
  if (participantKeys.length == 0) return

  try {
    const participantData = (await (
      await Api.post(`${API_PROFILES}`, {
        AccountIDs: participantKeys
      })
    ).data) as ParticipantDataDoc[]

    const existing = await ParticipantsForGroup(participantKeys).fetch()

    Database.write(async () => {
      if (existing.length != 0) {
        existing.map((existingParticipant) => {
          const participantFromServer = participantData.find(
            (participant) =>
              participant.AccountID == existingParticipant.AccountID
          )

          if (!participantFromServer) return

          existingParticipant.update((doc) => {
            doc.AccountID = participantFromServer.AccountID
            doc.FullName = participantFromServer.FullName
            doc.LastUpdate = participantFromServer.LastUpdate
            doc.PhoneNumber = participantFromServer.PhoneNumber
            doc.PhotoURL = participantFromServer.PhotoURL
          })
        })
      }

      participantData.forEach((participantFromServer) => {
        if (
          !existing.find(
            (existingParticipant) =>
              existingParticipant.AccountID === participantFromServer.AccountID
          )
        ) {
          Database.getCollection<Participant>(
            CollectionType.PARTICIPANT
          ).create((newParticipant) => {
            newParticipant.AccountID = participantFromServer.AccountID
            newParticipant.FullName = participantFromServer.FullName
            newParticipant.LastUpdate = participantFromServer.LastUpdate
            newParticipant.PhoneNumber = participantFromServer.PhoneNumber
            newParticipant.PhotoURL = participantFromServer.PhotoURL
          })
        }
      })
    })
  } catch (e) {
    Logger.red('Profiles:Error', e)
  }
}

export function buildRemoveParticipantData(
  participantToRemove: GroupParticipant,
  participantToRemoveId: string,
  groupId: string
) {
  const userId = LocalStorage.get(LocalStorageKey.USER_ID)!

  const leftParticipant: GroupParticipant = {
    ...participantToRemove,
    Status: GroupParticipantStatus.INACTIVE,
    Type: GroupParticipantType.REGULAR,
    RemovedBy: userId
  }

  const participants: Record<string, GroupParticipant> = {}
  participants[participantToRemoveId] = leftParticipant

  const leaveData: UpdateParticipantData = {
    Participants: participants,
    DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID),
    ActionType: GROUP_INFO_ACTION_TYPES.EDIT_PARTICIPANTS,
    UserID: userId,
    GroupID: groupId
  }
  return leaveData
}

export async function joinGroup(groupId: string, password?: string) {
  const response = Api.post(API_JOIN_GROUP, {
    groupId,
    password,
    DeviceChangeID: getOrUpdateDeviceId()
  })

  const joinedGroup: GroupDoc = (await response).data
  joinedGroup._sortOrder = dayjs().unix()
  const toUpdate: Record<string, GroupDoc> = {}
  toUpdate[joinedGroup._id ?? ''] = { ...joinedGroup }
  const groupToSave: ChangeDocsByTypes = {
    Group: toUpdate,
    GroupEvent: {},
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  processDownloadedData(groupToSave)

  doGetChanges(
    LocalStorage.get(LocalStorageKey.DEVICE_ID),
    [joinedGroup._id ?? ''],
    null
  )

  return joinedGroup
}

export async function downgradeGroupOnServer(id: string) {
  const serverUpdate = await (await Api.post(API_DOWNGRADE_GROUP(id), {})).data

  const groupChangeDoc: Record<string, GroupDoc> = prepareDocForSaving({
    ...serverUpdate
  })

  const docs: ChangeDocsByTypes = {
    Group: groupChangeDoc,
    GroupEvent: {},
    Account: {},
    MasterLabel: {},
    Profile: {},
    UserSettings: {},
    Task: {},
    Note: {},
    REGULAR_EVENT: {}
  }

  return await processDownloadedData(docs)
}

export async function unlinkExternalCalendar(group: Model<GroupDoc>) {
  return connectThirdPartyToServer({
    action: 'DISCONNECT',
    type: groupIdToProvider(group._id) ?? '',
    email: group._message
  })
}

export interface ConnectThirdPartyToServerData {
  email: string
  username: string
  type: string
  connected: boolean
  expiry_date: number
  access_token: string
}

export async function connectThirdPartyToServer(
  data: Record<string, string>
): Promise<ConnectThirdPartyToServerData> {
  return (await Api.post(`/v1/services`, data)).data
}

export async function searchPublicGroups(text: string) {
  return await Api.get(`${API_SEARCH_GROUP}?search=${text}`)
}
