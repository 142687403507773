import {
  getDeviceId,
  Identify,
  identify,
  init,
  logEvent,
  setUserId
} from '@amplitude/analytics-browser'
import Logger from 'services/Logger'
import { SingularConfig, singularSdk } from 'singular-sdk'
import clevertap from 'clevertap-web-sdk'
import { AccountDoc } from 'types/api/changes'
import { highestActiveProduct } from 'utils/api/accounts'
import { getCookie } from 'utils/cookies'
import { isTestEnv } from 'utils/env'

import baseEventTracker, { ConfigType, EventsProps } from './baseEventTracker'

export interface AnalyticsProperty {
  key: UserProperties
  value: any
}

export interface DeviceProps {
  amplitudeDeviceId?: any
  singularDeviceId?: any
  clevertapDeviceId?: any
}

export default class Analytics implements baseEventTracker {
  private static instance: Analytics

  public static getInstance(deviceProps?: DeviceProps): Analytics {
    let amplitudeConfig: import('@amplitude/analytics-types').BrowserOptions = {
      logLevel: 0
    }
    const amplitudeDeviceId =
      getCookie('amplitudeId') || deviceProps?.amplitudeDeviceId
    const singularDeviceId =
      getCookie('singularId') || deviceProps?.singularDeviceId
    const clevertapDeviceId =
      getCookie('clevertapId') || deviceProps?.clevertapDeviceId

    if (amplitudeDeviceId) {
      amplitudeConfig = {
        ...amplitudeConfig,
        deviceId: amplitudeDeviceId
      }
      Logger.debug('custom device id for amplitude', amplitudeDeviceId)
    }

    init(process.env.REACT_APP_AMPLITUDE_KEY ?? '', undefined, amplitudeConfig) // amplitude init

    let config = new SingularConfig(
      process.env.REACT_APP_SINGULAR_KEY,
      process.env.REACT_APP_SINGULAR_SECRET,
      isTestEnv() ? 'app.groupcal.stage002-app' : 'app.groupcal.app'
    )

    if (singularDeviceId) {
      config = config.withPersistentSingularDeviceId(singularDeviceId)
      Logger.debug('custom device id for singular', singularDeviceId)
    }

    singularSdk.init(config) // singular init

    if (clevertapDeviceId) {
      //TODO: set user id here
    }

    clevertap.init(isTestEnv() ? 'TEST-R59-58R-655Z' : 'R7R-47Z-K55Z') // clevertap init
    clevertap.spa = true

    return this.instance && !deviceProps ? this.instance : new Analytics()
  }

  sendEvent(eventName: Events, eventProps?: EventsProps) {
    if (eventProps) {
      Logger.pink('analytics', eventProps)
      logEvent(eventName, eventProps)
      singularSdk.event(eventName, eventProps)
      clevertap.event.push(eventName, eventProps)
    }
  }

  sendOutOfTierDialogAnalytics(
    eventName: Events,
    isOwner: 'Yes' | 'No',
    subscription?: string,
    features?: String[],
    neededTier?: String,
    flow?: String
  ) {
    const props: EventsProps = {}

    props.category = 'Group_Blocked'
    props['Is owner'] = isOwner
    props.Flow = flow

    if (isOwner === 'Yes') {
      props['Is active subscription'] = subscription ? 'Yes' : 'No'
      props['Active subscription'] = subscription
      props['Features opened with'] = features
      props['Needed tier'] = neededTier
    }

    this.sendEvent(eventName, props)
  }

  setAnalyticsUserId(userId: string) {
    singularSdk.login(userId)
    setUserId(userId)
    clevertap.profile.push({
      Site: {
        ['Identity']: userId
      }
    })
  }

  setUserProperties(properties: AnalyticsProperty) {
    const identifyObj = new Identify()
    identifyObj.set(properties.key, properties.value)
    identify(identifyObj)

    clevertap.profile.push({
      Site: {
        [properties.key]: properties.value
      }
    })
  }

  get singularDeviceId() {
    return singularSdk.getSingularDeviceId()
  }

  get amplitudeDeviceId() {
    return getDeviceId()
  }

  removeUserProperty(key: string) {
    const identifyObj = new Identify()
    identifyObj.unset(key)
    identify(identifyObj)
  }

  appendSubscriptionInfo(account: AccountDoc) {
    const product = highestActiveProduct(account)

    this.setUserProperties({
      key: UserProperties.IS_ACTIVE_SUBS,
      value: product != null ? 'Yes' : 'No'
    })
    if (product) {
      this.setUserProperties({
        key: UserProperties.ACTIVE_SUBS,
        value: product?.ID
      })
    }
  }

  handleLogout() {
    singularSdk.logout()
  }
}

export enum UserProperties {
  APP_VERSION = 'App version',
  LANG_CODE = 'LanguageCode',
  IS_ACTIVE_SUBS = 'Is active subscription', // “Yes”/“No”
  ACTIVE_SUBS = 'Active subscription',
  INVITED = 'InvitedByAnotherUser', // (send only in case yes)
  IS_IN_ACTIVE_TRIAL = 'Is in active trial',
  IS_TRIAL_EXPIRED = 'Is trial expired'
}

export enum Events {
  FIRST_SCREEN = 'PAGE_LOAD_Tutorial_Screen',
  LOGIN_TUTOR = 'Click_Login_Tutorial_Screen',
  LOGIN_ERROR = 'Login_Error',
  LOGIN_SUCCESS = 'Login_Success',
  CREATE_ACC_SUCCESS = 'Create_Account_Success',
  CREATE_ACC_FAILURE = 'SignUp_Error',
  FIRST_MAIN_SCREEN = 'User_Saw_Main_Screen_For_first_Time',
  ADD_GROUP = 'Click_Add_New_Group',
  ADDED_GROUP = 'New_Group_Added',
  EVENT_START_ADD_EVENT = 'Click_Add_New_Item',
  ITEM_ADDED = 'Item_Added',
  LOGOUT = 'Click_Settings_Logout',
  PAGE_LOAD_ENTER_PHONE = 'PAGE_LOAD_Enter_phone_number',
  PHONE_VERIF_REQUEST_SENT = 'Phone_verification_request_sent',
  PHONE_VERIF_REQ_ERROR_FIREBASE = 'Phone_verification_request_Firebase_Verification_Error',
  PHONE_VERIF_REQ_DONE = 'Phone_verification_request_done',

  PAGE_LOAD_VERIF = 'PAGE_LOAD_Verification',
  PHONE_VERIF_INCORRECT = 'Phone_verification_incorrect',
  PHONE_VERIF_CORRECT = 'Phone_verification_correct',
  OPEN_INAPP_PURCHASE = 'Open_In_App_Purchase_Popup',
  CLICK_INAPP_PURCHASE = 'Click_In_App_Purchase_Buy',
  INAPP_PURCHASED = 'In_App_Purchase_Purchased',
  ITEM_DELETED = 'Delete_Item',
  blockedGroupDialogCancelClick = 'Blocked_group_dialog_cancel_clicked',
  blockedGroupDialogDowngradeClick = 'Blocked_group_dialog_downgrade_clicked',
  blockedGroupDialogUpgradeClick = 'Blocked_group_dialog_upgrade_clicked',
  blockedDialogPresented = 'Blocked_group_dialog_prenseted',
  GEUST_MODE_OPEN_FULL_VERSION = 'Click_guest_mode_open_full_version',
  GEUST_MODE_OPEN = 'Guest_mode_open',
  INAPP_CANCELLED = 'In_App_Purchase_Canceled'
}

export enum EventProperties {
  GROUP_NAME = 'Group Name',
  NUM_OF_PARTICIPANTS = 'Num Of Participants',
  ALLOW_META_DATA = 'Allow MetaData Changes',
  ALLOW_ADD_EDIT_EVENT = 'Allow Add Edit Items',
  ALLOW_ADD_PARTICIPANTS = 'Allow Adding Participants',
  METHOD_OF_SHARING = 'Method of sharing',
  METHOD_CONTACT_LIST = 'Contact list',
  METHOD_LINK = 'Link',
  LOCATION_ADD_EVENT = 'Location tapped',
  EVENT_FROM_SCREEN = 'Plus icon',
  ADD_EVENT_FROM_LONG_TAP = 'Long tap on calendar',
  ITEM_TYPE = 'Type',
  CATEGORY = 'category',
  SELECTED_PHONE_TYPE = 'Selected_phone_type',
  COUNTRY_CODE = 'Country_code',
  PHONE_NUMBER = 'Phone_number',
  ERROR = 'error',
  FEATURES_OPENED_WITH = 'Features opened with',
  NEEDED_TIER = 'Needed tier',
  IS_OWNER = 'Is owner',
  FLOW = 'Flow',
  IS_PURCHASING_TRIAL = 'Is purchasing trial',
  TRIAL_OR_PLAN_EXPIRES_IN = 'Trial or plan expires in'
}

export enum EventCategory {
  PHONE_NUMBER = 'Enter_phone_number',
  CODE_VERIF = 'Code_verification'
}

export enum BlockedFlow {
  groupInfo = 'Group Info',
  addItem = 'Add item',
  editItem = 'Edit item'
}
