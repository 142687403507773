import {
  ArchiveIcon,
  CloseIcon,
  CompleteIcon,
  CopyPaste,
  DeleteIcon,
  GlobeIcon,
  GoogleProblem,
  LockGroup,
  PlusIcon,
  PriorityIcon
} from 'assets/icons'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { SubmitHandler, Controller } from 'react-hook-form'
import Button from 'components/buttons/Button'
import LoadingContainer from 'components/containers/LoadingContainer'
import Switch from 'components/form/Switch'
import MembersList from 'components/lists/MembersList'
import NotesList from 'components/lists/NotesList'
import RemindersList from 'components/lists/RemindersList'
import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import {
  AccountDoc,
  DocType,
  EventDoc,
  EventStatus,
  GroupDoc,
  ParticipantDeliveryStatusType,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import * as EventPopupStyled from './styled'
import * as Styled from '../styled'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import EventRecurrenceComponent from 'components/eventComponents/EventRecurrence'
import { EventLocationRow } from 'components/eventComponents/EventLocation'
import AddReminderPopup from './AddReminderPopup'
import { IEventDetails, useEventDetails } from 'hooks/useEventDetails'
import Grid from '@mui/material/Grid'
import IconButton from 'components/buttons/IconButton'
import { Box, List, Stack, TextField, Typography } from '@mui/material'
import {
  GoingBar,
  groupcalStatusToGoogle
} from 'components/eventComponents/GoingBar'
import { RemoveTempEvents } from 'services/db/Queries/Event'
import Logger from 'services/Logger'

import groupDefaultPhoto from '../../../assets/icons/group-default.svg'
import channelDefaultPhoto from '../../../assets/icons/channel_calendar.svg'
import {
  updateEventOnServer,
  updateParticipantStatus,
  UpdateParticipantStatusBody
} from 'services/api/event'
import { AxiosError } from 'axios'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import {
  defaultConvertedCalendarPhoto,
  defaultGroupImage,
  featureToMinimumTierNeeded,
  isEventsActionsAllowed,
  isGroupBlockedToAddEvents,
  isGroupOutOfTier,
  isUserActiveInGroup,
  isUserCanOperateEvents,
  TierFeatures
} from 'utils/api/groups'
import { APP_COLOR } from 'utils/colors'
import { theme } from 'theme'
import dayjs from 'dayjs'
import { GENERIC_ERROR } from 'utils/strings'
import { highestActiveTier, Tier } from 'utils/api/accounts'
import OutOfTierDialog from 'components/tier/OutOfTierDialog'
import {
  ADD_EVENT_CANNOT_POST_ITEMS,
  EDIT_EVENT_CANNOT_CHANGE_EVENT,
  OUT_OF_TIER
} from 'utils/api/codes'
import { BlockedFlow } from 'analytics/Analytics'
import { KeyboardEvent } from 'react'
import i18n from '../../../i18n'
import { t } from 'i18next'
import {
  CurrentConvertedCalendars,
  prefixGoogleEventsGroup
} from 'utils/google_events'
import { SelectableItem } from '../SelectItemWithSearchPopup'
import ParticipantsList from 'components/lists/ParticipantList'
import { isOwnerForGroup } from 'services/db/utils/GroupUtils'
import { LoadingSpinner } from 'components/containers/styled'
import { useConnection } from 'components/providers/ConnectionProvider'
import { ResyncCalendarDialog } from 'components/dialogs/ResyncGoogleDialog'
import Database from 'services/db/Database'
import AllDayRow from './AllDayRow'
import StartDate from './StartDate'
import EndDate from './EndDate'
import EventsActionsMenu from './EventsActionMenu'
import RecurrentActionDialog from 'components/dialogs/RecurrentActionDialog'
import { makeStyles } from '@mui/styles'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import {
  allGroups,
  allGroupsForUI,
  localGroupById
} from 'services/db/Queries/GroupQueries'
import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import { ArrowRightGrey } from 'assets/img'
import { isGroupHidden } from 'utils/api/userSettings'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import LabelsSection from './LabelsSection'
import TypeSegment from './TypeSegment'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import NotesSection from './notesSection/NotesSection'
import { generateStartEndBasedOnTimeWithShift } from 'utils/date'
import { instanceStatus } from 'components/calendar/components/Event'
import { getStringSizeInKB } from 'utils/file'
import { docTypeToString } from 'utils/doc'
import TimezonePopup from './TimezonePopup'
import { darken } from '@mui/material'
import {
  groupIdToPrefix,
  groupIdToProvider,
  groupIdToProviderTypeName,
  groupToProvider,
  isConvertedGroup,
  isConvertedGroupId
} from 'utils/groups'
import {
  ProviderType,
  removePrefix,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'
import { outlookPrefix } from 'utils/constants'
import { ICalendar } from 'types/google_events'
import { useCalendar } from 'components/providers/CalendarProvider'
import BlockOverlay, { BlockOverlayProps } from 'components/BlockOverlay'
import CalendarSelectPopup, {
  CalendarSectionItem
} from '../CalendarSelectPopup'
import { GroupCardAvatar } from 'components/cards/styled'
import { GroupPrivacy } from 'model/models/Group'
import { GraphError } from '@microsoft/microsoft-graph-client'
import { useNotification } from 'components/providers/NotificationProvider'
import { Ref } from '@fullcalendar/core/preact'
import FullCalendar from '@fullcalendar/react'
import { getDefaultTimezone } from 'index'

export enum RECUR_SAVE_TYPE {
  THIS = 'this',
  THIS_AND_FOLLOWING = 'thisAndFollowing',
  ALL = 'all'
}

interface ContentProps {
  event: Model<EventDoc> | EventDoc
  group?: Model<GroupDoc> | GroupDoc
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc
  onClose: (simpleClose?: boolean, forceClose?: boolean) => void
  open: boolean
  instanceStart: number
  instanceEnd: number
  account: Model<AccountDoc> | AccountDoc
  doNotScrollToEvent?: boolean
  fc_id: string
  awayClickTriggered: boolean
  setAwayClickTriggered: (b: boolean) => void
}

const useStyles = makeStyles({
  root: {
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
      }
    }
  }
})

function EventPopupContent(props: ContentProps) {
  Logger.debug('event', props.event)
  Logger.debug('group', props.group)

  // disable scroll first
  useEffect(() => {
    const scrollers = document.querySelectorAll('.fc-scroller')
    scrollers.forEach((scroller) => {
      ;(scroller as any).style.overflow = 'hidden'
    })
    setTimeout(() => {
      LocalStorage.set(
        LocalStorageKey.VISIBLE_EVENT,
        `${props.event._id}${props.instanceStart}`
      )
    }, 250)
  }, [])
  Logger.blue(
    'Event:Start',
    dayjs(props.instanceStart)
      .tz(
        (props.event.TimeZoneNameID?.length ?? 0) > 0
          ? props.event.TimeZoneNameID
          : getDefaultTimezone()
      )
      .format('YYYY-MM-DD HH:mm:ss')
  )
  Logger.blue(
    'Event:End',
    dayjs(props.instanceEnd)
      .tz(
        (props.event.TimeZoneNameID?.length ?? 0) > 0
          ? props.event.TimeZoneNameID
          : getDefaultTimezone()
      )
      .format('YYYY-MM-DD HH:mm:ss')
  )
  const classes = useStyles()
  const connection = useConnection()
  const calendarSync = useCalendarSync()
  const calendar = useCalendar()

  const showSmallUI =
    window.self !== window.top ||
    LocalStorage.get(LocalStorageKey.USER_ID) === null ||
    LocalStorage.get(LocalStorageKey.USER_ID)?.length === 0

  const eventHook = useEventDetails(
    props.event,
    props.group,
    props.instanceStart,
    props.instanceEnd,
    props.doNotScrollToEvent
  )

  useEffect(() => {
    if (props.awayClickTriggered) handleCloseButtonClick()
  }, [props.awayClickTriggered])

  // load event details if needed
  useEffect(() => {
    const provider = groupToProvider(props.group)
    if ((props.event.ParentTaskID?.length ?? 0) > 0 && provider) {
      calendarSync
        .loadEventDetails(props.event.ParentTaskID ?? '', provider)
        .then((event) => {
          // update all needed fields here

          if (event.Recurrence) eventHook.setRecurrence(event.Recurrence, false)
          setNeedShowReccurenceDialog(true)
        })
    }
  }, [props.event._id])

  const isCurrentGroupIsThirdPartyCalendar = isConvertedGroupId(
    eventHook.groupId
  )

  const activeTier = highestActiveTier(props.account)
  const appUiContext = useAppUIType()

  const [showOutOfTierDialog, onShowOutOfTierDialog] = useState(false)

  const newEvent =
    props.event?._id == undefined || props.event?._id?.length == 0

  const shouldShowGoingBar =
    (props.event._id?.length ?? 0) > 0 &&
    (props.event?.RequestConfirmation === '1' ||
      ((eventHook.attendees ?? [])?.length > 0 &&
        eventHook.attendees?.find((attendee) => attendee.self)))

  const [timeZoneOpen, setTimeZoneOpen] = useState(false)

  const userId = LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  const [showDeleteForDialog, setShowDeleteForDialog] = useState(false)

  const [needShowReccurenceDialog, setNeedShowReccurenceDialog] = useState(
    !!props.event.Recurrence &&
      typeof props.event.Recurrence !== 'string' &&
      Object.keys(props.event.Recurrence).length !== 0 &&
      (props.event._id?.length ?? 0) > 0 &&
      eventHook.someday !== '1'
  )
  const [showRecurrenceDialog, setShowRecurrenceDialog] = useState(false)

  const [showCalendarSync, onShowCalendarSync] = useState(false)

  function handleEventServerError(e: any) {
    if (e instanceof GraphError) {
      if (e.statusCode === 405) {
        onShowCalendarSync(true)
      } else {
        eventHook.setError(parseErrorToMessage(e))
      }
    } else if (e instanceof AxiosError) {
      if (
        isCurrentGroupIsThirdPartyCalendar &&
        eventHook.getValues('Type') === DocType.REGULAR_EVENT
      ) {
        if (
          e.response?.status === 405 ||
          e.response?.status === 400 ||
          e.response?.status === 401 ||
          CurrentConvertedCalendars.length === 0
        ) {
          onShowCalendarSync(true)
        } else {
          eventHook.setError(parseErrorToMessage(e))
        }
      } else {
        eventHook.setError(parseErrorToMessage(e))
      }
    } else {
      eventHook.setError(parseErrorToMessage(e))
      Logger.red(e)
    }
  }

  const processEvent = async (data: EventDoc) => {
    Logger.blue('UPDATE_EVENT', data)
    data.Text = data.Text?.trim()

    delete data['noteText']
    delete data['id']

    // check data be less than 2MB
    const dataString = JSON.stringify(data)
    const dataSizeInKB = getStringSizeInKB(dataString)
    const dataSizeInMB = dataSizeInKB / 1024

    Logger.debug('Doc size in MB', dataSizeInMB)

    if (dataSizeInMB > 2) {
      eventHook.setError(
        i18n.t('sizeTooLarge', {
          name: docTypeToString(data.Type).toLowerCase()
        }) ?? ''
      )
      return
    }

    if (needShowReccurenceDialog && eventHook.someday !== '1') {
      setShowRecurrenceDialog(true)
    } else {
      try {
        await eventHook.sendEventToServer(data).then(() => {
          props.onClose(false, true)
        })
        if (appUiContext.uiType.value != AppUIType.calendars)
          switch (data.Type) {
            case DocType.REGULAR_EVENT:
            case DocType.EVENT:
              appUiContext.goToCalendar()
              break
            case DocType.TASK:
              appUiContext.goToTasks()
              break
            case DocType.NOTE:
              appUiContext.goToNotes()
              break
          }
      } catch (e) {
        // print stack trace of error

        Logger.red(`error during event update ${e}`)

        handleEventServerError(e)
        eventHook.setLoading(false)
      }
    }
  }

  const onEventSave = async (data: EventDoc) => {
    eventHook.setStatus(EventStatus.ACTIVE)
    await processEvent(data)
  }

  async function updateStatus(status: EventStatus) {
    eventHook.setStatus(status)
    await processEvent({ ...eventHook.getValues() })
  }

  const performEventDeletion = async (status: EventStatus | undefined) => {
    if (
      !status &&
      (props.event.Type === DocType.TASK || props.event.Type === DocType.NOTE)
    ) {
      if (
        instanceStatus(props.event, props.instanceStart) === EventStatus.ACTIVE
      ) {
        status = EventStatus.COMPLETED
      } else if (
        instanceStatus(props.event, props.instanceStart) ===
        EventStatus.COMPLETED
      ) {
        status = EventStatus.REMOVED
      } else if (
        instanceStatus(props.event, props.instanceStart) === EventStatus.REMOVED
      ) {
        status = EventStatus.DELETED
      }
    }

    await updateStatus(status ?? EventStatus.DELETED)
  }

  const onEventDelete = async (status?: EventStatus) => {
    if (isConvertedGroupId(eventHook.groupId) && isUiBlocked) {
      eventHook.setAttendees(
        eventHook.attendees?.map((attendee) => {
          if (attendee.self)
            attendee.responseStatus = groupcalStatusToGoogle('3')
          return attendee
        }) ?? [],
        true
      )
      console.log(eventHook.attendees, 'attendees')
      return await saveEventData(eventHook.getValues())
    }

    if (
      is24meApp() &&
      (props.event.Type === DocType.TASK ||
        props.event.Type === DocType.NOTE) &&
      (!props.event.Recurrence ||
        typeof props.event.Recurrence === 'string' ||
        Object.keys(props.event.Recurrence).length === 0)
    ) {
      LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
      updateEventOnServer(props.event, {
        Status:
          status ??
          instanceStatus(props.event, props.instanceStart) ===
            EventStatus.COMPLETED
            ? EventStatus.DELETED
            : EventStatus.COMPLETED,
        SupplementaryGroupsIDs: props.event.SupplementaryGroupsIDs
      })
      props.onClose()
      return
    }

    if (
      !isEventsActionsAllowed(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ||
      !isUserCanOperateEvents(props.group, userId)
    ) {
      handleDeleteForMe()
    } else {
      if (needShowReccurenceDialog) {
        await performEventDeletion(status)
      } else if ((props.event.ThirdPartyID?.length ?? 0) > 0) {
        handleDeleteForAll()
      } else {
        setShowDeleteForDialog(true)
      }
    }
  }

  function onApproveNoteText(text: string) {
    eventHook.setNotePendingText('')
    eventHook.addNote(text)
  }

  const removeTimeZone = () => {
    eventHook.setTimeZone(getDefaultTimezone())
  }

  const onRSVPChange = (event: ChangeEvent<HTMLInputElement>) => {
    eventHook.setRSVP(event.target.checked ? '1' : '0')

    if (newEvent)
      Database.write(async () => {
        if (props.event.syncStatus === 'deleted') return
        props.event.update((local: { RequestConfirmation: string }) => {
          local.RequestConfirmation = event.target.checked ? '1' : '0'
        })
      })
  }

  const handleCloseEventPopup = (simpleClose?: boolean) => {
    eventHook.setOpenDiscard(false)
    props.onClose(simpleClose, true)
  }

  const handleCloseRecurringSave = () => {
    setShowRecurrenceDialog(false)
  }

  const handleCancelDeleteFor = () => {
    setShowDeleteForDialog(false)
  }

  const handleSaveRecurringEvent = async (type: RECUR_SAVE_TYPE) => {
    const data: EventDoc = { ...eventHook.getValues() }

    eventHook.setLoading(true)
    calendarSync
      .syncEventWithThirdParty(
        props.instanceStart,
        props.instanceEnd,
        data,
        props.event as Model<EventDoc>,
        false,
        true,
        (e) => {
          Logger.red('error during event update', e)
          eventHook.setError(e)
        },
        type,
        'StartDate' in eventHook.dirtyFields ||
          'EndDate' in eventHook.dirtyFields
      )
      .then(() => {
        eventHook.setLoading(false)
        props.onClose(false, true)
      })
  }

  const hideDiscardDialog = () => {
    eventHook.setOpenDiscard(false)
    props.setAwayClickTriggered(false)
  }

  const [openNoteDeatils, setOpenNoteDetails] = useState(
    props.event.Type === DocType.NOTE &&
      LocalStorage.get(LocalStorageKey.NOTE_DETAILS_ID) != props.event.id
  )

  /**
   * We need to store in a cache which note we are opened because user
   * might change a time so popup will recreate and we do not want to
   * show note details again
   */
  if (props.event.Type === DocType.NOTE) {
    LocalStorage.set(LocalStorageKey.NOTE_DETAILS_ID, props.event.id)
  }

  const handleCloseButtonClick = () => {
    if (Object.keys(eventHook.dirtyFields).length === 0) {
      props.onClose()
      if (props.event._id?.length === 0) {
        RemoveTempEvents().then(() => {
          ;(calendar.ref as any).current
            ?.getApi()
            .getEventById(props.fc_id)
            ?.remove()
        })
      }
    } else {
      eventHook.setOpenDiscard(true)
    }
  }

  useEffect(() => {
    const keyEnter = async (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseButtonClick()
      }
    }

    document.addEventListener('keydown', keyEnter as any)

    return () => {
      document.removeEventListener('keydown', keyEnter as any)
    }
  }, [])

  const onSubmit: SubmitHandler<EventDoc> = async (data) => {
    saveEventData(data)
  }

  const saveEventData = async (data: EventDoc) => {
    if (connection.isConnectionAvailable()) {
      try {
        await eventHook.applyPendingData().then(() => {
          return onEventSave(eventHook.getValues())
        })
      } catch (e) {
        handleEventServerError(e)
      }
    }
  }

  const handleDeleteForAll = async () => {
    await performEventDeletion(EventStatus.DELETED)
  }

  const handleDeleteForMe = async () => {
    const updatedStatus =
      props.event.ParticipantsStatus?.[
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ]

    if (updatedStatus) {
      updatedStatus.ParticipantConfirmationStatus = '3'
      const updateBody: UpdateParticipantStatusBody = {
        _id: props.event._id,
        DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID) ?? '',
        ParticipantsStatus: { ...updatedStatus }
      }
      eventHook.setLoading(true)
      await updateParticipantStatus([updateBody])
      eventHook.setLoading(false)
    }

    props.onClose()
  }

  if (isGroupcalApp()) markEventAsReadIfNeeded(props.event)

  let readOnly = LocalStorage.get(LocalStorageKey.USER_ID)?.length === 0

  if (props.event.Organizer) {
    readOnly =
      props.event.Organizer.email !== removePrefix(props.event?.GroupID ?? '')
  }

  const isActive = isUserActiveInGroup(
    props.group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  )

  const [showUserBlockedDialog, setShowUserBlockedDialog] = useState(false)

  const hideUserBlocked = () => {
    setShowUserBlockedDialog(false)
  }

  const [userCannotAddEvents, setUserCanAddEvents] = useState(false)

  const showCanAddEventsDialog = () => {
    setUserCanAddEvents(true)
  }

  const hideCanAddEventsDialog = () => {
    setUserCanAddEvents(false)
  }

  const blockedOverlayClick = () => {
    if (props.group?.SyncProblem === '1') {
      onShowCalendarSync(true)
    } else if (isOutOfTier) {
      onShowOutOfTierDialog(true)
    } else if (!isUserCanOperateEvents(props.group, userId)) {
      showCanAddEventsDialog()
    } else {
      setShowUserBlockedDialog(true)
    }
  }

  const dismissError = () => {
    eventHook.setError('')
  }

  const isOutOfTier = isGroupOutOfTier(activeTier, props.group, props.account)

  let isUiBlocked: boolean

  if (isGroupcalApp()) {
    isUiBlocked =
      isOutOfTier ||
      readOnly ||
      !isActive ||
      !isUserCanOperateEvents(props.group, userId)
  } else {
    isUiBlocked = readOnly
  }

  if (
    isConvertedGroup(props.group) &&
    props.event.Type !== DocType.TASK &&
    props.event.Type !== DocType.NOTE
  ) {
    const provider = groupIdToProvider(props.event.GroupID ?? '')

    if (props.group?.SyncProblem === '1') {
      isUiBlocked = true
    } else {
      if (provider == ProviderType.GOOGLE) {
        isUiBlocked =
          props.event.OwnerID !=
          CurrentConvertedCalendars.find(
            (calendar) =>
              calendar.id === removePrefix(props.event.ThirdPartyID ?? '')
          )?.primaryCalendarEmail
      } else if (provider == ProviderType.OUTLOOK) {
        isUiBlocked =
          props.event.OwnerID !=
          LocalStorage.get(LocalStorageKey.OUTLOOK_DEFAULT_CALENDAR)
      }
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  if (showSmallUI) {
    const hideEventDetails = () => {
      props.onClose()
    }

    return (
      <>
        <ConfirmationDialog
          title={props.event.Text ?? ''}
          content={
            <Grid minWidth={300} style={{ pointerEvents: 'none' }}>
              <AllDayRow value={props.event.AllDay ?? '0'} field={undefined} />
              <StartDate eventHook={eventHook} event={props.event} />
              <EndDate eventHook={eventHook} event={props.event} />
              {props.event.Location &&
                (props.event.Location?.Address?.length ?? 0) > 0 && (
                  <EventLocationRow
                    disallowEdit
                    setLocation={eventHook.setLocation}
                    eventLocation={props.event.Location}
                  />
                )}
              {props.event.Notes && (props.event.Notes?.length ?? 0) > 0 && (
                <PopupSection
                  titlePadding="0px"
                  padding={'0px'}
                  title={`${t('notes')}`}
                >
                  <NotesList
                    disableDelete
                    checkable={false}
                    notes={props.event.Notes}
                  />
                </PopupSection>
              )}
            </Grid>
          }
          showCloseIcon
          noButtons
          handleClose={hideEventDetails}
          open={props.open}
        />
      </>
    )
  }

  function providePlaceholderByType(
    Type: DocType | undefined
  ): string | undefined {
    if (Type === DocType.TASK) return `${i18n.t('addTitleTask')}`

    if (Type === DocType.NOTE) return `${i18n.t('addTitleNote')}`

    return `${i18n.t('addTitle')}`
  }

  function deleteIconByEvent(event?: Model<EventDoc> | EventDoc): ReactNode {
    if (event?.Type === DocType.TASK || event?.Type === DocType.NOTE) {
      if (
        instanceStatus(props.event, props.instanceStart) === EventStatus.ACTIVE
      ) {
        return <CompleteIcon />
      }

      if (
        instanceStatus(props.event, props.instanceStart) ===
        EventStatus.COMPLETED
      ) {
        return <ArchiveIcon />
      }
    }

    return <DeleteIcon />
  }

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Box
        sx={{
          overflowY: 'scroll',
          height: '650px'
        }}
      >
        <LoadingContainer loading={eventHook.loading ? 1 : 0} dontShowSpinner />
        <Styled.EventPopupHead>
          <Grid
            item
            container
            display={'flex'}
            flexDirection={'row'}
            wrap={'nowrap'}
          >
            <GroupcalTooltip title={t('close')}>
              <Styled.PopupCloseButton
                type={'button'}
                size="sm"
                onClick={() => {
                  handleCloseButtonClick()
                }}
              >
                <CloseIcon />
              </Styled.PopupCloseButton>
            </GroupcalTooltip>
            <Grid
              container
              wrap={'nowrap'}
              direction={'row'}
              columnGap={2}
              justifyContent={'end'}
            >
              {newEvent || readOnly ? (
                <></>
              ) : (
                <EventsActionsMenu
                  onClose={props.onClose}
                  instanceEnd={props.instanceEnd}
                  instanceStart={props.instanceStart}
                  disabled={isUiBlocked}
                  event={props.event}
                  eventHook={eventHook}
                  closePopup={handleCloseEventPopup}
                  updateStatus={updateStatus}
                />
              )}
              {newEvent || readOnly ? (
                <></>
              ) : (
                <Button
                  id="delete-event-button"
                  tootltip={`${t('deleteEvent')}`}
                  disabled={
                    (isUiBlocked && !isConvertedGroupId(eventHook.groupId)) ||
                    (isUiBlocked &&
                      isConvertedGroupId(eventHook.groupId) &&
                      (props.event.attendees?.length ?? 0) === 0)
                  }
                  onClick={() => {
                    onEventDelete()
                  }}
                  type="button"
                  size="icon"
                >
                  {deleteIconByEvent(props.event)}
                </Button>
              )}
              <Button
                id="save-event-button"
                onClick={() => {
                  saveEventData(eventHook.getValues())
                }}
                type="submit"
                size="xs"
                disabled={
                  newEvent
                    ? eventHook.getValues('Text')?.length === 0
                    : Object.keys(eventHook.dirtyFields).length === 0
                }
              >
                {newEvent ? `${t('add')}` : `${t('save')}`}
                <LoadingSpinner
                  loading={eventHook.loading ? 1 : 0}
                  sx={{
                    top: -4,
                    right: 0,
                    left: 0,
                    margin: 'auto'
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          {is24meApp() &&
          newEvent &&
          appUiContext.uiType.value === AppUIType.calendars ? (
            <TypeSegment eventHook={eventHook} />
          ) : (
            <></>
          )}
          <Controller
            name="Type"
            control={eventHook.control}
            defaultValue={props.event.Type}
            render={({ field }) => {
              const type = field.value
              return (
                <Controller
                  name="Text"
                  control={eventHook.control}
                  defaultValue={props.event.Text}
                  render={({ field }) => (
                    <Styled.EventPopupInputContainer>
                      <TextField
                        id="event-title-input"
                        sx={{
                          width: '100%',
                          padding: theme.spacing(0, 1)
                        }}
                        onKeyDown={handleKeyPress}
                        multiline
                        autoFocus={newEvent}
                        maxRows={14}
                        value={field.value}
                        className={classes.root}
                        placeholder={providePlaceholderByType(type)}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          eventHook.setName(event.target.value)
                          eventHook.onTitleSet(true)
                        }}
                        variant="standard"
                        InputProps={{
                          style: {
                            textDecoration:
                              instanceStatus(
                                props.event,
                                props.instanceStart
                              ) === EventStatus.COMPLETED
                                ? 'line-through'
                                : 'none',
                            fontSize: '1.4rem',
                            fontWeight: 600,
                            pointerEvents: isUiBlocked ? 'none' : 'all',
                            borderBottom: 'none'
                          }
                        }}
                      />
                      {is24meApp() &&
                        (eventHook.getValues('Type') === DocType.TASK ||
                          eventHook.getValues('Type') === DocType.NOTE) && (
                          <Controller
                            name="Priority"
                            control={eventHook.control}
                            defaultValue={props.event.Priority}
                            render={({ field }) => (
                              <div
                                style={{
                                  marginRight:
                                    i18n.dir() === 'ltr' ? -13 : undefined,
                                  marginLeft:
                                    i18n.dir() === 'ltr' ? undefined : -13,
                                  position: 'absolute',
                                  right: i18n.dir() === 'ltr' ? 0 : undefined,
                                  left: i18n.dir() === 'ltr' ? undefined : 0
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    field.onChange(
                                      field.value === '2' ? '1' : '2'
                                    )
                                  }}
                                  size="sm"
                                >
                                  <PriorityIcon
                                    style={{
                                      color:
                                        field.value === '2' ? 'red' : undefined
                                    }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          />
                        )}
                      <BlockOverlay
                        zIndex={-1}
                        isUiBlocked={isUiBlocked}
                        blockedOverlayClick={blockedOverlayClick}
                      />
                      <BlockOverlay
                        zIndex={2}
                        isUiBlocked={isUiBlocked}
                        color="transparent"
                        blockedOverlayClick={blockedOverlayClick}
                      />
                    </Styled.EventPopupInputContainer>
                  )}
                />
              )
            }}
          />
        </Styled.EventPopupHead>
        <ConferenceSection {...props} />
        <PopupSection
          isUiBlocked={isUiBlocked}
          blockedOverlayClick={blockedOverlayClick}
          title={`${i18n.t('time')}`}
        >
          <Controller
            name="someday"
            control={eventHook.control}
            defaultValue={props.event.someday}
            render={({ field }) => (
              <Box display={'flex'} flexDirection={'column'}>
                {field.value === '1' ? (
                  <></>
                ) : (
                  <Controller
                    name="AllDay"
                    control={eventHook.control}
                    defaultValue={props.event.AllDay}
                    render={({ field }) => (
                      <AllDayRow
                        value={field.value ?? '0'}
                        field={field}
                        setAllDay={(allday) => {
                          eventHook.setAllDay(allday)
                        }}
                      />
                    )}
                  />
                )}
                <StartDate eventHook={eventHook} event={props.event} />
                <EndDate eventHook={eventHook} event={props.event} />
              </Box>
            )}
          />
          {eventHook.getValues('AllDay') === '1' ? (
            <></>
          ) : (
            <Controller
              name="TimeZoneNameID"
              control={eventHook.control}
              defaultValue={
                (props.event.TimeZoneNameID?.length ?? 0) > 0
                  ? props.event.TimeZoneNameID
                  : getDefaultTimezone()
              }
              render={({ field }) => (
                <TimezonePopup
                  open={timeZoneOpen}
                  setOpen={setTimeZoneOpen}
                  setTimeZone={(tz: string) => {
                    eventHook.setTimeZone(tz)
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <PopupSectionRow
                      neededTier={is24meApp() ? Tier.PRO : undefined}
                      action={() => {
                        setTimeZoneOpen(true)
                      }}
                      key={'holder'}
                      icon={<GlobeIcon />}
                      title={
                        field.value && field.value?.length > 0
                          ? field.value
                          : t('addTimezone')
                      }
                    />
                  </span>
                </TimezonePopup>
              )}
            />
          )}
          <Controller
            name="someday"
            control={eventHook.control}
            defaultValue={props.event.someday}
            render={({ field }) =>
              field.value === '1' ? (
                <></>
              ) : (
                <Controller
                  name="Recurrence"
                  control={eventHook.control}
                  defaultValue={props.event.Recurrence}
                  render={({ field }) => (
                    <EventRecurrenceComponent
                      userSettings={props.userSettings}
                      eventHook={eventHook}
                      recurrence={field.value}
                      setEventRecurrence={eventHook.setRecurrence}
                    />
                  )}
                />
              )
            }
          />
        </PopupSection>
        <PopupSection
          title={`${t('location')}`}
          contentZIndex={
            (props.event.Location?.Address?.length ?? 0) !== 0 ? 3 : 1
          }
          blockedOverlayClick={blockedOverlayClick}
          isUiBlocked={isUiBlocked}
        >
          <Controller
            name="Location"
            control={eventHook.control}
            defaultValue={props.event.Location}
            render={({ field }) => (
              <EventLocationRow
                disallowEdit={isUiBlocked}
                eventHook={eventHook}
                setLocation={eventHook.setLocation}
                eventLocation={field.value}
              />
            )}
          />
        </PopupSection>
        <PopupSection
          title={`${t('reminders')}`}
          isUiBlocked={isUiBlocked}
          blockedOverlayClick={blockedOverlayClick}
        >
          <Controller
            name="Reminder"
            control={eventHook.control}
            defaultValue={props.event.Reminder}
            render={({ field }) => {
              Logger.pink('Reminder:', field.value)
              return field.value ? (
                <RemindersList
                  setReminders={eventHook.setReminders}
                  reminders={
                    typeof field.value !== 'string' ? field.value ?? [] : []
                  }
                />
              ) : (
                <></>
              )
            }}
          />
          <AddReminderPopup
            setOpen={eventHook.setOpenAddReminder}
            open={eventHook.openAddReminder}
            reminders={eventHook.reminders || []}
            setReminders={eventHook.setReminders}
          >
            <div>
              <PopupSectionRow
                action={() => {
                  eventHook.setOpenAddReminder(true)
                }}
                titleColor={theme.palette.input.placeholder}
                icon={
                  <Box marginLeft={'2px'}>
                    <PlusIcon />
                  </Box>
                }
                iconClass={'icon-small'}
                title={t('addReminder')}
                value=""
              />
            </div>
          </AddReminderPopup>
        </PopupSection>
        {isCurrentGroupIsThirdPartyCalendar || isGroupcalApp() ? (
          <PopupSection
            title={`${t(
              isCurrentGroupIsThirdPartyCalendar ? 'participants' : 'members'
            )}`}
            padding={theme.spacing(0, 2, 0, 0)}
            isUiBlocked={isUiBlocked}
            blockedOverlayClick={blockedOverlayClick}
          >
            {isCurrentGroupIsThirdPartyCalendar ? (
              <Controller
                name="attendees"
                control={eventHook.control}
                defaultValue={props.event.attendees}
                render={({ field }) =>
                  field.value ? (
                    <ParticipantsList
                      eventDoc={props.event}
                      eventHook={eventHook}
                      participants={field.value}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            ) : (
              <Box display={'flex'} flexDirection={'column'}>
                <PopupSectionRow
                  title={t('requestToConfirmParticipation')}
                  tierFeature={TierFeatures.RSVP}
                  cursor="default"
                  neededTier={
                    isOwnerForGroup(props.group)
                      ? featureToMinimumTierNeeded(TierFeatures.RSVP)
                      : Tier.FREE
                  }
                  value={
                    <Switch
                      onChange={onRSVPChange}
                      checked={eventHook.RSVP === '1'}
                    />
                  }
                />
                <MembersList
                  setLoading={eventHook.setLoading}
                  event={props.event}
                  group={props.group}
                  groupId={eventHook.groupId}
                  withoutActions
                  localParticipants={[]}
                />
              </Box>
            )}
          </PopupSection>
        ) : (
          <></>
        )}
        <Controller
          name="Type"
          control={eventHook.control}
          defaultValue={props.event.Type}
          render={({ field }) =>
            field.value ? (
              <NotesSection
                isCurrentGroupIsGoogleCalendar={
                  isCurrentGroupIsThirdPartyCalendar
                }
                eventHook={eventHook}
                event={props.event}
                onApproveNoteText={onApproveNoteText}
                openNoteDetails={openNoteDeatils}
                setOpenNoteDetails={setOpenNoteDetails}
                isUiBlocked={isUiBlocked}
                blockedOverlayClick={blockedOverlayClick}
              />
            ) : (
              <></>
            )
          }
        />

        {isGroupcalApp() ||
        eventHook.type === DocType.REGULAR_EVENT ||
        eventHook.type === DocType.EVENT ? (
          <Controller
            name="SupplementaryGroupsIDs"
            control={eventHook.control}
            defaultValue={props.event.SupplementaryGroupsIDs}
            render={({ field }) => {
              return (
                <CalendarSection
                  thirdParty={eventHook.thirdPartyId ?? ''}
                  setType={eventHook.setType}
                  selectedGroupId={eventHook.getValues('GroupID')}
                  setGroupID={eventHook.setGroupID}
                  setThirdPartyId={eventHook.setThirdPartyId}
                  isUiBlocked={isUiBlocked}
                  blockedOverlayClick={blockedOverlayClick}
                  eventHook={eventHook}
                  SupplementaryGroupsIDs={field.value}
                  {...props}
                />
              )
            }}
          />
        ) : (
          <></>
        )}
        {(eventHook.type === DocType.TASK ||
          eventHook.type === DocType.NOTE) && (
          <Controller
            name="Label"
            control={eventHook.control}
            defaultValue={props.event.Label}
            render={({ field }) => (
              <LabelsSection
                labels={field.value}
                setLabels={eventHook.setLabels}
                setLoading={eventHook.setLoading}
                event={props.event}
              />
            )}
          />
        )}
        <Box height={4} paddingBottom={4} width={'100%'} />

        {Object.keys(eventHook.dirtyFields).length !== 0 &&
          eventHook.openDiscard && (
            <ConfirmationDialog
              title={`${t('areYouSureCancel')}`}
              content={''}
              rightButton={`${t('discard')}`}
              leftButton={`${t('cancel')}`}
              leftButtonColor={'black'}
              rightButtonColor={APP_COLOR}
              handleLeftButton={hideDiscardDialog}
              open={eventHook.openDiscard}
              handleClose={() => {
                if (openNoteDeatils) {
                  setOpenNoteDetails(false)
                  hideDiscardDialog()
                } else {
                  setTimeout(() => {
                    LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
                  }, 500)
                  handleCloseEventPopup()
                  RemoveTempEvents()
                }
              }}
            />
          )}
        {showRecurrenceDialog && (
          <RecurrentActionDialog
            item={props.event}
            open={showRecurrenceDialog}
            handleClose={handleCloseRecurringSave}
            handleSaveRecurringEvent={handleSaveRecurringEvent}
          />
        )}
        {showDeleteForDialog && (
          <ConfirmationDialog
            title={t('wouldYouLikeToRemove')}
            content={''}
            cancelDisabled={props.event.Recurrence != null}
            leftButton={`${t('removeForAll')}`}
            rightButton={`${t('removeForMe')}`}
            handleRightButton={handleDeleteForMe}
            handleLeftButton={handleDeleteForAll}
            open={showDeleteForDialog}
            handleClose={handleCancelDeleteFor}
            followForm={false}
          />
        )}
        {showUserBlockedDialog && (
          <ConfirmationDialog
            rightButtonHidden
            title={
              isConvertedGroup(props.group)
                ? t('readyOnlyConverted')
                : t('youDontHavePermissions')
            }
            content={''}
            handleLeftButton={hideUserBlocked}
            handleClose={hideUserBlocked}
            open={showUserBlockedDialog}
          />
        )}
        {eventHook.error && eventHook.error.length > 0 && (
          <ConfirmationDialog
            rightButtonHidden
            title={t('cantDoThat')}
            content={eventHook.error}
            leftButtonColor={APP_COLOR}
            leftButton={`${t('ok')}`}
            handleLeftButton={dismissError}
            handleClose={dismissError}
            open={eventHook.error.length > 0}
          />
        )}
        {userCannotAddEvents && (
          <ConfirmationDialog
            rightButtonHidden
            title={t('cantDoThat')}
            content={t('thisEventPartOf')}
            handleLeftButton={hideCanAddEventsDialog}
            handleClose={hideCanAddEventsDialog}
            open={userCannotAddEvents}
          />
        )}
        {showOutOfTierDialog && (
          <OutOfTierDialog
            account={props.account}
            flow={newEvent ? BlockedFlow.addItem : BlockedFlow.editItem}
            group={props.group}
            setOpen={onShowOutOfTierDialog}
            opened={showOutOfTierDialog}
          />
        )}
        {showOutOfTierDialog && (
          <OutOfTierDialog
            group={props.group}
            setOpen={onShowOutOfTierDialog}
            opened={showOutOfTierDialog}
          />
        )}
        {showCalendarSync && (
          <ResyncCalendarDialog
            isOpen={showCalendarSync}
            setOpen={onShowCalendarSync}
            group={props.group}
          />
        )}
        {shouldShowGoingBar ? (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              position: 'sticky',
              bottom: 0,
              zIndex: 13,
              paddingBottom: '8px'
            }}
          >
            <PopupSection title="">
              <div
                style={{
                  height: '1px',
                  backgroundColor: theme.palette.border.primary,
                  marginBottom: '8px'
                }}
              />
              <PopupSectionRow
                title={
                  <Typography fontWeight={500}>{`${t('going')}`}</Typography>
                }
                value={
                  <GoingBar
                    attendees={eventHook.attendees}
                    setLoading={eventHook.setLoading}
                    event={props.event}
                    status={eventHook.participantsStatus}
                    setStatus={eventHook.setParticipantStatus}
                    setAttendees={eventHook.setAttendees}
                  />
                }
              />
            </PopupSection>
          </div>
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  )
}

function ConferenceSection(props: ContentProps) {
  const conferenceLink = props.event.ConferenceData?.entryPoints?.find(
    (e) => e.entryPointType === 'video'
  )?.uri
  const copyMeetTextToClip = async () => {
    const phonePoint = props.event.ConferenceData?.entryPoints?.find(
      (point) => point.entryPointType === 'phone'
    )
    const morePoint = props.event.ConferenceData?.entryPoints?.find(
      (point) => point.entryPointType === 'more'
    )

    let text =
      `${props.event.Text}` +
      `\n${dayjs(Number(props.event.StartDate) * 1000).format(
        'dddd, MMMM, DD · HH:mm - '
      )}${dayjs(Number(props.event.EndDate) * 1000).format('HH:mm')}` +
      `\n${t('appJoining', {
        appName: props.event.ConferenceData?.conferenceSolution?.name
      })}\n${t('videoCallLink')}: ${conferenceLink}`

    if (phonePoint?.pin) {
      text += `\n${phonePoint?.uri ?? ''} PIN: ${phonePoint.pin}`
    }

    if (phonePoint?.passcode) {
      text += `\n${phonePoint?.uri ?? ''} PASSCODE: ${phonePoint.passcode}`
    }

    if (morePoint) {
      text += `\n${t('forMoreDial')}: ${morePoint?.uri}`
    }
    await navigator.clipboard.writeText(text)
  }

  const openGoogleMeet = () => {
    window.open(conferenceLink, '_blank')
  }

  if (!props.event.ConferenceData) return <></>

  return (
    <PopupSection>
      {props.event.ConferenceData && (
        <PopupSectionRow
          title={
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: APP_COLOR
              }}
              href={conferenceLink}
            >{`${t('joinWithApp', {
              appName: props.event.ConferenceData.conferenceSolution?.name
            })}`}</a>
          }
          onlyTitle
          icon={
            <span onClick={openGoogleMeet}>
              <img
                style={{ marginLeft: 4 }}
                width={16}
                height={16}
                src={props.event.ConferenceData.conferenceSolution?.iconUri}
              />
            </span>
          }
          value={
            <IconButton
              tooltip={`${t('copyConferenceInfo')}`}
              size="xs"
              onClick={copyMeetTextToClip}
            >
              <CopyPaste />
            </IconButton>
          }
        />
      )}
    </PopupSection>
  )
}

interface CalendarSectionProps extends ContentProps, BlockOverlayProps {
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc
  setGroupID: (id: string) => void
  setThirdPartyId: (id: string) => void
  thirdParty: string
  selectedGroupId?: string
  setType: (type: DocType) => void
  eventHook: IEventDetails
  SupplementaryGroupsIDs?: string[]
}

function CalendarSection(props: CalendarSectionProps) {
  const calendarSync = useCalendarSync()
  const { showNotification } = useNotification()
  const currentGroupIsConvertedCalendar =
    isConvertedGroup(props.group) || isConvertedGroupId(props.event.GroupID)

  const groupOrCalendarId = props.selectedGroupId

  const [calendarTitle, onCalendarTitle] = useState<string>(
    currentGroupIsConvertedCalendar
      ? calendarSync.calendarTitle(props.thirdParty, props.group)
      : props.group?.Name ?? ''
  )
  const [showCalendarPicker, onShowCalendarPicker] = useState(false)

  const onCalendarPick = (calendar: SelectableItem) => {
    if (props.event._id?.length !== 0) {
      showNotification(t('changeNotSupported'))
      return
    }
    onShowCalendarPicker(false)
    Logger.orange('calendar', calendar)
    Logger.orange('calendar', CurrentConvertedCalendars)
    const isSharedCalendar =
      (calendar as ICalendar)?.primaryCalendarEmail !== undefined
    const thirdPartyCalendar = CurrentConvertedCalendars.find(
      (userCalendar) => {
        return (
          userCalendar.id ===
          calendar.id
            ?.replace(prefixGoogleEventsGroup, '')
            .replace(outlookPrefix, '')
        )
      }
    )
    const newGroupId = isSharedCalendar
      ? `${thirdPartyCalendar?.prefix}${thirdPartyCalendar?.primaryCalendarEmail}`
      : calendar.id
    Logger.orange('calendar', newGroupId)
    Database.write(async () => {
      if (props.event.syncStatus === 'deleted') return

      const localGroup = await localGroupById(newGroupId ?? '')

      props.event.update((event: EventDoc) => {
        event.GroupID = newGroupId
        if (thirdPartyCalendar)
          event.Color =
            darken(thirdPartyCalendar?.backgroundColor ?? '', 0.15) ??
            props.event.Color
        else {
          event.Color = localGroup.GroupColor ?? props.event.Color
        }
        event.ThirdPartyID = `${calendar.id}`
        event.OwnerID = `${(calendar as ICalendar).primaryCalendarEmail}`
      })
    })
    onCalendarTitle(calendar.title)
    if (isSharedCalendar) {
      props.setType(DocType.REGULAR_EVENT)
    } else {
      props.setType(DocType.EVENT)
    }
    props.setGroupID(newGroupId ?? '')
    props.setThirdPartyId(`${calendar.id}`)
  }

  const showCalendarPickerForGoogleEvent = () => {
    onShowCalendarPicker(true)
  }

  const data: SelectableItem[] = []

  data.push(
    ...CurrentConvertedCalendars.filter(
      (calendar) => calendar.accessRole != 'reader'
    ).map((calendar) => {
      return {
        id: calendar.id,
        title: calendar.name ?? ''
      }
    })
  )

  const [calendars, onCalendars] = useState(data)

  //load groups for the list
  useEffect(() => {
    const userId = LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    UserSettings().then((userSettings) => {
      return allGroups().then((groups) => {
        onCalendars([
          ...allGroupsForUI(groups, userSettings).map((group) => {
            const isSyncProblem = group.SyncProblem === '1'
            const notShowOnAllCalendars = isGroupHidden(
              userSettings,
              group._id ?? ''
            )
            const cantOperateEventsIn = isGroupBlockedToAddEvents(
              props.account,
              group,
              props.userSettings,
              userId,
              false
            )
            const isBlocked =
              cantOperateEventsIn || isSyncProblem || notShowOnAllCalendars

            const picSize = 16

            const subItems = isConvertedGroup(group)
              ? [
                  ...CurrentConvertedCalendars.filter(
                    (calendar) =>
                      calendar.accessRole != 'reader' &&
                      calendar.prefix === groupIdToPrefix(group._id ?? '') &&
                      calendar.primaryCalendarEmail === group._message
                  ).map((calendar) => {
                    return {
                      ...calendar
                      // subTitle: calendar.primary ? t('primaryCalendar') : '',
                      // subTitleColor: calendar.primary ? APP_COLOR : undefined
                    }
                  })
                ]
              : undefined

            let icon: ReactNode | undefined = undefined

            if (isBlocked) {
              if (isGroupHidden(userSettings, group._id ?? '')) {
                icon = <VisibilityOffIcon width={picSize} height={picSize} />
              } else {
                if (isSyncProblem) {
                  icon = <GoogleProblem />
                } else {
                  icon = <LockGroup width={picSize} height={picSize} />
                }
              }
            }
            const item: SelectableItem = {
              id: group._id,
              title: isConvertedGroup(group) ? group._message : group.Name,
              subItems,
              rightIcon: icon,
              photo: group.Photo,
              group: group,
              subTitle:
                groupOrCalendarId === group._id
                  ? `${t('primaryCalendar')}`
                  : '',
              subTitleColor:
                groupOrCalendarId === group._id ? APP_COLOR : undefined,
              notClickable: cantOperateEventsIn || isSyncProblem,
              notClickableReason: cantOperateEventsIn
                ? `${t('youDontHavePermissions')}`
                : undefined
            }
            return item
          })
        ])
      })
    })
  }, [props.event])

  const sections: CalendarSectionItem[] = []
  calendars
    .filter((calendar) => {
      return isConvertedGroupId(calendar.id)
    })
    .flatMap((calendar) => {
      sections.push({
        title: `${
          isConvertedGroupId(calendar.id)
            ? `${groupIdToProviderTypeName(calendar.id)} - `
            : ''
        } ${calendar.title}`,
        items: calendar.subItems ?? [],
        sectionIcon: calendar.rightIcon
      })
    })
  if (isGroupcalApp())
    sections.push({
      title: `${t('sharedCalendars')}`,
      items: calendars
        .filter((calendar) => {
          return !isConvertedGroupId(calendar.id)
        })
        .map((calendar) => {
          const alreadySynced =
            props.event.SupplementaryGroupsIDs?.includes(calendar.id ?? '') ||
            props.event.GroupID === calendar.id

          return {
            ...calendar,
            selected:
              calendar.id === groupOrCalendarId ||
              props.SupplementaryGroupsIDs?.includes(calendar.id ?? ''),
            notClickable: calendar.notClickable,
            notClickableReason:
              groupOrCalendarId === calendar.id ? `${t('primaryCalendar')}` : ''
          }
        }),

      availableMultiMode: true
    })

  return (
    <CalendarSelectPopup
      group={props.group}
      account={props.account}
      eventHook={props.eventHook}
      onCalendarPick={onCalendarPick}
      calendars={sections}
      open={showCalendarPicker}
      setOpen={onShowCalendarPicker}
      SupplementaryGroupsIDs={props.SupplementaryGroupsIDs ?? []}
    >
      <div>
        <PopupSection
          isUiBlocked={props.isUiBlocked}
          blockedOverlayClick={props.blockedOverlayClick}
          title={`${t('calendar')}`}
        >
          <List>
            {calendarTitle && (
              <PopupSectionRow
                key={groupOrCalendarId}
                iconClass="icon-big"
                icon={
                  <GroupCardAvatar
                    asGroup
                    img={
                      calendars.find((value) => value.id === groupOrCalendarId)
                        ?.photo
                    }
                    name=""
                    size={24}
                    emptyPic={
                      isConvertedGroupId(groupOrCalendarId)
                        ? defaultConvertedCalendarPhoto(groupOrCalendarId)
                        : props.group?.PrivacyMode === GroupPrivacy.CHANNEL
                        ? (channelDefaultPhoto as any)
                        : (groupDefaultPhoto as any)
                    }
                  />
                }
                title={calendarTitle}
                action={showCalendarPickerForGoogleEvent}
                spacing={theme.spacing(0)}
              />
            )}
            <Controller
              name="SupplementaryGroupsIDs"
              control={props.eventHook.control}
              defaultValue={props.event.SupplementaryGroupsIDs}
              render={({ field }) => {
                if (!field.value) return <></>

                return (
                  <Box>
                    {field.value?.map((groupId) => {
                      const group = calendars.find(
                        (value) => value.id === groupId
                      )?.group
                      if (!group) return <></>
                      return (
                        <PopupSectionRow
                          key={groupId}
                          title={
                            calendars.find((value) => value.id === groupId)
                              ?.title
                          }
                          action={showCalendarPickerForGoogleEvent}
                          iconClass="icon-big"
                          icon={
                            <GroupCardAvatar
                              asGroup
                              img={
                                calendars.find((value) => value.id === groupId)
                                  ?.photo
                              }
                              name=""
                              size={24}
                              emptyPic={
                                isConvertedGroup(group)
                                  ? defaultGroupImage(group)
                                  : group.PrivacyMode === GroupPrivacy.CHANNEL
                                  ? channelDefaultPhoto
                                  : groupDefaultPhoto
                              }
                            />
                          }
                        />
                      )
                    })}
                  </Box>
                )
              }}
            />
          </List>
          <ArrowRightGrey
            style={{
              transform: i18n.dir() === 'ltr' ? '' : 'rotate(180deg)',
              top: '50%',
              right: i18n.dir() === 'ltr' ? 24 : undefined,
              left: i18n.dir() === 'ltr' ? undefined : 24,
              marginTop: 4,
              position: 'absolute',
              cursor: 'pointer'
            }}
            onClick={showCalendarPickerForGoogleEvent}
            width={16}
            height={16}
          />
        </PopupSection>
      </div>
    </CalendarSelectPopup>
  )
}

interface EventPopupProps {
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc
  account?: Model<AccountDoc> | AccountDoc
  event?: Model<EventDoc> | EventDoc
  group?: Model<GroupDoc> | GroupDoc
  children: ReactNode
  open: boolean
  setOpen: (b: boolean) => void
  instanceStart?: number
  instanceEnd?: number
  onClose?: () => void
  fc_id?: string
  awayClickTriggered: boolean
  setAwayClickTriggered: (b: boolean) => void
}

export default function EventPopup(props: EventPopupProps) {
  const closeEvent = (simpleClose?: boolean, forceClose?: boolean) => {
    if (
      LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) !== '1' ||
      forceClose
    ) {
      Logger.blue('close called')
      const scrollers = document.querySelectorAll('.fc-scroller')
      scrollers.forEach((scroller) => {
        ;(scroller as any).style.overflow = 'auto'
      })
      LocalStorage.remove(LocalStorageKey.NOTE_DETAILS_ID)

      props.setOpen(false)
      if (props.onClose) {
        props.onClose()
      }

      if (simpleClose) {
        return
      }
    }
  }
  const showSmallUI =
    window.self !== window.top ||
    LocalStorage.get(LocalStorageKey.USER_ID) === null ||
    LocalStorage.get(LocalStorageKey.USER_ID)?.length === 0

  return (
    <EventPopupStyled.EventDetailsPopup
      sx={{ opacity: showSmallUI ? 0 : 1 }}
      open={props.open || false}
      onHide={() => {
        setTimeout(() => {
          LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
          LocalStorage.remove(LocalStorageKey.MORE_EVENTS_OPENED)
          LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
        }, 260)
      }}
      placement={'auto'}
      content={
        props.userSettings &&
        props.event && (
          <EventPopupContent
            {...props}
            event={props.event!}
            group={props.group}
            userSettings={props.userSettings!}
            account={props.account!}
            onClose={closeEvent}
            instanceStart={
              props.instanceStart ?? Number(props.event.StartDate) * 1000
            }
            instanceEnd={
              props.instanceEnd ?? Number(props.event.EndDate) * 1000
            }
            fc_id={props.fc_id ?? ''}
          />
        )
      }
    >
      {props.children}
    </EventPopupStyled.EventDetailsPopup>
  )
}

async function markEventAsReadIfNeeded(event?: Model<EventDoc> | EventDoc) {
  if (
    !event ||
    event._id?.length == 0 ||
    !LocalStorage.get(LocalStorageKey.USER_ID)
  )
    return

  const currentStatus =
    event.ParticipantsStatus?.[LocalStorage.get(LocalStorageKey.USER_ID) ?? '']

  if (
    !isConvertedGroupId(event.GroupID) &&
    event._id &&
    currentStatus?.ParticipantDeliveryStatus !==
      ParticipantDeliveryStatusType.READ
  ) {
    const readBody: UpdateParticipantStatusBody = {
      _id: event._id,
      ParticipantsStatus: {
        ...currentStatus,
        ParticipantDeliveryStatus: ParticipantDeliveryStatusType.READ
      },
      DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID) ?? ''
    }

    await updateParticipantStatus([readBody])
  }
}

export function parseErrorToMessage(error: AxiosError | any): string {
  const httpCode = error.response?.status

  if (httpCode === 403) {
    /**
     * we have specific cases when server return 403, but we should not retry the request, such as:
     * out of tier, user blocked from adding/editing events etc
     *
     */
    const code = (error.response?.data as unknown as any)['code']
    const isByRSVP = (error.response?.data as unknown as any)['byEventsRSVP']

    if (
      code === EDIT_EVENT_CANNOT_CHANGE_EVENT ||
      code === ADD_EVENT_CANNOT_POST_ITEMS
    ) {
      return i18n.t('thisEventPartOf')
    } else if (code === OUT_OF_TIER) {
      if (isByRSVP) {
        return i18n.t('thisCalendarDoesntAllow', {
          feature: i18n.t('rsvpForEvents')
        })
      }
      return i18n.t('calendarBlockedForChanges')
    }
  }

  return GENERIC_ERROR
}
