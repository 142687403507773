import { Backdrop, Box, CircularProgress, Grid } from '@mui/material'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { supportedFeatures } from 'components/dialogs/payment/PlansInfoDialog'
import { usePayment } from 'components/providers/PaymentProvider'

import { ComponentType, useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { downgradeGroupOnServer } from 'services/api/group'
import { AccountDoc, GroupDoc } from 'types/api/changes'
import {
  highestActiveProduct,
  highestActiveTier,
  Tier
} from 'utils/api/accounts'
import {
  provideNeededTierForGroup,
  outOfTiersFeaturesForGroup,
  TierFeatures
} from 'utils/api/groups'
import { tierToPlan, humanReadableTier } from './TierHover'
import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { outOfTiersFeaturesForAccount } from 'utils/tier'
import { APP_COLOR } from 'utils/colors'
import Analytics, { BlockedFlow, Events } from 'analytics/Analytics'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { t } from 'i18next'

interface OutOfTierDialogProps {
  group?: GroupDoc
  setOpen: (b: boolean) => void
  opened: boolean
  account: AccountDoc
  flow?: BlockedFlow
}

export function OutOfTierDialog(props: OutOfTierDialogProps) {
  const activeTier = highestActiveTier(props.account)
  const { showPaymentDialog } = usePayment()
  let landlineMode = false
  let neededTier = provideNeededTierForGroup(activeTier, props.group)
  const [loading, setLoading] = useState(0)

  const isUserOwnerOfGroup =
    props.group?.OwnerID === LocalStorage.get(LocalStorageKey.USER_ID)

  let outOfTierFeatures: TierFeatures[] = []

  if (props.group) {
    outOfTierFeatures = outOfTiersFeaturesForGroup(
      activeTier,
      props.group,
      props.account
    )
  } else if (props.account) {
    outOfTierFeatures = outOfTiersFeaturesForAccount(props.account)
  }

  /**
   * Only in case of out of tier feature is landline and it's the only one, we show special dialog
   * in that case
   */
  if (
    outOfTierFeatures.length === 1 &&
    outOfTierFeatures[0] === TierFeatures.LANDLINE
  ) {
    neededTier = Tier.BUSINESS
    landlineMode = true
  }

  const showPayments = () => {
    logAnalytics(Events.blockedGroupDialogUpgradeClick)
    props.setOpen(false)
    showPaymentDialog(
      tierToPlan(neededTier),
      undefined,
      tierFeaturesToAnalyticsFeatures(outOfTierFeatures)
    )
  }

  const hideOutOfTier = () => {
    props.setOpen(false)
    logAnalytics(Events.blockedGroupDialogCancelClick)
  }

  const downgradeGroup = async () => {
    setLoading(1)
    if (props.group) {
      await downgradeGroupOnServer(props.group._id ?? '')
      logAnalytics(Events.blockedGroupDialogDowngradeClick)
    }
    setLoading(0)
  }

  if (props.opened) highestActiveProduct(props.account)

  function logAnalytics(event: Events) {
    Analytics.getInstance().sendOutOfTierDialogAnalytics(
      event,
      isUserOwnerOfGroup ? 'Yes' : 'No',
      tierToAnalyticsTier(activeTier),
      tierFeaturesToAnalyticsFeatures(outOfTierFeatures),
      tierToAnalyticsTier(neededTier),
      props.flow
    )
  }

  useEffect(() => {
    if (props.opened) {
      logAnalytics(Events.blockedDialogPresented)
    }
  }, [props.opened])

  return (
    <ConfirmationDialog
      title={t('upgradeTo', { tier: humanReadableTier(neededTier) })}
      content={
        <>
          <Grid container flexDirection={'column'} rowGap={4}>
            {landlineMode ? <></> : <div>{t('thisSharedCalendarUses')}</div>}
            {landlineMode ? (
              <div>{t('landlineError', { appName: 'GroupCal' })}</div>
            ) : (
              <Box marginLeft={4}>
                {outOfTierFeatures.map((featureCode) => (
                  <div key={featureCode}>
                    {`- ${
                      supportedFeatures.find(
                        (supported) => supported.key === featureCode
                      )?.title
                    }`}
                  </div>
                ))}
              </Box>
            )}
            {landlineMode ? <></> : <Box>{t('youCanEither')}</Box>}
          </Grid>
          <Backdrop
            sx={{ color: APP_COLOR, zIndex: 99999 }}
            open={loading === 1}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      }
      leftButton={landlineMode ? `${t('cancel')}` : `${t('upgrade')}`}
      rightButton={
        landlineMode ? `${t('upgrade')}` : `${t('downgradeCalendar')}`
      }
      rightButtonColor={'red'}
      leftButtonColor={'black'}
      handleClose={hideOutOfTier}
      handleLeftButton={landlineMode ? hideOutOfTier : showPayments}
      handleRightButton={landlineMode ? showPayments : downgradeGroup}
      open={props.opened}
    />
  )
}

export default compose(
  withObservables([], () => ({
    account: ObserveService.Account()
  }))
)(OutOfTierDialog as ComponentType<unknown>) as any

function tierFeaturesToAnalyticsFeatures(
  outOfTierFeatures: TierFeatures[]
): string[] {
  return outOfTierFeatures.map((feature) => {
    return tierFeaturesToAnalyticsFeature(feature)
  })
}

export function tierFeaturesToAnalyticsFeature(feature?: TierFeatures): string {
  switch (feature) {
    case TierFeatures.ADMINS:
      return 'NumOfAdmins'
    case TierFeatures.BUSINESSINFO:
      return 'BusinessPage'
    case TierFeatures.CHANNEL:
      return 'CalendarChannel'
    case TierFeatures.LANDLINE:
      return 'LandlineNumber'
    case TierFeatures.MEMBERCOLOR:
      return 'MembersColors'
    case TierFeatures.PERMISSIONS:
    case TierFeatures.PERMISSIONS_CALENDAR_NAME:
    case TierFeatures.PERMISSIONS_EVENTS:
    case TierFeatures.PERMISSIONS_MEMBERS:
      return 'PermissionsManagement'
    case TierFeatures.PRIORITY_SUPPORT:
      return 'PrioritySupport'
    case TierFeatures.RSVP:
      return 'RSVP'
    case TierFeatures.VERIFIED:
      return 'VerifiedCalendar'
  }

  return ''
}

export function tierToAnalyticsTier(tier: Tier) {
  switch (tier) {
    case Tier.PRO:
      return 'Tier10'
    case Tier.BUSINESS:
      return 'Tier20'
    case Tier.BUSINESS_PLUS:
      return 'Tier30'
    default:
      return 'Free'
  }
}
