import dayjs, { Dayjs } from 'dayjs'
import i18n from 'i18n'
import { isCypress } from './cypress'
import Logger from 'services/Logger'

export { default as getDay } from 'date-fns/getDay'
export { default as format } from 'date-fns/format'
export { default as unix } from 'date-fns/fromUnixTime'
export { default as differenceSeconds } from 'date-fns/differenceInSeconds'
export { default as differenceInDays } from 'date-fns/differenceInDays'
export { default as isBefore } from 'date-fns/isBefore'
export { default as isToday } from 'date-fns/isToday'
export { default as isYesterday } from 'date-fns/isYesterday'

export interface StartEndEvent {
  start: number
  end: number
}

/**
 * generates start/end time object based on time passed
 * @param time current time in seconds
 */
export function generateStartEndBasedOnTimeWithShift(
  time: number
): StartEndEvent {
  const eventStart =
    dayjs
      .unix(time)
      .set('second', 0)
      .set('millisecond', 0)
      .add(isCypress() ? 0 : 1, 'hour')
      .minute(0)
      .valueOf() / 1000
  const eventEnd = dayjs.unix(eventStart).add(1, 'hour').valueOf() / 1000
  return { start: eventStart, end: eventEnd }
}

export function generateStartEndBasedOnTime(
  time: number,
  vieType: string
): StartEndEvent {
  let dayjsTime = dayjs.unix(time)
  if (vieType === 'dayGridMonth') {
    dayjsTime = dayjsTime.set('hour', 12)
  }
  const eventStart = dayjsTime.minute(0).valueOf() / 1000
  const eventEnd = dayjs.unix(eventStart).add(1, 'hour').valueOf() / 1000
  return { start: eventStart, end: eventEnd }
}

export function ms(t: number) {
  let year, month, day, hour, minute, second

  second = Math.floor(t / 1000)
  minute = Math.floor(second / 60)
  second = second % 60
  hour = Math.floor(minute / 60)
  minute = minute % 60
  day = Math.floor(hour / 24)
  hour = hour % 24
  month = Math.floor(day / 30)
  day = day % 30
  year = Math.floor(month / 12)
  month = month % 12

  return { year, month, day, hour, minute, second }
}

export function isAMPM(): boolean {
  const date = new Date().toLocaleString([], {
    hour: 'numeric'
  })

  if (
    !date.toLocaleLowerCase().includes('am') &&
    !date.toLocaleLowerCase().includes('pm')
  ) {
    return false
  }
  return true
}

export const isDateBetween = (
  date: Dayjs,
  start: Dayjs,
  end: Dayjs
): boolean => {
  return (
    (date.isSame(start) || date.isAfter(start)) &&
    (date.isSame(end) || date.isBefore(end))
  )
}
