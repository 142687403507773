import dayjs from 'dayjs'
import Logger from 'services/Logger'

import React, { useState, useEffect } from 'react'

import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import { eventsForReminders } from 'services/db/Queries/Event'
import { EventDoc, EventStatus } from 'types/api/changes'
import { Model } from 'types/model'
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { mapRecurrency } from 'utils/api/events'
import { isTestEnv } from 'utils/env'
import Database from 'services/db/Database'
import { isTrulyEmpty } from 'utils/object'
import { t } from 'i18next'
import { isSameDay } from 'date-fns'
import { isCypress } from 'utils/cypress'

export function MinuteTimer({ children }: { children: React.ReactNode }) {
  const [currentTime, setCurrentTime] = useState<Date>(new Date())
  const [timerId, setTimerId] = useState<number | any | undefined>(undefined)

  function showEventAlertsIfNeeded() {
    const now = dayjs()

    Logger.pink('processing reminders at:', now.toISOString())
    try {
      UserSettings().then((userSettings) => {
        eventsForReminders(userSettings).then((events) => {
          events.forEach((groupcalEvent) => {
            const instances =
              groupcalEvent.Recurrence && groupcalEvent.Recurrence.Unit
                ? generateGroupcalArrayFromRecurrence(groupcalEvent)
                : [groupcalEvent]
            //going over all reminders

            if (
              groupcalEvent?.Reminder &&
              typeof groupcalEvent?.Reminder !== 'string'
            ) {
              groupcalEvent?.Reminder?.forEach((reminder) => {
                //per each instance
                instances.forEach((instance) => {
                  const parentStart = dayjs(
                    Number(groupcalEvent.StartDate) * 1000
                  )
                  let eventStart = dayjs
                    .unix(Number(instance.StartDate))
                    .set('hour', parentStart.hour())

                  if (instance.AllDay === '1') {
                    eventStart = dayjs
                      .unix(Number(instance.StartDate))
                      .tz('UTC')
                  }

                  const eventEnd = dayjs.unix(Number(instance.EndDate))
                  const minutesDiff = eventStart.diff(
                    now.tz(instance.AllDay === '1' ? 'UTC' : undefined),
                    'minute'
                  )

                  if (
                    (reminder.offset === 0 && minutesDiff === 0) ||
                    (reminder.offset != 0 &&
                      minutesDiff === reminder.offset - 1)
                  ) {
                    Logger.debug(
                      'will present notification for ',
                      groupcalEvent
                    )
                    const startText = eventStart.format('HH:mm')
                    const endText = eventEnd.format('HH:mm')

                    let secondLine = `${startText} - ${endText}`

                    if (groupcalEvent.AllDay === '1') {
                      secondLine = `${eventStart.format('MMM DD')}, ${t(
                        'allDay'
                      )}`
                    }

                    if (startText === endText) {
                      secondLine = `${startText}`
                    }

                    if (groupcalEvent.Location?.Address) {
                      secondLine += `\n${groupcalEvent.Location.Address}`
                    }
                    if (isTestEnv()) {
                      secondLine += `\n${process.env.REACT_APP_LINK_DOMAIN}`
                    }
                    showNotification(
                      groupcalEvent.Text ?? '',
                      secondLine,
                      groupcalEvent.id
                    )
                  }
                })
              })
            }
          })
        })
      })
    } catch (e) {
      //ignore minute update errors per https://24meapp.atlassian.net/jira/software/c/projects/SER/boards/15?view=detail&selectedIssue=SER-1308
    }
  }

  function launchEveryMinute() {
    Logger.pink('minute tick')
    try {
      showEventAlertsIfNeeded()
    } catch (e) {
      Logger.red('minute', e)
    }
  }

  useEffect(() => {
    const storedTimerId = localStorage.getItem('timerId')
    if (storedTimerId) {
      if (isCypress()) return
      setTimerId(parseInt(storedTimerId, 10))
    }

    return () => {
      if (timerId) {
        clearInterval(timerId)
        localStorage.removeItem('timerId')
      }
    }
  }, [timerId])

  useEffect(() => {
    if (!timerId) {
      const msUntilNextMinute =
        (60 - currentTime.getSeconds()) * 1000 - currentTime.getMilliseconds()

      const newTimer = setInterval(() => {
        if (isCypress()) return
        setCurrentTime(new Date())
      }, 60 * 1000)

      if (isCypress()) return
      setTimerId(newTimer)
      localStorage.setItem('timerId', newTimer.toString())

      setTimeout(() => {
        if (isCypress()) return
        setCurrentTime(new Date())
        clearInterval(newTimer)
        localStorage.removeItem('timerId')

        const nextTimer = setInterval(() => {
          if (isCypress()) return
          setCurrentTime(new Date())
          launchEveryMinute()
        }, 60 * 1000)

        if (isCypress()) return
        setTimerId(nextTimer)
        localStorage.setItem('timerId', nextTimer.toString())
        launchEveryMinute()
      }, msUntilNextMinute)
      launchEveryMinute()
    }
  }, [currentTime, timerId])

  return <>{children}</>
}

export function showNotification(title: string, body: string, tag: string) {
  if (typeof Notification === 'undefined') return
  try {
    Logger.blue('notif state', Notification.permission)
    const options = {
      body: body,
      icon: '/logo192.png',
      requireInteraction: true,
      tag: tag
    }
    if (Notification.permission === 'granted') {
      new Notification(title, options)
    } else if (Notification.permission !== 'denied') {
      Promise.resolve(Notification.requestPermission()).then(function (
        permission
      ) {
        if (permission === 'granted') {
          new Notification(title, options)
        } else {
          alert(title)
        }
      })
    }
  } catch (e) {
    console.error('no way to show notif', e)
  }
}

export function generateGroupcalArrayFromRecurrence(
  groupcalEvent: EventDoc,
  ignoreExclusions = false
): Model<EventDoc>[] | EventDoc[] {
  if (!groupcalEvent.Recurrence || typeof groupcalEvent.Recurrence === 'string')
    return [groupcalEvent]

  //return itself in
  if (groupcalEvent._id?.length === 0) return [groupcalEvent]
  try {
    const durInSeconds =
      Number(groupcalEvent.EndDate) - Number(groupcalEvent.StartDate)
    const rrule = rrulestr(
      mapRecurrency(
        groupcalEvent,
        true,
        groupcalEvent.TimeZoneNameID,
        ignoreExclusions
      )
    )
    const instances = rrule.between(
      dayjs(Number(groupcalEvent.StartDate) * 1000)
        .startOf('day')
        .toDate(),
      dayjs().startOf('day').add(365, 'day').toDate()
    )

    const parentHour = dayjs(Number(groupcalEvent.StartDate) * 1000).hour()
    const parentMinute = dayjs(Number(groupcalEvent.StartDate) * 1000).minute()

    return instances.map((instance) => {
      const eventDoc = Database.toServerObject(groupcalEvent)
      const copyEvent: EventDoc = { ...groupcalEvent, ...eventDoc }
      copyEvent.StartDate = (
        dayjs(instance.valueOf())
          .set('minute', parentMinute)
          .set('hour', parentHour)
          .valueOf() / 1000
      ).toString()
      copyEvent.EndDate = (
        dayjs(instance.valueOf())
          .set('minute', parentMinute)
          .set('hour', parentHour)
          .add(durInSeconds, 'second')
          .valueOf() / 1000
      ).toString()
      if (typeof copyEvent.Recurrence?.Exclusion !== 'string') {
        copyEvent.Status =
          eventDoc.Status !== EventStatus.ACTIVE
            ? eventDoc.Status
            : copyEvent.Recurrence?.Exclusion?.find((exclusion) =>
                isSameDay(
                  Number(exclusion.Date) * 1000,
                  Number(copyEvent.StartDate) * 1000
                )
              )?.Status ?? EventStatus.ACTIVE
      }

      return copyEvent
    })
  } catch (e) {
    return [groupcalEvent]
  }
}
