import { useTheme } from '@mui/material'
import { CaretDownIcon } from 'assets/icons'
import { useMemo } from 'react'
import ReactSelect from 'react-select'
import { Option } from 'types/components/form'

import { customStyles } from './select/styles'
import * as Styled from './styled'

interface SelectProps {
  searchable?: boolean
  size?: string
  variant?: 'no-border'
  placeholder?: string
  options: Option[]
  className?: string
  value?: string | Option
  onChange?: (e: Option | null) => void
}

const Components = {
  DropdownIndicator: () => <CaretDownIcon />,
  IndicatorSeparator: () => null
}

export default function Select(props: SelectProps) {
  const { size = 'sm', placeholder = 'Choose', variant } = props
  const theme = useTheme()
  const styles = useMemo(() => customStyles(theme, size, variant), [theme])
  const value =
    typeof props.value === 'string'
      ? props.options.find((o) => o.value === props.value)
      : props.value
  return (
    <Styled.InputRoot className={props.className}>
      <ReactSelect
        styles={styles}
        value={value}
        placeholder={placeholder}
        isSearchable={props.searchable === true}
        components={Components}
        options={props.options}
        onChange={props.onChange as any}
      />
    </Styled.InputRoot>
  )
}
