import PopupSectionRow from 'components/popup/PopupSectionRow'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePickPopup from '../popups/eventInfo/DatePickPopup'
import { useCalendar } from 'components/providers/CalendarProvider'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { IEventDetails } from 'hooks/useEventDetails'
import { Box, ClickAwayListener } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { darken } from '@mui/material/styles'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { CloseIcon } from 'assets/icons'
import { is24meApp } from 'utils/appType'
import { Controller } from 'react-hook-form'
import SelectItemWithSearchPopup, {
  SelectableItem
} from 'components/popups/SelectItemWithSearchPopup'
import dateToTimeString from 'components/calendar/components'
import { minutesToHumanReadable } from 'components/popups/eventInfo/AddReminderPopup'
import { theme } from 'theme'
import { DocType } from 'types/api/changes'
import { isAMPM } from 'utils/date'
import { getDefaultTimezone } from 'index'
import { isCypress } from 'utils/cypress'
import Logger from 'services/Logger'

export enum PickerType {
  START,
  END
}

export interface DatePickerProps {
  pickerType: PickerType
  allDay: boolean
  setStartTime: (date: number) => void
  startTime: number
  setEndTime: (date: number) => void
  endTime: number
  timeZone: string | null | undefined
  setTimeZone: (tz?: string | undefined | null) => void
  duration: number
  eventHook: IEventDetails
  shouldShowTimeDropdown?: boolean
}

let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

export function EventDatePicker(props: DatePickerProps) {
  const pickerType = props.pickerType
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    allDay,
    timeZone,
    setTimeZone,
    shouldShowTimeDropdown,
    duration,
    eventHook
  } = props

  const tz =
    eventHook.timeZone && eventHook.timeZone?.length > 0
      ? eventHook.timeZone
      : getDefaultTimezone()

  const zonedStart = dayjs(startTime).tz(tz)
  let zonedEnd = dayjs(endTime).tz(tz)

  const [dateState, setDateState] = useState(false)

  const openDate = () => {
    setDateState(true)
  }

  const calendar = useCalendar()

  let dayjsTime = pickerType === PickerType.START ? zonedStart : zonedEnd

  const [time, setTime] = useState<Dayjs | null>(dayjsTime)

  const onAcceptDate = (date: Dayjs | null) => {
    let newDate = date?.tz(props.eventHook.timeZone) ?? dayjs()

    if (allDay) {
      newDate = newDate.set('hour', 0).set('minute', 0).set('second', 0)
    }

    setTime(newDate)

    if (pickerType === PickerType.START) {
      const dayVisible =
        newDate.isAfter(
          dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_FROM))
        ) &&
        newDate.isBefore(
          dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_TO)).subtract(1, 'day')
        )
      if (!dayVisible && props.eventHook.isNewEvent) {
        calendar.onGoToDate(newDate.toDate())
        calendar.onScrollToTime(newDate)
      }

      setStartTime(newDate.valueOf())
      setTimeout(
        () => {
          if (allDay || !props.eventHook.isNewEvent) return
          try {
            ;(calendar.ref as any)?.current.getApi().scrollToTime(
              dayjs(newDate)
                .tz(timeZone ?? getDefaultTimezone())
                .format('HH:mm:ss')
            )
          } catch (e) {
            //can't go to date
          }
        },
        !dayVisible ? 250 : 1
      )
    } else if (pickerType === PickerType.END) {
      console.log(newDate)
      setEndTime(newDate.valueOf())
    }
  }

  const { t } = useTranslation()

  const [showTimeMinutesPopup, setShowTimeMinutesPopup] = useState(false)

  function buildMinutes() {
    let minutes: SelectableItem[] = []
    for (let i = 1; i < 7; i++) {
      const time = zonedEnd
        .add(0 - duration, 'milliseconds')
        .add(i * 30, 'minute')
      const selectableTime: SelectableItem = {
        title: dateToTimeString(time.toDate(), true),
        subTitle: minutesToHumanReadable(i * 30),
        value: time
      }
      minutes.push(selectableTime)
    }
    return minutes
  }

  // Inside your component:
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        setDateState(false)
        setShowTimeMinutesPopup(false)
      }}
    >
      <div>
        <PopupSectionRow
          icon={
            pickerType === PickerType.START ? (
              <div>{t('starts')}</div>
            ) : (
              <div>{t('ends')}</div>
            )
          }
          title={
            <DatePickPopup
              onAccept={onAcceptDate}
              value={dayjsTime}
              open={dateState}
              setOpen={setDateState}
              eventHook={props.eventHook}
              instanceStart={
                pickerType === PickerType.START
                  ? props.startTime
                  : props.endTime
              }
            >
              <div
                id={
                  pickerType === PickerType.START
                    ? 'start-date-picker'
                    : 'end-date-picker'
                }
                style={{ cursor: 'pointer', width: 'fit-content' }}
                onClick={openDate}
              >
                {props.eventHook.getValues('someday') === '1' ? (
                  <>{t('clickToSet')}</>
                ) : (
                  <Box
                    onClick={() => {}}
                    display={'flex'}
                    flexWrap={'wrap'}
                    flexDirection={'row'}
                    width={'fit-content'}
                    alignItems={'center'}
                    gap={1}
                  >
                    {`${dayjsTime.format('dddd')}, ${dayjsTime.format('LL')}`}
                    {is24meApp() && (
                      <Controller
                        name="Type"
                        control={props.eventHook.control}
                        defaultValue={props.eventHook.type}
                        render={({ field }) => {
                          if (field.value !== DocType.TASK) return <></>

                          return (
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation()
                                props.eventHook.setSomeday('1')
                              }}
                            />
                          )
                        }}
                      />
                    )}
                  </Box>
                )}
              </div>
            </DatePickPopup>
          }
          value={
            allDay ? (
              <></>
            ) : (
              <Controller
                name="someday"
                control={props.eventHook.control}
                defaultValue={props.eventHook.someday}
                render={({ field }) => {
                  if (field.value === '1') return <></>

                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <SelectItemWithSearchPopup
                        data={buildMinutes()}
                        width="fit-content"
                        placement="bottom"
                        open={showTimeMinutesPopup}
                        selectedItemId={undefined}
                        rowBuilder={(item) => {
                          return buildTimeRow({
                            item,
                            setShowTimeMinutesPopup,
                            onAcceptDate
                          })
                        }}
                      >
                        <TimeField
                          id={
                            pickerType === PickerType.START
                              ? 'start-time-picker'
                              : 'end-time-picker'
                          }
                          hiddenLabel
                          ampm={isCypress() ? false : isAMPM()}
                          timezone={tz}
                          value={dayjsTime}
                          variant="standard"
                          inputProps={{
                            style: {
                              cursor: 'pointer',
                              fontSize: '14px',
                              padding: 0,
                              borderBottom: 'none',
                              textAlign: 'end',
                              width: '60px'
                            }
                          }}
                          onFocus={
                            shouldShowTimeDropdown
                              ? () => {
                                  setShowTimeMinutesPopup(true)
                                }
                              : undefined
                          }
                          onBlur={() => {}}
                          InputProps={{ disableUnderline: true }}
                          onChange={(newValue) => {
                            setShowTimeMinutesPopup(false)

                            if (timeoutRef.current) {
                              clearTimeout(timeoutRef.current)
                            }

                            ;(timeoutRef.current as any) = setTimeout(() => {
                              setTime(newValue)
                              onAcceptDate(newValue)
                            }, 500)
                          }}
                        />
                      </SelectItemWithSearchPopup>
                    </LocalizationProvider>
                  )
                }}
              />
            )
          }
        />
      </div>
    </ClickAwayListener>
  )
}

interface TimeRowProps {
  item: SelectableItem
  setShowTimeMinutesPopup: (show: boolean) => void
  onAcceptDate: (date: Dayjs | null) => void
}

function buildTimeRow(props: TimeRowProps) {
  const { item, setShowTimeMinutesPopup, onAcceptDate } = { ...props }
  return (
    <Box
      key={item.value.valueOf()}
      onClick={(e) => {
        e.stopPropagation()
        setShowTimeMinutesPopup(false)
        onAcceptDate(item.value)
      }}
      width={'fit-content'}
      display={'flex'}
      alignContent={'center'}
      flexDirection={'row'}
      color={'black'}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: darken(
            theme.palette.button.background.primary as string,
            0.02
          )
        }
      }}
      gap={4}
    >
      <div style={{ minWidth: '65px' }}>{item.title}</div>
      <div style={{ color: theme.palette.text.secondary }}>{item.subTitle}</div>
    </Box>
  )
}
