import { ClickAwayListener, IconButton } from '@mui/material'
import { SearchIcon } from 'assets/icons'
import { t } from 'i18next'
import SearchPopup, { SEARCH_MODE } from './popups/searchItems/SearchPopup'
import GroupcalTooltip from './tooltips/GroupcalTooltip'
import { useState } from 'react'
import { CircleIconButton } from './styled'

export interface SearchEventsPopupProps {
  mode: SEARCH_MODE
  groupId?: string
  textfieldPlaceholder?: string
}

export default function SearchPopupButton(props: SearchEventsPopupProps) {
  const [searchState, setSearchState] = useState(false)

  const showSearch = () => {
    setSearchState(true)
  }

  const hideSearch = () => {
    setSearchState(false)
  }
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={hideSearch}
    >
      <div>
        <SearchPopup
          groupId={props.groupId}
          mode={props.mode}
          open={searchState}
          setOpen={setSearchState}
          textfieldPlaceholder={props.textfieldPlaceholder}
        >
          <span onClick={showSearch}>
            <GroupcalTooltip title={t('search')}>
              <CircleIconButton sx={{ width: '52px', height: '52px' }}>
                <SearchIcon />
              </CircleIconButton>
            </GroupcalTooltip>
          </span>
        </SearchPopup>
      </div>
    </ClickAwayListener>
  )
}
