import Grid from '@mui/material/Grid'

import { AlarmIcon, CloseIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { ChangeEvent, useState } from 'react'
import { EventNote, EventReminder } from 'types/api/changes'
import Checkbox from '@mui/material/Checkbox'
import { theme } from 'theme'
import { Typography } from '@mui/material'
import { TextWithLinks } from 'components/TextWithLinks'
import ImageDetailsDialog from 'components/dialogs/ImageDetailsDialog'

interface NotesListProps {
  notes: EventNote[]
  setNotes?: (notes: EventNote[]) => void
  checkable?: boolean
  disableDelete?: boolean
}

export default function NotesList(props: NotesListProps) {
  const { notes, setNotes } = props
  const removeNote = (noteToRemove: EventNote) => {
    const withoutNote = notes.filter(
      (note) => note.NoteID != noteToRemove.NoteID
    )
    if (setNotes) setNotes(withoutNote)
  }

  const [data, setData] = useState<string | undefined>()
  const [open, setOpen] = useState(false)

  if (!notes || typeof notes === 'string') return <></>

  return (
    <>
      {notes?.map((note, index) => {
        return (
          <Grid
            container
            key={note.NoteID}
            direction={'row'}
            display={'flex'}
            wrap={'nowrap'}
            columnGap={2}
            justifyContent={'space-between'}
          >
            {props.checkable ? (
              <Checkbox
                sx={{
                  padding: 0,
                  '&.Mui-checked': {
                    color: theme.palette.input.placeholder
                  }
                }}
                size={'medium'}
                checked={note.Status != 1}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const updatedNotes = [...props.notes]
                  updatedNotes[index].Status = event.target.checked ? 2 : 1
                  if (props.setNotes) props.setNotes(updatedNotes)
                }}
              />
            ) : (
              <></>
            )}
            <PopupSectionRow
              title={
                <span
                  style={{
                    width: '282px',
                    display: 'block',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    color: note.FileBytes ? 'blue' : undefined,
                    cursor: note.FileBytes ? 'pointer' : 'default',
                    textDecoration:
                      note.Status != 1
                        ? 'line-through'
                        : note.FileBytes
                        ? 'underline'
                        : 'none'
                  }}
                >
                  <TextWithLinks text={note.Note} />
                </span>
              }
              action={
                note.FileBytes
                  ? () => {
                      if (note.FileBytes) {
                        // show dialog here
                        setData(note.FileBytes)
                        setOpen(true)
                      }
                    }
                  : undefined
              }
              value={
                !props.disableDelete ? (
                  <IconButton
                    size="xs"
                    onClick={() => {
                      removeNote(note)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <></>
                )
              }
            />
          </Grid>
        )
      })}
      <ImageDetailsDialog
        open={open}
        onClose={() => {
          setOpen(false)
          setTimeout(() => {
            setData(undefined)
          }, 500)
        }}
        imageBase64={data ?? ''}
      />
    </>
  )
}
