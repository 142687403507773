import { Box } from '@mui/material'
import { Late, Notes, RecurItem } from 'assets/icons'
import { isToday } from 'date-fns'
import dayjs from 'dayjs'
import { EventDoc } from 'types/api/changes'

export interface IconsForEventProps {
  item: EventDoc
  instanceEnd?: number
}

export default function IconsForEvent(props: IconsForEventProps) {
  const { item, instanceEnd } = props
  const lateItem =
    item.late ||
    (isToday(Number(item.StartDate) * 1000) &&
      dayjs(Number(item.EndDate) * 1000).isBefore(dayjs())) ||
    (instanceEnd &&
      isToday(instanceEnd) &&
      dayjs(instanceEnd).isBefore(dayjs()))
  const hasIcons =
    lateItem ||
    (item.Notes && item.Notes?.length > 0) ||
    (item.Recurrence && typeof item.Recurrence !== 'string')

  if (!hasIcons) return <></>

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      gap={0.5}
      alignItems={'center'}
      marginRight={0.5}
      minHeight={'20px'}
    >
      {lateItem && <Late />}
      {item.Notes && item.Notes?.length > 0 && <Notes width={12} height={12} />}
      {item.Recurrence &&
        typeof item.Recurrence !== 'string' &&
        Object.keys(item.Recurrence).length > 0 && (
          <RecurItem width={12} height={12} />
        )}
    </Box>
  )
}
