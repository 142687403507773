import {
  AccountDoc,
  EventDoc,
  GroupDoc,
  GroupParticipant,
  GroupParticipantStatus,
  GroupParticipantType,
  ParticipantDataDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import {
  differenceInDays,
  differenceSeconds,
  format,
  isToday,
  isYesterday,
  unix
} from 'utils/date'
import { difference, isEmpty, isEqual } from 'utils/object'

import groupDefaultPhoto from '../../assets/icons/group-default.svg'
import channelDefaultPhoto from '../../assets/icons/channel_calendar.svg'
import googleEvents from '../../assets/img/google-events.svg'
import outlookEvents from '../../assets/img/outlook-events.svg'
import { GroupPrivacy } from 'model/models/Group'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { Tier, highestActiveTier } from './accounts'

import i18n from '../../i18n'
import {
  isOwnerForGroup as isCurrentUserOwnerForGroup,
  isOwnerForGroup
} from 'services/db/utils/GroupUtils'
import { isGroupHidden } from './userSettings'
import { is24meApp } from 'utils/appType'
import { allGroups, localGroupById } from 'services/db/Queries/GroupQueries'
import { Account } from 'services/db/Queries/AccountQueries'
import {
  defaultGroupId,
  groupIdToProvider,
  groupToProvider,
  isConvertedGroup
} from 'utils/groups'
import { UserSettings } from 'services/db/Queries/UserSettingsQueries'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'
import { calApple, calGoogle, calMs } from 'assets/img'

export function groupHasMembersWithColor(group: GroupDoc) {
  if (group.Participants) {
    const participants = Object.values(group.Participants)

    if (participants.length > 0) {
      return participants.some(
        (participant) =>
          participant.MemberColor &&
          participant.MemberColor.length > 0 &&
          participant.MemberColor != 'null'
      )
    }
  }

  return false
}

export function amountOfAdminsInGroup(group: GroupDoc) {
  let admins = 0

  if (group.Participants) {
    const participants = Object.values(group.Participants)

    if (participants.length > 0) {
      participants.forEach((participant) => {
        if (participant.Type === GroupParticipantType.ADMIN) admins += 1
      })
    }
  }

  return admins
}

export function isAllPermissionsEnabled(group: GroupDoc) {
  return (
    group.GroupSettings.IsAllParticipantsCanAddItems === '1' &&
    group.GroupSettings.IsAllParticipantsCanAddParticipants === '1' &&
    group.GroupSettings.IsAllParticipantsCanEditGroupMetadata === '1'
  )
}

export function isUserParticipantOfAGroup(
  group: GroupDoc | undefined | null,
  userID: string
): boolean {
  if (!group) return false

  const participant = group?.Participants?.[userID]
  return participant != undefined && participant != null
}

export function isUserActiveInGroup(
  group: GroupDoc | undefined,
  userID: string
) {
  if (!group || isConvertedGroup(group)) return true

  const participant = group?.Participants?.[userID]
  return participant?.Status === GroupParticipantStatus.ACTIVE
}

export function isGroupDocRecentlyUpdated(group: GroupDoc): boolean {
  const creationDate = unix(+group.CreationDate)
  const updateDate = unix(+(group.LastUpdate ?? 0))
  return (
    differenceInDays(updateDate, creationDate) < 1 &&
    differenceSeconds(updateDate, creationDate) < 5
  )
}

function filterActiveMembers(participant: GroupParticipant) {
  return participant?.Status === GroupParticipantStatus.ACTIVE
}

function groupActiveMembersCount(group: GroupDoc): number {
  return (
    Object.values(group.Participants || {}).filter(filterActiveMembers).length +
    Object.values(group.PendingParticipants || {}).filter(filterActiveMembers)
      .length
  )
}

export function isGroupBlockedToAddEvents(
  account: AccountDoc,
  group: GroupDoc,
  userSettings: UserSettingsDoc | undefined,
  userId: string,
  withHiddenCheck = true
) {
  if (!group) return true

  return (
    isGroupOutOfTier(highestActiveTier(account), group, account) ||
    group?.SyncProblem === '1' ||
    !isUserCanOperateEvents(group, userId) ||
    !isUserActiveInGroup(group, userId) ||
    (withHiddenCheck && isGroupHidden(userSettings, group._id ?? ''))
  )
}

export function isUserCanOperateEvents(group?: GroupDoc, userId?: string) {
  if (is24meApp()) return true

  if (!group || !userId) return false

  if (isConvertedGroup(group)) return true

  const isAdmin =
    group.Participants?.[userId]?.Type === GroupParticipantType.ADMIN
  const canAddAll = group.GroupSettings?.IsAllParticipantsCanAddItems === '1'

  return isAdmin || canAddAll
}

export function isUserAdminInGroup(group: GroupDoc, userID: string) {
  return group.Participants?.[userID]?.Type === GroupParticipantType.ADMIN
}

export function isUserOwnerOfGroup(group: GroupDoc, userID: string) {
  return group.OwnerID === userID
}

function addGroupMessage(group: GroupDoc, message: string): GroupDoc {
  return {
    ...group,
    _sortOrder:
      group._sortOrder === 0 ? Number(group.LastUpdate) : group._sortOrder,
    _message: message,
    Name: group.DeviceChangeID?.toLowerCase().includes('prepopulated_group')
      ? nameForPrepopulatedGroup(group)
      : group.Name,
    UserID: LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  }
}

function addGroupSortOrder(group: GroupDoc, sortOrder: number): GroupDoc {
  return {
    ...group,
    _sortOrder: sortOrder
  }
}

export function updateGroupDocMessage(
  userID: string,
  newGroup: GroupDoc,
  prevGroup: GroupDoc,
  participants: Model<ParticipantDataDoc>[]
): GroupDoc {
  if (
    prevGroup &&
    !isUserActiveInGroup(
      prevGroup,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    ) &&
    !isUserActiveInGroup(
      newGroup,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )
  ) {
    /**
     * If user are inactive in a group and we receied update about it
     * we need to skip it
     */

    return prevGroup
  }

  const newGroupLastUpdate = Number(newGroup.LastUpdate)

  const isCurrentUserChangedEvent = newGroup.UserID === userID
  const isActiveInGroup = isUserActiveInGroup(newGroup, userID)
  const userMadeAction = participants.find(
    (participant) => participant.AccountID === newGroup.UserID
  )

  let userTitleMadeChange =
    userMadeAction?.FullName ?? userMadeAction?.PhoneNumber ?? ''
  let groupParameterWhichChanged = ''

  if (isGroupDocRecentlyUpdated(newGroup)) {
    if (newGroup.DeviceChangeID?.toLowerCase().includes('prepopulated_group')) {
      return addGroupMessage(
        { ...newGroup, Name: nameForPrepopulatedGroup(newGroup) },
        `${i18n.t('prepopulatedCreated')}`
      )
    } else if (newGroup.OwnerID === userID) {
      return addGroupSortOrder(
        addGroupMessage(newGroup, `${i18n.t('youCreatedCalendar')}`),
        newGroupLastUpdate
      )
    } else {
      return addGroupSortOrder(
        addGroupMessage(
          newGroup,
          userTitleMadeChange
            ? `${i18n.t('userCreatedCalendar', { userTitleMadeChange })}`
            : `${i18n.t('calendarCreated')}`
        ),
        newGroupLastUpdate
      )
    }
  }

  if (!isActiveInGroup) {
    const user = newGroup.Participants?.[userID]
    const removedBy = user?.RemovedBy

    if (removedBy && removedBy === userID) {
      return addGroupSortOrder(
        addGroupMessage(newGroup, `${i18n.t('youLeft')}`),
        newGroupLastUpdate
      )
    } else {
      if (userTitleMadeChange) {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            `${i18n.t('removedYouFrom', { userTitleMadeChange })}`
          ),
          newGroupLastUpdate
        )
      } else {
        return addGroupSortOrder(
          addGroupMessage(newGroup, `${i18n.t('removedYouGeneral')}`),
          newGroupLastUpdate
        )
      }
    }
  }

  if (
    !prevGroup ||
    (prevGroup && !isUserActiveInGroup(prevGroup, userID) && isActiveInGroup)
  ) {
    if (newGroup.OwnerID === userID) {
      return addGroupSortOrder(
        addGroupMessage(newGroup, `${i18n.t('youCreated')}`),
        newGroupLastUpdate
      )
    } else {
      if (newGroup.Participants[userID].InvitedBy === 'sharelink') {
        return addGroupSortOrder(
          addGroupMessage(newGroup, `${i18n.t('youJoined')}`),
          newGroupLastUpdate
        )
      }

      return addGroupSortOrder(
        addGroupMessage(newGroup, `${i18n.t('youAdded')}`),
        newGroupLastUpdate
      )
    }
  }

  if (prevGroup) {
    // const updatedGroupHasPhoto = newGroup.Photo !== null && newGroup.Photo;

    if (newGroup.Photo !== prevGroup.Photo && newGroup.Photo) {
      groupParameterWhichChanged = `${i18n.t('photo')}`
    }

    if (newGroup.GroupColor !== prevGroup.GroupColor) {
      groupParameterWhichChanged = `${i18n.t('color')}`
    }

    if (newGroup.Name !== prevGroup.Name) {
      groupParameterWhichChanged = `${i18n.t('name')}`
    }

    if (groupParameterWhichChanged) {
      // In case GroupName is changed, we need to move group up!
      // If changed Photo, Color etc... no need to move group up - use prev sortOrder.
      const sortOrder =
        groupParameterWhichChanged === 'name'
          ? newGroup.LastUpdate
          : prevGroup._sortOrder

      if (isCurrentUserChangedEvent) {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            `${i18n.t('youChangedInCal', { groupParameterWhichChanged })}`
          ),
          Number(sortOrder)
        )
      } else {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            `${i18n.t('changedInCalGeneral', { groupParameterWhichChanged })}`
          ),
          Number(sortOrder)
        )
      }
    }

    const previousGroupMembersCount = groupActiveMembersCount(prevGroup)
    const updatedGroupMembersCount = groupActiveMembersCount(newGroup)

    if (
      isUserAdminInGroup(newGroup, userID) &&
      !isUserAdminInGroup(prevGroup, userID)
    ) {
      return addGroupSortOrder(
        addGroupMessage(
          newGroup,
          userTitleMadeChange
            ? `${i18n.t('madeYouAdmin', { userTitleMadeChange })}`
            : `${i18n.t('youNowAdmin')}`
        ),
        newGroupLastUpdate
      )
    }

    if (
      !isUserAdminInGroup(newGroup, userID) &&
      isUserAdminInGroup(prevGroup, userID)
    ) {
      return addGroupSortOrder(
        addGroupMessage(newGroup, `${i18n.t('youNoLongerAdmin')}`),
        newGroupLastUpdate
      )
    }

    if (updatedGroupMembersCount > previousGroupMembersCount) {
      if (isCurrentUserChangedEvent) {
        return addGroupSortOrder(
          addGroupMessage(newGroup, `${i18n.t('youAddedParticipants')}`),
          prevGroup._sortOrder
        )
      } else {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            userTitleMadeChange
              ? `${i18n.t('userAddedMembers', { userTitleMadeChange })}`
              : `${i18n.t('membersAdded')}`
          ),
          prevGroup._sortOrder
        )
      }
    }

    const participantsChange: Record<
      string,
      Partial<GroupParticipant>
    > = difference(prevGroup.Participants, newGroup.Participants)

    if (!isEmpty(participantsChange)) {
      const participantChangeIds = Object.keys(participantsChange)

      if (participantChangeIds.includes(newGroup.UserID ?? '')) {
        if (
          participantsChange[newGroup.UserID ?? ''].Status ===
          GroupParticipantStatus.INACTIVE
        ) {
          return addGroupSortOrder(
            addGroupMessage(newGroup, `${i18n.t('memberLeft')}`),
            prevGroup._sortOrder
          )
        }
      }

      const removedMembers = Object.values(participantsChange).filter(
        (participant) => participant.Status === GroupParticipantStatus.INACTIVE
      )

      if (removedMembers.length) {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            isCurrentUserChangedEvent
              ? `${i18n.t('youRemovedMembers')}`
              : userTitleMadeChange
              ? `${i18n.t('userRemovedMembers', { userTitleMadeChange })}`
              : `${i18n.t('membersRemovedGeneral')}`
          ),
          prevGroup._sortOrder
        )
      }
    }

    if (!isEqual(newGroup.GroupSettings, prevGroup.GroupSettings)) {
      if (isCurrentUserChangedEvent) {
        return addGroupSortOrder(
          addGroupMessage(newGroup, `${i18n.t('youChangedPermissions')}`),
          prevGroup._sortOrder
        )
      } else {
        return addGroupSortOrder(
          addGroupMessage(
            newGroup,
            userTitleMadeChange
              ? `${i18n.t('userChangedPermissions', { userTitleMadeChange })}`
              : `${i18n.t('permissionsChangeGeneral')}`
          ),
          prevGroup._sortOrder
        )
      }
    }

    if (!isEqual(newGroup.BusinessInfo?.Text, prevGroup.BusinessInfo?.Text)) {
      return addGroupSortOrder(
        addGroupMessage(newGroup, prevGroup?._message),
        prevGroup._sortOrder
      )
    }

    return addGroupSortOrder(
      addGroupMessage(newGroup, prevGroup?._message),
      prevGroup._sortOrder
    )
  }

  return addGroupMessage(newGroup, (prevGroup as GroupDoc)?._message)
}

export function formatLastUpdate(lastUpdate: number): string {
  if (!lastUpdate) return ''

  const date = unix(lastUpdate)

  if (isToday(date)) {
    return format(date, 'h:mm aa')
  }

  if (isYesterday(date)) {
    return i18n.t('yesterday')
  }

  return format(date, 'dd/MM/yyyy')
}

export function getGroupMessage(
  group: Model<GroupDoc> | GroupDoc | undefined,
  event: Model<EventDoc> | undefined
): string {
  return (
    (Number(event?.LastUpdate) >= (group?._sortOrder ?? 0) &&
    event?._message &&
    event?._message.length > 0
      ? event?._message
      : group?._message) ?? ''
  )
}

export function groupsToImages(groups?: Model<GroupDoc>[]): string[] {
  if (!groups) return []
  return groups.map((group) => {
    return group.Photo ? group.Photo : defaultGroupImage(group)
  })
}

export function defaultConvertedCalendarPhoto(groupId: string | undefined) {
  if (!groupId) return groupDefaultPhoto
  const provider = groupIdToProvider(groupId)

  if (provider === ProviderType.GOOGLE) {
    return calGoogle
  } else if (provider === ProviderType.OUTLOOK) {
    return calMs
  } else if (provider === ProviderType.APPLE) {
    return calApple
  }

  return groupDefaultPhoto
}

export function defaultGroupImage(group?: GroupDoc): any {
  if (!group) return groupDefaultPhoto

  if (isConvertedGroup(group)) {
    return defaultConvertedCalendarPhoto(group._id)
  }

  return group.PrivacyMode === GroupPrivacy.CHANNEL
    ? channelDefaultPhoto
    : groupDefaultPhoto
}

export function getActiveParticipants(
  participants: Record<string, GroupParticipant>
) {
  if (!participants) return []
  let activeParticipants = Object.keys(participants)
    .filter(function (key) {
      let entry = participants[key]
      return entry.Status === GroupParticipantStatus.ACTIVE
    })
    .reduce(
      (res: Record<string, GroupParticipant>, key) => (
        (res[key] = participants[key]), res
      ),
      {}
    )

  return activeParticipants
}

export function isAddingMemberAllowedForGroup(group: GroupDoc) {
  if (!group) return false

  if (isCurrentUserOwnerForGroup(group)) return true

  return group.GroupSettings.IsAllParticipantsCanAddParticipants === '1'
}

export function isEventsActionsAllowed(
  group: GroupDoc | undefined,
  userId: string
) {
  if (!group || isConvertedGroup(group)) return true

  return (
    group.OwnerID === userId ||
    (group.GroupSettings.IsAllParticipantsCanAddItems === '1' &&
      isUserActiveInGroup(group, userId))
  )
}

export enum TierFeatures {
  PRIORITY_SUPPORT = 'prioritySupport',
  LANDLINE = 'landline',
  ADMINS = 'numAdmins',
  BUSINESSINFO = 'business',
  PERMISSIONS = 'permManagement',
  PERMISSIONS_EVENTS = 'permManagementAddEvents',
  PERMISSIONS_CALENDAR_NAME = 'permManagementCalendarName',
  PERMISSIONS_MEMBERS = 'permManagementAddMembers',
  VERIFIED = 'verifiedChannels',
  CHANNEL = 'calendarChannels',
  MEMBERCOLOR = 'memberColors',
  RSVP = 'rsvp',
  SYNC_WITH_CALENDARS = 'syncWithCalendars'
}

export function provideNeededTierForGroup(
  currentTier: Tier,
  group?: GroupDoc
): Tier {
  if (isGroupOutOfTier(currentTier, group)) {
    const features = outOfTiersFeaturesForGroup(currentTier, group)

    let largest = Tier.FREE

    features.forEach((feature) => {
      const newLargest = featureToMinimumTierNeeded(
        feature,
        feature === TierFeatures.ADMINS && group
          ? amountOfAdminsInGroup(group)
          : undefined
      )

      if (newLargest > largest) largest = newLargest
    })

    return largest
  }

  return Tier.FREE
}

export function isGroupOutOfTier(
  currentTier: Tier,
  group?: GroupDoc,
  accountDoc?: AccountDoc
) {
  if (!group) return false
  if (group.OwnerID != LocalStorage.get(LocalStorageKey.USER_ID)) return false

  return outOfTiersFeaturesForGroup(currentTier, group, accountDoc).length > 0
}

export function isGroupChannel(group?: GroupDoc) {
  if (!group) return false

  return group.PrivacyMode === GroupPrivacy.CHANNEL
}

export function outOfTiersFeaturesForGroup(
  tier: Tier,
  group?: GroupDoc,
  account?: AccountDoc
): TierFeatures[] {
  let outOfTierFeatures: TierFeatures[] = []
  if (!isFeatureAllowedToUser(TierFeatures.ADMINS, tier, group, account))
    outOfTierFeatures.push(TierFeatures.ADMINS)
  if (!isFeatureAllowedToUser(TierFeatures.BUSINESSINFO, tier, group, account))
    outOfTierFeatures.push(TierFeatures.BUSINESSINFO)
  if (!isFeatureAllowedToUser(TierFeatures.CHANNEL, tier, group, account))
    outOfTierFeatures.push(TierFeatures.CHANNEL)
  if (!isFeatureAllowedToUser(TierFeatures.LANDLINE, tier, group, account))
    outOfTierFeatures.push(TierFeatures.LANDLINE)
  if (!isFeatureAllowedToUser(TierFeatures.MEMBERCOLOR, tier, group, account))
    outOfTierFeatures.push(TierFeatures.MEMBERCOLOR)
  if (
    !isFeatureAllowedToUser(
      TierFeatures.PERMISSIONS_CALENDAR_NAME,
      tier,
      group,
      account
    )
  )
    outOfTierFeatures.push(TierFeatures.PERMISSIONS_CALENDAR_NAME)
  if (
    !isFeatureAllowedToUser(
      TierFeatures.PERMISSIONS_EVENTS,
      tier,
      group,
      account
    )
  )
    outOfTierFeatures.push(TierFeatures.PERMISSIONS_EVENTS)
  if (
    !isFeatureAllowedToUser(
      TierFeatures.PERMISSIONS_MEMBERS,
      tier,
      group,
      account
    )
  )
    outOfTierFeatures.push(TierFeatures.PERMISSIONS_MEMBERS)
  if (!isFeatureAllowedToUser(TierFeatures.VERIFIED, tier, group, account))
    outOfTierFeatures.push(TierFeatures.VERIFIED)

  return outOfTierFeatures
}

export function isFeatureAllowedToUser(
  feature: TierFeatures,
  currentTier: Tier,
  group?: GroupDoc,
  accountDoc?: AccountDoc
) {
  if (group && group.OwnerID != LocalStorage.get(LocalStorageKey.USER_ID))
    return true // for all old groups or for non-owners, we mark all features as allowed

  switch (feature) {
    case TierFeatures.RSVP:
      if (currentTier < featureToMinimumTierNeeded(TierFeatures.RSVP))
        return false
      break
    case TierFeatures.PRIORITY_SUPPORT:
      if (
        currentTier < featureToMinimumTierNeeded(TierFeatures.PRIORITY_SUPPORT)
      )
        return false
      break

    case TierFeatures.LANDLINE:
      if (group) {
        if (group.OwnerID === LocalStorage.get(LocalStorageKey.USER_ID)) {
          if (
            accountDoc &&
            currentTier < featureToMinimumTierNeeded(TierFeatures.LANDLINE) &&
            accountDoc.PhoneNumberType === '2'
          )
            return false
        }
      } else {
        if (
          currentTier < featureToMinimumTierNeeded(TierFeatures.LANDLINE) &&
          accountDoc &&
          accountDoc.PhoneNumberType === '2'
        )
          return false
      }
      break

    case TierFeatures.ADMINS:
      if (Number(group?.Version ?? '1') < 2) return true
      if (group) {
        if (amountOfAdminsInGroup(group) > allowedAdminsForTier(currentTier)) {
          return false
        } else {
          return true
        }
      }
      break

    case TierFeatures.BUSINESSINFO:
      if (group) {
        if (
          group.BusinessInfo?.Active === '1' &&
          currentTier < featureToMinimumTierNeeded(TierFeatures.BUSINESSINFO)
        )
          return false
        else return true
      }

      if (currentTier < featureToMinimumTierNeeded(TierFeatures.BUSINESSINFO))
        return false
      break
    case TierFeatures.PERMISSIONS:
      if (
        currentTier < featureToMinimumTierNeeded(TierFeatures.PERMISSIONS) &&
        group &&
        !isAllPermissionsEnabled(group)
      )
        return false
      break
    case TierFeatures.PERMISSIONS_CALENDAR_NAME:
      if (
        group?.GroupSettings &&
        currentTier <
          featureToMinimumTierNeeded(TierFeatures.PERMISSIONS_CALENDAR_NAME) &&
        group &&
        group.GroupSettings.IsAllParticipantsCanEditGroupMetadata != '1'
      )
        return false
      break
    case TierFeatures.PERMISSIONS_EVENTS:
      if (
        group?.GroupSettings &&
        currentTier <
          featureToMinimumTierNeeded(TierFeatures.PERMISSIONS_EVENTS) &&
        group &&
        group.GroupSettings.IsAllParticipantsCanAddItems != '1'
      )
        return false
      break
    case TierFeatures.PERMISSIONS_MEMBERS:
      if (
        group?.GroupSettings &&
        currentTier <
          featureToMinimumTierNeeded(TierFeatures.PERMISSIONS_MEMBERS) &&
        group &&
        group.GroupSettings.IsAllParticipantsCanAddParticipants != '1'
      )
        return false
      break
    case TierFeatures.VERIFIED:
      if (group) {
        if (
          currentTier < featureToMinimumTierNeeded(TierFeatures.VERIFIED) &&
          group.VerifiedGroupStatus === '2'
        ) {
          return false
        } else {
          return true
        }
      }
      if (currentTier < featureToMinimumTierNeeded(TierFeatures.VERIFIED))
        return false
      break

    case TierFeatures.CHANNEL:
      if (group) {
        if (
          group.PrivacyMode === GroupPrivacy.CHANNEL &&
          currentTier < featureToMinimumTierNeeded(TierFeatures.CHANNEL)
        ) {
          return false
        } else {
          return true
        }
      }
      if (currentTier < Tier.BUSINESS_PLUS) return false
      break
    case TierFeatures.MEMBERCOLOR:
      if (group) {
        if (
          currentTier < featureToMinimumTierNeeded(TierFeatures.MEMBERCOLOR) &&
          groupHasMembersWithColor(group)
        ) {
          return false
        } else {
          return true
        }
      }
      if (currentTier < featureToMinimumTierNeeded(TierFeatures.MEMBERCOLOR))
        return false
      break
    case TierFeatures.SYNC_WITH_CALENDARS:
      if (
        currentTier <
        featureToMinimumTierNeeded(TierFeatures.SYNC_WITH_CALENDARS)
      )
        return false
      break
    default:
      return true
  }

  return true
}

function allowedAdminsForTier(tier: Tier) {
  switch (tier) {
    case Tier.PRO:
      return 1
    case Tier.BUSINESS:
      return 3
    case Tier.BUSINESS_PLUS:
      return 999
    default:
      return 1
  }
}

/**
 *
 * @param feature to check
 * @param amount optional param for features such as admins
 * @returns lowest needed tier to use feature
 */
export function featureToMinimumTierNeeded(
  feature: TierFeatures,
  amount?: number
) {
  switch (feature) {
    case TierFeatures.PRIORITY_SUPPORT:
      return Tier.PRO
    case TierFeatures.ADMINS:
      if ((amount ?? 0) >= 3) {
        return Tier.BUSINESS_PLUS
      } else if ((amount ?? 0) >= 1) {
        return Tier.BUSINESS
      } else return Tier.FREE
    case TierFeatures.BUSINESSINFO:
      return Tier.BUSINESS
    case TierFeatures.CHANNEL:
      return Tier.BUSINESS_PLUS
    case TierFeatures.LANDLINE:
      return Tier.BUSINESS
    case TierFeatures.MEMBERCOLOR:
      return Tier.PRO
    case TierFeatures.PERMISSIONS:
    case TierFeatures.PERMISSIONS_CALENDAR_NAME:
    case TierFeatures.PERMISSIONS_EVENTS:
    case TierFeatures.PERMISSIONS_MEMBERS:
      return Tier.PRO
    case TierFeatures.VERIFIED:
    case TierFeatures.SYNC_WITH_CALENDARS:
      return Tier.BUSINESS_PLUS
    case TierFeatures.RSVP:
      return Tier.PRO
  }
}
function nameForPrepopulatedGroup(newGroup: GroupDoc): string {
  switch (newGroup.DeviceChangeID) {
    case 'Prepopulated_Group_work':
      return i18n.t('preGroupWork')

    case 'Prepopulated_Group_family':
      return i18n.t('preGroupFamily')

    case 'Prepopulated_Group_vacation':
      return i18n.t('preGroupVacation')
  }

  return ''
}

export function isGroupNotAvailableForEventChanges(
  group: GroupDoc,
  account: AccountDoc
): boolean {
  const activeTier = highestActiveTier(account)

  return (
    isGroupOutOfTier(activeTier, group, account) ||
    LocalStorage.get(LocalStorageKey.USER_ID)?.length === 0 ||
    !isUserActiveInGroup(
      group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    ) ||
    !isUserCanOperateEvents(
      group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )
  )
}

/** function returns first group id (preferebly default one)) where user can add events */
export async function isUserHaveGroupToAddEvents(): Promise<
  string | undefined
> {
  const account = await Account()
  const defaultGroup = await localGroupById(
    defaultGroupId(await UserSettings()) ?? ''
  )

  if (defaultGroup) return defaultGroup._id

  return allGroups().then((groups) => {
    for (const group of groups) {
      if (!isGroupNotAvailableForEventChanges(group, account)) return group._id
    }
    return undefined
  })
}
