import { Q } from '@nozbe/watermelondb'
import { of, switchMap } from 'rxjs'
import {
  AccountDoc,
  DocType,
  EventDoc,
  GroupDoc,
  MasterLabelDoc,
  ParticipantDataDoc,
  ProfileDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { getArchivedGroups } from 'utils/api/userSettings'

import Database from './Database'
import { ParticipantsAll, ParticipantsForGroup } from './Queries/GroupQueries'
import { defaultGroupId } from 'utils/groups'

class ObserveService {
  public Groups(): Model<GroupDoc>[] {
    return Database.getCollection(CollectionType.GROUP)
      .query(Q.sortBy('_sortOrder', Q.desc))
      .observeWithColumns([
        '_sortOrder',
        'LastUpdate',
        '_message',
        'Name',
        'SyncProblem'
      ]) as unknown as Model<GroupDoc>[]
  }

  public TopGroups(): Model<GroupDoc>[] {
    return Database.getCollection(CollectionType.GROUP)
      .query(Q.sortBy('_sortOrder', Q.desc), Q.take(3))
      .observe() as unknown as Model<GroupDoc>[]
  }

  public GroupByID(id: string): Model<GroupDoc> {
    return (id
      ? Database.getCollection(CollectionType.GROUP).findAndObserve(id)
      : of(null)) as unknown as Model<GroupDoc>
  }

  public GroupByServerID(id: string): Model<GroupDoc> {
    return (id
      ? (Database.getCollection(CollectionType.GROUP)
          .query(Q.where('_id', id))
          .observe()
          .pipe(
            switchMap((groups) => {
              return groups[0] ? groups[0].observe() : of(null)
            })
          ) as unknown as Model<GroupDoc>)
      : of(null)) as unknown as Model<GroupDoc>
  }

  public GroupByServerIDRestricted(
    id?: string | undefined | null
  ): Model<GroupDoc> {
    return (id
      ? (Database.getCollection(CollectionType.GROUP)
          .query(Q.where('_id', id))
          .observeWithColumns(['Name'])
          .pipe(
            switchMap((groups) => {
              return groups[0] ? groups[0].observe() : of(null)
            })
          ) as unknown as Model<GroupDoc>)
      : of(null)) as unknown as Model<GroupDoc>
  }

  public Group(group: Model<GroupDoc>): Model<GroupDoc> {
    return group.observe() as unknown as Model<GroupDoc>
  }

  public FirstGroup(userSettings?: UserSettingsDoc): Model<GroupDoc> {
    const first = Database.getCollection(CollectionType.GROUP)
      .query(
        Q.sortBy('_sortOrder', Q.desc),
        Q.where('_id', Q.like(defaultGroupId(userSettings) ?? ''))
      )
      .observe()
      .pipe(
        switchMap((groups) => {
          return groups[0] ? groups[0].observe() : of(null)
        })
      ) as unknown as Model<GroupDoc>
    return first
  }

  public GroupLastEvent(group?: Model<GroupDoc> | GroupDoc): Model<EventDoc> {
    const query: Q.Clause[] = []

    return Database.getCollection(CollectionType.EVENT)
      .query(
        Q.where('GroupID', group?._id ?? ''),
        Q.sortBy('LastUpdate', Q.desc)
      )
      .observeWithColumns([
        'Text',
        'StartDate',
        'EndDate',
        'AllDay',
        '_id',
        'Recurrence',
        '_message',
        'GroupID'
      ])
      .pipe(
        switchMap((event) => (event[0] ? event[0]?.observe() : of(null)))
      ) as unknown as Model<EventDoc>
  }

  public UserSettings(): Model<UserSettingsDoc> {
    return Database.getCollection(CollectionType.USER_SETTINGS)
      .query()
      .observe()
      .pipe(
        switchMap((userSettings) =>
          userSettings[0] ? userSettings[0].observe() : of(null)
        )
      ) as unknown as Model<UserSettingsDoc>
  }

  public Events(
    userSettings?: Model<UserSettingsDoc>,
    type?: DocType,
    visibleFromUnix?: number,
    visibleToUnix?: number
  ): Model<EventDoc>[] {
    const archivedGroups = userSettings ? getArchivedGroups(userSettings) : []
    const query: Q.Clause[] = [Q.where('GroupID', Q.notIn(archivedGroups))]

    let eventsQuery = [...query, Q.where('Status', Q.notIn(['3', '4']))]

    if (type) {
      eventsQuery.push(Q.where('Type', Q.like(type)))
    }

    return Database.getCollection(CollectionType.EVENT)
      .query(...eventsQuery)
      .observeWithColumns([
        'Text',
        'StartDate',
        'Status',
        'Type',
        'TaskType',
        'EndDate',
        'AllDay',
        'Color',
        'Priority',
        '_id',
        'Note',
        'Recurrence',
        'ParticipantsStatus',
        'GroupID'
      ]) as unknown as Model<EventDoc>[]
  }

  public ItemByID<T>(id: string, type: CollectionType): Model<T> {
    return (id
      ? Database.getCollection(type).findAndObserve(id)
      : of(null)) as unknown as Model<T>
  }

  public Profile(): Model<ProfileDoc> {
    return Database.getCollection(CollectionType.PROFILE)
      .query()
      .observe()
      .pipe(
        switchMap((profiles) =>
          profiles[0] ? profiles[0].observe() : of(null)
        )
      ) as unknown as Model<ProfileDoc>
  }

  public MasterLabel(): Model<MasterLabelDoc> {
    return Database.getCollection(CollectionType.MASTER_LABEL)
      .query()
      .observe()
      .pipe(
        switchMap((mlabel) => (mlabel[0] ? mlabel[0].observe() : of(null)))
      ) as unknown as Model<MasterLabelDoc>
  }

  public Account(): Model<AccountDoc> {
    return Database.getCollection(CollectionType.ACCOUNT)
      .query()
      .observe()
      .pipe(
        switchMap((accounts) => {
          return accounts[0] ? accounts[0].observe() : of(null)
        })
      ) as unknown as Model<AccountDoc>
  }

  public ParticipantsForIds(participantIds: string[]) {
    return ParticipantsForGroup(
      participantIds
    ).observe() as unknown as Model<ParticipantDataDoc>[]
  }

  public Participants() {
    return ParticipantsAll().observe() as unknown as Model<ParticipantDataDoc>[]
  }
}

export default new ObserveService()
