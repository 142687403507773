import { CloseIcon, PhotoIcon } from 'assets/icons'
import InviteLinkBadge from '../../badges/InviteLinkBadge'
import Button from '../../../components/buttons/Button'
import Switch from '../../../components/form/Switch'
import PopupSection from '../../../components/popup/PopupSection'
import PopupSectionRow from '../../../components/popup/PopupSectionRow'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { addGroup } from '../../../services/api/group'
import { Model } from '../../../types/model'
import { useFilePicker } from 'use-file-picker'
import ColorsPopup from '../ColorsPopup'
import * as Styled from '../styled'
import * as HeaderStyled from '../../sidebar/styled'
import AddGroupProvider, { useAddGroup } from './AddGroupProvider'
import { ErrorLabel } from 'components/labels/styled'
import { GENERIC_ERROR } from 'utils/strings'
import { LoadingSpinner } from 'components/containers/styled'
import { useCalendar } from 'components/providers/CalendarProvider'
import { GroupDoc } from 'types/api/changes'
import { Box, Grid } from '@mui/material'
import {
  defaultGroupImage,
  featureToMinimumTierNeeded,
  TierFeatures
} from 'utils/api/groups'
import Analytics, { EventProperties, Events } from 'analytics/Analytics'
import { AvatarBadge } from 'components/calendar/styled'

import { t } from 'i18next'
import { capitalize } from 'lodash'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import { compressBase64 } from 'utils/file'
import Logger from 'services/Logger'

interface ContentProps {
  saving: boolean
  setSaving: (b: boolean) => void
  onClose: () => void
  group: Model<GroupDoc> | undefined
  open: boolean
  setOpen: (b: boolean) => void
}

interface AddGroupPopupProps extends ContentProps {
  children: ReactNode
  group: Model<GroupDoc> | undefined
  onClickAwayAction?: () => void
}

function Content(props: ContentProps) {
  const [error, setError] = useState<string>('')
  const addGroupProvider = useAddGroup()
  const color = addGroupProvider.color
  const maxLengthForGroupName = 25
  const onChannelChange = (event: ChangeEvent<HTMLInputElement>) => {
    addGroupProvider.setIsCalendarChannel(event.target.checked)
  }

  const onGroupTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('')
    addGroupProvider.setTitle(event.target.value)
  }

  const toggleCalendarChannel = () => {
    addGroupProvider.setIsCalendarChannel(!addGroupProvider.isCalendarChannel)
  }

  useEffect(() => {
    if (!props.open) {
      //reset form
      addGroupProvider.setTitle('')
      addGroupProvider.setIsCalendarChannel(false)
      addGroupProvider.setPhoto('')
      addGroupProvider.setLink('')
    }
  }, [props.open])

  const [showColorPopup, setShowColorPopup] = useState(false)

  const showColors = () => {
    setShowColorPopup(true)
  }

  return (
    <div style={{ pointerEvents: props.saving ? 'none' : 'all' }}>
      <PopupSection>
        <AddGroupHeader {...props} onError={setError} />
        <div style={{ pointerEvents: props.group?._id ? 'none' : 'all' }}>
          <Styled.AddGroupInputContainer>
            {props.group && props.group._id.length > 0 ? (
              <Box>
                <AvatarBadge
                  sx={{ margin: 'unset' }}
                  name=""
                  size="sm"
                  asGroup
                  img={props.group.Photo}
                  emptyPic={defaultGroupImage(props.group)}
                />
              </Box>
            ) : (
              <AddGroupPhotoButton />
            )}

            <Styled.AddGroupInput
              shouldNotFocus={props.group?._id.length != 0}
              maxLength={maxLengthForGroupName}
              value={addGroupProvider.title}
              onChange={onGroupTitleChange}
              placeholder={`${t('newSharedCalendar')}`}
              variant="popup"
              id="group-title-input"
            />
            <Styled.GroupPopupInputCount>{`${
              maxLengthForGroupName - addGroupProvider.title.length
            }`}</Styled.GroupPopupInputCount>
          </Styled.AddGroupInputContainer>
        </div>
      </PopupSection>
      <PopupSection pointerEvents={props.group?._id ? 'none' : 'all'}>
        <PopupSectionRow
          actionId="group-color-button"
          color={color}
          title={capitalize(`${t('color')}`)}
          action={showColors}
          value={
            <ColorsPopup
              open={showColorPopup}
              setOpen={setShowColorPopup}
              color={addGroupProvider.color}
              setColor={addGroupProvider.setColor}
            />
          }
        />
        <PopupSectionRow
          title={t('setAsCalendarChannel')}
          tierFeature={TierFeatures.CHANNEL}
          neededTier={featureToMinimumTierNeeded(TierFeatures.CHANNEL)}
          value={
            <Switch
              checked={addGroupProvider.isCalendarChannel}
              onChange={onChannelChange}
            />
          }
          action={toggleCalendarChannel}
        />
      </PopupSection>
      <PopupSection>
        <PopupSectionRow title={t('inviteMembers')} value="" />
        <PopupSectionRow
          onlyTitle
          title={<InviteLinkBadge link={addGroupProvider.link} />}
        />
        {error && <ErrorLabel>{error}</ErrorLabel>}
      </PopupSection>
    </div>
  )
}

interface AddGroupHeaderProps extends ContentProps {
  onError: (error: string) => void
}

function AddGroupHeader(props: AddGroupHeaderProps) {
  const addGroupProvider = useAddGroup()
  const calendar = useCalendar()

  async function handleGroupSave() {
    if (props.group != undefined && !props.saving) {
      props.setSaving(true)
      try {
        const group = await addGroup(props.group, addGroupProvider)
        calendar.onGroupServer(group._id ?? '')
        props.setSaving(false)
        addGroupProvider.setIsCalendarChannel(false)
        Analytics.getInstance().sendEvent(Events.ADDED_GROUP, {
          [EventProperties.GROUP_NAME]: group.Name,
          [EventProperties.NUM_OF_PARTICIPANTS]: Object.keys(group.Participants)
            .length,
          [EventProperties.ALLOW_META_DATA]:
            group.GroupSettings.IsAllParticipantsCanEditGroupMetadata ?? '0',
          [EventProperties.ALLOW_ADD_EDIT_EVENT]:
            group.GroupSettings.IsAllParticipantsCanAddItems ?? '0',
          [EventProperties.ALLOW_ADD_PARTICIPANTS]:
            group.GroupSettings.IsAllParticipantsCanAddParticipants ?? '0',
          //TODO: add another one method once supported
          [EventProperties.METHOD_OF_SHARING]: EventProperties.METHOD_LINK
        })
      } catch (e) {
        props.setSaving(false)
        props.onError(GENERIC_ERROR)
      }
    }
  }

  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' && addGroupProvider.title) {
        handleGroupSave()
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [addGroupProvider.title])

  const closeAddGroup = () => {
    addGroupProvider.setTitle('')
    addGroupProvider.setLink('')
    addGroupProvider.setIsCalendarChannel(false)
    props.onClose()
  }

  return (
    <Grid
      item
      container
      display={'flex'}
      flexDirection={'row'}
      wrap={'nowrap'}
      justifyContent={'space-between'}
    >
      <Styled.PopupCloseButton
        id="add-group-close-button"
        size="sm"
        onClick={closeAddGroup}
      >
        <CloseIcon />
      </Styled.PopupCloseButton>
      {props.group?._id ? (
        <></>
      ) : (
        <>
          <div style={{ opacity: props.saving ? 0 : 1 }}>
            <Button
              id="add-group-save-button"
              disabled={addGroupProvider.title.length === 0}
              size="xs"
              onClick={handleGroupSave}
            >
              {t('save')}
            </Button>
          </div>
          <LoadingSpinner
            loading={props.saving ? 1 : 0}
            style={{
              pointerEvents: props.saving ? 'all' : 'none',
              right: '40px',
              top: '15px'
            }}
          />
        </>
      )}
    </Grid>
  )
}

function AddGroupPhotoButton() {
  const addGroupProvider = useAddGroup()
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false
  })
  const handleOpenFile = () => {
    openFileSelector()
  }
  useEffect(() => {
    if (filesContent.length > 0)
      compressBase64(filesContent[0].content)
        .then((data) => {
          // returns an array of compressed images
          addGroupProvider.setPhoto(data)
        })
        .catch((e) =>
          Logger.red('Error while compressing image', e, 'AddGroupPopup')
        )
  }, [filesContent])

  return (
    <GroupcalTooltip title={t('addCalendarPhoto')}>
      <Styled.AddGroupPhoto>
        {filesContent.length > 0 ? (
          <span onClick={handleOpenFile}>
            <HeaderStyled.HeaderAvatar name="" img={filesContent[0].content} />
          </span>
        ) : (
          <PhotoIcon onClick={handleOpenFile}></PhotoIcon>
        )}
      </Styled.AddGroupPhoto>
    </GroupcalTooltip>
  )
}

export default function AddGroupPopup(props: AddGroupPopupProps) {
  const { children, ...otherProps } = props

  useEffect(() => {
    const keyEnter = async (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !props.saving) {
        props.onClose()
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [props.open, props.saving])

  return (
    <AddGroupProvider>
      <Styled.AddGroupPopup
        placement="right-start"
        open={props.open}
        setOpen={props.setOpen}
        onClickAwayAction={props.onClickAwayAction}
        content={<Content {...otherProps} />}
      >
        {children}
      </Styled.AddGroupPopup>
    </AddGroupProvider>
  )
}
