import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import Logger from 'services/Logger'
import { isGroupcalApp } from 'utils/appType'

export const firebaseConfig = isGroupcalApp()
  ? {
      apiKey: 'AIzaSyAISdpWw4E0q5xuVdnSWYMVhFWeTtOjIcU',
      authDomain: 'groupcal-9fea1.firebaseapp.com',
      databaseURL: 'https://groupcal-9fea1.firebaseio.com',
      projectId: 'groupcal-9fea1',
      storageBucket: 'groupcal-9fea1.appspot.com',
      messagingSenderId: '360266039864',
      appId: '1:360266039864:web:17879ac885f321a7809ccd'
    }
  : {
      apiKey: 'AIzaSyDYM7phLJoKGdw_CIKY0gVxINVZJMcyfjw',
      authDomain: 'vocal-circle-107021.firebaseapp.com',
      databaseURL: 'https://vocal-circle-107021.firebaseio.com',
      projectId: 'vocal-circle-107021',
      storageBucket: 'vocal-circle-107021.appspot.com',
      messagingSenderId: '641652799786',
      appId: '1:641652799786:web:0cf60545aa6aa365ad4653',
      measurementId: 'G-EHQEF2QT1V'
    }

Logger.pink('Firebase:Config', firebaseConfig)
Logger.pink('Firebase:ApiKey', process.env.REACT_APP_FIREBASE_API_KEY)

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
