import { useEffect, useMemo, useState } from 'react'
import LocalStorage from '../../services/LocalStorage'
import {
  EventDoc,
  GroupDoc,
  GroupParticipant,
  GroupParticipantStatus,
  GroupParticipantType,
  ParticipantDataDoc,
  ProfileDoc
} from '../../types/api/changes'
import { LocalStorageKey } from '../../types/services/localStorage'
import * as Styled from './styled'
import withObservables from '@nozbe/with-observables'
import ObserveService from '../../services/db/Observe'
import { Model } from '../../types/model'
import ParticipantsActionsPopup from '../popups/groupInfo/participantActions/ParticipantsActionPopup'
import { Box, ClickAwayListener } from '@mui/material'
import { GroupCardAvatarContainer } from 'components/cards/styled'
import Avatar from 'components/badges/Avatar'
import { getRandomColor } from 'utils/colors'
import { useProgress } from 'components/providers/ProgressProvider'
import { CaretDownIcon } from 'assets/icons'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { t } from 'i18next'
import {
  ParticipantsForGroup,
  localGroupById
} from 'services/db/Queries/GroupQueries'
import { stat } from 'fs'

export const MEMBERS_THRESHOLD = 3

export function MembersList(props: MemberListProps) {
  const [showExpander, setShowExpander] = useState(true)
  const [participants, setParticipants] = useState<
    Record<string, GroupParticipant>
  >(props.group?.Participants ?? props.participants ?? {})
  const [membersGroup, setMembersGroup] = useState<
    Model<GroupDoc> | undefined | GroupDoc
  >(props.group)

  const [participantsData, setParticipantsData] = useState(
    props.localParticipants ?? []
  )
  const participantsAmount = Object.values(participants).filter(
    (participant) => participant.Status === GroupParticipantStatus.ACTIVE
  ).length

  const pendingParticipantsAmount = Object.keys(
    props.group?.PendingParticipants ?? {}
  ).length

  const overallAmount = participantsAmount + pendingParticipantsAmount

  let pendingSlice = undefined

  if (participantsAmount < MEMBERS_THRESHOLD && showExpander) {
    pendingSlice = MEMBERS_THRESHOLD - participantsAmount
  }

  const showAllMembers = () => {
    setShowExpander(false)
  }
  const participantsToDraw = participantsData
    .sort((participant) =>
      participant.AccountID === LocalStorage.get(LocalStorageKey.USER_ID)
        ? -1
        : 1
    )
    .slice(
      0,
      showExpander
        ? participantsAmount > MEMBERS_THRESHOLD // if we have participants more than threshold, we show threshold
          ? MEMBERS_THRESHOLD
          : undefined // otherwise we show all participants
        : undefined // in case no expander we also show all participants
    )

  useEffect(() => {
    if (props.groupId)
      localGroupById(props.groupId).then((group) => {
        setParticipants(group?.Participants ?? {})
        setMembersGroup(group)
        ParticipantsForGroup(Object.keys(group?.Participants ?? {}))
          .fetch()
          .then((participants) => {
            setParticipantsData(participants)
          })
        return group
      })
  }, [props.groupId])

  return (
    <Styled.MembersList>
      {participantsToDraw.map((participant) => {
        return (
          <div key={participant.AccountID} style={{ marginRight: 4 }}>
            <MemberListRow
              profile={props.profile}
              participant={participant}
              group={membersGroup}
              event={props.event}
              setLoading={props.setLoading}
              withoutActions={props.withoutActions}
            />
          </div>
        )
      })}
      <Box marginTop={2} />
      {!showExpander || participantsAmount < MEMBERS_THRESHOLD ? (
        Object.keys(props.group?.PendingParticipants ?? {})
          .slice(0, pendingSlice)
          .map((phoneNumber) => {
            return (
              <div key={phoneNumber} style={{ marginRight: 4 }}>
                <PendingMember phoneNumber={phoneNumber} />
              </div>
            )
          })
      ) : (
        <></>
      )}
      {overallAmount > MEMBERS_THRESHOLD && showExpander ? (
        <div onClick={showAllMembers} style={{ cursor: 'pointer' }}>
          <Styled.MemberListItem style={{ marginBottom: 16 }}>
            <Styled.MembersListNameContainer columnGap={1} marginLeft={12}>
              <span style={{ marginLeft: 4 }}>
                {t('membersAmount', {
                  members: overallAmount - MEMBERS_THRESHOLD
                })}
              </span>
              <CaretDownIcon />
            </Styled.MembersListNameContainer>
          </Styled.MemberListItem>
        </div>
      ) : (
        <></>
      )}
    </Styled.MembersList>
  )
}

interface PendingMemberProps {
  phoneNumber: string
}

function PendingMember(props: PendingMemberProps) {
  const color = useMemo(
    () => getRandomColor(Number(props.phoneNumber)),
    [Number(props.phoneNumber)]
  )
  return (
    <Styled.MemberListItem key={props.phoneNumber}>
      <Styled.MembersListNameContainer>
        <GroupCardAvatarContainer color={'transparent'}>
          <Avatar
            img={''}
            size="sm"
            color={color}
            name={props.phoneNumber.substring(1, 2)}
          />
        </GroupCardAvatarContainer>
        <PopupSectionRow
          title={<span style={{ marginLeft: 4 }}>{props.phoneNumber}</span>}
          onlyTitle
        />
      </Styled.MembersListNameContainer>
      <span data-content="pending">{t('invitedToGroupcal')}</span>
    </Styled.MemberListItem>
  )
}

interface MemberListRowProps {
  event?: Model<EventDoc> | EventDoc
  participant: Model<ParticipantDataDoc>
  setLoading: (b: boolean) => void
  group?: Model<GroupDoc> | GroupDoc
  withoutActions: boolean
  profile?: Model<ProfileDoc>
}

function MemberListRow(props: MemberListRowProps) {
  const progress = useProgress()
  const userName =
    props.participant.AccountID ==
    (LocalStorage.get(LocalStorageKey.USER_ID) ?? '')
      ? t('you')
      : props.participant.FullName != 'null'
      ? props.participant.FullName
      : props.participant.PhoneNumber ?? t('tapToUpdate')

  const [actionsOpen, setActionsOpen] = useState(false)
  const currentUserId = LocalStorage.get(LocalStorageKey.USER_ID)!
  const canMakeActions =
    (props.group?.Participants[currentUserId]?.Type ===
      GroupParticipantType.ADMIN ||
      currentUserId === props.group?.OwnerID) &&
    !props.withoutActions
  const onMemberClick = () => {
    /**
     * exclude clicks on yourself
     * and in case user is an admin
     */
    if (canMakeActions) setActionsOpen(true)
  }

  const color = useMemo(
    () => getRandomColor(Number(props.participant.PhoneNumber)),
    [Number(props.participant.PhoneNumber)]
  )

  const participantColor =
    props.group?.Participants[props.participant.AccountID]?.MemberColor

  let statusText = t('pending')
  let statusColor = '#808080'

  if (props.event) {
    const deliveryStatus =
      props.event.ParticipantsStatus?.[props.participant.AccountID]
        ?.ParticipantDeliveryStatus

    statusText = deliveryStatus === '9' ? t('received') : t('sent')

    if (props.event.RequestConfirmation === '1') {
      const confirmStatus =
        props.event.ParticipantsStatus?.[props.participant.AccountID]
          ?.ParticipantConfirmationStatus

      switch (confirmStatus) {
        case '2':
          statusText = t('accepeted')
          statusColor = '#008000'
          break
        case '4':
          statusText = t('maybe')
          statusColor = '#D4C91D'
          break
        case '3':
          statusText = t('declined')
          statusColor = '#FF0000'
          break
        case '1':
          statusColor = '#808080'
          break
      }
    }
  }

  if (!props.group?.Participants[props.participant.AccountID]) return <></>

  if (!props.event || props.event?._id?.length === 0) statusText = ''

  return (
    <ClickAwayListener
      onClickAway={() => {
        setActionsOpen(false)
      }}
    >
      <span
        onClick={onMemberClick}
        style={{ cursor: canMakeActions ? 'pointer' : 'default' }}
      >
        <ParticipantsActionsPopup
          setError={progress.setError}
          group={props.group}
          setLoading={props.setLoading}
          isOpen={actionsOpen}
          setIsOpen={setActionsOpen}
          participant={props.group?.Participants[props.participant.AccountID]}
          participantId={props.participant.AccountID}
        >
          {props.group?.Participants[props.participant.AccountID].Status ===
          GroupParticipantStatus.ACTIVE ? (
            <Styled.MemberListItem key={props.participant.AccountID}>
              <Styled.MembersListNameContainer>
                <GroupCardAvatarContainer
                  borderWidth="2px"
                  color={participantColor ? participantColor : 'transparent'}
                >
                  <Avatar
                    img={
                      props.participant.AccountID ===
                      LocalStorage.get(LocalStorageKey.USER_ID)
                        ? props.profile?.ProfilePicture
                        : props.participant.PhotoURL
                    }
                    size="sm"
                    color={color}
                    name={userName}
                  />
                </GroupCardAvatarContainer>
                <Box display={'flex'} flexDirection={'column'}>
                  <PopupSectionRow
                    onlyTitle
                    cursor={canMakeActions ? 'pointer' : 'default'}
                    title={<span style={{ marginLeft: 4 }}>{userName}</span>}
                  />
                  {
                    <span
                      style={{
                        marginLeft: 4,
                        fontSize: 12,
                        marginRight: 4,
                        color: statusColor
                      }}
                    >
                      {`${statusText}`}
                    </span>
                  }
                </Box>
              </Styled.MembersListNameContainer>
              <span data-content="role">
                {props.participant.AccountID == props.group.OwnerID
                  ? t('owner')
                  : props.group.Participants[props.participant.AccountID]
                      ?.Type == '2'
                  ? t('admin')
                  : ''}
              </span>
            </Styled.MemberListItem>
          ) : (
            <></>
          )}
        </ParticipantsActionsPopup>
      </span>
    </ClickAwayListener>
  )
}

interface MemberListProps {
  event?: Model<EventDoc> | EventDoc
  groupId?: string
  localParticipants: Model<ParticipantDataDoc>[]
  setLoading: (b: boolean) => void
  group?: Model<GroupDoc> | GroupDoc
  withoutActions: boolean
  participants?: Record<string, GroupParticipant>
  pending?: boolean
  pendingParticipants?: Record<string, GroupParticipant>
  profile?: Model<ProfileDoc>
}

export default withObservables(['group'], (props: MemberListProps) => ({
  localParticipants: ObserveService.ParticipantsForIds(
    Object.keys(props.participants ?? props.group?.Participants ?? {})
  ),
  profile: ObserveService.Profile()
}))(MembersList)
