import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import { DocType } from 'types/api/changes'
import './styles.scss'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import SearchPopupButton from 'components/SearchPopupButton'
import { SEARCH_MODE } from 'components/popups/searchItems/SearchPopup'
import { pl } from 'date-fns/locale'

interface TitleProps {
  type: DocType
}

export default function TFMItemsTitle(props: TitleProps) {
  let title = ''
  let placeholder = ''
  const uiTypeContext = useAppUIType()
  const selectedLabel = uiTypeContext.selectedLabel
  if (props.type === DocType.NOTE) {
    title = t('allNotes')
  } else if (props.type === DocType.TASK) {
    title = t('allTasks')
  }

  if (uiTypeContext.uiType.value === AppUIType.tasks) {
    placeholder = t('searchItems', { what: t('tasks').toLowerCase() })

    if (selectedLabel) {
      placeholder = t('searchItemsIn', {
        what: t('tasks').toLowerCase(),
        where: selectedLabel.Text
      })
    }
  } else if (uiTypeContext.uiType.value === AppUIType.notes) {
    placeholder = t('searchItems', { what: t('notes').toLowerCase() })

    if (selectedLabel) {
      placeholder = t('searchItemsIn', {
        what: t('notes').toLowerCase(),
        where: selectedLabel.Text
      })
    }
  }

  return (
    <Box
      width={'100%'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <div className="screenTitle">
        {`${title}${
          uiTypeContext.selectedLabel &&
          uiTypeContext.uiType.value != AppUIType.calendars
            ? ` / ${uiTypeContext.selectedLabel.Text}`
            : ''
        }`}
      </div>
      <SearchPopupButton
        textfieldPlaceholder={placeholder}
        mode={SEARCH_MODE.OnlyEvents}
      />
    </Box>
  )
}
