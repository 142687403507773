import Compress from 'compress.js'

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export function base64RemoveMetadata(string: string): string {
  return string.split(',')[1]
}

const compress = new Compress()

const maxImageSize = 800

export async function compressBase64(base64: string) {
  // Assuming `base64` is a string containing your base64 URL
  // Assuming `compress` is an object with a `compress` method that you're using for compression
  // Assuming `maxImageSize` is defined somewhere in your code

  const fetchResponse = await fetch(base64)
  const blob = await fetchResponse.blob()

  // Convert blob to File. The name "image.jpg" is arbitrary, replace it with a meaningful name if available
  const fileName = 'image.jpg' // You might want to dynamically determine the file name based on the content or context
  const mimeType = blob.type // Get MIME type from the blob itself
  const file = new File([blob], fileName, { type: mimeType })

  const compressedData = await compress.compress([file], {
    size: 1, // the max size in MB, defaults to 2MB
    quality: base64.includes('jpeg') || base64.includes('jpg') ? 0.9 : 1, // the quality of the image, max is 1,
    maxWidth: maxImageSize, // the max width of the output image, defaults to 1920px
    maxHeight: maxImageSize, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })

  // Assuming the `compress` method returns an array of results, and each result has a `data` property containing the compressed file
  return compressedData[0].data
}

export const handleSaveToPC = (contactsData: any, fileName?: string) => {
  const fileData = JSON.stringify(contactsData)
  const blob = new Blob([fileData], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = `${fileName ?? 'filename'}.json`
  link.href = url
  link.click()
}

export function getStringSizeInKB(str: string): number {
  const blob = new Blob([str], { type: 'text/plain' })
  return blob.size / 1024
}

export function convertBytesToMB(bytes?: number): number {
  if (!bytes) return 0

  // round to 4 signs
  return Math.round((bytes / 1024 / 1024) * 10000) / 10000
}
