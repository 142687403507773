import NotesList from 'components/lists/NotesList'
import PopupSection from 'components/popup/PopupSection'

import { IEventDetails } from 'hooks/useEventDetails'
import { t } from 'i18next'
import { Controller } from 'react-hook-form'
import { DocType, EventDoc } from 'types/api/changes'
import { Model } from 'types/model'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import NoteDetailsPopup from '../noteDetails/NoteDetailsPopup'
import './styles.scss'
import FilePickerProvider from 'components/providers/FilePickProvider'
import NoteTitleInput from './NoteTitleInput'
import Logger from 'services/Logger'
import { BlockOverlayProps } from 'components/BlockOverlay'
import { Box, Grid } from '@mui/material'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import { TextWithLinks } from 'components/TextWithLinks'
import { CloseIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import { convertBytesToMB } from 'utils/file'
import { MiscText } from 'components/login/styled'
import { theme } from 'theme'

export interface NotesSectionProps extends BlockOverlayProps {
  isCurrentGroupIsGoogleCalendar: boolean
  eventHook: IEventDetails
  event: Model<EventDoc> | EventDoc
  onApproveNoteText: (s: string) => void
  openNoteDetails: boolean
  setOpenNoteDetails: (b: boolean) => void
}

export default function NotesSection(props: NotesSectionProps) {
  const {
    isCurrentGroupIsGoogleCalendar,
    eventHook,
    event,
    onApproveNoteText
  } = props

  Logger.blue('Notes:', isCurrentGroupIsGoogleCalendar)
  Logger.blue('Notes:', eventHook.getValues('Type'))
  Logger.blue('CustomURL:', eventHook.customUrl)
  Logger.blue('Attachments:', eventHook.attachements)
  return (
    <PopupSection
      title={`${
        event.Type === DocType.NOTE
          ? t('noteContent')
          : `${t(isCurrentGroupIsGoogleCalendar ? 'notes' : 'notesSubtask')}`
      }`}
      isUiBlocked={props.isUiBlocked}
      blockedOverlayClick={props.blockedOverlayClick}
      contentZIndex={3}
    >
      {eventHook.getValues('Type') === DocType.NOTE ? (
        <Controller
          name="Notes"
          control={eventHook.control}
          defaultValue={event.Notes}
          render={({ field }) =>
            field.value ? (
              <FilePickerProvider>
                <NoteDetailsPopup
                  event={event}
                  eventHook={eventHook}
                  open={props.openNoteDetails}
                  setOpen={props.setOpenNoteDetails}
                  note={field.value?.[0]}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      maxHeight: '140px',
                      overflow: 'auto'
                    }}
                    onClick={() => {
                      props.setOpenNoteDetails(true)
                    }}
                  >
                    <ReactQuill
                      className="tfm-note-preview"
                      style={{ pointerEvents: 'none' }}
                      theme="snow"
                      modules={{ toolbar: false }}
                      value={
                        field.value?.[0]?.Note ??
                        `<p>${t('addNoteContent')}</p>`
                      }
                      readOnly
                    />
                  </div>
                </NoteDetailsPopup>
              </FilePickerProvider>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <>
          {eventHook.notes ? (
            <Grid container>
              <Controller
                name="Notes"
                control={eventHook.control}
                defaultValue={event.Notes}
                render={({ field }) =>
                  field.value ? (
                    <NotesList
                      notes={field.value}
                      checkable={
                        eventHook.getValues('Type') !== DocType.REGULAR_EVENT &&
                        !props.isUiBlocked
                      }
                      disableDelete={props.isUiBlocked}
                      setNotes={eventHook.setNotes}
                    />
                  ) : (
                    <></>
                  )
                }
              />
              {eventHook.customUrl && (
                <PopupSectionRow
                  onlyTitle
                  title={<TextWithLinks text={`URL: ${eventHook.customUrl}`} />}
                  value={
                    <IconButton
                      size="xs"
                      onClick={() => {
                        if (eventHook.customUrl)
                          eventHook.setCustomUrl(undefined)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                />
              )}
              {eventHook.attachements &&
                eventHook.attachements?.map((attachment, index) => {
                  return (
                    <PopupSectionRow
                      key={index}
                      onlyTitle
                      title={
                        <Box display={'flex'} flexDirection={'column'}>
                          <TextWithLinks
                            title={attachment.FileName}
                            text={attachment.Url}
                          />
                          <MiscText
                            fontSize={'12px'}
                            theme={theme}
                          >{`${convertBytesToMB(
                            attachment.FileSize
                          )} MB`}</MiscText>
                        </Box>
                      }
                      value={
                        <IconButton
                          size="xs"
                          onClick={() => {
                            if (eventHook.attachements)
                              eventHook.setAttachments(
                                eventHook.attachements.filter(
                                  (item) => item !== attachment
                                )
                              )
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    />
                  )
                })}
            </Grid>
          ) : (
            <></>
          )}
          {props.isUiBlocked ||
          (isCurrentGroupIsGoogleCalendar &&
            eventHook.getValues('Type') === DocType.REGULAR_EVENT &&
            (eventHook.getValues('Notes')?.length ?? 0) > 0) ? (
            <></>
          ) : (
            <NoteTitleInput
              eventHook={eventHook}
              docType={eventHook.getValues('Type')}
              isCurrentGroupIsGoogleCalendar={isCurrentGroupIsGoogleCalendar}
              onApproveNoteText={onApproveNoteText}
              onAttachFile={(text, bytes) => {
                eventHook.addNote(text, bytes)
              }}
            />
          )}
        </>
      )}
    </PopupSection>
  )
}
