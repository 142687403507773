import CheckmobiApi from 'services/CheckmobiApi'
import LocalStorage from 'services/LocalStorage'
import { CheckmobiCheckPinRequest } from 'types/api/CheckmobiCheckPinRequest'
import { CheckmobiCheckPinResponse } from 'types/api/CheckmobiCheckPinResponse'
import { CheckmobiValidationRequest } from 'types/api/CheckmobiValidationRequest'
import { CheckmobiValidationResponse } from 'types/api/CheckmobiValidationResponse'
import { LocalStorageKey } from 'types/services/localStorage'

export function requestSMSFromCheckmobi(data: CheckmobiValidationRequest) {
  return CheckmobiApi.post('/validation/request', { ...data }).then(
    (response) => {
      const data: CheckmobiValidationResponse = response.data
      LocalStorage.remove(LocalStorageKey.FIREBASE_TOKEN)
      LocalStorage.set(LocalStorageKey.CHECKMOBI_TOKEN, data.id)
      return data
    }
  )
}

export function validatePin(data: CheckmobiCheckPinRequest) {
  return CheckmobiApi.post('/validation/verify', { ...data }).then(
    (response) => {
      const data: CheckmobiCheckPinResponse = response.data
      return data
    }
  )
}
