import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Spinner } from 'assets/icons'
import { LoadingContainerProps } from './LoadingContainer'

export const AppContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#EFF2F4',
  padding: theme.spacing(5),
  display: 'flex',
  flexWrap: 'nowrap',
  minWidth: '500px',
  gridTemplateColumns: '370px 1fr',
  gap: theme.spacing(5)
}))

export const LoadingOverlay = styled('div')((props: LoadingContainerProps) => ({
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: 'rgba(255, 255, 255, 0.65)',
  opacity: props.loading ? 1 : 0,
  position: 'absolute',
  zIndex: 12,
  transition: 'opacity 1s',
  pointerEvents: props.loading ? 'all' : 'none'
}))

interface LoadingSpinnerProps {
  loading?: number
}

export const LoadingSpinner = styled(Spinner)(
  ({ loading }: LoadingSpinnerProps) => ({
    position: 'absolute',
    right: '10px',
    top: '14px',
    opacity: (loading || 0) === 1 ? 1 : 0,
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  })
)
