import dayjs from 'dayjs'
import LocalStorage from 'services/LocalStorage'
import { AccountName } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import { is24meApp } from './appType'
import { getDefaultTimezone } from 'index'

export function extractToken(token: string): string {
  return token.replace('Bearer ', '')
}

export function addTokenPrefix(token: string): string {
  return `Bearer ${token}`
}

export function getAccessToken(): string | undefined {
  return LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
    ? addTokenPrefix(LocalStorage.get(LocalStorageKey.ACCESS_TOKEN) as string)
    : undefined
}

interface CreateSignUpObjectReq {
  deviceToken: string
  token?: string
  phoneNumber?: string
  email?: string
  password?: string
  fname?: string
  lname?: string
  phoneNumberType?: DocPhoneNumberType
}

export enum DocPhoneNumberType {
  MOBILE = '1',
  LANDLINE = '2'
}

interface SignUpBody {
  Name?: AccountName
  PhoneNumberType?: DocPhoneNumberType
  token?: string
  deviceId?: string
  networkId: string
  platformOs: string
  platform: string
  devicePushToken?: string
  locale: string
  timezone: string
  LastUpdate: number
  phoneNumber?: string
  email?: string
  passwd?: string
  fname?: string
  lname?: string
}

export function createSignUpObject(req: CreateSignUpObjectReq): SignUpBody {
  return {
    networkId: is24meApp()
      ? '0'
      : LocalStorage.get(LocalStorageKey.CHECKMOBI_TOKEN)
      ? '6'
      : '5',
    PhoneNumberType: is24meApp() ? undefined : req.phoneNumberType,
    platform: '3',
    platformOs: getUserAgent(),
    locale: getCurrentLocale(),
    timezone: getCurrentTimeZone(),
    LastUpdate: dayjs().unix(),
    deviceId: req.deviceToken,
    devicePushToken: 'null',
    phoneNumber: req.phoneNumber,
    token: req.token,
    email: req.email,
    passwd: req.password,
    fname: req.fname,
    lname: 'null'
  }
}

export function getUserAgent(): string {
  return navigator.userAgent
}

export function getCurrentTimeZone(): string {
  return getDefaultTimezone()
}

export function getCurrentLocale(): string {
  return navigator.language
}
