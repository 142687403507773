import { Model } from '@nozbe/watermelondb'
import { json, text } from '@nozbe/watermelondb/decorators'
import { sanitizeJson } from 'utils/watermalon'

export default class Profile extends Model {
  static table = 'Profiles'

  @text('_id') _id!: string
  @text('_rev') _rev!: string
  @text('ActiveProviders') ActiveProviders!: string
  @text('DeviceChangeID') DeviceChangeID!: string
  @text('Facebook') Facebook!: string
  @text('LastUpdate') LastUpdate!: string
  @text('Newsletters') Newsletters!: string
  @text('Notifications') Notifications!: string
  @text('ProfilePicture') ProfilePicture!: string
  @text('Type') Type!: string
  @text('UserID') UserID!: string
  @text('isDeleted') isDeleted!: string
  @json('DataProviders', sanitizeJson) DataProviders!: string
}
