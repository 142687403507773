import { Box, ClickAwayListener, Grid } from '@mui/material'
import {
  AccountDoc,
  DocType,
  EventDoc,
  EventStatus,
  MasterLabelDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'

import ObserveService from 'services/db/Observe'
import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { ComponentType, useState } from 'react'
import { isSomeDay } from 'utils/api/events'
import SelectItemWithSearchPopup, {
  SelectableItem
} from 'components/popups/SelectItemWithSearchPopup'
import { Circle } from '@mui/icons-material'
import {
  isLabelInsideMasterLabel,
  provideLabelName,
  provideTaskColor
} from 'utils/masterLabel'
import EventPopup from 'components/popups/eventInfo/EventPopup'
import { theme } from 'theme'
import { useAppUIType } from 'components/providers/AppUITypeProvider'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { CalendarRange } from 'types/components/calendar'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import i18n from 'i18n'

export interface SomedayItemProps {
  account: Model<AccountDoc>
  masterLabel: Model<MasterLabelDoc>
  userSettings: Model<UserSettingsDoc>
  events: Model<EventDoc>[]
  title?: string
}

export function SomedayItem(props: SomedayItemProps) {
  const masterLabelLabel = useAppUIType().selectedLabel
  const somedayEvents = props.events
    .filter((event) => isSomeDay(event))
    .filter((event) => {
      if (!masterLabelLabel) return true // return all items

      if (event.Label && typeof event.Label !== 'string') {
        return event.Label.some((ele) => ele.LabelText === masterLabelLabel?.ID)
      }
    })
  const [showEvents, setShowEventsList] = useState(false)
  const [showEvent, setShowEvent] = useState<string | undefined>(undefined)
  const hasLabels = somedayEvents.some(
    (event) =>
      event.Label && typeof event.Label !== 'string' && event.Label.length > 0
  )

  let biggestWidth = 0

  for (let i = 0; i < somedayEvents.length; i++) {
    const somedayEvent = somedayEvents[i]
    const label = somedayEvent.Label

    if (typeof label !== 'string' && (label?.length ?? 0) > 0) {
      const firstLabel = provideLabelName(props.masterLabel, somedayEvent)

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (context) {
        context.font = '18px Times'
        const metrics = context?.measureText(firstLabel)
        const width = metrics?.width ?? 0

        if (width > biggestWidth) biggestWidth = width
      }
    }
  }

  const somedayItem = (item: SelectableItem) => {
    const event = somedayEvents.find((event) => event._id === item.id)

    if (event) {
      const taskColor = provideTaskColor(
        props.masterLabel,
        event,
        props.account
      )
      return (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            if (showEvent === `${event.id}${event.StartDate}`) {
              setShowEvent(undefined)
              setTimeout(() => {
                LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
                LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
              }, 250)
            }
          }}
        >
          <div>
            <EventPopup
              key={event._id}
              userSettings={props.userSettings}
              account={props.account}
              event={event}
              setOpen={(open) => {
                setShowEvent(open ? `${event.id}${event.StartDate}` : undefined)
              }}
              open={showEvent === `${event.id}${event.StartDate}`}
              awayClickTriggered={false}
              setAwayClickTriggered={() => {}}
            >
              <div style={{ height: '100%', maxHeight: '40px', padding: 4 }}>
                <Grid
                  item
                  container
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowEvent(`${event.id}${event.StartDate}`)
                  }}
                  sx={{ cursor: 'pointer' }}
                  display={'flex'}
                  flexWrap={'nowrap'}
                  flexDirection={'row'}
                  gap={2}
                  width={'100%'}
                  justifyItems={'center'}
                  alignItems={'center'}
                >
                  <Circle
                    style={{
                      width: 14,
                      height: 14,
                      color: taskColor
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '14px',
                      maxWidth: hasLabels ? `${biggestWidth}px` : '0px',
                      minWidth: hasLabels ? `${biggestWidth}px` : '0px',
                      width: hasLabels ? `${biggestWidth}px` : '0px',
                      color: theme.palette.text.secondary
                    }}
                  >
                    {provideLabelName(props.masterLabel, event)}
                  </div>
                  <div
                    style={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      height: 'auto',
                      textDecoration:
                        event.Status === EventStatus.COMPLETED
                          ? 'line-through'
                          : 'none',
                      fontSize: '14px',
                      color: theme.palette.text.primary
                    }}
                  >
                    {event?.Text}
                  </div>
                </Grid>
              </div>
            </EventPopup>
          </div>
        </ClickAwayListener>
      )
    }

    return undefined
  }

  return (
    <SelectItemWithSearchPopup
      showSearch={false}
      onHide={() => {
        setTimeout(() => {
          LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
        }, 250)
      }}
      data={somedayEvents
        .sort((event1, event2) => {
          const label1 =
            typeof event1.Label === 'string'
              ? ''
              : event1.Label?.filter((label) =>
                  isLabelInsideMasterLabel(props.masterLabel, label.LabelText)
                )?.[0]?.LabelText
          const label2 =
            typeof event2.Label === 'string'
              ? ''
              : event2.Label?.filter((label) =>
                  isLabelInsideMasterLabel(props.masterLabel, label.LabelText)
                )?.[0]?.LabelText
          const len1 =
            typeof event1.Label === 'string' ? 0 : Number(label1 ?? 0)
          const len2 =
            typeof event2.Label === 'string' ? 0 : Number(label2 ?? 0)
          return len2 - len1
        })
        .map((event) => {
          const selectable: SelectableItem = {
            leftIcon: (
              <Circle
                style={{
                  color: provideTaskColor(
                    props.masterLabel,
                    event,
                    props.account
                  )
                }}
              />
            ),
            title: event.Text ?? '',
            id: event._id
          }

          return selectable
        })}
      open={showEvents}
      setOpen={setShowEventsList}
      selectedItemId={undefined}
      rowBuilder={somedayItem}
      title={props.title}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setShowEventsList(true)
          LocalStorage.set(LocalStorageKey.VISIBLE_EVENT, '1')
        }}
        sx={{
          height: '100%',
          width: '100%',
          marginRight:
            LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) ===
            CalendarRange.LISTWEEK
              ? 3
              : 0,
          marginLeft:
            LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) ===
            CalendarRange.LISTWEEK
              ? 3
              : 0,
          transform: 'translateY(-1px)',
          cursor: 'pointer'
        }}
      >
        <GroupcalTooltip
          placement="top"
          enterDelay={200}
          title={
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'0.25rem'}
              alignItems={i18n.dir() === 'rtl' ? 'end' : 'start'}
            >
              {props.title}
            </Box>
          }
        >
          {props.title}
        </GroupcalTooltip>
      </Box>
    </SelectItemWithSearchPopup>
  )
}

export default compose(
  withObservables([], (props: SomedayItemProps) => ({
    account: ObserveService.Account(),
    masterLabel: ObserveService.MasterLabel(),
    userSettings: ObserveService.UserSettings(),
    events: ObserveService.Events(props.userSettings, DocType.TASK)
  }))
)(SomedayItem as ComponentType<unknown>) as any
