import { aC } from '@fullcalendar/core/internal-common'
import Api from 'services/Api'
import { AccountDoc } from 'types/api/changes'

export async function updateAccountOnServer(acc: AccountDoc) {
  return await Api.post(`/general/account/edit/${acc._id}`, {
    _id: acc._id,
    _rev: acc._rev,
    UserID: acc.UserID,
    LastUpdate: acc.LastUpdate,
    DeviceChangeID: acc.DeviceChangeID,
    AccessToken: acc.AccessToken,
    AppleID: acc.AppleID,
    Devices: acc.Devices,
    Email: acc.Email,
    PaymentEmail: acc.PaymentEmail,
    Products: acc.Products,
    FacebookID: acc.FacebookID,
    Name: JSON.parse(JSON.stringify(acc.Name)),
    Password: acc.Password,
    PhoneNumber: acc.PhoneNumber,
    PhoneNumberType: acc.PhoneNumberType,
    TokenExpires: acc.TokenExpire,
    UserType: JSON.parse(JSON.stringify(acc.UserType)),
    ConnectedCalAccounts: acc.ConnectedCalAccounts
      ? JSON.parse(JSON.stringify(acc.ConnectedCalAccounts))
      : undefined,
    groupcalActivatedDate: acc.groupcalActivatedDate,
    isDeleted: JSON.parse(JSON.stringify(acc.isDeleted)),
    Type: 'Account'
  })
}

export async function forgotPassword(email: string) {
  return await Api.post('/general/forgotpass', {
    email: email
  })
}

export async function deleteAccount(accountId: string) {
  return await Api.post(`/general/account/deactivation/${accountId}`, {})
}
