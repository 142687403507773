import GenericDialog from 'components/dialogs/NoInternetDialog'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { AccountDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { isUserOutOfTierBasedOnLandline } from 'utils/tier'
import withObservables from '@nozbe/with-observables'
import ObserveService from 'services/db/Observe'
import { OutOfTierDialog } from 'components/tier/OutOfTierDialog'
import { DocPhoneNumberType } from 'utils/auth'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

interface LandlineCheckProviderProps {
  children: ReactNode
  account: Model<AccountDoc>
}
function LandlineCheckProvider(props: LandlineCheckProviderProps) {
  const [showOutOfTierBasedOnLandline, onShowOutOfTierBasedOnLandline] =
    useState(isUserOutOfTierBasedOnLandline(props.account))

  const [context, setContext] = useState<LandlineCheckContextProps>({
    outOfTierBasedOnLandline: showOutOfTierBasedOnLandline
  })

  useEffect(() => {
    onShowOutOfTierBasedOnLandline(
      isUserOutOfTierBasedOnLandline(props.account) &&
        props.account.PhoneNumberType === DocPhoneNumberType.LANDLINE &&
        LocalStorage.get(LocalStorageKey.PHONE_NUMBER_TYPE) ===
          DocPhoneNumberType.LANDLINE
    )
  }, [props.account])

  return (
    <LandlineCheckContext.Provider value={context}>
      {props.children}
      {showOutOfTierBasedOnLandline && (
        <OutOfTierDialog
          setOpen={onShowOutOfTierBasedOnLandline}
          opened={showOutOfTierBasedOnLandline}
          account={props.account}
        />
      )}
    </LandlineCheckContext.Provider>
  )
}

interface LandlineCheckContextProps {
  outOfTierBasedOnLandline: boolean
}

const LandlineCheckContext = createContext<LandlineCheckContextProps>({
  outOfTierBasedOnLandline: false
})

const LandlineCheckProviderrWithAccount = withObservables([], () => ({
  account: ObserveService.Account()
}))(LandlineCheckProvider)

function useLandlineCheck(): LandlineCheckContextProps {
  return useContext(LandlineCheckContext)
}

export {
  LandlineCheckProviderrWithAccount as LandlineCheckProvider,
  useLandlineCheck
}
