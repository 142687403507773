import { t } from 'i18next'
import ConfirmationDialog from './ConfirmationDialog'
import { APP_COLOR } from 'utils/colors'

import { RECUR_SAVE_TYPE } from 'components/popups/eventInfo/EventPopup'
import { ChangeEvent, useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { DocType, EventDoc } from 'types/api/changes'
import { localEventById } from 'services/db/Queries/Event'

interface RecurrentActionDialogProps {
  open: boolean
  handleClose: () => void
  handleSaveRecurringEvent: (type: RECUR_SAVE_TYPE) => void
  default?: RECUR_SAVE_TYPE
  disableOthers?: boolean
  item?: EventDoc
  handleLeftButton?: () => void
}

export default function RecurrentActionDialog(
  props: RecurrentActionDialogProps
) {
  const [recurSave, setRecurSave] = useState(
    props.default ?? RECUR_SAVE_TYPE.THIS
  )

  function handleRight() {
    props.handleSaveRecurringEvent(recurSave)
  }

  let title = t('editRecurringEvent')

  if (props.item?.Type === DocType.TASK) {
    title = t('editRecurringItem', { itemName: t('task_one').toLowerCase() })
  } else if (props.item?.Type === DocType.NOTE) {
    title = t('editRecurringItem', { itemName: t('note_one').toLowerCase() })
  }

  return (
    <ConfirmationDialog
      title={title}
      content={
        <SaveRecurrentEventDialogContent
          item={props.item}
          disableOthers={props.disableOthers}
          type={recurSave}
          setType={setRecurSave}
        />
      }
      rightButton={`${t('ok')}`}
      leftButton={`${t('cancel')}`}
      leftButtonColor={'black'}
      rightButtonColor={APP_COLOR}
      handleRightButton={handleRight}
      open={props.open}
      handleLeftButton={() => {
        if (props.handleLeftButton) props.handleLeftButton()

        props.handleClose()
      }}
      handleClose={props.handleClose}
    />
  )
}

interface SaveRecurrentEventDialogContentProps {
  disableOthers?: boolean
  type: RECUR_SAVE_TYPE
  setType: (type: RECUR_SAVE_TYPE) => void
  item?: EventDoc
}

export function SaveRecurrentEventDialogContent(
  props: SaveRecurrentEventDialogContentProps
) {
  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setType(event.target.value as RECUR_SAVE_TYPE)
  }

  let thisTitle = t('editRecurringItemThis', {
    itemName: t('event_one').toLowerCase()
  })
  let thisAndFollowing = t('editRecurringItemThisAndFollowing', {
    itemName: t('event_one').toLowerCase()
  })
  let allTitle = t('editRecurringItemThisAll', {
    itemName: t('event_other').toLowerCase()
  })

  if (props.item?.Type === DocType.TASK) {
    thisTitle = t('editRecurringItemThis', {
      itemName: t('task_one').toLowerCase()
    })
    thisAndFollowing = t('editRecurringItemThisAndFollowing', {
      itemName: t('task_one').toLowerCase()
    })
    allTitle = t('editRecurringItemThisAll', {
      itemName: t('task_other').toLowerCase()
    })
  } else if (props.item?.Type === DocType.NOTE) {
    thisTitle = t('editRecurringItemThis', {
      itemName: t('note_one').toLowerCase()
    })
    thisAndFollowing = t('editRecurringItemThisAndFollowing', {
      itemName: t('note_one').toLowerCase()
    })
    allTitle = t('editRecurringItemThisAll', {
      itemName: t('note_other').toLowerCase()
    })
  }

  const [status, onItemStatus] = useState(props.item?.Status ?? '1')

  useEffect(() => {
    localEventById(props.item?._id ?? '').then((event) => {
      if (event) onItemStatus(event?.Status ?? '1')
    })
  }, [])

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={props.type}
        defaultValue={props.type}
        onChange={handleTypeChange}
        name="radio-buttons-group"
      >
        {(props.item?.Type === DocType.TASK ||
          props.item?.Type === DocType.NOTE) &&
        status !== '1' ? (
          <></>
        ) : (
          <FormControlLabel
            id="recurrence-this"
            disabled={props.disableOthers}
            value={RECUR_SAVE_TYPE.THIS}
            control={<Radio />}
            label={thisTitle}
          />
        )}
        {props.item?.Type === DocType.TASK ||
        props.item?.Type === DocType.NOTE ? (
          <></>
        ) : (
          <FormControlLabel
            id="recurrence-this-and-following"
            disabled={props.disableOthers}
            value={RECUR_SAVE_TYPE.THIS_AND_FOLLOWING}
            control={<Radio />}
            label={thisAndFollowing}
          />
        )}
        <FormControlLabel
          id="recurrence-all"
          value={RECUR_SAVE_TYPE.ALL}
          control={<Radio />}
          label={allTitle}
        />
      </RadioGroup>
    </FormControl>
  )
}
