import { Box, Grid } from '@mui/material'
import { AlarmIcon, Approve, CloseIcon, LocationIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import InputWithApproveButton from 'components/inputs/InputWithApproveButton'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { firebaseConfig } from 'firebase-config'
import GoogleMapReact from 'google-map-react'
import { useTranslation } from 'react-i18next'
import { theme } from 'theme'
import { EventLocation } from 'types/api/changes'

import Geocode from 'react-geocode'
import { TextWithLinks } from 'components/TextWithLinks'
import { IEventDetails } from 'hooks/useEventDetails'
import { ChangeEvent } from 'react'

import './styles.scss'
import { calculateZoomLevel, radiusInMeters } from 'utils/location_reminder'

interface EventLocationRowProps {
  disallowEdit?: boolean
  eventHook?: IEventDetails
  setLocation: (loc: EventLocation | undefined) => void
  eventLocation: EventLocation | undefined
}

export function EventLocationRow(props: EventLocationRowProps) {
  Geocode.setApiKey(firebaseConfig.apiKey)
  const hasLocation =
    typeof props.eventLocation !== 'string' &&
    props.eventLocation != undefined &&
    Object.keys(props.eventLocation).length > 0

  const removeLocation = () => {
    props.setLocation(undefined)
  }

  const { t } = useTranslation()

  const onEventLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.eventHook?.setLocationPendingText(event.target.value)
  }

  const locationReminder = props.eventHook?.locationReminder?.at(0)

  return hasLocation ? (
    <div>
      <PopupSectionRow
        icon={<LocationIcon />}
        cursor={props.eventLocation?.Address ? 'default' : 'pointer'}
        title={
          <p className="multiline-ellipsis">
            {props.eventLocation?.Address ? (
              <TextWithLinks text={props.eventLocation?.Address} />
            ) : (
              t('addLocation')
            )}
          </p>
        }
        value={
          props.disallowEdit ? (
            <></>
          ) : (
            <IconButton size="xs" onClick={removeLocation}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      {locationReminder ? (
        <PopupSectionRow
          icon={<AlarmIcon />}
          cursor="default"
          onlyTitle
          overflow="hidden"
          title={t(
            locationReminder.approachEnabled
              ? 'remindWhenApproaching'
              : 'remindWhenDepartingBy',
            {
              radius: locationReminder.radius,
              unit:
                locationReminder.lenType === '1'
                  ? t('meters')
                  : locationReminder.lenType === '2'
                  ? t('yards')
                  : locationReminder.lenType === '3'
                  ? t('miles')
                  : t('kilometers')
            }
          )}
        />
      ) : (
        <></>
      )}
      {props.eventLocation?.Lat && props.eventLocation.Long && (
        <SimpleMap
          height={'150px'}
          lat={parseFloat(props.eventLocation?.Lat ?? '0.0')}
          lng={parseFloat(props.eventLocation?.Long ?? '0.0')}
          radius={
            props.eventHook?.locationReminder &&
            props.eventHook.locationReminder.length > 0
              ? radiusInMeters(
                  props.eventHook.locationReminder[0].lenType,
                  props.eventHook.locationReminder[0].radius
                )
              : 0
          }
        />
      )}
    </div>
  ) : (
    <InputWithApproveButton
      onTextChange={onEventLocationChange}
      inputStyle={{
        backgroundColor: 'transparent !important',
        borderRadius: '0 !important'
      }}
      leftIcon={<LocationIcon color={theme.palette.input.placeholder} />}
      hintText={`${t('addLocation')}`}
      onApprove={props.eventHook?.addLocationToEvent ?? (() => {})}
      approveIcon={<Approve />}
    />
  )
}

interface SimpleMapProps {
  height: string
  lat: number
  lng: number
  radius?: number
}

export function SimpleMap(props: SimpleMapProps) {
  if (props.lat === 0 && props.lng === 0) return <></>

  const defaultProps = {
    center: {
      lat: props.lat,
      lng: props.lng
    },

    zoom: calculateZoomLevel(props.radius ?? 0)
  }

  const handleApiLoaded = (map: any, maps: any) => {
    // Create a new circle and attach it to the map
    new maps.Circle({
      strokeColor: '#0084d3',

      strokeWeight: 3,
      fillColor: '#500084d3',
      map,
      center: { lat: props.lat, lng: props.lng },
      radius: props.radius ?? 0
    })
  }

  return (
    <Grid
      container
      style={{
        height: props.height,
        width: '100%'
      }}
    >
      <GoogleMapReact
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false
        }}
        bootstrapURLKeys={{ key: firebaseConfig.apiKey }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </Grid>
  )
}
