import Switch from '../../../components/form/Switch'
import PopupSection from '../../../components/popup/PopupSection'
import PopupSectionRow from '../../../components/popup/PopupSectionRow'
import { ChangeEvent, useState } from 'react'
import { updateGroupSettings } from '../../../services/api/group'
import Logger from '../../../services/Logger'
import { GroupDoc, GroupSettings } from '../../../types/api/changes'
import { Model } from '../../../types/model'
import {
  featureToMinimumTierNeeded,
  isUserAdminInGroup,
  TierFeatures
} from 'utils/api/groups'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { GENERIC_ERROR } from 'utils/strings'
import { GroupPrivacy } from 'model/models/Group'
import { useConnection } from 'components/providers/ConnectionProvider'
import { t } from 'i18next'
import { Tier } from 'utils/api/accounts'
import { parseErrorToMessage } from '../eventInfo/EventPopup'

interface MembersCanProps {
  group: Model<GroupDoc>
  setLoading: (b: boolean) => void
  error: string
  setError: (error: string) => void
}

export default function MemberCanSection(props: MembersCanProps) {
  const connection = useConnection()

  async function updateSettings(data: GroupSettings, group: Model<GroupDoc>) {
    if (connection.isConnectionAvailable()) {
      props.setLoading(true)
      try {
        await updateGroupSettings(data, group)
      } catch (e) {
        Logger.red(String(e))
        props.setError(parseErrorToMessage(e))
      }
      props.setLoading(false)
    }
  }

  const changeGroupMetaData = (event: ChangeEvent<HTMLInputElement>) => {
    updateSettings(
      {
        ...props.group.GroupSettings,
        IsAllParticipantsCanEditGroupMetadata: event.target.checked ? '1' : '0'
      },
      props.group
    )
  }

  const changeAllCanAddItems = async (event: ChangeEvent<HTMLInputElement>) => {
    updateSettings(
      {
        ...props.group.groupSettings,
        IsAllParticipantsCanAddItems: event.target.checked ? '1' : '0'
      },
      props.group
    )
  }

  const changeAddParticipants = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    updateSettings(
      {
        ...props.group.groupSettings,
        IsAllParticipantsCanAddParticipants: event.target.checked ? '1' : '0'
      },
      props.group
    )
  }

  return isUserAdminInGroup(
    props.group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  ) && props.group.PrivacyMode === GroupPrivacy.PRIVATE ? (
    <>
      <PopupSection title={`${t('membersCan')}`}>
        <PopupSectionRow
          title={`${t('addEditEvents')}`}
          tierFeature={TierFeatures.PERMISSIONS_EVENTS}
          neededTier={
            props.group.OwnerID !== LocalStorage.get(LocalStorageKey.USER_ID)
              ? Tier.FREE
              : featureToMinimumTierNeeded(TierFeatures.PERMISSIONS_EVENTS)
          }
          value={
            <Switch
              onChange={changeAllCanAddItems}
              checked={
                props.group.GroupSettings?.IsAllParticipantsCanAddItems === '1'
              }
            />
          }
        />
        <PopupSectionRow
          title={`${t('editCalendarName')}`}
          tierFeature={TierFeatures.PERMISSIONS_CALENDAR_NAME}
          neededTier={
            props.group.OwnerID !== LocalStorage.get(LocalStorageKey.USER_ID)
              ? Tier.FREE
              : featureToMinimumTierNeeded(
                  TierFeatures.PERMISSIONS_CALENDAR_NAME
                )
          }
          value={
            <Switch
              checked={
                props.group.GroupSettings
                  ?.IsAllParticipantsCanEditGroupMetadata === '1'
              }
              onChange={changeGroupMetaData}
            />
          }
        />
        <PopupSectionRow
          title={`${t('addNewMembers')}`}
          tierFeature={TierFeatures.PERMISSIONS_MEMBERS}
          neededTier={
            props.group.OwnerID !== LocalStorage.get(LocalStorageKey.USER_ID)
              ? Tier.FREE
              : featureToMinimumTierNeeded(TierFeatures.PERMISSIONS_MEMBERS)
          }
          value={
            <Switch
              onChange={changeAddParticipants}
              checked={
                props.group.GroupSettings
                  ?.IsAllParticipantsCanAddParticipants === '1'
              }
            />
          }
        />
      </PopupSection>
    </>
  ) : (
    <></>
  )
}
