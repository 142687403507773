import {
  EventDatePicker,
  PickerType
} from 'components/eventComponents/EventDatePicker'
import { IEventDetails } from 'hooks/useEventDetails'
import { Controller } from 'react-hook-form'
import { EventDoc } from 'types/api/changes'

interface StartDateProps {
  eventHook: IEventDetails
  event: EventDoc
}

export default function StartDate(props: StartDateProps) {
  const { eventHook, event } = { ...props }
  return (
    <Controller
      name="StartDate"
      control={eventHook.control}
      defaultValue={event.StartDate}
      render={({ field }) => (
        <EventDatePicker
          eventHook={props.eventHook}
          pickerType={PickerType.START}
          allDay={eventHook.getValues('AllDay') === '1'}
          setStartTime={eventHook.setStartTime}
          startTime={Number(field.value) * 1000}
          setEndTime={eventHook.setEndTime}
          endTime={Number(eventHook.endTime) * 1000}
          timeZone={eventHook.getValues('TimeZoneNameID')}
          setTimeZone={eventHook.setTimeZone}
          duration={
            Number(eventHook.endTime) * 1000 -
            Number(eventHook.startTime) * 1000
          }
        />
      )}
    />
  )
}
