import { useRef, useState, useEffect } from 'react'
import './styles.css'
import i18n from 'i18n'

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  name,
  segments,
  callback,
  defaultIndex = 0,
  currentIndex = 0,
  controlRef
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const componentReady = useRef()

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true
  }, [])

  useEffect(() => {
    const activeSegmentRef = segments[currentIndex].ref
    const { offsetWidth, offsetLeft } = activeSegmentRef.current
    const { style } = controlRef.current

    let offset = '0px'

    if (currentIndex === 0) offset = i18n.dir() === 'ltr' ? '4px' : '-4px'
    else if (currentIndex === segments.length - 1)
      offset = i18n.dir() === 'ltr' ? '-4px' : '4px'

    style.setProperty('--left-offset', offset)
    style.setProperty('--highlight-width', `${offsetWidth}px`)
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`)
  }, [activeIndex, callback, controlRef, segments, defaultIndex, currentIndex])

  const onInputChange = (value, index) => {
    setActiveIndex(index)

    callback(value, index)
  }

  useEffect(() => {
    setActiveIndex(currentIndex)
  }, [currentIndex])

  return (
    <div className="controls-container" ref={controlRef}>
      <div className={`controls ${componentReady.current ? 'ready' : 'idle'}`}>
        {segments?.map((item, i) => {
          let margin = '0px'

          if (i === 0) margin = i18n.dir() === 'ltr' ? '6px' : '-6px'
          else if (i === segments.length - 1)
            margin = i18n.dir() === 'ltr' ? '-6px' : '6px'

          return (
            <div
              key={item.value}
              className={`segment ${
                i === currentIndex ? 'active' : 'inactive'
              }`}
              ref={item.ref}
            >
              <input
                type="radio"
                value={item.value}
                id={item.label}
                name={name}
                onChange={() => onInputChange(item.value, i)}
                checked={i === currentIndex}
              />
              <label style={{ marginLeft: margin }} htmlFor={item.label}>
                {item.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SegmentedControl
