import './styles.scss'

import { Box, ClickAwayListener, Typography } from '@mui/material'
import withObservables from '@nozbe/with-observables'
import { InviteLinkRow } from 'components/badges/InviteLinkBadge'
import { CalendarHeaderTitle } from 'components/calendar/styled'
import ContainerWithBottomBadgeIcon from 'components/containers/ContainerWithBottomBadgeIcon'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { useConnection } from 'components/providers/ConnectionProvider'
import ProgressProvider, {
  useProgress
} from 'components/providers/ProgressProvider'
import TierHover from 'components/tier/TierHover'
import i18n from 'i18n'
import { t } from 'i18next'
import { GroupPrivacy, GroupVerified } from 'model/models/Group'
import { ComponentType, ReactNode, useState } from 'react'
import compose from 'recompose/compose'
import { theme } from 'theme'
import { highestActiveTier, Tier } from 'utils/api/accounts'
import {
  defaultGroupImage,
  featureToMinimumTierNeeded,
  getActiveParticipants,
  isAddingMemberAllowedForGroup,
  isGroupChannel,
  isGroupOutOfTier,
  isUserActiveInGroup,
  isUserAdminInGroup,
  isUserOwnerOfGroup,
  TierFeatures
} from 'utils/api/groups'
import { APP_COLOR } from 'utils/colors'

import { CloseIcon, GroupChannelIcon, PlusIcon } from '../../../assets/icons'
import { CircleImagePicker } from '../../../components/calendar/CircleImagePicker'
import LoadingContainer from '../../../components/containers/LoadingContainer'
import MembersList from '../../../components/lists/MembersList'
import { PopupInstance } from '../../../components/popup/Popup'
import PopupSection from '../../../components/popup/PopupSection'
import {
  generateLinkForGroup,
  updateGroupPhoto,
  updateGroupTitle
} from '../../../services/api/group'
import ObserveService from '../../../services/db/Observe'
import {
  isOwnerForGroup,
  isUserLeft
} from '../../../services/db/utils/GroupUtils'
import LocalStorage from '../../../services/LocalStorage'
import Logger from '../../../services/Logger'
import {
  AccountDoc,
  GroupDoc,
  GroupParticipant,
  GroupParticipantStatus,
  UserSettingsDoc
} from '../../../types/api/changes'
import { Model } from '../../../types/model'
import { LocalStorageKey } from '../../../types/services/localStorage'
import { parseErrorToMessage } from '../eventInfo/EventPopup'
import * as Styled from '../styled'
import BusinessInfoSection from './BusinessInfoSection'
import ChannelToggle from './ChannelToggle'
import { ExitGroupButton } from './ExitGroupButton'
import GroupTitle from './GroupTitle'
import InputWithAction from './InputWithAction'
import MakeGroupVerified from './MakeGroupVerified'
import MemberCanSection from './MembersCanSection'
import RemoveLinkRow from './RemoveLinkRow'
import SettingsForCalendarSection, {
  performUpdateGroupSettings
} from './SettingsForCalendarSection'
import { TextButtonContainer } from './styled'
import EmbedCalendarDialog from 'components/dialogs/embed/EmbedCalendarDialog'
import { PopupSectionRow } from 'components/popup/PopupSectionRow'
import CalendarProvider, {
  useCalendar
} from 'components/providers/CalendarProvider'
import { isGroupDefault } from 'utils/api/userSettings'
import { groupToProvider, isConvertedGroup } from 'utils/groups'
import {
  ProviderType,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'

export interface ILoading {
  setLoading: (b: boolean) => void
}

interface ContentProps {
  group: Model<GroupDoc>
  userSettings: Model<UserSettingsDoc>
  instance?: PopupInstance
  setGroupOpen: (b: boolean) => void
  account: Model<AccountDoc>
}

function GroupPopupContent(props: ContentProps) {
  const calendarSync = useCalendarSync()
  const calendar = useCalendar()
  const [showEmbedDialog, onShowEmbedDialog] = useState(false)
  const hideEmbedDialog = () => {
    onShowEmbedDialog(false)
  }
  const handleShowEmbedDialog = () => {
    onShowEmbedDialog(true)
  }
  const { error, setError, setLoading, loading } = useProgress()
  const connection = useConnection()
  const [editName, onEditName] = useState(false)
  const [showOutOfTierDialog, onShowOutOfTierDialog] = useState(false)

  const isUserLeftGroup = !isUserActiveInGroup(
    props.group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  )
  const participantsToShow: Record<string, GroupParticipant> = {}

  const activeTier = highestActiveTier(props.account)
  const isOutOfTier = isGroupOutOfTier(activeTier, props.group, props.account)

  for (
    let index = 0;
    index < Object.keys(props.group.Participants ?? {}).length;
    index++
  ) {
    const key = Object.keys(props.group.Participants)[index]
    const participant = props.group.Participants[key]

    if (participant.Status === GroupParticipantStatus.ACTIVE)
      participantsToShow[key] = participant
  }

  const onPicSelected = async (pic: string) => {
    if (connection.isConnectionAvailable()) {
      setLoading(true)
      try {
        await updateGroupPhoto(pic, props.group)
      } catch (e) {
        Logger.red(String(e))
      }
      setLoading(false)
    }
  }

  const onChangeGroupName = async (title: string) => {
    if (connection.isConnectionAvailable()) {
      if (title != props.group.Name) {
        setLoading(true)
        try {
          await updateGroupTitle(title, props.group)
        } catch (e) {
          setLoading(false)
          Logger.red(String(e))
          setError(parseErrorToMessage(e))
          return false
        }
        setLoading(false)
      }
    }

    onEditName(false)
    return true
  }

  const hideGroupPopup = () => {
    props.setGroupOpen(false)
  }

  const hideErrorDialog = () => {
    setError('')
  }

  const isCurrentGroupGoogleCalendar = isConvertedGroup(props.group)

  const canEditNameOrPhoto =
    !isOutOfTier &&
    (props.group.GroupSettings?.IsAllParticipantsCanEditGroupMetadata === '1' ||
      isUserAdminInGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ||
      isUserOwnerOfGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      )) &&
    isUserActiveInGroup(
      props.group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    ) &&
    props.group.VerifiedGroupStatus != GroupVerified.VERIFIED &&
    !isCurrentGroupGoogleCalendar

  const removeGoogleCalendar = () => {
    calendarSync
      .logout(props.group)
      .then(() => {
        props.setGroupOpen(false)
        calendar.onGroupServer('')
      })
      .then(() => {
        if (isGroupDefault(props.userSettings, props.group._id)) {
          performUpdateGroupSettings(props.userSettings, props.group._id, {
            Default: '0'
          })
        }
      })
  }
  function unlinkTitle(group: Model<GroupDoc>): string | undefined {
    const provider = groupToProvider(group)

    if (provider === ProviderType.GOOGLE) {
      return `${t('removeGoogleCalendar')}`
    } else if (provider === ProviderType.OUTLOOK) {
      return `${t('removeOutlookCalendar')}`
    } else if (provider === ProviderType.APPLE) {
      return `${t('removeAppleCalendar')}`
    }

    return ''
  }

  function dialogTitle(group: Model<GroupDoc>): string | undefined {
    const provider = groupToProvider(group)

    if (provider === ProviderType.GOOGLE) {
      return `${t('areYouSureDeleteGoogle')}`
    } else if (provider === ProviderType.OUTLOOK) {
      return `${t('areYouSureDeleteOutlook')}`
    } else if (provider === ProviderType.APPLE) {
      return `${t('areYouSureDeleteApple')}`
    }

    return ''
  }

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '600px'
      }}
    >
      <LoadingContainer loading={loading ? 1 : 0} />
      <PopupSection>
        <Styled.GroupPopupAvatarContainer>
          <div style={{ pointerEvents: canEditNameOrPhoto ? 'all' : 'none' }}>
            <ContainerWithBottomBadgeIcon
              showLock={isOutOfTier}
              topRightIcon={
                isGroupChannel(props.group) ? (
                  <GroupChannelIcon width={18} height={18} />
                ) : undefined
              }
              marginRight={-4}
            >
              <CircleImagePicker
                fallback={defaultGroupImage(props.group)}
                currentPicture={props.group.Photo}
                onPicSelected={onPicSelected}
              />
            </ContainerWithBottomBadgeIcon>
          </div>
          <InputWithAction
            disabled={!canEditNameOrPhoto}
            initialText={props.group?.Name ?? ''}
            editState={editName}
            setLoading={setLoading}
            setEditState={onEditName}
            onSubmit={onChangeGroupName}
            nonActiveChild={
              <CalendarHeaderTitle className="groupTitle">
                <GroupTitle group={props.group} iconHeight="20px" />
              </CalendarHeaderTitle>
            }
          />
          {loading ? (
            <></>
          ) : (
            <Styled.GroupPopupCloseButton
              sx={{
                right: i18n.dir() === 'ltr' ? '0' : 'calc(100% - 25px)'
              }}
              size="sm"
              onClick={hideGroupPopup}
            >
              <CloseIcon />
            </Styled.GroupPopupCloseButton>
          )}
        </Styled.GroupPopupAvatarContainer>
      </PopupSection>
      {!isCurrentGroupGoogleCalendar &&
      (isOwnerForGroup(props.group) ||
        isUserAdminInGroup(
          props.group,
          LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        ) ||
        (props.group?.BusinessInfo?.Text.length ?? 0) > 0) ? (
        <PopupSection
          withProBadge={
            isOwnerForGroup(props.group) &&
            (isOutOfTier ||
              activeTier <
                featureToMinimumTierNeeded(TierFeatures.BUSINESSINFO))
          }
          title={`${t('aboutCalendar', { calendarName: props.group.Name })}`}
        >
          <BusinessInfoSection
            group={props.group}
            setLoading={setLoading}
            account={props.account}
          />
        </PopupSection>
      ) : (
        <></>
      )}

      {isUserLeftGroup ? (
        <Typography color={'red'} align={'center'} padding={2}>
          {isUserLeft(
            props.group,
            LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
          )
            ? t('youLeft')
            : t('youRemovedFromCalendar')}
        </Typography>
      ) : (
        <MemberCanSection
          error={error}
          setError={setError}
          group={props.group}
          setLoading={setLoading}
        />
      )}
      <SettingsForCalendarSection
        account={props.account}
        group={props.group}
        userSettings={props.userSettings}
        setLoading={setLoading}
      />
      {isCurrentGroupGoogleCalendar ? <></> : <MembersSection {...props} />}
      {isCurrentGroupGoogleCalendar ? (
        <></>
      ) : (
        <OnGroupActionSection
          {...props}
          activeTier={activeTier}
          handleShowEmbedDialog={handleShowEmbedDialog}
        />
      )}
      {isCurrentGroupGoogleCalendar ? (
        <PopupSection>
          <ExitGroupButton
            group={props.group}
            setLoading={setLoading}
            rowTitle={unlinkTitle(props.group)}
            dialogTitle={dialogTitle(props.group)}
            positiveTitle={`${t('unlink')}`}
            action={removeGoogleCalendar}
          />
        </PopupSection>
      ) : isUserLeftGroup ? (
        <></>
      ) : !isOwnerForGroup(props.group) ? (
        <PopupSection>
          <ExitGroupButton group={props.group} setLoading={setLoading} />
        </PopupSection>
      ) : (
        <></>
      )}
      {error && (
        <ConfirmationDialog
          title={t('cantDoThat')}
          content={error}
          rightButtonHidden
          leftButton={`${t('ok')}`}
          leftButtonColor={APP_COLOR}
          handleLeftButton={hideErrorDialog}
          open={error.length > 0}
          handleClose={hideErrorDialog}
        />
      )}
      {showEmbedDialog && (
        <CalendarProvider>
          <EmbedCalendarDialog
            group={props.group}
            open={showEmbedDialog}
            handleClose={hideEmbedDialog}
          />
        </CalendarProvider>
      )}
      <TierHover
        isOutOfTier={isOutOfTier}
        topOffset={75}
        account={props.account}
        group={props.group}
        opened={showOutOfTierDialog}
        setOpen={onShowOutOfTierDialog}
      />
    </Box>
  )
}

interface OnGroupActionsProps extends ContentProps {
  activeTier: Tier
  handleShowEmbedDialog?: () => void
}

function OnGroupActionSection(props: OnGroupActionsProps) {
  const { setError, setLoading } = useProgress()
  return (
    <PopupSection>
      {isUserAdminInGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ? (
        <div>
          {' '}
          {props.group.PrivacyMode === GroupPrivacy.CHANNEL ? (
            <></>
          ) : (
            <ChannelToggle group={props.group} setLoading={setLoading} />
          )}
          <MakeGroupVerified
            currentTier={props.activeTier}
            group={props.group}
          />
        </div>
      ) : (
        <></>
      )}
      {props.group.PrivateLinkUrl && (
        <PopupSectionRow
          onlyTitle
          title={t('embedCalendar')}
          action={props.handleShowEmbedDialog}
        />
      )}
    </PopupSection>
  )
}

function MembersSection(props: ContentProps) {
  const { setError, setLoading } = useProgress()
  const connection = useConnection()
  const updateGroupWithLink = async () => {
    if (connection.isConnectionAvailable()) {
      setLoading(true)
      try {
        const updated = await generateLinkForGroup(props.group)

        try {
          await navigator.clipboard.writeText(updated.PrivateLinkUrl)
        } catch (e) {
          //
        }
      } catch (e) {
        setError(parseErrorToMessage(e))
      }

      setLoading(false)
    }
  }

  const shouldShowParticipants =
    props.group.PrivacyMode === GroupPrivacy.PRIVATE ||
    (props.group.PrivacyMode === GroupPrivacy.CHANNEL &&
      (isUserAdminInGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ||
        props.group.OwnerID === LocalStorage.get(LocalStorageKey.USER_ID)))

  const isUserActive = isUserActiveInGroup(
    props.group,
    LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
  )

  function AddLinkContainer() {
    return (
      <TextButtonContainer onClick={updateGroupWithLink}>
        <PlusIcon color={theme.palette.button.text.link} />
        <Styled.TextButton style={{ marginLeft: 4 }}>
          {t('shareCalendarWithLink')}
        </Styled.TextButton>
      </TextButtonContainer>
    )
  }

  return (
    <PopupSection
      title={`${t('membersInGroup', {
        memberCount: Object.keys(
          getActiveParticipants(props.group.Participants)
        ).length
      })}`}
      padding={theme.spacing(0, 2, 0, 0)}
    >
      {shouldShowParticipants ? (
        <>
          {isAddingMemberAllowedForGroup(props.group) && isUserActive ? (
            props.group.PrivateLinkUrl ? (
              <LinkActions group={props.group} />
            ) : (
              <AddLinkContainer />
            )
          ) : props.group.PrivateLinkUrl && isUserActive ? (
            <LinkActions group={props.group} />
          ) : (
            <></>
          )}
          {props.group.Participants != null ? (
            <div style={{ marginTop: 16 }}>
              <MembersList
                group={props.group}
                groupId={props.group._id}
                setLoading={setLoading}
                participants={props.group.Participants}
                withoutActions={false}
                localParticipants={[]}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : props.group.PrivateLinkUrl &&
        props.group.PrivacyMode === GroupPrivacy.CHANNEL ? (
        <InviteLinkRow link={props.group.PrivateLinkUrl} />
      ) : (
        <></>
      )}
    </PopupSection>
  )
}

interface LinkActionsProps {
  group: Model<GroupDoc>
}

function LinkActions(props: LinkActionsProps) {
  return (
    <>
      <InviteLinkRow link={props.group.PrivateLinkUrl} />
      {isUserAdminInGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ||
      isUserOwnerOfGroup(
        props.group,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      ) ? (
        <RemoveLinkRow group={props.group} />
      ) : (
        <></>
      )}
    </>
  )
}

interface GroupPopupProps {
  setOpen: (b: boolean) => void
  isOpen: boolean
  children: ReactNode
  group: Model<GroupDoc>
  groupID: string
  userSettings: Model<UserSettingsDoc>
  account: Model<AccountDoc>
}

function GroupPopup(props: GroupPopupProps) {
  const [instance, onInstance] = useState<PopupInstance>()
  const calendar = useCalendar()
  return (
    <ProgressProvider>
      <Styled.GroupPopup
        onMount={onInstance}
        open={props.isOpen}
        setOpen={props.setOpen}
        content={
          <ClickAwayListener
            onClickAway={() => {
              if (LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) === '1')
                return

              calendar.onGroupInfo(undefined)
              props.setOpen(false)
            }}
          >
            <div>
              <GroupPopupContent
                instance={instance}
                setGroupOpen={props.setOpen}
                {...props}
              />
            </div>
          </ClickAwayListener>
        }
      >
        {props.children}
      </Styled.GroupPopup>
    </ProgressProvider>
  )
}
export default compose(
  withObservables([], () => ({
    account: ObserveService.Account()
  })),
  withObservables([], () => ({
    userSettings: ObserveService.UserSettings()
  })),
  withObservables(['groupID', 'userSettings'], (props: GroupPopupProps) => ({
    group: ObserveService.GroupByServerID(props.groupID)
  }))
)(GroupPopup as ComponentType<unknown>) as any
