import { createContext, ReactNode, useContext, useState } from 'react'

interface ConnectionProviderProps {
  children: ReactNode
}
export default function ProgressProvider(props: ConnectionProviderProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const context = {
    loading: loading,
    setLoading: setLoading,
    error,
    setError
  }

  return (
    <ProgressContext.Provider value={context}>
      {props.children}
    </ProgressContext.Provider>
  )
}

interface ProgressContextProps {
  loading: boolean
  setLoading: (b: boolean) => void
  error: string
  setError: (error: string) => void
}

const ProgressContext = createContext<ProgressContextProps>({
  loading: false,
  setLoading: (b: boolean) => {},
  error: '',
  setError: (e: string) => {}
})

export function useProgress(): ProgressContextProps {
  return useContext(ProgressContext)
}
