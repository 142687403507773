import { ClickAwayListener, MenuList } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import {
  AccountDoc,
  GroupDoc,
  GroupStatus,
  UserSettingsDoc
} from 'types/api/changes'
import ArchiveIcon from '@mui/icons-material/Archive'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { t } from 'i18next'
import {
  isGroupArchived,
  isGroupHidden,
  isGroupUnread
} from 'utils/api/userSettings'
import { Model } from 'types/model'
import { useCalendar } from 'components/providers/CalendarProvider'
import { isGroupcalApp } from 'utils/appType'
import { performUpdateGroupSettings } from 'components/popups/groupInfo/SettingsForCalendarSection'
import { GroupContextMenuProps } from 'components/sidebar/Groups'
import { isConvertedGroup } from 'utils/groups'
import { DefaultCalendar } from 'assets/icons'
import { isGroupBlockedToAddEvents } from 'utils/api/groups'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

interface ContextMenuProps {
  group: GroupDoc
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc
  account?: Model<AccountDoc>
  x: number // X-coordinate of the click event
  y: number // Y-coordinate of the click event
  contextMenuPosition: GroupContextMenuProps
  onClose: () => void // Callback to close the context menu
}

const GroupItemContextMenu: React.FC<ContextMenuProps> = ({
  group,
  userSettings,
  account,
  x,
  y,
  contextMenuPosition,
  onClose
}) => {
  const archiveText = isGroupArchived(userSettings, group._id ?? '')
    ? t('unarchive')
    : t('archive')
  const archiveIcon = isGroupArchived(userSettings, group._id ?? '') ? (
    <UnarchiveIcon />
  ) : (
    <ArchiveIcon />
  )
  const unreadText = isGroupUnread(userSettings, group._id ?? '')
    ? t('markAsRead')
    : t('markAsUnread')
  const unreadIcon = isGroupUnread(userSettings, group._id ?? '') ? (
    <MarkunreadIcon />
  ) : (
    <MarkEmailUnreadIcon />
  )
  const allCalendarsText = isGroupHidden(userSettings, group._id ?? '')
    ? t('showOnAllCal', { cal: t('allCalendarsButton') })
    : t('dontShowOnAllCal', { cal: t('allCalendarsButton') })
  const allCalendarsIcon = isGroupHidden(userSettings, group._id ?? '') ? (
    <VisibilityIcon />
  ) : (
    <VisibilityOffIcon />
  )

  const calendar = useCalendar()

  const showCalendarDetails = () => {
    if (group._id) {
      calendar.onGroupServer(group._id)
      setTimeout(() => {
        calendar.onGroupInfo(group._id)
      }, 50)
    }

    onClose()
  }

  const handleArchiveCalendar = () => {
    // Logic for "Archive calendar" action
    onClose()
    const newValue = isGroupArchived(
      userSettings,
      contextMenuPosition?.group._id ?? ''
    )
      ? GroupStatus.ACTIVE
      : GroupStatus.ARCHIVED
    performUpdateGroupSettings(
      userSettings,
      contextMenuPosition?.group._id ?? '',
      {
        GroupStatus: newValue
      }
    ).then(() => {
      calendar.onGroupServer('')
    })
  }

  const handleMarkAsUnread = () => {
    // Logic for "Mark as unread" action
    onClose()
    const newValue = isGroupUnread(
      userSettings,
      contextMenuPosition?.group._id ?? ''
    )
      ? '0'
      : '1'
    performUpdateGroupSettings(
      userSettings,
      contextMenuPosition?.group._id ?? '',
      {
        Unread: newValue
      }
    )
  }

  const handleHideFromCalendars = () => {
    // Logic for "Don't show on all calendars" action
    onClose()

    const newValue = isGroupHidden(
      userSettings,
      contextMenuPosition?.group._id ?? ''
    )
      ? '1'
      : '0'
    performUpdateGroupSettings(
      userSettings,
      contextMenuPosition?.group._id ?? '',
      {
        ShowOnAllCalendars: newValue
      }
    )
  }

  const makeDefaultCalendar = () => {
    performUpdateGroupSettings(
      userSettings,
      contextMenuPosition?.group._id ?? '',
      {
        Default: '1'
      }
    ).then(() => {
      onClose()
    })
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <ul
        style={{
          position: 'absolute',
          width: 'fit-content',
          top: y,
          left: x,
          listStyleType: 'none',
          zIndex: 99999,
          padding: '8px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
        }}
      >
        <MenuList>
          {isGroupcalApp() && !isConvertedGroup(group) && (
            <MenuItem id="archive-group-button" onClick={handleArchiveCalendar}>
              <ListItemIcon>{archiveIcon}</ListItemIcon>
              <ListItemText>{archiveText}</ListItemText>
            </MenuItem>
          )}
          {isGroupcalApp() && (
            <MenuItem onClick={handleMarkAsUnread}>
              <ListItemIcon>{unreadIcon}</ListItemIcon>
              <ListItemText>{unreadText}</ListItemText>
            </MenuItem>
          )}
          {isGroupcalApp() && (
            <MenuItem onClick={handleHideFromCalendars}>
              <ListItemIcon>{allCalendarsIcon}</ListItemIcon>
              <ListItemText>{allCalendarsText}</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            id="show-group-details-button"
            onClick={showCalendarDetails}
          >
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText>{t('calendarDetails')}</ListItemText>
          </MenuItem>
          {account &&
          !isGroupBlockedToAddEvents(
            account,
            group,
            userSettings,
            LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
          ) ? (
            <MenuItem onClick={makeDefaultCalendar}>
              <ListItemIcon>
                <DefaultCalendar
                  style={{ marginLeft: 2 }}
                  width={20}
                  height={20}
                />
              </ListItemIcon>
              <ListItemText>{t('defaultCalendar')}</ListItemText>
            </MenuItem>
          ) : (
            <></>
          )}
        </MenuList>
      </ul>
    </ClickAwayListener>
  )
}

export default GroupItemContextMenu
