import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import IconButton from 'components/buttons/IconButton'
import { StyledInput } from 'components/popups/styled'
import { fi } from 'date-fns/locale'
import { t } from 'i18next'
import { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from 'react'

export interface InputWithApproveButtonProps {
  hintText: string
  onApprove: (text: string) => void
  leftIcon?: ReactNode
  approveIcon: ReactNode
  onTextChange?: (event: ChangeEvent<HTMLInputElement>) => void
  text?: string
  validate?: (text: string) => boolean
  error?: string
  setError?: (error: string) => void
  inputStyle?: React.CSSProperties
  options?: string[]
  bringFocus?: boolean
  compressedImage?: string
}

export default function InputWithApproveButton(
  props: InputWithApproveButtonProps
) {
  const [areOptionsAvailable, setAreOptionsAvailable] = useState(false)
  const [error, setError] = useState<string>(props.text ? '' : '')
  const [text, setText] = useState<string>(props.text ?? '')
  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('')
    setText(event.target.value)
    if (props.onTextChange) props.onTextChange(event)
  }

  const approve = (text: string) => {
    setAreOptionsAvailable(false)
    if (props.validate != undefined && !props.validate(props.text ?? text)) {
      setError(`${t('emailNotValid')}`)
      return
    }

    props.onApprove(props.text ?? text)
    setText('')
    setError('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (text) {
      if (props.validate != undefined && !props.validate(props.text ?? text)) {
        setError(`${t('emailNotValid')}`)
        return
      }
      props.onApprove(text)
    }
  }

  useEffect(() => {
    setText(props.text ?? '')
  }, [props.text])

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Grid
        container
        flexWrap={'nowrap'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        columnGap={3}
        marginLeft={1}
      >
        <div style={{ minWidth: 42 }}>{props.leftIcon}</div>
        {props.options ? (
          <Autocomplete
            open={areOptionsAvailable}
            value={text}
            options={props.options ?? []}
            sx={{
              width: '100%',
              border: 'none',

              padding: 0,
              '& .MuiOutlinedInput-root': {
                // Target the OutlinedInput component
                padding: 0,
                border: 'none',
                fontFamily: 'NotoSans'
              }
            }}
            onChange={(event, value) => {
              if (value) {
                approve(value)
                setText('')
              } else {
                setText('')
              }
            }}
            onInputChange={(event, value) => {
              if (value) {
                setText(value)
                props.onTextChange?.(event as any)

                const filtered = props.options?.filter((option) =>
                  option.toLowerCase().includes(value.toLowerCase())
                )
                setAreOptionsAvailable((filtered?.length ?? 0) > 0)
              } else {
                setText('')
                setError('')
                setAreOptionsAvailable(false)
              }
            }}
            slotProps={{
              popper: { sx: { zIndex: 9998 } }
            }}
            renderOption={(props, option, state) => (
              <li {...props} style={{ fontSize: '14px' }}>
                {option}
              </li>
            )}
            autoHighlight
            renderInput={(params) => {
              delete params.InputProps.endAdornment
              return (
                <TextField
                  {...params}
                  sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    '& .MuiInput-underline': {
                      fontSize: 14,
                      fontFamily: 'NotoSans',
                      fontWeight: 500,
                      '&:before': {
                        borderBottom: 'none'
                      },
                      '&:after': {
                        borderBottom: 'none'
                      },
                      '&:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none'
                      }
                    }
                  }}
                  variant="standard"
                  placeholder={props.hintText}
                />
              )
            }}
          />
        ) : (
          <StyledInput
            shouldNotFocus={true}
            bringFocus={props.bringFocus}
            placeholder={props.hintText}
            value={text}
            onChange={onTextChange}
            variant="popup"
            sx={{ ...props.inputStyle }}
          />
        )}
        {text || props.text ? (
          <div style={{ marginRight: 8 }}>
            <IconButton
              type="submit"
              size="xs"
              onClick={approve.bind(null, text)}
            >
              {props.approveIcon}
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </Grid>
      <span
        style={{
          color: 'red',
          fontSize: '12px'
        }}
      >
        {error}
      </span>
    </form>
  )
}
