/* eslint-disable */
import {
  DateSelectArg,
  DatesSetArg,
  EventApi,
  EventDropArg,
  EventSourceInput
} from '@fullcalendar/core'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import moment from 'moment-timezone'
import dayGridPlugin from '@fullcalendar/daygrid'
import rrulePlugin from '@fullcalendar/rrule'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg
} from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import withObservables from '@nozbe/with-observables'
/* eslint-enable */

import './styles.scss'

import * as Styled from './styled'
import ObserveService from 'services/db/Observe'
import { dayHeaderClassNames } from 'utils/calendar'
import {
  AllDayContent,
  DayCellContent,
  DayHeaderContent,
  EventContent,
  SlotLabelContent
} from './components'
import {
  CALENDAR_VIEW,
  useCalendar
} from 'components/providers/CalendarProvider'
import { AddIcon } from 'assets/icons'
import { Model } from 'types/model'
import {
  AccountDoc,
  AccountProduct,
  DocType,
  EventDoc,
  EventStatus,
  GroupDoc,
  UserSettingsDoc
} from 'types/api/changes'
import {
  handleEventUpdate,
  isLate,
  isSomeDay,
  mapEventsToCalendar
} from 'utils/api/events'
import { compose } from 'recompose'
import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import {
  StartEndEvent,
  generateStartEndBasedOnTime,
  generateStartEndBasedOnTimeWithShift
} from 'utils/date'
import {
  addTempEventToDate,
  localEventById,
  RemoveTempEvents
} from 'services/db/Queries/Event'
import { provideFirstGroup } from 'services/db/Queries/GroupQueries'
import { LocalStorageKey } from 'types/services/localStorage'
import LocalStorage from 'services/LocalStorage'
import { Component, useEffect, useState } from 'react'
import {
  isGroupNotAvailableForEventChanges,
  isGroupOutOfTier,
  isUserActiveInGroup,
  isUserCanOperateEvents,
  isUserHaveGroupToAddEvents
} from 'utils/api/groups'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { getProfilesForParticipants } from 'services/api/group'
import { exhaustedProduct, highestActiveTier } from 'utils/api/accounts'
import { OutOfTierDialog } from 'components/tier/OutOfTierDialog'
import { APP_COLOR } from 'utils/colors'
import Analytics, {
  BlockedFlow,
  EventProperties,
  Events
} from 'analytics/Analytics'
import { getEventsForGroup } from 'services/api/event'
import Logger from 'services/Logger'
import { t } from 'i18next'
import i18n from 'i18n'
import { usePayment } from 'components/providers/PaymentProvider'
import { getArchivedGroups } from 'utils/api/userSettings'
import { CalendarRange } from 'types/components/calendar'
import { isGroupHidden } from 'utils/api/userSettings'
import Database from 'services/db/Database'
import { updateEventOnServer } from 'services/api/event'
import RecurrentActionDialog from 'components/dialogs/RecurrentActionDialog'
import {
  RECUR_SAVE_TYPE,
  parseErrorToMessage
} from 'components/popups/eventInfo/EventPopup'
import { AxiosError } from 'axios'
import { Box, ThemeProvider } from '@mui/material'
import { createRoot } from 'react-dom/client'
import EventsListPopup from 'components/popups/EventsListPopup'
import { theme } from 'theme'
import Event from './components/Event'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import {
  defaultGroupId,
  groupIdToProvider,
  isConvertedGroupId
} from 'utils/groups'
import React from 'react'
import { useAppSettings } from 'components/providers/AppSettingsProvider'
import ListView from './ListView'
import useLocalStorage from 'components/LocalStorageHook'
import {
  CalendarSyncProvider,
  ProviderType,
  removePrefix,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'
import {
  CurrentConvertedCalendars,
  prefixGoogleEventsGroup
} from 'utils/google_events'
import { outlookPrefix } from 'utils/constants'
import { isToday } from 'date-fns'
import {
  IcloudProvider,
  icloudPrefix
} from 'components/providers/icloud/IcloudProvider'
import { NotificationProvider } from 'components/providers/NotificationProvider'
import { ResyncCalendarDialog } from 'components/dialogs/ResyncGoogleDialog'
import { Ref } from '@fullcalendar/core/preact'
import FullCalendar from '@fullcalendar/react'
import { isCypress } from 'utils/cypress'
import { getDefaultTimezone } from 'index'

export enum FIRST_DAYS {
  SUNDAY = 1,
  MONDAY = 2,
  SATURDAY = 7
}

interface CalendarViewProps {
  events: Model<EventDoc>[]
  groups: Model<GroupDoc>[]
  userSettings: Model<UserSettingsDoc>
  groupID: string
  group: Model<GroupDoc>
  readOnly?: boolean
  firstGroup: Model<GroupDoc>
  account: Model<AccountDoc>
  defaultView?: CalendarRange
  showOnlySelectedGroup?: boolean
  dayNameFormat?: string
  visibleFrom?: number
  visibleTo?: number
}

interface DateRange {
  leftBound: number
  rightBound: number
}

interface DropEventData {
  newEvent: EventApi
  oldEvent: Model<EventDoc>
  oldAllDay: boolean
  oldStart: number
  oldEnd: number
  oldTimeZone: string
  newStart: number
  newEnd: number
}

function CalendarView(props: CalendarViewProps) {
  //triggers re-render once someday date is changed
  const [someDayDate, setSomedayDate] = useLocalStorage(
    LocalStorageKey.SOMEDAY_DATE,
    dayjs().tz('UTC').startOf('day').unix().toString()
  )

  const calendarSync = useCalendarSync()

  const archivedGroups = getArchivedGroups(props.userSettings)
  const isGuest = !LocalStorage.get(LocalStorageKey.PHONE_NUMBER)
  const [showUserRemovedDialog, setShowUserRemovedDialog] = useState(false)
  const calendar = useCalendar()
  const [userCannotAddEvents, setUserCanAddEvents] = useState(false)
  const [showOutOfTierDialog, onShowOutOfTierDialog] = useState(false)
  const [dateRange, onDateRange] = useState<DateRange>()
  const activeTier = highestActiveTier(props.account)
  const isOutOfTier = isGroupOutOfTier(activeTier, props.group, props.account)
  const [showCalendarSync, onShowCalendarSync] = useState(false)

  function showSyncIfNeeded() {
    if (props.group?.SyncProblem === '1') {
      RemoveTempEvents().then(() => {
        ;(calendar.ref as any).current?.getApi().unselect()
      })

      onShowCalendarSync(true)
      return true
    }

    return false
  }

  const showCanAddEventsDialog = () => {
    setUserCanAddEvents(true)
  }

  const hideCanAddEventsDialog = () => {
    setUserCanAddEvents(false)
  }

  const hideUserRemovedDialog = () => {
    setShowUserRemovedDialog(false)
  }

  const showUserRemoved = () => {
    setShowUserRemovedDialog(true)
  }

  const userRemoved =
    !isUserActiveInGroup(
      props.group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    ) && (LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ?? '').length > 0

  const emptyDateClick = async (arg: DateClickArg) => {
    Logger.blue('EmptyDateClick', arg)
    if (showSyncIfNeeded()) {
      return
    }
    if (LocalStorage.get(LocalStorageKey.MORE_EVENTS_OPENED) === '1') return
    if (isGroupcalApp() && (!haveActiveCalendar || props.groups?.length == 0)) {
      onShowEventAddingNotPossible(true)
      return
    }

    function clearScreen() {
      LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
    }

    if (LocalStorage.get(LocalStorageKey.USER_ID)) {
      if (!LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)) {
        if (isOutOfTier) {
          onShowOutOfTierDialog(true)
        } else if (
          props.group &&
          !isUserCanOperateEvents(
            props.group,
            LocalStorage.get(LocalStorageKey.USER_ID)!
          )
        ) {
          showCanAddEventsDialog()
        } else if (userRemoved) {
          setShowUserRemovedDialog(true)
        } else if (
          !LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) ||
          LocalStorage.get(LocalStorageKey.SHOULD_KEEP_POPUP) === '0'
        ) {
          const allDay = arg.view.type === 'dayGridMonth' ? false : arg.allDay

          let start = dayjs(arg.date)
            .minute(0)
            .add(arg.view.type === 'dayGridMonth' ? 12 : 0, 'hour')
            .tz(allDay ? 'UTC' : getDefaultTimezone())
          if (allDay) {
            start = start.startOf('day')
          }
          if (LocalStorage.get(LocalStorageKey.USER_ID)) {
            console.log('start', start)
            addEventToDate(
              EventProperties.ADD_EVENT_FROM_LONG_TAP,
              props.group?._id,
              start.unix(),
              start
                .add(
                  (arg.view.type === 'dayGridMonth' ? false : arg.allDay)
                    ? 24
                    : 1,
                  'hour'
                )
                .unix(),
              arg.view.type === 'dayGridMonth' ? false : arg.allDay,
              props.userSettings
            )
          }
        }
      } else {
        clearScreen()
      }
    }
  }

  const onDatesSet = (datesSetArg: DatesSetArg) => {
    const nowDayJs = dayjs()
    let offset = new Date().getTimezoneOffset()
    if (offset > 0) {
      //
    } else if (offset < 0) {
      offset = Math.abs(offset)
    } else {
      //
    }
    const startDayJs = dayjs(datesSetArg.start.valueOf()).add(offset, 'minute')
    const endDayJs = dayjs(datesSetArg.end.valueOf())
      .add(offset, 'minute')
      .add(-1, 'second')
    const centerDayJs = dayjs((startDayJs.valueOf() + endDayJs.valueOf()) / 2)
    ;(datesSetArg.view as any).dateEnv.defaultSeparator = '\n'

    let timePeriod: ManipulateType = 'day'

    if (
      LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) === CalendarRange.MONTH
    ) {
      timePeriod = 'month'
    } else if (
      LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) === CalendarRange.WEEK ||
      LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) === CalendarRange.LISTWEEK
    ) {
      timePeriod = 'week'
    }

    LocalStorage.set(
      LocalStorageKey.VISIBLE_FROM,
      startDayJs.subtract(1, timePeriod).toISOString()
    )
    LocalStorage.set(
      LocalStorageKey.VISIBLE_TO,
      endDayJs.add(1, timePeriod).toISOString()
    )

    calendarSync.sync()

    onDateRange({
      leftBound: startDayJs.unix(),
      rightBound: endDayJs.unix()
    })

    if (datesSetArg.view.type === 'dayGridMonth') {
      if (nowDayJs.isAfter(startDayJs) && nowDayJs.isBefore(endDayJs)) {
        LocalStorage.set(LocalStorageKey.VISIBLE_DATE, nowDayJs.valueOf())
      } else if (centerDayJs.month() != nowDayJs.month()) {
        LocalStorage.set(
          LocalStorageKey.VISIBLE_DATE,
          startDayJs
            .add(centerDayJs.month() != startDayJs.month() ? 1 : 0, 'week')
            .valueOf()
        )
      } else {
        if (startDayJs.month() === nowDayJs.month()) {
          LocalStorage.set(LocalStorageKey.VISIBLE_DATE, startDayJs.valueOf())
        } else {
          LocalStorage.set(
            LocalStorageKey.VISIBLE_DATE,
            startDayJs.add(1, 'week').valueOf()
          )
        }
      }
    } else {
      if (nowDayJs.isAfter(startDayJs) && nowDayJs.isBefore(endDayJs)) {
        LocalStorage.set(LocalStorageKey.VISIBLE_DATE, nowDayJs.valueOf())
      } else {
        LocalStorage.set(
          LocalStorageKey.VISIBLE_DATE,
          startDayJs
            .set('hour', nowDayJs.hour())
            .set('minute', 0)
            .set('second', 0)
            .add(1, 'hour')
            .valueOf()
        )
      }
    }
  }

  //update profiles each time group presented
  useEffect(() => {
    if (
      props.group &&
      props.group.Participants &&
      LocalStorage.get(LocalStorageKey.USER_ID)
    )
      getProfilesForParticipants(Object.keys(props.group.Participants || {}))
  }, [props.groupID])

  useEffect(() => {
    if (
      isGuest &&
      dateRange &&
      (LocalStorage.get(LocalStorageKey.CACHED_GROUP_ID_JOIN) ?? '').length > 0
    ) {
      getEventsForGroup(
        LocalStorage.get(LocalStorageKey.CACHED_GROUP_ID_JOIN) ?? '',
        dateRange.leftBound,
        dateRange.rightBound
      ).then((eventsAndGroupData) => {
        Logger.blue('guest group response', eventsAndGroupData)
        calendar.onGroupServer(eventsAndGroupData.group._id ?? '')
      })
    }
  }, [dateRange])

  const queryParams = new URLSearchParams(location.search)
  const param1 = queryParams.get('view') as CalendarRange

  const [updateEventError, setUpdateEventError] = useState<string | undefined>()

  const [droppedEventData, onDroppedEventData] = useState<
    DropEventData | undefined
  >(undefined)

  async function updateEventData(
    newEvent: EventApi,
    oldEvent: Model<EventDoc>
  ) {
    LocalStorage.remove(LocalStorageKey.PENDING_EVENT_DATA)
    const showingEvent = LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)

    Logger.pink('dropped', newEvent, oldEvent)
    Logger.pink(
      'will set',
      LocalStorage.get(LocalStorageKey.VISIBLE_EVENT) != null
    )

    if (oldEvent.someday === '1') {
      LocalStorage.set(
        LocalStorageKey.SOMEDAY_DATE,
        (newEvent.start?.valueOf() ?? 0) / 1000
      )
      LocalStorage.set(
        LocalStorageKey.SOMEDAY_ALL_DAY,
        newEvent.allDay ? '1' : '0'
      )
      return
    }

    if (showingEvent != null) {
      //only in case event was opened previously
      if (showingEvent.includes(oldEvent._id)) {
        LocalStorage.set(
          LocalStorageKey.VISIBLE_EVENT,
          `${oldEvent._id}${newEvent?.start?.valueOf()}`
        )
      } else {
        LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)
        RemoveTempEvents()
      }
    }
    const oldLocal = await localEventById(oldEvent._id)
    if (!oldLocal) return
    const oldStart = oldLocal.StartDate
    const oldEnd = oldLocal.EndDate

    const lenInDays = dayjs(Number(oldEnd) * 1000).diff(
      dayjs(Number(oldStart) * 1000),
      'day'
    )

    const newAlldayStart = dayjs
      .utc(dayjs(newEvent.startStr))
      .add(1, 'day')
      .startOf('day')
    const newAlldayEnd = newEvent.endStr
      ? dayjs.utc(dayjs(newEvent.endStr)).startOf('day')
      : newAlldayStart

    const start = newEvent.allDay
      ? newAlldayStart.unix().toString()
      : `${(newEvent.start?.valueOf() ?? 0) / 1000}`
    const end = newEvent.allDay
      ? newAlldayEnd
          .add(
            groupIdToProvider(oldLocal.GroupID ?? '') === ProviderType.APPLE ||
              oldLocal.Type === DocType.EVENT
              ? 1
              : 0,
            'day'
          )
          .unix()
          .toString()
      : (
          (newEvent.end ?? newEvent.start ?? new Date())?.valueOf() / 1000 +
          (newEvent.end ? 0 : 60 * 60)
        ).toString()

    console.log(dayjs.unix(Number(end)).toISOString())

    function updateLocalEvent() {
      return Database.write(async () => {
        return oldLocal.update((local) => {
          if (newEvent.start) {
            if (local.syncStatus != 'deleted') {
              local.StartDate = start
              local.EndDate = end
              local.AllDay = newEvent.allDay ? '1' : '0'
              local.TimeZoneNameID = newEvent.allDay
                ? 'UTC'
                : oldLocal.TimeZoneNameID ?? getDefaultTimezone()
            }
          }
        })
      })
    }

    if (oldLocal.syncStatus === 'deleted') return

    if (oldEvent && !oldEvent.Recurrence?.Unit) {
      updateLocalEvent().then(async (updatedLocal) => {
        if (updatedLocal._id.length > 0) {
          RemoveTempEvents()
          ;(calendar.ref as any).current?.getApi().scrollToTime(
            dayjs(newEvent.start)
              .tz(
                (oldEvent.TimeZoneNameID?.length ?? 0) > 0
                  ? oldEvent.TimeZoneNameID
                  : getDefaultTimezone()
              )
              .format('HH:mm:ss')
          )
          await updateEventOnServer(
            updatedLocal,
            Database.toServerObject<EventDoc>(updatedLocal) as EventDoc
          ).catch((e) => {
            if (e instanceof AxiosError) {
              if (e.response?.status === 400) {
                console.error(e)
              } else if (e.response?.status === 403) {
                setUpdateEventError(parseErrorToMessage(e))
                Database.write(async () => {
                  updatedLocal.update((local) => {
                    local.StartDate = oldStart
                    local.EndDate = oldEnd
                  })
                }).then(() => {
                  ;(calendar.ref as any).current?.getApi().scrollToTime(
                    dayjs(Number(oldStart) * 1000)
                      .tz(oldEvent.TimeZoneNameID)
                      .format('HH:mm:ss')
                  )
                })
              }
            }
          })
        }
      })
    } else {
      const data: DropEventData = {
        newEvent,
        oldEvent,
        oldAllDay: oldEvent.AllDay === '1',
        oldStart: Number(oldStart),
        oldEnd: Number(oldEnd),
        oldTimeZone: oldEvent.TimeZoneNameID ?? getDefaultTimezone(),
        newStart: Number(start),
        newEnd: Number(end)
      }
      updateLocalEvent().then(() => {
        onDroppedEventData(data)
        setShowRecurrenceDialog(true)
      })
    }
  }

  const dropCallback = (arg: EventDropArg) => {
    if (showSyncIfNeeded()) {
      return
    }
    updateEventData(arg.event, arg.oldEvent.extendedProps.eventModel)
  }

  const resizeCallback = (arg: EventResizeDoneArg) => {
    console.log('resize callback', arg)

    if (showSyncIfNeeded()) {
      return
    }

    updateEventData(arg.event, arg.oldEvent.extendedProps.eventModel)
  }

  const [showRecurrenceDialog, setShowRecurrenceDialog] = useState(false)
  const handleCloseRecurringSave = () => {
    setShowRecurrenceDialog(false)
  }

  const handleSaveRecurringEvent = async (type: RECUR_SAVE_TYPE) => {
    Logger.pink('handle on calendar type; data', type, droppedEventData)

    if (droppedEventData) {
      const newStart = dayjs(droppedEventData.newEvent.start)
      const newEnd = droppedEventData.newEvent.end
        ? dayjs(droppedEventData.newEvent.end)
        : newStart.add(1, 'hour')
      const isAllDay = droppedEventData.newEvent.allDay
      const oldData = Database.toServerObject<EventDoc>(
        droppedEventData.oldEvent
      )

      const newValues: EventDoc = {
        ...oldData,

        StartDate: `${droppedEventData.newStart}`,
        EndDate: `${droppedEventData.newEnd}`,
        AllDay: isAllDay ? '1' : '0',
        TimeZoneNameID:
          droppedEventData.oldEvent.AllDay === '1'
            ? getDefaultTimezone()
            : droppedEventData.oldEvent.TimeZoneNameID,
        SupplementaryGroupsIDs: oldData.SupplementaryGroupsIDs ?? []
      }

      switch (type) {
        case RECUR_SAVE_TYPE.ALL:
          if (droppedEventData) {
            await handleEventUpdate(
              droppedEventData.oldEvent,
              newValues,
              false,
              true
            )
          }
          break
        case RECUR_SAVE_TYPE.THIS:
          break
      }
    }

    setShowRecurrenceDialog(false)
  }

  const visibleAreaStart = dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_FROM))
  const visibleAreaEnd = dayjs(LocalStorage.get(LocalStorageKey.VISIBLE_TO))

  const filtered = props.events
    .filter(
      (event) => archivedGroups.find((id) => id === event.GroupID) === undefined
    )
    .filter(
      (event) =>
        String(event.Status) != EventStatus.DELETED &&
        String(event.Status) != EventStatus.REMOVED
    )
    .filter((event) => {
      const eStart = dayjs(Number(event.StartDate) * 1000)
      const eEnd = dayjs(Number(event.EndDate) * 1000)

      if (isSomeDay(event)) return true
      if (isLate(event)) return true

      const isEventRecurrenceValid =
        event.Recurrence != null &&
        event.Recurrence != undefined &&
        typeof event.Recurrence !== 'string' &&
        (!event.Recurrence.EndDate ||
          Number(event.Recurrence.EndDate) >= visibleAreaStart.unix())

      return (
        isEventRecurrenceValid ||
        isEventWithinTimePeriod(event, visibleAreaStart, visibleAreaEnd)
      )
    })
    .filter((event) => {
      if (
        event._id.length > 0 &&
        isGroupHidden(props.userSettings, event.GroupID ?? '') &&
        ((event.SupplementaryGroupsIDs?.length ?? 0) == 0 ||
          event.SupplementaryGroupsIDs?.every((id) =>
            isGroupHidden(props.userSettings, id)
          )) &&
        event.GroupID != props.groupID
      ) {
        return false
      } else {
        return true
      }
    })
    .filter((event) => {
      if (props.showOnlySelectedGroup) {
        return event.GroupID === props.groupID
      } else return true
    })
  const eventsForCalendar = mapEventsToCalendar(
    filtered,
    props.groups,
    props.account
  )

  Logger.debug('events for calendar', eventsForCalendar)

  function dateSelect(arg: DateSelectArg) {
    Logger.pink('DateSelect', arg)
    if (showSyncIfNeeded()) {
      return
    }
    const allDayOffset = 0 //24 * 60 * 60
    RemoveTempEvents()
      .then(() =>
        addEventToDate(
          EventProperties.EVENT_FROM_SCREEN,
          props.group?._id,
          arg.allDay
            ? dayjs(arg.startStr).add(12, 'hour').tz('UTC').unix()
            : arg.start.valueOf() / 1000,
          (arg.end.valueOf() - (arg.allDay ? allDayOffset : 0)) / 1000,
          arg.allDay,
          props.userSettings
        )
      )
      .then((e) => {
        Logger.orange('added', e)
        ;(calendar.ref as any).current?.getApi().unselect()
      })
  }

  const [haveActiveCalendar, setHaveActiveCalendar] = useState(false)
  const [showEventAddingNotPossible, onShowEventAddingNotPossible] =
    useState(false)

  const handleAddEvent = async (startEnd: StartEndEvent) => {
    if (showSyncIfNeeded()) {
      return
    }
    if (LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)) return

    if (isGroupcalApp() && (!haveActiveCalendar || props.groups?.length == 0)) {
      onShowEventAddingNotPossible(true)
      return
    }

    if (isOutOfTier) {
      onShowOutOfTierDialog(true)
    } else if (
      !isUserCanOperateEvents(
        props.group ?? props.firstGroup,
        LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
      )
    ) {
      showCanAddEventsDialog()
    } else if (userRemoved) {
      showUserRemoved()
    } else {
      await RemoveTempEvents()

      calendar.onScrollToTime(
        dayjs(startEnd.start * 1000).tz(getDefaultTimezone())
      )

      if (
        LocalStorage.get(LocalStorageKey.CALENDAR_VIEW) === CalendarRange.MONTH
      ) {
        await addEventToDate(
          EventProperties.EVENT_FROM_SCREEN,
          props.group?._id,
          startEnd.start,
          startEnd.end,
          false,
          props.userSettings
        )
      } else {
        setTimeout(async () => {
          await addEventToDate(
            EventProperties.EVENT_FROM_SCREEN,
            props.group?._id,
            startEnd.start,
            startEnd.end,
            false,
            props.userSettings
          )
        }, 500) // to prevent 'blinking' of an event info popup we first scroll then add pending event to a ui
      }
    }
  }

  useEffect(() => {
    isUserHaveGroupToAddEvents().then((groupId) => {
      if (groupId) {
        setHaveActiveCalendar(true)
      } else {
        setHaveActiveCalendar(false)
      }
    })
  }, [props.groups])

  return (
    <Styled.CalendarView>
      <Styled.CalendarViewScrollContainer>
        <Styled.CalendarViewScroll data-calendar={calendar.view}>
          <TfmFullCalendar
            userSettings={props.userSettings}
            dateSelect={dateSelect}
            account={props.account}
            group={props.group}
            dropCallback={dropCallback}
            resizeCallback={resizeCallback}
            dayNameFormat={props.dayNameFormat}
            initialView={
              CALENDAR_VIEW[props.defaultView ?? param1 ?? calendar.view]
            }
            events={
              calendar.view === 'CALENDAR_RANGE/LISTWEEK'
                ? []
                : eventsForCalendar
            }
            calRef={calendar.ref}
            onDatesSet={onDatesSet}
            emptyDateClick={emptyDateClick}
            handleAddEvent={handleAddEvent}
          />

          {calendar.view === 'CALENDAR_RANGE/LISTWEEK' ? (
            <div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }}
            >
              <ListView
                groupId={props.group?._id}
                userSettings={props.userSettings}
                dayNameFormat={props.dayNameFormat}
                handleAddEvent={handleAddEvent}
                events={props.events}
              />
            </div>
          ) : (
            <></>
          )}
        </Styled.CalendarViewScroll>
      </Styled.CalendarViewScrollContainer>
      {props.readOnly ? (
        <></>
      ) : (
        <AddEventButton
          handleAddEvent={handleAddEvent}
          onShowOutOfTier={onShowOutOfTierDialog}
          showUserBlockedFromAdding={showCanAddEventsDialog}
          userRemoved={userRemoved}
          showUserRemoved={showUserRemoved}
          groupOutOfTier={isOutOfTier}
          {...props}
        />
      )}
      {userRemoved && showUserRemovedDialog && (
        <ConfirmationDialog
          rightButtonHidden
          title={`${t('youDontHavePermissions')}`}
          content={''}
          leftButton={'OK'}
          leftButtonColor={APP_COLOR}
          handleLeftButton={hideUserRemovedDialog}
          handleClose={hideUserRemovedDialog}
          open={showUserRemovedDialog}
        />
      )}
      {userCannotAddEvents && (
        <ConfirmationDialog
          rightButtonHidden
          title={`Can't Do That`}
          content={'Only calendar admins can add events to this calendar.'}
          leftButton={'OK'}
          leftButtonColor={APP_COLOR}
          handleLeftButton={hideCanAddEventsDialog}
          handleClose={hideCanAddEventsDialog}
          open={userCannotAddEvents}
        />
      )}
      {showOutOfTierDialog && (
        <OutOfTierDialog
          flow={BlockedFlow.addItem}
          account={props.account}
          group={props.group}
          setOpen={onShowOutOfTierDialog}
          opened={showOutOfTierDialog}
        />
      )}
      {showRecurrenceDialog && (
        <RecurrentActionDialog
          disableOthers
          handleLeftButton={() => {
            console.log(droppedEventData)
            Database.write(async () => {
              if (typeof droppedEventData?.oldEvent.update === 'function') {
                droppedEventData?.oldEvent.update((local) => {
                  local.StartDate = `${droppedEventData?.oldStart}`
                  local.EndDate = `${droppedEventData?.oldEnd}`
                  local.AllDay = droppedEventData?.oldAllDay ? '1' : '0'
                  local.TimeZoneNameID = droppedEventData.oldTimeZone
                })
              }
            })
          }}
          default={RECUR_SAVE_TYPE.ALL}
          open={showRecurrenceDialog}
          handleClose={handleCloseRecurringSave}
          handleSaveRecurringEvent={handleSaveRecurringEvent}
        />
      )}
      {updateEventError && (
        <ConfirmationDialog
          title={`${t('cantDoThat')}`}
          content={updateEventError}
          leftButtonHidden
          rightButton={`${t('ok')}`}
          handleClose={() => {
            setUpdateEventError(undefined)
          }}
          open={updateEventError != undefined}
        />
      )}
      {showEventAddingNotPossible && (
        <ConfirmationDialog
          title={''}
          content={t('toAddEvents')}
          leftButtonHidden
          rightButton={`${t('ok')}`}
          rightButtonColor={APP_COLOR}
          handleRightButton={() => {
            onShowEventAddingNotPossible(false)
          }}
          open
          handleClose={() => {}}
        />
      )}
      <TierEndDialog account={props.account} />
      {showCalendarSync && (
        <ResyncCalendarDialog
          isOpen={showCalendarSync}
          setOpen={onShowCalendarSync}
          group={props.group}
        />
      )}
    </Styled.CalendarView>
  )
}

export function isEventWithinTimePeriod(
  event: EventDoc,
  visibleAreaStart: Dayjs,
  visibleAreaEnd: Dayjs
): boolean {
  const itemStartDate = dayjs(Number(event.StartDate) * 1000)
  const itemEndDate = dayjs(Number(event.EndDate) * 1000).subtract(
    isConvertedGroupId(event.GroupID) && event.AllDay === '1' ? 1 : 0,
    'day'
  )

  if (isToday(visibleAreaStart.toDate()) && event.lateOriginalStartTime) {
    return true // all late events should be shown under today
  }

  const visibleStartCheck =
    itemStartDate.isSameOrAfter(visibleAreaStart) &&
    itemStartDate.isSameOrBefore(visibleAreaEnd)
  const visibleEndCheck =
    itemStartDate.isSameOrBefore(visibleAreaStart) &&
    itemEndDate.isSameOrAfter(visibleAreaStart) &&
    itemEndDate.isSameOrBefore(visibleAreaEnd)
  const startBeforeEndAfterCheck =
    itemStartDate.isBefore(visibleAreaStart) &&
    itemEndDate.isAfter(visibleAreaEnd)

  return (
    visibleStartCheck || // event starts within visible area
    visibleEndCheck || // event ends within visible area
    startBeforeEndAfterCheck // event starts before and ends after visible area
  )
}

interface TierEndDialogProps {
  account: AccountDoc
}

function TierEndDialog(props: TierEndDialogProps) {
  const payment = usePayment()

  const [trialProduct, onTrialProduct] = useState<AccountProduct | undefined>(
    undefined
  )

  function checkTrialDialog() {
    const trialProduct = exhaustedProduct(props.account)

    if (
      trialProduct &&
      props.account?.Products?.filter((product) => product.Status != 'trialing')
        .length === 0 // meaning we don't have any other products
    ) {
      const delta = dayjs().diff(
        dayjs(Number(trialProduct.ExpiryDate) * 1000),
        'month'
      )

      if (
        delta < 3 &&
        LocalStorage.get(LocalStorageKey.EXPIRY_DIALOG_SHOWN) != null &&
        LocalStorage.get(LocalStorageKey.EXPIRY_DIALOG_SHOWN) != '1'
      ) {
        LocalStorage.set(LocalStorageKey.EXPIRY_DIALOG_SHOWN, '1')
        onTrialProduct(trialProduct)
        onShowDialog(trialProduct != undefined)
      }
    }
  }

  const [showDialog, onShowDialog] = useState(false)
  //each minute check if we have expired product
  useEffect(() => {
    checkTrialDialog()

    const interval = setInterval(() => {
      checkTrialDialog()
    }, 60000)

    return () => clearInterval(interval)
  }, [props.account])

  const closeDialog = () => {
    onShowDialog(false)
  }

  const openPurchase = () => {
    payment.showPaymentDialog(null)
    closeDialog()
  }

  return (
    <>
      {trialProduct && showDialog && (
        <ConfirmationDialog
          title={t('trialExpired')}
          content={t('yourTrialExpirationDate', {
            date: dayjs(Number(trialProduct.ExpiryDate) * 1000)
              .add(10, 'minute')
              .format('dddd, DD MMM YYYY, HH:mm')
          })}
          rightButton={`${t('purchaseAPlan')}`}
          leftButton={`${t('donwgradeToBasic')}`}
          leftButtonColor={'black'}
          rightButtonColor={APP_COLOR}
          handleLeftButton={closeDialog}
          handleRightButton={openPurchase}
          open={showDialog}
          handleClose={() => {}}
        />
      )}
    </>
  )
}

export interface TfmFullCalendarProps {
  dateSelect: ((arg: DateSelectArg) => void) | undefined
  initialView: string
  height?: number
  emptyDateClick?: (arg: DateClickArg) => void
  onDatesSet?: (datesSetArg: DatesSetArg) => void
  events: EventSourceInput
  calRef?: Ref<FullCalendar>
  dayNameFormat?: string
  dropCallback: (arg: EventDropArg) => void
  resizeCallback: (arg: EventResizeDoneArg) => void
  account: AccountDoc
  group: GroupDoc
  userSettings: UserSettingsDoc
  handleAddEvent: (startEnd: StartEndEvent) => void
}

export function TfmFullCalendar(props: TfmFullCalendarProps) {
  useEffect(() => {
    const divsToAnimate = document.querySelectorAll('.animate-opacity')
    divsToAnimate.forEach((div) => div.classList.add('animate-opacity'))
  }, [])

  const handleEventOrder = (a: any, b: any) => {
    const groupcalA = a.extendedProps?.eventModel as EventDoc
    const groupcalB = b.extendedProps?.eventModel as EventDoc

    const lenA = dayjs(
      groupcalA.late
        ? groupcalA.lateOriginalStartTime
        : Number(groupcalA.EndDate)
    ).diff(dayjs(Number(groupcalA.StartDate)))
    const lenB = dayjs(
      groupcalA.late ? groupcalA.lateOriginalEndTime : Number(groupcalB.EndDate)
    ).diff(dayjs(Number(groupcalB.StartDate)))

    const selectedGroup: string | null = LocalStorage.get(
      LocalStorageKey.SELECTED_GROUP
    )

    switch (LocalStorage.get(LocalStorageKey.CALENDAR_VIEW)) {
      case CalendarRange.MONTH:
        // Move all day events up
        if (groupcalA.allDay === '1' && lenA > lenB) {
          return -1
        }
        if (groupcalB.allDay === '1') {
          return 1
        }
        if (groupcalB.GroupID === selectedGroup) {
          return 1
        } else {
          return -1
        }
      case CalendarRange.WEEK:
      case CalendarRange.DAY:
      case CalendarRange.LISTWEEK:
        if (b.start < a.start) {
          return 1
        } else if (b.start > a.start) {
          return -1
        }
    }

    return 0
  }

  const [closeAll, onCloseAll] = useState(false)

  const [showEventsDate, onShowEventsDate] = useState<Date | undefined>()

  useEffect(() => {
    if (showEventsDate) {
      LocalStorage.set(LocalStorageKey.MORE_EVENTS_OPENED, '1')
    } else {
      setTimeout(() => {
        LocalStorage.remove(LocalStorageKey.MORE_EVENTS_OPENED)
      }, 250)
    }
  }, [showEventsDate])

  const appSettings = useAppSettings()

  const targetDivRef = React.useRef<HTMLDivElement>(null)

  function firstDayForFullCalendar(
    firstDayOfTheWeek: number
  ): number | undefined {
    switch (firstDayOfTheWeek) {
      case FIRST_DAYS.SUNDAY:
        return 0
      case FIRST_DAYS.MONDAY:
        return 1

      default:
        return 0
    }
  }

  // Create a Map to store roots
  const roots = new Map()

  const calendar = useCalendar()

  return (
    <FullCalendar
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        rrulePlugin,
        listPlugin,
        momentTimezonePlugin
      ]}
      timeZone={appSettings.timeZone}
      initialView={props.initialView}
      firstDay={firstDayForFullCalendar(appSettings.firstDayOfTheWeek)}
      height={'inherit'}
      headerToolbar={false}
      snapDuration={{ minutes: 15 }}
      eventStartEditable
      eventResizableFromStart
      editable={
        LocalStorage.get(LocalStorageKey.SELECTED_GROUP) === '' ||
        isGroupNotAvailableForEventChanges(props.group, props.account) === false
      }
      droppable={
        LocalStorage.get(LocalStorageKey.SELECTED_GROUP) === '' ||
        isGroupNotAvailableForEventChanges(props.group, props.account) === false
      }
      eventDrop={(arg) => {
        props.dropCallback(arg)
        onCloseAll(false)
      }}
      selectOverlap
      selectMirror
      selectMinDistance={25}
      select={props.dateSelect}
      eventResize={props.resizeCallback}
      selectable={true}
      dateClick={props.emptyDateClick}
      moreLinkContent={(e) => {
        return <MoreLink num={e.num} />
      }}
      moreLinkClick={(e) => {
        RemoveTempEvents().then(() => {
          // Get the target element
          const target = e.jsEvent.target as HTMLElement

          // Check if a root already exists for this element
          let root = roots.get(target)

          // If no root exists, create one and store it in the Map
          if (!root) {
            root = createRoot(target)
            roots.set(target, root)
          }

          if (LocalStorage.get(LocalStorageKey.MORE_EVENTS_OPENED) === '1') {
            root.render(
              <span>
                <MoreLink num={e.hiddenSegs.length} />
              </span>
            )
            return
          }

          if (LocalStorage.get(LocalStorageKey.VISIBLE_EVENT)) {
            onShowEventsDate(undefined)
            RemoveTempEvents()
            LocalStorage.remove(LocalStorageKey.VISIBLE_EVENT)

            return
          }

          onShowEventsDate(e.date)

          root.render(
            <div>
              <ThemeProvider theme={theme}>
                <NotificationProvider>
                  <IcloudProvider>
                    <CalendarSyncProvider>
                      <EventsListPopup
                        onAway={() => {
                          onShowEventsDate(undefined)
                        }}
                        key={'list-popup'}
                        eventDrawer={(eventSegment) => {
                          return (
                            <Box>
                              <Event
                                alwaysFullSize={false}
                                onEventDetailsClose={() => {
                                  root.render(
                                    <MoreLink num={e.hiddenSegs.length} />
                                  )
                                }}
                                isStart={eventSegment.isStart}
                                instanceStart={
                                  Number(
                                    eventSegment.event?.extendedProps
                                      ?.eventModel.StartDate
                                  ) * 1000
                                }
                                instanceEnd={
                                  Number(
                                    eventSegment.event?.extendedProps
                                      ?.eventModel.EndDate
                                  ) * 1000
                                }
                                event={
                                  eventSegment.event?.extendedProps?.eventModel
                                }
                                title={
                                  eventSegment.event.extendedProps?.eventModel
                                    .Text
                                }
                              />
                            </Box>
                          )
                        }}
                        events={e.hiddenSegs}
                        root={root}
                      >
                        <span>
                          <MoreLink num={e.hiddenSegs.length} />
                        </span>
                      </EventsListPopup>
                    </CalendarSyncProvider>
                  </IcloudProvider>
                </NotificationProvider>
              </ThemeProvider>
            </div>
          )
        })
      }}
      dayMaxEvents
      eventDragStart={(e) => {
        console.log('drag start', e)
        LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')
        onCloseAll(true)
      }}
      eventDragStop={() => {
        onCloseAll(false)
        LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
      }}
      eventResizeStart={() => {
        LocalStorage.set(LocalStorageKey.SHOULD_KEEP_POPUP, '1')
        onCloseAll(true)
      }}
      eventResizeStop={() => {
        onCloseAll(false)
        LocalStorage.remove(LocalStorageKey.SHOULD_KEEP_POPUP)
      }}
      direction={i18n.dir()}
      nowIndicator
      datesSet={props.onDatesSet}
      slotDuration={{ hours: 1 }}
      slotLabelInterval={{ minutes: 30 }}
      eventOrder={'-allDay,-duration'}
      moreLinkText={'more'}
      eventBackgroundColor={'transparent'}
      eventBorderColor={'transparent'}
      dayHeaderClassNames={dayHeaderClassNames}
      dayHeaderContent={(e) => {
        return (
          <DayHeaderContent
            groupId={props.group?._id}
            userSettings={props.userSettings}
            dayNameFormat={props.dayNameFormat}
            handleAddEvent={props.handleAddEvent}
            {...e}
          />
        )
      }}
      dayCellContent={DayCellContent}
      eventContent={(e) => {
        const event = e.event.extendedProps?.eventModel as EventDoc
        return (
          <div
            id={`${e.event.id}_${dayjs(e.event.start ?? Date()).format(
              'YYYY-MM-DD'
            )}`}
            ref={event && event._id?.length === 0 ? targetDivRef : undefined}
            style={{ height: '100%', width: '100%' }}
          >
            <EventContent closeAll={closeAll} {...e} />
          </div>
        )
      }}
      allDayContent={AllDayContent}
      slotLabelContent={SlotLabelContent}
      events={props.events}
      ref={props.calRef}
    />
  )
}

export async function addEventToDate(
  source: string,
  groupId: string | undefined,
  startTime: number,
  endTime: number,
  allDay: boolean,
  userSettings?: UserSettingsDoc
) {
  const veryFirstGroup = await provideFirstGroup()

  Analytics.getInstance().sendEvent(Events.EVENT_START_ADD_EVENT, {
    [EventProperties.LOCATION_ADD_EVENT]: source
  })

  LocalStorage.set(LocalStorageKey.VISIBLE_EVENT, startTime * 1000)
  let thirdPartyId: string | undefined = undefined
  let groupIdForEvent =
    groupId ?? defaultGroupId(userSettings) ?? veryFirstGroup?._id

  if (groupIdForEvent === '' || !groupIdForEvent) {
    groupIdForEvent = defaultGroupId(userSettings) ?? veryFirstGroup?._id
  }
  if (isConvertedGroupId(groupIdForEvent)) {
    const provider = groupIdToProvider(groupIdForEvent ?? '')

    if (provider === ProviderType.GOOGLE) {
      thirdPartyId = CurrentConvertedCalendars.find(
        (calendar) =>
          calendar.prefix === prefixGoogleEventsGroup &&
          calendar.primaryCalendarEmail === removePrefix(groupIdForEvent ?? '')
      )?.primaryCalendarEmail
    } else if (provider === ProviderType.OUTLOOK) {
      thirdPartyId = CurrentConvertedCalendars.find(
        (calendar) => calendar.prefix === outlookPrefix && calendar.primary
      )?.id
    } else if (provider === ProviderType.APPLE) {
      thirdPartyId = CurrentConvertedCalendars.find(
        (calendar) => calendar.prefix === icloudPrefix && calendar.primary
      )?.id
    }
  }

  if (!userSettings) {
    return await isUserHaveGroupToAddEvents().then((groupId) => {
      return addTempEventToDate(
        startTime,
        endTime,
        groupId,
        allDay,
        is24meApp()
          ? groupId
            ? DocType.REGULAR_EVENT
            : DocType.TASK
          : DocType.EVENT,
        undefined,
        thirdPartyId
      )
    })
  }

  return await addTempEventToDate(
    startTime,
    endTime,
    groupIdForEvent,
    allDay,
    is24meApp()
      ? veryFirstGroup
        ? DocType.REGULAR_EVENT
        : DocType.TASK
      : DocType.EVENT,
    undefined,
    thirdPartyId
  )
}

interface AddEventButtonProps extends CalendarViewProps {
  showUserRemoved: () => void
  userRemoved: boolean
  showUserBlockedFromAdding: () => void
  onShowOutOfTier: (b: boolean) => void
  groupOutOfTier: boolean
  handleAddEvent: (startEnd: StartEndEvent) => void
}

function AddEventButton(props: AddEventButtonProps) {
  return (
    <>
      <Styled.AddEventButton
        id="add-event-button"
        onClick={() => {
          const startEnd = generateStartEndBasedOnTimeWithShift(
            Number(LocalStorage.get(LocalStorageKey.VISIBLE_DATE)) / 1000
          )

          props.handleAddEvent(startEnd)
        }}
      >
        <AddIcon />
        <span>{t('event')}</span>
      </Styled.AddEventButton>
    </>
  )
}

export default compose(
  withObservables([], () => ({
    account: ObserveService.Account()
  })),
  withObservables([], () => ({
    userSettings: ObserveService.UserSettings()
  })),
  withObservables([], (props: CalendarViewProps) => ({
    firstGroup: ObserveService.FirstGroup(props.userSettings)
  })),
  withObservables(['groupID'], (props: CalendarViewProps) => ({
    group: ObserveService.GroupByServerIDRestricted(props.groupID)
  })),
  withObservables([], (props: CalendarViewProps) => ({
    groups: ObserveService.Groups()
  })),
  withObservables(
    ['groupID', 'userSettings', 'visibleFrom'],
    (props: CalendarViewProps) => ({
      events: ObserveService.Events(
        props.userSettings,
        undefined,
        props.visibleFrom,
        props.visibleTo
      )
    })
  )
  // @ts-ignore
)(CalendarView as unknown as ComponentType<CalendarViewProps>) as any

interface MoreLinkProps {
  num: Number
}

const MoreLink = (props: MoreLinkProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {t('moreAmount', { more: props.num })}
    </Box>
  )
}
