import { Box, Grid, capitalize } from '@mui/material'
import Popup from 'components/popup/Popup'
import { ChangeEvent, ReactNode, useState } from 'react'
import { MasterLabel, removeTempLabel } from 'services/db/Queries/MasterLabel'
import {
  AddGroupInput,
  AddGroupInputContainer,
  GroupPopupInputCount,
  PopupCloseButton
} from './styled'
import { CloseIcon, DeleteIcon } from 'assets/icons'
import { t } from 'i18next'
import { theme } from 'theme'

import ColorsPopup from './ColorsPopup'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import Button from 'components/buttons/Button'
import { LoadingSpinner } from 'components/containers/styled'
import { getRandomColor } from 'utils/colors'
import dayjs from 'dayjs'
import { MasterLabelLabel } from 'types/api/changes'
import {
  addLabelToMasterLabel,
  convertHexToRGBALabelColor,
  editLabelInMasterLabel,
  removeLabelFromMasterLabel
} from 'utils/api/master_label'
import { convertLabelColorToStringColor } from 'utils/masterLabel'
import Database from 'services/db/Database'
import { is24meApp } from 'utils/appType'
import { Tier } from 'utils/api/accounts'
import { useAppUIType } from 'components/providers/AppUITypeProvider'
import IconButton from 'components/buttons/IconButton'

interface AddLabelPopupProps {
  children: ReactNode
  open: boolean
  setOpen: (b: boolean) => void
  label: MasterLabelLabel
  edit?: boolean
}

export default function AddLabelPopup(props: AddLabelPopupProps) {
  const appUiTypeContext = useAppUIType()

  return (
    <Popup
      onClickAwayAction={() => {
        props.setOpen(false)
        appUiTypeContext.setLabelInfo(undefined)
        removeTempLabel()
      }}
      placement="right-start"
      open={props.open}
      setOpen={props.setOpen}
      content={<Content {...props} />}
    >
      {props.children}
    </Popup>
  )
}

interface ContentProps extends AddLabelPopupProps {}

function Content(props: ContentProps) {
  const [title, setTitle] = useState<string>(props.label.Text)
  const [saving, setSaving] = useState(false)
  const onLabelTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }
  const appUiTypeContext = useAppUIType()

  const [color, setColor] = useState<string>(props.label.Color)
  const [showColorPopup, setShowColorPopup] = useState(false)

  function showColors() {
    setShowColorPopup(true)
  }

  const handleLabelSave = () => {
    setSaving(true)

    if (props.edit) {
      editLabelInMasterLabel({
        ...props.label,
        Text: title,
        Color: color
      }).then(() => {
        props.setOpen(false)
        appUiTypeContext.setLabelInfo(undefined)
      })
    } else {
      addLabelToMasterLabel(title, color)
    }
    setSaving(false)
  }

  function setColorToLabel(color: string) {
    setShowColorPopup(false)
    if (!props.edit)
      Database.write(async () => {
        return MasterLabel().then((label) => {
          label.update((local) => {
            local.Labels = local.Labels.map((label) => {
              if (label.ID === 'temp') {
                return { ...label, Color: convertHexToRGBALabelColor(color) }
              } else return label
            })
          })
        })
      })

    setColor(convertHexToRGBALabelColor(color))
  }

  function removeLabel() {
    setSaving(true)
    removeLabelFromMasterLabel(props.label)
      .then(() => {
        props.setOpen(false)
        appUiTypeContext.setLabelInfo(undefined)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <Grid
      container
      display={'flex'}
      flexDirection={'column'}
      padding={theme.spacing(0, 4)}
    >
      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        wrap={'nowrap'}
        justifyContent={'space-between'}
      >
        <PopupCloseButton
          size="sm"
          onClick={() => {
            props.setOpen(false)
            removeTempLabel()
            appUiTypeContext.setLabelInfo(undefined)
          }}
        >
          <CloseIcon />
        </PopupCloseButton>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          {props.edit && (
            <IconButton onClick={removeLabel} size="md">
              <DeleteIcon />
            </IconButton>
          )}
          <div style={{ opacity: saving ? 0 : 1 }}>
            <Button
              disabled={title.length === 0}
              size="xs"
              onClick={handleLabelSave}
            >
              {t('save')}
            </Button>
          </div>
          <LoadingSpinner
            loading={saving ? 1 : 0}
            style={{
              pointerEvents: saving ? 'all' : 'none',
              right: '40px',
              top: '15px'
            }}
          />
        </Box>
      </Grid>

      <AddGroupInputContainer>
        <AddGroupInput
          sx={{ marginLeft: '0px !important' }}
          shouldNotFocus={false}
          maxLength={25}
          value={title}
          onChange={onLabelTitleChange}
          placeholder={`${t('newLabel')}`}
          variant="popup"
        />
        <GroupPopupInputCount
          sx={{
            left: 16,
            top: '16px !important',
            marginRight: 2,
            borderBottom: '1px solid #e4e4e4'
          }}
        >{`${25 - title.length}`}</GroupPopupInputCount>
      </AddGroupInputContainer>
      <div style={{ marginLeft: 8, marginRight: 4, marginTop: 8 }}>
        <PopupSectionRow
          title={capitalize(`${t('color')}`)}
          neededTier={is24meApp() ? Tier.PRO : undefined}
          action={showColors}
          value={
            <ColorsPopup
              open={showColorPopup}
              setOpen={setShowColorPopup}
              color={convertLabelColorToStringColor(color)}
              setColor={setColorToLabel}
            />
          }
        />
      </div>
    </Grid>
  )
}
