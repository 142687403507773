import {
  EventDatePicker,
  PickerType
} from 'components/eventComponents/EventDatePicker'
import { IEventDetails } from 'hooks/useEventDetails'
import { Controller } from 'react-hook-form'
import Logger from 'services/Logger'
import { EventDoc } from 'types/api/changes'

interface EndDateProps {
  eventHook: IEventDetails
  event: EventDoc
}

export default function EndDate(props: EndDateProps) {
  const { eventHook, event } = { ...props }
  console.log(eventHook)
  return (
    <Controller
      name="EndDate"
      control={eventHook.control}
      defaultValue={props.event.EndDate}
      render={({ field }) => (
        <EventDatePicker
          shouldShowTimeDropdown
          eventHook={props.eventHook}
          pickerType={PickerType.END}
          allDay={eventHook.getValues('AllDay') === '1'}
          setStartTime={eventHook.setStartTime}
          startTime={Number(eventHook.startTime) * 1000}
          setEndTime={eventHook.setEndTime}
          endTime={Number(field.value) * 1000}
          timeZone={eventHook.getValues('TimeZoneNameID')}
          setTimeZone={eventHook.setTimeZone}
          duration={
            Number(eventHook.endTime) * 1000 -
            Number(eventHook.startTime) * 1000
          }
        />
      )}
    />
  )
}
