import { MasterLabelDoc } from 'types/api/changes'
import { Q } from '@nozbe/watermelondb'

import { CollectionType } from 'types/model'
import Database from '../Database'
import { convertHexToRGBALabelColor } from 'utils/api/master_label'
import { getRandomColor } from 'utils/colors'

export function addTempLabel() {
  return MasterLabel().then((label) => {
    return Database.write(async () => {
      return label.update((local) => {
        local.Labels = [
          {
            ID: 'temp',
            Color: '{0.000, 0.000, 0.000, 0.000}',
            Text: ''
          },
          ...local.Labels
        ]
      })
    })
  })
}

export function removeTempLabel() {
  return MasterLabel().then((label) => {
    return Database.write(async () => {
      return label.update((local) => {
        local.Labels = [...local.Labels.filter((mLabel) => mLabel.ID != 'temp')]
      })
    })
  })
}

export const MasterLabel = async () => {
  const masterLabel = await Database.getCollection<MasterLabelDoc>(
    CollectionType.MASTER_LABEL
  )
    .query(Q.take(1))
    .fetch()
  return masterLabel[0]
}
