import { Box, Grid, Typography } from '@mui/material'
import {
  GenericErrorPic,
  logo,
  PointerRight,
  tfmLogo,
  tfmLogoText
} from 'assets/icons'
import { theme } from 'theme'
import {
  AppTitleContainer,
  GenericErrorContainer,
  RefreshPageButton
} from './styled'

import { t } from 'i18next'
import { isGroupcalApp } from 'utils/appType'
import { OnboardingIcon24me } from 'assets/img'

interface GenericErrorPageProps {
  title?: string
  subTitle?: string
}

export default function GenericErrorPage(props: GenericErrorPageProps) {
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <GenericErrorContainer container>
      <AppTitleContainer>
        <img
          height={36}
          width={isGroupcalApp() ? 36 : undefined}
          src={isGroupcalApp() ? logo : tfmLogoText}
        />
        {isGroupcalApp() ? 'GroupCal' : ''}
      </AppTitleContainer>
      <GenericErrorPic
        style={{
          display: 'block',
          height: '50vh'
        }}
      />
      <Typography fontSize={42} lineHeight={'54px'} fontFamily={'Inter'}>
        Oops. Something went wrong
      </Typography>
      <RefreshPageButton onClick={refreshPage}>
        <Typography
          color={
            isGroupcalApp() ? theme.palette.button.text.darkRed : undefined
          }
          fontFamily={'Inter'}
          fontWeight={600}
        >
          {t('takeMeBack')}
        </Typography>
        <PointerRight color={theme.palette.text.primary} />
      </RefreshPageButton>
    </GenericErrorContainer>
  )
}
