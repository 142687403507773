import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import { updateUserSettings } from 'services/api/userSettings'
import { theme } from 'theme'
import { UserSettingsDoc } from 'types/api/changes'
import PickLanguagePopup, { langs } from '../PickLanguagePopup'
import SelectItemWithSearchPopup from '../SelectItemWithSearchPopup'
import { days } from './UserMenuPopup'
import { useAppSettings } from 'components/providers/AppSettingsProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Database from 'services/db/Database'
import { Model } from 'types/model'
import { MenuFirstDayIcon, MenuLanguageIcon } from 'assets/icons/menu'

export interface SettingsSectionProps {
  userSettings: Model<UserSettingsDoc> | undefined
  setMenuOpen: (b: boolean) => void
}

export default function SettingsSection(props: SettingsSectionProps) {
  const { t, i18n } = useTranslation()
  const appSettings = useAppSettings()

  const [showLanguagePopup, onShowLanguagePopup] = useState(false)
  const [showFirstDayPopup, onShowFirstDayPopup] = useState(false)

  const showLangauge = () => {
    onShowLanguagePopup(true)
  }

  const onLangChanged = (lang: string) => {
    window.location.reload()
    i18n.changeLanguage(lang)
  }

  const showFirstDay = () => {
    onShowFirstDayPopup(true)
  }

  return (
    <PopupSection title={`${t('settings')}`}>
      <PickLanguagePopup
        open={showLanguagePopup}
        setOpen={onShowLanguagePopup}
        selectItem={onLangChanged}
      >
        <span onClick={showLangauge}>
          <PopupSectionRow
            cursor="pointer"
            title={`${t('language')}`}
            iconClass="icon-regular"
            maxWidthIcon={20}
            icon={<MenuLanguageIcon style={{ cursor: 'default' }} />}
            value={
              langs.find((lang) => i18n.language.includes(lang.lang))?.name
            }
            valueColor={theme.palette.text.disabled}
          />
        </span>
      </PickLanguagePopup>
      <SelectItemWithSearchPopup
        open={showFirstDayPopup}
        setOpen={onShowFirstDayPopup}
        selectItem={(item) => {
          const userSettings = Database.toServerObject<UserSettingsDoc>(
            props.userSettings
          ) as UserSettingsDoc
          userSettings.FirstDayOfTheWeek = Number(item.id ?? 1)
          updateUserSettings(userSettings).then((updated) => {
            appSettings.setFirstDayOfTheWeek(userSettings.FirstDayOfTheWeek)
            props.setMenuOpen(false)
          })
        }}
        data={days}
        selectedItemId={`${appSettings.firstDayOfTheWeek}`}
      >
        <span id="first-day-button" onClick={showFirstDay}>
          <PopupSectionRow
            title={`${t('firstDay')}`}
            iconClass="icon-regular"
            maxWidthIcon={20}
            cursor="pointer"
            icon={<MenuFirstDayIcon style={{ cursor: 'default' }} />}
            value={
              days.find((day) => day.id === `${appSettings.firstDayOfTheWeek}`)
                ?.title
            }
            valueColor={theme.palette.text.disabled}
          />
        </span>
      </SelectItemWithSearchPopup>
    </PopupSection>
  )
}
