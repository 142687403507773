import { useProgress } from 'components/providers/ProgressProvider'
import { updateLinkOnServer } from 'services/api/group'
import Database from 'services/db/Database'
import LocalStorage from 'services/LocalStorage'
import { GroupDoc } from 'types/api/changes'
import { GROUP_INFO_ACTION_TYPES } from 'types/api/group'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { getOrUpdateDeviceId } from 'utils/device'
import { GENERIC_ERROR } from 'utils/strings'
import { TextButton } from '../styled'
import { TextButtonContainer } from './styled'

import { t } from 'i18next'

interface RemoveLinkRowProps {
  group: Model<GroupDoc>
}

export default function RemoveLinkRow(props: RemoveLinkRowProps) {
  const progress = useProgress()

  const removeLinkFromGroup = async () => {
    progress.setLoading(true)
    updateLinkOnServer(
      {
        PrivateLinkUrl: '',
        PrivateLinkPassword: '',
        ActionType: GROUP_INFO_ACTION_TYPES.METADATA,
        UserID: LocalStorage.get(LocalStorageKey.USER_ID) ?? '',
        GroupID: props.group._id,
        DeviceChangeID: getOrUpdateDeviceId()
      },
      props.group
    )
      .catch((error) => {
        progress.setError(GENERIC_ERROR)
      })
      .finally(() => {
        progress.setLoading(false)
      })
  }

  return (
    <TextButtonContainer
      onClick={removeLinkFromGroup}
      style={{ paddingLeft: 8, paddingTop: 8 }}
    >
      <TextButton>{`${t('disableLink')}`}</TextButton>
    </TextButtonContainer>
  )
}
