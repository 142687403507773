import { isTestEnv } from 'utils/env'

function colorString(text: string, color: string): string[] {
  return [`%c ${text}`, `background: #222; color: ${color}`, '\n']
}

class Logger {
  log(text: string, ...args: any[]): void {
    if (isTestEnv()) console.log(...colorString(text, '#bada55'), ...args)
  }

  red(text: string, ...args: any[]): void {
    if (isTestEnv()) console.log(...colorString(text, '#f24b0f'), ...args)
  }

  blue(text: string, ...args: any[]): void {
    if (isTestEnv()) console.log(...colorString(text, '#29b0d9'), ...args)
  }

  orange(text: string, ...args: any[]): void {
    if (isTestEnv()) console.log(...colorString(text, '#eba134'), ...args)
  }

  pink(text: string, ...args: any[]): void {
    if (isTestEnv()) console.log(...colorString(text, '#E145E1'), ...args)
  }

  debug(...args: any[]): void {
    if (isTestEnv()) this.orange('[DEBUG]', ...args)
  }

  group(title: string, ...args: any[]): void {
    if (isTestEnv()) {
      console.log(`%c ${title}`, 'background: #222; color: #bada55')
      console.log(...args)
      console.log(`%c End ${title}`, 'background: #222; color: #bada55')
    }
  }

  apiResponse(endpoint: string, ...res: any[]): void {
    if (isTestEnv()) this.pink(`[API RESPONSE] ${endpoint}`, ...res)
  }

  time(label: string): void {
    if (isTestEnv()) console.time(label)
  }

  timeEnd(label: string): void {
    if (isTestEnv()) console.timeEnd(label)
  }
}

export default new Logger()
