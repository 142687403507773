import './styles.scss'

import { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from 'react'

import { CheckmarkIcon, PencilIcon } from '../../../assets/icons'
import * as Styled from '../styled'
import i18n from 'i18n'
export interface InputWithActionProps {
  disabled?: boolean
  initialText: string
  editState: boolean
  setLoading: (b: boolean) => void
  setEditState: (b: boolean) => void
  onSubmit: (s: string) => Promise<boolean>
  nonActiveChild?: ReactNode
}

export default function InputWithAction(props: InputWithActionProps) {
  //we have 2 states of input field(edit/showing), so we keep title in the usestate
  const [title, setTitle] = useState(props.initialText)
  useEffect(() => {
    setTitle(props.initialText)
  }, [props.initialText])

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 25) return
    setTitle(event.target.value)
  }

  const toggleEditName = () => {
    props.setEditState(true)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    props.onSubmit(title)
  }

  return (
    <Styled.GroupPopupInputContainer>
      {props.editState ? (
        <form onSubmit={handleSubmit}>
          <Styled.GroupPopupInput
            value={title}
            onChange={onTitleChange}
            className="title-input"
            component={
              <Styled.GroupPopupInputButton type="submit">
                <CheckmarkIcon />
              </Styled.GroupPopupInputButton>
            }
          />
        </form>
      ) : (
        <Styled.GroupPopupName
          container
          wrap="nowrap"
          display={'flex'}
          item
          direction={'row-reverse'}
        >
          {!props.disabled ? (
            <Styled.GroupNamePencilButton
              sx={{ right: i18n.dir() === 'ltr' ? 8 : -15 }}
              size="sm"
              onClick={toggleEditName}
            >
              <PencilIcon />
            </Styled.GroupNamePencilButton>
          ) : (
            <></>
          )}
          {props.nonActiveChild ? (
            props.nonActiveChild
          ) : (
            <div className="groupTitle">
              <span>{props.initialText}</span>
            </div>
          )}
        </Styled.GroupPopupName>
      )}

      {!props.disabled ? (
        <Styled.GroupPopupInputCount>
          {25 - title.length}
        </Styled.GroupPopupInputCount>
      ) : (
        <></>
      )}
    </Styled.GroupPopupInputContainer>
  )
}
