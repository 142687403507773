import { Typography } from '@mui/material'

interface NoteTextProps {
  title?: string
  text?: string
}

export function TextWithLinks(props: NoteTextProps) {
  if (!props.text) return <></>
  const textWithLinks = props.text
  const parts = textWithLinks.split(/(https?:\/\/\S+)/)

  return (
    <>
      {parts.map((part, index) => {
        if (part.match(/https?:\/\/\S+/)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.title ?? part}
            </a>
          )
        }
        return part
      })}
    </>
  )
}
