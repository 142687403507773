import isEqual from 'lodash.isequal'
import { useEffect, useState } from 'react'
import Geocode from 'react-geocode'
import {
  Attachment,
  Attendee,
  DocType,
  EventDoc,
  EventLocation,
  EventNote,
  EventParticipantStatus,
  EventRecurrence,
  EventReminder,
  EventStatus,
  GroupDoc,
  LocationReminder,
  SimpleLabel
} from 'types/api/changes'
import { CollectionType, Model } from 'types/model'

import {
  useForm,
  UseFormRegister,
  UseFormGetValues,
  Control,
  UseFormHandleSubmit
} from 'react-hook-form'
import dayjs from 'dayjs'
import Logger from 'services/Logger'
import Database from 'services/db/Database'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { is24meApp } from 'utils/appType'
import { isTrulyEmpty } from 'utils/object'
import { generateStartEndBasedOnTime } from 'utils/date'
import { localEventById, localEventByLocalId } from 'services/db/Queries/Event'
import { AppUIType } from 'components/providers/AppUITypeProvider'
import ParticipantSuggestion from 'model/models/ParticipantSuggestion'
import {
  ProviderType,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'

import { groupToProvider, isConvertedGroupId } from 'utils/groups'
import { getDefaultTimezone } from 'index'

export interface IEventDetails {
  setStatus: (status: string) => void
  setLoading: (b: boolean) => void
  loading: boolean
  setOpenDiscard: (b: boolean) => void
  openDiscard: boolean
  setOpenAddReminder: (b: boolean) => void
  openAddReminder: boolean
  sendEventToServer(data: EventDoc): Promise<any>
  setStartTime: (date: number) => void
  startTime: string | undefined
  setEndTime: (date: number) => void
  endTime: string | undefined
  setTimeZone: (tz?: string | undefined | null) => void
  timeZone: string
  setRecurrence: (eventRecurrence: EventRecurrence, dirty?: boolean) => void
  reminders: EventReminder[] | undefined
  setReminders: (reminders: EventReminder[]) => void
  RSVP: string | undefined
  setRSVP: (state: string) => void
  notes: EventNote[] | undefined
  setNotes: (notes: EventNote[]) => void
  attendees: Attendee[] | undefined
  setAttendees: (attendees: Attendee[], shouldDirty?: boolean) => void
  attendeeText: string
  setAttendeeText: (t: string) => void
  addAttendee: (attendee: Attendee) => void
  noteText: string
  setNoteText: (t: string) => void
  addNote: (text: string, bytes?: string) => void
  updateNote: (note: EventNote) => void
  participantsStatus: Record<string, EventParticipantStatus> | undefined
  setParticipantStatus: (
    participantsStatus: Record<string, EventParticipantStatus>
  ) => void
  register: UseFormRegister<EventDoc>
  control: Control<EventDoc, any>
  isDirty: boolean
  handleSubmit: UseFormHandleSubmit<EventDoc>
  getValues: UseFormGetValues<EventDoc>
  setLocation: (loc: EventLocation | undefined) => void
  error: string
  setError: (errorText: string) => void
  setGroupID: (id: string) => void
  setSupplementaryGroupIds: (ids: string[]) => void
  supplementaryGroupIds: string[] | undefined
  setAllDay: (allDay: string) => void
  cacheEventData: () => void
  setName: (text: string) => void
  setThirdPartyId: (id: string) => void
  thirdPartyId?: string
  ownerId?: string
  setOwnerId: (id: string) => void
  labels: SimpleLabel[] | undefined
  setLabels: (reminders: SimpleLabel[]) => void
  type: DocType
  setType: (type: DocType) => void
  someday?: string
  setSomeday: (s: string) => void
  createAtStart: number
  createAtEnd: number
  priority: string
  setPriority: (p: string) => void
  titleSet: boolean
  onTitleSet: (b: boolean) => void
  isNewEvent?: boolean
  groupId?: string
  dirtyFields: any

  setLocationPendingText: (text: string) => void
  locationPendingText: string
  setNotePendingText: (text: string) => void
  notePendingText: string
  addLocationToEvent: (text: string) => Promise<void>
  applyPendingData: () => Promise<void>
  attachements?: Attachment[]
  customUrl?: string
  setAttachments: (attachments: Attachment[] | undefined) => void
  setCustomUrl: (url: string | undefined) => void
  locationReminder: LocationReminder[]
}

export function useEventDetails(
  event: Model<EventDoc> | EventDoc,
  group: GroupDoc | undefined,
  createAtStart: number,
  createAtEnd: number,
  doNotScrollToEvent?: boolean
) {
  let pendingData: EventDoc | undefined | null
  const storedData = LocalStorage.get(LocalStorageKey.PENDING_EVENT_DATA)
  if (storedData) {
    pendingData = JSON.parse(storedData)
    Logger.debug('cached data', pendingData)
  }

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    resetField,
    formState: { isDirty, dirtyFields }
  } = useForm<EventDoc>({
    defaultValues:
      pendingData != null
        ? { ...pendingData }
        : {
            Text: event.Text,
            AllDay: event.AllDay ? event.AllDay : '0',
            TimeZoneNameID:
              (event.TimeZoneNameID?.length ?? 0) > 0
                ? event.TimeZoneNameID
                : getDefaultTimezone(),
            StartDate:
              !event.Recurrence && event.late === false
                ? event.StartDate
                : (
                    (createAtStart ??
                      generateStartEndBasedOnTime(dayjs().unix(), '').start *
                        1000) / 1000
                  ).toString(),
            EndDate:
              !event.Recurrence && event.late === false
                ? event.EndDate
                : (
                    (createAtEnd ??
                      generateStartEndBasedOnTime(dayjs().unix(), '').end *
                        1000) / 1000
                  ).toString(),
            Status: event.Status ?? EventStatus.ACTIVE,
            Recurrence: event.Recurrence,
            Location: event.Location,
            Reminder: event.Reminder ?? [],
            Notes: event.Notes ?? [],
            attendees: event.attendees ?? [],
            attendeesChanged: false,
            RequestConfirmation: event.RequestConfirmation ?? '0',
            GroupID: event.GroupID,
            ThirdPartyID: event.ThirdPartyID,
            OwnerID: event.OwnerID,
            Label: event.Label,
            Type: event.Type,
            someday: event.someday,
            Priority: event.Priority,
            createAtStart: createAtStart,
            createAtEnd: createAtEnd,
            noteText: '',
            id: event.id,
            locationPendingText: '',
            notePendingText: '',
            SupplementaryGroupsIDs: event.SupplementaryGroupsIDs ?? [],
            Attachments: event.Attachments,
            CustomUrl: event.CustomUrl,
            LocationReminders: event.LocationReminders ?? []
          }
  })

  useEffect(() => {
    return () => {
      if (!isDirty && event._id && event._id.length > 0) {
        reset({
          Text: event.Text,
          AllDay: event.AllDay ? event.AllDay : '0',
          TimeZoneNameID:
            (event.TimeZoneNameID?.length ?? 0) > 0
              ? event.TimeZoneNameID
              : getDefaultTimezone(),
          StartDate:
            !event.Recurrence && event.late === false
              ? event.StartDate
              : (createAtStart / 1000).toString(),
          EndDate:
            !event.Recurrence && event.late === false
              ? event.EndDate
              : (createAtEnd / 1000).toString(),
          Status: event.Status ?? EventStatus.ACTIVE,
          Recurrence: event.Recurrence,
          Location: event.Location,
          Reminder: event.Reminder ?? [],
          Notes: event.Notes ?? [],
          attendees: event.attendees ?? [],
          attendeesChanged: false,
          RequestConfirmation: event.RequestConfirmation ?? '0',
          GroupID: event.GroupID,
          ThirdPartyID: event.ThirdPartyID,
          Label: event.Label,
          Type: event.Type,
          someday: event.someday,
          Priority: event.Priority,
          createAtStart: createAtStart,
          createAtEnd: createAtEnd,
          noteText: '',
          locationPendingText: '',
          notePendingText: '',
          SupplementaryGroupsIDs: event.SupplementaryGroupsIDs ?? [],
          Attachments: event.Attachments,
          CustomUrl: event.CustomUrl,
          LocationReminders: event.LocationReminders ?? []
        })
      }
    }
  }, [event._rev])

  function cacheEventData() {
    LocalStorage.set(
      LocalStorageKey.PENDING_EVENT_DATA,
      JSON.stringify(getValues())
    )
  }

  const newEvent = event?._id == undefined || event?._id?.length == 0

  const [participantsStatus, setParticipantStatus] = useState<
    Record<string, EventParticipantStatus> | undefined
  >(event?.ParticipantsStatus)
  const setGroupID = (id: string) => {
    setValue('GroupID', id, { shouldDirty: true })
    if (newEvent) cacheEventData()
  }
  const setLocation = (location: EventLocation | undefined) => {
    if (location === undefined) {
      setValue('Location', {}, { shouldDirty: true })
    } else {
      setValue('Location', location, { shouldDirty: true })
    }

    if (newEvent) cacheEventData()

    setValue('locationPendingText', '', { shouldDirty: true })
  }

  const supplementaryGroupIds = getValues('SupplementaryGroupsIDs')

  const setSupplementaryGroupIds = (ids: string[] | undefined) => {
    setValue('SupplementaryGroupsIDs', ids, { shouldDirty: true })
  }

  const someday = getValues('someday')

  const setSomeday = (someday: string) => {
    setValue('TimeZoneNameID', getDefaultTimezone(), { shouldDirty: false })

    if (someday === '0' && isTrulyEmpty(getValues('StartDate'))) {
      const newStartTime = dayjs(createAtStart)
        .set('minute', 0)
        .set('second', 0)

      setAllDay('0')
      setStartTime(newStartTime.valueOf())
      setEndTime(newStartTime.add(30, 'minute').valueOf())
    }

    setValue('someday', someday, { shouldDirty: true })
    setValue('Recurrence', undefined, { shouldDirty: true })
    setValue('Reminder', [], { shouldDirty: true })

    Database.write(async () => {
      const localEvent = await localEventByLocalId(event.id ?? '')
      if (!localEvent) return

      return localEvent.update((local) => {
        local.someday = someday
      })
    })

    if (newEvent) cacheEventData()
  }

  const setTimeZone = (tz: string | undefined | null) => {
    setValue('TimeZoneNameID', tz ?? getDefaultTimezone(), {
      shouldDirty: true
    })
  }
  const setStatus = (status: string) => {
    setValue('Status', status as EventStatus, { shouldDirty: true })
  }

  const setName = (name: string) => {
    setValue('Text', name, { shouldDirty: true })
    if (newEvent) cacheEventData()
  }

  const setThirdPartyId = (id: string) => {
    setValue('ThirdPartyID', id, { shouldDirty: false })
    if (newEvent) cacheEventData()
  }

  const setType = (type: DocType) => {
    setValue('Type', type, { shouldDirty: true })
    setNotes([])
    setPriority('1')
    setRSVP('0')
    setThirdPartyId('')
    if (newEvent) cacheEventData()
  }

  const setPriority = (priority: string) => {
    setValue('Priority', priority, { shouldDirty: true })
    if (newEvent) cacheEventData()
  }

  const [attendeeText, setAttendeeText] = useState<string>('')

  async function updateTimeInDB() {
    if (
      LocalStorage.get(LocalStorageKey.CURRENT_UI) &&
      LocalStorage.get(LocalStorageKey.CURRENT_UI) !== AppUIType.calendars
    ) {
      return
    }

    if (newEvent) {
      cacheEventData()
      const start =
        getValues('AllDay') === '1'
          ? dayjs
              .utc(
                dayjs(Number(getValues('StartDate')) * 1000).format(
                  'YYYY-MM-DD'
                )
              )
              .startOf('day')
              .unix()
              .toString()
          : getValues('StartDate')
      //to keep visible popup
      if (getValues('AllDay') === '1') {
        LocalStorage.set(
          LocalStorageKey.VISIBLE_EVENT,
          dayjs(Number(start) * 1000)
            .startOf('day')
            .valueOf()
        )
      } else {
        LocalStorage.set(LocalStorageKey.VISIBLE_EVENT, Number(start) * 1000)
      }

      if (event.syncStatus != 'deleted')
        await Database.write(async () => {
          return (await localEventByLocalId(event.id ?? '')).update((local) => {
            local.AllDay = getValues('AllDay')
            local.StartDate = getValues('StartDate')
            local.EndDate = getValues('EndDate')
            local.TimeZoneNameID =
              getValues('AllDay') === '1' ? 'UTC' : getValues('TimeZoneNameID')
          })
        })
    }
  }

  const setAllDay = (allDay: string) => {
    setValue('AllDay', allDay, { shouldDirty: true })
    setTimeZone(allDay === '1' ? 'UTC' : dayjs.tz.guess())

    updateTimeInDB()
  }

  const ownerId = getValues('OwnerID')
  const setOwnerId = (id: string) => {
    console.log('updating owner id')
    setValue('OwnerID', id, { shouldDirty: true })
    cacheEventData()
  }
  const startTime = getValues('StartDate')
  const setStartTime = (time: number) => {
    let zoned = dayjs(time).tz(
      getValues('TimeZoneNameID') ?? getDefaultTimezone()
    )

    let duration = Number(getValues('EndDate')) - Number(getValues('StartDate'))

    if (getValues('EndDate') === getValues('StartDate')) {
      duration += 1
    }

    let newStartDate = zoned
      .tz(getValues('TimeZoneNameID') ?? getDefaultTimezone())
      .set('hour', zoned.get('hour'))
      .set('minute', zoned.get('minute'))
      .set('date', zoned.get('date'))
      .unix()

    if (getValues('TimeZoneNameID') === 'UTC' && getValues('AllDay') !== '1') {
      newStartDate = zoned.unix()
    }

    setValue('StartDate', newStartDate.toString(), {
      shouldDirty: true
    })

    if (!isConvertedGroupId(getValues('GroupID')) && duration === 0) {
      setValue('EndDate', newStartDate.toString(), {
        shouldDirty: true
      })
    } else {
      setValue('EndDate', (newStartDate + duration).toString(), {
        shouldDirty: true
      })
    }

    updateTimeInDB()
  }
  const endTime = getValues('EndDate')
  const setEndTime = (time: number) => {
    const zoned = dayjs(time).tz(
      getValues('TimeZoneNameID') ?? getDefaultTimezone()
    )

    const duration =
      Number(getValues('EndDate')) - Number(getValues('StartDate'))

    const startDate = dayjs
      .unix(Number(getValues('StartDate')))
      .tz(getValues('TimeZoneNameID') ?? getDefaultTimezone())

    const newEndDate = zoned
      .tz(getValues('TimeZoneNameID') ?? getDefaultTimezone())
      .set('hour', zoned.get('hour'))
      .set('minute', zoned.get('minute'))
      .set('date', zoned.get('date'))
      .unix()

    Logger.blue(dayjs.unix(newEndDate).format('YYYY-MM-DD HH:mm:ss'))
    Logger.blue(startDate.format('YYYY-MM-DD HH:mm:ss'))

    let isEndDateBeforeStartDate = false

    if (getValues('AllDay') === '1') {
      const endDayOfYear = dayjs.unix(newEndDate).dayOfYear()
      const startDayOfYear = startDate.dayOfYear()
      isEndDateBeforeStartDate = endDayOfYear < startDayOfYear
    } else {
      isEndDateBeforeStartDate = newEndDate < Number(getValues('StartDate'))
    }

    if (isEndDateBeforeStartDate) {
      setValue('StartDate', (newEndDate - duration).toString(), {
        shouldDirty: true
      })
    }

    setValue('EndDate', newEndDate.toString(), {
      shouldDirty: true
    })
    updateTimeInDB()
  }

  const eventReminders = getValues('Reminder')
  const setEventReminders = (eventReminders: EventReminder[]) => {
    setValue('Reminder', eventReminders, { shouldDirty: true })

    if (newEvent) cacheEventData()
  }

  const labels = getValues('Label')
  const setLabels = (labels: SimpleLabel[]) => {
    setValue('Label', labels, { shouldDirty: true })

    if (newEvent) cacheEventData()
  }

  const RSVP = getValues('RequestConfirmation')
  const setRSVP = (requestParticipation: string) => {
    setValue('RequestConfirmation', requestParticipation, { shouldDirty: true })
    if (newEvent) cacheEventData()
  }
  const notes = getValues('Notes')
  const setNotes = (notes: EventNote[]) => {
    setValue('Notes', notes, { shouldDirty: true })

    if (newEvent)
      Database.write(async () => {
        ;(await localEventByLocalId(event.id ?? ''))?.update((local) => {
          local.Notes = notes
        })
      })
  }
  const attendees = getValues('attendees')
  const setAttendees = (attendees: Attendee[], shouldDirty?: boolean) => {
    setValue(
      'attendeesChanged',
      !arraysAreEqual(event.attendees ?? [], attendees),
      {
        shouldDirty: true
      }
    )
    setValue('attendees', attendees, { shouldDirty: shouldDirty ?? false })
    console.log(attendees)

    if (newEvent) cacheEventData()
  }

  const setRecurrence = (eventRecurrence: EventRecurrence, dirty?: boolean) => {
    setValue('Recurrence', eventRecurrence, { shouldDirty: dirty ?? true })
    if (newEvent && typeof event.update === 'function' && dirty) {
      Database.write(async () => {
        event.update((local: { Recurrence: EventRecurrence }) => {
          local.Recurrence = eventRecurrence
        })
      })
      cacheEventData()
    }
  }

  const [loading, setLoading] = useState(false)
  const [openDiscard, setOpenDiscard] = useState(false)
  const [openAddReminder, setOpenAddReminder] = useState(false)
  const [error, setError] = useState('')

  const setRemindersToEvent = (reminders: EventReminder[]) => {
    setOpenAddReminder(false)
    setEventReminders(reminders)
  }

  const calendarSync = useCalendarSync()

  const sendEventToServer = async (data: EventDoc) => {
    const local = await localEventById(event._id ?? '')

    setLoading(true)
    /**
     * This field determines, if an event should be updated locally only, without touching the server
     * For example, we save reminders only locally
     */
    const shouldSaveLocally =
      local?.ThirdPartyID?.length === 0 &&
      Object.keys(dirtyFields).length === 1 &&
      dirtyFields.Reminder != undefined

    await calendarSync.sendEventToServer(
      event,
      data,
      shouldSaveLocally,
      isDirty
    )
  }

  const [titleSet, onTitleSet] = useState(false)

  const addNoteToArray = (noteText: string, bytes?: string) => {
    if (!noteText) return

    let currentNotes = getValues('Notes')

    if (typeof currentNotes === 'string') {
      currentNotes = []
    }

    const newNotes = [...(currentNotes ?? [])]
    const newNote: EventNote = {
      FilePath: 'null',
      Note: noteText,
      NoteID: Date.now(),
      Status: 1,
      FileBytes: bytes
    }

    if (getValues('Type') === DocType.NOTE && !titleSet) {
      let updatedText = noteText.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 25)

      if (noteText.length > 25) {
        updatedText += '...'
      }
      if (updatedText != '...') setName(updatedText)
    }

    newNotes.push(newNote)
    setNotes(newNotes)
  }

  const updateNote = (updatedNote: EventNote) => {
    const currentNotes = getValues('Notes')
    if (currentNotes) {
      const updated = currentNotes.map((note) => {
        if (note.NoteID === updatedNote.NoteID) return updatedNote

        return note
      })
      setNotes(updated)
      const noteText = updatedNote.Note

      if (getValues('Type') === DocType.NOTE && !titleSet) {
        let updatedText = noteText
          .replace(/<\/?[^>]+(>|$)/g, '')
          .substring(0, 25)

        if (noteText.length > 25) {
          updatedText += '...'
        }

        if (updatedText != '...') setName(updatedText)
      }
    }
  }

  const addAttendeeToArray = (newAttendee: Attendee) => {
    if (
      !newAttendee ||
      attendees?.find((attendee) => attendee.email === newAttendee.email)
    )
      return

    const newAttendees = [...(getValues('attendees') ?? [])]

    Database.getCollection<ParticipantSuggestion>(
      CollectionType.PARTICIPANT_SUGGESTION
    )
      .query()
      .fetch()
      .then((participants) => {
        const participant = participants.find(
          (p) => p.email === newAttendee.email
        )
        if (!participant && newAttendee.email) {
          Database.write(async () => {
            await Database.getCollection<ParticipantSuggestion>(
              CollectionType.PARTICIPANT_SUGGESTION
            ).create((p) => {
              p.email = newAttendee.email!
              p.name = newAttendee.displayName ?? ''
              p.img = ''
            })
          })
        }
      })

    const provider = groupToProvider(group)
    if (provider === ProviderType.GOOGLE) {
      //first add yourself if not exist
      if (
        attendees?.find((attendee) => attendee.email === group?._message) ===
        undefined
      ) {
        newAttendees.push({
          email: group?._message,
          responseStatus: 'accepted',
          self: true
        })
      }
    }

    if (!newAttendees?.find((attendee) => attendee.email === newAttendee.email))
      newAttendees.push(newAttendee)

    setAttendeeText('')
    setAttendees(newAttendees)
  }
  const noteText = getValues('noteText')
  const setNoteText = (text: string) => {
    setValue('noteText', text, { shouldDirty: false })
  }

  const attachements = getValues('Attachments')
  const setAttachments = (attachments: Attachment[] | undefined) => {
    setValue('Attachments', attachments, { shouldDirty: true })
  }
  const customUrl = getValues('CustomUrl')
  const setCustomUrl = (url: string | undefined) => {
    setValue('CustomUrl', url, { shouldDirty: true })
  }

  useEffect(() => {
    if ((event._id?.length ?? 0) > 0)
      LocalStorage.set(
        LocalStorageKey.SHOULD_KEEP_POPUP,
        Object.keys(dirtyFields).length === 0 ? '0' : '1'
      )
  }, [Object.keys(dirtyFields).length === 0])

  const setLocationPendingText = (text: string) => {
    setValue('locationPendingText', text, { shouldDirty: true })
  }

  const notePendingText = getValues('notePendingText')
  const setNotePendingText = (text: string) => {
    setValue('notePendingText', text, { shouldDirty: true })
  }

  const addLocationToEvent = (text: string) => {
    return Geocode.fromAddress(text).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        setLocation({
          FilteredLocation: 'null',

          Address: text,
          Lat: String(lat),
          Long: String(lng)
        })
      },
      (error) => {
        setLocation({
          FilteredLocation: 'null',

          Address: text,
          Lat: '0',
          Long: '0'
        })
        console.log(getValues())
      }
    )
  }

  const applyPendingData = async () => {
    const locationPendingText = getValues('locationPendingText')
    const notePendingText = getValues('notePendingText')

    if (
      locationPendingText.length > 0 &&
      getValues('Location')?.Address !== locationPendingText
    ) {
      await addLocationToEvent(locationPendingText)
    }
    if (notePendingText.length > 0) {
      addNoteToArray(notePendingText)
    }
  }

  const eventPopupInterface: IEventDetails = {
    setLoading: setLoading,
    loading: loading,
    sendEventToServer: sendEventToServer,
    startTime: startTime,
    setStartTime: setStartTime,
    endTime: endTime,
    setEndTime: setEndTime,
    setTimeZone: setTimeZone,
    timeZone: getValues('TimeZoneNameID') ?? getDefaultTimezone(),
    reminders: eventReminders,
    setReminders: setRemindersToEvent,
    RSVP: RSVP,
    setRSVP: setRSVP,
    notes: notes,
    setNotes: setNotes,
    setRecurrence: setRecurrence,
    setOpenDiscard: setOpenDiscard,
    openDiscard: openDiscard,
    setOpenAddReminder: setOpenAddReminder,
    openAddReminder: openAddReminder,
    setAttendees,
    attendeeText: attendeeText,
    setAttendeeText: setAttendeeText,
    addAttendee: addAttendeeToArray,
    addNote: addNoteToArray,
    participantsStatus: participantsStatus,
    setParticipantStatus,
    setStatus,
    register,
    isDirty,
    handleSubmit,
    getValues,
    control,
    setLocation,
    error,
    setError,
    setGroupID,
    attendees,
    setAllDay,
    cacheEventData,
    setName,
    setThirdPartyId,
    thirdPartyId: getValues('ThirdPartyID'),
    labels,
    setLabels,
    type: getValues('Type') ?? (is24meApp() ? DocType.TASK : DocType.EVENT),
    setType,
    updateNote,
    someday,
    setSomeday,
    createAtStart,
    createAtEnd,
    priority: getValues('Priority') ?? '0',
    setPriority: setPriority,
    titleSet,
    onTitleSet,
    noteText: noteText,
    setNoteText,
    isNewEvent: newEvent,
    ownerId,
    setOwnerId,
    groupId: getValues('GroupID'),
    dirtyFields,
    locationPendingText: getValues('locationPendingText'),
    setLocationPendingText,
    setNotePendingText,
    notePendingText: getValues('notePendingText'),
    addLocationToEvent,
    applyPendingData,
    supplementaryGroupIds,
    setSupplementaryGroupIds,
    attachements,
    setAttachments,
    customUrl,
    setCustomUrl,
    locationReminder: getValues('LocationReminders') ?? []
  }

  return eventPopupInterface
}

function arraysAreEqual(a: any[], b: any[]): boolean {
  return isEqual(a, b)
}
