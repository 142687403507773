import { ClickAwayListener, MenuList } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import {
  MasterLabelDoc,
  MasterLabelLabel,
  UserSettingsDoc
} from 'types/api/changes'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { t } from 'i18next'
import { Model } from 'types/model'
import { useCalendar } from 'components/providers/CalendarProvider'
import MasterLabel from 'model/models/MasterLabel'
import LabelIcon from '@mui/icons-material/Label'
import { useAppUIType } from 'components/providers/AppUITypeProvider'

interface ContextMenuProps {
  label: MasterLabelLabel
  userSettings?: Model<UserSettingsDoc> | UserSettingsDoc
  masterLabel?: Model<MasterLabelDoc>
  x: number // X-coordinate of the click event
  y: number // Y-coordinate of the click event
  onClose: () => void // Callback to close the context menu
}

const LabelItemContextMenu: React.FC<ContextMenuProps> = ({
  label,
  userSettings,
  masterLabel,
  x,
  y,
  onClose
}) => {
  const tfmUIContext = useAppUIType()

  const showLabelDetails = () => {
    if (label.ID) {
      tfmUIContext.setLabelInfo(label.ID)
    }

    onClose()
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <ul
        style={{
          position: 'absolute',
          width: 'fit-content',
          top: y,
          left: x,
          listStyleType: 'none',
          zIndex: 99999,
          padding: '8px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
        }}
      >
        <MenuList>
          <MenuItem onClick={showLabelDetails}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText>{t('labelDetails')}</ListItemText>
          </MenuItem>
        </MenuList>
      </ul>
    </ClickAwayListener>
  )
}

export default LabelItemContextMenu
