import { APP_COLOR } from 'utils/colors'
import ConfirmationDialog from './ConfirmationDialog'
import { t } from 'i18next'
import { ICalendar } from 'types/google_events'
import Database from 'services/db/Database'
import { GroupDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import { isGroupcalApp } from 'utils/appType'
import { outlookPrefix } from 'utils/constants'
import {
  ProviderType,
  useCalendarSync
} from 'components/providers/CalendarSyncProdivder'
import { icloudPrefix } from 'components/providers/icloud/IcloudProvider'

interface ResyncCalendarDialogProps {
  isOpen: boolean
  setOpen: (o: boolean) => void
  group?: Model<GroupDoc> | GroupDoc
}

export function ResyncCalendarDialog(props: ResyncCalendarDialogProps) {
  const calendarSync = useCalendarSync()

  const markGroupAsProblematic = () => {
    if (props.group && !props.group.SyncProblem) {
      Database.write(async () => {
        return await props.group?.update((local: GroupDoc) => {
          local.SyncProblem = '1'
        })
      })
    }
  }

  const hideSyncProblem = () => {
    props.setOpen(false)
    markGroupAsProblematic()
  }

  function onGrantedCalendarAccess(calendar: ICalendar) {
    Database.write(async () => {
      if (props.group) {
        await props.group.update((local: GroupDoc) => {
          local.SyncProblem = null
        })
      }
    })
  }

  function initiateCalendarSync() {
    if (props.group?._id?.includes(prefixGoogleEventsGroup)) {
      calendarSync.connect(
        ProviderType.GOOGLE,
        onGrantedCalendarAccess,
        hideSyncProblem
      )
    } else if (props.group?._id?.includes(outlookPrefix)) {
      calendarSync.connect(ProviderType.OUTLOOK)
    } else if (props.group?._id?.includes(icloudPrefix)) {
      calendarSync.connect(ProviderType.APPLE)
    }

    hideSyncProblem()
  }

  return (
    <ConfirmationDialog
      title={syncTitle(props.group)}
      content={syncContent(props.group)}
      leftButton={`${t('cancel')}`}
      leftButtonColor={'black'}
      rightButton={`${t('continue')}`}
      rightButtonColor={APP_COLOR}
      handleLeftButton={hideSyncProblem}
      handleRightButton={initiateCalendarSync}
      handleClose={markGroupAsProblematic}
      open={props.isOpen}
    />
  )
}

function syncTitle(group?: GroupDoc): string {
  if (group?._id?.includes(outlookPrefix)) return `${t('cantSyncOutlook')}`
  if (group?._id?.includes(icloudPrefix)) return `${t('cantSyncIcloud')}`

  return `${t('cantSyncGoogle')}`
}

function syncContent(group?: GroupDoc) {
  if (group?._id?.includes(outlookPrefix))
    return `${t('cantSyncOutlookBody', {
      app: isGroupcalApp() ? t('appName') : t('appName24me')
    })}`

  if (group?._id?.includes(icloudPrefix))
    return `${t('cantSyncIcloudBody', {
      app: isGroupcalApp() ? t('appName') : t('appName24me')
    })}`

  return `${t('cantSyncGoogleBody', {
    app: isGroupcalApp() ? t('appName') : t('appName24me')
  })}`
}
