import Logger from 'services/Logger'

export function userLang() {
  try {
    let lang = window.navigator.language ?? null

    if (!lang || lang.length === 0) return 'us'

    let shortLang: string | null | undefined = lang
    if (shortLang?.indexOf('-') !== -1) {
      shortLang = shortLang?.split('-')[1]
    } else {
      shortLang = undefined
    }

    if (shortLang?.indexOf('_') !== -1) {
      shortLang = shortLang?.split('_')[1]
    } else {
      shortLang = undefined
    }

    return shortLang ? shortLang?.toLocaleLowerCase() : 'us'
  } catch (e) {
    Logger.red('Error getting user language', e)
    return 'us'
  }
}

export function isTextRTL(text?: string): boolean {
  if (!text) return false
  // Regular expression to match any character in the Unicode range of RTL scripts
  const rtlRegex = new RegExp(
    /[\u0600-\u06FF\u0750-\u077F\u0590-\u05FF\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/
  )

  return rtlRegex.test(text) // Returns true if text contains any RTL characters
}
