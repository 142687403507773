import { t } from 'i18next'
import TooltipMenu, { Option } from './MoreEventActionsButton'
import {
  DocType,
  EventDoc,
  EventRecurrence,
  EventStatus,
  Exclusion
} from 'types/api/changes'
import { Model } from 'types/model'
import { IEventDetails } from 'hooks/useEventDetails'
import { addTempEventToDate, localEventById } from 'services/db/Queries/Event'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { is24meApp } from 'utils/appType'
import { Tier } from 'utils/api/accounts'
import { instanceStatus } from 'components/calendar/components/Event'
import { updateEventOnServer } from 'services/api/event'
import { useCalendar } from 'components/providers/CalendarProvider'
import dayjs from 'dayjs'

interface EventsActionsMenuProps {
  instanceStart: number
  instanceEnd: number
  event?: Model<EventDoc> | EventDoc
  eventHook: IEventDetails
  disabled?: boolean
  onClose: () => void
  closePopup: (b?: boolean) => void
  updateStatus?: (status: EventStatus) => void
}

export default function EventsActionsMenu(props: EventsActionsMenuProps) {
  let options: Option[] = [
    { title: t('duplicate'), neededTier: is24meApp() ? Tier.PRO : undefined }
  ]

  if (
    is24meApp() &&
    (props.event?.Type === DocType.TASK || props.event?.Type === DocType.NOTE)
  ) {
    if (
      instanceStatus(props.event, props.instanceStart) === EventStatus.COMPLETED
    ) {
      options.push({ title: t('uncomplete') })
    } else {
      options.push({ title: t('archive'), color: 'red' })
    }
  }

  const calendar = useCalendar()

  return (
    <TooltipMenu
      disabled={props.disabled}
      options={options}
      onSelectOption={function (option: string): void {
        localEventById(props.event?._id ?? '').then((event) => {
          if (option === t('duplicate')) {
            props.closePopup(true)
            props.eventHook.cacheEventData()
            const dayjsInstance = dayjs.unix(props.instanceStart / 1000)
            // .add(props.event.AllDay === '1' ? 12 : 0, 'hour')

            calendar.onGoToDate(dayjsInstance.toDate())
            addTempEventToDate(
              Number(dayjsInstance.unix()),
              Number(props.instanceEnd / 1000),
              props.event?.GroupID ?? '',
              props.event?.AllDay === '1',
              props.event?.Type,
              undefined,
              props.event?.ThirdPartyID
            ).then((event) => {
              setTimeout(() => {
                LocalStorage.set(
                  LocalStorageKey.VISIBLE_EVENT,
                  `${dayjsInstance.unix()}`
                )
              }, 250)
            })
          } else if (option === t('complete')) {
            if (props.updateStatus) props.updateStatus(EventStatus.COMPLETED)
          } else if (option === t('archive')) {
            if (props.updateStatus) props.updateStatus(EventStatus.DELETED)
          } else if (option === t('uncomplete')) {
            if (props.updateStatus) {
              localEventById(props.event?._id ?? '').then((event) => {
                if (event.Status === EventStatus.COMPLETED) {
                  const data = {
                    Status: EventStatus.ACTIVE,
                    Recurrence:
                      event.Recurrence &&
                      typeof event.Recurrence !== 'string' &&
                      !('0' in event.Recurrence)
                        ? { ...event.Recurrence, Exclusion: [] }
                        : undefined
                  }

                  updateEventOnServer(event, {
                    ...data,
                    SupplementaryGroupsIDs:
                      props.event?.SupplementaryGroupsIDs ?? []
                  })
                } else if (event.Status === EventStatus.ACTIVE) {
                  const eventRecurrence = event.Recurrence as EventRecurrence
                  updateEventOnServer(event, {
                    Status: EventStatus.ACTIVE,
                    Recurrence: {
                      ...eventRecurrence,
                      Exclusion: [
                        ...(eventRecurrence.Exclusion ?? []),
                        {
                          Date: `${
                            dayjs(props.instanceStart)
                              .add(event.AllDay === '1' ? 12 : 0, 'hour')
                              .tz('UTC')
                              .startOf('day')
                              .valueOf() / 1000
                          }`,
                          Status: EventStatus.ACTIVE
                        }
                      ]
                        .reverse()
                        .filter(
                          (item: Exclusion, index: number, self: Exclusion[]) =>
                            index ===
                            self.findIndex(
                              (i: Exclusion) => i.Date === item.Date
                            )
                        )
                    },
                    SupplementaryGroupsIDs: event.SupplementaryGroupsIDs ?? []
                  })
                }
                if (props.onClose) props.onClose()
              })
            }
          }
        })
      }}
    />
  )
}
