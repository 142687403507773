import { Grid } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'

import Avatar from '../badges/Avatar'

export const MembersList = styled(Grid)(() => ({
  width: '102%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  maxHeight: '215px'
}))

export interface ParticipantAvatarContainerProps {
  color: string
}

export const ParticipantAvatarContainer = styled('div')(
  (props: ParticipantAvatarContainerProps) => ({
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
    display: 'flex',
    justifyContent: 'center',

    border: props.color ? `1px solid ${props.color}` : ``,
    borderRadius: 9999
  })
)

export const MemberListItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 400,
  color: theme.palette.text.primary,
  margin: theme.spacing(0.75, 0),

  '& span[data-content="role"]': {
    fontSize: '0.625rem',
    color: theme.palette.input.placeholder
  },

  '& span[data-content="pending"]': {
    fontSize: '0.625rem',

    color: theme.palette.input.placeholder,
    '@keyframes flickerAnimation': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 }
    },
    animation: 'flickerAnimation 2s infinite'
  }
}))

export const MembersListNameContainer = styled(Grid)(() => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center'
}))
