import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { CloseIcon, EmailIcon, PasswordIcon } from 'assets/icons'
import { appleIcon } from 'assets/icons/menu'
import { Axios, AxiosError } from 'axios'

import InputFieldWithIcon from 'components/inputs/InputWithIcon'
import VideoPreview from 'components/video/VideoPreview'
import useEnterKey from 'hooks/useEnterKey'
import { t } from 'i18next'
import { ChangeEvent, useState } from 'react'
import { ConnectThirdPartyToServerData } from 'services/api/group'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { GENERIC_ERROR } from 'utils/strings'

interface ICloudCredentialsDialogProps {
  open: boolean
  setOpen: (b: boolean) => void
  onCancel?: Function
  connect: (
    email: string,
    password: string
  ) => Promise<ConnectThirdPartyToServerData>
}

export default function ICloudCredentialsDialog(
  props: ICloudCredentialsDialogProps
) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState(' ')
  const [inProgress, setInProgress] = useState(false)

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorText('')
    setEmail(event.target.value)
  }
  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorText('')
    setPassword(event.target.value)
  }

  const connectIcloud = () => {
    if (inProgress) return

    if (!email || !password) {
      setErrorText(t('emailAndPasswordRequired') ?? '')
      return
    }

    setInProgress(true)
    setErrorText('')

    props
      .connect(email, password)
      .then((response) => {
        if (response.connected) {
          setInProgress(false)
          props.setOpen(false)
        }
      })
      .catch((error) => {
        setInProgress(false)
        if (error instanceof AxiosError) {
          if (error.response?.status === 401)
            setErrorText(t('invalidCredentials') ?? '')
          else setErrorText(GENERIC_ERROR)
        } else setErrorText(error.message)
      })
  }

  const handleClose = () => {
    props.setOpen(false)
    if (props.onCancel) props.onCancel()
  }

  useEnterKey(() => {
    connectIcloud()
  })

  return (
    <Dialog
      sx={{
        zIndex: 9999
      }}
      open={props.open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '19px',
          paddingBottom: 4
        }
      }}
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        sx={{ minWidth: '600px', minHeight: '300px' }}
      >
        <Grid
          sx={{ position: 'sticky', top: 0, zIndex: 9999 }}
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'row-reverse'}
        >
          <IconButton
            sx={{ marginRight: 2, marginTop: 2 }}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            sx={{ marginLeft: 2, marginTop: 2 }}
            onClick={() => {
              props.setOpen(false)
            }}
            aria-label="close"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid
          container
          width={'70%'}
          height={'100%'}
          display={'flex'}
          overflow={'auto'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <img src={appleIcon} alt="Apple" />
          <Typography sx={{ marginTop: 2 }} fontSize={24}>{`${t(
            'connectToICloud'
          )}`}</Typography>
          <Grid
            marginTop={'24px'}
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'12px'}
          >
            <InputFieldWithIcon
              id="icloud-email"
              autocomplete="off"
              placeholder={`${t('enterYourIcloud')}`}
              onChange={onEmailChange}
              icon={<EmailIcon />}
            />
            <InputFieldWithIcon
              id="icloud-password"
              autocomplete="off"
              isPasswordField
              placeholder={`${t('yourIcloudPassword', {
                appName: isGroupcalApp() ? t('appName') : t('appName24me')
              })}`}
              onChange={onPasswordChange}
              icon={<PasswordIcon />}
            />
            <Typography
              sx={{ opacity: errorText ? 1 : 0, height: '20px' }}
              color={'red'}
              width={'100%'}
              textAlign={'center'}
              fontSize={14}
            >
              {errorText}
            </Typography>
          </Grid>
          <Grid
            marginTop={'8px'}
            marginBottom={'8px'}
            container
            width={'100%'}
            justifyContent={'center'}
          >
            {inProgress ? (
              <CircularProgress size={'48px'} />
            ) : (
              <Button
                id="connect-to-icloud-button"
                onClick={connectIcloud}
                sx={{
                  height: '48px',

                  backgroundColor: '#007AFF', // iOS blue color
                  color: '#fff', // white text color
                  borderRadius: '24px',
                  width: '80%',
                  '&:hover': {
                    backgroundColor: '#0056b3' // darker blue color on hover
                  }
                }}
              >
                {`${t('connect')}`}
              </Button>
            )}
          </Grid>
          <Box
            sx={{
              marginTop: '10px',
              marginBottom: '24px',
              background: '#e4e4e4',
              height: '1px',
              width: '100%'
            }}
          />
          <Typography marginBottom={'16px'} fontSize={16}>
            {t('howToGenerate', {
              appName: isGroupcalApp() ? t('appName') : t('appName24me')
            })}
          </Typography>
          <VideoPreview videoId={is24meApp() ? 'IeFkbBI0DXs' : '1EKnW0HChBs'} />
          <a
            style={{ marginTop: '16px' }}
            href="https://support.apple.com/en-us/102654"
            target="_blank"
            rel="noreferrer"
          >
            {t('appleArticle')}
          </a>
        </Grid>
      </Grid>
    </Dialog>
  )
}
