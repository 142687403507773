import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useFilePicker } from 'use-file-picker'
import { compressBase64 } from 'utils/file'

interface FilePickProviderProps {
  children: ReactNode
}

interface FilePickerContextProps {
  openFileSelector: () => void
  filesContent: any[]
  loading: boolean
  errors: any[]
  compressedImage: string | null
  error: string | null
}

const FilePickerContext = createContext<FilePickerContextProps | undefined>(
  undefined
)

export default function FilePickerProvider(props: FilePickProviderProps) {
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false
  })

  const [compressedImage, setCompressedImage] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (filesContent.length > 0) {
      compressBase64(filesContent[0].content)
        .then((data) => {
          setCompressedImage(data)
        })
        .catch((e) => {
          setError('GroupCal allows to use photos up to 8MB in size.')
        })
    }
  }, [filesContent])

  return (
    <FilePickerContext.Provider
      value={{
        openFileSelector,
        filesContent,
        loading,
        errors,
        compressedImage,
        error
      }}
    >
      {props.children}
    </FilePickerContext.Provider>
  )
}

export function useFilePickerContext() {
  const context = useContext(FilePickerContext)
  if (context === undefined) {
    throw new Error(
      'useFilePickerContext must be used within a FilePickerProvider'
    )
  }
  return context
}
