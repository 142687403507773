import { DialogContent } from '@mui/material'
import { StyledDialog } from './styled.dialog'
import { CloseIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export interface BrowserDialogProps {
  open: boolean
  setOpen: (b: boolean) => void
  url: string
  offsetTop?: number
}

export default function BrowserDialog(props: BrowserDialogProps) {
  const handleClose = () => {
    props.setOpen(false)
  }

  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
  }

  return (
    <StyledDialog
      disableEscapeKeyDown
      open={props.open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '90%',
          maxHeight: '90%',
          width: '90%',
          maxWidth: '90%',
          borderRadius: 8
        }
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'relative'
        }}
      >
        <div
          className="iframe-container"
          style={{
            width: '100%',
            border: 'none',
            position: 'relative'
          }}
        >
          {isLoading && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          )}
          <iframe
            style={{ marginTop: props.offsetTop }}
            src={props.url}
            onLoad={handleLoad}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
          />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: 12,
            right: 15
          }}
        >
          <IconButton
            onClick={() => {
              props.setOpen(false)
            }}
          >
            <CloseIcon width={36} height={36} color="#000" />
          </IconButton>
        </div>
      </DialogContent>
    </StyledDialog>
  )
}
