import SegmentedControl from 'components/segment-control/SegmentControl'
import Groups from './Groups'
import Header from './Header'
import * as Styled from './styled'
import './styles.scss'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'
import { t } from 'i18next'
import { useRef, useState } from 'react'
import { is24meApp } from 'utils/appType'
import Logger from 'services/Logger'
import { useCalendar } from 'components/providers/CalendarProvider'

export default function Sidebar() {
  const uiTypeContext = useAppUIType()
  const calendar = useCalendar()
  const segmentRef = useRef()
  const segments = [
    {
      label: t('calendar'),
      value: AppUIType.calendars,
      ref: useRef()
    },
    {
      label: t('tasks'),
      value: AppUIType.tasks,
      ref: useRef()
    },
    {
      label: t('notes'),
      value: AppUIType.notes,
      ref: useRef()
    }
  ]

  const [currentIndex, setCurrentIndex] = useState(
    uiTypeContext.getCurrentIndex()
  )

  return (
    <Styled.Sidebar className="sidebar">
      <Header />
      {is24meApp() && (
        <Styled.Header sx={{ justifyItems: 'center', alignItems: 'center' }}>
          <SegmentedControl
            name="group-2"
            callback={(item: any) => {
              const selected = uiTypeContext.uiTypes.find(
                (type) => type.value === item
              )

              if (selected) {
                uiTypeContext.onUIType(selected)
                uiTypeContext.setSelectedLabel(undefined)
                calendar.onGroupServer('')
              }

              let index = 0

              if (item === AppUIType.calendars) {
                index = 0
              } else if (item === AppUIType.tasks) {
                index = 1
              } else if (item === AppUIType.notes) {
                index = 2
              }

              setCurrentIndex(index)
            }}
            controlRef={segmentRef}
            currentIndex={currentIndex}
            defaultIndex={currentIndex}
            segments={segments}
          />
        </Styled.Header>
      )}
      <Groups />
    </Styled.Sidebar>
  )
}
