import { Grid } from '@mui/material'
import { EventDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { prepareTasksForUI } from 'utils/api/events'
import Event from 'components/calendar/components/Event'
import { Date, Scrollable, Time } from './styled'
import dayjs from 'dayjs'
import dateToTimeString from 'components/calendar/components'
import { t } from 'i18next'
import { isToday, isTomorrow } from 'date-fns'
import { NoResults } from './GroupsColumn'
import { CalendarRange } from 'types/components/calendar'
import { useAppUIType } from 'components/providers/AppUITypeProvider'

export interface EventsColumnProps {
  events: Model<EventDoc>[] | EventDoc[]
  showTime?: boolean
}

export default function EventsColumn(props: EventsColumnProps) {
  const appui = useAppUIType()
  return (
    <Scrollable>
      <div style={{ height: '8px', width: '100%' }}>
        <br />
      </div>
      <>
        {props.events.length === 0 && <NoResults />}
        {prepareTasksForUI(props.events)
          ?.filter((event) => {
            if (appui.selectedLabel) {
              if (
                event.Label &&
                typeof event.Label !== 'string' &&
                event.Label.some(
                  (ele) => ele.LabelText === appui.selectedLabel?.ID
                )
              ) {
                return true
              } else return false
            }

            return true
          })
          ?.sort((a, b) => {
            if (a.StartDate === b.StartDate) {
              return 0
            }

            return Number(a.StartDate) > Number(b.StartDate) ? 1 : -1
          })
          ?.map((event) => {
            let dateText = dayjs(Number(event.StartDate) * 1000).format(
              'MMM DD, YYYY'
            )
            let timeText = dateToTimeString(
              dayjs(Number(event.StartDate) * 1000).toDate(),
              true
            )
            if (isToday(Number(event.StartDate) * 1000)) {
              dateText = t('today')
            } else if (isTomorrow(Number(event.StartDate) * 1000)) {
              dateText = t('tomorrow')
            }

            if (event.AllDay === '1') {
              timeText = t('allDay')
            }

            return (
              <Grid
                key={`${event._id}_${event.StartDate}_${event.EndDate}`}
                display={'flex'}
                flexDirection={'row'}
                maxHeight={'47px'}
                width={'100%'}
              >
                {props.showTime && (
                  <Grid
                    display={'flex'}
                    flexDirection={'column'}
                    minWidth={'100px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Date>{dateText}</Date>
                    <Time>{timeText}</Time>
                  </Grid>
                )}
                <Event
                  doNotScrollToEvent
                  calendarView={CalendarRange.WEEK}
                  alwaysFullSize
                  event={event}
                  instanceStart={Number(event.StartDate) * 1000}
                  instanceEnd={Number(event.EndDate) * 1000}
                  ignoreDim
                  title={event.Text ?? ''}
                />
              </Grid>
            )
          })}
      </>
      <div style={{ height: '8px', width: '100%' }}>
        <br />
      </div>
    </Scrollable>
  )
}
