import { Model } from '@nozbe/watermelondb'
import { text } from '@nozbe/watermelondb/decorators'

export default class ParticipantSuggestion extends Model {
  static table = 'ParticipantSuggestion'

  @text('email') email!: string
  @text('name') name!: string
  @text('img') img!: string
}
