import { TextareaAutosize } from '@mui/material'
import { CheckmarkIcon, PencilIcon } from 'assets/icons'
import { useConnection } from 'components/providers/ConnectionProvider'
import { useProgress } from 'components/providers/ProgressProvider'
import i18n from 'i18n'
import { ChangeEvent } from 'react'
import { useState } from 'react'
import { updateGroupBI } from 'services/api/group'
import { isOwnerForGroup } from 'services/db/utils/GroupUtils'
import Logger from 'services/Logger'
import { AccountDoc, GroupDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { GENERIC_ERROR } from 'utils/strings'
import * as Styled from '../styled'
import {
  TierFeatures,
  featureToMinimumTierNeeded,
  isUserAdminInGroup
} from 'utils/api/groups'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { Tier, highestActiveTier, paddlePlanToTier } from 'utils/api/accounts'
import { usePayment } from 'components/providers/PaymentProvider'
import { PlanName } from 'components/dialogs/payment/PlansInfoDialog'
import { tierToPlan } from 'components/tier/TierHover'
import { tierFeaturesToAnalyticsFeature } from 'components/tier/OutOfTierDialog'
import { parseErrorToMessage } from '../eventInfo/EventPopup'

interface BusinessInfoProps {
  group: Model<GroupDoc>
  account: Model<AccountDoc>
  setLoading: (b: boolean) => void
  editable?: boolean
}

export default function BusinessInfoSection(props: BusinessInfoProps) {
  const { showPaymentDialog } = usePayment()
  const activeTier = highestActiveTier(props.account)
  const connection = useConnection()
  const progress = useProgress()
  const [text, setText] = useState<string>(props.group.BusinessInfo?.Text ?? '')
  const [editBI, onEditBI] = useState(false)

  const handleClose = (
    status: 'PURCHASED' | 'PURCHASE_ABORTED' | 'DIALOG_CLOSED',
    plan: PlanName
  ) => {
    Logger.debug('plan status; plan; =>', status, plan)

    const shouldHandleAction = paddlePlanToTier(plan) >= Tier.BUSINESS

    console.log(shouldHandleAction)

    if (shouldHandleAction) onEditBI(true)
  }

  const openPayments = () => {
    showPaymentDialog(tierToPlan(Tier.BUSINESS), handleClose, [
      tierFeaturesToAnalyticsFeature(TierFeatures.BUSINESSINFO)
    ])
  }

  const toggleEditBI = () => {
    if (
      activeTier < featureToMinimumTierNeeded(TierFeatures.BUSINESSINFO) &&
      isOwnerForGroup(props.group)
    ) {
      openPayments()
      return
    }

    if (connection.isConnectionAvailable()) onEditBI(!editBI)
  }
  const onSubmitChangeBI = async () => {
    toggleEditBI()

    if (text != props.group.BusinessInfo?.Text) {
      props.setLoading(true)
      try {
        await updateGroupBI(text, props.group)
      } catch (e) {
        Logger.red(String(e))
        progress.setError(parseErrorToMessage(e))
      }

      props.setLoading(false)
    }
  }
  const onBITextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {editBI ? (
        <Styled.GroupBusinessInfoPencilButton
          size="sm"
          onClick={onSubmitChangeBI}
        >
          <CheckmarkIcon color="green" />
        </Styled.GroupBusinessInfoPencilButton>
      ) : isOwnerForGroup(props.group) ||
        isUserAdminInGroup(
          props.group,
          LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
        ) ? (
        <Styled.GroupBusinessInfoPencilButton
          sx={{
            right: i18n.dir() === 'ltr' ? 12 : undefined,
            left: i18n.dir() === 'ltr' ? undefined : 12
          }}
          size="sm"
          onClick={toggleEditBI}
        >
          <PencilIcon />
        </Styled.GroupBusinessInfoPencilButton>
      ) : (
        <></>
      )}
      {editBI ? (
        <TextareaAutosize
          autoFocus
          style={{ padding: 8, width: '100%' }}
          value={text}
          minRows={3}
          onChange={onBITextChange}
        />
      ) : (
        <div>{props.group.BusinessInfo?.Text}</div>
      )}
    </div>
  )
}
