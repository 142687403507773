import { Collection } from '@nozbe/watermelondb'
import { mapKeys } from 'lodash'
import database from 'model'
import Logger from 'services/Logger'
import { EventDoc } from 'types/api/changes'
import { CollectionType, Model } from 'types/model'
import { extract, omit } from 'utils/object'

class Database {
  getCollection<T>(collectionType: CollectionType): Collection<Model<T>> {
    return database.get(collectionType)
  }

  async getById(collectionType: CollectionType, id: string) {
    return database.get(collectionType).find(id)
  }

  async clear() {
    return database.write(async () => {
      return database.unsafeResetDatabase()
    })
  }

  async batch(
    ...e: (false | void | Model<any> | Promise<void> | null)[]
  ): Promise<void> {
    return database.batch(...e)
  }

  async write<T>(cb: () => Promise<T>) {
    return await database.write<T>(cb)
  }

  updateFieldsInLocalDB<T>(existing: Model<T>, dataFromServer: any) {
    return this.write(async () => {
      if (existing && existing.syncStatus != 'deleted') {
        existing.update(() => {
          Object.keys(dataFromServer).forEach((key) => {
            if (['_sortOrder'].includes(key)) {
              ;(existing as any)._raw[key] = dataFromServer[key]
            } else {
              ;(existing as any)[key] = dataFromServer[key]
            }
          })
        })
      }
    })
  }

  toServerObject<T>(data?: Model<T> | EventDoc) {
    if (!data) return {}
    return omit(extract(data, Object.keys(data._raw)), [
      'id',
      '_status',
      '_changed',
      '_message'
    ])
  }

  toCreateObject<T>(data: Model<T>) {
    return omit(extract(data, Object.keys(data._raw)), [
      'id',
      '_status',
      '_changed',
      '_message',
      '_rev',
      '_id',
      'AggregatedParticipantsConfirmationStatus',
      'AggregatedParticipantsDeliveryStatus'
    ])
  }
}

export default new Database()
