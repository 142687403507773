import { Box, Grid, IconButton, ListItemButton, lighten } from '@mui/material'
import { Reorder } from 'framer-motion'
import {
  DocType,
  EventDoc,
  MasterLabelDoc,
  MasterLabelLabel,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import { convertLabelColorToStringColor } from 'utils/masterLabel'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import './styles.scss'
import { theme } from 'theme'
import { t } from 'i18next'
import { AppUIType, useAppUIType } from 'components/providers/AppUITypeProvider'

import withObservables from '@nozbe/with-observables'
import ObserveService from 'services/db/Observe'
import { compose } from 'recompose'
import { ComponentType, useState } from 'react'
import AddLabelPopup from 'components/popups/AddLabelPopup'
import LabelLoader from 'components/loaders/LabelLoader'
import { LabelIcon } from 'assets/icons'

interface LabelsListProps {
  userSettings: Model<UserSettingsDoc>
  events: Model<EventDoc>[]
  masterLabel: Model<MasterLabelDoc>

  handleCloseContextMenu: () => void
  handleContextMenu: (
    event: React.MouseEvent<HTMLElement>,
    label: MasterLabelLabel
  ) => void
}

function LabelsList(props: LabelsListProps) {
  const tfmContext = useAppUIType()
  const showingDocType =
    tfmContext.uiType.value === AppUIType.tasks ? DocType.TASK : DocType.NOTE

  return (
    <>
      {props.masterLabel?.Labels.map((row) => (
        <Reorder.Item as="div" key={row.ID} value={row} dragListener={false}>
          <LabelRow
            label={row}
            itemsAmount={
              props.events?.filter((event) => {
                if (event.Label && typeof event.Label !== 'string') {
                  return event.Label.some(
                    (ele) =>
                      ele.LabelText === row.ID && event.Type === showingDocType
                  )
                }
              }).length ?? 0
            }
            handleContextMenu={props.handleContextMenu}
          />
        </Reorder.Item>
      ))}
      {!props.masterLabel && (
        <Box marginTop={3}>
          {[...Array(3)].map((_, i) => (
            <LabelLoader key={i} />
          ))}
        </Box>
      )}
    </>
  )
}

export default compose(
  withObservables([], () => ({
    userSettings: ObserveService.UserSettings()
  })),
  withObservables([], (props: LabelsListProps) => ({
    events: ObserveService.Events(props.userSettings)
  }))
)(LabelsList as ComponentType<unknown>) as any

interface LabelRowProps {
  label: MasterLabelLabel
  itemsAmount: number
  handleContextMenu: (
    event: React.MouseEvent<HTMLElement>,
    label: MasterLabelLabel
  ) => void
}

function LabelRow(props: LabelRowProps) {
  const tfmContext = useAppUIType()

  const selectLabel = () => {
    tfmContext.setSelectedLabel(props.label)
  }

  const [openAddLabel, setOpenAddLabel] = useState(props.label.ID === 'temp')

  return (
    <AddLabelPopup
      label={props.label}
      edit={tfmContext.labelInfo === props.label.ID}
      open={openAddLabel || tfmContext.labelInfo === props.label.ID}
      setOpen={setOpenAddLabel}
    >
      <ListItemButton
        className="labelRow"
        sx={{
          height: '71px',
          marginLeft: '5px',
          position: 'relative',
          padding: theme.spacing(0, 4),
          textAlign: 'unset !important'
        }}
        onContextMenu={(e) => {
          if (props.handleContextMenu) props.handleContextMenu(e, props.label)
        }}
        onClick={selectLabel}
        selected={tfmContext.selectedLabel?.ID === props.label.ID}
      >
        <Grid
          justifyItems={'center'}
          alignItems={'center'}
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          gap={0}
        >
          <LabelIcon
            color={convertLabelColorToStringColor(props.label.Color)}
            style={{ height: '21px', width: '48px' }}
          />
          <Grid
            width={'100%'}
            item
            display={'flex'}
            flexDirection={'column'}
            marginLeft={'1rem'}
          >
            <Box textAlign={undefined} fontSize={'16px'}>
              {props.label.Text}
            </Box>
            <div className="textGroup">
              {t('itemsAmount', {
                itemsAmount: props.itemsAmount,
                itemTitle:
                  tfmContext.uiType.value === AppUIType.tasks
                    ? t('task', { count: props.itemsAmount })
                    : t('note', { count: props.itemsAmount })
              })}
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            height: '1px',
            width: '85%',
            margin: '0 8px',
            background: theme.palette.border.primary
          }}
        ></div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if (props.handleContextMenu) props.handleContextMenu(e, props.label)
          }}
          className="hoverButton"
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemButton>
    </AddLabelPopup>
  )
}
