import { GroupPrivacy } from '../../../model/models/Group'
import { GroupDoc } from '../../../types/api/changes'
import { Model } from '../../../types/model'
import Switch from '../../../components/form/Switch'
import PopupSectionRow from '../../../components/popup/PopupSectionRow'
import { ILoading } from './GroupPopup'
import Logger from '../../../services/Logger'
import { updateGroupOnServer } from '../../../services/api/group'
import LocalStorage from '../../../services/LocalStorage'
import {
  GROUP_INFO_ACTION_TYPES,
  UpdateGroupPrivacyMode
} from '../../../types/api/group'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'
import { LocalStorageKey } from '../../../types/services/localStorage'
import { useState } from 'react'
import { useConnection } from 'components/providers/ConnectionProvider'
import { useProgress } from 'components/providers/ProgressProvider'
import { GENERIC_ERROR } from 'utils/strings'
import { Tier } from 'utils/api/accounts'
import {
  featureToMinimumTierNeeded,
  isUserOwnerOfGroup,
  TierFeatures
} from 'utils/api/groups'

import { t } from 'i18next'

interface ChannelToggleProps extends ILoading {
  group: Model<GroupDoc>
}

export default function ChannelToggle(props: ChannelToggleProps) {
  const { setError, setLoading } = useProgress()
  const [openMakeChannel, setOpenMakeChannel] = useState(false)
  const connection = useConnection()

  const makeCalendarChannel = async () => {
    setOpenMakeChannel(false)

    if (connection.isConnectionAvailable()) {
      setLoading(true)

      const data: UpdateGroupPrivacyMode = {
        PrivacyMode: GroupPrivacy.CHANNEL,
        GroupSettings: {
          IsAllParticipantsCanAddItems: '0',
          IsAllParticipantsCanAddParticipants: '0',
          IsAllParticipantsCanEditGroupMetadata: '0'
        },
        DeviceChangeID: LocalStorage.get(LocalStorageKey.DEVICE_ID),
        ActionType: GROUP_INFO_ACTION_TYPES.GROUP_SETTINGS,
        UserID: LocalStorage.get(LocalStorageKey.USER_ID),
        GroupID: props.group._id
      }
      try {
        await updateGroupOnServer(data, props.group)
      } catch (e) {
        Logger.red(String(e))
        setError(GENERIC_ERROR)
      }
      setLoading(false)
    }
  }

  const closeMakeChannel = () => {
    setOpenMakeChannel(false)
  }

  const showMakeChannel = () => {
    setOpenMakeChannel(true)
  }

  let neededTier = featureToMinimumTierNeeded(TierFeatures.CHANNEL)

  if (
    !isUserOwnerOfGroup(
      props.group,
      LocalStorage.get(LocalStorageKey.USER_ID) ?? ''
    )
  )
    neededTier = Tier.FREE

  return (
    <PopupSectionRow
      tierFeature={TierFeatures.CHANNEL}
      neededTier={neededTier}
      title={t('setAsCalendarChannel')}
      action={showMakeChannel}
      value={
        <div>
          <Switch
            onChange={(event) => {
              showMakeChannel()
            }}
          />
          {openMakeChannel && (
            <ConfirmationDialog
              title={`${t('areYouSure')}`}
              followForm
              content={t('calendarChannelDesc')}
              handleRightButton={makeCalendarChannel}
              open={openMakeChannel}
              leftButtonColor={'black'}
              handleLeftButton={closeMakeChannel}
              handleClose={closeMakeChannel}
            />
          )}
        </div>
      }
    />
  )
}
