import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import './styles.scss'

export const border = '1px solid #e4e4e4'

interface CardHeaderProps {
  title: string
  subtitle: string
  weather?: ReactNode
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  subtitle,
  weather
}) => {
  return (
    <Box
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'row'}
      borderBottom={border}
      borderTop={border}
      padding={'1.25rem'}
    >
      <div>
        <div className="title">{title}</div>
        <div className="subTitle" style={{ marginTop: 4 }}>
          {subtitle}
        </div>
      </div>
      <div>{weather}</div>
    </Box>
  )
}
