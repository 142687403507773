import withObservables from '@nozbe/with-observables'
import {
  DefaultCalendar,
  EyeCrossIcon,
  GoogleProblem,
  GroupChannelIcon,
  PinIcon,
  ReminderCrossIcon
} from 'assets/icons'
import { GroupPrivacy } from 'model/models/Group'
import ObserveService from 'services/db/Observe'
import {
  AccountDoc,
  EventDoc,
  GroupDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import {
  defaultGroupImage,
  formatLastUpdate,
  getGroupMessage,
  isGroupChannel,
  isGroupOutOfTier
} from 'utils/api/groups'
import groupDefaultPhoto from '../../assets/icons/group-default.svg'
import channelDefaultPhoto from '../../assets/icons/channel_calendar.svg'

import * as Styled from './styled'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import GroupTitle from 'components/popups/groupInfo/GroupTitle'
import { CalendarHeaderTitle } from 'components/calendar/styled'
import { highestActiveTier } from 'utils/api/accounts'
import ContainerWithBottomBadgeIcon from 'components/containers/ContainerWithBottomBadgeIcon'
import { useTranslation } from 'react-i18next'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import { PrivateLabel } from './styled'
import { useState } from 'react'
import { APP_COLOR } from 'utils/colors'
import { GoogleCalendar, ICalendar } from 'types/google_events'
import Database from 'services/db/Database'
import { isGroupArchived, isGroupUnread } from 'utils/api/userSettings'
import { tfmGoogleTempLabel } from 'services/db/Queries/GroupQueries'
import { useCalendar } from 'components/providers/CalendarProvider'
import { Box, CircularProgress, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import './styles.scss'
import { isConvertedGroup } from 'utils/groups'
import { ResyncCalendarDialog } from 'components/dialogs/ResyncGoogleDialog'
import { calendarsGif } from 'assets/gif'

interface GroupCardProps {
  account?: Model<AccountDoc>
  userSettings: Model<UserSettingsDoc> | UserSettingsDoc | undefined
  group: Model<GroupDoc> | GroupDoc
  LastEvent?: Model<EventDoc>
  default: boolean
  muted: boolean
  hidden: boolean
  pinned: boolean
  onClick?: () => void
  unread?: number
  handleContextMenu: (
    event: React.MouseEvent<HTMLElement>,
    group: GroupDoc
  ) => void
  disableSelection?: boolean
  hideMetaData?: boolean
}

function GroupCard(props: GroupCardProps) {
  const calendar = useCalendar()
  const { group, LastEvent } = props
  const groupsSettings = props.userSettings?.GroupsSettings
  const groupColor = groupsSettings?.[props.group?._id ?? '']?.GroupColor
  const isBlocked = isGroupOutOfTier(
    highestActiveTier(props.account),
    group,
    props.account
  )

  const isSyncProblem = group.SyncProblem === '1'
  const lockIcon = isSyncProblem ? <GoogleProblem /> : null

  const lockAction = () => {
    if (isSyncProblem) {
      onShowSyncProblemDialog(true)
    }
  }

  const hideSyncProblem = () => {
    onShowSyncProblemDialog(false)
  }

  const { t, i18n } = useTranslation()

  const [showSyncProblemDialog, onShowSyncProblemDialog] = useState(false)

  function onGrantedCalendarAccess(calendar: ICalendar) {
    Database.write(async () => {
      try {
        if ((props.group._rev?.length ?? 0) > 0)
          if (props.group.id)
            await props.group.update((local: GroupDoc) => {
              local.SyncProblem = null
            })
      } catch (e) {
        console.error(e)
      }
    })
  }

  function initiateGoogleSync() {
    calendar.initiateGoogleSync(
      (cal) => {
        onGrantedCalendarAccess(cal)
        calendar.onGroupServer(`${prefixGoogleEventsGroup}${cal.id}`)
        onShowProgress(false)
      },
      () => {
        onShowProgress(false)
      },
      () => {
        onShowProgress(true)
      }
    )
    hideSyncProblem()
  }

  const [showProgress, onShowProgress] = useState(false)

  const cardClick = () => {
    if (props.group._id?.includes(tfmGoogleTempLabel)) {
      //add google cal here

      initiateGoogleSync()
      return
    }

    if (props.onClick) {
      props.onClick()
    }
  }

  let showSubLabel = isConvertedGroup(group) || group._id?.includes('tempid')

  if (isGroupArchived(props.userSettings, group._id ?? '')) {
    showSubLabel = true
  }

  return (
    <Styled.GroupCard
      id={`${group.Name}${(group._id?.includes(tfmGoogleTempLabel)
        ? APP_COLOR
        : groupColor ?? group.GroupColor
      ).replace('#', '')}`}
      onClick={cardClick}
      className="groupRow"
      onContextMenu={(e) => {
        props.handleContextMenu(e, props.group)
      }}
    >
      <Styled.GroupCardButton
        selected={
          props.disableSelection
            ? false
            : group._id === LocalStorage.get(LocalStorageKey.SELECTED_GROUP) ||
              isBlocked
        }
      >
        {showProgress ? (
          <CircularProgress color="inherit" />
        ) : (
          <ContainerWithBottomBadgeIcon
            showLock={isBlocked || isSyncProblem}
            lockIcon={lockIcon}
            topRightIcon={
              isGroupChannel(props.group) ? (
                <GroupChannelIcon width={18} height={18} />
              ) : undefined
            }
            action={lockAction}
          >
            <Styled.GroupCardAvatarContainer
              color={
                group._id?.includes(tfmGoogleTempLabel)
                  ? APP_COLOR
                  : groupColor ?? group.GroupColor
              }
            >
              <Styled.GroupCardAvatar
                asGroup
                img={group.Photo}
                name=""
                emptyPic={
                  group._id === 'tempid'
                    ? calendarsGif
                    : isConvertedGroup(group)
                    ? defaultGroupImage(group)
                    : group.PrivacyMode === GroupPrivacy.CHANNEL
                    ? channelDefaultPhoto
                    : groupDefaultPhoto
                }
              />
            </Styled.GroupCardAvatarContainer>
          </ContainerWithBottomBadgeIcon>
        )}
        <Styled.GroupCardContent>
          <Styled.GroupCardTitleContainer>
            <CalendarHeaderTitle sx={{ fontSize: '1rem', maxWidth: '170px' }}>
              <GroupTitle group={group} iconHeight={'16px'} />
            </CalendarHeaderTitle>
            {showSubLabel ? (
              <PrivateLabel>
                {isGroupArchived(props.userSettings, group._id ?? '')
                  ? t('archived')
                  : t('private')}
              </PrivateLabel>
            ) : (
              <Styled.GroupCardTime>
                {formatLastUpdate(
                  group._sortOrder && group._sortOrder > 0
                    ? group._sortOrder
                    : Number(group.LastUpdate)
                )}
              </Styled.GroupCardTime>
            )}
          </Styled.GroupCardTitleContainer>

          <Styled.GroupInfoWrapper>
            <Styled.GroupCardInfoContainer>
              <div className="textGroup">
                {getGroupMessage(group, LastEvent)}
              </div>
            </Styled.GroupCardInfoContainer>
            {props.hideMetaData ? (
              <></>
            ) : (
              <Styled.GroupIcon>
                <Box className="iconsContainer" flexDirection={'row'}>
                  {props.default && <DefaultCalendar />}
                  {props.muted && <ReminderCrossIcon data-icon="mute" />}
                  {props.hidden && <EyeCrossIcon data-icon="hide" />}
                  {isGroupUnread(props.userSettings, props.group._id ?? '') ? (
                    <Styled.UnreadAmount></Styled.UnreadAmount>
                  ) : (
                    (props?.unread ?? 0) > 0 && (
                      <Styled.UnreadAmount>{props.unread}</Styled.UnreadAmount>
                    )
                  )}
                  {props.pinned && <PinIcon />}
                </Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (props.handleContextMenu)
                      props.handleContextMenu(e, props.group)
                  }}
                  className="hoverButton"
                >
                  <MoreVertIcon />
                </IconButton>
              </Styled.GroupIcon>
            )}
          </Styled.GroupInfoWrapper>
        </Styled.GroupCardContent>
      </Styled.GroupCardButton>
      {showSyncProblemDialog && (
        <ResyncCalendarDialog
          isOpen={showSyncProblemDialog}
          setOpen={onShowSyncProblemDialog}
          group={props.group}
        />
      )}
    </Styled.GroupCard>
  )
}

export default withObservables(['group'], (props: GroupCardProps) => ({
  account: ObserveService.Account(),
  LastEvent: ObserveService.GroupLastEvent(props.group)
}))(GroupCard)
