import { GroupDefaultIcon } from 'assets/icons'
import { ReactNode, useEffect, useState } from 'react'
import { getRandomColor } from 'utils/colors'

import * as Styled from './styled'

interface AvatarProps {
  name: string
  className?: string
  size?: 'sm' | 'xs' | 'xxs' | number
  colored?: boolean
  asGroup?: boolean
  img?: string
  emptyPic?: string
  color?: string
}

export default function Avatar(props: AvatarProps) {
  const [innerImg, onInnerImg] = useState(props.img)

  useEffect(() => {
    onInnerImg(props.img)
  }, [props.img])
  return (
    <Styled.Avatar
      className={props.className}
      data-size={props.size}
      data-colored={props.color && true}
      data-asgroup={props.asGroup}
      {...(props.color && { style: { backgroundColor: props.color } })}
    >
      {innerImg && innerImg.length > 0 ? (
        <img
          src={innerImg}
          alt={props.emptyPic}
          onError={() => onInnerImg(props.emptyPic)}
        />
      ) : props.asGroup ? (
        <img src={props.emptyPic} />
      ) : (
        <div style={{ paddingBottom: '1px' }}>{getSymbols(props.name)}</div>
      )}
    </Styled.Avatar>
  )
}

function getSymbols(name: string) {
  return name[0]?.toUpperCase()
}
