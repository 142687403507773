import { createContext, ReactNode, useContext, useState } from 'react'
import { COLORS } from 'utils/colors'

interface AddGroupProviderProps {
  children: ReactNode
}

export interface AddGroupContextProps {
  color: string
  setColor: (color: string) => void
  title: string
  setTitle: (title: string) => void
  isCalendarChannel: boolean
  setIsCalendarChannel: (isChannel: boolean) => void
  link: string
  setLink: (link: string) => void
  setPhoto: (data: string) => void
  photo: string
}

const AddGroupContext = createContext<AddGroupContextProps>({
  color: '',
  setColor: () => {},
  title: '',
  setTitle: () => {},
  isCalendarChannel: false,
  setIsCalendarChannel: () => {},
  link: '',
  setLink: () => {},
  setPhoto: () => {},
  photo: ''
})

export default function AddGroupProvider(props: AddGroupProviderProps) {
  const [groupColor, setGroupColor] = useState(COLORS[0]!)
  const [title, setTitle] = useState('')
  const [channel, setChannel] = useState(false)
  const [link, setLink] = useState<string>('')

  const [photo, setPhoto] = useState('')

  const onGroupColor = (color: string) => {
    setGroupColor(color)
  }
  const onTitle = (title: string) => {
    setTitle(title)
  }
  const onChannel = (isChannel: boolean) => {
    setChannel(isChannel)
  }
  const onSetLink = (link: string) => {
    setLink(link)
  }
  const onSetPhoto = (photo: string) => {
    setPhoto(photo)
  }

  const context: AddGroupContextProps = {
    color: groupColor,
    setColor: onGroupColor,
    title: title,
    setTitle: onTitle,
    isCalendarChannel: channel,
    setIsCalendarChannel: onChannel,
    link: link,
    setLink: onSetLink,
    photo: photo,
    setPhoto: onSetPhoto
  }

  return (
    <AddGroupContext.Provider value={context}>
      {props.children}
    </AddGroupContext.Provider>
  )
}

export function useAddGroup(): AddGroupContextProps {
  return useContext(AddGroupContext)
}
