import { DialogContent, Dialog } from '@mui/material'
import { DialogTitle, IconButton } from '@mui/material'
import { CloseIcon } from 'assets/icons'
import React from 'react'

interface ImageDetailsDialogProps {
  open: boolean
  onClose: () => void
  imageBase64?: string
}

const ImageDetailsDialog: React.FC<ImageDetailsDialogProps> = ({
  open,
  onClose,
  imageBase64
}) => {
  return (
    <Dialog
      style={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          src={`data:image/png;base64,${imageBase64}`}
          style={{ maxWidth: '100%', height: '80vh', objectFit: 'contain' }}
          alt="Note"
        />
      </DialogContent>
    </Dialog>
  )
}

export default ImageDetailsDialog
