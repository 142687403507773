import { MenuContactIcon, MenuUserGuideIcon } from 'assets/icons/menu'
import BrowserDialog from 'components/dialogs/BrwoserDialog'
import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import i18n from 'i18n'
import { t } from 'i18next'
import { useState } from 'react'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import UAParser from 'ua-parser-js'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { SUPPORT_EMAIL, SUPPORT_EMAIL_TFM } from 'utils/strings'

export interface HelpCenterSectionProps {
  setPopupOpen: (b: boolean) => void
}

export default function HelpCenterSection(props: HelpCenterSectionProps) {
  const onContactUs = () => {
    const subject = t(isGroupcalApp() ? 'emailSubject' : 'emailSubjectTfm')

    window.open(
      `mailto:${
        isGroupcalApp() ? SUPPORT_EMAIL : SUPPORT_EMAIL_TFM
      }?subject=${subject}&body=${` %0D%0A%0D%0A%0D%0A%0D%0A Technical info for this report: ${browserDetails()}`}`
    )
    props.setPopupOpen(false)
  }

  return (
    <PopupSection title={`${t('helpCenter')}`}>
      <PopupSectionRow
        icon={<MenuContactIcon />}
        title={t('contactUs')}
        iconClass="icon-regular"
        maxWidthIcon={20}
        onlyTitle
        action={onContactUs}
      />
      <PopupSectionRow
        title={t('userGuide')}
        icon={<MenuUserGuideIcon />}
        iconClass="icon-regular"
        maxWidthIcon={20}
        action={() => {
          window.open(
            is24meApp()
              ? 'https://24me.zendesk.com/'
              : 'https://www.groupcal.app/guide/',
            '_blank'
          )
        }}
      />
    </PopupSection>
  )
}

function browserDetails(): string {
  const deviceData = UAParser(window.navigator.userAgent)

  return (
    '%0D%0A' +
    `Browser: ${deviceData.browser.name}; ver: ${deviceData.browser.version}` +
    '%0D%0A' +
    `Language: ${i18n.language}` +
    '%0D%0A' +
    `Phone: ${LocalStorage.get(LocalStorageKey.PHONE_NUMBER)}` +
    '%0D%0A' +
    `Version: ${process.env.APP_VERSION}`
  )
}
