import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber
} from 'firebase/auth'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'

import { firebaseApp, firebaseAuth } from '../firebase-config'

import {
  Messaging,
  getMessaging,
  getToken,
  onMessage
} from 'firebase/messaging'
import { doGetChanges } from 'components/providers/GetChangesProvider'
import { isSupported } from 'firebase/messaging'
import Logger from 'services/Logger'

export function generateRecaptcha() {
  window.recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha',
    {
      size: 'invisible',
      callback: () => {}
    },
    firebaseAuth
  )
}

export function getRecaptcha(): RecaptchaVerifier {
  return window.recaptchaVerifier
}

export function getConfirmationResult(): ConfirmationResult {
  return window.confirmationResult
}

export async function getFCMToken(): Promise<Promise<string> | undefined> {
  const supported = await isSupported()

  if (typeof Notification === 'undefined') return undefined
  if (!supported) return undefined

  const messaging: Messaging = getMessaging(firebaseApp)

  onMessage(messaging, (p) => {
    Logger.debug('push received', p)
    Logger.debug(
      'same user for push?',
      LocalStorage.get(LocalStorageKey.USER_ID) === p.data?.UserID
        ? 'Yes'
        : 'No'
    )
    if (
      p.data?.Type === '1' &&
      LocalStorage.get(LocalStorageKey.USER_ID) === p.data?.UserID
    ) {
      doGetChanges(
        LocalStorage.get(LocalStorageKey.USER_ID),
        undefined,
        LocalStorage.get(LocalStorageKey.LAST_UPDATE)
      )
    }
  })

  const permission = await Notification.requestPermission()

  if (permission === 'granted') return getToken(messaging)
  else return undefined
}

export function verifyPhoneNumber(phone: string) {
  return signInWithPhoneNumber(firebaseAuth, phone, getRecaptcha()).then(
    (result) => {
      window.confirmationResult = result
      LocalStorage.set(LocalStorageKey.PHONE_NUMBER, phone)
    }
  )
}

export async function verifyPinCode(code: string): Promise<[string, string]> {
  const result = await getConfirmationResult().confirm(code)
  const token = await result.user?.getIdToken()

  LocalStorage.set(LocalStorageKey.FIREBASE_TOKEN, token)

  return [token, result.user.phoneNumber as string]
}
