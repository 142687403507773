import FullCalendar from '@fullcalendar/react'
import { dateFormat } from 'components/calendar/CalendarHeader'
import dayjs, { Dayjs } from 'dayjs'
import usePersistState from 'hooks/usePersistState'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import LocalStorage from 'services/LocalStorage'
import { CalendarRange } from 'types/components/calendar'
import { Option } from 'types/components/form'
import { LocalStorageKey } from 'types/services/localStorage'
import i18n from 'i18n'
import Logger from 'services/Logger'
import { GoogleCalendar, ICalendar } from 'types/google_events'
import { prefixGoogleEventsGroup } from 'utils/google_events'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { t } from 'i18next'
import { is24meApp } from 'utils/appType'
import { APP_COLOR } from 'utils/colors'
import { setDefaultCalendarIfNeeded } from './GetChangesProvider'
import { ProviderType, useCalendarSync } from './CalendarSyncProdivder'
import { Ref } from '@fullcalendar/core/preact'

interface CalendarContextProps {
  ref: Ref<FullCalendar>
  view: CalendarRange
  groupServerID: string
  onGoToToday: () => void
  onView: (view: CalendarRange) => void
  next: () => void
  prev: () => void
  onGoToDate: (date: Date) => void
  onGroupServer: (id: string) => void
  currentMonth: string
  onCurrentMonth: (month: string) => void
  monthArray: Option[]
  onSetMonthArray: (array: Option[]) => void
  groupInfo: string | undefined
  onGroupInfo: (id: string | undefined) => void
  initiateGoogleSync: (
    onComplete?: (calendar: ICalendar) => void,
    onCancel?: () => void,
    startedProcess?: () => void
  ) => void
  weekArray: Option[]
  scrollToTime: Dayjs
  onScrollToTime: (d: Dayjs) => void
}
interface CalendarProviderProps {
  children: ReactNode
}

const CalendarContext = createContext<CalendarContextProps>({
  ref: { current: {} as FullCalendar },
  view: CalendarRange.MONTH,
  groupServerID: '',
  onGoToToday: () => {},
  onView: () => {},
  next: () => {},
  prev: () => {},
  onGoToDate: () => {},
  onGroupServer: () => {},
  currentMonth: '',
  onCurrentMonth: () => {},
  onSetMonthArray: () => {},
  monthArray: [],
  groupInfo: undefined,
  onGroupInfo: () => {},
  initiateGoogleSync: () => {},
  weekArray: [],
  scrollToTime: dayjs(),
  onScrollToTime: (d: Dayjs) => {}
})

export const CALENDAR_VIEW = {
  [CalendarRange.DAY]: 'timeGridDay',
  [CalendarRange.WEEK]: 'timeGridWeek',
  [CalendarRange.MONTH]: 'dayGridMonth',
  [CalendarRange.LISTWEEK]: 'listWeek'
}

export default function CalendarProvider(props: CalendarProviderProps) {
  const ref = useRef<FullCalendar>()

  const [scrollToTime, onScrollToTime] = useState<Dayjs>(dayjs())
  const [monthArray, onSetMonthArray] = useState<Option[]>(buildMonthsArray())
  const [weekArray, onSetWeekArray] = useState<Option[]>(buildWeekArray())
  const [groupServerID, onGroupServerID] = usePersistState(
    '',
    LocalStorageKey.SELECTED_GROUP
  )

  window.onGroupServerID = onGroupServerID
  const [groupInfo, onGroupInfo] = useState<string | undefined>(undefined)
  const [currentView, onCurrentView] = usePersistState(
    LocalStorage.get(LocalStorageKey.PHONE_NUMBER)
      ? CalendarRange.WEEK
      : CalendarRange.MONTH,
    LocalStorageKey.CALENDAR_VIEW
  )
  const [currentMonth, onCurrentMonth] = useState<string>(
    dayjs(Date.now()).set('date', 1).format(dateFormat)
  )

  function switchMonthIfNeeded(isNext: boolean) {
    const currentMonthDayJS = dayjs(ref.current?.getApi().getDate()).set(
      'date',
      1
    )
    const currentMonth = currentMonthDayJS.format(dateFormat)

    if (!monthArray.find((option) => option.value === currentMonth)) {
      const newMonth = {
        label: currentMonthDayJS.format('MMM YYYY'),
        value: currentMonth
      }
      if (isNext) {
        onSetMonthArray([...monthArray, newMonth])
      } else {
        onSetMonthArray([newMonth, ...monthArray])
      }
    }

    onCurrentMonth(currentMonth)
  }

  useEffect(() => {
    ref.current?.getApi()?.scrollToTime(scrollToTime.format('HH:mm'))
  }, [scrollToTime])

  const onView = (view: CalendarRange) => {
    onCurrentView(view)
    ref.current?.getApi()?.changeView(CALENDAR_VIEW[view])
  }

  const next = () => {
    ref.current?.getApi()?.next()
    switchMonthIfNeeded(true)
  }

  const prev = () => {
    ref.current?.getApi()?.prev()
    switchMonthIfNeeded(false)
  }

  const onGroupServer = (id: string) => {
    LocalStorage.set(LocalStorageKey.SELECTED_GROUP, id)
    onGroupServerID(id)
  }

  const onGoToToday = () => {
    const visibleFrom = dayjs(ref.current?.getApi().view.activeStart)
    const visibleTo = dayjs(ref.current?.getApi().view.activeEnd)
    const today = dayjs()

    // check if today is visible
    if (today.isSameOrAfter(visibleFrom) && today.isSameOrBefore(visibleTo)) {
      return
    }

    ref.current?.getApi()?.today()

    onCurrentMonth(dayjs().startOf('month').format(dateFormat))
    onScrollToTime(dayjs())
  }

  const onGoToDate = (date: Date) => {
    try {
      ref.current?.getApi().gotoDate(date)
    } catch (e) {
      Logger.red(`${e}`)
    }

    onCurrentMonth(dayjs(date).set('date', 1).format(dateFormat))
  }

  const context: CalendarContextProps = {
    ref: ref as Ref<FullCalendar>,
    view: currentView,
    groupServerID,
    onGoToToday,
    onView,
    next,
    prev,
    onGroupServer,
    onGoToDate,
    currentMonth,
    onCurrentMonth,
    monthArray,
    onSetMonthArray,
    groupInfo,
    onGroupInfo,
    initiateGoogleSync,
    weekArray: [],
    scrollToTime,
    onScrollToTime
  }

  const calendarSync = useCalendarSync()

  const [showNonGrantedGoogleCalendar, onShowNonGrantedGoogleCalendar] =
    useState(false)

  function onNonGrantedCalendarAccess() {
    onShowNonGrantedGoogleCalendar(true)
  }

  function onGrantedCalendarAccess(calendar: ICalendar) {
    onGroupServer(`${prefixGoogleEventsGroup}${calendar.id}`)
  }

  function initiateGoogleSync(
    onComplete?: (calendar: ICalendar) => void,
    onCancel?: () => void,
    startedProcess?: () => void
  ) {
    const success = (googleCalendar: ICalendar) => {
      onGrantedCalendarAccess(googleCalendar)
      if (onComplete) onComplete(googleCalendar)

      if (is24meApp()) setDefaultCalendarIfNeeded()
    }

    const cancel = () => {
      onNonGrantedCalendarAccess()
      if (onCancel) onCancel()
    }

    const process = () => {
      if (startedProcess) startedProcess()
    }

    calendarSync.connect(ProviderType.GOOGLE, success, cancel, process)

    hideNonGrantedGoogleCal()
  }

  const hideNonGrantedGoogleCal = () => {
    onShowNonGrantedGoogleCalendar(false)
  }

  return (
    <CalendarContext.Provider value={context}>
      {props.children}
      {showNonGrantedGoogleCalendar && (
        <ConfirmationDialog
          title={`${t('noGooglePermissionsTitle')}`}
          content={`${t('noGooglePermissionsMessage', {
            app: is24meApp() ? t('appName24me') : t('appName')
          })}`}
          rightButton={`${t('retry')}`}
          rightButtonColor={APP_COLOR}
          leftButton={`${t('cancel')}`}
          handleRightButton={() => {
            calendarSync.connect(ProviderType.GOOGLE, onGrantedCalendarAccess)
          }}
          handleLeftButton={hideNonGrantedGoogleCal}
          handleClose={hideNonGrantedGoogleCal}
          leftButtonColor="black"
          open={showNonGrantedGoogleCalendar}
        />
      )}
    </CalendarContext.Provider>
  )
}

export function useCalendar(): CalendarContextProps {
  return useContext(CalendarContext)
}

function buildMonthsArray(): Option[] {
  let months: Option[] = []
  let now = dayjs(Date.now()).set('date', 1).locale(i18n.language)

  for (let index = 2; index >= 0; index--) {
    const prevMonth = now.add(index * -1, 'month')
    months.push({
      label: prevMonth.format('MMM YYYY'),
      value: prevMonth.format(dateFormat)
    })
  }

  for (let index = 1; index < 12; index++) {
    const nextMonth = now.add(index, 'month')
    months.push({
      label: nextMonth.format('MMM YYYY'),
      value: nextMonth.format(dateFormat)
    })
  }

  return months
}

function buildWeekArray(): Option[] {
  let weeks: Option[] = []
  let now = dayjs(Date.now()).set('date', 1).locale(i18n.language)

  for (let index = 2; index >= 0; index--) {
    const prevWeek = now.add(index * -1, 'month')
    weeks.push({
      label: prevWeek.format('MMM YYYY'),
      value: prevWeek.format(dateFormat)
    })
  }

  for (let index = 1; index < 12; index++) {
    const nextWeek = now.add(index, 'month')
    weeks.push({
      label: nextWeek.format('MMM YYYY'),
      value: nextWeek.format(dateFormat)
    })
  }

  return weeks
}
