import { ReactNode } from 'react'

import * as Styled from './styled'

interface CardProps {
  children: ReactNode
  padding?: boolean
  className?: string
}

export default function Card(props: CardProps) {
  return (
    <Styled.Card data-padding={props.padding} className={props.className}>
      {props.children}
    </Styled.Card>
  )
}
