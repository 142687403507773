import { GroupCardAvatarContainer } from 'components/cards/styled'
import i18n from 'i18n'
import { theme } from 'theme'
import { GroupDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { defaultGroupImage } from 'utils/api/groups'
import { prefixGoogleEventsGroup } from 'utils/google_events'

import Avatar from './Avatar'
import * as Styled from './styled'
import { is24meApp } from 'utils/appType'
import { isConvertedGroup } from 'utils/groups'

interface MultiAccountBadgeProps {
  className?: string
  images: string[]
  groups?: Model<GroupDoc>[]
}

export default function MultiAccountBadge(props: MultiAccountBadgeProps) {
  const dir = i18n.dir()

  const nonSharedGroups =
    props.groups?.filter((group) => isConvertedGroup(group)) ?? []
  let groupsForUI = [
    ...nonSharedGroups.slice(0, 1),
    ...(props.groups?.slice(nonSharedGroups.length, props.groups?.length - 1) ??
      [])
  ]

  const border = '3px'

  if (is24meApp()) return <></>

  return (
    <Styled.MultiAccountBadge data-dir={dir} className={props.className}>
      {groupsForUI?.[0] ? (
        <GroupCardAvatarContainer
          data-size="sm"
          borderWidth={border}
          color={'white'}
        >
          <Avatar
            size="sm"
            name=""
            asGroup
            img={groupsForUI[0].Photo}
            emptyPic={defaultGroupImage(groupsForUI[0])}
          />
        </GroupCardAvatarContainer>
      ) : (
        <></>
      )}
      {groupsForUI?.[1] ? (
        <GroupCardAvatarContainer
          data-size="sm"
          borderWidth={border}
          color={'white'}
        >
          <Avatar
            size="sm"
            name=""
            asGroup
            img={groupsForUI[1].Photo}
            emptyPic={defaultGroupImage(groupsForUI[1])}
          />
        </GroupCardAvatarContainer>
      ) : (
        <></>
      )}
      {groupsForUI?.[2] ? (
        <GroupCardAvatarContainer
          data-size="sm"
          borderWidth={border}
          color={'white'}
        >
          <Avatar
            size="sm"
            name=""
            asGroup
            img={groupsForUI[2].Photo}
            emptyPic={defaultGroupImage(groupsForUI[2])}
          />
        </GroupCardAvatarContainer>
      ) : (
        <></>
      )}
    </Styled.MultiAccountBadge>
  )
}
