import PopupSection from 'components/popup/PopupSection'
import PopupSectionRow from 'components/popup/PopupSectionRow'
import AccountActionsPopup from './AccountActionsPopup'
import { t } from 'i18next'
import { MenuAccount, MenuUserGuideIcon } from 'assets/icons/menu'
import { ArrowAllCalendars } from 'assets/icons'

import { AccountDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface AccountSectionProps {
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
}

export default function AccountSection(props: AccountSectionProps) {
  const { t, i18n } = useTranslation()

  const [showAccount, setShowAccount] = useState(false)

  return (
    <PopupSection>
      <AccountActionsPopup
        account={props.account}
        userSettings={props.userSettings}
        setOpen={setShowAccount}
        open={showAccount}
      >
        <span>
          <PopupSectionRow
            title={t('account')}
            icon={<MenuAccount />}
            iconClass="icon-regular"
            maxWidthIcon={20}
            value={
              <ArrowAllCalendars
                style={{
                  transform: i18n.dir() === 'ltr' ? '' : 'rotate(180deg)',
                  cursor: 'pointer'
                }}
              />
            }
            action={() => {
              setShowAccount(true)
            }}
          />
        </span>
      </AccountActionsPopup>
    </PopupSection>
  )
}
