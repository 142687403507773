import { darken } from '@mui/material'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { icloudPrefix } from 'components/providers/icloud/IcloudProvider'
import { DocType, EventDoc, EventStatus } from 'types/api/changes'
import { LocalStorageKey } from 'types/services/localStorage'
import { getAccessToken } from 'utils/auth'
import { uuid } from 'utils/uuid'

import { LOGIN_URL } from './api/auth'
import LocalStorage from './LocalStorage'

export const fetchCalendars = '/calendars'
export const fetchCalendarItems = '/calendars/items'
export const addCalendarItem = '/calendarobject/create'
export const updateCalendarItem = '/calendarobject/update'
export const deleteCalendarItem = '/calendarobject/delete'

export const API_URL =
  process.env.REACT_APP_API_URL ?? 'https://stage001.twentyfour.me'

class IcloudApi {
  instance = axios.create({
    baseURL: `${API_URL}/api/v1/icloud`
  })

  constructor() {
    this.instance.interceptors.request.use((req) => {
      if (getAccessToken()) {
        req.headers = {
          ...req.headers,
          Authorization: req.url?.includes(LOGIN_URL)
            ? false
            : (getAccessToken() as string)
        }
      }
      return req
    })
  }

  private post(
    url: string,
    body: Record<string, string | number | object | undefined | null>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const postBody = {
      ...body
    }

    return this.instance.post(url, postBody, config).then((e) => {
      return e
    })
  }

  public async getCalendars(data: any): Promise<any[]> {
    return (await this.post(fetchCalendars, data)).data
  }

  public async addCalendarItemToIcloud(
    addCalendarData: AddCalendarData
  ): Promise<any> {
    return (
      await this.post(addCalendarItem, { ...addCalendarData, _id: uuid() })
    ).data
  }

  public async updateCalendarItemToIcloud(
    addCalendarData: AddCalendarData
  ): Promise<any> {
    return (await this.post(updateCalendarItem, { ...addCalendarData })).data
  }

  public async deleteCalendarItemFromIcloud(
    deleteEventData: DeleteEventData
  ): Promise<any> {
    return (await this.post(deleteCalendarItem, { ...deleteEventData })).data
  }

  public getCalendarItems(email: string, calendar: any): Promise<EventDoc[]> {
    return this.post(fetchCalendarItems, {
      email: email,
      calendar: calendar,
      timeRange: {
        start: LocalStorage.get(LocalStorageKey.VISIBLE_FROM),
        end: LocalStorage.get(LocalStorageKey.VISIBLE_TO)
      }
    }).then((response) => {
      return response.data.map((calDavEventData: IcloudApiEvent) => {
        return convertIcloudEventToEventDoc(calDavEventData, email, calendar)
      })
    })
  }
}

export default new IcloudApi()

export interface AddCalendarData {
  calendar?: string
  email: string
  filename?: string
  iCalEventString?: string
  event: EventDoc
  url?: string
  etag?: string
}

export function convertIcloudEventToEventDoc(
  calDavEventData: IcloudApiEvent,
  email: string,
  calendar: any
) {
  const event: EventDoc = {
    ...calDavEventData,
    attendees: calDavEventData.Attendees || [],
    etag: calDavEventData.etag,
    ConferenceData:
      typeof calDavEventData?.ConferenceData !== 'string'
        ? calDavEventData.ConferenceData
        : undefined,
    local_id: calDavEventData._id,
    Rank: calDavEventData.CreatedDate,
    GroupID: `${icloudPrefix}${email}`,
    Type: DocType.REGULAR_EVENT,
    Status: EventStatus.ACTIVE,
    OwnerID: email,
    ThirdPartyID: calendar.url,
    Color: darken(
      calendar.calendarColor.length > 0 ? calendar.calendarColor : '#3293eb',
      0.15
    )
  }
  return event
}

export interface IcloudApiEvent extends EventDoc {
  icalString: string
  icalObject2: any
}

export interface DeleteEventData {
  email: string
  url?: string
}
