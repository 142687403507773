import { Box, Grid, Typography } from '@mui/material'

import './styles.scss'
import { t } from 'i18next'
import { Model } from 'types/model'
import {
  AccountDoc,
  EventDoc,
  GroupDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Scrollable, SearchColumn } from './styled'
import EventsColumn from './EventsColumn'
import Groups from 'components/sidebar/Groups'
import { GroupsList } from 'components/sidebar/GroupsList'
import GroupsColumn, { GROUP_TYPE } from './GroupsColumn'
import { isGroupcalApp } from 'utils/appType'
import OffsetFromBottomDivider from 'components/dividers/OffsetFromBottomDivider'

export interface SearchTabsProps {
  setOpen: (b: boolean) => void
  events?: Model<EventDoc>[] | EventDoc[]
  groups?: GroupDoc[]
  account?: Model<AccountDoc>
  userSettings?: UserSettingsDoc
  publicGroups?: GroupDoc[]
}

export default function SearchTabs(props: SearchTabsProps) {
  return (
    <Grid
      container
      flexWrap={'nowrap'}
      display={'flex'}
      style={{
        height: '50vh',
        paddingTop: 8,
        overflow: 'hidden'
      }}
    >
      <SearchColumn>
        <Box className="column-header">
          <Typography className="title">{t('calendars')}</Typography>
        </Box>
        <GroupsColumn
          type={GROUP_TYPE.ALL}
          account={props.account}
          groups={props.groups}
          userSettings={props.userSettings}
          setOpen={props.setOpen}
        />
        <OffsetFromBottomDivider bottomOffset={30} />
      </SearchColumn>
      <SearchColumn>
        <Box className="column-header">
          <Typography className="title">{t('events')}</Typography>
        </Box>
        <EventsColumn showTime events={props.events ?? []} />
        <OffsetFromBottomDivider bottomOffset={30} />
      </SearchColumn>
      {isGroupcalApp() && (
        <SearchColumn>
          <Box className="column-header">
            <Typography className="title">{t('calendarChannels')}</Typography>
          </Box>
          <GroupsColumn
            type={GROUP_TYPE.PUBLIC}
            groups={props.publicGroups}
            localGroups={props.groups}
            userSettings={props.userSettings}
            setOpen={props.setOpen}
          />
        </SearchColumn>
      )}
    </Grid>
  )
}
