import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { LocalStorageKey } from 'types/services/localStorage'
import { getAccessToken } from 'utils/auth'
import LocalStorage from './LocalStorage'
import Logger from 'services/Logger'
import { login, LOGIN_URL } from './api/auth'
import axiosRetry from 'axios-retry'
import { getOrUpdateDeviceId } from 'utils/device'
import dayjs from 'dayjs'
import { API_CHANGES } from './api/changes'
import {
  ADD_EVENT_CANNOT_POST_ITEMS,
  ADD_EVENT_NOT_PART_OF_GROUP,
  EDIT_EVENT_CANNOT_CHANGE_EVENT,
  EDIT_EVENT_NOT_PART_OF_GROUP,
  EDIT_GROUP_ADD_PARTICIPANTS_USER_NOT_ADMIN,
  EDIT_GROUP_METADATA_USER_NOT_AUTHORIZED,
  EDIT_GROUP_SETTINGS_USER_NOT_AUTHORIZED,
  EDIT_GROUP_USER_NOT_PART_OF_GROUP,
  EDIT_PARTICIPANTS_USER_NOT_AUTHORIZED,
  OUT_OF_TIER
} from 'utils/api/codes'

export const API_URL =
  process.env.REACT_APP_API_URL ?? 'https://stage003.twentyfour.me'

class Api {
  instance = axios.create({
    baseURL: `${API_URL}/api`
  })

  constructor() {
    this.instance.interceptors.request.use((req) => {
      if (getAccessToken()) {
        req.headers = {
          ...req.headers,
          Authorization: req.url?.includes(LOGIN_URL)
            ? false
            : (getAccessToken() as string)
        }
      }
      return req
    })

    this.instance.interceptors.response.use(
      undefined,
      async (error: AxiosError) => {
        if (!shouldRetry(error)) {
          return Promise.reject(error)
        }

        const originalRequestConfig = error.config
        // LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN)

        // delay original requests until authorization has been completed
        return login().then(() => {
          originalRequestConfig.headers = {
            ...originalRequestConfig.headers,
            Authorization: getAccessToken() as string
          }
          return axios.request(originalRequestConfig)
        })
      }
    )
    axiosRetry(this.instance, {
      retries: 3,
      retryCondition: (error) => {
        return shouldRetry(error)
      },
      retryDelay: (retryCount) => {
        return retryCount * 5000
      }
    })
  }

  public get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    Logger.blue(`request to ${url}`)

    const response = this.instance.get(url, config)

    return response
  }

  public post(
    url: string,
    body: Record<string, string | number | boolean | object | undefined | null>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const lastUpdate = LocalStorage.get(LocalStorageKey.LAST_UPDATE)

    let postBody = {
      ...body
    }

    if (lastUpdate && body['LastUpdate'] != null) {
      //if we have last update, meaning very first getchanges finished
      postBody = {
        ...postBody,
        DeviceChangeID: getOrUpdateDeviceId(),
        LastUpdate: url.includes(API_CHANGES)
          ? lastUpdate
          : `${dayjs().unix()}`,
        UserID: LocalStorage.get(LocalStorageKey.USER_ID)
      }
    }

    Logger.blue(`request to ${url}; with body`, postBody)

    return this.instance.post(url, postBody, config).then((e) => {
      return e
    })
  }
}

function shouldRetry(error: AxiosError) {
  const httpCode = error.response?.status

  if (httpCode === 403) {
    /**
     * we have specific cases when server return 403, but we should not retry the request, such as:
     * out of tier, user blocked from adding/editing events etc
     *
     */
    const code = (error.response?.data as unknown as any)['code']

    if (VALID_403_CODES.includes(code)) {
      return false
    }
  }

  return (
    httpCode === 403 && LocalStorage.get(LocalStorageKey.USER_ID) != undefined
  )
}

export default new Api()

export const VALID_403_CODES = [
  EDIT_PARTICIPANTS_USER_NOT_AUTHORIZED,
  EDIT_GROUP_METADATA_USER_NOT_AUTHORIZED,
  EDIT_GROUP_SETTINGS_USER_NOT_AUTHORIZED,
  EDIT_GROUP_USER_NOT_PART_OF_GROUP,
  EDIT_GROUP_ADD_PARTICIPANTS_USER_NOT_ADMIN,
  OUT_OF_TIER,
  ADD_EVENT_NOT_PART_OF_GROUP,
  ADD_EVENT_CANNOT_POST_ITEMS,
  EDIT_EVENT_NOT_PART_OF_GROUP,
  EDIT_EVENT_CANNOT_CHANGE_EVENT
]
