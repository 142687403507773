import { Box, Typography } from '@mui/material'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import { t } from 'i18next'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { theme } from 'theme'
import {
  AccountDoc,
  EventDoc,
  GroupDoc,
  UserSettingsDoc
} from 'types/api/changes'
import { Model } from 'types/model'
import withObservables from '@nozbe/with-observables'
import ObserveService from 'services/db/Observe'
import { CloseIcon } from 'assets/icons'

import EventsColumn from './EventsColumn'
import SearchTabs from './SearchTabs'
import * as Styled from '../styled'
import { PopupCloseButton } from '../styled'
import SearchBarWithButton from 'components/inputs/SearchBarWithButton'
import { allGroups, allGroupsForUI } from 'services/db/Queries/GroupQueries'
import { searchPublicGroups } from 'services/api/group'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import LocalStorage from 'services/LocalStorage'
import { LocalStorageKey } from 'types/services/localStorage'
import { generateGroupcalArrayFromRecurrence } from 'components/MinuteTimer'
import {
  AppUIType,
  appUITypeToDocType,
  useAppUIType
} from 'components/providers/AppUITypeProvider'
import {
  prefixGoogleEventsGroup,
  syncAllGoogleAccounts,
  syncGoogleAcc
} from 'utils/google_events'
import { ThirdPartyAccountByEmail } from 'services/db/Queries/ThirdPartyCalendarsQuieries'
import { isConvertedGroupId } from 'utils/groups'
import { allCalendarItems } from 'services/db/Queries/Event'

export enum SEARCH_MODE {
  OnlyEvents,
  OnlyAllEvents,
  All
}

export interface SearchPopupProps {
  children: ReactNode
  open: boolean
  setOpen: (b: boolean) => void
  userSettings?: Model<UserSettingsDoc>
  account?: Model<AccountDoc>
  mode?: SEARCH_MODE
  groupId?: string
  textfieldPlaceholder?: string
}

export function SearchPopup(props: SearchPopupProps) {
  const appUiTypeContext = useAppUIType()
  const [events, setEvents] = useState<EventDoc[]>([])
  const [groups, setGroups] = useState<Model<GroupDoc>[]>([])
  const [publicGroups, setPublicGroups] = useState<Model<GroupDoc>[]>([])
  const [searchValue, setSearchValue] = useState('')

  function clearAllData() {
    setEvents([])
    setGroups([])
    setPublicGroups([])
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAllData()

    setSearchValue(event.target.value)
  }

  const applyFilter = () => {
    allCalendarItems().then((events) => {
      const filteredEvents = events
        ?.filter((event) => {
          if (searchValue.length === 0) return true
          return (
            event.Text?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            event.Location?.Address?.toLowerCase()?.includes(
              searchValue.toLowerCase()
            ) ||
            (event.Notes &&
              typeof event.Notes !== 'string' &&
              event.Notes?.some((note) =>
                note.Note.toLowerCase().includes(searchValue.toLowerCase())
              )) ||
            (event.Location &&
              typeof event.Location !== 'string' &&
              event.Location?.Address?.toLowerCase()?.includes(
                searchValue.toLowerCase()
              ))
          )
        })
        .filter((event) => {
          if (
            (LocalStorage.get(LocalStorageKey.SELECTED_GROUP)?.length ?? 0) >
              0 &&
            props.mode === SEARCH_MODE.OnlyEvents
          ) {
            return (
              event.GroupID === LocalStorage.get(LocalStorageKey.SELECTED_GROUP)
            )
          }

          if (props.mode === SEARCH_MODE.OnlyEvents && is24meApp()) {
            if (appUiTypeContext.selectedLabel) {
              return (
                event.Type ===
                  appUITypeToDocType(appUiTypeContext.uiType.value) &&
                event.Label &&
                typeof event.Label !== 'string' &&
                event.Label.some((label) => {
                  return label.LabelText === appUiTypeContext.selectedLabel?.ID
                })
              )
            }

            if (
              (LocalStorage.get(LocalStorageKey.SELECTED_GROUP)?.length ?? 0) >
              0
            )
              return (
                event.Type ===
                  appUITypeToDocType(appUiTypeContext.uiType.value) &&
                !event.Recurrence
              )
          }

          return true
        })
        .sort((a, b) => {
          if (a.StartDate === b.StartDate) {
            return Number(b.EndDate) - Number(a.EndDate)
          }
          return Number(b.StartDate) - Number(a.StartDate)
        })

      const eventsForUI: EventDoc[] = [
        ...(filteredEvents?.filter(
          (event) => !event.Recurrence || typeof event.Recurrence === 'string'
        ) ?? [])
      ]

      for (const event of filteredEvents?.filter(
        (event) => event.Recurrence && typeof event.Recurrence !== 'string'
      ) ?? []) {
        eventsForUI.push(...generateGroupcalArrayFromRecurrence(event))
      }

      setEvents(eventsForUI ?? [])
    })

    allGroups().then((groups) => {
      const filteredGroups = groups?.filter((group) => {
        if (searchValue.length === 0) return true
        return group.Name?.toLowerCase()?.includes(searchValue.toLowerCase())
      })

      setGroups(filteredGroups ?? [])
    })
  }

  let placeholder = ''

  if (props.textfieldPlaceholder) {
    placeholder = props.textfieldPlaceholder
  }

  useEffect(() => {
    if (props.open) {
      setSearchValue('')
      clearAllData()
    }
  }, [props.open])

  return (
    <Styled.SearchPopup
      open={props.open}
      sx={{
        minWidth: props.mode === SEARCH_MODE.All ? '1050px' : '550px',
        width: props.mode === SEARCH_MODE.All ? '1050px' : '550px',
        height: props.mode !== SEARCH_MODE.All ? '750px' : undefined
      }}
      placement="right"
      content={
        <Box
          position={'relative'}
          display={'flex'}
          flexDirection={'column'}
          alignContent={'center'}
          gap={2}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row-reverse'}
            paddingLeft={4}
            paddingRight={4}
          >
            <GroupcalTooltip title={t('close')}>
              <PopupCloseButton
                type={'button'}
                size="sm"
                onClick={() => {
                  props.setOpen(false)
                }}
              >
                <CloseIcon />
              </PopupCloseButton>
            </GroupcalTooltip>
          </Box>
          <SearchBarWithButton
            placeholder={placeholder}
            onSearch={() => {
              applyFilter()
              searchPublicGroups(searchValue).then((data) => {
                console.log(data)

                if (props.mode === SEARCH_MODE.All)
                  setPublicGroups(
                    data['data'].map((item: any) => {
                      const group: GroupDoc = { ...item }
                      return group
                    })
                  )
              })
              if (props.mode === SEARCH_MODE.OnlyEvents) {
                if (isConvertedGroupId(props.groupId)) {
                  ThirdPartyAccountByEmail(
                    props.groupId?.replace(prefixGoogleEventsGroup, '') ?? ''
                  ).then((googleAcc) => {
                    syncGoogleAcc(googleAcc, '', '', searchValue)
                  })
                } else {
                  syncAllGoogleAccounts('', '', searchValue)
                }
              } else {
                syncAllGoogleAccounts('', '', searchValue)
              }
            }}
            onTextChange={handleFilterChange}
            searchValue={searchValue}
          />
          {props.mode === SEARCH_MODE.OnlyEvents ||
          props.mode === SEARCH_MODE.OnlyAllEvents ? (
            <EventsColumn events={events} showTime />
          ) : (
            <SearchTabs
              account={props.account}
              userSettings={props.userSettings}
              events={events}
              groups={allGroupsForUI(groups ?? [], props.userSettings!, true)}
              setOpen={props.setOpen}
              publicGroups={publicGroups}
            />
          )}
        </Box>
      }
    >
      {props.children}
    </Styled.SearchPopup>
  )
}

export default withObservables([], (props: SearchPopupProps) => ({
  userSettings: ObserveService.UserSettings(),
  account: ObserveService.Account()
}))(SearchPopup)
