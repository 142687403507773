import { Q } from '@nozbe/watermelondb'
import { updateAccountOnServer } from 'services/api/account'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { AccountDoc } from 'types/api/changes'
import { CollectionType } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { getOrUpdateDeviceId } from 'utils/device'
import { getFCMToken } from 'utils/firebase'
import Database from '../Database'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import { isCypress } from 'utils/cypress'

export const Account = async () => {
  const accounts = await Database.getCollection<AccountDoc>(
    CollectionType.ACCOUNT
  )
    .query()
    .fetch()
  return accounts[0]
}

export async function updatePushTokenIfNeeded() {
  try {
    const token = await getFCMToken()
    Logger.debug('push token', token)
    if (!token) return

    Account().then((acc) => {
      if (acc == null) return

      const deviceUUID = getOrUpdateDeviceId()
      const deviceInArray = acc.Devices.find(
        (e) => e.DevicesID == token && e.DeviceUUID == deviceUUID
      )
      const isDeviceAdded = deviceInArray != null

      console.log(`device in arr: ${isDeviceAdded}`)

      if (!isDeviceAdded) {
        let newDevices = acc.Devices.map((device) => {
          return device.DeviceUUID == deviceUUID
            ? {
                ...device,
                DevicesID: token,
                AppID: is24meApp() ? 'twentyfourme' : 'groupcal'
              }
            : device
        })

        if (
          !isCypress() &&
          !acc.Devices.find((device) => device.DeviceUUID === deviceUUID)
        ) {
          // we does not have such device, adding such
          newDevices = [...acc.Devices]
          newDevices.push({
            AppID: is24meApp() ? 'twentyfourme' : 'groupcal',
            DeviceType: '3',
            DeviceUUID: deviceUUID,
            DevicesID: token,
            Token: LocalStorage.get(LocalStorageKey.ACCESS_TOKEN) ?? ''
          })
        }

        Database.write(async () => {
          const accFromDB = (await Database.getById(
            CollectionType.ACCOUNT,
            acc.id
          )) as unknown as AccountDoc
          await accFromDB.update(() => {
            accFromDB.Devices = newDevices
          })

          const response = updateAccountOnServer(accFromDB)

          Logger.debug('add push', response)
        })
      }
    })
  } catch (e) {
    console.error('no way to receive a push token', e)
  }
}
