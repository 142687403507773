import dayjs from 'dayjs'
import Api from 'services/Api'
import Logger from 'services/Logger'
import Database from 'services/db/Database'
import { MasterLabel } from 'services/db/Queries/MasterLabel'
import { MasterLabelLabel } from 'types/api/changes'

const ADD_MASTER_LABEL_URL = '/misc/masterlabels/add'
const EDIT_MASTER_LABEL_URL = '/misc/masterlabels/edit'

export async function removeLabelFromMasterLabel(toRemove: MasterLabelLabel) {
  Logger.blue('MasterLabel:Remove', toRemove)
  MasterLabel()
    .then((masterLabel) => {
      return Database.write(async () => {
        return masterLabel.update((local) => {
          Logger.blue('MasterLabel:Remove', local.Labels)
          const filteredOut = local.Labels.filter((label) => {
            console.log(label)
            return label.ID !== toRemove.ID
          })
          Logger.blue('MasterLabel:Remove', filteredOut)
          local.Labels = filteredOut
        })
      })
    })
    .then((updatedMasterLabel) => {
      Logger.blue('MasterLabel:Updated', updatedMasterLabel)
      return Api.post(`${EDIT_MASTER_LABEL_URL}/${updatedMasterLabel._id}`, {
        ...Database.toServerObject(updatedMasterLabel)
      })
    })
    .then((result) => {
      Logger.blue('MasterLabel:Upload', result)
    })
}

export async function editLabelInMasterLabel(updatedLabel: MasterLabelLabel) {
  MasterLabel()
    .then((masterLabel) => {
      return Database.write(async () => {
        return masterLabel.update((local) => {
          local.Labels = local.Labels.map((label) => {
            if (label.ID === updatedLabel.ID) {
              return updatedLabel
            } else return label
          })
        })
      })
    })
    .then((updatedMasterLabel) => {
      Logger.blue('MasterLabel:Updated', updatedMasterLabel)
      return Api.post(`${EDIT_MASTER_LABEL_URL}/${updatedMasterLabel._id}`, {
        ...Database.toServerObject(updatedMasterLabel)
      })
    })
    .then((result) => {
      Logger.blue('MasterLabel:Upload', result)
    })
}

export async function addLabelToMasterLabel(title: string, color: string) {
  MasterLabel()
    .then((masterLabel) => {
      return Database.write(async () => {
        return masterLabel.update((local) => {
          local.Labels = local.Labels.map((label) => {
            if (label.ID === 'temp') {
              return {
                ID: dayjs().valueOf().toString(),
                Text: title,
                Color: color
              }
            } else return label
          })
        })
      })
    })
    .then((updatedMasterLabel) => {
      Logger.blue('MasterLabel:Updated', updatedMasterLabel)
      return Api.post(`${EDIT_MASTER_LABEL_URL}/${updatedMasterLabel._id}`, {
        ...Database.toServerObject(updatedMasterLabel)
      })
    })
    .then((result) => {
      Logger.blue('MasterLabel:Upload', result)
    })
}

export function convertHexToRGBALabelColor(hex: string) {
  // Remove the '#' character from the beginning of the hex string
  const cleanedHex = hex.replace('#', '')

  // Check if the hex string is valid
  if (!/^([0-9A-F]{3}){1,2}$/i.test(cleanedHex)) {
    return '{0,0,0,1}' // Invalid hex string
  }

  if (cleanedHex && cleanedHex.match(/.{1,2}/g)) {
    // Parse the hex string into separate RGB components
    const hexMatches = cleanedHex.match(/.{1,2}/g)
    const [r, g, b] = hexMatches
      ? hexMatches.map((value) => parseInt(value, 16))
      : [0, 0, 0]

    // Construct the RGBA string
    return `{${r / 255},${g / 255},${b / 255},${1}}`
  }

  return '{0,0,0,1}'
}
