import { GroupDoc, UserSettingsDoc } from 'types/api/changes'
import { prefixGoogleEventsGroup } from './google_events'
import { outlookPrefix } from './constants'
import { ProviderType } from 'components/providers/CalendarSyncProdivder'
import { icloudPrefix } from 'components/providers/icloud/IcloudProvider'

export function defaultGroupId(userSettings?: UserSettingsDoc) {
  if (!userSettings) return undefined

  return Object.entries(userSettings?.GroupsSettings ?? {}).find(
    ([key, value]) => value.Default === '1'
  )?.[0]
}

/**
 *
 * @param group to check if it is a converted group
 * @returns true if id contains prefix of a converted group
 */
export function isConvertedGroup(group: GroupDoc | undefined) {
  if (!group) return false
  return isConvertedGroupId(group._id?.toLowerCase() ?? '')
}

export function isConvertedGroupId(groupId?: string) {
  if (!groupId) return false
  return [
    prefixGoogleEventsGroup.toLowerCase(),
    outlookPrefix.toLowerCase(),
    icloudPrefix.toLowerCase()
  ].some((prefix) => groupId.toLowerCase().includes(prefix))
}

export function groupIdToProviderTypeName(groupId?: string) {
  if (!groupId) return undefined
  if (groupId.includes(outlookPrefix)) return 'Outlook'
  if (groupId.includes(prefixGoogleEventsGroup)) return 'Google'
  if (groupId.includes(icloudPrefix)) return 'iCloud'

  return undefined
}

export function groupIdToProvider(groupId: string): ProviderType | undefined {
  if (!groupId) return undefined
  if (groupId.includes(prefixGoogleEventsGroup)) return ProviderType.GOOGLE
  if (groupId.includes(outlookPrefix)) return ProviderType.OUTLOOK
  if (groupId.includes(icloudPrefix)) return ProviderType.APPLE
  return undefined
}

export function groupIdToPrefix(groupId: string): string | undefined {
  if (!groupId) return undefined
  if (groupId.includes(prefixGoogleEventsGroup)) return prefixGoogleEventsGroup
  if (groupId.includes(outlookPrefix)) return outlookPrefix
  if (groupId.includes(icloudPrefix)) return icloudPrefix
  return undefined
}

export function groupToProvider(
  group: GroupDoc | undefined
): ProviderType | undefined {
  if (!group) return undefined

  return groupIdToProvider(group._id ?? '')
}
