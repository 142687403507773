import { Box, CircularProgress, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import withObservables from '@nozbe/with-observables'
import Analytics from 'analytics/Analytics'
import {
  avatarFallback,
  BetaStrip,
  NoNotificationsIcon,
  SettingsIcon
} from 'assets/icons'
import { PicContainer } from 'components/badges/styled'
import IconButton from 'components/buttons/IconButton'
import TierButton from 'components/buttons/TierButton'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import EnterNameDialog from 'components/dialogs/EnterNameDialog'
import { PlanName } from 'components/dialogs/payment/PlansInfoDialog'
import { removeZeroIfNeeded } from 'components/login/LoginPage'
import UserMenuPopup from 'components/popups/userMenu/UserMenuPopup'
import { useConnection } from 'components/providers/ConnectionProvider'
import { usePayment } from 'components/providers/PaymentProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parsePhoneNumber } from 'react-phone-number-input'
import { updateProfileOnServer } from 'services/api/profile'
import ObserveService from 'services/db/Observe'
import LocalStorage from 'services/LocalStorage'
import Logger from 'services/Logger'
import { AccountDoc, ProfileDoc, UserSettingsDoc } from 'types/api/changes'
import { Model } from 'types/model'
import { LocalStorageKey } from 'types/services/localStorage'
import { useFilePicker } from 'use-file-picker'
import { highestActiveProduct, highestActiveTier } from 'utils/api/accounts'
import { APP_COLOR } from 'utils/colors'
import { compressBase64 } from 'utils/file'

import * as Styled from './styled'
import { useCalendar } from 'components/providers/CalendarProvider'
import { GoogleCalendar } from 'types/google_events'
import NotificationPermissionDialog from 'components/dialogs/NotificationPermissionDialog'
import GroupcalTooltip from 'components/tooltips/GroupcalTooltip'
import { is24meApp, isGroupcalApp } from 'utils/appType'
import i18n from 'i18n'
import { useOutlookContext } from 'components/providers/outlook/OutlookProvider'
import { MenuIcon } from 'assets/icons/menu'
import { useAppSettings } from 'components/providers/AppSettingsProvider'
import { isCypress } from 'utils/cypress'
interface HeaderProps {
  profile: Model<ProfileDoc>
  account: Model<AccountDoc>
  userSettings: Model<UserSettingsDoc>
}

function Header(props: HeaderProps) {
  const { t } = useTranslation()
  const [error, setError] = useState<string>('')
  const [progress, onProgress] = useState(false)
  const [showEnterNameDialog, onShowEnterNameDialog] = useState(false)
  const { showPaymentDialog } = usePayment()

  const showNameDialog = () => {
    onShowEnterNameDialog(true)
  }
  const calendar = useCalendar()
  const connection = useConnection()
  const appSettings = useAppSettings()

  useEffect(() => {
    if (props.userSettings)
      appSettings.setFirstDayOfTheWeek(props.userSettings.FirstDayOfTheWeek)
  }, [props.userSettings?.FirstDayOfTheWeek])

  const parsedNum = parsePhoneNumber(
    LocalStorage.get(LocalStorageKey.PHONE_NUMBER) ?? ''
  )
  const humanReadable = `+${parsedNum?.countryCallingCode} ${removeZeroIfNeeded(
    parsedNum?.formatNational()
  )}`

  Logger.debug('account', props.account)
  Logger.debug('usersettings', props.userSettings)
  Logger.debug('profile', props.profile)

  let userName = ''
  const shouldAskUserForName =
    props.account?.Name.FirstName === 'null' ||
    props.account?.Name.FullName === 'null'

  if (shouldAskUserForName) {
    userName = t('addYourName')
  } else {
    userName = props.account?.Name.FullName.includes('null')
      ? props.account?.Name?.FirstName ?? ''
      : props.account?.Name?.FullName ?? props.account?.Name?.FirstName ?? ''
  }

  const showUserDialogIfNeeded = () => {
    showNameDialog()
  }

  const [openFileSelector, { filesContent }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false
  })
  const handleOpenFile = () => {
    openFileSelector()
  }

  async function sendPicToServer(data: string) {
    let pic = ''

    try {
      pic = await compressBase64(data)
    } catch (e) {
      setError('GroupCal allows to use photos up to 8MB in size.')
    }

    if (!pic) return
    const profile = props.profile
    if (!profile) return
    const profileToUpdate: ProfileDoc = {
      ActiveProviders: profile.ActiveProviders,
      DataProviders: profile.DataProviders,
      DeviceChangeID: profile.DeviceChangeID,
      Facebook: profile.Facebook,
      Newsletters: profile.Newsletters,
      Notifications: profile.Notifications,
      ProfilePicture: pic,
      isDeleted: profile.isDeleted,
      _id: profile._id,
      _rev: profile._rev,
      UserID: profile.UserID,
      LastUpdate: profile.LastUpdate,
      Type: profile.Type
    }

    onProgress(true)
    try {
      await updateProfileOnServer(profileToUpdate)
    } catch (e) {
      console.error(e)
      connection.setGenericErrorVisible(true)
    }
    onProgress(false)
  }

  useEffect(() => {
    if (filesContent.length > 0) {
      if (connection.isConnectionAvailable())
        sendPicToServer(filesContent[0].content)
    }
  }, [filesContent])

  const [showSettingsPopup, setShowSettingsPopup] = useState(false)

  const openUserSettingsPopup = () => {
    setShowSettingsPopup(true)
  }

  useEffect(() => {
    Analytics.getInstance().appendSubscriptionInfo(props.account)
  }, [props.account])

  const handleClose = (
    status: 'PURCHASED' | 'PURCHASE_ABORTED' | 'DIALOG_CLOSED',
    plan: PlanName
  ) => {}

  const showPurchases = () => {
    if (isGroupcalApp()) {
      showPaymentDialog(null, handleClose, ['OpenFromMenu'])
    } else {
      //handle purchase screen in 24me
    }
  }

  const [notificationPermission, setNotificationPermission] =
    useState('default')

  const [notificationPermissionGeneral, onNotificationPermissionGeneral] =
    useState(
      LocalStorage.get(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN) != '1'
    )

  const [showNotificationPermission, onShowNotificationPermission] =
    useState(false)

  const toggleNotificationPermission = () => {
    onShowNotificationPermission(true)
  }

  const outlook = useOutlookContext()
  const userIdentifier = isGroupcalApp()
    ? `‎${humanReadable}`
    : LocalStorage.get(LocalStorageKey.USER_EMAIL)

  return (
    <Styled.Header sx={{ position: 'relative' }}>
      <Styled.HeaderContent>
        <span onClick={handleOpenFile} style={{ cursor: 'pointer' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              {props.profile?.ProfilePicture ? (
                <Styled.HeaderAvatar
                  asGroup
                  name=""
                  img={progress ? '' : props.profile?.ProfilePicture}
                  emptyPic={avatarFallback}
                />
              ) : (
                <GroupcalTooltip title={t('yourProfilePicture')}>
                  <PicContainer>
                    <img
                      style={{ maxWidth: 52, maxHeight: 52 }}
                      src={avatarFallback}
                    />
                  </PicContainer>
                </GroupcalTooltip>
              )}
              {progress && (
                <CircularProgress
                  size={54}
                  sx={{
                    position: 'absolute',
                    color: APP_COLOR,
                    top: -1,
                    left: -1,
                    zIndex: 1
                  }}
                />
              )}
            </Box>
          </Box>
        </span>

        <Grid
          xs
          item
          marginLeft={i18n.dir() === 'ltr' ? 2 : 0}
          flexDirection={'column'}
        >
          <GroupcalTooltip
            title={
              userName.length === 0 ? t('addYourName') : t('updateYourName')
            }
          >
            <div
              id="user-name"
              style={{
                fontWeight: 400,
                fontSize: 20,
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '12rem',
                minHeight: '1.2em'
              }}
              onClick={showUserDialogIfNeeded}
            >
              {userName}
            </div>
          </GroupcalTooltip>
          {(userIdentifier?.length ?? 0) > 25 ? (
            <GroupcalTooltip title={userIdentifier}>
              <UserIdentifierComponent userIdentifier={userIdentifier} />
            </GroupcalTooltip>
          ) : (
            <UserIdentifierComponent userIdentifier={userIdentifier} />
          )}

          <TierButton
            product={highestActiveProduct(props.account)}
            tier={highestActiveTier(props.account)}
            onClick={showPurchases}
          />
        </Grid>

        <Grid
          display={'flex'}
          flexDirection={'column'}
          style={{ marginRight: i18n.dir() === 'ltr' ? 4 : 0 }}
        >
          <UserMenuPopup
            account={props.account}
            userSettings={props.userSettings}
            open={showSettingsPopup}
            setOpen={setShowSettingsPopup}
            connectGoogleCalendar={calendar.initiateGoogleSync}
            connectOutlookCalendar={() => {
              if (outlook.signIn) outlook.signIn()
            }}
          >
            <span id="user-settings-button" onClick={openUserSettingsPopup}>
              <GroupcalTooltip title={t('settings')}>
                <IconButton size="md">
                  <MenuIcon />
                </IconButton>
              </GroupcalTooltip>
            </span>
          </UserMenuPopup>
          {typeof Notification !== 'undefined' &&
            Notification.permission != 'granted' &&
            LocalStorage.get(LocalStorageKey.DONT_SHOW_NOTIF) === null &&
            LocalStorage.get(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN) ===
              '1' && (
              <span onClick={toggleNotificationPermission}>
                <IconButton size="md">
                  <NoNotificationsIcon />
                </IconButton>
              </span>
            )}
        </Grid>
      </Styled.HeaderContent>
      {showEnterNameDialog && (
        <EnterNameDialog
          open={showEnterNameDialog}
          setOpen={onShowEnterNameDialog}
        />
      )}

      {error && (
        <ConfirmationDialog
          rightButtonHidden
          title={t('oopsError')}
          content={error}
          handleLeftButton={function () {
            setError('')
          }}
          open={error.length > 0}
        />
      )}
      {!isCypress() && showNotificationPermission && (
        <NotificationPermissionDialog
          onGranted={() => {
            setNotificationPermission('granted')
            Logger.pink('granted permission', notificationPermission)
          }}
          openGeneral={showNotificationPermission}
          setOpenGeneral={onShowNotificationPermission}
        />
      )}
      {typeof Notification !== 'undefined' &&
        Notification.permission != 'granted' &&
        LocalStorage.get(LocalStorageKey.NOTIFICATION_PERMISSION_SHOWN) !=
          '1' && (
          <NotificationPermissionDialog
            onGranted={() => {
              Logger.pink('granted')
              onNotificationPermissionGeneral(false)
              setNotificationPermission('granted')
            }}
            openGeneral={notificationPermissionGeneral}
            setOpenGeneral={onNotificationPermissionGeneral}
          />
        )}
    </Styled.Header>
  )
}

function UserIdentifierComponent({
  userIdentifier
}: {
  userIdentifier: string | null
}) {
  return (
    <div
      id="user-identifier"
      style={{
        fontWeight: 300,
        fontSize: 14,
        minHeight: '1.2em',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '12rem'
      }}
    >
      {userIdentifier}
    </div>
  )
}

export default withObservables([], () => ({
  profile: ObserveService.Profile(),
  account: ObserveService.Account(),
  userSettings: ObserveService.UserSettings()
}))(Header)
